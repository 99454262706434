import React from 'react'
import { useHistory } from 'react-router-dom'

import { Header, Container, Grid } from 'semantic-ui-react'
import './styles.scss'
import { useTranslation } from 'react-i18next';

import importer from '@takedapdt/biolife-core/src/importer'

const HorizontalNewsBlock = importer('Components/HorizontalNewsBlock')
const VerticalNewsBlock = importer('Components/VerticalNewsBlock')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const NewsSection = ({ images, newsHorizontal1, newsHorizontal2, newsVertical }) => {
  const history = useHistory()
  const { t } = useTranslation('NewsSection');

  return (
    <div className='NewsSection'>
      <Container>
        <Header as='h2' className='NewsSectionHeader'>
          {t('becomeDonor')}
        </Header>
        <Grid className="NewsSectionGrid">
          <Grid.Row>
            <Grid.Column computer={10} mobile={16} className='NewsColumn'>
              <HorizontalNewsBlock content={newsHorizontal1} image={images.homeNews1} />
              <HorizontalNewsBlock content={newsHorizontal2} image={images.homeNews2} />
            </Grid.Column>
            <Grid.Column computer={6} mobile={16} className='NewsColumn'>
              <VerticalNewsBlock content={newsVertical} image={images.homeNews3} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid className='NewsSectionButtonContainer'>
          <PrimaryButton text={t('becomeDonor')} width={200} height={50} fontSize={16} onClick={() => history.push('/registration')} />
        </Grid>
      </Container>
    </div>
  )
}

export default NewsSection
