import React, { useState } from "react";
import { Header, Container, Grid } from "semantic-ui-react";
import { NavLink, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./styles.scss";
import importer from "@takedapdt/biolife-core/src/importer";

/* */
const PrimaryButton = importer("Components/Buttons/PrimaryButton");
const LoyaltyAdvertisementCard = ({ header, children, button,navigation }) => {
  const { t } = useTranslation("rewardsCard");

  return (
    <div className="card">
      <Container fluid>
        <Grid.Row
          className="LoyaltyCard"
          style={{ backgroundColor: "#cce4e2" }}
        >
          <Header as="h2" className="LoyaltyHeader">
            {header}
          </Header>
          <p className="LoyaltyText">{children}</p>

          <PrimaryButton
            text={button}
            classType="Secondary1"
            onClick={navigation}
          />
        </Grid.Row>
      </Container>
    </div>
  );
};

export default LoyaltyAdvertisementCard;