import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Header, Container, Grid, Image } from 'semantic-ui-react'
import { NavLink, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next';
import { splitCenterName } from "../../../Helpers/Utils"
import moment from 'moment'
import './styles.scss'
/* */
const LoadingState = importer("Components/Dashboard/StateLoadingContainer");
const FaiureState = importer("Components/Dashboard/StateErrorContainer");
const NoDataState = importer("Components/Dashboard/StateNoDataContainer");
/* */
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const ModifyAppointmentModal = importer('Components/Dashboard/ModifyAppointmentModal')
const ModifyOrCancelAppointmentModal = importer('Components/Dashboard/ModifyOrCancelAppointmentModal')
const ConfirmCancellationModal = importer('Components/Dashboard/ConfirmCancellationModal')
const ConfirmAppointmentModal = importer('Components/Dashboard/ConfirmAppointmentModal')
const AddToCalendarModal = importer('Components/Dashboard/AddToCalendarModal')
const LoyaltyAdvertisementCard = importer('Components/LoyaltyAdvertisementCard')
const ChangePaymentMethodModal = importer("Components/Dashboard/ChangePaymentMethodModal");
const DashboardDonationsSection = importer('Components/Dashboard/DashboardDonationsSection');
const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { webArticles } = importer('Helpers/WebArticles')
// Actions
const centersActions = importer("Stores/Centers/Actions");
const appointmentsActions = importer("Stores/Appointments/Actions");

const DashboardAppointmentsSection = ({
  loggedInUser,
  appointments,
  inquiry,
  donorLoading,
  error,
  loading,
  getSlotsForAppointmentType,
  getAllCenters,
  center,
  centerError,
  centerLoading,
  apptCenter,
  cancelAppointment,
  appointmentsLoading,
  appointmentsError,
  getUpcomingAppointments,
  donor,
  createNewAppointmentFailure,
  createNewAppointmentLoading,
  createNewAppointmentSuccess,
  hideHeader,
  getAppointmentCenter,
  rescheduleAppointmentSuccess,
  rescheduleAppointmentLoading,
  rescheduleAppointmentFailure,
  getDonorCenterDetails,
  cancelAppointmentSuccess,
  cancelAppointmentLoading,
  cancelAppointmentFailure,
  enableSchedule = true,
  session,
  publicContent,
  lastPhysicalDate
}) => {
  const { t } = useTranslation("donorDashboardPage");
  const [appointment, setAppointment] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmModalType, setConfirmModalType] = useState("create");

  const [openAddToCalendarModal, setOpenAddToCalendarModal] = useState(false);
  const [
    openConfirmCancellationModal,
    setOpenConfirmCancellationModal,
  ] = useState(false);
  const [openChangePaymentModal, setOpenChangePaymentModal] = useState(false);

  const toggleConfirmModal = () => setOpenConfirmModal(!openConfirmModal);
  const toggleAddToCalendarModal = () =>
    setOpenAddToCalendarModal(!openAddToCalendarModal);
  const toggleConfirmCancellationModal = () =>
    setOpenConfirmCancellationModal(!openConfirmCancellationModal);

  const toggleModal = () => setOpenModal(!openModal);
  const toggleCancelModal = () => setOpenCancelModal(!openCancelModal);
  const handleAppointment = (app) => {
    setAppointment({ ...app });
  };
  const handleRescheduleAppointment = (app) => {
    setConfirmModalType("reschedule");
    setAppointment({ ...app });
  };
  
/** 
 * Toggle method for Change Payment Method modal
 */
  const toggleChangePaymentModal = () => {
    setOpenChangePaymentModal(!openChangePaymentModal);
  }
  const history = useHistory();
  const navigateToNextStep = () => {
    window.sessionStorage.setItem('LOYALTY_DASHBOARD_ACTIVE_TAB',"WAYS_TO_EARN")
    history.push('/dashboard/my-rewards');
    window.scrollTo(0, 0); 
  };
  const buddy_bonus = ArticleByIdentifier(
    publicContent,
    webArticles.BUDDY_BONUS
  )

  return (
    <div className="DashboardAppointmentsSection">
      {!hideHeader && (
        <Header as="h3" className="AppointmentsHeader">
          {t("appointments")}
        </Header>
      )}
      {!appointments && <NoDataState />}
      {appointments && (
        <Container fluid className="AppointmentsContainer">
          {enableSchedule && donor?.donationCenter && (
            <Grid.Row className="RowContainerWithBottomBorder">
              <PrimaryButton
                text={t("scheduleAppointment")}
                width="95%"
                classType="LightBlueWithPadding"
                fontSize="16px"
                as={NavLink}
                to="/appointment-scheduling"
                disabled={donorLoading || (donor.pdn && !inquiry?.data)}
              />
            </Grid.Row>
          )}
          {loading && <LoadingState />}
          {!loading
            ? !error && (
              <Grid.Row className="RowContainerWithBottomBorder">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div className="LastNextPhysicalContainer">
                    <Header className="LastNextPhysicalHeader">
                      {t("lastPhysical")}
                    </Header>
                    <Header className="LastNextPhysicalText">
                      {inquiry && inquiry.data.lastPhysicalDate
                        ? moment(inquiry.data.lastPhysicalDate).format(
                          "MM/DD/YYYY"
                        )
                        : "-"}
                    </Header>
                  </div>
                  <div className="LastNextPhysicalContainer">
                    <Header
                      className="LastNextPhysicalHeader"
                      style={{ textAlign: "right" }}
                    >
                      {t("nextPhysicalDue")}
                    </Header>
                    <Header
                      className="LastNextPhysicalText"
                      style={{ textAlign: "right" }}
                    >
                      {inquiry && inquiry.data.nextPhysicalDate
                        ? moment(inquiry.data.nextPhysicalDate).format(
                          "MM/DD/YYYY"
                        )
                        : "-"}
                    </Header>
                  </div>
                </div>
              </Grid.Row>
            )
            : error && <FaiureState />}
          <Grid.Row className="RowContainerWithoutBottomBorder">
            <Header as="h4" className="UpcomingAppointmentsHeader">
              {t("upcomingAppointments")}
            </Header>
            {!isEmpty(appointments) ? (
              appointments.map((appointment, index) => (
                <div className="UpcomingAppointmentsContainer" key={index}>

                  {loggedInUser?.role === "Donor" && loggedInUser?.pdn !== null && appointment?.isGiftcardEnabled ? (
                    <Grid
                      className={
                        "UpcomingAppointmentsRow " +
                        (appointments.length - 1 !== index
                          ? "UpcomingAppointmentsBorderBottom"
                          : "")
                      }>
                      <Grid.Row columns={2}>
                        <Grid.Column className="UpcomingAppointmentsInfoFirst">
                          {moment
                            .parseZone(appointment.startDateTime)
                            .format("MM/DD/YYYY") + "\n"}
                          <div style={{ fontWeight: "bold" }}>
                            {loggedInUser?.role === "Donor" && `${splitCenterName(appointment?.centerName)}, ${appointment.centerStateCode} `}
                            {loggedInUser?.role === "Admin" && `${appointment?.centerName}, ${appointment.centerStateCode} `}
                          </div>
                        </Grid.Column>
                        <Grid.Column className="UpcomingAppointmentsInfoBold">
                          {appointment.startTime &&
                            appointment.startTime.toUpperCase()}
                        </Grid.Column>
                        <Grid.Row className="AppointmentsButton">
                          <span className="ModifyButton">
                            <PrimaryButton
                              text={t("modify")}
                              width={112}
                              classType="Inverted"
                              fontSize="16px"
                              onClick={() => {
                                getAppointmentCenter(appointment.centerSfid);
                                handleAppointment(appointment);
                                toggleCancelModal();
                              }}
                            />
                          </span>
                          <span className="ChangePaymentButton">
                            <PrimaryButton
                              text={t("Change Payment Method")}
                              width={240}
                              classType="Inverted"
                              fontSize="16px"
                              onClick={() => {
                                toggleChangePaymentModal();
                                handleAppointment(appointment);
                              }}
                            />
                          </span>
                        </Grid.Row>
                      </Grid.Row>
                    </Grid>
                  ) : (
                    <Grid 
                      className={
                      "UpcomingAppointmentsRow " +
                      (appointments.length - 1 !== index
                        ? "UpcomingAppointmentsBorderBottom"
                        : "")
                    }>
                       <Grid.Row columns={2}>
                        <Grid.Column className="UpcomingAppointmentsInfoSecond">
                          {moment
                            .parseZone(appointment.startDateTime)
                            .format("MM/DD/YYYY") + "\n"}
                          <div style={{ fontWeight: "bold" }}>
                            {loggedInUser?.role === "Admin" && `${appointment?.centerName}, ${appointment.centerStateCode} `} 
                            {loggedInUser?.role === "Donor" && `${splitCenterName(appointment?.centerName)}, ${appointment.centerStateCode} `} 
                          </div>
                        </Grid.Column>
                        <Grid.Column className="UpcomingAppointmentsInfoBold">
                          {appointment.startTime &&
                            appointment.startTime.toUpperCase()}
                        </Grid.Column>
                        <Grid.Row className="AppointmentsButton">
                        <span className="ModifyButton">
                          <PrimaryButton
                            text={t("modify")}
                            width={112}
                            classType="Inverted"
                            fontSize="16px"
                            onClick={() => {
                              getAppointmentCenter(appointment.centerSfid);
                              handleAppointment(appointment);
                              toggleCancelModal();
                            }}
                          />
                        </span>
                        </Grid.Row>
                    </Grid.Row>
                    </Grid>
                  )}
                </div>
              ))
            ) : (
              <div style={{ fontWeight: "normal" }}>
                {t("youDontHaveAppoinments")}
              </div>
            )}
{ session?.role === 'Donor' && (
  <div className="card">
    {(donor?.isLoyaltyMember === "No" ||
                  donor?.isLoyaltyMember === null) &&
                donor?.defaultCenter?.isLoyaltyProgramEnabled ? (
                  <div>
                    <LoyaltyAdvertisementCard
                      header={t("header")}
                      children={t("children")}
                      button={t("button")}
                      navigation={navigateToNextStep}
                    />
                  </div>
                ) : donor?.isLoyaltyMember === "Yes" ||
                donor?.isLoyaltyMember === null &&
                  donor?.defaultCenter?.isLoyaltyProgramEnabled ? (
                    <div>
                    <LoyaltyAdvertisementCard
                      header={t("rewardsheader")}
                      children={t("rewardschildren")}
                      button={t("rewardsbutton")}
                      navigation={navigateToNextStep}
                    />
                  </div>
                ) : null}
              </div>
)}
        { session?.role === 'Donor' && (
            <div className="DonationSection">
              <DashboardDonationsSection
                loading={appointmentsLoading || centerLoading}
                error={appointmentsError || centerError}
                inquiry={inquiry}
                center={center}
                loggedInUser={loggedInUser}
              />
            </div>
        )}
        { session?.role === 'Donor' && (     
            <Grid.Column className="BuddyBonusSection">
              <NavLink to={buddy_bonus?.production_url__c}>
                <Image src={buddy_bonus?.content__c} /> 
                <p className="BuddyBonusDescription">{buddy_bonus?.image_description__c}</p>
              </NavLink>
            </Grid.Column>
        )}
          </Grid.Row>
        </Container>
      )}
      <ModifyAppointmentModal
        open={openModal}
        toggleModal={toggleModal}
        toggleConfirmModal={toggleConfirmModal}
        appointment={appointment}
        handleAppointment={handleRescheduleAppointment}
        getUpcomingAppointments={getUpcomingAppointments}
        donor={donor}
        center={apptCenter}
      />
      <ModifyOrCancelAppointmentModal
        open={openCancelModal}
        toggleModal={toggleCancelModal}
        appointment={appointment}
        toggleModifyModal={toggleModal}
        getSlotsForAppointmentType={getSlotsForAppointmentType}
        center={apptCenter}
        cancelAppointment={cancelAppointment}
        getUpcomingAppointments={getUpcomingAppointments}
        donor={donor}
        toggleConfirmCancellationModal={toggleConfirmCancellationModal}
        getDonorCentersAll={getDonorCenterDetails}
        getAllCenters={getAllCenters}
        loggedInUser={loggedInUser}
      />
      <ConfirmCancellationModal
        loggedInUser={loggedInUser}
        open={openConfirmCancellationModal}
        toggleModal={toggleConfirmCancellationModal}
        appointment={appointment}
        getSlotsForAppointmentType={getSlotsForAppointmentType}
        center={apptCenter}
        cancelAppointment={cancelAppointment}
        getUpcomingAppointments={getUpcomingAppointments}
        donor={donor}
        cancelAppointmentSuccess={cancelAppointmentSuccess}
        cancelAppointmentLoading={cancelAppointmentLoading}
        cancelAppointmentFailure={cancelAppointmentFailure}
      />
      <ConfirmAppointmentModal
        open={openConfirmModal}
        toggleModal={toggleConfirmModal}
        appointment={appointment}
        update
        donor={donor}
        center={apptCenter}
        createNewAppointmentFailure={rescheduleAppointmentFailure}
        toggleAddToCalendarModal={toggleAddToCalendarModal}
        createNewAppointmentSuccess={rescheduleAppointmentSuccess}
        createNewAppointmentLoading={rescheduleAppointmentLoading}
        loggedInUser={loggedInUser}
      />
      <AddToCalendarModal
        open={openAddToCalendarModal}
        toggleModal={toggleAddToCalendarModal}
        appointment={appointment}
        donor={donor}
        center={apptCenter}
        update
        createNewAppointmentSuccess={rescheduleAppointmentSuccess}
        createNewAppointmentFailure={rescheduleAppointmentFailure}
        loggedInUser={loggedInUser}
        lastPhysicalDate={lastPhysicalDate}
      />
      <ChangePaymentMethodModal
        open={openChangePaymentModal}
        toggleModal={toggleChangePaymentModal}
        appointment={appointment}
        setOpenModal={setOpenChangePaymentModal}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users?.user,
  center: state.centers.getAllCentersSuccess?.data,
  apptCenter: state.centers.getAppointmentCenterSuccess?.data,
  donor: state.donors.donors?.data,
  createNewAppointmentFailure: state.appointments.createNewAppointmentFailure?.data,
  createNewAppointmentLoading: state.appointments.createNewAppointmentLoading,
  createNewAppointmentSuccess: state.appointments.createNewAppointmentSuccess?.data,
  rescheduleAppointmentSuccess: state.appointments.rescheduleAppointmentSuccess?.data,
  rescheduleAppointmentLoading: state.appointments.rescheduleAppointmentLoading,
  rescheduleAppointmentFailure: state.appointments.rescheduleAppointmentFailure?.data,
  cancelAppointmentSuccess: state.appointments.cancelAppointmentSuccess?.data,
  cancelAppointmentLoading: state.appointments.cancelAppointmentLoading,
  cancelAppointmentFailure: state.appointments.cancelAppointmentFailure,
  session: state.users?.session,
  publicContent: state.publicContent.WebArticles?.completeArticles,
  lastPhysicalDate: state.donors?.inquiryDonorSuccess?.data?.lastPhysicalDate,
})

const mapDispatchToProps = (dispatch) => ({
  getSlotsForAppointmentType: (
    forDate,
    fromDateTime,
    toDateTime,
    numberOfChildren,
    centerSfid,
    appointmentType,
    rescheduleId,
    donorSfid
  ) =>
    dispatch(
      centersActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        rescheduleId,
        donorSfid
      )
    ),
  cancelAppointment: (id, cancelledFrom) => dispatch(appointmentsActions.cancelAppointment(id, cancelledFrom)),
  getAllCenters: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  getAppointmentCenter: (sfid) => dispatch(centersActions.getAppointmentCenter(sfid)),
  getUpcomingAppointments: (donorSfid) => dispatch(appointmentsActions.getUpcomingAppointments(donorSfid)),
  getDonorCenterDetails: (searchStr, isDIS8Center, searchType) => dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardAppointmentsSection)
