import React from 'react'

import { Form, Icon, Header, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const ForgotPasswordForm = ({
  onSubmit,
  loading,
  error
}) => {
  const handleSubmit = (values) => {
   onSubmit({username:values.username?.replace(/\s+/g,'')})
  }
  const { t } = useTranslation('ForgotPasswordForm');

  return (
    <div className='ForgotPasswordForm'>
      <Header as='h1' className='MainHeader'>
        {t('forgotPassword')}
      </Header>
      <FinalForm
        onSubmit={handleSubmit}
        render={({ handleSubmit, pristine }) => (
          <Form onSubmit={handleSubmit}>
            <Field name='username'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={46}
                  fieldLabel={t('username*')}
                />
              )}
            </Field>
            <Form.Group>
              <div className='MessageButtonContainer'>
                <p className='FormNote'>
                  <sup>
                    <Icon
                      className='RequiredInfo'
                      size='tiny'
                      name='asterisk'
                    />
                  </sup>
                  {t('requiredField')}
                </p>
              </div>
            </Form.Group>
            {loading && <LoadingState/>}
            {error && error.response && error.response.data && (
              <Message visible warning>
                <p>{error.response.data.message}</p>
              </Message>
            )}
            <Form.Group className='ButtonContainer'>
              <PrimaryButton
                type='submit'
                text={t('submit')}
                width={340}
                height={50}
                fontSize={16}
                classType='Primary'
              />
            </Form.Group>
            <Form.Group className='ButtonContainer'>
              <PrimaryButton
                text={t('cancel')}
                width={340}
                height={50}
                fontSize={16}
                classType='Inverted'
                as={NavLink}
                to='/Login'
              />
            </Form.Group>
          </Form>
        )}
      />
    </div>
  )
}

export default ForgotPasswordForm
