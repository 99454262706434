import React, { useEffect, useState } from 'react'
import { Grid, Visibility } from 'semantic-ui-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
const HugeSelect = importer('Components/Inputs/HugeSelect')

const AppointmentTypeAndPlayroom = ({
  appointment,
  handleAppointment,
  edit,
  defaultAppointmentType,
  donor,
  centerSlots,
  centerSlotsFailure,
  centerSlotsLoading,
  inquiry,
  scrolltoCalendar
}) => {
  const { t, i18n } = useTranslation('AppointmentTypeAndPlayroom','Children');

  const options = [
    { key: 'DONATION', value: 'DONATION', text: t('donation'), disabled: (!donor?.pdn  || (donor?.pdn && !inquiry?.lastPhysicalDate))? true : false },
    { key: 'PHYSICAL', value: 'PHYSICAL', text: t('PhysicalWithDonation'), disabled: false }
  ]

  const playroom = [
    { key: '0', value: 0, text: t('Children:childrenZero') },
    { key: '1', value: 1, text: t('Children:childrenOne') },
    { key: '2', value: 2, text: t('Children:childrenTwo') },
    { key: '3', value: 3, text: t('Children:childrenThree') },
    { key: '4', value: 4, text: t('Children:childrenFour') },
    { key: '5', value: 5, text: t('Children:childrenFive') },
    { key: '6', value: 6, text: t('Children:childrenSix') }
  ]

  const [width, setWidth] = useState('')
  const [isAppointmentChanged, setIsAppointmentChanged] = useState(false)

  const handleUpdate = (e, { calculations }) => setWidth(calculations.width)

  useEffect(() => {
    if (!centerSlotsLoading && isAppointmentChanged) {
      if (centerSlots && centerSlots?.length > 0) {
        scrolltoCalendar();
      }
      setIsAppointmentChanged(false);
    }
  }, [centerSlots])

  useEffect(() => {
    if (!centerSlotsLoading) {
      setIsAppointmentChanged(false);
    }
  }, [centerSlotsLoading])

  const getDonationType = (value) => {
    handleAppointment({
      ...appointment,
      startDateTime: moment(appointment.startDateTime).format('YYYY-MM-DDT00:00:00'),
      appointmentType: value,
      startTime: null
    })

    setIsAppointmentChanged(true);
  }

  const getPlayRoom = (value) => {
    handleAppointment({
      ...appointment,
      startDateTime: moment(appointment.startDateTime).format('YYYY-MM-DDT00:00:00'),
      numberOfChildren: value,
      startTime: null
    })
  }

  useEffect(() => {
    handleAppointment({
      ...appointment,
      centerSfid: donor?.donationCenter
    })
  }, [appointment.appointmentType])

  return (
    <div className='AppointmentTypeAndPlayroom'>
      <Visibility onUpdate={handleUpdate}>
        <Grid columns={1}>
          <Grid.Column computer={8} tablet={8} mobile={16}>
            <div className='HugeSelectContainer'>
              <p className='LabelForSelect'>{t('appointmentType')}</p>
              <HugeSelect
                placeholder={t('select')}
                options={options}
                className={`huge-select ${i18n.language} ${(appointment.appointmentType === 'PHYSICAL' || defaultAppointmentType === 'PHYSICALONLY') ? 'HugeSelectLarge' : 'HugeSelect'}`}
                name={t('donation')}
                handleOnChange={getDonationType}
                defaultValue={edit ? appointment.appointmentType : defaultAppointmentType === 'PHYSICALONLY' ? 'PHYSICAL' : defaultAppointmentType}
                width={256}
              />                  
              {((!donor?.pdn || (donor?.pdn && !inquiry?.lastPhysicalDate)) ?
                <div className="FirstTimeDonorcontainer">
                  <div className="FirstTimeDonortext">
                    <p className='FirstTimeDonorMesssage'>{t('firsttimedonortext')}</p>
                  </div>
                </div>
                : null)}
              {/* *** PD-6838 Move error messages related to the appointment type above the calendar */}
              {centerSlotsFailure &&
                centerSlotsFailure?.code !== "DayIsOutsideSchedulingWindowRule" &&
                  centerSlotsFailure?.code !==
                    "DayIsInPastPotentialAppointmentDateRule" && (
                  <div className="AppointmentFailureContainer">
                    <div className="AppointmentFailure">
                      <p className="AppointmentFailureMesssage">
                        {centerSlotsFailure.message}
                      </p>
                    </div>
                  </div>
                )}
            </div>  
          </Grid.Column>
        </Grid>
      </Visibility>
    </div>
  )
}

export default AppointmentTypeAndPlayroom
