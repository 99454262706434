import React from 'react'
import { useTranslation } from 'react-i18next';
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import { splitCenterName } from '../../../Helpers/Utils'
import moment from 'moment'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const ModifyOrCancelAppointmentModal = ({
  open,
  toggleModal,
  appointment,
  center,
  toggleModifyModal,
  getSlotsForAppointmentType,
  getDonorCentersAll,
  getAllCenters,
  toggleConfirmCancellationModal,
  donor,
  loggedInUser
}) => {
  const { t } = useTranslation('ModifyOrCancelAppointmentModal');
  const handleRescheduleAppointment = async () => {
    await getSlotsForAppointmentType(
      moment.utc(appointment.startDateTime.split('T')[0]).format('YYYY-MM-DDT00:00:00'),
      null,
      null,
      appointment.numberOfChildren || 0,
      appointment.centerSfid,
      appointment.appointmentType,
      appointment.appointmentId,
      donor?.sfid
    )
    getDonorCentersAll && getDonorCentersAll(null, true, 'disDate')
    toggleModal()
    toggleModifyModal()
  }

  return (
    <Modal open={open} onClose={toggleModal} className='CancelAppointmentModal'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as='h2' className='ModalHeader'>
              {t('modifyAppointment')}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={toggleModal} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
          <Header as='h3' className='HeaderText'>
            {t('appointmentDate')} &amp; {t('time')}
          </Header>
          <p className='RegularText'>
          {`${moment.parseZone(appointment.startDateTime).format('ddd')}, ${moment.parseZone(appointment.startDateTime).format('ll')}
            at ${appointment.startTime && appointment.startTime.toLowerCase()}`}
          </p>
          <Header as='h3' className='HeaderText'>
            {t('appointmentType')}
          </Header>
          <p className='RegularText'>{appointment.appointmentType === 'PHYSICAL' ? t('physicalWithDonation') : t('donation') }</p>
          {appointment && appointment.numberOfChildren > 0 &&
            <React.Fragment>
              <Header as='h3' className='HeaderText'>
              {t('playroomReserved')}
              </Header>
              <p className='RegularText'>{t('children')} {appointment.numberOfChildren}</p>
            </React.Fragment>
          }
          <React.Fragment>
            <Header as='h3' className='HeaderText'>
              {loggedInUser?.role === 'Donor'? splitCenterName(appointment.centerName) : appointment.centerName} {t('center')}
            </Header>
          </React.Fragment>
          {center && (
            <p className='RegularText'>
              {center.addressLine1}
              <br />
              {loggedInUser?.role === 'Admin' && `${center.city}, ${center.stateCode} ${center.zipcode}`}
              {loggedInUser?.role === 'Donor' && `${splitCenterName(center.city)}, ${center.stateCode} ${center.zipcode}`}
            </p>
          )}
          <div className='ModalActions'>
            <Grid.Row className='ConfirmContainer'>
              <PrimaryButton
                text={t('rescheduleAppointment')}
                width={270}
                height={50}
                fontSize={16}
                disabled={!center}
                classType='Primary'
                onClick={handleRescheduleAppointment}
              />
            </Grid.Row>
            <Grid.Row>
              <PrimaryButton
                text={t('cancelAppointment')}
                width={270}
                height={50}
                fontSize={16}
                classType='Inverted'
                onClick={() => {
                  toggleConfirmCancellationModal()
                  toggleModal()
                }}
              />
            </Grid.Row>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ModifyOrCancelAppointmentModal
