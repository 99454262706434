
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { Button } from "semantic-ui-react";
import importer from '@takedapdt/biolife-core/src/importer'
const StartupActions = importer('Stores/Startup/Actions')
const changeLanguageHelper = importer('Helpers/changeLanguage')

import './styles.scss'

const MobileLanguageSelector = ({ initializeApp }) => {
    const { t, i18n } = useTranslation('NavBar');
    const [isEnglish, setisEnglish] = useState(i18n.language === 'en-US' ? true : false)

    const onclick = (language) => {
        changeLanguageHelper(language)
        initializeApp()
        setisEnglish(language === 'en-US' ? true : false)
    }
    return (

        <Button.Group widths='2' size='large' className='buttonGroup'>
            <Button primary={isEnglish} active={isEnglish} onClick={() => onclick('en-US')} >{t('english')}</Button>
            <Button primary={!isEnglish} active={!isEnglish} onClick={() => onclick('es-MX')}>{t('spanish')}</Button>
        </Button.Group>

    )
}

const mapDispatchToProps = dispatch => ({
    initializeApp: () => dispatch(StartupActions.initializeApp()),
  
  })

export default connect(null, mapDispatchToProps)(MobileLanguageSelector)
