import React from 'react'
import { useTranslation } from 'react-i18next';
import { Header, Container, Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { splitCenterName } from "../../../Helpers/Utils"
import moment from 'moment'
import './styles.scss'
/* */
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FaiureState = importer('Components/Dashboard/StateErrorContainer')
const NoDataState = importer('Components/Dashboard/StateNoDataContainer')


const DashboardDonationsSection = ({ inquiry, center, error, loading, loggedInUser }) => {
  const { t } = useTranslation('DashboardDonationsSection');
  const getTwoDonations = () => {
    const {
      data: { topTwoDonations }
    } = inquiry
    return topTwoDonations
  }
  
  return (
    <Container fluid className='DonationSection'>
      <Header as='h4' className='DonationHeader'>
        {t('lastTwoDonations')}
      </Header>
      {loading && <LoadingState />}
      {error && <FaiureState />}
      {!center && !inquiry && <NoDataState />}
      {!loading &&
        !error &&
        inquiry &&
        center &&
        getTwoDonations().map((donation, index) => (
          <Grid.Row key={index} className='AppointmentRow' style={{ borderBottom: '1px solid #DDDDDD' }}>
            <Grid columns={2}>
              <Grid.Column className='DonationDate'>{moment(donation.donationDate).format('MM/DD/YYYY')}</Grid.Column>
              <Grid.Column className='DonationLocation'>{loggedInUser.role === 'Admin' ? `${donation.centerName}` : `${splitCenterName(donation.centerName)}`}</Grid.Column>
            </Grid>
          </Grid.Row>
        ))}
    </Container>
  )
}

export default DashboardDonationsSection
