

export const careersData = [
  {
    id: 1,
    name: 'careersWhatWeOffer',
    to: 'https://www.takedajobs.com/what-we-offer',
    page: 'what-we-offer',
    external: true
  },
  {
    id: 2,
    name: 'careersWhatWeBelieve',
    to: 'https://www.takedajobs.com/what-we-believe',
    page: 'what-we-believe',
    external: true
  },
  {
    id: 3,
    name: 'careersAwardsAndRecognition',
    to: 'https://www.takedajobs.com/awards',
    page: 'awards-&-recognition',
    external: true
  },
  {
    id: 4,
    name: 'careersDiversityAndInclusion',
    to: 'https://www.takeda.com/corporate-responsibility/labor/',
    page: 'diversity-&-inclusion',
    external: true
  },
  {
    id: 5,
    name: 'careersBenefits',
    to: 'https://www.prod.biolifeplasma.com/downloads/NewHireBenefitSummary.pdf',
    page: 'benefits',
    external: true
  },
  {
    id: 6,
    name: 'careersManagementTraineeProgram',
    to: 'https://www.prod.biolifeplasma.com/downloads/management-trainee-program.pdf',
    page: 'management-trainee-program',
    external: true
  }
]

export const messages = [
  {
    type: 'alert',
    title: 'messageClosingsAndglobalalerts',
    text: 'messageCoronavirusCovid'
  },
  {
    type: 'announce',
    title: 'messageOpenhouseAndglobalAnnoucement',
    text: "messageHavequestionsaboutBioLife"
  },
  {
    type: 'promo',
    title: 'messageOffersPromos',
    text: 'messageRegistertomakeyourfirst'
  }
]


export const locations = [
  {
    key: 'AL',
    state: 'Alabama',
    facilities: [
      {
        name: 'Birmingham',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Mobile',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'AZ',
    state: 'Arizona',
    facilities: [
      {
        name: 'Glendale',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Mesa',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Phoenix',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'empe',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Tucson',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'CO',
    state: 'Colorado',
    facilities: [
      {
        name: 'Colorado Springs',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Longmont',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Loveland',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'FL',
    state: 'Florida',
    facilities: [
      {
        name: 'Casselberry',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Jacksonville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Kissimmee',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Lakeland',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Orlando',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },

  {
    key: 'GA',
    state: 'Georgia',
    facilities: [
      {
        name: 'Augusta',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Columbus',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Douglasville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'McDonough',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Morrow',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Savannah',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Snellville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Warner Robins',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'ID',
    state: 'Idaho',
    facilities: [
      {
        name: 'Ammon',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Boise',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Hayden',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Nampa',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'IL',
    state: 'Illinois',
    facilities: [
      {
        name: 'Addison',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Bolingbrook',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'DeKalb',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Villa Park',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'IN',
    state: 'Indiana',
    facilities: [
      {
        name: 'Bloomington',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Elkhart',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Fort Wayne - Getz',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Ft. Wayne - Coldwater',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Greendwood',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Kokomo',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Villa Park',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },

  {
    key: 'IA',
    state: 'Iowa',
    facilities: [
      {
        name: 'Ames',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Ankeny',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Cedar Falls',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Cedar Rapids - Edgewood',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Cedar Rapids - Rockwell',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Coralville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Dubuque',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Siox City',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Waterloo',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'West Des Moines',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'LA',
    state: 'Louisiana',
    facilities: [
      {
        name: 'Baton Rouge',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },

  {
    key: 'MD',
    state: 'Maryland',
    facilities: [
      {
        name: 'Catonsville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Riverdale Park',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },

  {
    key: 'MI',
    state: 'Michigan',
    facilities: [
      {
        name: 'Grand Rapids',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Grandville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Marquette',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Mt. Pleasant',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Walker',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Warren',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'MN',
    state: 'Minnesota',
    facilities: [
      {
        name: 'Manakato',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Maple Grove',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Moorhead',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Mounds View',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'St. Clouds',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Waite Park',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'MO',
    state: 'Missouri',
    facilities: [
      {
        name: 'Independence',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Joplin',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Kansas City - Liberty',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Kansas City - Tiffany Springs',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Saint Ann',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Saint Louis',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Springfield - Erie',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Springfield - McCurry',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'St. Peters',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'MT',
    state: 'Montana',
    facilities: [
      {
        name: 'Billings',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Great Falls',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Missoula',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'NV',
    state: 'Nevada',
    facilities: [
      {
        name: 'Henderson',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: ' North Las Vegas',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'NC',
    state: 'North Carolina',
    facilities: [
      {
        name: 'Charolette',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Greensboro',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'ND',
    state: 'North Dakota',
    facilities: [
      {
        name: 'Bismarck',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Fargo',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Grand Forks',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'West Fargo',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'OH',
    state: 'Ohio',
    facilities: [
      {
        name: 'Findlay',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Lima',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Mentor',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Middleton',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'OK',
    state: 'Oklahoma',
    facilities: [
      {
        name: 'Broken Arrow',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Oklahoma City',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'PA',
    state: 'Pennsylvania',
    facilities: [
      {
        name: 'State College',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'SC',
    state: 'South Carolina',
    facilities: [
      {
        name: 'Greenville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'TN',
    state: 'Tennessee',
    facilities: [
      {
        name: 'Cordova',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Mount Juliet',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'TX',
    state: 'Texas',
    facilities: [
      {
        name: 'Arlington',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Austin',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Burleson',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Denton',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Houston - FM',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Houston - Richmond',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Kyle',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Lewisville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'North Richland Hills',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'San Antonio',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Tyler',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Universal City',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'UT',
    state: 'Utah',

    facilities: [
      {
        name: ' American Fork',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Layton',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Riverton',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'St. George',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'St. George - Dixie',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'VA',
    state: 'Virginia',
    facilities: [
      {
        name: 'Harrisonburg',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'WV',
    state: 'West Virginia',
    facilities: [
      {
        name: 'Clarksburg',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'Wi',
    state: 'Wisconsin',
    facilities: [
      {
        name: 'Appleton',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Appleton - Eisen',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Eau Claire',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Fond du Lac',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Green Bay - Finger',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Green Bay - Isbell',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Janesville',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Onalasaka',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Oshkosh',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Sheboygan',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Stevens Point',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Wausau',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  },
  {
    key: 'WY',
    state: 'Wyoming',
    facilities: [
      {
        name: 'Independence',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Joplin',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Kansas City - Liberty',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Kansas City - Tiffany Springs',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Saint Ann',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Springfield - Erie',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'Springfield - McCurry',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      },
      {
        name: 'St. Peters',
        address: '8015 Bellevue Parkway Cordova, TN 38016',
        phone: '(901) 248-0251'
      }
    ]
  }
]
