import moment from 'moment'
import i18n from '../i18n'
export const getOperationHours = (operation) => {
  if (operation.isClosed) {
    return i18n.t('common:closed')
  }
  return `${moment(operation.openingTime, 'h:mm:ss').format('hh:mm A')} - ${moment(
    operation.closingTime,
    'h:mm:ss'
  ).format('hh:mm A')}`
}

export const getPlaroomHours = (operation) => {
  if (operation.isPlayroomClosed) {
    return i18n.t('common:closed')
  }
  return `${moment(operation.playroomOpeningTime, 'h:mm:ss').format('hh:mm A')} - ${moment(
    operation.playroomClosingTime,
    'h:mm:ss'
  ).format('hh:mm A')}`
}

export const numberWithCommas = (x) =>
    x.toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')

export const chunkArray = (myArray, chunkSize) => {
  let index = 0
  const arrayLength = myArray.length
  const tempArray = []

  for (index = 0; index < arrayLength; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize)

    tempArray.push(myChunk)
  }

  return tempArray
}

export const formatPhoneNumber = (phoneNumberString) => {
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export const getAge = (dateString) => {
  var today = new Date()
  var birthDate = new Date(dateString)
  var age = today.getFullYear() - birthDate.getFullYear()
  var m = today.getMonth() - birthDate.getMonth()
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--
  }
  return age
}

export const setErrorMessageTitle = (msg) => {
  if (msg){
    let titleIndex =msg.indexOf('.')
    const title = msg.slice(0, titleIndex)
    const message = msg.slice(titleIndex+2)
    return {title, message}
  }else return {title : '', message: ''}
}

 /**
  Utility function to filterout nomogram centers
  param {Array of Object} centers
  param {Object} donor (Provides data of default center of the loggedin user)
  param {Object} loggedInUser (Provides data on user's role)
  return {Array of Object} filtered centers
  */
export const filterNonNomogramCenters = (centers, donor,loggedInUser) => {
    let filterData = []
    if(loggedInUser?.role === 'Admin'){
      filterData = centers
      return filterData
    }
    else{
      centers?.forEach(function(obj) {
          if((donor?.defaultCenter?.centerNum === obj?.centerNum && donor?.defaultCenter?.sfid === obj?.sfid) ||  
          (obj?.isCenterHiddenFromDonors === null && donor?.defaultCenter?.centerNum !== obj?.centerNum)){
            if(obj.isCenterHiddenFromDonors === 'yes'){
              obj.name = splitCenterName(obj.name)
              filterData.push(obj)
            }else {
              filterData.push(obj)
            }
          }
      })
    }
    return filterData
  }

   /**
  Utility function to filterout public pages
  param {Array of Object} centers
  return {Array of Object} filtered centers
  */

  export const filterNonNomogramCentersPublicPage = (centers) => {
    let filterData = []
      centers?.forEach(function(obj) {
          if(obj?.isCenterHiddenFromDonors === null){
              filterData.push(obj)
          }
      })
    return filterData
  }

  /**
  Utility function to split the centername at '-' 
  param {String} centerName
  return {String} centerName
  */
  
  export const splitCenterName = (centerName) => {
   return centerName?.split('--')[0]
  }

  export const mapOptionsByState = (list, centerOpsDonorSearch, loggedInUser) => {

    return new Promise((resolve, reject) => {
      try {
        const states = []
        if (list && list.length) {
          if(centerOpsDonorSearch){
            return list.forEach(({details, donationCenter},index) => {
              details.name = loggedInUser?.role === 'Admin' ? details?.name : splitCenterName(details?.name);
              const foundIndex = states.findIndex(i => i.state === details.state)
              if (foundIndex !== -1) { states[foundIndex].facilities.push({
                  key: donationCenter,
                  zipCode: details.zipcode,
                  value: donationCenter,
                  name: details.name ,
                  text: `${details?.name} Center ${details?.zipcode}`
                }) 
              } 
              else { 
                  states.push({ state: details.state, stateCode: details.stateCode, facilities: [{
                  key: donationCenter,
                  zipCode: details.zipcode,
                  value: donationCenter,
                  name: details.name ,
                  text: `${details?.name} Center ${details?.zipcode}`
                }]}) 
              }
              if (index === list.length - 1) { resolve(states) }
            })
          }
          return list.forEach((item, index) => {
            item.name = loggedInUser?.role === 'Admin' ? item?.name : splitCenterName(item?.name);
            const foundIndex = states.findIndex(i => i.state === item.state)
            if (foundIndex !== -1) { states[foundIndex].facilities.push({
                key: item.sfid,
                zipCode: item.zipcode,
                value: item.sfid,
                name: item.name,
                text: `${item?.name} Center ${item?.zipcode}`,
                address: item.addressLine1,
                centerName:item.name,
                city:item.city
              }) 
            } 
            else { 
                states.push({ state: item.state, stateCode: item.stateCode, facilities: [{
                key: item.sfid,
                zipCode: item.zipcode,
                value: item.sfid,
                name: item.name,
                text: `${item?.name} Center ${item?.zipcode}`,
                address: item.addressLine1,
                centerName:item.name,
                city:item.city
              }]}) 
            }
            if (index === list.length - 1) { resolve(states) }
          })
        } else resolve(list)
      } catch (error) {
        reject(error)
      }
    })
  }
export const sortCentersAlphabetically = (data) => {
  data?.sort((a,b)=> a.state<b.state ? -1:1)   //sorting states alphabetically
  data?.map(({facilities}) => facilities.sort((a,b)=> a.state<b.state ? -1:1))  //sorting centers alphabetically
  return data
}

// Get cookies from browser cookie storage
// const getCookies = () => {
//   let cookies = [];
//   const Storedcookiearr = document.cookie.split(';')
//   Storedcookiearr.forEach((item) => {
//     let [k,v] = item.split('=')
//     cookies.push(k.trim())
//   })
//   return cookies
// }

// Triger the Privecy Preference cookie settings if not accepted or rejected otherwise redirect to registration page
// export const openCookieSettingsModal = (history) => {
//   if(OneTrust) {
//     const cookies = getCookies()
//     if(cookies.indexOf('OptanonAlertBoxClosed') === -1) {
//       OneTrust.ToggleInfoDisplay(true)
//     } else history.push('/registration')
//   } else {
//     history.push('/registration')
//   }
// }

export const isMobileWeb = () => {
  if(window.navigator.userAgent.toLowerCase().indexOf("android") > -1 || window.navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
    return true
  }
  else return false
}

// Smooth scroll to the top of the page
export const ScrollToTop = () => {
  var isSafari = window.safari !== undefined;
  if(isSafari){
    // Safari does not support smooth scroll behaviour.. this scrollIntoView method is tested on Safari
    document.getElementById('react').scrollIntoView();
  } else {
  window.scrollTo({top:0,behavior:"smooth"})
  }
}