import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Grid, Icon, Message, Modal, Header, Button } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import i18n from "../../../i18n"

import importer from '@takedapdt/biolife-core/src/importer'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'

/* */
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
// const FaiureState = importer('Components/Dashboard/StateErrorContainer')
const NoDataState = importer('Components/Dashboard/StateNoDataContainer')
const WirecardActions = importer('Stores/Wirecard/Actions')
const DebitCardHeader = importer('Components/Dashboard/DebitCardHeader')
const UnavailableDebitCard = importer('Components/Dashboard/DashboardUnavailableDebitCard')
const NearbyCentersSection = importer('Components/Dashboard/NearbyCentersSection')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const BiolifePaymentCardsModal = importer("Components/Dashboard/BiolifePaymentCardsModal")

const DashboardDebitCard = ({
  wirecard,
  wirecardError,
  wirecardLoading,
  donor,
  getWirecardInfoHandler,
  centerStatus,
  nearbyCenters
}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [openWarningMessage, setOpenWarningMessage] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null);
  const { t } = useTranslation('DashboardDebitCard');
  const [filterOn, setFilterOn] = useState(false)
  const [filteredTotal, setFilteredTotal] = useState(false)
  const [openBiolifePaymentCardsModal, setOpenBiolifePaymentCardsModal] = useState(false);

  //toggles the 'Find in-Network ATM Locations' Modal
  const toggleBiolifePaymentCardsModal = () => {
    setOpenBiolifePaymentCardsModal(!openBiolifePaymentCardsModal);
  };

  const filterByDate = (data, start, end) => {
    setFilterOn(true)
    const filteredData = data && data.filter((item) => {
      const startDate = Number(
        moment
          .utc(new Date(start))
          .startOf('day')
          .format('X')
      )
      const endDate = Number(
        moment
          .utc(new Date(end))
          .endOf('day')
          .format('X')
      )
      const itemDate = Number(moment.utc(item.transactionDate).format('X'))

      return itemDate >= startDate && itemDate <= endDate
    })
    setFilteredTotal(filteredData)
  }

  const handleStartDateChange = (date) => {
      setStartDate(date)
      setErrorMessage(null)
      setEndDate(null)
      if (endDate && date && date <= endDate) {
      const mtStartDate = moment(date).startOf('day')
      const mtEndDate = moment(endDate).endOf('day')
      if (mtStartDate.diff(endDate, 'year') <= 1) {
        getWirecardInfoHandler(
          donor?.pdn,
          mtStartDate.format('YYYY-MM-DD'),
          mtEndDate.format('YYYY-MM-DD')
        )
      }
    }
  }

const handleEndDateChange = (date) => {
    if (startDate && date && date >= startDate) {
      setEndDate(date);
      setErrorMessage(null);
      const mtStartDate = moment(startDate).startOf('day');
      const mtEndDate = moment(date).endOf('day');
      if (mtStartDate.diff(mtEndDate, 'year') <= 1) {
        getWirecardInfoHandler(
          donor?.pdn,
          mtStartDate.format('YYYY-MM-DD'),
          mtEndDate.format('YYYY-MM-DD')
        );
      }
    } else {
      setErrorMessage(t("invalidDateRange"));
      setEndDate(null);
    }
  }
  const clearFilter = () => {
    if(startDate !== null || endDate !== null) {
      setStartDate(null)
      setEndDate(null)
      setFilterOn(false)
      getWirecardInfoHandler(donor?.pdn, null, null)
    }
  }

  if (centerStatus && centerStatus !== 'Selected') {
    return <NearbyCentersSection centerStatus={centerStatus} nearbyCenters={nearbyCenters} />
  }

  if (wirecardError || !donor?.pdn) {
    return <UnavailableDebitCard />
  }

  if (wirecardLoading) {
    return <LoadingState />
  }

  if (isEmpty(wirecard)) {
    return <NoDataState />
  }
  
  const EndDatePickerClass = i18n.language === "es-MX" ? "spanishEndDatePickerClass " : "DatePickerClass";
  const StartDatePickerClass = i18n.language === "es-MX" ? "spanishStartDatePickerClass " : "DatePickerClass";
  return (
    <div className='DashboardDebitCard'>
      <DebitCardHeader wirecard={wirecard} />
      <Grid className='DebitCardFilter'>
        <Grid.Row>
          <span className='FilterByDateText'>{t('filterBtDate')}</span> ( {t('last30Transactions')} )
        </Grid.Row>
        <Grid.Row className='GridBorder'>
          <Grid.Row>
            <Icon name='calendar alternate outline' className='CalendarIcon' />
            <DatePicker selected={startDate} onChange={handleStartDateChange} startDate={startDate} placeholderText={t('StartDate')} className={StartDatePickerClass}/>
          </Grid.Row>
          <Grid.Row>
            <span className='ToText'>{t('to')}</span>
          </Grid.Row>
          <Grid.Row>
            <Icon name='calendar alternate outline' className='CalendarIcon' />
            <DatePicker selected={endDate} onChange={handleEndDateChange} startDate={startDate} selectsEnd placeholderText={t('EndDate')} className={EndDatePickerClass} dayClassName={date => (startDate && date < startDate ? 'disabled' : null)}/>
          </Grid.Row>
          <Grid.Row>
            <Icon name='x' className={(startDate !== null || endDate !== null) ? 'xIcon' : 'xIconDisabled'} />
            <span className={(startDate !== null || endDate !== null) ? 'ClearFilterText' : 'ClearFilterTextDisabled'} onClick={clearFilter}>
              {t('clearFilter')}
            </span>
          </Grid.Row>
        </Grid.Row>
      </Grid>
      {errorMessage && (
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column className="EndDateErrorMessage">
              <Message negative>
                <p>{t("invalidDateRange")}</p>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
      {!isEmpty(wirecard && wirecard.journal)
        && ((!startDate || !endDate) || moment(endDate).diff(moment(startDate), 'year', true) <= 1) && (
        <Grid className='TransactionEntries'>
          <Grid.Row columns={3} className='EntriesHeader'>
            <Grid.Column>{t('date')}</Grid.Column>
            <Grid.Column>{t('type')}</Grid.Column>
            <Grid.Column>{t('amountUSD')}</Grid.Column>
          </Grid.Row>
          {wirecard.journal && wirecard.journal.map((entry, index) => (
            <Grid.Row
              key={index}
              columns={3}
              className={wirecard && wirecard.length - 1 !== index ? 'EntriesContent' : 'EntriesContentNoBorder'}>
              <Grid.Column>{moment.utc(entry.transactionDate).format('MM/DD/YYYY')}</Grid.Column>
              <Grid.Column style={{ fontWeight: 'bold' }}>{entry.transactionType === "Receive $" ? t('receive') : entry.transactionType}</Grid.Column>
              <Grid.Column className='EntryAmount'>
                {entry.transactionAmount != null && entry.transactionAmount >= 0 ? (
                  <span>${Math.abs(entry.transactionAmount).toFixed(2)}</span>
                ) : (
                  <span>-${Math.abs(entry.transactionAmount).toFixed(2)}</span>
                )}
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      )}
      {(moment(endDate).diff(moment(startDate), 'year') >= 1) &&
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message negative>
                <p>{t('selecteddaterange')}</p>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      {isEmpty(wirecard && wirecard.journal) && (moment(endDate).diff(moment(startDate), 'year') < 1) &&
        <Grid>
          <Grid.Row columns={1}>
            <Grid.Column>
              <Message negative>
                <p>{t('noMatchingTransactionsFound')}</p>
              </Message>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      }
      {wirecardLoading &&
        <LoadingState />
      }
      <Grid className={isEmpty(wirecard && wirecard.journal) ? 'Pagination NoData' : 'Pagination'}>
        <Grid.Row className='FindLocations'>
          <PrimaryButton
            text={t('findInNetworkATMLocation')}
            // width='33%'
            classType='Primary'
            fontSize='16px'
            onClick={() => {
              toggleBiolifePaymentCardsModal();
              }
            }
          />
        </Grid.Row>
      </Grid>

      {/* Pop-up modal for 'Find in-Network ATM Locations' */}
      <BiolifePaymentCardsModal
        openBiolifePaymentCardsModal={openBiolifePaymentCardsModal}
        toggleBiolifePaymentCardsModal={toggleBiolifePaymentCardsModal}
        headerText={t('findInNetworkATMLocation')}
        bodyTextLine1={t('youAreAboutToLeave')}
        link= 'http://www.geoserve.com/forms/catm.htm'
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  wirecard: state.wirecard.getWirecardInfoSuccess?.data,
  wirecardError: state.wirecard.getWirecardInfoFailure,
  wirecardLoading: state.wirecard.getWirecardInfoLoading,
  centerStatus: state.centers.centerStatus,
  donor: state.donors.donors?.data,
  nearbyCenters: state.centers.nearbyCenters,
})
const mapDispatchToProps = dispatch => ({
  getWirecardInfoHandler: (pdn, startDate, endDate) => dispatch(WirecardActions.getWirecardInfo(pdn, startDate, endDate)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardDebitCard)
