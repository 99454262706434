import React from 'react'
import { Container, Header, Image } from 'semantic-ui-react'
import HeroImage from '../../../assets/media/images/hero-image-headquarters.jpg'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const PasswordLinkExpired = () => {
  const { t } = useTranslation('PasswordLinkExpired');
  return (
    <div className='PasswordLinkExpired'>
      <div className='HeroContent'>
        <div className='HeroImageContainer'>
        <Image src={HeroImage} alt='Hero Image' />
        </div>
        <Container>
          <div className='PasswordLinkExpiredTextContainer'>
            <div className='SuccessContainer'>
            <Header as='h1' className='PasswordLinkExpiredHeader'>
               {t('passwordResetLinkExpired')}
            </Header>
            <p className='PasswordLinkExpiredRegularText'>
                {t('pleaseclickForgotPassword')}
            </p>
            </div>
            <PrimaryButton 
              text={t('ForgotPassword')}
              width={226}
              height={50}
              as={NavLink}
              to='/donor-forgot-password'
              fontSize={16}
              lineHeight='25px'
              classType='Primary'
            />
          </div>
        </Container>
      </div>
    </div>
  )
}

export default PasswordLinkExpired
