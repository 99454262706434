import React, { useEffect, useState } from 'react'
import { Grid, Label, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import locationImage from '../../../../assets/media/icons/icon-nav-locations.png'
import { mapOptionsByState, sortCentersAlphabetically, splitCenterName, filterNonNomogramCenters } from "../../../Helpers/Utils"
import { isEmpty } from 'lodash'
import { connect } from 'react-redux'
import './styles.scss'
const centersActions = importer('Stores/Centers/Actions')
import importer from '@takedapdt/biolife-core/src/importer'

const SelectSearch = importer('Components/Inputs/SelectSearch')

const CenterSelection = ({ appointment,loggedInUser, handleAppointment, edit, centers, donor, center, setAppointment, getAppointmentCenter }) => {
  const { t } = useTranslation('CenterSelection');
  const [defaultCenterName, setDefaultCenterName] = useState(center && loggedInUser.role === 'Admin' ? center?.name : splitCenterName(center?.name))
  const preparedOptions = async () => {
    const filteredData = filterNonNomogramCenters(centers,donor,loggedInUser)
    const res = await mapOptionsByState(filteredData,false,loggedInUser)
    const data = sortCentersAlphabetically(res)
    return data
  }
  const [centerOptions, setCenterOptions] = useState([])

  useEffect(() => {
    preparedOptions()
    .then(res => setCenterOptions(res))
  }, [centers])

  const handleSelection = (value) => {
    getAppointmentCenter(value.value)
    const selectedCenter = value ? `${value?.name} Center` : ''
    handleAppointment({
      ...appointment,
      center: selectedCenter,
      centerSfid: value.value,
      centerCity: value.city,
      centerName: value.centerName,
      time: null,
      startTime: null
    })
  }

  useEffect(() => {
    setDefaultCenterName(center && loggedInUser.role === 'Admin' ? center?.name : splitCenterName(center?.name))
    handleAppointment({ 
      ...appointment,
      center: center && loggedInUser.role === 'Admin'  ? `${center?.name} ${t('center')}` :  `${splitCenterName(center?.name)} ${t('center')}`,
      centerSfid: center ? center.sfid : '',
      time: null
    })
  },[])

  useEffect(() => {
    setDefaultCenterName(center && loggedInUser.role === 'Admin' ? center?.name : splitCenterName(center?.name))
  },[center])

  return (
    <div className='CenterSelection'>
      {!isEmpty(centers) && (
        <Grid columns={2}>
          <Grid.Column computer={5} tablet={3} mobile={3} textAlign='right' className='CenterChoosenIcon'>
            <Image src={locationImage} alt='Center' inline />
          </Grid.Column>
          <Grid.Column computer={11} tablet={13} mobile={13}>
            <>
              <Label className='ChooseCenter' as='p'>
                {t('yourchosendonationcenter')} {' '}
                {!edit && (
                <>
                <span className='SelectedCenter'>{defaultCenterName} {t('center')}</span>
                <p className='Notification'>{t('theAbilitytochangecenters')}</p>
                </>
                )}
              </Label>

              {edit && (
                  <SelectSearch
                    options={centerOptions}
                    centers={filterNonNomogramCenters(centers,donor,loggedInUser)}
                    placeholderVal={t('enterStateOrCenterName')}
                    handleOnChange={handleSelection}
                    defaultValue={defaultCenterName}
                    centerID={appointment?.centerSfid || donor?.defaultCenter?.sfid}
                    allowZipcodeSearch={appointment?.appointmentType==='DONATION'?true:false}
                  />
              )}
            </>
          </Grid.Column>
          {/* {edit && (
            <Grid.Column width={16} style={{ padding: 0 }}>
              <p className='Notification' style={{ textAlign: 'center', marginTop: -10 }}>
                To change your donation center, please call your center at (901) 248-0251.
              </p>
            </Grid.Column>
          )} */}
        </Grid>
      )}
    </div>
  )
}
const mapStateToProps = (state) => ({
  loggedInUser: state?.users?.user
})

const mapDispatchToProps = (dispatch) => ({
  getAppointmentCenter: (sfid) => dispatch(centersActions.getAppointmentCenter(sfid)),

})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CenterSelection)
