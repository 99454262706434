import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Grid } from 'semantic-ui-react'
import './styles.scss'
import { useTranslation } from 'react-i18next'
import importer from '@takedapdt/biolife-core/src/importer'
const DonorSearchForm = importer('Components/Forms/DonorSearchForm')
const DonationCenter = importer('Components/DonorSearch/DonationCenter')
const SearchResults = importer('Components/DonorSearch/SearchResults')
const DonorDetails = importer('Components/DonorSearch/DonorDetails')
const Messages = importer('Components/DonorSearch/Messages')
const centersActions = importer('Stores/Centers/Actions')
const donorsActions = importer('Stores/Donors/Actions')

const DonorSearch = props => {
  const [isOpen, setIsOpen] = useState(false)
  const [showResults, setShowResults] = useState(false)
  const [showSelectedDonorDetails, setShowSelectedDonorDetails] = useState(false)
  const [selectedDonorDetails, setSelectedDonorDetails] = useState({})
  const [params, setParams] = useState({ PER_PAGE: 10 })
  const [page, setPage] = useState(1)
  const PER_PAGE = 10
  const [totalPages, setTotalPages] = useState(0)
  const totalRecords = props.donors && props.donors.totalRecords
  const donorsList = props.donors && props.donors.donors.length > 0 ? props.donors.donors : []
  const {t, i18n} = useTranslation('')

  useEffect(() => {
    if (totalRecords && totalRecords > 0) {
      const total = Math.ceil(totalRecords / PER_PAGE)
      setTotalPages(total)
      setParams({ ...params, totalPages })
    }
  }, [donorsList])
  useEffect(() => {
    props.getCenter(props.user.defaultCenter.sfid)
  }, [])


  const handleSubmitDonorSearch = (values, page = 1) => {
    setShowSelectedDonorDetails(false)
    setIsOpen(false)
    setShowResults(true)
    setParams({
      lastName: values.lastName,
      firstName: values.firstName,
      pdn: values.pdn,
      email: values.email,
      phoneNumber: values.phoneNumber,
      currentPage: page,
      pageSize: PER_PAGE
    })
    props.searchForDonor({
      lastName: values.lastName,
      firstName: values.firstName,
      pdn: values.pdn,
      email: values.email,
      phoneNumber: values.phoneNumber,
      currentPage: page,
      pageSize: PER_PAGE
    })
  }

  const handleChangePage = (_, { activePage }) => {
    setParams({ ...params, page: activePage })
    setPage(activePage)
    props.searchForDonor({
      lastName: params.lastName,
      firstName: params.firstName,
      pdn: params.pdn,
      email: params.email,
      phoneNumber: params.phoneNumber,
      currentPage: activePage,
      pageSize: PER_PAGE
    })
  }

  const handleClearDonorSearch = () => {
    setIsOpen(false)
    setShowResults(false)
    props.getCenter(props.user.defaultCenter.sfid)
  }

  const handleBackToSearch = () => {
    setShowSelectedDonorDetails(false)
  }

  const handleSelectDonor = donorDetails => {
    if (donorDetails.id) {
      props.getDonors(donorDetails.id)
    }
    if (donorDetails.donationCenter) {
      props.getAllCenters(donorDetails.donationCenter)
    }
    setShowSelectedDonorDetails(true)
    setSelectedDonorDetails(donorDetails)
  }

  const renderResultsContent = () => {
    if (showSelectedDonorDetails) {
      return (
        <DonorDetails
          selectedDonor={selectedDonorDetails}
          handleBackToSearch={handleBackToSearch}
          donorLoading={props.donorLoading}
        />
      )
    }
    return (
      <SearchResults
        donors={props.donors}
        loading={props.searchLoading}
        onSelectDonor={handleSelectDonor}
        onPageChange={handleChangePage}
        setParams={setParams}
        params={params}
        totalPages={totalPages}
        page={page}
      />
    )
  }

  return (
    <Container fluid className='DonorSearchContainer'>
      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column mobile={16} tablet={5} computer={4}>
            <DonorSearchForm
              loading={props.searchLoading}
              submitDonorSearch={handleSubmitDonorSearch}
              clearDonorSearch={handleClearDonorSearch}
            />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={11} computer={12}>
            {showResults ? (
              <Grid.Row>{renderResultsContent()}</Grid.Row>
            ) : (
              <Grid.Row>
                <DonationCenter
                  loading={props.donorLoading}
                  center={props.center}
                  isOpen={isOpen}
                  setIsOpen={setIsOpen}
                />
                <Messages />
              </Grid.Row>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  )
}

const mapStateToProps = ({ users, donors, centers }) => ({
  user: users.loginHerokuSuccess,
  donors: donors.searchForDonorSuccess?.data,
  searchLoading: donors.searchForDonorLoading,
  donor: donors.donors?.data,
  donorLoading: donors?.getDonorsLoading,
  center: centers.getAllCentersSuccess?.data,
  centerLoading: centers.getAllCentersLoading,
  allCenters: centers.getDonorCentersAllSuccess?.data,
  centers: centers.donorCenters?.data,
  getDonorsSuccess: donors?.getDonorsSuccess
})

const mapDispatchToProps = dispatch => ({
  getDonors: id => dispatch(donorsActions.getDonors(id)),
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
  getCenter: sfid => dispatch(centersActions.getAllCenters(sfid)),
  getAllCenters: searchStr => dispatch(centersActions.getDonorCentersAll(searchStr)),
  searchForDonor: searchParams => dispatch(donorsActions.searchForDonor(searchParams))
})

export default connect(mapStateToProps, mapDispatchToProps)(DonorSearch)
