import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react'
class index extends Component {
  render () {
    const { name,t } = this.props
    return (
      <Message>
        {name && <Message.Header>{name}</Message.Header>}
        <p>{t('noData')} </p>
      </Message>
    )
  }
}

export default withTranslation('StateNoDataContainer')(index)