import React, { useState, useEffect } from 'react'

import { Form, Icon, Modal, Loader, Header } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import validate from './validate'

import importer from '@takedapdt/biolife-core/src/importer'

const UserActions = importer('Stores/Users/Actions')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const FailureState = importer('Components/Dashboard/StateErrorContainer')

const MergeDonorAccountForm = ({
  donor,
  donorGlobalState,
  mergeAccountSetting,
  mergeAccountSettingLoading,
  mergeAccountSettingSuccess,
  mergeAccountSettingFailure
}) => {
  const { t } = useTranslation('MergeDonorAccountForm');

  const [isSubmited, setIsSubmited] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [currentEmail, setCurrentEmail] = useState('')
  const [newEmail, setNewEmail] = useState('')
  const [confirmEmail, setConfirmEmail] = useState('')
  const toggleModal = () => setOpenModal(!openModal)
  const onSubmit = (values,form) => {
    setNewEmail(values.newEmail);
    setCurrentEmail(values.currentEmail);
    setConfirmEmail(values.confirmEmail);
    setIsSubmited(true)
    mergeAccountSetting({
       ...values,
       forceMerge: false
     })
     form.resetFieldState('newEmail')
      form.resetFieldState('confirmEmail')
  }

  useEffect(() => {
    if (!openModal && mergeAccountSettingFailure?.code && (mergeAccountSettingFailure?.code === 'NewEmailExistsInHeroku' || mergeAccountSettingFailure?.code === 'DonorWithPDNExists')) {
      toggleModal();
    }
  }, [mergeAccountSettingFailure])

  useEffect(() => {
   setOpenModal(false);
  }, [])

 return (
    <div className='MergeEmailForm MergeAccount'>
      {mergeAccountSettingSuccess && isSubmited &&
        <p className="SuccessContainer">
          {'Email Change was successful'}
        </p>
      }
      <FinalForm
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{ currentEmail: donorGlobalState?.loginId }}
        render={({
          handleSubmit, form
        }) => (
          <Form onSubmit={handleSubmit}>
            <Field name='currentEmail'>
              {({ input, meta }) => (
                <InputField
                  className="noBorderInputField"
                  meta={meta}
                  {...input}
                  height={50}
                  placeholder={t('currentEmail')}
                  topLabel={t('currentEmail')}
                  disabled={true}
                />
              )}
            </Field>

            <Field name='newEmail'>
              {({ input, meta }) => (
                <InputField
                  required
                  meta={meta}
                  {...input}
                  height={50}
                  placeholder={t('newEmail*')}
                  topLabel={t('newEmail')}
                />
              )}
            </Field>

            <Field name='confirmEmail'>
              {({ input, meta }) => (
                <InputField
                  required
                  meta={meta}
                  {...input}
                  height={50}
                  placeholder={t('confirmEmail*')}
                  topLabel={t('confirmEmail')}
                />
              )}
            </Field>

            <Form.Group>
              <div className='MessageButtonContainer'>
                <p className='FormNote'>
                  <sup>
                    <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                  </sup>
                  {t('requiredField')}
                </p>
              </div>
            </Form.Group>
            {(!mergeAccountSettingFailure?.code && mergeAccountSettingFailure?.statusCode && mergeAccountSettingFailure?.statusCode!='NewEmailExistsInHeroku' && mergeAccountSettingFailure?.statusCode!='DonorWithPDNExists') && isSubmited &&
              <div className='FailureStateContainer'>
                <FailureState message={mergeAccountSettingFailure?.message} />
              </div>
            }
            <Form.Group className='ButtonContainer'>
              <PrimaryButton
                text={t('cancel')}
                type='button'
                width={180}
                height={52}
                fontSize={16}
                classType='Inverted'
                onClick={() => {
                  setIsSubmited(false)
                  form.reset()
                  form.resetFieldState('newEmail')
                  form.resetFieldState('confirmEmail')
                }}
              />
              <PrimaryButton
                type='submit'
                text={t('changeEmail')}
                width={180}
                height={52}
                fontSize={16}
                classType='Primary'
                loading={mergeAccountSettingLoading}
              />
            </Form.Group>
            <Modal open={openModal} className='MergeAccountModal'>
              <Modal.Content className='usernameUpdateModal'>
                {mergeAccountSettingFailure?.code && mergeAccountSettingFailure?.code == 'NewEmailExistsInHeroku' &&
                  <p className='usernameUpdateMessage'>
                    {t('newEmailExists')} <br/>
                    {t('verifyMail')} <br/>
                    {t('confirmed')}<br/>  
                    {t('viewDuplicateAcc')}
                  </p>
                  
                }

                {mergeAccountSettingFailure?.code && mergeAccountSettingFailure?.code == 'DonorWithPDNExists' &&
                  <p className='usernameUpdateMessage'>
                  {t('emailConnectedToPdn')}<br/>
                  {t('instructDonors')}<br/>
                  {t('viewDuplicateAcc')}
                  </p>
                }
                <div className="ModelButtonWrapper">
                <PrimaryButton
                  text={t('cancel')}
                  type='button'
                  width={150}
                  height={50}
                  fontSize={16}
                  marginRight= {50}
                  classType='Inverted'
                  onClick={() => {
                    setIsSubmited(false)
                    toggleModal()
                  }}
                />
                
                {mergeAccountSettingFailure?.code && mergeAccountSettingFailure?.code == 'NewEmailExistsInHeroku' && (
                  <PrimaryButton
                    text={t('proceed')}
                    width={150}
                    height={50}
                    fontSize={16}
                    classType='Primary'
                    onClick={() => {
                      mergeAccountSetting({
                        currentEmail:currentEmail,
                        newEmail:newEmail,
                        confirmEmail:confirmEmail,
                        forceMerge: true
                      })
                      toggleModal();
                    }}
                  />
                  )}</div>
              </Modal.Content>
            </Modal>
          </Form>
        )}
      />

    </div>
  )
}


const mapStateToProps = state => ({
  user: state.users.user,
  mergeAccountSettingLoading: state.users?.mergeAccountSettingLoading,
  mergeAccountSettingSuccess: state.users?.mergeAccountSettingSuccess,
  mergeAccountSettingFailure: state.users?.mergeAccountSettingFailure?.response?.data,
  donorGlobalState: state.donors?.donors?.data
})

const mapDispatchToProps = dispatch => ({
  mergeAccountSetting: (currentEmail, newEmail, confirmEmail, forceMerge) => dispatch(UserActions.mergeAccountSetting(currentEmail, newEmail, confirmEmail, forceMerge)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MergeDonorAccountForm)
