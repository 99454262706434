import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const FailureState = importer('Components/Dashboard/StateErrorContainer')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const CancelAppointmentModal = ({
  open,
  toggleCancelAppointmentModal,
  appointmentData,
  cancelAppointmentHandler,
  cancelApptSuccessInfo,
  isCancelApptLoading,
  cancelApptErrorInfo,
  reloadCalendar
}) => {
  const [cancelError, setCancelError] = useState(null)
  const [cancelLoading, setCancelLoading] = useState(false)
  const [cancelSuccess, setCancelSuccess] = useState(false)
  const [cancelClicked, setCancelClicked] = useState(false)
  const { t } = useTranslation('cancelAppointmentModal','modalCommon');

  const cancelAppointment = () => {
    setCancelClicked(true)
    cancelAppointmentHandler(appointmentData.id, 'CENTER_OPS')
  }

  useEffect(() => {
    if (cancelClicked) {
      setCancelSuccess(cancelApptSuccessInfo)
      setCancelError(cancelApptErrorInfo)
      setCancelLoading(isCancelApptLoading)
    }
  }, [cancelApptErrorInfo, isCancelApptLoading])

  const closeConfirmation = () => {
    setCancelSuccess(false)
    setCancelError(false)
    setCancelLoading(false)
    toggleCancelAppointmentModal()
    reloadCalendar()
  }

  const dismissConfirmation = () => {
    setCancelSuccess(false)
    setCancelError(false)
    setCancelLoading(false)
    toggleCancelAppointmentModal()
    reloadCalendar()
  }

  return (
    <Modal open={open} onClose={toggleCancelAppointmentModal} className='CancelAppointmentModal'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            {!cancelSuccess ? (
              <Header as='h2' className='ModalHeader'>
                {t('cancelAppointmentfor')} <b>{appointmentData.name}</b>
              </Header>
            ) : (
              <Header as='h2' className='ModalHeader'>
                {t('appointmentisCanceledFor')}<b>{appointmentData.name}</b>
              </Header>
            )}
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={dismissConfirmation} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
          {cancelSuccess && (
            <Header as='h1' className='CancelledHeader'>
              {t('appointmentisCanceled')}
            </Header>
          )}
          <Header as='h3' className='HeaderText'>
           {t('appointmentDate')} &amp; {t('time')}
          </Header>
          {appointmentData && (
            <p className='RegularText'>
              {appointmentData.weekDay}, {appointmentData.date} at {appointmentData.time.toLowerCase()}
            </p>
          )}
          <Header as='h3' className='HeaderText'>
           {t('appointmentType')}
          </Header>
          <p className='RegularText'>{appointmentData.donationType === 'PHYSICAL' ? t('modalCommon:PhysicalWithDonation') : t('modalCommon:donation') }</p>
          <Header as='h3' className='HeaderText'>
            {appointmentData.city} {t('center')}
          </Header>
          <p className='RegularText'>
            {appointmentData.addressLineOne} <br />
            {appointmentData.stateCode} {appointmentData.zipcode} <br />
          </p>

          {cancelLoading && (
            <LoadingState/>
          )}

          {cancelError &&
            cancelError.response &&
            cancelError.response.data && (
            <FailureState
              message = {cancelError.response.data.message}
            />
          )}
          {!cancelSuccess && (
            <div className='ModalActions'>
              <Grid.Row className='ConfirmContainer'>
                <PrimaryButton
                  text={t('confirmCancellation')}
                  width={280}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  onClick={cancelAppointment}
                />
              </Grid.Row>
              <Grid.Row>
                <PrimaryButton
                  text={t('keepthisAppointment')}
                  width={280}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  onClick={toggleCancelAppointmentModal}
                />
              </Grid.Row>
            </div>
          )}
          {cancelSuccess && (
            <div className='ModalActions'>
              <Grid.Row className='ConfirmContainer'>
                <PrimaryButton
                  text={t('close')}
                  width={270}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  onClick={closeConfirmation}
                />
              </Grid.Row>
            </div>
          )}
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default CancelAppointmentModal
