import React from 'react'
import { Button } from 'semantic-ui-react'

import './styles.scss'

const PrimaryButton = (props) => {
  const { text, width, type, height, fontSize, classType, disabled, onClick, as, to, className, lineHeight, loading, minWidth } = props
  const buttonClass = `PrimaryButton ${classType} ${className}`
  const isDisabled = classType === 'Inactive' || disabled

  return (
    <Button
      loading={loading}
      className={buttonClass}
      style={{ width: width, height: height, fontSize: fontSize, lineHeight: lineHeight, minWidth: minWidth }}
      disabled={isDisabled}
      type={type}
      onClick={onClick}
      as={as}
      to={to}>
      {text}
    </Button>
  )
}
PrimaryButton.defaultProps = {
  text: 'Primary',
  classType: 'Primary',
  fontSize: 14
}
export default PrimaryButton
