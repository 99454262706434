import React, { useEffect, useState } from 'react'
import { Form, Grid, Message, Visibility } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next';

import validate from './validate'
import { isEmpty, isArray } from 'lodash'

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const SignUpForm = ({
  saveSignUp,
  registerDonor,
  registerDonorSuccess,
  registerDonorFailure,
  registerDonorLoading,
  centers,
  getDonorCentersAll,
  disclaimerText,
  agreeText
}) => {
  const history = useHistory()
  const [submited, setSubmited] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [width, setWidth] = useState('')
  const fieldsWidths = width < 480 ? 350 : 396
  const handleUpdate = (e, { calculations }) => setWidth(calculations.width)
  const [nextClicked, setNextClicked] = useState(false)
  const { t } = useTranslation('SignUpForm');

  const handleRegisterDonor = async donor => {
    await registerDonor(donor)
    saveSignUp(donor)
    setSubmited(true)
  }

  const onSubmit = values => {
    setNextClicked(true)
    const { firstName, lastName, email, zipCode, isEmailOptIn=true } = values
    const donor = {
      donorDetails: {
        firstName,
        lastName,
        email: email.toLowerCase(),
        zipCode,
        isEmailOptIn,
        gclickId:getGclickId()
      }
    }
    getDonorCentersAll(zipCode, '', 'pubDate', true)      // true corresponds to addNewDonorBonusInformation
    handleRegisterDonor(donor)
  }
  const getGclickId=()=>{
    var b = document.cookie.match("(^|;)\\s*" + "_gcl_aw" + "\\s*=\\s*([^;]+)");
    return b ? b.pop().split(".")[2] : null;
  } 

  useEffect(() => {
    if (registerDonorSuccess != null && submited) {
      const lead = { ...registerDonorSuccess.data }
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: 'profileRegistration',
      //     profileRegistrationStage: 'profile lead generated',
      //     donorZip: lead.zipCode,
      //     donorId: lead.id,
      //     bdpDonorId: lead.bdpDonorId || null
      //   }
      // })
      window.sessionStorage.setItem('lead', JSON.stringify(lead))
      setSubmited(false)
      if (!isEmpty(centers)) {
        history.push('/create-account')
      } else {
        history.push('/no-center-zone')
      }
    }
  }, [registerDonorSuccess])
  useEffect(() => {
    if (registerDonorFailure !== null && submited) {
      console.log('Error: ', registerDonorFailure)
      if (isArray(registerDonorFailure.message)) {
        setErrorMessages(registerDonorFailure.message)
      } else {
        setErrorMessages([registerDonorFailure.message])
      }

      setSubmited(false)
    }
  }, [registerDonorFailure])

  return (
    <div className='SignUpForm'>
      <Visibility onUpdate={handleUpdate}>
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Field name='firstName'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      width={fieldsWidths}
                      height={64}
                      fieldLabel={t('firstName')}
                      className='SignUpInput'
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Field name='lastName'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      width={fieldsWidths}
                      height={64}
                       fieldLabel={t('lastName')}
                      className='SignUpInput'
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Field name='email'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      width={fieldsWidths}
                      height={64}
                      fieldLabel={t('email')}
                      className='SignUpInput'
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Field name='zipCode'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      width={fieldsWidths}
                      height={64}
                      fieldLabel={t('zipCode')}
                      className='SignUpInput'
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <div
                  className='DisclaimerText'
                  dangerouslySetInnerHTML={{
                    __html: disclaimerText?.content__c
                  }}
                />
              </Form.Group>
              <Form.Group>
                <div className='SignUpButtonContainer'>
                  <PrimaryButton
                    text={t('next')}
                    width={230}
                    height={52}
                    fontSize={16}
                    disabled={registerDonorLoading && nextClicked}
                  />
                </div>
              </Form.Group>
              <div className='contact-us-Container'>
                {t('thinkYouMightAlreadyHave')}<br/>
                <a href='/about-biolife/contact-us' className='linkStyle'>{t('contactUshere')}</a>
                {t('toFindOut')}
              </div>
              {registerDonorLoading && nextClicked && (
                <LoadingState/>
              )}
            </Form>
          )}
        />
        {!isEmpty(errorMessages) && (
          <Message error style={{ maxWidth: 396 }}>
            {errorMessages.map((message, index) => (
              <p key={index}>{message}</p>
            ))}
          </Message>
        )}
      </Visibility>
    </div>
  )
}

export default SignUpForm
