import React from 'react'
import { Header, Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss'
const donations = [
  {
    date: '04/24/2020',
    location: 'Cordova, TN'
  },
  {
    date: '03/24/2020',
    location: 'Cordova, TN'
  }
]

const LastDonations = () => {
  const { t } = useTranslation('LastDonations');
  return (
    <div className='LastDonations'>
      <Header as='h4' className='DonationHeader'>
       {t('last2Donations')}
      </Header>
      <Grid columns={2}>
        {donations.map((donation, index) => (
          <Grid.Row
            key={index}
            className='RowData'
            style={{
              borderBottom:
                donations.length - 1 !== index ? '1px solid #dddddd' : ''
            }}
          >
            <Grid.Column className='DonationDate'>{donation.date}</Grid.Column>
            <Grid.Column className='DonationLocation'>
              {donation.location}
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </div>
  )
}

export default LastDonations
