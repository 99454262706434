import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Form,Image } from 'semantic-ui-react'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
import infoIcon from '../../../../../assets/media/images/info-icon.png'

const PrimarySelect = importer('Components/Inputs/PrimarySelect')

const SelectField = ({
  meta,
  input,
  required,
  placeholder,
  options,
  className,
  defaultValue,
  handleOnChange,
  topLabel,
  disabled,
  key,
  value,
  groupSelect,
  fieldLabel,
  icon
}) => {
  const [hasErrors, setHasErrors] = useState(meta.touched && meta.error)
  const { t } = useTranslation('ValidationErrorMessages');
  const onChange = (value) => {
    if (value) {
      setHasErrors(false)
    }
    input.onChange(value)
  }
  useEffect(() => {
    setHasErrors(meta.touched && meta.error)
  }, [meta.touched, meta.error])

  return (
    <Form.Field required={required} className='FormField'>
       {fieldLabel && <small className={'TopLabel'}>{fieldLabel}</small>} 
      {hasErrors && <small className={fieldLabel ? 'TopLabelError' : ''}>
      {fieldLabel ? <div className="ErrorMsgContainer"><Image className='InfoIcon'  src={infoIcon} width="18" height="18"/> <span className="ErrorMsg" >{t(meta.error)}</span></div> : <span>{t(meta.error)}</span> }</small>}
      {meta.touched && meta.error && !topLabel && <small className={fieldLabel ? 'TopLabelError' : ''}>
      {fieldLabel ? <div className="ErrorMsgContainer"><Image className='InfoIcon'  src={infoIcon} width="18" height="18"/> <span className="ErrorMsg" >{t(meta.error)}</span></div> : <span>{t(meta.error)}</span> }</small>}
      {(!meta.touched || !meta.pristine) && topLabel && (
        <small className={meta.touched && meta.error ? 'TopLabelError' : 'TopLabel'}>{topLabel}</small>
      )}
      <PrimarySelect
        placeholder={placeholder}
        options={options}
        name={input.name}
        className={className}
        groupSelect={groupSelect}
        handleOnChange={handleOnChange || onChange}
        {...input}
        key={key || undefined}
        error={hasErrors}
        required={required}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        icon = {icon}
      />
    </Form.Field>
  )
}

export default SelectField
