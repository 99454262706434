import React, { useEffect, useState } from 'react'
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'

import moment from 'moment'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const FailureState = importer('Components/Dashboard/StateErrorContainer')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const ConfirmRescheduleModal = ({
  open,
  toggleModal,
  reloadCalendar,
  appointment,
  onScheduleClick,
  donor,
  scheduleApptErrorInfo,
  isScheduleApptLoading,
  scheduleApptSuccessInfo
}) => {
  const { t} = useTranslation('ConfirmAppointmentModal','modalCommon');
  const [scheduleError, setScheduleError] = useState(null)
  const [scheduleLoading, setScheduleLoading] = useState(false)
  const [scheduleSuccess, setScheduleSuccess] = useState(false)
  const [confirmClicked, setConfirmClicked] = useState(false)

  const confirmAppointment = () => {
    const finalAppointmentData = {
      startDateTime: appointment.startDateTime,
      centerSfid: appointment.centerSfid,
      appointmentType: appointment.appointmentType,
      donorSfid: donor.sfid,
      numberOfChildren: appointment.numberOfChildren || 0,
      createdFrom: 'CENTER_OPS'
    }
    setConfirmClicked(true)
    onScheduleClick(finalAppointmentData)
  }

  const closeConfirmation = () => {
    toggleModal()
    reloadCalendar()
  }
  useEffect(() => {
    if (confirmClicked) {
      setScheduleSuccess(scheduleApptSuccessInfo)
      setScheduleError(scheduleApptErrorInfo)
      setScheduleLoading(isScheduleApptLoading)
    }
  }, [scheduleApptErrorInfo, isScheduleApptLoading])

  return (
    <Modal open={open} onClose={toggleModal} className='ConfirmAppointmentModal'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            {!scheduleSuccess &&
              <Header as='h2' className='ModalHeader'>
                {t('appointmentConfirmat')}
              </Header>
            }
            {scheduleSuccess &&
              <Header as='h2' className='ModalHeader'>
                {t('appointmentConfirmed')}
              </Header>
            }
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={toggleModal} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
          <Header as='h3' className='HeaderText'>
           {t('appointmentDate')} &amp; {t('time')}
          </Header>
          <p className='RegularText'>
            {`${moment(appointment.startDateTime).format('ddd')}, ${moment(appointment.startDateTime).format(
              'll'
            )} at ${appointment.startTime}`}
          </p>
          <Header as='h3' className='HeaderText'>
            {t('appointmentType')}
          </Header>
          <p className='RegularText'> 
          {appointment.appointmentType === 'PHYSICAL' ? t('modalCommon:PhysicalWithDonation') : t('modalCommon:donation') }
          </p>
          {appointment && appointment.numberOfChildren > 0 &&
            <React.Fragment>
              <Header as='h3' className='HeaderText'>
                {t('playroomReserved')}
              </Header>
              <p className='RegularText'>{t('children')} {appointment.numberOfChildren}</p>
            </React.Fragment>
          }
          <Header as='h3' className='HeaderText'>
            {appointment.city} {'Center'}
          </Header>
          <p className='RegularText'>{appointment.addressLineOne}
            <br />
            {`${appointment.stateCode} ${appointment.zipcode}`}
            <br />
          </p>
          {scheduleLoading && (
            <LoadingState/>
          )}
          {scheduleError && (
            <FailureState
              message = {scheduleError.message}
            />
          )}
          {!scheduleSuccess && (
            <div className='ModalActions'>
              <Grid.Row className='ConfirmContainer'>
                <PrimaryButton
                  text={t('confirmAppointment')}
                  width={270}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  onClick={confirmAppointment}
                  disabled={isScheduleApptLoading}
                />
              </Grid.Row>
              <Grid.Row>
                <PrimaryButton
                  text={t('cancel')}
                  width={150}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  onClick={toggleModal}
                />
              </Grid.Row>
            </div>
          )}
          {scheduleSuccess && (
            <div className='ModalActions'>
              <Grid.Row className='ConfirmContainer'>
                <PrimaryButton
                  text={t('close')}
                  width={270}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  onClick={closeConfirmation}
                />
              </Grid.Row>
            </div>
          )}
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ConfirmRescheduleModal
