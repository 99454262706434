import React, { useState, useEffect } from 'react'
import { Table, Checkbox, Input } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'
import * as moment from 'moment'
import { Message } from 'semantic-ui-react'
import './styles.scss'

const InputField = importer('Components/Inputs/PrimaryInput')

const AppointmentSetupTable = ({
  scheduledCountKey,
  allowedCountKey,
  slotType,
  slotConfigData,
  checkedFromDate,
  checkedUntilDate,
  isParentChecked,
  handleInputChange,
  handleCheckboxChange,
  handleParentCheckboxChange
}) => {
  const { t} = useTranslation('appoinmentSetupTable');
  return (
    <div className='AppointmentSetupTable'>
      <div className='Results'>
        <div className='ResultsTableContainer'>
          <Table basic='very'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <Checkbox
                    checked={isParentChecked}
                    onChange={(e, { checked }) => handleParentCheckboxChange(checked, slotType)}
                  />
                </Table.HeaderCell>
                <Table.HeaderCell>{t('time')}</Table.HeaderCell>
                <Table.HeaderCell>{t('effective')}</Table.HeaderCell>
                <Table.HeaderCell textAlign='right'>{t('scheduled')}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {slotConfigData.map((item, index) => (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Checkbox
                      checked={item.isChecked}
                      onChange={(e, { checked }) => handleCheckboxChange(checked, index, slotType)}
                    />
                  </Table.Cell>
                  <Table.Cell>{item.appointmentSlotTime}</Table.Cell>
                  <Table.Cell>
                    <div className='Field'>
                      <Input
                        type='number'
                        min='0'
                        error={item.blankError}
                        value={item.allowedCount}
                        onChange={(e, { value }) => handleInputChange(value, index, slotType)}
                      />
                    </div>
                  </Table.Cell>
                  <Table.Cell textAlign='right'>
                    {item.scheduledCount}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </div>
      </div>
    </div>
  )
}

export default AppointmentSetupTable
