import React from 'react'
import { Grid, Image, Header } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { formatPhoneNumber } from '../../Helpers/Utils'

import tempFacility from '../../../assets/media/images/tempFacility.png'
import './styles.scss'

const FacilityCard = ({ facility, selectedState }) => {
  return (
    <div className='FacilityCard'>
      <Grid columns={2}>
        <Grid.Column computer={6}>
          <div className='ImgContainer'>
            <NavLink to={`${selectedState.split(' ').join('-')}/${facility.centerUrlName}`}>
              <Image src={facility.mobileImageUrl || tempFacility} alt={facility.imageAltText} />
            </NavLink>
          </div>
        </Grid.Column>
        <Grid.Column computer={10}>
          <div>
            <Header as='h3' className='FacilityName'>
              <NavLink to={`${selectedState.split(' ').join('-')}/${facility.centerUrlName}`}>{facility.name}</NavLink>
            </Header>
            <p className='Address'>{`${facility.addressLine1} 
              ${facility.city}, ${facility.stateCode} ${facility.zipcode}`}</p>
            <a href={`tel:${facility.telephone}`} className='Phone'>
              {formatPhoneNumber(facility.telephone)}
            </a>
          </div>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default FacilityCard
