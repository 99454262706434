import moment from 'moment'
import { isEmpty } from 'lodash'

const validate = values => {
  const errors = {}

  if (isEmpty(values.start_date)) {
    errors.start_date = 'startDateCannotBeEmpty'
  }

  if (!isEmpty(values.start_date)) {
    if (!moment(values.start_date, 'MM/DD/YYYY', true).isValid()) {
      errors.start_date = 'startDateShouldBeMmDdYy'
    }
  }

  if (isEmpty(values.end_date)) {
    errors.end_date = 'endDateCannotBeEmpty'
  }

  if (!isEmpty(values.end_date)) {
    if (!moment(values.end_date, 'MM/DD/YYYY', true).isValid()) {
      errors.end_date = 'startDateShouldBeMmDdYy'
    }
  }

  return errors
}

export default validate
