import React, { useRef } from 'react'
import { connect } from 'react-redux'
import { Responsive, Ref, Visibility, Sticky } from 'semantic-ui-react'

import importer from '@takedapdt/biolife-core/src/importer'

const NavBar = importer('Components/NavBar')
const getWidth = importer('Helpers/getWidth')

const DesktopContainer = ({
  children,
  loggedInUser
}) => {
  const contextRef = useRef(null)

  return (
    <Responsive getWidth={getWidth} minWidth={Responsive.onlyComputer.minWidth}>
      <Ref innerRef={contextRef}>
        <Visibility>
          <Sticky context={contextRef}>
            <NavBar loggedInUser={loggedInUser} />
          </Sticky>
          {children}
        </Visibility>
      </Ref>
    </Responsive>
  )
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users.user
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DesktopContainer)
