import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image, Visibility, Dimmer, Message,Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../../assets/media/icons/icon-close-web.png'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer';
const centerActions = importer("Stores/Centers/Actions");
import { connect } from "react-redux";

const PrimaryButton = importer('Components/Buttons/PrimaryButton');

const UpdateUserDefaultCenterModal = (
    { 
        selectedCenter,
        selectedUser,
        toggleSuccessModal,
        open,
        toggleModal,
        updateUserDefaultCenterFailure,
        updateUserDefaultCenterSuccess,
        updateUserDefaultCenterLoading,
        updateUserDefaultCenter
    }) => {  
  const { t } = useTranslation('UpdateUserDefaultCenterModal'); 
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  const [submited, setSubmited] = useState(false);

    useEffect(() => {
      if (updateUserDefaultCenterSuccess !== null && submited) {
        setLoading(false);
        setErrorMessages("");
        toggleSuccessModal(true,"UpdateUserDefaultCenterModal");
        toggleModal();
      }
    }, [updateUserDefaultCenterSuccess]);

    useEffect(() => {
      if (updateUserDefaultCenterFailure !== null && submited) {
        setSubmited(false)
        setLoading(false)
        setErrorMessages(updateUserDefaultCenterFailure ? updateUserDefaultCenterFailure.message : t('somethingWentWrong'))
      }
    }, [updateUserDefaultCenterFailure]);

    useEffect(() => {
      setErrorMessages("");
    },[])

    const onConfirmation = () => {
        setLoading(true);
        setSubmited(true);
        const userCenter = {
            centerSfid : selectedCenter.sfid,
             userSfid:  selectedUser.sfid
            };
        updateUserDefaultCenter(userCenter);
    };

    return (
    <Visibility> 
      <Modal open={open} onClose={null} className='UpdateUserDefaultCenterModal'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
               {t('header')}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image src={CloseIcon} alt='Close' onClick={toggleModal} className='CloseModal' />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
            <Grid.Row>
                <div className='ModalContent'>
                    <p className='RegularText'>{t('content').replace("%c1%", selectedUser.defaultCenter).replace("%c2%",selectedCenter.name).replace("%u%",selectedUser.name)}</p>
                </div>
            </Grid.Row>
            {errorMessages && (
          <Message warning className='errorMessage'>
            <p>{Array.isArray(errorMessages) ? errorMessages.join(', ') : errorMessages}</p>
          </Message>
        )}
            <Grid.Row className="rowspace">
                <PrimaryButton
                    text={t('close')}
                    fontSize={16}
                    classType='Inverted'
                    onClick={toggleModal}
                    className='close'
                />
                <PrimaryButton
                    text={t('saveButton')}
                    classType='Primary'
                    className='save'
                    onClick={onConfirmation}
                />
            </Grid.Row>
        </Modal.Content>
      </Modal>
      {(loading && updateUserDefaultCenterLoading) && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
    </Visibility>
    )
}

const mapStateToProps = (state) => ({
    updateUserDefaultCenterLoading: state.centers.updateUserDefaultCenterLoading,
    updateUserDefaultCenterSuccess: state.centers.updateUserDefaultCenterSuccess,
    updateUserDefaultCenterFailure: state.centers.updateUserDefaultCenterFailure,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    updateUserDefaultCenter: (userCenter) => dispatch(centerActions.updateUserDefaultCenter(userCenter)),
  })

export default connect(
    mapStateToProps, mapDispatchToProps
) (UpdateUserDefaultCenterModal)
