import React from "react"
import { Grid, Image } from "semantic-ui-react"
import { useTranslation } from "react-i18next";
import "../styles.scss"

const CheckboxCard = ({ isGiftcardOptionSelected, handleGiftcardOptionChange, value, checkboxIcon, altText, label }) => {
    const { i18n } = useTranslation();
    return (
        <>
            <Grid.Column class="checkbox">
                <label
                    for="checkbox-wrapper"
                    onClick={() => handleGiftcardOptionChange(value)}
                >
                    <input
                        type="checkbox"
                        class="checkbox-input"
                        value={value}
                        checked={isGiftcardOptionSelected === value}
                        onClick={() => handleGiftcardOptionChange(value)}
                    />
                    <span class={`checkbox-tile ${i18n.language}`}>
                        <span class="checkbox-icon">
                            <Image
                                src={checkboxIcon}
                                alt={altText}
                            />
                        </span>
                        <span class={`checkbox-label ${i18n.language}`}>{label}</span>
                    </span>
                </label>
            </Grid.Column>
        </>
    )
}

export default CheckboxCard;