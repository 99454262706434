import React, { useEffect } from "react";
import importer from "@takedapdt/biolife-core/src/importer";
import { connect } from "react-redux";
import { FeedLike } from "semantic-ui-react";
import { Card, Feed } from "semantic-ui-react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const publicContentActions = importer("Stores/PublicContent/Actions");
const TopEarningActivity = ({
  loyaltyMilestones,
  getLoyaltyMilestones,
  donor,
  setTab
}) => {
  const { t, i18n} = useTranslation("TopEarningActivity");
  useEffect(() => {
    if (donor) {
      getLoyaltyMilestones(donor?.defaultCenter?.sfid);
    }
  }, [donor,i18n.language]);
  const handleEarningsLinkClick = () => {
   setTab("WAYS_TO_EARN");
   window.scrollTo(0, 0);
  };
  
  return (
    <div className="TopEarningContainer">
      <Card className="topEarnCardList">
        <Card.Content>
          <Card.Header>
            <h2>{t("TopEarningActivities")}</h2>
            <h6 className="subText">{t("earnsubText")}</h6>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Feed>
          {loyaltyMilestones &&
                  loyaltyMilestones.length > 0 &&
                  loyaltyMilestones
                  .sort((a, b) => a.bll_top_earn_activity_order__c - b.bll_top_earn_activity_order__c)
                  .slice(0,3)
                  .map((milestone, index) => (
                <Feed.Event key={milestone.id}>
                  <Feed.Content>
                    <Feed.Summary
                      style={{ color: "#007065" }}
                    >
                      {`${index + 1}. ${milestone.bll_milestone_title__c}`}
                      <Feed.Date
                        content={milestone.bll_milestone_sub_title__c}
                      />
                    </Feed.Summary>
                    <Feed.Meta>
                    {`${milestone.bll_points__c} ${t("points")}`}
                    </Feed.Meta>
                    <Feed.Extra>
                      {milestone.description__c}
                    </Feed.Extra>
                  </Feed.Content>
                </Feed.Event>
              ))}
          </Feed>
        </Card.Content>
      <FeedLike onClick={handleEarningsLinkClick} target="_blank">
          {t("earningsLink")}
      </FeedLike>
      </Card>
    </div>
  );
};
const mapStateToProps = (state, publicContent) => ({
  loyaltyMilestones: state.publicContent.getLoyaltyMilestonesSuccess,
  donor: state.donors.donors?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyMilestones: (sfid) =>
    dispatch(publicContentActions.getLoyaltyMilestones(sfid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopEarningActivity);
