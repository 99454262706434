import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import { connect } from 'react-redux'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const AppointmentsActions = importer('Stores/Appointments/Actions')
const DonorsActions = importer('Stores/Donors/Actions')
const SearchResultsCalendar = importer('Components/CenterOps/SearchResultsCalendar')
const ConfirmAppointmentModal = importer('Components/CenterOps/ConfirmAppointmentModal')

const NoResultsCalendar = importer('Components/CenterOps/NoResultsCalendar')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const HorizontalDonorSearchForm = importer(
  'Components/Forms/HorizontalDonorSearchForm'
)

const ScheduleAppointmentModal = ({
  open,
  toggleModal,
  reloadCalendar,
  toggleScheduleAppointment,
  toogleQuickPhysicalModal,
  appointmentTime,
  maxAllowed,
  searchForDonor,
  searchDonors,
  searchDonorsError,
  searchDonorsLoading,
  scheduleAppointment,
  scheduleAppointmentError,
  scheduleAppointmentLoading,
  scheduleAppointmentSuccess,
  appointmentDateTime,
  donationType,
  donationCenter,
  totalScheduled,
  centerInfo,
  handleClearSearchResults,
  tempSearchResults,
  setIsNewDonorSearch,
  isNewDonorSearch,
  needPlayRoom
}) => {
  const [searchClicked, setSearchClicked] = useState(false)
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [appointmentInfo, setAppointmentInfo] = useState(null)
  const [donorInfo, setDonorInfo] = useState(null)
  const [quickPhysicalError, setQuickPhysicalError] = useState(null)
  const { t } = useTranslation('ScheduleAppointmentModal','Children')

  const playroom = [
    { key: '0', value: 0, text: t('Children:childrenZero') },
    { key: '1', value: 1, text: t('Children:childrenOne') },
    { key: '2', value: 2, text: t('Children:childrenTwo') },
    { key: '3', value: 3, text: t('Children:childrenThree') },
    { key: '4', value: 4, text: t('Children:childrenFour') },
    { key: '5', value: 5, text: t('Children:childrenFive') },
    { key: '6', value: 6, text: t('Children:childrenSix') }
  ]
  const getPlayRoom = value => {}

  const scheduleAppointmentHandler = (donorSfid, numberOfChildren) => {
    setIsOpenConfirmModal(true)
    setAppointmentInfo({
      startDateTime: appointmentDateTime,
      startTime: appointmentTime,
      appointmentType: donationType,
      numberOfChildren: numberOfChildren,
      centerSfid: donationCenter,
      city: centerInfo.city,
      addressLineOne: centerInfo.addressLine1,
      stateCode: centerInfo.stateCode,
      zipcode: centerInfo.zipcode

    })
    setDonorInfo({
      sfid: donorSfid
    })
  }
  const searchForDonorHandler = (values) => {
    setSearchClicked(true)
    setIsNewDonorSearch(false)
    searchForDonor(values)
    handleClearSearchResults(searchDonors && searchDonors.data?.donors && searchDonors.data?.donors.length > 0 && searchDonors.data?.donors)
  }
  const closeIconHandler = () => {
    setQuickPhysicalError(false)
    setIsNewDonorSearch(true)
    toggleScheduleAppointment()
  }

  useEffect(() => {
    handleClearSearchResults(searchDonors && searchDonors.data?.donors && searchDonors.data?.donors.length > 0 && searchDonors.data?.donors)
  }, [searchDonors])

  useEffect(() => {
    if (open) { setSearchClicked(false) }
  }, [open])

  const quickPhysicalHandler = () => {
    if (donationType === 'DONATION') {
      setQuickPhysicalError(true)
    } else {
      setQuickPhysicalError(false)
      toogleQuickPhysicalModal()
      toggleScheduleAppointment()
    }
  }

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      className='ScheduleAppointmentModal'
      size='large'
    >
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as='h2' className='ModalHeader'>
              {t('scheduleAppointment')}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image
              src={CloseIcon}
              alt='Close'
              onClick={closeIconHandler}
            />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
          <div className='SelectedTimeHeader'>
            <Header as='h1' className='HeaderText'>
              {appointmentTime} {donationType ==='PHYSICAL' ? t('physical') :t('donation')}
              <span>( {totalScheduled} {t('scheduled')} / {maxAllowed} {t('allowed')})</span>
            </Header>
          </div>
          <HorizontalDonorSearchForm searchForDonorHandler = {searchForDonorHandler} setIsNewDonorSearch={setIsNewDonorSearch}/>
          {searchDonorsLoading && (
            <LoadingState/>
          )}
          {searchClicked && searchDonorsError && (
            Array.isArray(searchDonorsError) ? (
              <Message warning visible> 
                {searchDonorsError.map(item => <p>{item}</p>)}
              </Message>)
            : <FailureState/>
          )}
          {!searchDonorsLoading && !searchDonorsError && searchDonors && searchDonors.data?.donors.length > 0 && tempSearchResults && tempSearchResults.length > 0 && (
            <SearchResultsCalendar
              playroom={playroom}
              needPlayRoom={needPlayRoom}
              getPlayRoom={getPlayRoom}
              isScheduleAppointmentLoading={scheduleAppointmentLoading}
              toggleScheduleAppointment = {toggleScheduleAppointment}
              searchDonors = {searchDonors && searchDonors.data?.donors && tempSearchResults}
              scheduleAppointmentHandler = {scheduleAppointmentHandler}
              donationType = {donationType}
              handleClearSearchResults={handleClearSearchResults}
              setIsNewDonorSearch={setIsNewDonorSearch}
            />
          )}
          {!searchDonorsLoading &&
            !searchDonorsError &&
            searchDonors?.data.totalRecords < 1 && !isNewDonorSearch && (
            <NoResultsCalendar
              toggleScheduleAppointment={toggleScheduleAppointment}
              toogleQuickPhysicalModal={toogleQuickPhysicalModal}
              quickPhysicalHandler={quickPhysicalHandler}
              setQuickPhysicalError={setQuickPhysicalError}
            />
          )}
          { quickPhysicalError && <FailureState message = {t('pleaseselectaPhysical')}/> }

          {isOpenConfirmModal && (
            <ConfirmAppointmentModal
              open={isOpenConfirmModal}
              toggleModal={() => setIsOpenConfirmModal(!isOpenConfirmModal)}
              appointment={appointmentInfo}
              onScheduleClick={scheduleAppointment}
              donor={donorInfo}
              isScheduleApptLoading={scheduleAppointmentLoading}
              scheduleApptErrorInfo={scheduleAppointmentError}
              scheduleApptSuccessInfo={scheduleAppointmentSuccess}
              reloadCalendar={reloadCalendar}
            />
          )}
        </div>
      </Modal.Content>
    </Modal>
  )
}

const mapStateToProps = state => {
  return ({
    user: state.users.loginHerokuSuccess,
    searchDonors: state.donors.searchForDonorSuccess,
    searchDonorsError: state.donors.searchForDonorFailure?.response?.data?.message,
    searchDonorsLoading: state.donors.searchForDonorLoading,
    scheduleAppointmentError: state.appointments.scheduleAppointmentFailure,
    scheduleAppointmentLoading: state.appointments.scheduleAppointmentLoading,
    scheduleAppointmentSuccess: state.appointments.scheduleAppointmentSuccess
  })
}

const mapDispatchToProps = dispatch => ({
  searchForDonor: (values) => dispatch(
    DonorsActions.searchForDonor(values)
  ),
  scheduleAppointment: (appt) => dispatch(
    AppointmentsActions.scheduleAppointment(
      appt.startDateTime,
      appt.appointmentType,
      appt.donorSfid,
      appt.numberOfChildren,
      appt.centerSfid,
      appt.createdFrom
    )
  )
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScheduleAppointmentModal)
