import React from 'react'
import { connect } from 'react-redux'
import { Header, Image } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { webArticles } = importer('Helpers/WebArticles')

import './styles.scss'

const Testimonials = ({ publicContent, AppImages }) => {
  const registrationTestimonial = ArticleByIdentifier(
    publicContent,
    webArticles.REGISTRATION_TESTIMONIAL
  );

  const testimonialImage = AppImages?.find(
    (i) => i?.name__c === "Image to testimonial on registration page"
  );

  return (
    <div className='Testimonials'>
    <div className='TestimonialsContainer'>
      <Header as='h1' className='TestimonialsHeader'>
        <div dangerouslySetInnerHTML={{ __html: registrationTestimonial?.title__c }} />
      </Header>
        <div>
          <Image
            src={testimonialImage?.image_url_desktop__c}
            alt={testimonialImage?.image_alt_text__c}
            width="100"
            height="100"
            className="TestimonialsImg"
            circular
            centered
          />
        </div>
        <div className="TestimonialsContent">
          <div
            dangerouslySetInnerHTML={{
              __html: registrationTestimonial?.content__c,
            }}
          />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  publicContent: state.publicContent.WebArticles?.completeArticles,
  AppImages: state.publicContent?.AppImages,
});

export default connect(mapStateToProps,null)(Testimonials)
