import React from 'react'
import { hot } from 'react-hot-loader/root'
import { connect } from 'react-redux'
import { Router, Switch, Redirect } from 'react-router-dom'

import Helmet from 'react-helmet'
import styled, { ThemeProvider } from 'styled-components'

import importer from '@takedapdt/biolife-core/src/importer'
import { createBrowserHistory } from 'history'
import { withTranslation } from 'react-i18next'
/* ACTIONS */
const UserActions = importer('Stores/Users/Actions')
const StartupActions = importer('Stores/Startup/Actions')

const theme = importer('Theme')
const AuthResetPassword = importer('Routes/ResetPassword')
const AuthSignInAuthorize = importer('Routes/SignInAuth')


const CenterOps = importer('Routes/CenterOps')
const LogIn = importer('Routes/LogIn')
const LogOut = importer('Routes/LogOut')

const AppointmentScheduling = importer('Routes/AppointmentScheduling')
const CenterOpsCalendar = importer('Routes/CenterOpsCalendar')
const CenterOpsReports = importer('Routes/CenterOpsReports')
const CenterOpsDonorView = importer('Routes/CenterOpsDonorView')
const CenterOpsSetup = importer('Routes/CenterOpsSetup')
const CenterOpsAccessManagementView = importer('Routes/CenterOpsAccessManagementView')
const CenterOpsAccessManagementCreateForm = importer('Routes/CenterOpsAccessManagementCreateForm')

const RoutePublic = importer('Components/RoutePublic')
const RoutePrivate = importer('Components/RoutePrivate')
const AlertModal = importer('Components/AlertModal')
const InactivityTimer = importer('Helpers/InactivityTimer')
const LeadGenReport = importer('Routes/LeadGenReport')

const NotFound = importer('Routes/NotFound')

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  opacity: 1 !important;
  position: relative;
  transition: opacity 0.5s;
`

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = { inactiveAlertOpen: false }
    this.alertModal = this.alertModal.bind(this)
    this.history = createBrowserHistory()
    this.bodyObserver = null;
  }

  // This is a callback function that will be invoked when mutations are observed
  handleOTBannerAdded(mutationsList) {
    for (const mutation of mutationsList) {
      if (mutation.addedNodes.length > 0) {
        const oneTrustComponent = document.getElementById('onetrust-consent-sdk');
        if (oneTrustComponent) {
          const allConsentToggles = document.querySelectorAll('#onetrust-consent-sdk .category-switch-handler');
          const consentConfirmBtn = document.querySelector('#onetrust-consent-sdk .onetrust-close-btn-handler');
          // Variable to track the previous checkbox status
          let previousCheckboxStatus = Array.from(allConsentToggles, checkbox => checkbox?.checked);
          consentConfirmBtn?.addEventListener('click', () => {
            let hasUncheckedChange = false;
            // Check the status of all checkboxes and compare with the previous status
            allConsentToggles?.forEach(function (checkbox, index) {
              const currentStatus = checkbox?.checked;
              if (currentStatus !== previousCheckboxStatus[index]) {
                hasUncheckedChange = true;
              }
              previousCheckboxStatus[index] = currentStatus; // Update previous status
            });
            if (hasUncheckedChange) {
              console.log('User has removed the consent for some categories');
              //reload page when consent is changed
              window.location.reload();
            }
          });
          // Stop observing once the component is found
          this.bodyObserver.disconnect();
          return;
        }
      }
    }
  }

  componentDidMount() {
    const { loggedInUser, initializeApp, getLoggedInUser } = this.props
    getLoggedInUser()
    initializeApp()
    const isAuthenticated = this.isDonorLoggedIn()
    if (isAuthenticated) {
      const timer = 25
      InactivityTimer(timer, () => {
        this.setState({ inactiveAlertOpen: true })
      })
    }
    window.localStorage.setItem('showAlert', true)
    window.localStorage.setItem('showSmartBanner', true)
    window.localStorage.setItem('openAppModal', true)
    
    // Start observing changes within the entire body
    this.bodyObserver = new MutationObserver(this.handleOTBannerAdded.bind(this));
    this.bodyObserver.observe(document.body, { childList: true, subtree: true });
  }

  componentWillUnmount() {
    // Stop observing when the component unmounts
    if (this.bodyObserver) {
      this.bodyObserver.disconnect();
    }
  }

  isDonorLoggedIn() {
    const { loggedInUser, loggedInSession } = this.props
    // alert(history.location.pathname + '  --- logged in data --- ' + JSON.stringify(loggedInUser))
    return loggedInUser && loggedInSession && loggedInUser.loginId
  }

  isAdminLoggedIn() {
    const { loggedInUser, loggedInSession } = this.props
    return loggedInUser && loggedInSession && loggedInUser.role === 'Admin'
  }

  isCenterManager(){
    const { loggedInUser, loggedInSession } = this.props
    return loggedInUser && loggedInSession && loggedInUser.systemRole === 'Manager'
  }

  alertModal() {
    const { inactiveAlertOpen } = this.state
    const { sessionRefresh, logout, logoutTimeout,t } = this.props
    return (
      <AlertModal
        open={inactiveAlertOpen}
        title={t('youAreAboutToSignOut')}
        message={t('forSecurityReasons')}
        timedAction={{
          timer: 4 * 60 * 1000,
          callback: () =>
            this.setState({ inactiveAlertOpen: false }, () => {
              window.sessionStorage.setItem('logoutTimeOut', true)
              logout()
            })
        }}
        actions={[
          {
            text: t('continue'),
            primary: true,
            callback: () => this.setState({ inactiveAlertOpen: false }, () => sessionRefresh())
          }
        ]}
      />
    )
  }

  render() {
    const isAuthenticated = this.isDonorLoggedIn()
    const isAdminLoggedIn = this.isAdminLoggedIn()
    const isCenterManager = this.isCenterManager();
    const currentUrl = this.history.location.pathname
    const modifiedUrl = currentUrl.substring(0, 3) === '/us' ? currentUrl.substring(3).length === 0 ? '/' : currentUrl.substring(0, 4) === '/us/' ? currentUrl.substring(3) : null : null
    const {i18n} =this.props
    return (
      <Router history={this.history}>
        <ThemeProvider theme={theme}>
          <AppWrapper logged={isAuthenticated}>
            <Helmet
              defer={false}
              htmlAttributes={{ lang: i18n.language }}
              encodeSpecialCharacters
              defaultTitle={process.env.APP_NAME}
              titleTemplate={`%s | ${process.env.APP_NAME} Plasma Services`}
              titleAttributes={{ itemprop: 'name', lang: i18n.language  }}
            />
            {this.alertModal()}
            {/* All the RoutePublic route components should not have the isAuthenticated prop.
            isAuthenticated prop should only be passed on to RoutePrivate route components. */}
            <Switch>
              <RoutePublic path='/' exact component={LogIn} />
              <Redirect from="/:url*(/+)" to={currentUrl.slice(0, -1)} />
              <RoutePublic path='/authorize' exact component={AuthSignInAuthorize} />
              <RoutePublic path='/logout' exact component={LogOut} />

              <RoutePublic path='/reset-password' exact component={AuthResetPassword} />

              <RoutePrivate path='/center-ops/donor-search' exact isAuthenticated={isAdminLoggedIn} component={CenterOps} />
              <RoutePrivate path='/center-ops/calendar' exact isAuthenticated={isAdminLoggedIn} component={CenterOpsCalendar} />
              <RoutePrivate path='/center-ops/reports' exact isAuthenticated={isAdminLoggedIn} component={CenterOpsReports} />
              <RoutePrivate path='/center-ops/potential-donors' exact isAuthenticated={isAdminLoggedIn} component={LeadGenReport} />
              <RoutePrivate path='/center-ops/setup' exact isAuthenticated={isAdminLoggedIn} component={CenterOpsSetup} />
              <RoutePrivate path='/center-ops/access-management' exact isAuthenticated={isAdminLoggedIn && isCenterManager} component={CenterOpsAccessManagementView} />
              <RoutePrivate path='/center-ops/create-access-management' exact isAuthenticated={isAdminLoggedIn && isCenterManager} component={CenterOpsAccessManagementCreateForm} />
              <RoutePrivate path='/center-ops/:id' exact isAuthenticated={isAdminLoggedIn} component={CenterOpsDonorView} />
              <RoutePrivate path='/appointment-scheduling' exact isAuthenticated={isAuthenticated} component={AppointmentScheduling} />
              
              <RoutePublic path='/login' exact component={LogIn} />
              
              {
                modifiedUrl || `${modifiedUrl}/` ? (
                  <RoutePublic path={currentUrl} >
                    <Redirect to={modifiedUrl} />
                  </RoutePublic>
                ) : <RoutePublic path="" component={NotFound} />
              }
              
              <RoutePublic path="" component={NotFound} />
            </Switch>
          </AppWrapper>
        </ThemeProvider>
      </Router>
    )
  }
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users.user,
  loggedInSession: state.users.session
})

const mapDispatchToProps = (dispatch) => ({
  initializeApp: () => dispatch(StartupActions.initializeApp()),
  getLoggedInUser: () => dispatch(UserActions.getLoggedInUser()),
  sessionRefresh: () => dispatch(UserActions.sessionRefresh()),
  logoutTimeout: () => dispatch(UserActions.logoutTimeout()),
  logout: () => dispatch(UserActions.logout())
})

export default hot(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation('Index')(App))
)
