import React, { useEffect } from "react";
import { connect } from "react-redux";
import importer from "@takedapdt/biolife-core/src/importer";
import { Container } from "semantic-ui-react";

import "./styles.scss";

const HeroEmailConfirmation = importer("Components/HeroEmailConfirmation");

const { ArticleByIdentifier } = importer("Stores/PublicContent/Select", null);
const { webArticles } = importer("Helpers/WebArticles");

const EmailCofirmationContainer = ({ webContents }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const heroContent = ArticleByIdentifier(
    webContents,
    webArticles.EMAIL_CONFIRMATION
  );

  const expectContent = ArticleByIdentifier(
    webContents,
    webArticles.WHAT_TO_EXPECT
  );

  const step3RegistrationPageHeading = ArticleByIdentifier(
    webContents,
    webArticles.STEP3_REGISTRATIONPAGE_TITLE
  );

  return (
    <div>
      <div className="EmailCofirmationContainer">
        <HeroEmailConfirmation content={heroContent} registrationStep3Heading={step3RegistrationPageHeading} />
        <div className="MainContent">
          <Container>
            <div
              dangerouslySetInnerHTML={{ __html: expectContent?.content__c }}
            />
          </Container>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ publicContent }) => ({
  webContents: publicContent.WebArticles?.completeArticles,
});

export default connect(mapStateToProps, {})(EmailCofirmationContainer);
