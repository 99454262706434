import React from 'react'
import { withTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'
import { Grid, Dropdown, Header } from 'semantic-ui-react'
import './styles.scss'
import { changeCenterOptions } from './helperFactory'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

class ScheduleAppointment extends React.Component {
  constructor (props) {
    super(props)

    this.state = { bioCentersFound: true }
  }

  testEmptyNonEmpty = bioCentersFound => {
    this.setState({ bioCentersFound: !bioCentersFound })
  }

  render () {
    const { t} = this.props;
    var bioCentersFound = this.state.bioCentersFound

    return (
      <Grid className='ScheduleAppointment'>
        {bioCentersFound ? (
          <div>
            <Grid.Row>
              <Header as='h4'>
               {t('pleaseSelectADonationCenter')}
              </Header>
            </Grid.Row>
            <Grid.Row className='Content'>
              <Dropdown
                className='Dropdown'
                placeholder={t('changeCenter')}
                fluid
                search
                selection
                options={changeCenterOptions}
              />
            </Grid.Row>
          </div>
        ) : (
          <div>
            <Grid.Row className='NoCentersContent'>
              {t('noCentersInYourArea')}
            </Grid.Row>
          </div>
        )}
        <Grid.Row className='TestingPurposesButton'>
          <PrimaryButton
            text='Test Empty/Non-Empty Scenario'
            width={'40%'}
            classType='Primary'
            fontSize='16px'
            onClick={() => this.testEmptyNonEmpty(bioCentersFound)}
          />
        </Grid.Row>
      </Grid>
    )
  }
}

export default withTranslation('ScheduleAppointment')(ScheduleAppointment)
