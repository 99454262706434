import React from 'react'
import { Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';

import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryInput = importer('Components/Inputs/PrimaryInput')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const validate = importer('Helpers/Validations')

const SubscribeForm = () => {
  const { t } = useTranslation('SubscribeForm');
  const onSubmit = ({ email }) => {
    // Implementation here
  }
  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group widths='equal'>
            <Field name='email'>
              {({ input, meta }) => (
                <Form.Field required>
                  <PrimaryInput
                    width={232}
                    placeholder={t('email')}
                    error={!!(meta.touched && meta.error)}
                    {...input}
                  />
                </Form.Field>
              )}
            </Field>
            <div className='MessageButtonContainer'>
              <PrimaryButton
                text={t('stay')}
                width={180}
                classType='Primary'
              />
            </div>
          </Form.Group>
        </Form>
      )}
    />
  )
}

export default SubscribeForm
