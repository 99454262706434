import React, { useRef } from 'react'
import { Grid, Container, Ref, Sticky, Visibility } from 'semantic-ui-react'

import './styles.scss'
import { useTranslation } from 'react-i18next'

import importer from '@takedapdt/biolife-core/src/importer'
const AppointmentHours = importer('Components/Dashboard/AppointmentHours')
const SubmitAppointment = importer('Components/Dashboard/SubmitAppointment')

const DateTimePicker = ({
  appointment,
  handleAppointment,
  toggleModal,
  availableSlots,
  centerSlotsFailure,
  centerSlotsLoading
}) => {
  const contextRef = useRef(null)
  // *** PD-6836 Move error messages close to calendar//  ***
  const shouldShowFailure = centerSlotsFailure?.code === 'DayIsOutsideSchedulingWindowRule' || centerSlotsFailure?.code === 'DayIsInPastPotentialAppointmentDateRule';
  const { t,i18n } = useTranslation('DateTimepicker');
  const dateTimePickerClass = `DateTimePicker ${centerSlotsFailure ? '' : 'withPadding'}`;
  return (
    <div className='DateTimePickerContainer'>
      <div className={dateTimePickerClass}>
        {shouldShowFailure ? (
          <div className='centerSlotsFailureContainer'>
            <div className='centerSlotsFailure'>
              <p className='centerSlotsFailureMesssage'>{centerSlotsFailure?.message}</p>
            </div>
          </div>
        ) : (
          <>
          {!centerSlotsFailure  && (
            <h2 className='SelectYourAppointment'>{t("SelectYourAppointment")}</h2>
          )}
            <Ref innerRef={contextRef}>
              <Container fluid>
                <Grid columns={2} divided>
                  <Grid.Column computer={12} mobile={16}>
                    <Visibility>
                      <AppointmentHours
                        appointment={appointment}
                        handleAppointment={handleAppointment}
                        availableSlots={availableSlots}
                        centerSlotsLoading={centerSlotsLoading}
                      />
                    </Visibility>
                  </Grid.Column>
                  <Grid.Column computer={4} mobile={16} style={{  zIndex: availableSlots?.length > 0 ? 1 : 'unset',
                      boxShadow: availableSlots?.length > 0 ?     ' -1px 0 0 0 rgba(34,36,38,.15)' :'none' }}>
                    {availableSlots?.length > 0 && (
                      <Sticky context={contextRef}>
                        <SubmitAppointment
                          appointment={appointment}
                          toggleModal={toggleModal}
                        />
                      </Sticky>
                    )}
                  </Grid.Column>
                </Grid>
              </Container>
            </Ref>
          </>
        )}
      </div>
    </div>
  )
}

export default DateTimePicker;
