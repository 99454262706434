import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react'
import { connect } from 'react-redux'
import './styles.scss'
import { mapOptionsByState, filterNonNomogramCenters, sortCentersAlphabetically } from '../../../Helpers/Utils'
import importer from '@takedapdt/biolife-core/src/importer'

// Actions
const centersActions = importer('Stores/Centers/Actions')
const donorsActions = importer('Stores/Donors/Actions')

const SelectSearch = importer('Components/Inputs/SelectSearch')

const CenterNoSelected = ({ centers,loggedInUser, donor, updateDonorSuccess, updateDonor,  getDonorNearbyCenters, getCenterDetails }) => {
  const { t } = useTranslation('CenterNoSelected');
  const [selectedCenter, setSelectedCenter] = useState(null)
  const [centerOptions, setCenterOptions] = useState([])

  const preparedOptions = async () => {
      const nomoFilteredCenters = filterNonNomogramCenters(centers, donor)
      const res = await mapOptionsByState(nomoFilteredCenters,false,loggedInUser)
      const data = sortCentersAlphabetically(res)
    return data
  }

  const handleSelection = async ({ value }) => {
    setSelectedCenter(value)
    await updateDonor(donor.id, {
      donorDetails: {
        donationCenter: value
      }
    }, true)
  }
  useEffect(() => {
    preparedOptions()
    .then(res => setCenterOptions(res))
  },[])

  useEffect(() => {
    if (updateDonorSuccess && selectedCenter) {
      getCenterDetails(selectedCenter)
      getDonorNearbyCenters(donor.sfid)
    }
  }, [updateDonorSuccess])

  return (
    <div className='CenterNoSelected'>
      <Header as='h2' className='MainHeader'>
       {t('pleaseselectaDonationCenter')}
      </Header>
      <div className='CenterSelectionContainer'>
        <SelectSearch
          options={centerOptions}
          centers={centers}
          handleOnChange={handleSelection}
          className='CenterDropdown'
          placeholder={t('chooseADonationCenter')}
        />
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  centers: state?.centers?.nearbyCenters,
  loggedInUser: state?.users?.user,
  donor: state.donors?.donors?.data,
  updateDonorSuccess: state?.donors?.updateDonorSuccess
})

const mapDispatchToProps = (dispatch) => ({
  getCenterDetails: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
  getDonorNearbyCenters: (donorSfid) => dispatch(centersActions.getDonorCenterInfo(donorSfid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CenterNoSelected)
