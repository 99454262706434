import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
    const errors = {}
    // First name validation
    if (values.firstName) {
        if (values.firstName.length < 1) {
            errors.firstName = 'firstNameShouldHaveAtLeasst1Char'
        } else if (values.firstName.length === 1 && values.firstName.match(/^[a-zA-Z]$/i) === null) {
          errors.firstName = 'firstNameShouldBeCharacteterOnly'
        } else if (values.firstName.length > 1 && values.firstName.match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null) {
            errors.firstName = 'firstNameShouldBeCharacteterOnly'
        }
    } else {
        errors.firstName = 'firstNameIsRequired'
    }
    // Last name validation
    if (values.lastName) {
        if (values.lastName.length < 2) {
            errors.lastName = 'lastNameShouldHaveAtLeast2Char'
        } else if (values.lastName.match(/^(?!.*(['ʻ,. -]){2})([a-zA-Z])([a-zA-Z'ʻ,. -]*[a-zA-Z])$/i) === null) {
            errors.lastName = 'lastNameShouldBeCharacter'
        }
    } else {
        errors.lastName = 'lastNameIsRequired'
    }
    // Email validation
    if (values.email) {
        if (!isValidEmail(values.email)) {
            errors.email = 'emailAddressMustBeInValid'
        } else if (values.email.indexOf('@takeda.com') > -1 || values.email.indexOf('@shire.com') > -1) {
            errors.email = 'pleaseEnterANonEmployeeEmail'
        }
    } else {
        errors.email = 'emailAddressIsRequired'
    }
    // Postal code validation
    if (values.zipCode) {
        if (!/^\d{5}$/.test(values.zipCode)) {
            errors.zipCode = 'zipCodeShouldHave5Digits'
        }
    } else {
        errors.zipCode = 'zipCodeIsRequired'
    }
    return errors
}

export default validate
