import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, Icon, Message, Modal, Header, Image, Grid } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';
import './styles.scss'
import Helmet from 'react-helmet'
import { isEmpty } from 'lodash'
const  ProductionText= importer('Components/ProductionText')
const { webArticles } = importer('Helpers/WebArticles')
const { ArticleByIdentifier } = importer  ('Stores/PublicContent/Select', null)
const { pageMetaArticles } = importer('Helpers/menuBuilder')
import importer from '@takedapdt/biolife-core/src/importer'
import validate from './validate'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import infoIcon from '../../../../assets/media/images/info-icon.png'
import { filterNonNomogramCentersPublicPage } from "../../../Helpers/Utils"
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const PrimaryTextArea = importer('Components/Inputs/PrimaryTextArea')
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')

const publicContentActions = importer('Stores/PublicContent/Actions')
const CentersActions = importer('Stores/Centers/Actions')

const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const ContactForm = ({
  width,
  getCountries,
  getAllStates,
  saveContactForm,
  saveContactFormSuccess,
  saveContactFormError,
  saveContactFormLoading,
  states,
  getAllCenters,
  countries,
  centers,
  webArticlesContent
}) => {
  const { t, i18n } = useTranslation('ContactForm','ValidationErrorMessages');
  const [stateOptions, setStateOptions] = useState([])
  const [centerOptions, setCenterOptions] = useState([])
  const statusOptions = [
    { key: 'Potential BioLife Donor', value: 'Potential BioLife Donor', text: t('potentialBioLifeDonor') },
    { key: 'Former BioLife Donor', value: 'Former BioLife Donor', text: t('formerBioLifeDonor') },
    { key: 'Existing BioLife Donor', value: 'Existing BioLife Donor', text: t('existingBioLifeDonor') },
    { key: 'Not Applicable', value: 'Not Applicable', text: t('notApplicable') }
  ]
  const subjectOptions = [
    {
      key: 'Login Questions', category: t('loginQuestions'), text: t('loginQuestions'), header: true, subcategory: [
        { key: 'didNotRecieveVeriEmail', value: 'I did not receive verification Email', text: t('didNotRecieveVeriEmail') },
        { key: 'iCanNotResetMyPassword', value: 'I can not reset my password', text: t('iCanNotResetMyPassword') },
        { key: 'iRecievedAnErrorMessageDuringLogin', value: 'I received an error message during login', text: t('iRecievedAnErrorMessageDuringLogin') },
        { key: 'otherLoginQuestions', value: 'Other Login Questions', text: t('otherLoginQuestions') }]
    },
    { key: 'schedulingQuestions', value: 'Scheduling Questions', text: t('schedulingQuestions'), header: false },
    { key: 'customerServiceQuestions', value: 'Customer Service Questions', text: t('customerServiceQuestions'), header: false },
    { key: 'couponPaymentQuestions', value: 'Coupon/Payment Questions', text: t('couponPaymentQuestions'), header: false },
    { key: 'eligibilityToDonateQuestions', value: 'Eligibility to Donate Questions', text: t('eligibilityToDonateQuestions'), header: false },
    { key: 'giftCardQuestions', value: 'Gift Card Questions', text: t('giftCardQuestions'), header: false },
    { key: 'loyaltyProgramQuestions', value: 'Loyalty Program Questions', text: t('loyaltyProgramQuestions'), header: false },
    { key: 'otherQuestions', value: 'Other Questions', text: t('otherQuestions'), header: false },
   
  ]
  const [saveClicked, setSaveClicked] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [selectedCenter, setSelectedCenter] = useState(null)
  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedSubject, setSelectedSubject] = useState(null)
  const [selectedStateCode, setSelectedStateCode] = useState(null)
  const [selectedStateText, setSelectedStateText] = useState(null)

  const contactUsMeta =  ArticleByIdentifier(
    webArticlesContent,
    pageMetaArticles.CONTACT_US_FORM_META
  )

  useEffect(() => {
    getCountries()
    getAllStates()
    getAllCenters()
  }, [])

  useEffect(() => {
    if (saveContactFormSuccess && saveClicked) {
      setOpenModal(true)
    }
  }, [saveContactFormSuccess])

  useEffect(() => {
    const sortedStates = states?.slice()?.sort((a, b) => a.description.localeCompare(b.description));
    states && setStateOptions(sortedStates.map((item, index) => ({
      key: index,
      value: item.code,
      text: item.description
    })))
  }, [states])

  useEffect(() => {
    const filteredCenters = centers?.filter(center => selectedStateCode===center.stateCode)
    if(filteredCenters?.length > 0){
      const filterRes = filterNonNomogramCentersPublicPage(filteredCenters)
      setCenterOptions(filterRes?.map((item, index) => ({
        key: index,
        value: item.sfid,
        text: <label className='CenterLocationLabel'>
                <label className='LabelCenterName'>{item.name + ', '+ item.stateCode}</label> 
                <label className='LabelCenterAddress'>{item.addressLine1} {item.addressLine2} {item.city}, {item.stateCode} {item.zipcode}</label>
              </label>
      })))
    }
    else{
      setCenterOptions([{
        key: 0,
        value: "Other",
        text: "Other"
      }])
    }
  },[selectedStateCode])
  
  useEffect(() => {
   setCenterOptions([])
    if(states?.length > 0){
      const sortedStates = states?.slice()?.sort((a, b) => a.description.localeCompare(b.description));
      states && setStateOptions(sortedStates.map((item, index) => ({
        key:index,
        value: item.code,
        text: item.description
      })))
    }
  },[])



  const handleStateChange = (value) =>{
    setSelectedStateCode(value)
    const state= states.find(state => state.code === value)
    setSelectedStateText(state.description)
  }

  const onSubmit = (values) => {
    setSaveClicked(true)
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailId,
      zipCode: values.zipCode,
      subject: values.subject,
      customSubjectLine: values.subject==="Other Questions" ? values.customSubjectLine : null ,
      description: values.message,
      phone: values.phone,
      center: selectedCenter==='Other'?null:selectedCenter,
      state: selectedStateText,
      status: selectedStatus
    }
    saveContactForm(formData)
    setSelectedStateCode(null)
    setSelectedStateText(null)
    setSelectedSubject(null)
  }


  return (
    <div className="ContactForm">
      {!isEmpty(contactUsMeta) && (
        <Helmet>
          <title>{`${contactUsMeta?.meta_title__c}`}</title>
          <meta
            name="description"
            content={`${contactUsMeta && contactUsMeta?.meta_description__c}`}
          />
        </Helmet>
      )}
      <p className="FormNote">
        <sup>
          <Icon className="RequiredInfo" size="tiny" name="asterisk" />
        </sup>
        {t("requiredField")}
      </p>
      {!openModal && (
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit} style={{ width }}>
              <Form.Group widths="equal">
                <Field name="firstName">
                  {({ input, meta }) => (
                    <InputField
                      meta={meta}
                      {...input}
                      height={50}
                      fieldLabel={t("firstName")}
                    />
                  )}
                </Field>
                <Field name="lastName">
                  {({ input, meta }) => (
                    <InputField
                      meta={meta}
                      {...input}
                      height={50}
                      fieldLabel={t("lastName")}
                    />
                  )}
                </Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Field name="emailId">
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={50}
                      fieldLabel={t("email")}
                    />
                  )}
                </Field>
                <Field name="phone">
                  {({ input, meta }) => (
                    <InputField
                      meta={meta}
                      {...input}
                      height={50}
                      type="number"
                      fieldLabel={t("phone")}
                    />
                  )}
                </Field>
              </Form.Group>

              <Form.Group widths="equal">
                <Field name="state">
                  {({ input, meta }) => (
                    <SelectField
                      required
                      meta={meta}
                      input={input}
                      fieldLabel={t("stateOrProvince")}
                      options={stateOptions}
                      placeholder={t("select")}
                      handleOnChange={(value) => {
                        input.onChange(value);
                        handleStateChange(value);
                      }}
                    />
                  )}
                </Field>
                <Field name="zipCode">
                {({ input, meta }) => (
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    type="number"
                    fieldLabel={t("zipCode")}
                  />
                )}
              </Field>
              </Form.Group> 
              <Field name="center">
                {({ input, meta }) => (
                  <SelectField
                    required
                    meta={meta}
                    input={input}
                    disabled={!selectedStateCode}
                    fieldLabel={t("centerLocation")}
                    placeholder={t("select")}
                    options={centerOptions}
                    handleOnChange={(value) => {
                      input.onChange(value);
                      setSelectedCenter(value);
                    }}
                  />
                )}
              </Field>
              <Field name="status">
                {({ input, meta }) => (
                  <SelectField
                    required
                    meta={meta}
                    input={input}
                    fieldLabel={t("status")}
                    placeholder={t("select")}
                    options={statusOptions}
                    handleOnChange={(value) => {
                      input.onChange(value);
                      setSelectedStatus(value);
                    }}
                  />
                )}
              </Field>
              <Field name="subject">
                {({ input, meta }) => (
                  <SelectField
                    required
                    meta={meta}
                    input={input}
                    fieldLabel={t("subject")}
                    placeholder={t("select")}
                    groupSelect={true}
                    options={subjectOptions}
                    defaultValue={selectedSubject}
                    handleOnChange={(value) => {
                      input.onChange(value);
                      setSelectedSubject(value);
                    }}
                    className={selectedSubject === null ? "SelectStyle" : " "}
                  />
                )}
              </Field>
              {selectedSubject &&
                (selectedSubject.includes("error message") ||
                  selectedSubject.includes("Login Questions")) && (
                  <p className="FormNote HelpText">
                    {selectedSubject.includes("error message") && (
                      <i>{t('pleaseProvideAScreenshot')}</i>
                    )}
                    {selectedSubject.includes("Login Questions") && (
                      <i>{t('pleaseDescribeYourLoginIssue')}</i>
                    )}
                  </p>
                )}
              {selectedSubject === "Other Questions" && (
                <Field name="customSubjectLine">
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={50}
                      fieldLabel={t("typeSubject")}
                      className="TypeSubject"
                    />
                  )}
                </Field>
              )}
              <Field name="message">
                {({ input, meta }) => (
                  <Form.Field required className="FormField">
                    <PrimaryTextArea
                      required
                      {...input}
                      fieldLabel={t("message")}
                      height={155}
                      error={!!(meta.touched && meta.error)}
                      meta={meta}
                    />
                  </Form.Field>
                )}
              </Field>
              <div className="MessageButtonContainer">
                {saveClicked && (
                  <div>
                    {saveClicked && saveContactFormLoading && <LoadingState />}
                    {saveClicked && saveContactFormError && (
                      <Message warning visible>
                        <Message.Header>{t("serviceError")}</Message.Header>
                        <p>{t("errorSavingForm")}</p>
                      </Message>
                    )}
                  </div>
                )}
                <br />
                <PrimaryButton
                  text={t("submit")}
                  type="submit"
                  width={180}
                  classType="Primary"
                />
              </div>
            </Form>
          )}
        />
      )}
      <Modal open={openModal} size="small">
        <Modal.Header className="HeaderContainer">
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as="h1" className="ModalHeader">
                {t("thankYouForYourInquiry")}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign="right">
              <Image
                src={CloseIcon}
                alt="Close"
                onClick={() => {
                  setOpenModal(false);
                }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <p>{t("bioLifeRepresentativeRespond")}</p>
          <p>{t("pleaseBeAdvised")}</p>
          <p>{t("contactYourCenter")}</p>
        </Modal.Content>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  countries: state.publicContent.Countries,
  states: state.publicContent.States,
  centers: state.centers.donorCenters?.data,
  saveContactFormSuccess: state.publicContent.saveContactFormSuccess,
  saveContactFormError: state.publicContent.saveContactFormFailure,
  saveContactFormLoading: state.publicContent.saveContactFormLoading,
  webArticlesContent: state.publicContent.WebArticles?.completeArticles
})

const mapDispatchToProps = (dispatch) => ({
  saveContactForm: formData => dispatch(publicContentActions.saveContactForm(formData)),
  getCountries: () => dispatch(publicContentActions.getCountries()),
  getAllStates: () => dispatch(publicContentActions.getAllStates()),
  getAllCenters: () => dispatch(CentersActions.getDonorCentersAll()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactForm)
