import React from 'react'
import { Header, Grid, Table, Pagination, Dropdown } from 'semantic-ui-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import { formatPhoneNumber } from '../../../Helpers/Utils'
import './styles.scss'

const LeadGenReportResults = ({
  page,
  totalPages,
  searched,
  operations,
  onExport,
  onChangePage
}) => {
  const { t } = useTranslation('LeadGenReportResults');
  if (!searched) {
    return null
  }

  const exportOptions = [
    { key: '1', value: 'pdf', text: t('pdf') },
    { key: '2', value: 'excel', text: t('excel') }
  ]

  if (operations) {
    const {
      centerCurrentTime,
      centerAddress,
      reportFromDate,
      reportToDate,
      donors
    } = operations
    return (
      <div className='LeadGenReportsSearchResults'>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column verticalAlign='middle'>
              <Header as='h4' className='Header'>{t('reportResults')}</Header>
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <Dropdown
                className='Export'
                item
                icon='chevron down'
                text={t('export')}
                name='potential donor report'
                disabled={totalPages === 0}
              >
                <Dropdown.Menu>
                  {exportOptions.map(item => 
                    <Dropdown.Item key={item.key} onClick={(e,{value})=> onExport(value)} value={item.value} text={item.text} />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign='bottom'>
              <p className='RegularText'>
                <b>{t('reportDates')}</b>{' '}
                {moment.utc(reportFromDate).format('MMMM D, YYYY')} to{' '}
                {moment.utc(reportToDate).format('MMMM D, YYYY')}
              </p>
              <p className='RegularText'>
                <b>{t('center')}</b> {centerAddress}
              </p>
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='bottom'>
              <p className='RegularText'>
                <b>{t('reportGenerated')}</b>{' '}
                {moment(centerCurrentTime).format('MMMM D, YYYY [at] hh:mmA')}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className='Results'>
          <div className='ResultsTableContainer'>
            {donors?.length === 0 ? (
                  <div className='NoResult'>
                    {t('noresultsfound')}
                  </div>
                ) :
            <Table basic='very' selectable>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>{t('donorName')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('donorPhone')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('donorEmail')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('languageSupport')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('appointmentDate')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('profileCreated')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('profileStatus')}</Table.HeaderCell>
                  <Table.HeaderCell>{t('oktoContact')}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {donors.map((donor, i) => (
                  <Table.Row key={`search-donor-${i}`}>
                    <Table.Cell>{donor.donorName}</Table.Cell>
                    <Table.Cell>{formatPhoneNumber(donor.donorPhone)}</Table.Cell>
                    <Table.Cell className='TableEmailCol'>{donor.donorEmail}</Table.Cell>
                    <Table.Cell>{donor.language === 'es-MX' ? 'Español' : ''}</Table.Cell>
                    <Table.Cell>{donor.appointmentDate ? donor.appointmentDate : ''}</Table.Cell>
                    <Table.Cell>{donor.profileCreated ? donor.profileCreated : ''}</Table.Cell>
                    <Table.Cell>{donor.profileStatus}</Table.Cell>
                    <Table.Cell>{donor.okToContact}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            }
          </div>
          {totalPages > 0 && (
            <div className='PaginationContainer'>
              <span className='PageText'>{t('PAGE')}</span>
              <Pagination
                secondary
                boundaryRange={3}
                activePage={page}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={totalPages}
                onPageChange={onChangePage}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  return null
}

export default LeadGenReportResults
