import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Form, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next';

import validate from './validate'
import { isEmpty, isArray } from 'lodash'

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

/* ACTIONS */
const userActions = importer('Stores/Users/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const centersActions = importer('Stores/Centers/Actions')

const SignUpFields = ({
    saveSignUp,
    registerDonor,
    registerDonorSuccess,
    registerDonorFailure,
    registerDonorLoading,
    centers,
    getDonorCentersAll
}) => {
    const history = useHistory()
    const [submited, setSubmited] = useState(false)
    const [errorMessages, setErrorMessages] = useState([])
    const fieldsWidths = window.innerWidth < 992 ? 322 : 200
    const [signUpClicked, setSignUpClicked] = useState(false)
    const { t } = useTranslation('SignUpForm');

    const handleRegisterDonor = async donor => {
        await registerDonor(donor)
        saveSignUp(donor)
        setSubmited(true)
    }

    // Submit handler
    const onSubmit = values => {
        setSignUpClicked(true)
        const { firstName, lastName, email, zipCode = true } = values
        const donor = {
            donorDetails: {
                firstName,
                lastName,
                email: email.toLowerCase(),
                zipCode,
                gclickId: getGclickId()
            }
        }
        getDonorCentersAll(zipCode, '', 'pubDate')
        handleRegisterDonor(donor)
    }
    const getGclickId = () => {
        var b = document.cookie.match("(^|;)\\s*" + "_gcl_aw" + "\\s*=\\s*([^;]+)");
        return b ? b.pop().split(".")[2] : null;
    }

    //checks for registration success and centers available to given zip code
    useEffect(() => {
        if (registerDonorSuccess != null && submited) {
            const lead = { ...registerDonorSuccess.data }
            // TagManager.dataLayer({
            //     dataLayer: {
            //         event: 'profileRegistration',
            //         profileRegistrationStage: 'profile lead generated',
            //         donorZip: lead.zipCode,
            //         donorId: lead.id,
            //         bdpDonorId: lead.bdpDonorId || null
            //     }
            // })
            window.sessionStorage.setItem('lead', JSON.stringify(lead))
            setSubmited(false)
            if (!isEmpty(centers)) {
                history.push('/create-account')
            } else {
                history.push('/no-center-zone')
            }
        }
    }, [registerDonorSuccess])

    // checks for registration failure error
    useEffect(() => {
        if (registerDonorFailure !== null && submited) {
            console.log('Error: ', registerDonorFailure)
            if (isArray(registerDonorFailure.message)) {
                setErrorMessages(registerDonorFailure.message)
            } else {
                setErrorMessages([registerDonorFailure.message])
            }

            setSubmited(false)
        }
    }, [registerDonorFailure])

    return (
        <div className='SignUpFields'>
            <FinalForm
                onSubmit={onSubmit}
                validate={validate}
                render={({ handleSubmit, hasValidationErrors }) => (
                    <Form onSubmit={handleSubmit}>
                        <p className='RequiredFieldText'>{t('requiredField')}</p>
                        <Form.Group>
                            <Field name='firstName'>
                                {({ input, meta }) => (
                                    <InputField
                                        required
                                        meta={meta}
                                        {...input}
                                        width={fieldsWidths}
                                        height={64}
                                        fieldLabel={t('firstName')}
                                        className='SignUpInput'
                                    />
                                )}
                            </Field>

                            <Field name='lastName'>
                                {({ input, meta }) => (
                                    <InputField
                                        required
                                        meta={meta}
                                        {...input}
                                        width={fieldsWidths}
                                        height={64}
                                        fieldLabel={t('lastName')}
                                        className='SignUpInput'
                                    />
                                )}
                            </Field>
                        </Form.Group>
                        <Form.Group>
                            <Field name='email'>
                                {({ input, meta }) => (
                                    <InputField
                                        required
                                        meta={meta}
                                        {...input}
                                        width={fieldsWidths}
                                        height={64}
                                        fieldLabel={t('email')}
                                        className='SignUpInput'
                                    />
                                )}
                            </Field>

                            <Field name='zipCode'>
                                {({ input, meta }) => (
                                    <InputField
                                        required
                                        meta={meta}
                                        {...input}
                                        width={fieldsWidths}
                                        height={64}
                                        fieldLabel={t('zipCode')}
                                        className='SignUpInput'
                                    />
                                )}
                            </Field>
                        </Form.Group>
                        <Form.Group>
                            <div className='SignUpButtonContainer'>
                                <PrimaryButton
                                    text={t('signup')}
                                    width={200}
                                    height={52}
                                    fontSize={16}
                                    disabled={ hasValidationErrors || (registerDonorLoading && signUpClicked)}
                                />
                            </div>
                        </Form.Group>

                        {registerDonorLoading && signUpClicked && (
                            <LoadingState />
                        )}
                    </Form>
                )}
            />
            {!isEmpty(errorMessages) && (
                <Message error style={{ maxWidth: 396 }}>
                    {errorMessages.map((message, index) => (
                        <p key={index}>{message}</p>
                    ))}
                </Message>
            )}
        </div>
    )
}

const mapStateToProps = state => ({
    registerDonorSuccess: state.donors.registerDonorSuccess,
    registerDonorFailure: state.donors.registerDonorFailure,
    registerDonorLoading: state.donors.registerDonorLoading,
    centers: state.centers.donorCenters?.data
})

const mapDispatchToProps = dispatch => ({
    saveSignUp: user => dispatch(userActions.saveSignUp(user)),
    registerDonor: donor => dispatch(donorsActions.registerDonor(donor)),
    getDonorCentersAll: (searchStr, isDIS8Center, searchType) =>
        dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignUpFields)
