import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form, Icon, Message, Modal, Header, Image, Grid } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import './styles.scss'
import { useTranslation } from 'react-i18next';

import importer from '@takedapdt/biolife-core/src/importer'
import validate from './validate'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import infoIcon from '../../../../assets/media/images/info-icon.png'
import { filterNonNomogramCentersPublicPage } from "../../../Helpers/Utils"

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const PrimaryTextArea = importer('Components/Inputs/PrimaryTextArea')

const CentersActions = importer('Stores/Centers/Actions')
const publicContentActions = importer('Stores/PublicContent/Actions')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const YourStoryForm = ({
  width,
  centers,
  getAllCenters,
  saveContactForm,
  saveContactFormSuccess,
  saveContactFormError,
  saveContactFormLoading,
  countries,
  states,
  getCountries,
  getAllStates
}) => {
  const { t } = useTranslation('YourStoryForm', 'ValidationErrorMessages');

  const [centerOptions, setCenterOptions] = useState([])
  const [saveClicked, setSaveClicked] = useState(false)
  const [stateOptions, setStateOptions] = useState([])
  const [countryOptions, setCountryOptions] = useState([])
  const [selectedCountryCode, setSelectedCountryCode] = useState(null)
  const [selectedCountryText, setSelectedCountryText] = useState(null)
  const [selectedStateCode, setSelectedStateCode] = useState(null)
  const [selectedStateText, setSelectedStateText] = useState(null)

  const [selectedCenter, setSelectedCenter] = useState(null)
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    getAllCenters()
    getCountries()
    getAllStates()
    setSelectedCountryCode('US')
    setSelectedCountryText('United States')
  }, [])

  useEffect(() => {
    const filteredState = states?.filter(state => selectedCountryCode===state.countrycode)
    filteredState && setStateOptions(filteredState.map((item, index) => ({
      key: index,
      value: item.code,
      text: item.description
    })))
  }, [states])

  useEffect(() => {
    const filteredCenters = centers?.filter(center => selectedStateCode===center.stateCode)
    if(filteredCenters?.length > 0){
      const filterRes = filterNonNomogramCentersPublicPage(filteredCenters)
      setCenterOptions(filterRes.map((item, index) => ({
        key: index,
        value: item.sfid,
        text: item.name + ', '+ item.stateCode
      })))
    }
    else{
      setCenterOptions([{
        key: 0,
        value: "Other",
        text: "Other"
      }])
    }
  },[selectedStateCode])

  useEffect(() => {
    if (saveContactFormSuccess && saveClicked) {
      setOpenModal(true)
    }
  }, [saveContactFormSuccess])

  useEffect(() => {
    countries && setCountryOptions(countries.map((item, index) => ({
      key: index,
      value: item.code,
      text: item.description
    })))
  }, [countries])

  const handleCountryChange = (value) =>{
    setSelectedCountryCode(value)
    const country= countries.find(country => country.code === value)
    setSelectedCountryText(country.description)
  }

  const handleStateChange = (value) =>{
    setSelectedStateCode(value)
    const state= states.find(state => state.code === value)
    setSelectedStateText(state.description)
  }
  useEffect(() => {
    setCenterOptions([])
    const filteredState = states?.filter(state => selectedCountryCode===state.countrycode)
    if(filteredState?.length > 0){
      setStateOptions(filteredState.map((item, index) =>({
        key:index,
        value: item.code,
        text: item.description
      })))
    }
  },[selectedCountryCode])


  const onSubmit = (values) => {
    setSaveClicked(true)
    const formData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.emailId,
      phone: values.phone,
      duration: values.duration,
      subject: 'Feedback Form',
      description: values.message,
      center: selectedCenter === 'Other' ? null : selectedCenter,
      country: selectedCountryText,
      state: selectedStateText
    }
    saveContactForm(formData)
  }

  return (
    <div className='YourStoryForm'>
      {!openModal && (
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit }) => (
            <Form
              onSubmit={handleSubmit}
              style={{ width }}
            >
               <p className='FormNote'>
                  <sup>
                    <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                  </sup>
                  {t('requiredField')}
                </p>
              <Form.Group widths='equal'>
                <Field name='firstName'>
                  {({ input, meta }) => (
                    <InputField
                      meta={meta}
                      {...input}
                      height={50}
                      fieldLabel={t('firstName')}
                    />
                  )}
                </Field>
                <Field name='lastName'>
                  {({ input, meta }) => (
                    <InputField
                      meta={meta}
                      {...input}
                      height={50}
                      fieldLabel={t('lastName')}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group widths='equal' className="emailCustomFeild">
              <Field name='emailId'>
                {({ input, meta }) => (
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    fieldLabel={t('email')}
                  />
                )}
              </Field>
              <Field name='phone'>
                {({ input, meta }) => (
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    fieldLabel={t('phone')}
                  />
                )}
              </Field>
              </Form.Group>
              <Field name='duration'>
                {({ input, meta }) => (
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    fieldLabel={t('duration')}
                  />
                )}
              </Field>
              <Form.Group widths='equal'>
              <Field name='country'>
                  {({ input, meta }) => (
                    <SelectField
                      meta={meta}
                      input={input}
                      fieldLabel={t('country')}
                      defaultValue='US'
                      options={countryOptions}
                      handleOnChange={(value) => {
                        input.onChange(value)
                        handleCountryChange(value)
                      }}
                    />
                  )}
              </Field>
              <Field name='state'>
                {({ input, meta }) => (
                  <SelectField
             placeholder={t('select')}
                    meta={meta}
                    input={input}
                    fieldLabel={t('stateOrProvince')}
                    options={stateOptions}
                    handleOnChange={(value) => {
                      input.onChange(value)
                      handleStateChange(value)
                    }}
                  />
                )}
              </Field>
              </Form.Group>
              <Field name='center'>
                {({ input, meta }) => (
                  <SelectField
                    meta={meta}
                    input={input}
                    placeholder={t('select')}
                    fieldLabel={t('center')}
                    options={centerOptions}
                    disabled={!selectedStateCode}
                    handleOnChange={(value) => {
                      input.onChange(value)
                      setSelectedCenter(value)
                    }}
                  />
                )}
              </Field>
              <Field name='message'>
                {({ input, meta }) => (
                  <Form.Field required className='FormField'>
                    {meta?.touched && meta.error && <div className="ErrorMsgContainerMessage"><Image className='InfoIcon'  src={infoIcon} width="18" height="18"/> <span className="ErrorMsg" >{t(`ValidationErrorMessages:${meta.error}`)}</span></div>}
                    <PrimaryTextArea
                      fieldLabel={t('message')}
                      height={295}
                      {...input}
                      name={input.name}
                      error={!!(meta?.touched && meta.error)}
                    />
                  </Form.Field>
                )}
              </Field>
              <div className='MessageButtonContainer'>
                {saveClicked && (
                  <div>
                    {saveClicked && saveContactFormLoading && (
                      <LoadingState />
                    )}
                    {saveClicked && saveContactFormError && (
                      <Message warning visible>
                        <Message.Header>{t('serviceError')}</Message.Header>
                        <p>{t('tryLater')}</p>
                      </Message>
                    )}
                  </div>
                )}
                <br/>
                <PrimaryButton text={t('submit')} width={180} classType='Primary' />
              </div>
            </Form>
          )}
        />
      )}
      <Modal open={openModal} size='small'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h1' className='ModalHeader'>
                {t('thankYou')}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image
                src={CloseIcon}
                alt='Close'
                onClick={() => {
                  setOpenModal(false)
                }}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <p>{t('receivedStories')}</p>
          <p>{t('biolifeIsGrateFul')}</p>
          <p>{t('againThakYou')}</p>
        </Modal.Content>
      </Modal>
    </div>
  )
}

const mapStateToProps = (state) => ({
  centers: state.centers.donorCenters?.data,
  saveContactFormSuccess: state.publicContent.saveContactFormSuccess,
  saveContactFormError: state.publicContent.saveContactFormFailure,
  saveContactFormLoading: state.publicContent.saveContactFormLoading,
  countries: state.publicContent.Countries,
  states: state.publicContent.States,
})

const mapDispatchToProps = (dispatch) => ({
  saveContactForm: formData => dispatch(publicContentActions.saveContactForm(formData)),
  getAllCenters: () => dispatch(CentersActions.getDonorCentersAll()),
  getCountries: () => dispatch(publicContentActions.getCountries()),
  getAllStates: () => dispatch(publicContentActions.getAllStates())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(YourStoryForm)
