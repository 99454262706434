import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Grid, Icon, Message, Modal, Header, Button, Dropdown, Pagination } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import moment from 'moment'
import importer from '@takedapdt/biolife-core/src/importer'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import './styles.scss'

const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
// const FaiureState = importer('Components/Dashboard/StateErrorContainer')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const NoDataState = importer('Components/Dashboard/StateNoDataContainer')
const PaymentsActions = importer('Stores/Payments/Actions')
const BiolifePaymentCardsModal = importer("Components/Dashboard/BiolifePaymentCardsModal")

const DashboardGiftCard = ({
  donor,
  loggedInUser,
  getPaymentHistory,
  paymentHistory,
  paymentHistoryLoading,
  paymentHistoryError
}) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const { t } = useTranslation('DashboardGiftCard');
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [dropDownVal, setdropDownVal] = useState('All')
  const [filteredData, setFilteredData] = useState(null)
  const [dropdownfilteredData, setDropdownfilteredData] = useState(null)
  const [openBiolifePaymentCardsModal, setOpenBiolifePaymentCardsModal] = useState(false);

  //toggle the gift card portal modal
  const toggleBiolifePaymentCardsModal = () => {
    setOpenBiolifePaymentCardsModal(!openBiolifePaymentCardsModal);
  };

  const cardOptions = [
    { key: '1', value: 'All', text: t('all') },
    { key: '2', value: 'Debit', text: t('debit') },
    { key: '3', value: 'Gift', text: t('giftCard') }
  ]

  // to handle start date change
  const handleStartDateChange = (date) => {
    setStartDate(date)
    if (date && endDate) {
      const mtStartDate = moment(date).startOf('day')
      const mtEndDate = moment(endDate).endOf('day')
      if ((mtStartDate <= mtEndDate) && mtStartDate.diff(endDate, 'year') <= 1) {
        if (page != 1)
          setPage(1)
        getPaymentHistory(
          donor?.pdn,
          mtStartDate.format('YYYY-MM-DD'),
          mtEndDate.format('YYYY-MM-DD')
        )
      }
    }
  }

  // to handle end date change
  const handleEndDateChange = (date) => {
    setEndDate(date)
    if (date && startDate) {
      const mtStartDate = moment(startDate).startOf('day')
      const mtEndDate = moment(date).endOf('day')
      if ((mtStartDate <= mtEndDate) && mtStartDate.diff(mtEndDate, 'year') <= 1) {
        if (page != 1)
          setPage(1)
        getPaymentHistory(
          donor?.pdn,
          mtStartDate.format('YYYY-MM-DD'),
          mtEndDate.format('YYYY-MM-DD')
        )
      }
    }
  }


  // filter for page, dropdown vale and paymnet history data change
  const pageDropdownFilter = () => {
    let data = null;
    let currentPage = page;
    let startIndex = (currentPage - 1) * pageSize
    let endIndex = (currentPage - 1) * pageSize + pageSize
    if (!isEmpty(paymentHistory?.data?.paymentHistory)) {
      setFilteredData(null)
      if (dropDownVal === 'Debit') {
        data = paymentHistory?.data?.paymentHistory.filter((item) => item.type !== 6)
        setDropdownfilteredData(data);//to calculate the number of pages based on the entire data
      }
      else if (dropDownVal === 'Gift') {
        data = paymentHistory?.data?.paymentHistory.filter((item) => item.type === 6)
        setDropdownfilteredData(data);
      }
      else {
        data = paymentHistory?.data?.paymentHistory || dropdownfilteredData;
      }
      setFilteredData(data?.slice(startIndex, endIndex)) // to show perpage data
    }
  }

  //clearing the value for applied filters and getting updated data from api
  const clearFilter = () => {
    setStartDate(null)
    setEndDate(null)
    setdropDownVal('All')
    if (page != 1)
      setPage(1)
    getPaymentHistory(donor?.pdn, null, null)
  }

  //applying filter to get he data update don any state change
  useEffect(() => {
    setDropdownfilteredData(paymentHistory?.data?.paymentHistory);
    if (!isEmpty(paymentHistory?.data?.paymentHistory)) {
      pageDropdownFilter()
    }
  }, [page, dropDownVal, paymentHistory])

  useEffect(() => {
    console.log(paymentHistoryLoading);
  }, [paymentHistoryLoading])

  useEffect(() => {
    getPaymentHistory(donor?.pdn)
  }, [])

  return (
    <div>
      {paymentHistoryLoading ?
        <LoadingState /> :
        <div className='DashboardGiftCard'>
          <div className='GiftCardFilter'>
            <div columns={2} className='GridBorder'>
              <div >
                <div className="FilterText">
                  <span className='FilterByDateText'>{t('filterBtDate')}</span>
                </div>
                <div className='DateRangeFilter'>
                  <div className='dateRange'>
                    <Icon name='calendar alternate outline' className='CalendarIcon' />
                    <DatePicker selected={startDate} onChange={handleStartDateChange} placeholderText={t('selectDate')} />
                  </div>
                  <span className='ToText'>{t('to')}</span>
                  <div className='dateRange'>
                    <Icon name='calendar alternate outline' className='CalendarIcon' />
                    <DatePicker selected={endDate} onChange={handleEndDateChange} placeholderText={t('selectDate')} />
                  </div>
                  <div className='dateRange'>
                    <Icon name='x' className='xIcon' />
                    <span className='ClearFilterText' onClick={clearFilter}>
                      {t('clearFilter')}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div className="FilterText">
                  <span className='FilterByDateText'>{t('filterBtType')}</span>
                </div>
                <div>
                  <Dropdown
                    className='filterType'
                    icon='chevron down'
                    placeholder="Select Options"
                    selection
                    options={cardOptions}
                    value={dropDownVal}
                    onChange={(e, { value }) => {
                      // onDrodpownFilter(value)
                      setdropDownVal(value)
                      if (page != 1)
                        setPage(1)
                    }}
                    disabled={isEmpty(dropdownfilteredData)}
                  >
                  </Dropdown>
                </div>
              </div>
            </div>
            <Grid.Row>
              <span className='FilterByDateText'></span> *{t('paymentHistoryDuration')}
            </Grid.Row>
          </div>
          {!isEmpty(filteredData) && !isEmpty(dropdownfilteredData) && !paymentHistoryError
            && ((!startDate || !endDate) || moment(endDate).diff(moment(startDate), 'year', true) <= 1) && (
              <Grid className='TransactionEntries'>
                <Grid.Row columns={3} className='EntriesHeader'>
                  <Grid.Column>{t('date')}</Grid.Column>
                  <Grid.Column>{t('type')}</Grid.Column>
                  <Grid.Column>{t('amountUSD')}</Grid.Column>
                </Grid.Row>
                {filteredData && filteredData?.map((entry, index) => (
                  <Grid.Row
                    key={index}
                    columns={3}
                    className='EntriesContent'>
                    <Grid.Column>{moment.utc(entry.transactionDate).format('MM/DD/YYYY')}</Grid.Column>
                    <Grid.Column style={{ fontWeight: 'bold' }}>{entry.type == 6 ? t('giftCard') : t('debit')}</Grid.Column>
                    <Grid.Column className='EntryAmount'>
                      {entry.transactionAmount != null && entry.transactionAmount >= 0 ? (
                        <span>${Math.abs(entry.transactionAmount).toFixed(2)}</span>
                      ) : (
                        <span>-${Math.abs(entry.transactionAmount).toFixed(2)}</span>
                      )}
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>

            )}
          {isEmpty(dropdownfilteredData)
            &&
            <NoDataState />
          }

          {loggedInUser?.role === 'Donor' && donor?.defaultCenter !== null && donor?.defaultCenter?.isGiftcardEnabled &&
            <div className={isEmpty(dropdownfilteredData) ? "NoDataGiftCardPortalButton" : "GiftCardPortalButton"}>
              <PrimaryButton
                text={t("GiftCardPortal")}
                width={162}
                classType="Inverted"
                fontSize="16px"
                onClick={() => {
                  toggleBiolifePaymentCardsModal();
                }}
              />
            </div>
          }

          {dropdownfilteredData?.length > 0 && (
            <div className='PaginationContainer'>
              <span className='PageText'>{t('page')}</span>
              <Pagination
                secondary
                activePage={page}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={dropdownfilteredData?.length / pageSize}
                onPageChange={(e, { activePage }) => {
                  setPage(activePage)
                }
                }
              />
            </div>
          )}
        </div>}
 
      {/* Pop-up modal for Gift card portal */}
      <BiolifePaymentCardsModal
        openBiolifePaymentCardsModal={openBiolifePaymentCardsModal}
        toggleBiolifePaymentCardsModal={toggleBiolifePaymentCardsModal}
        headerText={t("GiftCardPortal")}
        bodyTextLine1={t('You are about to leave the BioLife Plasma Services web page')}
        bodyTextLine2={t('Would you like to continue')}
        link="https://www.prezzee.com/"
      />

    </div>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  loggedInUser: state.users?.user,
  paymentHistory: state.payments?.getPaymentHistorySuccess,
  paymentHistoryLoading: state.payments?.getPaymentHistoryLoading,
  paymentHistoryError: state.payments?.getPaymentHistoryFailure
})
const mapDispatchToProps = dispatch => ({
  getPaymentHistory: (pdn, startDate, endDate) => dispatch(PaymentsActions.getPaymentHistory(pdn, startDate, endDate))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardGiftCard)
