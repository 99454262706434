import React from 'react'

import './styles.scss'
import { Grid, Container } from 'semantic-ui-react'


const ProductionText = ({prodContent}) => (
  <div className='ProductionContent'>
    <Container>
      <Grid>
      <div
        dangerouslySetInnerHTML={{ __html: prodContent?.content__c }}
            />
      </Grid>
    </Container>
  </div>
)

export default ProductionText
