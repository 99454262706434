import React from 'react'
import { Form, Icon, Header, Grid } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import validate from './validate'
import './styles.scss'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'

const InputField = importer('Components/Forms/FormFields/InputField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const QuickPhysicalForm = ({ 
  toogleQuickPhysicalModal,
  handleScheduleQuickAppointment,
  quickAppointmentLoading
 }) => {   
   const onSubmit = values => {
    handleScheduleQuickAppointment(values)
   }
  const { t } = useTranslation('QuickPhysicalForm');

  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className='QuickPhysicalForm'>
          <div className='TopContent'>
            <Header as='h4' className='FormHeader'>
              {t('donorInfo')}
            </Header>
            <Form.Group>
              <Field name='firstName'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={50}
                      width={365}
                      className='SearchInput'
                      placeholder={t('firstName')}
                      topLabel={t('firstName')}
                      value={null}
                      icon={
                        <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                      }
                    />
                  )}
                </Field>

              <Field name='lastName'>
                {({ input, meta }) => (
                  <InputField
                    required
                    meta={meta}
                    {...input}
                    height={50}
                    width={365}
                    placeholder={t('lastName')}
                    topLabel={t('lastName')}
                    className='SearchInput'
                    value={null}
                    icon={
                      <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                    }
                  />
                )}
              </Field>
            </Form.Group>
            <Form.Group>
              <Field name='email'>
                {({ input, meta }) => (
                  <InputField
                    required
                    meta={meta}
                    {...input}
                    height={50}
                    width={365}
                    placeholder={t('email')}
                    topLabel={t('email')}
                    className='SearchInput'
                    value={null}
                    icon={
                      <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                    }
                  />
                )}
              </Field>
              <Field name='zipCode'>
                {({ input, meta }) => (
                  <InputField
                    required
                    meta={meta}
                    {...input}
                    height={50}
                    width={365}
                    placeholder={t('zipCode')}
                    topLabel={t('zipCode')}
                    className='SearchInput'
                    value={null}
                    icon={
                      <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                    }
                  />
                )}
              </Field>
            </Form.Group>
          </div>
          <div className='BottomContent'>
            <Grid columns={2} className='ActionContainer'>
              <Grid.Column textAlign='left'>
                <PrimaryButton
                  text={t('schedule')}
                  width={128}
                  height={50}
                  classType='Primary'
                  disabled={quickAppointmentLoading}
                  // onClick={scheduleHandler}
                  className='FindDonor'
                  value={null}
                  icon={
                    <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                  }
                />
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <PrimaryButton
                  text={t('cancel')}
                  width={128}
                  height={50}
                  classType='Inverted'
                  onClick={toogleQuickPhysicalModal}
                  className='FindDonor'
                  value={null}
                  icon={
                    <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                  }
                />
              </Grid.Column>
            </Grid>
          </div>
        </Form>
      )}
    />
  )
}

export default QuickPhysicalForm
