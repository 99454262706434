import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
  const errors = {}
  // First name validation
  if (!values.firstName) {
    errors.firstName = 'firstNameIsRequired'
  }
  // Last name validation
  if (!values.lastName) {
    errors.lastName = 'lastNameIsRequired'
  }
  // Last name validation
  if (!values.message) {
    errors.message = 'messageIsRequired'
  }
  // center validation
  if (!values.center) {
    errors.center = 'centerIsRequired'
  }
  // State validation
  if (!values.state) {
    errors.state = 'stateIsRequired'
  }
  // Email validation
  if (values.emailId) {
    if (!isValidEmail(values.emailId)) {
      errors.emailId = 'enterAValidEmail'
    }
  } else {
    errors.emailId = 'emailAddressIsRequired'
  }
  return errors
}

export default validate
