import React ,{useState,useEffect}from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Header, Icon, Popup } from 'semantic-ui-react'
import { numberWithCommas } from '../../../Helpers/Utils'
import './styles.scss'

const GiftCardHeader = ({filteredData}) => {
  const { t } = useTranslation('GiftCardHeader');
 const [debitBalance, setDebitBalance] = useState(null)
  const [giftBalance, setGiftBalance] = useState(null)
  const calcBalance = () => {
    let debitcard = 0;
    let giftcard = 0;
    filteredData?.data.paymentHistory.map((item) => {
      if (item.type != 6)
        debitcard = debitcard + item.transactionAmount
      else
        giftcard = giftcard + item.transactionAmount
    })
    console.log("debitcard", debitcard, "giftcard", giftcard)
    setDebitBalance(debitcard)
    setGiftBalance(giftcard)
  }
  useEffect(() => {
    if(filteredData){
      calcBalance()
  console.log("filteredData",filteredData)
    }
  }, [filteredData])

  return (
    <div className='GiftCardHeader'>
      <div>
        <Header as='h4' className='LabelHeader'>
          {t('debitCardTotal')}
        </Header>
        <Header as='h1' className='LeftTextHeader'>
          ${numberWithCommas(debitBalance ? debitBalance : 0)}
        </Header>
      </div>
      <div textAlign='center' verticalAlign='bottom'>
        <Header as='h4' className='LabelHeader'>
          {t('GiftCardTotal')}
        </Header>
        <Header as='h1' className='LeftTextHeader'>
          ${numberWithCommas(giftBalance ? giftBalance : 0)}
        </Header>
      </div>
      <div textAlign='right' verticalAlign='bottom'>
        <Header as='h4' className='LabelHeader'>
          {t('TotalAmountCredit')}
          <Popup content={t('SumText')} trigger={<Icon className='info circle icon' />} position='top center' basic />
        </Header>
        <Header as='h1' className='LeftTextHeader'>
          ${numberWithCommas(debitBalance + giftBalance)}
        </Header>
      </div>
    </div>
  )
}

export default GiftCardHeader
