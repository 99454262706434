import React, { useEffect } from "react";
import { useTranslation } from 'react-i18next';
import { Header, Loader } from 'semantic-ui-react';
import './styles.scss';
import importer from "@takedapdt/biolife-core/src/importer";
import { connect } from "react-redux";

const LoyalDonorActions = importer("Stores/Loyalty/Actions");
const RewardsPoints = ({ donor, getLoyaltyDetailsByPdn, loyaltyPoints }) => {
  const { t } = useTranslation('DashboardTopBanner');
  useEffect(() => {
    if (donor?.pdn != null) {
      getLoyaltyDetailsByPdn(donor.pdn);
    }
  }, [donor]);

  return (
    <>
      {donor?.isLoyaltyMember === "Yes" && donor?.isLoyaltyMember != null ? (
        <div>
          <Header as='h4' className='rewardHeader'>
            {t('rewardsPoints')}
          </Header>
          {loyaltyPoints === null  ? (
            <div className='SupHeaderLoading'>
              <Loader active />
            </div>
          ) : (
            <span className="loyaltyPoints">{loyaltyPoints || 0}</span>
          )}
        </div>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  loyaltyPoints: state.loyalty.getLoyaltyDetails?.data?.loyaltyPoints,
});
const mapDispatchToProps = (dispatch) => ({
  getLoyaltyDetailsByPdn: (pdn) => {
    dispatch(LoyalDonorActions.getLoyaltyDetails(pdn));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardsPoints);
