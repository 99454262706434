import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
const HeroSearchForm = importer('Components/Forms/HeroSearchForm')
import './styles.scss'
import i18n from "../../i18n";

const FindYourCenter = ({mainContent}) => {
  const findCenterContainerStyles = i18n.language === "es-MX" ? "spanishFindCenterContainer " : "findCenterContainer";
  return(
  <div className={findCenterContainerStyles}>
      <div
      className='findCenterContent'
      dangerouslySetInnerHTML={{ __html: mainContent?.content__c }}
    />
    <HeroSearchForm buttonProps={{
    className: 'HeroSearchPrimaryButton'
  }}/>
  </div>
  )
}

export default FindYourCenter
