import React, { useEffect, useState } from "react";
import { Card, Feed, FeedLike, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import "./styles.scss";
import importer from '@takedapdt/biolife-core/src/importer'
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const RecentRewardActivity = ({ loyaltyActivity, loyaltyActivityLoading, setTab }) => {
  const { t, i18n } = useTranslation("RewardsCard");
  const [recentRewardsHistoryData, setRecentRewardsHistoryData] = useState([])

  useEffect(() => {
    if(loyaltyActivity){
      const transformedRewardsHistoryData = []
      loyaltyActivity?.transactions?.slice(0,3).map((item) => {
        transformedRewardsHistoryData.push({...item, activityType: i18n.language === 'es-MX' ? item.activityTypeSpanish : item.activityType, transDesc: i18n.language === 'es-MX' ? item.transDescSpanish : item.transDesc })
      })
      setRecentRewardsHistoryData(transformedRewardsHistoryData)
    }
}, [i18n.language, loyaltyActivity])

const handleRewardsHistoryLinkClick = () => {
  setTab("REWARD_HISTORY");
  window.scrollTo(0, 0);
 };

  return (
    <div className="RewardsCardContainer">
    {loyaltyActivityLoading ? <LoadingState /> : 
    recentRewardsHistoryData && recentRewardsHistoryData.length > 0 ? (
      <Card>
        <Card.Content>
          <Card.Header>
            <h2>{t("RecentRewardActivity")}</h2>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <Feed>
            {recentRewardsHistoryData?.map((item, index) => (
              <Feed.Event key={index}>
                <Feed.Content>
                  <Feed.Label> 
                    <div className="RewardIconContainer">
                         <Image src={item.iconUrl} className='RewardIcon'/>
                    </div>
                  </Feed.Label>
                  <Feed.Summary>
                    {item.transDesc}
                    <Feed.Date
                      className="RewardsFeedDate"
                      content={`${item.activityType} ${item.activityDate}`}
                    />
                  </Feed.Summary>
                  <Feed.Extra
                        className={item.transOperation === 'Credit' ? 'positive' : 'negative'}>
                        {item.transOperation === 'Credit' ? `+ ${item.points}` : `- ${item.points}`}
                      </Feed.Extra>
                </Feed.Content>
              </Feed.Event>
            ))}
          </Feed>
        </Card.Content>
        <FeedLike onClick={handleRewardsHistoryLinkClick} target="_blank" className="link">
          {t("ViewRewardHistory")}
        </FeedLike>
      </Card>
    ) : (
      <div className="noRecentrewardContainer">
      <Card>
        <Card.Content>
          <Card.Header>
            <h2>{t("RecentRewardActivity")}</h2>
          </Card.Header>
        </Card.Content>
        <Card.Content>
          <p className="noDataMessage">{t("NoDataMessage")}</p>
        </Card.Content>
      </Card>
      </div>
    )}
  </div>
  
  );
};

const mapStateToProps = (state) => ({
  loyaltyActivity: state.loyalty?.getLoyaltyActivity,
  loyaltyActivityLoading: state.loyalty?.getLoyaltyActivityLoading
});

export default connect(mapStateToProps,null)(RecentRewardActivity);
