import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Loader } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

const AppContainer = importer('Containers/App')
const LeadGenReportResults = importer('Components/CenterOps/LeadGenReportResults')
const LeadGenReportForm = importer('Components/Forms/LeadGenReportForm')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const Footer = importer('Components/Footer')

const centersActions = importer('Stores/Centers/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const PER_PAGE = 10

const LeadGenReport = ({
  donor,
  donorLoading,
  operations,
  operationsLoading,
  user,
  getDonors,
  getReport,
  downloadReport,
  operationsFailure
}) => {
  const [searched, setSearched] = useState(false)
  const [params, setParams] = useState({})
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)
  const {t} = useTranslation('LeadGenReport')

  useEffect(() => {
    getDonors(user.id)
  }, [])

  useEffect(() => {
    if (operations?.totalRecords >= 0) {
      const total = Math.ceil(operations.totalRecords / PER_PAGE)
      setTotalPages(total)
    }
  }, [operations])

  const handleSearch = ({ start_date, end_date, sort_type, page = 1 }) => {
    const { defaultCenter } = user
    const payload = {
      start_date,
      end_date,
      center_sfid: defaultCenter.sfid,
      sort_type,
      limit: PER_PAGE,
      page
    }
    getReport(start_date, end_date, defaultCenter.sfid, sort_type, PER_PAGE, page)
    setPage(page)
    setSearched(true)
    setParams(payload)
  }

  const handleExport = (fileType) => {
    downloadReport(
      params.start_date,
      params.end_date,
      params.center_sfid,
      params.sort_type,
      fileType
    )
  }

  const handleChangePage = (_, { activePage }) => {
    handleSearch({
      start_date: params.start_date,
      end_date: params.end_date,
      sort_type: params.sort_type,
      page: activePage
    })
  }

  const handleClear = () => {
    setSearched(false)
    setPage(1)
    setParams({})
  }

  const loading = donorLoading || operationsLoading
  return (
    <AppContainer compact title={t('centerOpsCalender')}>
      <Helmet>
        <title>{t('potentialDonorsReport')}</title>
        <meta name="description" content= {t('leadGenReportMeta')} />
      </Helmet>
      <div className='MainContainer CenterOpsContainer'>
        <Container fluid>
          <LeadGenReportForm
            onSearch={handleSearch}
            onClear={handleClear}
            pageName='Potential Donors Report'
          />
          {operationsFailure && <FailureState message = {operationsFailure && operationsFailure?.response?.data?.message}/>}
          {loading ? (
            <Loader active />
          ) : (
            <LeadGenReportResults
              page={page}
              totalPages={totalPages}
              searched={searched}
              operations={operations}
              onExport={handleExport}
              onChangePage={handleChangePage}
            />
          )}
        </Container>
      </div>
      <Footer />
    </AppContainer>
  )
}

const mapStateToProps = ({ users, donors, centers }) => ({
  user: users.loginHerokuSuccess,
  operations: centers.getAppointmentReportSuccess?.data,
  operationsLoading: centers.getAppointmentReportLoading,
  operationsFailure: centers.getAppointmentReportFailure,
  donor: donors.donors?.data,
  donorLoading: donors?.getDonorsLoading
})

const mapDispatchToProps = dispatch => ({
  getDonors: id => dispatch(donorsActions.getDonors(id)),
  getReport: (fromDateTime, toDateTime, centerSfid, sort_type, limit, page) =>
    dispatch(centersActions.getAppointmentReport('potentialDonorsReport', fromDateTime, toDateTime, centerSfid, sort_type, limit, page)),
  downloadReport: (fromDateTime, toDateTime, centerSfid, sort_type, fileType, limit, page) =>
    dispatch(centersActions.downloadReport('potentialDonorsReport', fromDateTime, toDateTime, centerSfid, sort_type, fileType, limit, page))
})

export default connect(mapStateToProps, mapDispatchToProps)(LeadGenReport)
