import React , {useState}from 'react'
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const UserActions = importer('Stores/Users/Actions')

const ResendVerification = ({
  resendVerification,
  donorGlobalState,
  resendVerificationLoading,
  resendVerificationSuccess,
  resendVerificationFailure
}) => {
  const { t } = useTranslation('ResendVerification');
    const [isSubmited, setIsSubmited] = useState(false)
    const sendResendVerification = () => {
        setIsSubmited(true)
        resendVerification(donorGlobalState?.sfid);
     }
    return (
        <div className='ResendButtonWrap'>
            {isSubmited && resendVerificationFailure &&
              <div className='FailureStateContainer'>
                <FailureState message={resendVerificationFailure.message} />
              </div>
            }
            {isSubmited && resendVerificationSuccess &&
              <p className="SuccessContainer">
                {'Verification email was sent successfully'}
              </p>
            }
            {donorGlobalState?.profileCompletionStage=='AccountVerified' && donorGlobalState?.email!=null &&
            <p className='ErrorMessage'>{t('donorIsAlreadyVerified')}</p>
            }
            {donorGlobalState?.email==null && donorGlobalState?.profileCompletionStage=='AccountVerified' &&
            <p className='ErrorMessage'>{t('thisDonorHasNotBeenMigrated')}</p>
            }
            <PrimaryButton
                        text={t('resendVerificationbtn')}
                        width={200}
                        height={48}
                        disabled={resendVerificationLoading || (donorGlobalState?.profileCompletionStage!=='EmailSent' &&  donorGlobalState?.profileCompletionStage!=='NotVerified')}
                        classType='LightBlueWithPadding'
                        onClick={sendResendVerification}
                        value={null}
            />
        </div>
    )

}
  const mapStateToProps = state => ({
    user: state.users.user,
    resendVerificationLoading: state.users?.resendVerificationLoading,
    resendVerificationSuccess: state.users?.resendVerificationSuccess,
    resendVerificationFailure: state.users?.resendVerificationFailure?.response?.data,
    donorGlobalState: state.donors?.donors?.data
  })
  
  const mapDispatchToProps = dispatch => ({
    resendVerification: (sfid) => dispatch(UserActions.resendVerification(sfid)),
  })
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ResendVerification)
