import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Header, Container, Grid, Image, List } from 'semantic-ui-react'
import { getOperationHours, formatPhoneNumber, getPlaroomHours, mapOptionsByState, sortCentersAlphabetically } from '../../../Helpers/Utils'
import bioLifeCenterImg from '../../../../assets/media/images/biolife-center-image.jpg'
import { isEmpty } from 'lodash'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'
import './styles.scss'
/* */
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FaiureState = importer('Components/Dashboard/StateErrorContainer')
const NoDataState = importer('Components/Dashboard/StateNoDataContainer')
const SelectSearch = importer('Components/Inputs/SelectSearch')
const centersActions = importer('Stores/Centers/Actions')
const donorsActions = importer('Stores/Donors/Actions')

const DonorSearchCenterInformation = ({shouldCenterPopulate,loggedInUser, center, error, loading, centers, updateDonor, donor, getAllCenters, getDonorCentersAll, selectedDonor}) => {
  const { t } = useTranslation('DonorSearchCenterInformation');
  const [centerList, setCenterList] = useState([])
  const [refreshCenter, setRefreshCenter] = useState(shouldCenterPopulate)
  const loadData = (donorValue) => {
    if (!isEmpty(donorValue)) {
      if (donorValue.donationCenter) {
        getAllCenters(donorValue.donationCenter)
      }
    }
    getDonorCentersAll()
  }

  useEffect(() => {
  // To resolve issue of center loading data from previous donor or center ops , when donor center is null
    if(donor?.donationCenter) setRefreshCenter(true)
    else setRefreshCenter(false)
    if(!loading && selectedDonor?.donationCenter != donor?.donationCenter){
      loadData(donor)
    }
    else {
      loadData(selectedDonor)
    }
  }, [donor])

  useEffect(() => {
    centers && preparedOptions()
    .then(res => setCenterList(res))
  }, [centers]) 

  const preparedOptions = async () =>{

    const res = await mapOptionsByState(centers, false,loggedInUser)
    const data = sortCentersAlphabetically(res)
    return data
  }

  const handleSelection = async ({ value }) => {
    await updateDonor(donor.id, {
      donorDetails: {
        donationCenter: value
      }
    })
  }

  return (
    <div className='DonorSearchDonationCenterInformation'>
      {loading && <LoadingState />}
      {error && <FaiureState />}
      {//!center && <NoDataState />}
      (!refreshCenter || !center) && !loading && 
      <Grid.Row style={{ backgroundColor: '#EBF7FA' }}>
        <p className='DonationCenterLabel'>{t('noCenterSelected')}</p>
        <div className='ChangeCenterBlock'>
            <SelectSearch
              options={centerList}
              centers={centers}
              handleOnChange={handleSelection}
              className='CenterDropdown'
              placeholder={t('changeCenter')}
              allowZipcodeSearch={true}
            />
          </div>
      </Grid.Row>
    }
      {!loading && !error && refreshCenter && center && (
        <Container fluid>
          <Grid.Row style={{ backgroundColor: '#EBF7FA' }}>
            <p className='DonationCenterLabel'>{t('yourDonatationCenter')}</p>
            <p className='DonationCenterName'>{center.name}</p>
            <p className='DonationCenterInfo'>
              {center.addressLine1}
              <br />
              {`${center.city}, ${center.stateCode} ${center.zipcode}`}
              <br />
              <a href={`tel:${center.telephone}`}>{formatPhoneNumber(center.telephone)}</a>
            </p>
            <div className='ChangeCenterBlock'>
              <SelectSearch
                options={centerList}
                centers={centers}
                handleOnChange={handleSelection}
                className='CenterDropdown'
                placeholder={t('changeCenter')}
                allowZipcodeSearch={true}
              />
            </div>
          </Grid.Row>
          <Grid.Row>
            <Image src={center.mediumImageUrl || bioLifeCenterImg} alt={center.imageAltText} fluid />
          </Grid.Row>

          <div className='CenterMoreDetailsContainer'>
            <Grid.Row>
              <Header as='h4' className='OfficeHoursHeader'>
              {t('officeHours')}
              </Header>
              <List className='OfficeHoursContainer'>
                {center.hoursOfOperation.map((officeHours, index) => (
                  <List.Item key={index}>
                    <Grid columns={2}>
                      <Grid.Column style={{ color: '#1F237A' }} width={5}>
                        {officeHours.weekDayName}
                      </Grid.Column>
                      <Grid.Column style={{ color: '#484848' }} width={11}>
                        {getOperationHours(officeHours)}
                      </Grid.Column>
                    </Grid>
                  </List.Item>
                ))}
              </List>
              <p className="DifferHours">{t('HrsMayDiffOnMajorHolidays')}</p>
            </Grid.Row>
          </div>
        </Container>
      )}
    </div>
  )
}

const mapStateToProps = ({ donors, centers, users}) => ({
  donor: donors.donors?.data,
  loggedInUser: users?.user,
  center: centers.getAllCentersSuccess?.data,
  centers: centers.getDonorCentersAllSuccess?.data,
  updateDonorSuccess: donors.updateDonorSuccess,
  loading: centers.getAllCentersLoading
})

const mapDispatchToProps = dispatch => ({
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
  getAllCenters: searchStr => dispatch(centersActions.getAllCenters(searchStr)),
  getDonorCentersAll: () => dispatch(centersActions.getDonorCentersAll())
})

export default connect(mapStateToProps, mapDispatchToProps)(DonorSearchCenterInformation)

