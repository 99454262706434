import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
const UpdatePreferencesForm = importer('Components/Forms/UpdatePreferencesForm')
const donorsActions = importer('Stores/Donors/Actions')

const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { webArticles } = importer('Helpers/WebArticles')

const DashboardPreferences = ({
  getDonors,
  donor,
  user,
  getDonorsPreferences,
  updateDonorsPreferences,
  preferences,
  loading,
  webContents,
  updateDonorsPreferencesSuccess,
  getDonorsSuccess
}) => {
  useEffect(() => {
    getDonorsPreferences(user.id)
  }, [])

  const smsStatement = ArticleByIdentifier(
    webContents,
    webArticles.MOBILE_SMS_STATEMENT
  )

  const marketingStatement = ArticleByIdentifier(
    webContents,
    webArticles.MOBILE_MARKETING_STATEMENT
  )

  return (
    <div className='DashboardPreferences'>
      <UpdatePreferencesForm
        donor={donor}
        getDonors={getDonors}
        getDonorsSuccess={getDonorsSuccess}
        preferences={preferences}
        updateDonorsPreferences={updateDonorsPreferences}
        loading={loading}
        smsStatementContent={smsStatement}
        marketingStatementContent={marketingStatement}
        updateDonorsPreferencesSuccess={updateDonorsPreferencesSuccess}
      />
    </div>
  )
}
const mapStateToProps = ({ users, donors, publicContent }) => ({
  user: users.user,
  donor: donors.donors?.data,
  preferences: {
    isEmailOptIn: donors?.Preferences?.isEmailOptIn,
    isEmailReminderOptIn: donors?.Preferences?.isEmailReminderOptIn,
    isSmsOptIn: donors?.Preferences?.isSmsOptIn,
    isSmsReminderOptIn: donors?.Preferences?.isSmsReminderOptIn,
    language: donors?.Preferences?.language
  },
  loading: donors?.getDonorsPreferencesLoading,
  webContents: publicContent.WebArticles?.completeArticles,
  updateDonorsPreferencesSuccess: donors?.updateDonorsPreferencesSuccess,
  getDonorsSuccess: donors?.getDonorsSuccess
})

const mapDispatchToProps = dispatch => ({
  getDonors: id => dispatch(donorsActions.getDonors(id)),
  getDonorsPreferences: id => dispatch(donorsActions.getDonorsPreferences(id)),
  updateDonorsPreferences: (id, preferences) =>
    dispatch(donorsActions.updateDonorsPreferences(id, preferences))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardPreferences)
