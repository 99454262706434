import React from 'react'

import { Header, Container } from 'semantic-ui-react'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const Breadcrumbs = importer('Components/Breadcrumbs')

const PageTopBanner = props => {
  const { selectedPage, parentMenu } = props
  return (
    <div className='PageTopBanner'>
      <Container fluid>
      <Breadcrumbs 
          page={(selectedPage.name === "Gift Card Pilot Profit Share Terms and Conditions" 
          || selectedPage.name === "Gift Card Pilot Equal Value Terms and Conditions")
          ? "Gift Card Pilot Program Terms and Conditions" 
          : (selectedPage.name === "Términos y condiciones de participación en las ganancias del programa piloto de tarjetas de regalo" 
          || selectedPage.name === "Términos y condiciones del piloto de la tarjeta de regalo de igual valor")
          ?"Términos y Condiciones del Programa Piloto de Gift Card"
          : selectedPage.name
        }  
          parentMenu={parentMenu} />
        <Header as='h4' className='SupHeader'>
          {parentMenu}
        </Header>
        <Header as='h1' className='MainHeader'>
          {(selectedPage.name === "Gift Card Pilot Profit Share Terms and Conditions" 
              || selectedPage.name === "Gift Card Pilot Equal Value Terms and Conditions")
              ? "Gift Card Pilot Program Terms and Conditions" 
              : (selectedPage.name === "Términos y condiciones de participación en las ganancias del programa piloto de tarjetas de regalo" 
              || selectedPage.name === "Términos y condiciones del piloto de la tarjeta de regalo de igual valor")
              ?"Términos y Condiciones del Programa Piloto de Gift Card"
              : selectedPage.name
            }
        </Header>
      </Container>
    </div>
  )
}

export default PageTopBanner
