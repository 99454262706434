import React from 'react'
import { Button, Table, Icon } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import './styles.scss'
import { useTranslation } from 'react-i18next';

const RegistrationWizardSteps = ({ step }) => {
  const { t , i18n} = useTranslation('RegistrationWizardSteps');

  return (
    <div className={`RegistrationWizardSteps ${i18n.language}`}>
      <Table unstackable>
        <Table.Row>
          <Table.Cell className={`firstCell ${i18n.language}`}>
            <Button
              basic
              className='StepButton'
              as={NavLink}
              to='/registration'
            >
               <div className='stepItem'>
                <span className='StepNumber' style={{backgroundColor: step > 1 && '#009e8f'}}>
                  {step > 1 ? <Icon name='check' /> : '01'}
                </span>
                <p className={`StepText ${i18n.language}`}>
                {t('signUp')}
              </p>
              </div>
            </Button>
          </Table.Cell>
          <Table.Cell className='MiddleCell'>
            <Button
              basic
              className='StepButton'
              as={NavLink}
              to='/create-account'
              disabled={step === 1}
            >
            <div className='stepItem'>
              <span className={step >= 2 ? 'StepNumber' : 'StepNumberDisabled'} style={{backgroundColor: step > 2 && '#009e8f'}}>
                  {step > 2 ? <Icon name='check' /> : '02'}
              </span>
              <p className={step >= 2 ? `StepText  ${i18n.language}` : `StepTextDisable  ${i18n.language}`}>
                {t('createProfile')}
              </p>
              </div>
            </Button>
          </Table.Cell>
          <Table.Cell>
            <Button
              basic
              className='StepButton'
              as={NavLink}
              to='/email-confirmation'
              disabled={step !== 3}
            >
             <div className='stepItem'>
              <span className={step > 2 ? 'StepNumber' :  `StepNumberDisabled emailConfirmation ${i18n.language}`}>
                  03
              </span>
              <p className={step > 2 ? `StepText ${i18n.language}` : `StepTextDisable  ${i18n.language}`}>
                {t('emailConfirm')}
              </p>
              </div>  
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table>
    </div>
  )
}

export default RegistrationWizardSteps
