import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import moment from 'moment'
import importer from '@takedapdt/biolife-core/src/importer'
import { Grid, Header, Container, Visibility, Message } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { splitCenterName } from "../../../Helpers/Utils"
import './styles.scss'

/* */
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const NoDataState = importer('Components/Dashboard/StateNoDataContainer')
const NearbyCentersSection = importer('Components/Dashboard/NearbyCentersSection')
const ConfirmAppointmentModal = importer('Components/Dashboard/ConfirmAppointmentModal')
const AddToCalendarModal = importer('Components/Dashboard/AddToCalendarModal')
const ConfirmCancellationModal = importer('Components/Dashboard/ConfirmCancellationModal')

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const ModifyAppointmentModal = importer('Components/Dashboard/ModifyAppointmentModal')
const ModifyOrCancelAppointmentModal = importer('Components/Dashboard/ModifyOrCancelAppointmentModal')
const ChangePaymentMethodModal = importer("Components/Dashboard/ChangePaymentMethodModal")
// Actions
const centersActions = importer('Stores/Centers/Actions')
const appointmentsActions = importer('Stores/Appointments/Actions')


const MyAppointments = ({
  donor,
  appointments,
  inquiry,
  donorCenters,
  getDonorCentersAll,
  getSlotsForAppointmentType,
  getAllCenters,
  center,
  nearbyCenters,
  centerStatus,
  getDonorNearbyCenters,
  cancelAppointment,
  getUpcomingAppointments,
  appointmentsLoading,
  appointmentsError,
  rescheduleAppointmentLoading,
  rescheduleAppointmentSuccess,
  rescheduleAppointmentFailure,
  cancelAppointmentSuccess,
  cancelAppointmentLoading,
  cancelAppointmentFailure,
  apptCenter,
  getAppointmentCenter,
  centerLoading,
  loggedInUser,
  lastPhysicalDate
}) => {
  const [appointment, setAppointment] = useState({})
  const [openModal, setOpenModal] = useState(false)
  const [openCancelModal, setOpenCancelModal] = useState(false)
  const [openAddToCalendarModal, setOpenAddToCalendarModal] = useState(false)
  const [openConfirmCancellationModal, setOpenConfirmCancellationModal] = useState(false)
  const [openChangePaymentModal, setOpenChangePaymentModal] = useState(false)
  const { t } = useTranslation('MyAppointments');

  const toggleAddToCalendarModal = () => setOpenAddToCalendarModal(!openAddToCalendarModal)
  const toggleConfirmCancellationModal = () => setOpenConfirmCancellationModal(!openConfirmCancellationModal)

  const [donationList, setDonationList] = useState([])
  const [totalPages, setTotalPages] = useState(1)
  const [width, setWidth] = useState('')
  const buttonWidth = width && width < 480 ? '100%' : '60%'
  const fontSize = width && width < 480 ? '15px' : '16px'
  const handleUpdate = (e, { calculations }) => setWidth(calculations.width)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const toggleConfirmModal = () => setOpenConfirmModal(!openConfirmModal)
  const paginate = (pages, pageSize, page) => {
    const newPages = pages.slice((page - 1) * pageSize, page * pageSize)
    setTotalPages(pages.length / pageSize)
    setDonationList(newPages)
  }
  const toggleChangePaymentModal = () => setOpenChangePaymentModal(!openChangePaymentModal);

  useEffect(() => {
    if (!isEmpty(inquiry)) {
      paginate(inquiry.pastDonations, 7, 1)
    }
    if (isEmpty(nearbyCenters)) {
      getDonorNearbyCenters(donor?.sfid)
    }
    if (isEmpty(donorCenters)) {
      getDonorCentersAll(donor?.zipCode)
    }
  }, [])

  const toggleModal = () => setOpenModal(!openModal)
  const toggleCancelModal = () => setOpenCancelModal(!openCancelModal)
  const handleAppointment = (app) => {
    setAppointment({ ...app })
  }

  if (centerStatus !== 'Selected') {
    return <NearbyCentersSection centerStatus={centerStatus} nearbyCenters={nearbyCenters} />
  }
  if (centerLoading) {
    return <LoadingState />
  }
  if (isEmpty(center)) {
    return <NoDataState />
  }

  return (
    <Visibility onUpdate={handleUpdate}>
      <Container fluid className='MyAppointments'>
        <Grid className="ScheduleAppointments">
          <Grid.Row columns={3}>
            <Grid.Column className='ResponsiveButton' computer={6} mobile={16}>
              <PrimaryButton
                text={t('scheduleAppointment')}
                width='85%'
                classType='Primary'
                fontSize='16px'
                as={NavLink}
                to='/appointment-scheduling'
              />
            </Grid.Column>
            <Grid.Column computer={5} mobile={7}>
              <p className='LastNextPhysicalDateLabel'>{t('lastPhysical')}</p>
              <p className='LastNextPhysicalDateText'>
                {inquiry && inquiry.lastPhysicalDate ? moment(inquiry.lastPhysicalDate).format('MM/DD/YYYY') : '-'}
              </p>
            </Grid.Column>
            <Grid.Column className='LastNextPhysicalDateLabel' computer={5} mobile={9}>
              <p className='LastNextPhysicalDateLabel' style={{ textAlign: 'right' }}>{t('nextPhysicalDue')}</p>
              <p className='LastNextPhysicalDateText' style={{ textAlign: 'right' }}>
                {inquiry && inquiry.nextPhysicalDate ? moment(inquiry.nextPhysicalDate).format('MM/DD/YYYY') : '-'}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        {appointmentsLoading || appointmentsError && (
          <div class="ErrorContent">
            {appointmentsLoading && <LoadingState />}
            {appointmentsError && <FailureState />}
          </div>
        )}
        <Grid className="UpcomingAppointments">
          <Grid.Row columns={1} style={{ paddingTop: '40px' }}>
            <Grid.Column>
              <Header as='h4'>{t('upComingAppointments')}</Header>
            </Grid.Column>
          </Grid.Row>
          {!isEmpty(appointments) ? (
            appointments.map((appointment, index) => (

              (loggedInUser.role === "Donor" && loggedInUser?.pdn !== null && appointment?.isGiftcardEnabled ?
                (
                  <>
                    <Grid.Row key={index} columns={3} className="UpcomingAppointmentEntry">
                      <Grid.Column computer={4} tablet={8} mobile={8}>
                        <p className='AppointmentDate'>
                          {moment.parseZone(appointment.startDateTime).format('MM/DD/YYYY')}
                        </p>
                        <p className='AppointmentLocation'>
                          {`${splitCenterName(appointment?.centerName)}, ${appointment.centerStateCode}`}
                        </p>
                      </Grid.Column>
                      <Grid.Column computer={4} tablet={8} mobile={8}>
                        <p className='AppointmentDate'>
                          {appointment.startTime}
                        </p>
                      </Grid.Column>
                      <Grid.Column computer={8} tablet={15} mobile={15}>
                        <Grid className="AppointmentButton">
                          <PrimaryButton
                            text={t('modify')}
                            width={85}
                            classType='Secondary'
                            fontSize="14px"
                            onClick={async () => {
                              await getAppointmentCenter(appointment.centerSfid)
                              handleAppointment(appointment)
                              toggleCancelModal()
                            }}
                          />
                          <PrimaryButton
                            text={t("Change Payment Method")}
                            width={230}
                            classType="Inverted"
                            fontSize="14px"
                            onClick={() => {
                              toggleChangePaymentModal();
                              handleAppointment(appointment);
                            }}
                          />
                        </Grid>
                      </Grid.Column>
                    </Grid.Row>
                  </>
                ) : (
                  <Grid.Row key={index} columns={3} className="UpcomingAppointmentEntry">
                    <Grid.Column computer={4} tablet={8} mobile={8}>
                      <p className='AppointmentDate'>
                        {moment.parseZone(appointment.startDateTime).format('MM/DD/YYYY')}
                      </p>
                      <p className='AppointmentLocation'>
                        {`${splitCenterName(appointment?.centerName)}, ${appointment.centerStateCode}`}
                      </p>
                    </Grid.Column>
                    <Grid.Column computer={4} tablet={8} mobile={8}>
                      <p className='AppointmentDate'>
                        {appointment.startTime}
                      </p>
                    </Grid.Column>
                    <Grid.Column className="ModifyButton" computer={6} tablet={15} mobile={15}>
                      <PrimaryButton
                        text={t('modify')}
                        width={85}
                        classType='Secondary'
                        fontSize="14px"
                        onClick={async () => {
                          await getAppointmentCenter(appointment.centerSfid)
                          handleAppointment(appointment)
                          toggleCancelModal()
                        }}
                      />
                    </Grid.Column>
                  </Grid.Row>
                ))
            ))
          ) : (
            <p className='NoResults'>{t('youDontHaveanyUpComing')}</p>
          )}
        </Grid>
        <h3 className="PastDonationsHeader">
          <span>&#42;</span>
          {t('thisListDoesNotIncludedOn')}
        </h3>
        <Grid className="PastDonations">
          <Grid.Row columns={3} className='DonationHeader'>
            <Grid.Column>{t('date')}</Grid.Column>
            <Grid.Column>{t('type')}</Grid.Column>
            <Grid.Column textAlign='left'>{t('location')}</Grid.Column>
          </Grid.Row>
          {isEmpty(donationList) && (
            <Grid.Row columns={1}>
              <Grid.Column>
                <Message negative>
                  <p>{t('noRecordsFoundForPast')}</p>
                </Message>
              </Grid.Column>
            </Grid.Row>
          )}
          {!isEmpty(donationList) && donationList.map((donation, index) => (
            <Grid.Row key={index} columns={3} className='AppointmentEntries'>
              <Grid.Column>{moment(donation.donationDate).format('MM/DD/YYYY')}</Grid.Column>
              <Grid.Column style={{ fontWeight: 'bold' }}>{t('donation')}</Grid.Column>
              <Grid.Column textAlign='left'>{`${donation.centerName}`}</Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
        <ModifyAppointmentModal
          open={openModal}
          toggleModal={toggleModal}
          appointment={appointment}
          handleAppointment={handleAppointment}
          getUpcomingAppointments={getUpcomingAppointments}
          donor={donor}
          toggleConfirmModal={toggleConfirmModal}
          center={apptCenter}
        />
        <ModifyOrCancelAppointmentModal
          open={openCancelModal}
          toggleModal={toggleCancelModal}
          appointment={appointment}
          toggleModifyModal={toggleModal}
          getSlotsForAppointmentType={getSlotsForAppointmentType}
          center={apptCenter}
          cancelAppointment={cancelAppointment}
          getUpcomingAppointments={getUpcomingAppointments}
          donor={donor}
          toggleConfirmCancellationModal={toggleConfirmCancellationModal}
          getDonorCentersAll={getDonorCentersAll}
          getAllCenters={getAllCenters}
          loggedInUser={loggedInUser}
        />
        <ConfirmCancellationModal
          loggedInUser={loggedInUser}
          open={openConfirmCancellationModal}
          toggleModal={toggleConfirmCancellationModal}
          appointment={appointment}
          getSlotsForAppointmentType={getSlotsForAppointmentType}
          center={apptCenter}
          cancelAppointment={cancelAppointment}
          getUpcomingAppointments={getUpcomingAppointments}
          donor={donor}
          cancelAppointmentSuccess={cancelAppointmentSuccess}
          cancelAppointmentLoading={cancelAppointmentLoading}
          cancelAppointmentFailure={cancelAppointmentFailure}
        />
        <ConfirmAppointmentModal
          open={openConfirmModal}
          toggleModal={toggleConfirmModal}
          appointment={appointment}
          update
          donor={donor}
          center={apptCenter}
          toggleAddToCalendarModal={toggleAddToCalendarModal}
          loggedInUser={loggedInUser}
        />
        <AddToCalendarModal
          open={openAddToCalendarModal}
          toggleModal={toggleAddToCalendarModal}
          appointment={appointment}
          donor={donor}
          center={apptCenter}
          update
          createNewAppointmentSuccess={rescheduleAppointmentSuccess}
          createNewAppointmentFailure={rescheduleAppointmentFailure}
          loggedInUser={loggedInUser}
          lastPhysicalDate={lastPhysicalDate}
        />
        <ChangePaymentMethodModal
          open={openChangePaymentModal}
          toggleModal={toggleChangePaymentModal}
          appointment={appointment}
          setOpenModal={setOpenChangePaymentModal}
        />
      </Container>
    </Visibility>
  )
}

const mapStateToProps = ({ centers, donors, appointments, users }) => ({
  loggedInUser: users.user,
  center: centers.getAllCentersSuccess?.data,
  centerLoading: centers.getAllCentersLoading,
  donorCenters: centers.donorCenters?.data,
  donor: donors.donors?.data,
  appointments: appointments.getUpcomingAppointmentsSuccess,
  appointmentsLoading: appointments.getUpcomingAppointmentsLoading,
  appointmentsError: appointments.getUpcomingAppointmentsFailure,
  inquiry: donors.inquiryDonorSuccess?.data,
  nearbyCenters: centers.nearbyCenters,
  centerStatus: centers.centerStatus,
  rescheduleAppointmentSuccess: appointments.rescheduleAppointmentSuccess?.data,
  rescheduleAppointmentFailure: appointments.rescheduleAppointmentFailure,
  rescheduleAppointmentLoading: appointments.rescheduleAppointmentLoading,
  cancelAppointmentSuccess: appointments.cancelAppointmentSuccess?.data,
  cancelAppointmentLoading: appointments.cancelAppointmentLoading,
  cancelAppointmentFailure: appointments.cancelAppointmentFailure,
  apptCenter: centers.getAppointmentCenterSuccess?.data,
  lastPhysicalDate: donors?.inquiryDonorSuccess?.data?.lastPhysicalDate,
})

const mapDispatchToProps = (dispatch) => ({
  getDonorCentersAll: (searchStr) => dispatch(centersActions.getDonorCentersAll(searchStr)),
  getSlotsForAppointmentType: (
    forDate,
    fromDateTime,
    toDateTime,
    numberOfChildren,
    centerSfid,
    appointmentType,
    rescheduleId,
    donorSfid
  ) =>
    dispatch(
      centersActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        rescheduleId,
        donorSfid
      )
    ),
  cancelAppointment: (id, cancelledFrom) => dispatch(appointmentsActions.cancelAppointment(id, cancelledFrom)),
  getAllCenters: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  getUpcomingAppointments: (donorSfid) => dispatch(appointmentsActions.getUpcomingAppointments(donorSfid)),
  getDonorNearbyCenters: (donorSfid) => dispatch(centersActions.getDonorCenterInfo(donorSfid)),
  getAppointmentCenter: (sfid) => dispatch(centersActions.getAppointmentCenter(sfid)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAppointments)
