import React from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Header, Icon } from 'semantic-ui-react'
import { numberWithCommas } from '../../../Helpers/Utils'
import './styles.scss'

const DebitCardHeader = ({ wirecard }) => {
  const { t } = useTranslation('DebitCardHeader');
  return (
    <Grid columns={2} className='DebitCardHeader'>
      <Grid.Column>
        <Header as='h4' className='LabelHeader'>
        {t('debitCardBalance')}
        </Header>
        <Header as='h1' className='LeftTextHeader'>
          ${numberWithCommas(wirecard.balance.balanceAvailable)}
        </Header>
      </Grid.Column>
      <Grid.Column textAlign='right' verticalAlign='bottom'>
        <Header as='h4' className='LabelHeader'>
          <Icon name='credit card outline' style={{ fontSize: '2.2em', margin: '0' }} />
        </Header>
        <Header as='h1' className='RightTextHeader'>
          • • • •{'   '} • • • • {'   '}• • • • {'   '}{wirecard.card.cardNumber.substr(wirecard.card.cardNumber.length - 4)}
        </Header>
      </Grid.Column>
    </Grid>
  )
}

export default DebitCardHeader
