import React, { useEffect, useState } from 'react'
import { Container } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { useLocation } from 'react-router-dom'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const HeroAccountCreate = importer('Components/HeroAccountCreate')
const AccountCreateForm = importer('Components/Forms/AccountCreateForm')
// Actions
const centersActions = importer('Stores/Centers/Actions')
const AuthCustomActions = importer('Stores/AuthCustom/Actions')
const publicContentActions = importer('Stores/PublicContent/Actions')
const donorsActions = importer('Stores/Donors/Actions')

const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { webArticles } = importer('Helpers/WebArticles')

const AccountCreate = ({
  centers,
  countries,
  states,
  getDonorCentersAll,
  getDonorCentersAllLoading,
  getCountries,
  getAllStates,
  getGenders,
  genders,
  getPhoneTypes,
  getEthnicityOptions,
  phoneTypes,
  ethnicityOptions,
  register,
  registerSuccess,
  registerFailure,
  getDonors,
  donor,
  webContents,
  signUpUser,
  donorBySfid,
  getDonorBySfid
}) => {
  

  const location = useLocation()
  const [ registrationId, setRegistrationId] = useState(null)
  const [ registrationDonor, setRegistrationDonor] = useState(null)

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      const regId = searchParams.get('registrationId')
      const pid=searchParams.get('pid')
      setRegistrationId(regId)
      if (regId && pid) {
        getDonorBySfid(regId,pid)
      }
    } 
    if (location.registrationId && location.pid) {
      setRegistrationId(location.registrationId)
      getDonorBySfid(location.registrationId,location.pid)
    } 
   
  }, [location])

  useEffect(() => {
    if (donorBySfid && donorBySfid.sfid == registrationId) {
      window.sessionStorage.setItem('lead', JSON.stringify(donorBySfid))
      setRegistrationDonor(donorBySfid)
    } 
  }, [donorBySfid])

  useEffect(() => {
    getCountries()
    getAllStates()
    getGenders()
    getPhoneTypes()
    getEthnicityOptions()
  }, [])

  const smsStatement = ArticleByIdentifier(
    webContents,
    webArticles.MOBILE_SMS_STATEMENT
  )

  const privacyStatement = ArticleByIdentifier(
    webContents,
    webArticles.MOBILE_AGREE_PROVIDE_INFORMATION
  )

  const touStatement = ArticleByIdentifier(
    webContents,
    webArticles.MOBILE_TERMS_OF_USE
  )

  const eligibilityRequirement = ArticleByIdentifier(
    webContents,
    webArticles.MOBILE_ELIGIBILITY_REQUIREMENTS
  )

  const createProfilePageHeading = ArticleByIdentifier(
    webContents,
    webArticles.CREATE_PROFILE_PAGE_HEADING
  )

  return (
    <div className='AccountCreate'>
      <HeroAccountCreate 
        eligibilityRequirement={eligibilityRequirement} createProfilePageHeading={createProfilePageHeading} />
      <div className='AccountFormContainer'>
        <Container>
          <AccountCreateForm
            getGenders={getGenders}
            getPhoneTypes={getPhoneTypes}
            getEthnicityOptions={getEthnicityOptions}
            countries={countries}
            centers={centers}
            states={states}
            genders={genders}
            phoneTypes={phoneTypes}
            ethnicityOptions={ethnicityOptions}
            getDonorCentersAll={getDonorCentersAll}
            getDonorCentersAllLoading={getDonorCentersAllLoading}
            register={register}
            registerSuccess={registerSuccess}
            registerFailure={registerFailure}
            getDonors={getDonors}
            donor={donor}
            smsStatement={smsStatement}
            privacyStatement={privacyStatement}
            touStatement={touStatement}
            donorDetailsSaved={registrationDonor || signUpUser?.donorDetails}
          />
        </Container>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  centers: state.centers.donorCenters?.data,
  center: state.centers.getAllCentersSuccess,
  getDonorCentersAllLoading: state.centers.getAllCentersLoading,
  countries: state.publicContent.Countries,
  genders: state.publicContent.Genders,
  phoneTypes: state.publicContent.PhoneTypes,
  ethnicityOptions: state.publicContent.Ethnicity,
  states: state.publicContent.States,
  signUpUser: state.users.registerSignUp,
  registerLoading: state.authCustom.registerLoading,
  registerSuccess: state.authCustom.registerSuccess,
  registerFailure: state.authCustom.registerFailure,
  donor: state.donors.donors?.data,
  webContents: state.publicContent.WebArticles?.completeArticles,
  donorBySfid: state.donors.getDonorBySfidSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({
  getDonorCentersAll: (searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, setCenterDistance) => dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType, addNewDonorBonusInformation, setCenterDistance)),
  getCountries: () => dispatch(publicContentActions.getCountries()),
  getAllStates: () => dispatch(publicContentActions.getAllStates()),
  getGenders: () => dispatch(publicContentActions.getGenders()),
  register: (donor) => dispatch(AuthCustomActions.register(donor)),
  getDonors: (id) =>  dispatch(donorsActions.getDonors(id)),
  getDonorBySfid: (sfid,pid) => dispatch(donorsActions.getDonorBySfid(sfid,pid)),
  getPhoneTypes: () => dispatch(publicContentActions.getPhoneTypes()),
  getEthnicityOptions: () => dispatch(publicContentActions.getEthnicityOptions()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountCreate)
