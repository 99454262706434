import React from "react";
import { connect } from "react-redux";
import { Image, Grid, GridRow, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import Slider from "react-slick";

const DonorTestimonials = ({images, allArticles}) => {
  const { t } = useTranslation("DonorTestimonials");
  const slider = React.useRef(null);

  const donorTestimonialContentsArray = allArticles?.length > 0 ? allArticles.filter(item => item.title__c !== null && item.title__c.includes("Donor Testimonials Carousel on the homepage")) : [];
  const donorTestimonialIconsArray = images?.length > 0 ? images.filter(item => item.name__c !== null && item.name__c.includes("Donor Testimonials Carousel on the homepage")) : [];

  const donorTestimonialsArray = donorTestimonialIconsArray?.reduce((arr, obj) => {
    arr.push(Object.assign({}, obj, donorTestimonialContentsArray?.find(item => item.title__c === obj.name__c)))
    return arr;
  }, []);

  const sliderSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };

  return (
    donorTestimonialsArray.length ?
      <div className="DonorTestimonialsContainer">
        <GridRow className="TestimonialHeading">
          {t("donorsLikeYou")}
        </GridRow>
        <Slider ref={slider} {...sliderSettings} className="TestimonialCard">
          {donorTestimonialsArray?.map((item) => (
            <Grid className="Testimonial" key={item.content_id__c} column={1}>
              <GridRow className="DonorImage">
                <Image
                  src={item.image_url_desktop__c}
                  alt={item.image_alt_text__c || "donor-image"}
                  circular
                  size="tiny"
                  width="80" 
                  height="80"
                />
              </GridRow>
              <GridRow className="DonorTestimonial">
                  <div dangerouslySetInnerHTML={{ __html: item.content__c }} />
              </GridRow>
            </Grid>
          ))}
        </Slider>
        <div className="ArrowContainer">
          <button
            className="SlickArrow LeftArrow"
            onClick={() => slider?.current?.slickPrev()}
          >
            <Icon name="chevron left" size="big"/>
          </button>
          <button
            className="SlickArrow RightArrow"
            onClick={() => slider?.current?.slickNext()}
          >
            <Icon name="chevron right" size="big"/>
          </button>
        </div>
      </div>
      : null
  );
};

const mapStateToProps = (state) => ({
    allArticles: state.publicContent.WebArticles?.completeArticles,
    images: state.publicContent.AppImages
});

export default connect(mapStateToProps, null)(DonorTestimonials);
