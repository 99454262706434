import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Header, Grid, Container } from 'semantic-ui-react'
import './styles.scss'

const Messages = ({ messages, user }) => {
  const [messageList, setMessageList] = useState([]);
  const { t } = useTranslation('Messages');
  useEffect(() => {
    setMessages()
  }, [messages, user])

  const setMessages = () => {
    let centerID = user ? user.defaultCenter.sfid : null;

    if (messages && messages.length && centerID) {
      const _messages = messages.find(item => item.centerID === centerID);

      if (_messages && _messages.list.length) {
        const { list } = _messages;
        const _messageList = list.filter(item => item.message_type__c === 'Staff' && item.is_active__c).sort((m1, m2) => m2.id - m1.id);
        setMessageList(_messageList);
      }
    }
  }

  return (
    <Container fluid>
      <Grid columns={1} padded>
        <Grid.Column className='MessagesColumn'>
          <div className='Messages'>
            <Header as='h4'>{t('messages')}</Header>
            {messageList.map((message, index) => (
              <p key={index} className='MessagesText'>
                {message.description__c}
              </p>
            ))}
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

const mapStateToProps = state => ({
  messages: state.publicContent.messagesFiltered,
  user: state.users.loginHerokuSuccess,
})

export default connect(mapStateToProps, null)(Messages)
