import React, { useEffect } from "react";
import { Header, Grid } from "semantic-ui-react";
import importer from "@takedapdt/biolife-core/src/importer";
import { connect } from "react-redux";
import "./styles.scss";
import { useTranslation } from "react-i18next";
const PrimaryButton = importer("Components/Buttons/PrimaryButton");
const LoyalDonorActions = importer("Stores/Loyalty/Actions");
const {isMobileWeb} = importer('Helpers/Utils', null)
const LoyaltyDashboardCard = ({
  donor,
  getLoyaltyDetailsByPdn,
  loyaltyPoints,
  onSubmit,
  showButton,
  submitHandler,
  text,
  loyaltyActivity,
  setTab
}) => {
  const { t,i18n } = useTranslation("LoyaltyDashboard");
  useEffect(() => {
    if (donor.pdn != null) {
      getLoyaltyDetailsByPdn(donor.pdn);
    }
  }, []);
  const RedeemTextStyle = showButton
    ? "RedeemTextWithButton"
    : "RedeemTextWithoutButton";
  const GridStyle = showButton
    ? "LoyaltyGridWithButton"
    : "LoyaltyGridWithoutButton";
    const handleTabClick = () => {
      if (setTab) {
        setTab("REWARD_HISTORY");
      } else {
          window.location.href = '/dashboard/my-rewards';
          window.sessionStorage.setItem('LOYALTY_DASHBOARD_ACTIVE_TAB', 'REWARD_HISTORY')
          window.scrollTo(0, 0);
    }
    };
  return (
    <div>
      <Header as="h2" className="BioLifeRewards">
        {t("BioLife Rewards")}
      </Header>
      <Grid columns={2} className={GridStyle}>
        <Grid.Row className="GridRow">
          <Grid.Column className="LeftPart" computer={8} tablet={8} >
          <p className="RewardPointsText">{text}</p>
            <span className="RewardPoints" style={{fontSize: isMobileWeb() && loyaltyPoints?.toString().length > 4 && 60 }}>{loyaltyPoints || 0}</span>
          </Grid.Column>
          <Grid.Column
            className="RightPart"
            computer={8}
            tablet={8}
          >
            {showButton && (
              <PrimaryButton
                className="PrimaryBtn"
                text={t("RedeemRewards")}
                onClick={() => {
                  submitHandler(!onSubmit);
                }}
              ></PrimaryButton>
            )}
  {/* implementation of expirationDetails*/}
     {loyaltyActivity?.expirationDetails?.expirationMsg ? (
            <>
      <div className="expirationDetails">
        <span className={RedeemTextStyle}>
          {i18n.language === "en-US" ? loyaltyActivity?.expirationDetails?.expirationMsg : loyaltyActivity?.expirationDetails?.expirationMsgSpanish}
        </span>
        <div className="expirationText">
          {t('Click')} <span className='expirationDetailsNav' onClick={handleTabClick}>{t('Here')}</span> {t("learnMore")}
        </div>
      </div>
            </>
          ) : (
            <p></p>
      )}

          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};
const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  loyaltyPoints: state.loyalty.getLoyaltyDetails?.data?.loyaltyPoints,
  loyaltyActivity: state.loyalty?.getLoyaltyActivity,
});

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyDetailsByPdn: (pdn) => {
    dispatch(LoyalDonorActions.getLoyaltyDetails(pdn));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoyaltyDashboardCard);
