import React , { useState, useEffect } from "react";
import importer from "@takedapdt/biolife-core/src/importer";
import { Grid, GridColumn, GridRow, Image, Header, Form, Modal, Button, Input } from "semantic-ui-react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { isEmpty } from 'lodash'
import "./styles.scss";
import CloseIcon from '../../../../assets/media/icons/icon-close-web.svg'
import infoIcon from '../../../../assets/media/images/info-icon.png'
import { NavLink } from 'react-router-dom'

const { ArticleByIdentifier } = importer("Stores/PublicContent/Select", null);
const { webArticles } = importer("Helpers/WebArticles");
const PrimaryButton = importer("Components/Buttons/PrimaryButton");
const LoyaltyDashboardCard = importer("Components/Dashboard/LoyaltyDashboardCard");
const RedeemPointsSuccessModal = importer('Components/LoyaltyModal')
const RedeemPointsFailureModal = importer('Components/LoyaltyModal')
const NotEngoughRedeemPontsModal = importer('Components/LoyaltyModal')
const RedeemPausedModal = importer('Components/LoyaltyModal')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const LoyalDonorActions = importer("Stores/Loyalty/Actions")
const PublicContentActions = importer('Stores/PublicContent/Actions')

const RedeemRewards = ({ 
   publicContent,
   images,
   loyaltyPoints,
   getLoyaltyConstant,
   redeemRewards, 
   redeemConstantValues, 
   loyaltyDetails,
   getLoyaltyConstantLoading,
   redeemRewardsLoading,
   redeemRewardsFailure,
   redeemRewardsResponse,
   user,
   navigateToTab,
   redeemPaused,
   vendorRedeemPaused,
   getRedeemPaused,
   getVendorRedeemPaused
}) => {
  const { t } = useTranslation("RedeemRewards");
  const redeemRewardsSubtext = ArticleByIdentifier(publicContent,webArticles?.REDEEM_SUBTEXT);
  const redeemGiftCardSubtext = ArticleByIdentifier(publicContent,webArticles?.REDEEM_GIFTCARD_SUBTEXT);
  const redeemCharitableDonationSubtext = ArticleByIdentifier( publicContent,webArticles?.REDEEM_CHARITABLE_DONATION_SUBTEXT);
  const cashOnDebitCardSubtext = ArticleByIdentifier(publicContent, webArticles?.REDEEM_DEBITCARD_SUBTEXT);
  const redeemRewardsSuccessMsg = ArticleByIdentifier(publicContent, webArticles?.REDEEM_REWARDS_SUCCES_MSG);
  const redeemRewardsErrorMsg = ArticleByIdentifier(publicContent, webArticles?.REDEEM_REWARDS_ERROR_MSG);
  const giftCardImage = images?.find( (i) => i?.name__c === "Donor Dashboard Reward Redemption 01" );
  const charityOptionImage = images?.find((i) => i?.name__c === "Donor Dashboard Reward Redemption 02");
  const debitCardImage = images?.find((i) => i?.name__c === "Donor Dashboard Reward Redemption 03");

  const [openRedeemModal, setOpenRedeemModal] = useState(false)
  const [openRedeemSuccessModal, setOpenRedeemSuccessModal] = useState(false)
  const [openRedeemFailureModal, setOpenRedeemFailureModal] = useState(false)
  const [openNotEnoughPointsModal, setOpenNotEnoughPointsModal] = useState(false)
  const [openRedeemPausedModal, setOpenRedeemPausedModal] = useState(false)
  const [redeemOptions, setRedeemOptions] = useState()
  const [redeemConstants, setRedeemConstants]= useState({})
  const [redeemPoints, setredeemPoints] = useState(0)
  const [cash, setCash] = useState(0)
  const [loyaltyMinPoints, setLoyaltyMinPoints] = useState(0)
  const [redeemSubmitted, setRedeemSubmitted] = useState(false)
  const toggleModal = () => setOpenRedeemModal(!openRedeemModal)
  const isOnbeRedeemOff = vendorRedeemPaused?.find(item => item.name === 'Onbe')?.bll_active__c === 'Yes' ? false : true 

  const redeemPointsFormSubmit = () => {
   const data={
      loyaltyPoints: redeemPoints,
      loyaltyAmount: cash,
      redeemOption: redeemOptions,
      pdn: user.pdn
    }
    redeemRewards(data)
    setRedeemSubmitted(true)
  }

  // const redeemEligible =() =>{
  //   if(loyaltyPoints === null || loyaltyPoints === undefined || isEmpty(user?.pdn) || loyaltyPoints < redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N){
  //     setOpenNotEnoughPointsModal(true)
  //   } else{
  //     setOpenRedeemModal(true)
  //   }
  // }

  const redeemPausedMethod= ()=>{
    if (redeemPaused?.bll_redeem_paused__c === true) {
      setOpenRedeemPausedModal(true);
    }
    else if(loyaltyPoints === null || loyaltyPoints === undefined || isEmpty(user?.pdn) || loyaltyPoints < redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N){
      setOpenNotEnoughPointsModal(true)
    }
    else{
      setOpenRedeemModal(true)
    }
  }

  useEffect(()=>{
    //Converting string into numbers
    if(redeemConstantValues !== null){
      setRedeemConstants({
        LOYALTY_CHARITY_RATE_N : Number(redeemConstantValues?.LOYALTY_CHARITY_RATE),
        LOYALTY_DEBIT_CARD_RATE_N: Number(redeemConstantValues?.LOYALTY_DEBIT_CARD_RATE),
        LOYALTY_GIFT_CARD_RATE_N: Number(redeemConstantValues?.LOYALTY_GIFT_CARD_RATE),
        LOYALTY_INCREMENTAL_POINTS_N: Number(redeemConstantValues?.LOYALTY_INCREMENTAL_POINTS),
        LOYALTY_MAXIMUM_REDEEM_POINTS_N:  Number(redeemConstantValues?.LOYALTY_MAXIMUM_REDEEM_POINTS),
        LOYALTY_MINIMUM_REDEEM_POINTS_N: Number(redeemConstantValues?.LOYALTY_MINIMUM_REDEEM_POINTS)
      })

    // Set inital value for Redeem points as minimum redeem points
    setredeemPoints(Number(redeemConstantValues?.LOYALTY_MINIMUM_REDEEM_POINTS))   
    setLoyaltyMinPoints(Number(redeemConstantValues?.LOYALTY_MINIMUM_REDEEM_POINTS)) 
    }
  },[redeemConstantValues])

  // handle sucess 
  useEffect(()=>{
    if(redeemSubmitted &&  redeemRewardsResponse !== null){
      setOpenRedeemModal(false)
      setOpenRedeemSuccessModal(true)
    }
  },[redeemRewardsResponse])

  // handle failure 
  useEffect(()=>{
    if(redeemSubmitted === true && redeemRewardsFailure !== null){
      setOpenRedeemModal(false)
     setOpenRedeemFailureModal(true)
    }
  },[redeemRewardsFailure])
  

  const incrementRedeemPonts = () => {
    if ( redeemPoints + redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N <= loyaltyPoints && redeemPoints <= redeemConstants?.LOYALTY_MAXIMUM_REDEEM_POINTS_N) {
      setredeemPoints(redeemPoints + redeemConstants.LOYALTY_INCREMENTAL_POINTS_N)
    }
  }
  const decrementRedeemPonts = () => {
    if (redeemPoints > redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N) {
      setredeemPoints(redeemPoints - redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N)
    }
  }

  // Get Loyalty contants on the inital render
  useEffect(()=>{
    getLoyaltyConstant()
    getVendorRedeemPaused()
  },[])

  // update the cash value when the redeem points change
  useEffect(() => {
    switch (redeemOptions) {
      case 'DEBIT_CARD':
        setCash(redeemPoints * redeemConstants?.LOYALTY_DEBIT_CARD_RATE_N)
        break
      case 'GIFT_CARD':
        setCash(redeemPoints * redeemConstants?.LOYALTY_GIFT_CARD_RATE_N)
        break
      case 'CHARITY':
        setCash(redeemPoints * redeemConstants?.LOYALTY_CHARITY_RATE_N)
        break
    }
  }, [redeemPoints, redeemOptions])

  const navigateToWaystoEarn =() =>{
    setOpenNotEnoughPointsModal(false)
    navigateToTab('WAYS_TO_EARN')
    window.sessionStorage.setItem('LOYALTY_DASHBOARD_ACTIVE_TAB',"WAYS_TO_EARN")
  }
  useEffect(()=>{
    getRedeemPaused();
  },[])
  return (
    <div>
      <LoyaltyDashboardCard
        showButton={false}
        text={t("Rewards Points")}
      />
      <Grid className="RedeemRewardsGrid">
        <GridRow className="RedeemRewardsSubtext">
          <div
            dangerouslySetInnerHTML={{
              __html: redeemRewardsSubtext?.content__c,
            }}
          />
          <PrimaryButton
            className="RedeemRewardsButton"
            text={t("RedeemRewardsNow")}
            loading={getLoyaltyConstantLoading}
            width={200}
            onClick={redeemPausedMethod}
          />
        </GridRow>

        <GridRow columns={2} className="RedeemOptionContainer">
          <GridColumn
            computer={8}
            tablet={8}
            mobile={16}
            className="RedeemOptionContainerColumn"
          >
            <Header as="h1" className="RedeemOptionHeader">
              {redeemGiftCardSubtext?.title__c}
            </Header>
            <div
              className="RedeemOptionText"
              dangerouslySetInnerHTML={{
                __html: redeemGiftCardSubtext?.content__c,
              }}
            />
          </GridColumn>
          <GridColumn
            computer={8}
            tablet={8}
            mobile={16}
            className="ImageContainer"
          >
            <Image
              src={giftCardImage?.image_url_desktop__c}
              alt={giftCardImage?.image_alt_text__c}
              className="RedeemOptionImage"
            />
          </GridColumn>
        </GridRow>
        <GridRow columns={2} className="RedeemOptionContainer SecondContainer">
          <GridColumn
            computer={8}
            tablet={8}
            mobile={16}
            className="ImageContainer SecondImageContainer"
          >
            <Image
              src={charityOptionImage?.image_url_desktop__c}
              alt={charityOptionImage?.image_alt_text__c}
              className="SecondRedeemOptionImage"
            />
          </GridColumn>
          <GridColumn
            computer={8}
            tablet={8}
            mobile={16}
            className="RedeemOptionContainerColumn SecondRedeemOptionContainerColumn"
          >
            <Header as="h1" className="RedeemOptionHeader">
              {redeemCharitableDonationSubtext?.title__c}
            </Header>
            <div
              className="RedeemOptionText"
              dangerouslySetInnerHTML={{
                __html: redeemCharitableDonationSubtext?.content__c,
              }}
            />
          </GridColumn>
        </GridRow>
        <GridRow
          columns={2}
          className="RedeemOptionContainer LastRedeemOptionContainer"
        >
          <GridColumn
            computer={8}
            tablet={8}
            mobile={16}
            className="RedeemOptionContainerColumn"
          >
            <Header as="h1" className="RedeemOptionHeader">
              {cashOnDebitCardSubtext?.title__c}
            </Header>
            <div
              className="RedeemOptionText"
              dangerouslySetInnerHTML={{
                __html: cashOnDebitCardSubtext?.content__c,
              }}
            />
          </GridColumn>
          <GridColumn
            computer={8}
            tablet={8}
            mobile={16}
            className="ImageContainer"
          >
            <Image
              src={debitCardImage?.image_url_desktop__c}
              alt={debitCardImage?.image_alt_text__c}
              className="RedeemOptionImage"
            />
          </GridColumn>
        </GridRow>
        <GridRow className="ButtonContainer">
          <PrimaryButton
            text={t("RedeemRewardsNow")}
            loading={getLoyaltyConstantLoading}
            width={200}
            onClick={redeemPausedMethod}
          />
        </GridRow>
      </Grid>
            {/* Redeem modal starts here */}
        <Modal open={openRedeemModal} size="large" className='Redeem-points-modal'>
        <Modal.Header className='redeemHeaderContainer'>
          <Grid columns={1}>
            <Grid.Row className="closebtnRow">
              <Image src={CloseIcon} className="close-btn" alt='Close' onClick={!redeemRewardsLoading? toggleModal : null} />
            </Grid.Row>
          </Grid>

          <Grid columns={2}>
            <Grid.Column computer={10} mobile={16} className='ModalHeader'>
              <Header as='h2' >
                {t('redeemPoints')}
              </Header>
            </Grid.Column>

            <Grid.Column computer={6} mobile={16} className="available-point-container">
              <Grid.Row >
                <div className="availabel-points-txt">{t('availablePoints')}</div>
                <div className="availabel-points-count">{loyaltyPoints}</div>
              </Grid.Row>
            </Grid.Column>

          </Grid>
        </Modal.Header>

        <Modal.Content>
          {getLoyaltyConstantLoading &&(
            <LoadingState/>
          )}
          {!getLoyaltyConstantLoading &&(
          <div className="Redeem-points-modal-content">
            <div className="redeem-intro-txt">
             {t('redeemSubText')}
            </div>
            <div className="redemption-options-container">
              {t('redemptionOptions')}
              <Form>
                <Form.Field className="radio-btn-group">
                  <input
                    type="radio"
                    value="DEBIT_CARD"
                    className="radio-input"
                    checked={redeemOptions === "DEBIT_CARD"}
                    onChange={() => setRedeemOptions('DEBIT_CARD')}
                    disabled={isOnbeRedeemOff}
                  />
                  <div style={{color: isOnbeRedeemOff && 'gray'}} className="redeem-text-container">
                    <div className="redeem-text">{t('cashOnDebitCard')}</div>
                    <div className="redeem-description">{t('cashOnDebitCardDesc')}</div>
                  </div>
                </Form.Field>
                  {isOnbeRedeemOff && 
                  <div className="vender-redeem-off-error">
                    <Image className='InfoIcon' src={infoIcon} width="18" height="18"/> 
                    <p><b>{t('onbeOffErrorMessage')}</b></p>
                  </div>}
                <Form.Field className="radio-btn-group">
                  <input
                    type="radio"
                    value="GIFT_CARD"
                    className="radio-input"
                    checked={redeemOptions === "GIFT_CARD"}
                    onChange={() => setRedeemOptions('GIFT_CARD')}
                  />
                  <div className="redeem-text-container">
                    <div className="redeem-text">{t('giftCard')}</div>
                    <div className="redeem-description">{t('giftCardDesc')}</div>
                  </div>
                </Form.Field>

                <Form.Field className="radio-btn-group">
                  <input
                    type="radio"
                    value="CHARITY"
                    className="radio-input"
                    checked={redeemOptions === "CHARITY"}
                    onChange={() => setRedeemOptions('CHARITY')}
                  />
                  <div className="redeem-text-container">
                    <div className="redeem-text">{t('charitableDonation')}</div>
                    <div className="redeem-description">{t('charitableDonationDesc')}</div>
                  </div>
                </Form.Field>
              </Form>
            </div>

            <div className="points-selector-container">
              <Grid columns={2}>
                <Grid.Column computer={11} mobile={16}>
                  <div className="redeem-points-selector-container">
                    <div className="no-of-ponts-txt">{t('NumberOfPointsYouWouldLikeToUse')}</div>
                    <div style={{display: 'flex'}}>
                      <div className="redeem-btn-container left-btn">
                        <Button circular basic className="redeem-control-btn" 
                         disabled={redeemPoints - redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N < redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N ? true : false} 
                         onClick={() => decrementRedeemPonts()} > - </Button>
                      </div>
                      <div className="redeem-pnt-input-container">
                        <Input className="redeem-pnts-input" type="number" value={redeemPoints} disabled/>
                        <p className="available-pnts">{redeemPoints} {t('of')} {loyaltyPoints} {t('pointsAvailable')}</p>
                      </div>
                      <div className="redeem-btn-container right-btn">
                        <Button circular basic className="redeem-control-btn" 
                         disabled={
                          (redeemPoints + redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N > redeemConstants?.LOYALTY_MAXIMUM_REDEEM_POINTS_N) || (redeemPoints + redeemConstants?.LOYALTY_INCREMENTAL_POINTS_N) > loyaltyPoints ? true : false} 
                          onClick={() => incrementRedeemPonts()}> + </Button>
                      </div>
                    </div>
                  </div>
                </Grid.Column>
                <Grid.Column computer={5} mobile={16}>
                  <div className="your-cash-value-container">
                    <div className="your-cash-value-text">{t('yourPointsCashValue')}</div>
                    <div className="cash"> ${cash}</div>
                  </div>
                </Grid.Column>
              </Grid>
            </div>
            <div className="submit-btn-container">
              <PrimaryButton
                text={t('redeem')}
                loading={redeemRewardsLoading}
                disabled={loyaltyDetails?.isRedeemProcessing || loyaltyPoints < redeemConstants?.LOYALTY_MINIMUM_REDEEM_POINTS_N || !redeemOptions}
                width={210}
                height={50}
                fontSize={16}
                type='submit'
                onClick={redeemPointsFormSubmit}
              />
              {redeemRewardsLoading &&(
               <div> 
                <p className="reedeem-processing-txt">{t('rewardsAreprocessing')}</p>
              </div>)}
            </div>

          </div>
            )}
        </Modal.Content>
      </Modal>

      {/* Redeem Points Success Modal */}
      <RedeemPointsSuccessModal
        openModal={openRedeemSuccessModal}
        header={redeemRewardsSuccessMsg?.title__c}
        onClose={() => setOpenRedeemSuccessModal(false)}
      >
        <Grid columns={2} stackable>
          <Grid.Row>
          <div
            dangerouslySetInnerHTML={{
              __html: redeemRewardsSuccessMsg?.content__c,
            }}
          />
          </Grid.Row>
          <div style={{margin:"auto"}}>
            <PrimaryButton 
              text={t("redeemMorePoints")}
              width={190}
              height={52}
              onClick={() => {
                setOpenRedeemSuccessModal(false)
                setOpenRedeemModal(true)
              }}/>
          </div>
        </Grid>
      </RedeemPointsSuccessModal>

      {/* Redeem Points Failure Modal */}
      <RedeemPointsFailureModal
        openModal={openRedeemFailureModal}
        header={redeemRewardsErrorMsg?.title__c}
        onClose={() => setOpenRedeemFailureModal(false)}
      >
        <Grid columns={2} stackable>
          <Grid.Row>
          <div
            dangerouslySetInnerHTML={{
              __html: redeemRewardsErrorMsg?.content__c,
            }}
          />
          </Grid.Row>
          <div style={{margin:"auto"}}>
            <PrimaryButton 
              text={t("tryAgain")}
              width={130}
              height={52}
              onClick={() => {
                setOpenRedeemFailureModal(false)
                setOpenRedeemModal(true)
              }}/>
          </div>
        </Grid>
      </RedeemPointsFailureModal>

      {/*Not Engough Redeem Points modal */}
      <NotEngoughRedeemPontsModal
        openModal={openNotEnoughPointsModal}
        header={t('ohNoMorePointsAreNeeded')}
        onClose={() => setOpenNotEnoughPointsModal(false)}
      >
        <Grid columns={2} stackable>
          <Grid.Row>
            <p className='ModalContent'>{t('youDonotHaveEnoughpnts',{loyaltyMinPoints})} <a className="navigateWaysToEarn" onClick={navigateToWaystoEarn}>{t('waysToEarn')}</a></p>
          </Grid.Row>
          <div style={{margin:"auto"}}>
            <PrimaryButton 
              text={t("close")}
              width={120}
              height={52}
              onClick={() => {
                setOpenNotEnoughPointsModal(false)
              }}/>
          </div>
        </Grid>
      </NotEngoughRedeemPontsModal>

{/*Redeem Paused modal */}
<RedeemPausedModal
        openModal={openRedeemPausedModal}
        header={t('redeemPausedTitle')}
        headerStyle={{   lineHeight: window.innerWidth >= 768 ? '40px' : '20px',}}
        onClose={() => setOpenRedeemPausedModal(false)}
      >
        <Grid columns={2} stackable>
          <Grid.Row className="redeemPausedRow">
            <div>
            <p className='ModalContent' style={{ marginBottom:'2px' }}>{t('redeemPausedText')}</p>
            <p className='ModalContent' style={{ marginBottom:'15px' }}>{t('redeemPausedTextDesc')}</p>
            </div>
            <div className="scheduleNow"><PrimaryButton 
              text={t("Schedulenow")}
              width={220}
              height={52}
              as={NavLink}
              to="/appointment-scheduling"
              className="SchedulenowButton"
              />
              </div>
          </Grid.Row>
        </Grid>
      </RedeemPausedModal>

    </div>
  );
};
const mapStateToProps = ({loyalty, users, publicContent }) => ({
  publicContent: publicContent.WebArticles?.completeArticles,
  images: publicContent?.AppImages,
  loyaltyDetails: loyalty.getLoyaltyDetails?.data,
  loyaltyPoints: loyalty.getLoyaltyDetails?.data?.loyaltyPoints,
  redeemConstantValues : loyalty?.getLoyaltyConstant,
  getLoyaltyConstantLoading: loyalty?.getLoyaltyConstantLoading,
  redeemRewardsLoading: loyalty?.redeemRewardsLoading,
  redeemRewardsFailure: loyalty?.redeemRewardsFailure,
  redeemRewardsResponse: loyalty?.redeemRewards,
  user: users?.user,
  redeemPaused: publicContent?.getRedeemPausedSuccess,
  vendorRedeemPaused: publicContent?.getVendorRedeemPausedSuccess
});

const mapDispatchToProps = dispatch => ({
  getLoyaltyConstant:() => { dispatch(LoyalDonorActions.getLoyaltyConstant()) },
  redeemRewards:(data)=> {dispatch(LoyalDonorActions.redeemRewards(data))},
  getRedeemPaused: () => { dispatch(PublicContentActions.getRedeemPaused())},
  getVendorRedeemPaused: () => { dispatch(PublicContentActions.getVendorRedeemPaused())},
});

export default connect(mapStateToProps, mapDispatchToProps)(RedeemRewards);
