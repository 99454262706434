import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Message } from 'semantic-ui-react'
 class index extends Component {
  render () {
    const { name, message ,t} = this.props
    return (
      <Message warning visible>
        {name && <Message.Header>{name}</Message.Header>}
        {message && (
          <p>{message}</p>
        )}
        {!message && (
          <p>{t('anErrorHasOccured')}</p>
        )}
      </Message>
    )
  }
}
export default withTranslation('StateErrorContainer')(index)
