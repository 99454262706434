import React, { useEffect, useState } from 'react'
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'

import moment from 'moment'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const FailureState = importer('Components/Dashboard/StateErrorContainer')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const ConfirmAppointmentModal = ({
  open,
  toggleModal,
  reloadCalendar,
  appointmentData,
  onSubmitRescheduleClick,
  scheduleApptErrorInfo,
  isScheduleApptLoading,
  scheduleApptSuccessInfo
}) => {
  const [confirmClicked, setConfirmClicked] = useState(false)
  const { t} = useTranslation('ConfirmRescheduleModal','modalCommon');

  useEffect(() => {
    if (open) { setConfirmClicked(false) }
  }, [open])

  const confirmAppointment = () => {
    setConfirmClicked(true)
    onSubmitRescheduleClick({...appointmentData, createdFrom: "CENTER_OPS"})
  }
  const closeConfirmation = () => {
    toggleModal()
    if(confirmClicked) { reloadCalendar() }
  }

  return appointmentData && (
    <Modal open={open} onClose={toggleModal} className='ConfirmAppointmentModal'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
                {confirmClicked && scheduleApptSuccessInfo ? t('rescheduleConfirmed') : t('appointmentReschedul')}
              </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={closeConfirmation} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
          <Header as='h3' className='HeaderText'>
            {t('donorDetails')}
          </Header>
          <p className='RegularText'>
            {`${appointmentData.name}`}
          </p>
          <Header as='h3' className='HeaderText'>
           {t('appointmentDate')} &amp; {t('time')}
          </Header>
          <p className='RegularText'>
            {`${moment.parseZone(appointmentData.rescheduledStartDateTime).format('ddd ll')} at ${appointmentData.rescheduledTime}`}
          </p>

          <Header as='h3' className='HeaderText'>{t('appointmentType')}</Header>
          <p className='RegularText'>{appointmentData.appointmentType === 'PHYSICAL' ? t('modalCommon:physicalWithDonation') : t('modalCommon:donation') }</p>
          {appointmentData && appointmentData.numberOfChildren > 0 && (
            <React.Fragment>
              <Header as='h3' className='HeaderText'>
               {t('playroomReserved')}
              </Header>
              <p className='RegularText'>{Children} {appointmentData.numberOfChildren}</p>
            </React.Fragment>
          )}
          <Header as='h3' className='HeaderText'>
            {appointmentData.city} {t('center')}
          </Header>
          <p className='RegularText'>{appointmentData?.addressLineOne}
            <br />
            {`${appointmentData?.stateCode} ${appointmentData?.zipcode}`}
            <br />
          </p>

          {confirmClicked && isScheduleApptLoading && <LoadingState/>}
          {confirmClicked && scheduleApptErrorInfo?.response?.data && (
            <FailureState message={scheduleApptErrorInfo.response.data.message}/>
          )}
          {confirmClicked && scheduleApptSuccessInfo ? (
            <div className='ModalActions'>
              <Grid.Row className='ConfirmContainer'>
                <PrimaryButton
                  text={t('close')}
                  width={270}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  onClick={closeConfirmation}
                />
              </Grid.Row>
            </div>
          ) : (
            <div className='ModalActions'>
              <Grid.Row className='ConfirmContainer'>
                <PrimaryButton
                  text={t('confirmAppointment')}
                  width={270}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  onClick={confirmAppointment}
                  disabled={confirmClicked && isScheduleApptLoading}
                />
              </Grid.Row>
              <Grid.Row>
                <PrimaryButton
                  text={t('cancel')}
                  width={150}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  onClick={closeConfirmation}
                />
              </Grid.Row>
            </div>
          )}
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ConfirmAppointmentModal
