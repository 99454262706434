import React from 'react'
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash'
import { Header, Container, Grid, Loader } from 'semantic-ui-react'
import { numberWithCommas } from '../../../Helpers/Utils'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const RewardsPoints = importer('Components/Dashboard/RewardsPoints')
const renderBalanceSection = (loading, wirecard, unavailableBalance) => {
  const { t } = useTranslation('DashboardTopBanner');
  if (loading) {
    return (
      <div className='SupHeaderLoading'>
        <Loader active />
      </div>
    )
  }

  if (unavailableBalance) {
    return (
      <Header as='h4' className='SupHeaderUnavailable'>
       {t('balanceUnavailable')}
      </Header>
    )
  }

  return (
    <Header as='h2' className='MainHeader'>
      $
      {!isEmpty(wirecard)
        ? numberWithCommas(wirecard.data.balance.balanceAvailable)
        : '-.--'}
    </Header>
  )
}

const DashboardTopBanner = props => {
  const { parentMenu, name, wirecard, unavailableBalance, loading } = props
  const { t } = useTranslation('DashboardTopBanner');
  return (
    <div className='DashboardTopBanner'>
      <Container fluid>
        <Grid columns={3}>
          <Grid.Column width={8}>
            <Header as='h4' className='SupHeader'>
              {parentMenu}
            </Header>
            <Header as='h1' className='MainHeader'>
              {t('welcome')} {name}
            </Header>
          </Grid.Column>
          <Grid.Column width={8} textAlign='right' className="rewardsPointsSection">
            <Grid>
            <RewardsPoints  />
          <div>
            <Header as='h4' className='SupHeader'>
               {t('debitCardBalance')}
            </Header>
            {renderBalanceSection(loading, wirecard, unavailableBalance)}
            </div>
            </Grid>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  )
}

export default DashboardTopBanner
