import React, { useState, useEffect } from 'react'
import { Grid, Container, Button, Header } from 'semantic-ui-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const HorizontalCalendarDayOfWeek = importer('Components/Dashboard/HorizontalCalendarDayOfWeek')

const getYear = (monday, sunday) =>
  moment(monday).year() === moment(sunday).year()
    ? moment(monday).format('YYYY')
    : `${moment(monday).format('YYYY')} / ${moment(sunday).format('YYYY')}`

const getMonth = (monday, sunday) =>
  moment(monday).month() === moment(sunday).month()
    ? moment(monday).format('MMMM')
    : `${moment(monday).format('MMMM')} / ${moment(sunday).format('MMMM')}`

const generateWeek = date => {
  const daysAfterMonday = moment(date).weekday() - 1
  const monday = moment(date).add(-daysAfterMonday, 'days')
  const weekDays = []
  for (var i = 0; i < 7; i++) {
    weekDays.push(moment(monday).add(i, 'days'))
  }
  const sunday = weekDays[6]
  const month = getMonth(monday, sunday)

  const year = getYear(monday, sunday)
  const week = { year, month, weekDays }

  return week
}

const formatMonthYear = (month, year) => {
  if (year.includes('/') && month.includes('/')) {
    const years = year.split(' / ')
    const months = month.split(' / ')
    return `${months[0]} ${years[0]} / ${months[1]} ${years[1]}`
  }
  return `${month} ${year}`
}

const OpsCalendar = ({
  date,
  onDateChange,
  setOpsCalendarSelectedDate,
  setTab,
  onTabChange
}) => {
  const {i18n} = useTranslation('common')
  const [selectedDate, setSelectedDate] = useState(date || window.sessionStorage.getItem("lastChoosenDate") || moment().format('YYYY-MM-DDT00:00:00'))
  const [currentDate, setCurrentDate] = useState(selectedDate)
  const [currentWeek, setCurrentWeek] = useState(generateWeek(selectedDate))

  useEffect(() => {
    setCurrentWeek(generateWeek(currentDate))
  }, [currentDate,i18n.language])

  useEffect(() => {
    if (onDateChange) {
      window.sessionStorage.setItem("lastChoosenDate",moment(selectedDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'))
      onDateChange(moment(selectedDate).startOf('day').format('YYYY-MM-DDTHH:mm:ss'))
    }
  }, [selectedDate])

  const calendarDateChangeHandler = (wd)=>{
    setTab && setTab('DONATION')
    onTabChange && onTabChange('DONATION')
    setOpsCalendarSelectedDate && setOpsCalendarSelectedDate(wd)
  }
  return (
    <div className='OpsCalendar'>
      <Header as='h2' className='MonthHeader'>
        {formatMonthYear(currentWeek.month, currentWeek.year)}
      </Header>
      <div className='CalendarSlider'>
        <Grid columns={3}>
          <Grid.Column computer={2} verticalAlign='middle'>
            <Button
              circular
              icon='chevron left'
              onClick={() =>
                setCurrentDate(moment(currentDate).add(-7, 'days'))}
            />
          </Grid.Column>
          <Grid.Column computer={12}>
            <Container>
              <Grid columns='equal'>
                {currentWeek.weekDays.map(wd => (
                  <Grid.Column key={wd.date()}>
                    <HorizontalCalendarDayOfWeek
                      dayOfWeek={moment(wd).format('ddd')}
                      dayOfMonth={moment(wd).format('DD')}
                      isSeledted={
                        moment(wd).format('YYYY-MM-DD') ===
                        moment(selectedDate).format('YYYY-MM-DD')
                      }
                      onClick={() => { setSelectedDate(wd) 
                        calendarDateChangeHandler(wd)
                      }}
                    />
                  </Grid.Column>
                ))}
              </Grid>
            </Container>
          </Grid.Column>
          <Grid.Column computer={2} verticalAlign='middle'>
            <Button
              circular
              icon='chevron right'
              onClick={() => setCurrentDate(moment(currentDate).add(7, 'days'))}
            />
          </Grid.Column>
        </Grid>
      </div>
    </div>
  )
}
export default OpsCalendar
