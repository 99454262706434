import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const HugeSelect = importer('Components/Inputs/HugeSelect')
const AppointmentHours = importer('Components/CenterOps/AppointmentHours')
const HorizontalCalendar = importer('Components/CenterOps/HorizontalCalendar')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const CenterOpsModifyAppointmentModal = ({
  open,
  toggleModal,
  initialSnapshot,
  handleAppointment,
  toggleCancelAppointmentModal,
  toggleConfirmRescheduleModal,
  onClickRescheduleAppointment,
  getSlotsForAppointmentType,
  centerSlots,
  centerSlotsFailure,
  getSlotsSuccessInfo,
  getSlotsFailureInfo,
  getSlotsLoading,
  appointmentDate,
  getAppSlotsHandler,
  reloadCalendar
}) => {
  const [localAppointmentData, setLocalAppointmentData] = useState(null)
  const { t} = useTranslation('CenterOpsModifyAppointmentModal','Children');

  useEffect(() => {
    if (open && initialSnapshot) {
      setLocalAppointmentData({ ...initialSnapshot })
    }
  }, [open])

  const getDay = strDate => {
    const dateArr = strDate.split(',')
    return dateArr[0]
  }
  const playroom = [
    { key: '0', value: 0, text: t('Children:childrenZero') },
    { key: '1', value: 1, text: t('Children:childrenOne') },
    { key: '2', value: 2, text: t('Children:childrenTwo') },
    { key: '3', value: 3, text: t('Children:childrenThree') },
    { key: '4', value: 4, text: t('Children:childrenFour') },
    { key: '5', value: 5, text: t('Children:childrenFive') },
    { key: '6', value: 6, text: t('Children:childrenSix') }
  ]
  const onSelectPlayroom = value => {
    setLocalAppointmentData(prevState => ({
      ...prevState,
      numberOfChildren: value
    }))
  }

  const [isHoursSelected, setIsHoursSelected] = useState(null)
  const dismissConfirmation = () => {
    setIsHoursSelected(false)
    toggleModal()
    reloadCalendar()
  }

  return localAppointmentData && (
    <Modal open={open} onClose={toggleModal} className='CenterOpsModifyAppointmentModal' size='large'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as='h2' className='ModalHeader'>
              <b>{t('modifying')} </b>
              {`${initialSnapshot.donationType ==='PHYSICAL' ? t('physical') :t('donation')} on ${initialSnapshot.weekDay}, ${getDay(initialSnapshot.date)} at ${initialSnapshot.time} for ${initialSnapshot.name}`}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={dismissConfirmation} />
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content>
        <div className='MyAppointment'>
          <div className="RescheduleHeader">
            <div className='Playroom'>
              <div className='PlayroomContainer'>
                <p className='LabelForSelect'>{t('supervisedPlayroom')}</p>
                <HugeSelect
                  options={playroom}
                  name='playroom'
                  className='HugeSelect '
                  handleOnChange={onSelectPlayroom}
                  defaultValue={initialSnapshot.numberOfChildren}
                  placeholder='Children'
                  disabled = {true}
                />
              </div>
            </div>
            <HorizontalCalendar
              appointmentDate={appointmentDate}
              getAppSlotsHandler={getAppSlotsHandler}
              appointment={localAppointmentData}
              handleAppointment={handleAppointment}
              setAppointment = {setLocalAppointmentData}
              edit
              setIsHoursSelected={setIsHoursSelected}
            />
            <div className='HoursContainer'>
              <AppointmentHours
                appointment={localAppointmentData}
                handleAppointment={handleAppointment}
                toggleModal={toggleModal}
                getSlotsForAppointmentType ={getSlotsForAppointmentType}
                centerSlots={centerSlots}
                centerSlotsFailure={centerSlotsFailure}
                getSlotsSuccessInfo={getSlotsSuccessInfo}
                getSlotsFailureInfo={getSlotsFailureInfo}
                setAppointment={setLocalAppointmentData}
                setIsHoursSelected={setIsHoursSelected}
              />
              {getSlotsLoading && <LoadingState/>}
              {getSlotsFailureInfo && getSlotsFailureInfo.message && (
                <FailureState message = {getSlotsFailureInfo.message} />
              )}
            </div>
          </div>
          <div className='ModalActions'>
            <Grid columns={2}>
              <Grid.Column textAlign='left'>
                <PrimaryButton
                  text={t('cancelAppointment')}
                  width={250}
                  height={50}
                  fontSize={16}
                  onClick={() => {
                    toggleModal()
                    toggleCancelAppointmentModal()
                  }}
                  className='CancelAppointment'
                />
              </Grid.Column>
              <Grid.Column textAlign='right'>
                <PrimaryButton
                  text={t('submitAppointmentCha')}
                  width={270}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  onClick={() => {
                    setIsHoursSelected(false)
                    toggleModal()
                    onClickRescheduleAppointment(localAppointmentData)
                    toggleConfirmRescheduleModal()
                  }}
                  disabled={getSlotsFailureInfo || !isHoursSelected }
                />
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default CenterOpsModifyAppointmentModal
