import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import { connect } from "react-redux";
import { Helmet } from 'react-helmet'
import { Grid, Image, Embed } from 'semantic-ui-react'
import ClockIcon from '../../../../assets/media/icons/icon-clock.svg'
import BackIcon from '../../../../assets/media/icons/back-icon.svg'
import importer from '@takedapdt/biolife-core/src/importer'
const getYoutubeId = importer('Helpers/YoutubeId')
import BlogCard from './BlogCard'
import { useTranslation } from 'react-i18next';

const BlogView = ({ blogs, blog, setFilteredBlog }) => {
  const [exploreMoreBlogs, setExploreMoreBlogs] = useState([])
  const videoId = getYoutubeId(blog?.video_url__c)
  const { t } = useTranslation('BlogView')

  useEffect(() => {
    const exploreBlogs = []
    const otherBlogs = blogs?.filter(item => item.slug__c != blog.slug__c)
    otherBlogs.map(item => {
      item.content_category__c.map(category => {
        blog.content_category__c.includes(category) ? exploreBlogs.push(item) : null
      })
    })
    if(exploreBlogs.length <= 2){
      exploreBlogs.push(...otherBlogs)
    }
    setExploreMoreBlogs([...new Set(exploreBlogs)])
    return () => {
      setExploreMoreBlogs([])
    }
  }, [window.location.pathname])

  return (
    <>
      <Helmet>
        <title>{blog.meta_title__c}</title>
        <meta name="description" content={blog.meta_description__c} />
      </Helmet>
      <div className='BlogViewContainer'>
          <NavLink to="/about-biolife/whats-new/blog" className='Link' onClick={() => setFilteredBlog([])}><Image src={BackIcon} width="20" height="20" />{t('backToHome')}</NavLink>
          <h1 className='BlogTitle'>{blog?.title__c}</h1>
          <p className='BlogStats'>{moment(blog?.publish_date__c).format('MMMM D')}, &nbsp; {moment(blog?.publish_date__c).format('YYYY')} &nbsp; | &nbsp; <Image src={ClockIcon} /> {blog?.expectedReadingTime} {t('min')}</p>
          {blog?.video_url__c ? 
            <Embed
              id={videoId}
              placeholder={blog?.cover_image_cover_video_english__c}
              source='youtube'
            /> : 
            <Image src={blog?.cover_image_cover_video_english__c} alt={blog?.cover_image_alt_text__c} className='BlogHeroImage' />
          }
          <div className='BlogContent' dangerouslySetInnerHTML={{ __html: blog?.content_internal__c}}/> 
      </div>

      {exploreMoreBlogs.length > 0 && 
        <>
          <h1 className='ExploreMoreHeading'>{t('exploreMoreArticles')}</h1>
          <Grid columns={3} stackable className='ExploreMoreBlogContainer'>
              {exploreMoreBlogs?.slice(0,3).map(blog => (
                <BlogCard blog={blog} />
              ))}
          </Grid>
        </>
      }
    </>
  )
}

const mapStateToProps = ({ publicContent }) => ({
  blogs: publicContent.getBlogsSuccess,
});

export default connect(mapStateToProps,null)(BlogView);