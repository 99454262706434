import React, { useState, useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const InputField = importer('Components/Forms/FormFields/InputField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const SelectField = importer('Components/Forms/FormFields/SelectField')

const EffectiveSlotsForm = ({
  defaultFromDate,
  defaultToDate,
  slotType,
  effectiveSlotsValue,
  slotConfigData,
  onApplyDateFilter,
  onApplyToSelected,
  isParentChecked,
  setIsSelectClicked
}) => {
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [isFilterEnabled, setIsFilterEnabled] = useState(false)
  const [isApplyEnabled, setIsApplyEnabled] = useState(false)

  const [effectiveSlots, setEffectiveSlots] = useState(null)
  const [dateOptions, setDateOptions] = useState([])
  const [toDateOptions, setToDateOptions] = useState([])
  const { t } = useTranslation('EffectiveSlotsForm');

  useEffect(() => {
    setFromDate(defaultFromDate)
    setToDate(defaultToDate)
  }, [defaultFromDate, defaultToDate])

  useEffect(() => {
    setToDateOptions(dateOptions.filter((item)=>{
      return item.value > fromDate
    }))
  }, [fromDate])

  useEffect(() => {
    setIsFilterEnabled(fromDate && toDate)
    setIsApplyEnabled((fromDate && toDate && effectiveSlots) || (isParentChecked && effectiveSlots))
  }, [fromDate, toDate, effectiveSlots])

  useEffect(() => {
    if (slotConfigData) {
      const options = []
      slotConfigData.forEach((item, index) => {
        options.push({
          key: index,
          value: item.appointmentSlotDateTime,
          text: item.appointmentSlotTime
        })
      })
      setDateOptions(options)
    }
  }, [slotConfigData])

  const onSubmit = values => {
  }

  const handleApplyDateFilter = () => {
    onApplyDateFilter(slotType, fromDate, toDate)
    setIsSelectClicked(true)
  }
  const handleApplyToChecked = (e) => {
    onApplyToSelected(slotType, effectiveSlots)
  }

  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <Form
          onSubmit={handleSubmit}
          className='EffectiveSlotsForm'
        >
          <Form.Group style={{ margin: 0 }}>
            <Field name='from_date'>
              {({ input, meta }) => (
                <div className='Field'>
                  <p className='FieldTitle'>{t('selectTime')}</p>
                  <SelectField
                    meta={meta}
                    input={input}
                    placeholder={t('select')}
                    options={dateOptions}
                    value={fromDate}
                    handleOnChange={(value) => {
                      setFromDate(value)
                      input.onChange(value)
                    }}
                  />
                </div>
              )}
            </Field>
            <p className='FieldSpacer'>{t('to')}</p>
            <Field name='to_date'>
              {({ input, meta }) => (
                <div className='Field'>
                  <p className='FieldTitle'>{t('selectTime')}</p>
                  <SelectField
                    meta={meta}
                    input={input}
                    selection
                    placeholder={t('select')}
                    options={toDateOptions}
                    value={toDate}
                    handleOnChange={(value) => {
                      setToDate(value)
                      input.onChange(value)
                    }}
                  />
                </div>
              )}
            </Field>
            <PrimaryButton
              text={t('select')}
              width={150}
              height={50}
              classType='Primary'
              fontSize={16}
              className='SelectButton'
              disabled={!isFilterEnabled}
              onClick={handleApplyDateFilter}
              minWidth={120}
            />
            <div className='FormDivider' />
            <Field name='end_date'>
              {({ input, meta }) => (
                <div className='Field'>
                  <p className='FieldTitle'>{t('effectiveSlots')}</p>
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    width={222}
                    className='SearchInput'
                    value={effectiveSlotsValue}
                    onChange={(e) => setEffectiveSlots(e.target.value)}
                  />
                </div>
              )}
            </Field>
            <PrimaryButton
              text={t('applyToSelected')}
              width={322}
              height={50}
              classType='Primary'
              fontSize={16}
              disabled={!isApplyEnabled}
              onClick={handleApplyToChecked}
              minWidth={145}
            />
          </Form.Group>
        </Form>
      )}
    />
  )
}

export default EffectiveSlotsForm
