import React from 'react'
import { useTranslation } from 'react-i18next';
import { Grid } from 'semantic-ui-react'
import './styles.scss'

const LastPhysicals = () => {
  const { t } = useTranslation('LastPhysicals');
  return (
    <div className='LastPhysicals'>
      <Grid verticalAlign='middle' columns={2}>
        <Grid.Column textAlign='left'>
          <p className='PhysicalHeader'>{t('LASTPHYSICAL')}</p>
          <p className='PhysicalText'>02/14/2020</p>
        </Grid.Column>
        <Grid.Column textAlign='right'>
          <p className='PhysicalHeader'>{t('NEXTPHYSICALDUE')}</p>
          <p className='PhysicalText'>11/14/2020</p>
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default LastPhysicals
