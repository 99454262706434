import React from 'react'
import { TextArea, Icon ,Image} from 'semantic-ui-react'
import infoIcon from '../../../../assets/media/images/info-icon.png'
import { useTranslation } from 'react-i18next';
import './styles.scss'


const PrimaryTextArea = ({
  as,
  onChange,
  onInput,
  rows,
  value,
  required,
  placeholder,
  height,
  name,
  error,
  fieldLabel,
  meta
}) => {
  const textAreaClasses = error ? 'Error' : ''
  const { t, i18n } = useTranslation('ValidationErrorMessages');
  return (
    <div>
      {fieldLabel && <small className={'FieldLabel'}>{fieldLabel}</small>}
      {fieldLabel && meta?.touched && meta.error && <small className={`FieldLabelError ${i18n.language}`}><div className="ErrorMsgContainer"><Image className='InfoIcon'  src={infoIcon} width="18" height="18"/> {<span className="ErrorMsg">{t('ValidationErrorMessages:'+meta.error)}</span>}</div></small>  }
    <div className='PrimaryTextArea'>
      {required && <Icon className='Required' size='tiny' name='asterisk' />}
      <TextArea
        className={textAreaClasses}
        as={as}
        onChange={onChange}
        onInput={onInput}
        rows={rows}
        value={value}
        placeholder={placeholder}
        style={{ height }}
        name={name}
        error={error}
      />
    </div>
    </div>
  )
}

export default PrimaryTextArea
