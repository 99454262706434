import React from 'react'
import { Form, Header } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const InputField = importer('Components/Forms/FormFields/InputField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const HorizontalDonorSearchForm = props => {
  const { t } = useTranslation('HorizontalDonorSearchForm');
  const { searchForDonorHandler } = props
  const onSubmit = values => {
    let emailTrim;
    if(values.email){
       emailTrim=values?.email.replace(/\s+/g,'')
    }
    searchForDonorHandler({
    email:emailTrim?emailTrim:values?.email,
    firstName:values?.firstName,
    lastName:values?.lastName,
    pdn:values?.pdn
  })
  }

  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine}) => (
        <Form onSubmit={handleSubmit} className='HorizontalDonorSearchForm'>
          <Header as='h4' className='FormHeader'>
            {t('donorSearch')}
          </Header>
          <Form.Group>
            <Field name='firstName'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                  width={365}
                  placeholder={t('firstName')}
                  topLabel={t('firstName')}
                  className='SearchInput'
                />
              )}
            </Field>
            <Field name='lastName'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                  width={365}
                  placeholder={t('lastName')}
                  topLabel={t('lastName')}
                  className='SearchInput'
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group>
            <Field name='pdn'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                  width={365}
                  placeholder={t('pdnRequired')}
                  topLabel={t('pdn')}
                  className='SearchInput'
                />
              )}
            </Field>
            <Field name='email'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                  width={365}
                  placeholder={t('email')}
                  topLabel={t('email')}
                  className='SearchInput'
                />
              )}
            </Field>
            <PrimaryButton
              text={t('find')}
              width={128}
              height={50}
              classType='Primary'
              disabled={pristine}
              className='FindDonor'
            />
          </Form.Group>
        </Form>
      )}
    />
  )
}

export default HorizontalDonorSearchForm
