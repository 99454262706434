import React, { Component } from 'react'
import { Loader, Message } from 'semantic-ui-react'
export default class index extends Component {
  render () {
    const { name } = this.props
    return (
      <Message>
        {name && <Message.Header>{name}</Message.Header>}
        <Loader active inline='centered' />
      </Message>
    )
  }
}
