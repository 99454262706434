/**
 *
 * @param {Number} timeout  In minutes
 * @param {Function} callback
 */
const inactivityTime = (
  timeout = 10,
  callback = () => null
) => {
  var time
  /* On Page load, reset */
  window.onload = resetTimer
  /* On dom events, reset */
  document.onmousemove = resetTimer
  document.onkeypress = resetTimer
  function resetTimer () {
    clearTimeout(time)
    time = setTimeout(callback, ((timeout * 60) * 1000))
  }
}

export default inactivityTime
