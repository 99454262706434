import React from 'react'
import { func, object, bool } from 'prop-types'
import { Form, Icon, Header, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { NavLink } from 'react-router-dom'
import validate from './validate'
import { useTranslation } from 'react-i18next';

import './styles.scss'

/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'

/* COMPONENTS */
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const InputField = importer('Components/Forms/FormFields/InputField')

const SocialLoginForm = ({
  handleSubmit,
  error,
  loading
}) => {
  const { t } = useTranslation('SocialLoginForm');
  const onSubmit = ({ email }) => {
    handleSubmit(email)
  }
  return (
    <div className="social-login-form page-container">
      <div className='social-login-form__content page-container__content'>
        <Header as='h1' className='MainHeader'>{t('userNameUpdate')}</Header>
        <div className="page-container_pageinfo">
          <p>
            {t('keepingInfoSafe')}
          </p>
          <p>
            {t('facebookLogin')}
          </p>
          <p className="mb-40">
           {t('googleLogin')}
          </p>
        </div>
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          initialValues={{ email: '' }}
          render={({
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Field name='email'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      className='login-with-email__textinput'
                      placeholder={t('userName')}
                      topLabel={t('userLabel')}
                      value={null}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <div className='MessageButtonContainer'>
                  <p className='FormNote'>
                    <sup>
                      <Icon
                        className='RequiredInfo'
                        size='tiny'
                        name='asterisk'
                      />
                    </sup>
                      {t('requiredField')}
                  </p>
                </div>
              </Form.Group>

              {loading && <LoadingState />}
              {error && error.response && error.response.data && (
                <p>
                  {error.response.data.code === 'DonorNotFound' && (
                    <Message warning visible>
                      <p>
                        {t('accountNotFound')}
                      </p>
                    </Message>
                  )}
                  {error.response.data.code !== 'DonorNotFound' && (
                    <FailureState message={error.response.data.message} />
                  )}
                </p>
              )}

              <Form.Group className='ButtonContainer'>
                <PrimaryButton
                  text={t('submit')}
                  width={340}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  type='submit'
                />
              </Form.Group>
              <Form.Group className='ButtonContainer'>
                <PrimaryButton
                  text={t('cancel')}
                  width={340}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  as={NavLink}
                  to='/login'
                />
              </Form.Group>
            </Form>
          )}
        />
      </div>
    </div>
  )
}

SocialLoginForm.defaultProps = {
  error: undefined,
  showLoader: false
}

SocialLoginForm.propTypes = {
  handleSubmit: func.isRequired,
  error: object,
  showLoader: bool
}

export default SocialLoginForm
