import React from 'react'
import { Grid, Header, Container } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import './styles.scss'
import { useTranslation } from 'react-i18next';

import importer from '@takedapdt/biolife-core/src/importer'

const HeroSearchForm = importer('Components/Forms/HeroSearchForm')
const Breadcrumbs = importer('Components/Breadcrumbs')

const HeroLocations = (props) => {
  const { parentMenu, locationState, centers, dynamicArticleLeft, dynamicArticleRight } = props
  const { t } = useTranslation('HeroLocations');

  return (
    <div className={locationState ? 'HeroLocationsState' : 'HeroLocations'}>
      {!isEmpty(centers) && locationState && (
        <Container fluid>
          <Breadcrumbs page={centers[0].state} parentMenu={parentMenu} color='blue' />
        </Container>
      )}
      <Container>
        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column computer={10} mobile={16}>
              <div className={`HeroTextContainer ${isEmpty(centers) ? 'NoSearchFound' : ''} `}>
                <i className='map marker alternate icon huge markerIcon' />
                <div dangerouslySetInnerHTML={{ __html: dynamicArticleLeft?.content__c }} />
              </div>
            </Grid.Column>
            <Grid.Column computer={6} mobile={16}>
              <div className='HeroTextFormContainer'>
              <HeroSearchForm locationState={locationState} />
                {!isEmpty(centers) && locationState && (
                  <>
                    <p className='HeroRegularText'>
                      {t('biolifeIsGrowing')}
                    </p>
                  </>
                )}
                {!locationState && <div dangerouslySetInnerHTML={{ __html: dynamicArticleRight?.content__c }} />}

                {isEmpty(centers) && locationState && (
                  <p className='HeroRegularText'>{t('noCentersFound')}</p>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
      {!isEmpty(centers) && locationState && (
        <Container>
          <Grid className='SearchData'>
            <Grid.Row columns={2} >
              <Grid.Column mobile={6} computer={8} tablet={8}>
                <Header as='h2' className='SelectedState'>
                  { isNaN(locationState) ? centers[0].state : locationState}                 
                </Header>
              </Grid.Column>
              <Grid.Column textAlign='right' mobile={10} computer={8} tablet={8}>
                <p className='HeroRegularText NoPadding'>
                  {centers.length} {t('locationsFound')}{ isNaN(locationState) ? centers[0].state : locationState}  
                </p>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )}
    </div>
  )
}

export default HeroLocations
