import React, { useState, useEffect } from 'react'
import { Form, Message, Loader, Dimmer, Visibility, Icon } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import validate from './validate'
import { useTranslation } from 'react-i18next';
import './styles.scss';
import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from 'react-redux'
import { CenterOpsUserRole } from '../../../../Constant/centerOps_enums';

const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const centerActions = importer('Stores/Centers/Actions')



const EditAccessManagementForm = ({
    editApplicationUser,
    editAccountUserLoading,
    editAccountUserFailure,
    editAccountUserSuccess,
    selectedUser,
    closeEditModal,
    toggleSuccessModal }) => {
    const { t } = useTranslation('EditAccessManagementForm');
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState('');
    const [submited, setSubmited] = useState(false)
    const roleOptions = [
      { key: CenterOpsUserRole.STAFF, text: CenterOpsUserRole.STAFF, value: CenterOpsUserRole.STAFF, options: CenterOpsUserRole.STAFF },
      { key: CenterOpsUserRole.MANAGER, text: CenterOpsUserRole.MANAGER, value: CenterOpsUserRole.MANAGER, options: CenterOpsUserRole.MANAGER},
    ];

      useEffect(() => {
        if (editAccountUserSuccess !== null && submited) {
          setLoading(false);
          toggleSuccessModal(true,"EditUserDetails");
          closeEditModal();
        }
      }, [editAccountUserSuccess])

      useEffect(() => {
        if (editAccountUserFailure !== null && submited) {
          setSubmited(false)
          setLoading(false)
          setErrorMessages(editAccountUserFailure ? editAccountUserFailure.message : t('somethingWentWrong'))
        }
      }, [editAccountUserFailure])

      const onSubmit = values => {
        setLoading(true);
        setSubmited(true);
        const request = {
          name: values.name,
          role: values.role,
          userId: selectedUser.userId,
          loginId: values.loginId,
          donationCenter: selectedUser.defaultCenter
        };
        editApplicationUser(request);
      };

      const onCancel = () => {
        closeEditModal();
      }
    
    // This function will called only once
    useEffect(() => {
      console.log(selectedUser);
    }, [])

    return (
        <Visibility>
        <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      initialValues={selectedUser}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className='EditAccessManagementForm'>
            <Form.Group>
            <Field name='name'>
                {({ input, meta }) => (
                <InputField
                    meta={meta}
                    {...input}
                    required
                    placeholder={t('placeholderName')}
                    fieldLabel={t('name')}
                    className='EditInput'
                    //value={selectedUser.name}
                    icon={
                        <Icon className='Required' size='tiny' name='asterisk' />
                      }
                />
                )}
            </Field>
            <Field name='role'>
                {({ input, meta }) => (
                <SelectField
                    meta={meta}
                    input={input}
                    required
                    placeholder={t('placeholderdefaultRole')}
                    fieldLabel={t('role')}
                    options={roleOptions}
                    className='CreateFormSelect'
                    defaultValue={selectedUser.role}
                    icon={
                        <Icon className='Required' size='tiny' name='asterisk' />
                      }
                />
                )}
            </Field>
            </Form.Group>
            <Form.Group className="rowSpaces">
            <Field name='defaultCenter'>
                {({ input, meta }) => (
                <InputField
                    meta={meta}
                    {...input}
                    required
                    placeholder={t('placeholderdefaultCenter')}
                    fieldLabel={t('defaultCenter')}
                    className='EditInput'
                    disabled={true}
                    icon={
                        <Icon className='Required' size='tiny' name='asterisk' />
                      }
                />
                )}
            </Field>
            <Field name='loginId'>
                {({ input, meta }) => (
                <InputField
                    meta={meta}
                    {...input}
                    required
                    placeholder={t('placeholderEmail')}
                    fieldLabel={t('email')}
                    className='EditInput'
                    disabled={true}
                    //value={selectedUser.loginId}
                    icon={
                        <Icon className='Required' size='tiny' name='asterisk' />
                      }
                />
                )}
            </Field>
            </Form.Group>
            {errorMessages && (
          <Message warning className='errorMessage'>
            <p>{Array.isArray(errorMessages) ? errorMessages.join(', ') : errorMessages}</p>
          </Message>
        )}
            <Form.Group className='submitBtnGroup'>
            <PrimaryButton
                text={t('cancelButton')}
                classType='Inverted'
                className='cancel'
                onClick={onCancel}
                />
                <PrimaryButton
                text={t('saveButton')}
                classType='Primary'
                className='save'  
                />
            </Form.Group>
            </Form>
      )}
    />
    {(loading && editAccountUserLoading) && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
    </Visibility>
    )
}

const mapStateToProps = (state) => ({
    editAccountUserLoading: state.centers.editApplicationUserLoading,
    editAccountUserSuccess: state.centers.editApplicationUserSuccess,
    editAccountUserFailure: state.centers.editApplicationUserFailure,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    editApplicationUser: (applicationUser) => dispatch(centerActions.editApplicationUser(applicationUser)),
  })

export default connect(
    mapStateToProps, mapDispatchToProps
) (EditAccessManagementForm)