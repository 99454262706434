import React, { useEffect, useState} from 'react'
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import moment from 'moment'
import TagManager from 'react-gtm-module'
import { splitCenterName } from "../../../Helpers/Utils"
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
// Actions
const appointmentsActions = importer('Stores/Appointments/Actions')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const {isMobileWeb} = importer('Helpers/Utils', null)


const ConfirmAppointmentModal = ({
  open,
  toggleModal,
  appointment,
  createNewAppointment,
  donor,
  center,
  update,
  toggleAddToCalendarModal,
  rescheduleAppointment,
  rescheduleAppointmentSuccess,
  rescheduleAppointmentLoading,
  rescheduleAppointmentFailure,
  createNewAppointmentLoading,
  createNewAppointmentSuccess,
  createNewAppointmentFailure,
  loggedInUser
}) => {
  const [confirmClicked, setConfirmClicked] = useState(false)
  const { t } = useTranslation('DashBoardConfirmAppointmentModal');
  
  const createdFrom = loggedInUser?.role === 'Donor' ? isMobileWeb() ? 'MOBILE_WEB' : 'WEB' : 'CENTER_OPS'
  const confirmAppointment = () => {
    setConfirmClicked(true)
    const finalAppointmentData = {
      startDateTime: appointment.startDateTime,
      centerSfid: appointment.centerSfid,
      appointmentType: appointment.appointmentType,
      donorSfid: donor.sfid,
      numberOfChildren: appointment.numberOfChildren || 0,
      createdFrom
    }
    const finalRescheduleAppointmentData = {
      rescheduledStartDateTime: appointment.startDateTime,
      donationCenter: appointment.centerSfid,
      donationType: appointment.appointmentType,
      donorSfid: donor.sfid,
      numberOfChildren: appointment.numberOfChildren || 0,
      createdFrom
    }
    if (!update) {
      createNewAppointment(finalAppointmentData)
    } else {
      rescheduleAppointment(appointment.appointmentId, finalRescheduleAppointmentData)
    }
  }

  useEffect(() => {
    if (open) {setConfirmClicked(false)}
  }, [open])

  useEffect(() => {
    if (!confirmClicked) { return }
    if (update && rescheduleAppointmentSuccess) {
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: 'appointment',
      //     appointmentStage: 'rescheduled',
      //     appointmentId: rescheduleAppointmentSuccess.id
      //   }
      // })
      toggleAddToCalendarModal && toggleAddToCalendarModal()
      toggleModal()
    } else if (createNewAppointmentSuccess) {
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: 'appointment',
      //     appointmentStage: 'scheduled',
      //     appointmentId: createNewAppointmentSuccess.id
      //   }
      // })
      toggleAddToCalendarModal && toggleAddToCalendarModal()
      toggleModal()
    }
  }, [rescheduleAppointmentSuccess, createNewAppointmentSuccess])

  return (
    <Modal open={open} onClose={toggleModal} className='ConfirmAppointmentModal'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as='h2' className='ModalHeader'>
              {t('appointmentConfirmation')}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={toggleModal} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
          <Header as='h3' className='HeaderText'>
            {t('appointmentDate')} &amp; {t('time')}
          </Header>
          <p className='RegularText'>
            {`${moment.parseZone(appointment.startDateTime).format('ddd')}, ${moment.parseZone(appointment.startDateTime).format('ll')}
              at ${appointment.startTime && appointment.startTime.toLowerCase()}`}
          </p>
          <Header as='h3' className='HeaderText'>
            {t('appointmentType')}
          </Header>
          <p className='RegularText'>{appointment.appointmentType === 'PHYSICAL' ? t('physicalwithDonation') : t('donation') }</p>

          {appointment && appointment.numberOfChildren > 0 &&
            <React.Fragment>
              <Header as='h3' className='HeaderText'>
                {t('playroomReserved')}
              </Header>
              <p className='RegularText'>{t('children')} {appointment.numberOfChildren}</p>
            </React.Fragment>
          }

          <Header as='h3' className='HeaderText'>
            {center && loggedInUser?.role === 'Donor' ? splitCenterName(center?.name) : center?.name} {t('center')}
          </Header>
          {center && (
            <p className='RegularText'>
              {center.addressLine1}
              <br />
              {loggedInUser?.role === 'Donor' && `${splitCenterName(center?.city)}, ${center.stateCode} ${center.zipcode}`}
              {loggedInUser?.role === 'Admin' && `${center?.city}, ${center.stateCode} ${center.zipcode}`}
            </p>
          )}
          {confirmClicked && (
            <div>
              <br/>
              {update && (
                <div>
                  {rescheduleAppointmentLoading && <LoadingState/>}
                  {rescheduleAppointmentFailure && <FailureState message = {rescheduleAppointmentFailure.response.data.message}/>}
                </div>
              )}
              {!update && (
                <div>
                  {createNewAppointmentLoading && <LoadingState/>}
                  {createNewAppointmentFailure && <FailureState message = {createNewAppointmentFailure.response.data.message}/>}
                </div>
              )}
            </div>
          )}
         <div className='ModalActions'>
            <Grid.Row className='ConfirmContainer'>
              <PrimaryButton
                text={t('confirmAppointment')}
                width={270}
                height={50}
                fontSize={16}
                classType='Primary'
                onClick={confirmAppointment}
                disabled={confirmClicked && (update ? rescheduleAppointmentLoading : createNewAppointmentLoading)}
              />
            </Grid.Row>
            <Grid.Row>
              <PrimaryButton
                text={t('cancel')}
                width={150}
                height={50}
                fontSize={16}
                classType='Inverted'
                onClick={toggleModal}
              />
            </Grid.Row>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  rescheduleAppointmentFailure: state.appointments.rescheduleAppointmentFailure,
  rescheduleAppointmentLoading: state.appointments.rescheduleAppointmentLoading,
  rescheduleAppointmentSuccess: state.appointments.rescheduleAppointmentSuccess?.data,
  loggedInUser: state.users?.user
})

const mapDispatchToProps = (dispatch) => ({
  rescheduleAppointment: (id, appointment) => dispatch(appointmentsActions.rescheduleAppointment(id, appointment))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmAppointmentModal)
