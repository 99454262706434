import React from 'react'
import { useTranslation } from 'react-i18next';
import { Form,Image } from 'semantic-ui-react'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
import infoIcon from '../../../../../assets/media/images/info-icon.png'
const PrimaryMaskedInput = importer('Components/Inputs/PrimaryMaskedInput')

const MaskedField = (props) => {
  const {
    meta,
    input,
    required,
    height,
    placeholder,
    className,
    width,
    icon,
    type,
    onChange,
    customOnChange,
    topLabel,
    value,
    mask,
    maskPlaceholder,
    disabled,
    onBlur,
    fieldLabel
  } = props
  const { t } = useTranslation('ValidationErrorMessages');
  const message = meta.touched && meta.error ? meta.error : topLabel
  const hasErrorsClass = meta.touched && meta.error ? 'error' : ''
  return (
    <Form.Field required={required} className='FormField'>
      {fieldLabel && <small className={'TopLabel'}>{fieldLabel}</small>} 
      {message && (
        <small className={meta.touched && meta.error ? 'TopLabelError' : 'TopLabel'}>
            {fieldLabel ? <div className="ErrorMsgContainer"><Image className='InfoIcon'  src={infoIcon} width="18" height="18"/> <span className="ErrorMsg" >{t(message)}</span></div> : <span>{t(message)}</span> }
        </small>
      )}
      <PrimaryMaskedInput
        height={height}
        placeholder={placeholder}
        className={className}
        width={width}
        {...input}
        value={value}
        error={!!(meta.touched && meta.error)}
        required={required}
        icon={icon}
        type={type}
        onChange={(e) => {
          onChange(e)
          customOnChange && customOnChange(e)
        }}
        onBlur={(e) => { onBlur(e) }}
        mask={mask}
        maskPlaceholder={maskPlaceholder}
        disabled={disabled}
        hasErrorsClass={hasErrorsClass}
      />
    </Form.Field>
  )
}

export default MaskedField
