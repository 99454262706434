import React, { useState, useEffect ,useRef } from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import moment from 'moment'
import importer from '@takedapdt/biolife-core/src/importer'
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash'
import TagManager from 'react-gtm-module';
const HeroAppointmentScheduling = importer('Components/Dashboard/HeroAppointmentScheduling')
const AppointmentTypeAndPlayroom = importer('Components/Dashboard/AppointmentTypeAndPlayroom')
const DateTimePicker = importer('Components/Dashboard/DateTimePicker')
const HorizontalCalendar = importer('Components/Dashboard/HorizontalCalendar')
const AlternativeCenters = importer('Components/Dashboard/AlternativeCenters')
const ConfirmAppointmentModal = importer('Components/Dashboard/ConfirmAppointmentModal')
const AddToCalendarModal = importer('Components/Dashboard/AddToCalendarModal')

const {isMobileWeb} = importer('Helpers/Utils', null)
const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { pageMetaArticles } = importer('Helpers/menuBuilder')
// Actions
const donorsActions = importer('Stores/Donors/Actions')
const centersActions = importer('Stores/Centers/Actions')
const appointmentsActions = importer('Stores/Appointments/Actions')

const AppContainer = importer('Containers/App')

const Footer = importer('Components/Footer')

const AppointmentScheduling = ({
  donor,
  lastPhysicalDate,
  inquiry,
  getSlotsForAppointmentType,
  centerSlots,
  createNewAppointment,
  centers,
  center,
  getAllCenters,
  centerSlotsFailure,
  centerSlotsLoading,
  createNewAppointmentFailure,
  createNewAppointmentSuccess,
  createNewAppointmentLoading,
  getDonorCentersAll,
  apptCenter,
  loggedInUser,
  getZipcodeSearchCenters,
  searchedCenters,
  webArticles
}) => {
  const appointmetSuccessStateRef = useRef('');
  appointmetSuccessStateRef.current=createNewAppointmentSuccess
  const { t } = useTranslation('AppointmentScheduling');
  const [appointment, setAppointment] = useState({ appointmentType: donor.pdn ? inquiry?.defaultAppointmentType=="PHYSICALONLY"?"PHYSICAL":inquiry?.defaultAppointmentType : 'PHYSICAL', startDateTime: moment().format('YYYY-MM-DDT00:00:00') })

  const [openModal, setOpenModal] = useState(false)
  const [openAddToCalendarModal, setOpenAddToCalendarModal] = useState(false)

  const toggleModal = () => setOpenModal(!openModal)
  const toggleAddToCalendarModal = () => setOpenAddToCalendarModal(!openAddToCalendarModal)
  const handleAppointment = (app) => {
    sessionStorage.removeItem('AVAILABLE_SLOTS_API_CALLED')
    setAppointment({ ...app })
   }

  const getAllAvailableSlotsPerDay = async (day, centerSfid, appointmentType, playroom = 0, donorSfid) => {
    const newDate = day ? moment(day).format('YYYY-MM-DDT00:00:00') : moment().format('YYYY-MM-DDT00:00:00')
    await getSlotsForAppointmentType(newDate, null, null, playroom, centerSfid, appointmentType, null, donorSfid)
  }

  useEffect(() => {
    if (donor && !appointment.startTime && appointment.appointmentType && appointment.centerSfid && sessionStorage.getItem('AVAILABLE_SLOTS_API_CALLED') != 'true') {
      getAllAvailableSlotsPerDay(
        appointment.startDateTime,
        appointment.centerSfid || donor.donationCenter,
        appointment.appointmentType,
        appointment.numberOfChildren,
        donor.sfid
      )
      getAllCenters(appointment?.centerSfid || donor.donationCenter)
      sessionStorage.setItem('AVAILABLE_SLOTS_API_CALLED', 'true')
     }
  }, [appointment])

  useEffect(() => {
     if(appointment?.appointmentType!=='DONATION')
     getZipcodeSearchCenters(donor?.zipCode,true,'disdate', donor?.donationCenter, true)    // true corresponds to addNewDonorBonusInformation
  }, [appointment.appointmentType])

  useEffect(() => {
    getDonorCentersAll(null, true, 'disDate', true)     // true corresponds to addNewDonorBonusInformation
    getAllCenters(donor?.donationCenter)
    getZipcodeSearchCenters(donor?.zipCode,true,'disdate',donor?.donationCenter, true)
    sessionStorage.removeItem('AVAILABLE_SLOTS_API_CALLED')
    return () => {
      sessionStorage.removeItem('AVAILABLE_SLOTS_API_CALLED')
      // if(!appointmetSuccessStateRef.current){
      //    TagManager.dataLayer({
      //     dataLayer: {
      //       event: 'appointmentAbandon'
      //       }
      //      })
      //    }
     }; 
  }, [])

  const allowCenterEdit = () => {
    return center ? center.isDIS8Center : false
  }
  const AppoinmnetSchedulingsMeta =  ArticleByIdentifier(
    webArticles,
    pageMetaArticles.APPOINTMENT_SCHEDULING_META
  )
  const scrolltoCalendar = () => {
    if(isMobileWeb()){
      const HorizontalCalendarElement = document.querySelector(".HorizontalCalendar")
       if(HorizontalCalendarElement){
         HorizontalCalendarElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  return (
    <AppContainer compact title={`${AppoinmnetSchedulingsMeta?.meta_title__c}`}>
      {!isEmpty(AppoinmnetSchedulingsMeta) &&
        <Helmet>
        <title>{`${AppoinmnetSchedulingsMeta?.meta_title__c}`} </title>
        <meta name="description" content= {`${AppoinmnetSchedulingsMeta?.meta_description__c}`} />
      </Helmet>}
      <HeroAppointmentScheduling loggedInUser={loggedInUser}/>
      <AppointmentTypeAndPlayroom donor={donor} defaultAppointmentType={inquiry?.defaultAppointmentType || ''} scrolltoCalendar={scrolltoCalendar} appointment={appointment} handleAppointment={handleAppointment} edit={!donor?.pdn} centerSlotsFailure={centerSlotsFailure} centerSlotsLoading={centerSlotsLoading} centerSlots={centerSlots} inquiry={inquiry}/>
      <HorizontalCalendar
        appointment={appointment}
        handleAppointment={handleAppointment}
        centers={appointment.appointmentType==='DONATION'?centers:searchedCenters}
        donor={donor}
        setAppointment={setAppointment}
        center={center}
        edit={allowCenterEdit}
        centerSlotsFailure={centerSlotsFailure}
      />
      {centerSlotsFailure?.code === 'CenterIsClosedPotentialAppointmentDateRule' && 
        <AlternativeCenters
          loggedInUser={loggedInUser}
          donor={donor}
          appointment={appointment}
          apptCenter={apptCenter}
          handleAppointment={handleAppointment}
          center={center}
        />
      }
      <DateTimePicker
        appointment={appointment}
        handleAppointment={handleAppointment}
        toggleModal={toggleModal}
        availableSlots={centerSlots || []}
        centerSlotsFailure={centerSlotsFailure}
        centerSlotsLoading={centerSlotsLoading}
      />
      <ConfirmAppointmentModal
        open={openModal}
        toggleModal={toggleModal}
        appointment={appointment}
        createNewAppointment={createNewAppointment}
        donor={donor}
        center={center}
        createNewAppointmentFailure={createNewAppointmentFailure}
        toggleAddToCalendarModal={toggleAddToCalendarModal}
        createNewAppointmentSuccess={createNewAppointmentSuccess}
        createNewAppointmentLoading={createNewAppointmentLoading}
      />
      <AddToCalendarModal
        open={openAddToCalendarModal}
        toggleModal={toggleAddToCalendarModal}
        appointment={appointment}
        createNewAppointment={createNewAppointment}
        donor={donor}
        center={center}
        centers={appointment.appointmentType==='DONATION'?centers:searchedCenters}
        createNewAppointmentSuccess={createNewAppointmentSuccess}
        loggedInUser={loggedInUser}
        createNewAppointmentFailure={createNewAppointmentFailure}
        lastPhysicalDate={lastPhysicalDate}
        pdn={donor?.pdn}
      />

      <Footer />
    </AppContainer>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  apptCenter: state?.centers?.getAppointmentCenterSuccess?.data,
  lastPhysicalDate: state.donors?.inquiryDonorSuccess?.data?.lastPhysicalDate,
  centerSlots: state.centers?.getSlotsForAppointmentTypeSuccess?.data,
  centerSlotsFailure: state?.centers?.getSlotsForAppointmentTypeFailure,
  centerSlotsLoading: state.centers.getSlotsForAppointmentTypeLoading,
  centers: state.centers.donorCenters?.data,
  center: state.centers.centers?.data,
  createNewAppointmentFailure: state.appointments.createNewAppointmentFailure,
  createNewAppointmentLoading: state.appointments.createNewAppointmentLoading,
  createNewAppointmentSuccess: state.appointments.createNewAppointmentSuccess?.data,
  inquiry: state.donors.inquiryDonorSuccess?.data,
  loggedInUser: state.users?.user,
  searchedCenters: state.centers.getZipcodeSearchCentersSuccess?.data,
  webArticles: state.publicContent.WebArticles?.completeArticles
})

const mapDispatchToProps = (dispatch) => ({
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  getSlotsForAppointmentType: (
    forDate,
    fromDateTime,
    toDateTime,
    numberOfChildren,
    centerSfid,
    appointmentType,
    appointmentId,
    donorSfid
  ) =>
    dispatch(
      centersActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        appointmentId,
        donorSfid
      )
    ),
  createNewAppointment: (appointment) => dispatch(appointmentsActions.createNewAppointment(appointment)),
  getDonorCentersAll: (searchStr, isDIS8Center, searchType, addNewDonorBonusInformation) => dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType, addNewDonorBonusInformation)),
  getAllCenters: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  getZipcodeSearchCenters: (searchStr, isDIS8Center, searchType, defaultCenter, addNewDonorBonusInformation) => dispatch(centersActions.getZipcodeSearchCenters(searchStr, isDIS8Center, searchType, defaultCenter, addNewDonorBonusInformation))
})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentScheduling)
