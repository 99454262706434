import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Container } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss';

const CreateAccessManagementHeader = importer('Components/CenterOps/AccessManagement/CreateAccessManagementHeader')
const CreateAccessManagementForm = importer('Components/Forms/AccessManagementForms/CreateAccessManagementForm')

const CreateAccessManagement = () => {
  const { t } = useTranslation('CreateAccessManagement');
  const pageName=t('pageHeader');
  return (
        <Container fluid className='AccessManagementCreate'>
          <CreateAccessManagementHeader/>
          <CreateAccessManagementForm/>
        </Container>
  )
}

export default CreateAccessManagement