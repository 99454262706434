import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import './styles.scss'

const DonorDetailTabs = ({
  tab,
  setTab,

}) => {
  const { t } = useTranslation('DonorDetailTabs');
  return (
    <ul className='DonorDetailTabs'>
      <div style={{ display: 'flex' }}>
        <li
          className={tab === 'PROFILE' ? 'tabs active' : 'tabs'}
          onClick={() => {
            setTab('PROFILE')
          }}
        >
          {t('donorProfile')}
        </li>
        <li
          className={tab === 'ACCOUNT' ? 'tabs active' : 'tabs'}
          onClick={() => {
            setTab('ACCOUNT')
          }}
        >
              {t('donorAccountSetting')}
        </li>

      </div>

    </ul>
  )
}

export default DonorDetailTabs
