export const donationCenterOfficeHours = [
  {
    id: 1,
    day: 'sunday',
    time: 'Closed'
  },
  {
    id: 2,
    day: 'monday',
    time: 'Closed'
  },
  {
    id: 3,
    day: 'tuesday',
    time: '7:00 AM - 7:00 PM'
  },
  {
    id: 4,
    day: 'wednesday',
    time: '7:00 AM - 7:00 PM'
  },
  {
    id: 5,
    day: 'thursday',
    time: '7:00 AM - 7:00 PM'
  },
  {
    id: 6,
    day: 'friday',
    time: '7:00 AM - 5:00 PM'
  },
  {
    id: 7,
    day: 'saturday',
    time: '7:00 AM - 4:00 PM'
  }
]
export const donationCenterPlayroom = [
  {
    id: 1,
    day: 'sunday',
    time: 'Closed'
  },
  {
    id: 2,
    day: 'monday',
    time: 'Closed'
  },
  {
    id: 3,
    day: 'tuesday',
    time: 'Closed'
  },
  {
    id: 4,
    day: 'wednesday',
    time: 'Closed'
  },
  {
    id: 5,
    day: 'thursday',
    time: 'Closed'
  },
  {
    id: 6,
    day: 'friday',
    time: 'Closed'
  },
  {
    id: 7,
    day: 'saturday',
    time: 'Closed'
  }
]
