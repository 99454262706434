import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Header } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './styles.scss';

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const AccessManagementHeader = () => {
    const { t } = useTranslation('AccessManagementHeader');
    const pageName=t('pageHeader');
    return (
      <div className='AccessManagementHeader'>
        <div className='header'>
            <Header as='h4' className='FormHeader'>
            {pageName}
            </Header>
            <PrimaryButton
                text={t('createButton')}
                as={NavLink}
                to='/center-ops/create-access-management'
            />
        </div>
      </div>
    )
  }
  
  export default AccessManagementHeader