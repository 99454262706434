import React from 'react'
import { NavLink } from 'react-router-dom'
import { Breadcrumb } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next';
import './styles.scss'

const Breadcrumbs = (props) => {
  const { parentMenu, page, color, parentSubMenu } = props
  const { t} = useTranslation('breadCrumbs');
  return (
    <Breadcrumb className='Breadcrumbs'>
      <Breadcrumb.Section
        className={color === 'blue' ? 'LightBlueParentLink' : 'Homelink'}
        as={NavLink}
        to='/'
      >
        {t('home')}
      </Breadcrumb.Section>
      <Breadcrumb.Divider className={color === 'blue' ? 'BlueDivide' : 'Divider'} />
      {parentMenu.to ? (
        <Breadcrumb.Section
          className={color === 'blue' ? 'LightBlueParentLink' : 'Homelink'}
          as={NavLink}
          to={parentMenu.to}
        >
          {parentMenu.name}
        </Breadcrumb.Section>
      ) : (
        <Breadcrumb.Section text>{parentMenu}</Breadcrumb.Section>
      )}

      <Breadcrumb.Divider className={color === 'blue' ? 'BlueDivide' : 'Divider'} />
      {!isEmpty(parentSubMenu) && (
        <>
          <Breadcrumb.Section
            className={color === 'blue' ? 'LightBlueParentLink' : 'Homelink'}
            as={NavLink}
            to={`/${parentMenu.name}/${parentSubMenu}`}
          >
            {parentSubMenu}
          </Breadcrumb.Section>
          <Breadcrumb.Divider className={color === 'blue' ? 'BlueDivide' : 'Divider'} />
        </>
      )}

      <Breadcrumb.Section active className={color === 'blue' ? 'DarkBlueCurrentPage' : 'CurrentPage'}>
        {page}
      </Breadcrumb.Section>
    </Breadcrumb>
  )
}

export default Breadcrumbs
