import React from 'react'
import { Input, Icon } from 'semantic-ui-react'
import './styles.scss'

const PrimaryInput = (props) => {
  const {
    width,
    height,
    action,
    actionPosition,
    as,
    children,
    className,
    disabled,
    error,
    fluid,
    focus,
    icon,
    iconPosition,
    inverted,
    label,
    labelPosition,
    onChange,
    size,
    transparent,
    type,
    placeholder,
    required,
    value,
    onBlur,
    name,
    defaultValue,
    autoComplete
  } = props

  return (
    <div className={className}>
      {required && className === 'PrimaryInput' && <Icon className='Required' size='tiny' name='asterisk' />}
      <Input
        action={action}
        actionPosition={actionPosition}
        as={as}
        disabled={disabled}
        error={error}
        fluid={fluid}
        focus={focus}
        icon={icon}
        iconPosition={iconPosition}
        inverted={inverted}
        label={label}
        labelPosition={labelPosition}
        onChange={onChange}
        size={size}
        transparent={transparent}
        type={type}
        placeholder={placeholder}
        style={{ width: width, height: height }}
        name={name}
        value={value}
        defaultValue={defaultValue}
        onBlur={onBlur}
        autoCapitalize="off"
        autoComplete={autoComplete}
      >
        {children}
      </Input>
    </div>
  )
}

PrimaryInput.defaultProps = {
  className: 'PrimaryInput'
}
export default PrimaryInput
