import React, { Component } from 'react'
import { Container, Accordion as AccordionSemantic, Icon } from 'semantic-ui-react'
import './styles.scss'

class Accordion extends Component {
  state = {
    activeIndex: 0,
    data: this.props.data
  }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  render () {
    const { activeIndex, data } = this.state

    return (
      <div className='Accordion'>
        <Container>
          <AccordionSemantic fluid styled>
            {data.map((item, index) => (
              <div key={`accordion_tab_${index}`}>
                <AccordionSemantic.Title active={activeIndex === index} index={index} onClick={this.handleClick}>
                  <Icon name='dropdown' />
                  {item.title}
                </AccordionSemantic.Title>
                <AccordionSemantic.Content active={activeIndex === index}>
                  <p>{item.content}</p>
                </AccordionSemantic.Content>
              </div>
            ))}
          </AccordionSemantic>
        </Container>
      </div>
    )
  }
}

export default Accordion
