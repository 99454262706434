import React, { useEffect } from 'react'
import { Form } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next';

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryInput = importer('Components/Inputs/PrimaryInput')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const HeroSearchForm = ({ locationState,  buttonProps = {} }) => {
  const { t } = useTranslation('HeroSearchForm');
  const history = useHistory()
  useEffect(() => {
    const element=document.querySelector('div.SearchInput input')
    element.classList.add("SearchInitialized");
  }, [])
  const onSubmit = ({ search }) => {
    search = search.trim()
    // TagManager.dataLayer({
    //   dataLayer: {
    //     event: 'search',
    //     locationSearch: 'search submitted',
    //     searchTerm: search
    //   }
    // })
    history.push(`/locations/${search}`)
  }
  return (
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit, pristine }) => (
        <Form onSubmit={handleSubmit} className='HeroSearchForm'>
          <Form.Group widths='equal'>
            <Field name='search'>
              {({ input, meta }) => (
                <Form.Field required>
                  <PrimaryInput
                    className='SearchInput'
                    placeholder={t('enterZip')}
                    height={50}
                    {...input}
                    defaultValue={locationState || ''}
                  />
                </Form.Field>
              )}
            </Field>
            <div className='MessageButtonContainer'>
              <PrimaryButton  text={t('search')} width={140} height={48} disabled={pristine} {...buttonProps} />
            </div>
          </Form.Group>
        </Form>
      )}
    />
  )
}

export default HeroSearchForm
