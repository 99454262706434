import React from 'react'
import { Menu, Icon } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

const MenuItem = ({ title, isOpen, navigateTo, pageContent }) => (
  <>
    <Menu.Item
      className={`MenuHeader ${isOpen ? 'open' : ''}`}
      as={NavLink}
      to={navigateTo}
    >
      {title}
      {isOpen ? (
        <Icon name='chevron up' />
      ) : (
        <Icon name='chevron right' />
      )}
    </Menu.Item>
    {isOpen && (
      <div className='MenuContent'>
        {pageContent}
      </div>
    )}
  </>
)

export default MenuItem