import React,{ useState, useEffect  } from "react";
import i18n from "../../../i18n";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import MyRewards from "./MyRewards"
import WaystoEarnCard from "./WaystoEarnCard";
import RewardsHistory from "./RewardsHistory"

const LoyaltyDashboardTabs = ({setLoyaltyDashboardTab, navigateToRedeemRewards, tab, setTab, LoyaltyDashboardTab}) => {
  const { t } = useTranslation("LoyaltyDashboardTabs");
  const classname = i18n.language === "es-MX" ? "spanishTabs " : "tabs";

  const renderTabs = () => {
    switch(tab){
      case "MY_REWARDS":
        return <MyRewards setTab={setTab} />
      case "WAYS_TO_EARN":
        return <WaystoEarnCard navigateToRedeemRewards={navigateToRedeemRewards} />
      case "REWARD_HISTORY":
        return <RewardsHistory />
    }
  } 

  useEffect(() => {
    window.sessionStorage.setItem('LOYALTY_DASHBOARD_ACTIVE_TAB',tab);
    if(LoyaltyDashboardTab === "REDEEM_REWARDS") {
      navigateToRedeemRewards(true)
    }
    }, [tab]);
    
  return (
    <>
      <ul
        className={
          i18n.language === "es-MX" ? "SpanishMyLoyaltyTabs" : "MyLoyaltyTabs"
        }
      >
        <div style={{ display: "flex" }}>
          <li
            className={
              tab === "MY_REWARDS"
                ? `${classname} active first `
                : ` ${classname} first`
            }
            onClick={() => {
              setTab("MY_REWARDS");
            }}
          >
            {t("MyRewards")}
          </li>
          <li
            className={
              tab === "WAYS_TO_EARN"
                ? `${classname} active second`
                : `${classname} second`
            }
            onClick={() => {
              setTab("WAYS_TO_EARN");
            }}
          >
            {t("WaysToEarn")}
          </li>
          <li
            className={
              tab === "REWARD_HISTORY"
                ? `${classname} active last `
                : `${classname} last`
            }
            onClick={() => {
              setTab("REWARD_HISTORY");
            }}
          >
            {t("RewardHistory")}
          </li>
        </div>
      </ul>
      {renderTabs()}
    </>
  );
};

export default LoyaltyDashboardTabs;
