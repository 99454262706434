import React, { useState } from 'react'
import { string, func } from 'prop-types'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, Icon, Header, Message, Loader, Dimmer } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash'

import './styles.scss'

/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'

/* COMPONENTS */
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const PasswordValidation = importer('Components/PasswordValidation')
const InputField = importer('Components/Forms/FormFields/InputField')
const PasswordLinkExpired = importer('Components/PasswordLinkExpired')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const VerifyAccountForm = ({
  verifyTokenError,
  verifyTokenSuccess,
  verifyTokenLoading,
  resetPasswordLoading,
  resetPasswordSuccess,
  resetPasswordError,
  forgotPasswordError,
  handleSubmit
}) => {
  const { t,i18n } = useTranslation('VerifyAccountForm');

  const [isAccountVerified, setIsAccountVerified] = useState(false)
  const [passwordReveal, setPasswordReveal] = useState(false)
  const [passwordConfirmReveal, setPasswordConfirmReveal] = useState(false)
  const [hasPasswordExpired, setHasPasswordExpired] = useState(false)
  const [passwordInput, setPasswordInput] = useState('')
  const togglePasswordReveal = () => setPasswordReveal(!passwordReveal)

  const togglePasswordConfirmReveal = () => setPasswordConfirmReveal(!passwordConfirmReveal)

  const handlePasswordChange = ({ target: { value } }) => {
    setPasswordInput(value)
  }

  const formErrors = (errors, submitFailed) => {
    return (
      submitFailed && (
        <div className='ErrorsContainer'>
          {Object.values(errors).map((e, index) => (
            <p key={index} className='ErrorText'>
              {e}
            </p>
          ))}
        </div>
      )
    )
  }
// Checking the error code returning from OKTA on expiration or used token. This error code is hardcoded in OKTA response of /verify-token
  if(verifyTokenError && verifyTokenError.errorCode === 'E0000011'){
    return <PasswordLinkExpired />
  }

  if(verifyTokenLoading){
    return (
    <Dimmer active>
      <Loader>{t('loading')}</Loader>
    </Dimmer>
    )
  }

  return (
    <div className="verify-account-form page-container">
      {!isAccountVerified && !hasPasswordExpired && <div className='verify-account-form__content page-container__content'>
        <Header as='h1' className='MainHeader'>
          {t('changePassword')}
        </Header>
        <h3 className='sub-header'>
          {t('enerNewPassword')}
        </h3>
        <FinalForm
          onSubmit={handleSubmit}
          validate={(values) => {
            const errors = {}
          
            if (values.password) {
              if (values.password.length < 8) {
                errors.password = 'passwordShouldHaveAtLeast8Char'
              }
              if (!values.password.match(/[0-9]/)) {
                errors.password = 'numbersRequired'
              }
              if (!values.password.match(/[a-z]/)) {
                errors.password = 'lowercaseRequired'
              }
              if (!values.password.match(/[A-Z]/)) {
                errors.password = 'uppercaseRequired'
              } if (values.password.toLowerCase().includes(verifyTokenSuccess?.userInfo?.firstName?.toLowerCase())) {
                errors.password = 'shouldNotContainFirstname';
              }
              if (values.password.toLowerCase().includes(verifyTokenSuccess?.userInfo?.lastName?.toLowerCase())) {
                errors.password = 'shouldNotContainLastName';
              }
              if (values.password.toLowerCase().includes(verifyTokenSuccess?.userInfo?.login?.toLowerCase())) {
                errors.password = 'shouldNotContainUserName';
              }
              const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
              if (!specialChars.test(values.password)) {
                errors.password = 'symbolRequired';
              }
            } else {
              errors.password = 'passwordCannotBeEmpty'
            }
            if (values.passwordConfirm) {
              if (values.passwordConfirm !== values.password) {
                errors.passwordConfirm = 'passwordsDoNotMatch'
              }
            } else {
              errors.passwordConfirm = 'confirmPasswordCannotBeEmpty'
            }
          
            return errors
          }
          }
          render={({
            handleSubmit,
            pristine,
            submitErrors,
            errors,
            submitFailed,
            invalid
          }) => (
            <Form onSubmit={handleSubmit}>
              <Field name='password'>
                {({ input, meta }) => {
                  return (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={46}
                      placeholder={t('newPassword')}
                      type={passwordReveal ? 'text' : 'password'}
                      customOnChange={handlePasswordChange}
                      icon={
                        <Icon
                          name={passwordReveal ? 'eye slash' : 'eye'}
                          link
                          onClick={togglePasswordReveal}
                        />
                      }
                      topLabel={t('newPassword')}
                    />
                  )
                }}
              </Field>
              <Field name='passwordConfirm'>
                {({ input, meta }) => {
                  return (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={46}
                      placeholder={t('confirmPassword')}
                      type={passwordConfirmReveal ? 'text' : 'password'}
                      // customOnChange={handlePasswordChange}
                      icon={
                        <Icon
                          name={passwordConfirmReveal ? 'eye slash' : 'eye'}
                          link
                          onClick={togglePasswordConfirmReveal}
                        />
                      }
                      topLabel={t('confirmPassword')}
                    />
                  )
                }}
              </Field>
              <Form.Group>
                <div className='MessageButtonContainer'>
                  <p className='FormNote'>
                    <sup>
                      <Icon
                        className='RequiredInfo'
                        size='tiny'
                        name='asterisk'
                      />
                    </sup>
                    {t('requiredField')}
                  </p>
                </div>
              </Form.Group>

              {formErrors(errors, submitFailed)}

              <PasswordValidation
                passwordInput={passwordInput}
              />

              {resetPasswordLoading && <LoadingState /> }
              {resetPasswordError && (
                <Message visible warning>
                  <p>
                    {!isEmpty(resetPasswordError.errorCauses) ? (i18n.exists(`apiErrorMessages:${resetPasswordError.errorCauses[0].errorSummary}`)?
                      t(`apiErrorMessages:${resetPasswordError.errorCauses[0].errorSummary}`) :
                      resetPasswordError.errorCauses[0].errorSummary) :
                      (i18n.exists(`apiErrorMessages:${resetPasswordError.errorSummary}`)?
                      t(`apiErrorMessages:${resetPasswordError.errorSummary}`) :
                      resetPasswordError.errorSummary)
                        }</p>
                </Message>
              ) }
              { forgotPasswordError && (<Message visible warning>
                  <p> {forgotPasswordError} </p>
                  </Message>)
              }
              <Form.Group className='ButtonContainer'>
                <PrimaryButton
                  text={t('submit')}
                  width={340}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  type='submit'
                  disabled={invalid || pristine}
                />
              </Form.Group>
            </Form>
          )}
        />
      </div>}
      {isAccountVerified && !hasPasswordExpired && (
        <div className='verify-account-form__content page-container__content'>
          <Header as='h1' className='MainHeader'>
            {t('updateSuccess')}
          </Header>
          <div className="page-container_pageinfo">
            <p>
              {t('accountSuccess')}
            </p>
            <p className="mb-40">
              {t('signIn')}
            </p>
            <PrimaryButton
              text={t('login')}
              width={340}
              height={50}
              fontSize={16}
              as={NavLink}
              to='/login'
            />
          </div>
        </div>
      )}
      {hasPasswordExpired && (
        <div className='verify-account-form__content page-container__content'>
          <Header as='h1' className='MainHeader'>
            {t('passwordExpired')}
          </Header>
          <div className="page-container_pageinfo">
            <p>
              {t('linkExpired')}
            </p>
            <PrimaryButton
              text={t('login')}
              width={340}
              height={50}
              fontSize={16}
              as={NavLink}
              to='/login'
            />
          </div>
        </div>
      )}
    </div>
  )
}

VerifyAccountForm.defaultProps = {
  error: '',
  handleSubmit: () => { }
}

VerifyAccountForm.propTypes = {
  error: string,
  handleSubmit: func
}

export default VerifyAccountForm
