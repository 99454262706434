const validate = (values) => {
  const errors = {}
  // Name validation
  if (values.name){
    if (values.name.length < 1) {
      errors.name = 'nameShouldHaveAtLeasst1Char'
    }
    if (values.name.match(/^[a-zA-Z]+((['. -][a-zA-Z ])?[a-zA-Z]*)*$/i) === null) {
      errors.name = 'nameShouldBeCharacteterOnly'
    }
  } else {
    errors.name = 'mandatoryField'
  }

  if(!values.role){
    errors.role = 'mandatoryField'
  }

  // Gender Validation
  if(!values.defaultCenter) {
    errors.defaultCenter = 'mandatoryField'
  }
  // Email validation
  if (values.loginId) {
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@takeda\.+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    if (!expression.test(String(values.loginId).toLowerCase())) {
      errors.loginId = 'validTakedaEmailId'
    }
  } else {
    errors.loginId = 'mandatoryField'
  }
  return errors
}

export default validate
