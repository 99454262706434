import React from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const Config = importer('Config')
import './styles.scss'

const SendNotification = ({token}) => {
    const { t } = useTranslation('sendNotification');

    const navigateToSendNotification = () =>{
        window.open(`${Config.CENTER_ACTION_FORM_URL}?token=${token}`,'_blank')    
    }

  return (
    <div className='sendNotificationContainer'>
       <div className='notificationHeading'> {t('sendNotificationToDonor')}</div>
       <div>
       <PrimaryButton
        text={t('startSendProcess')}
        width={220}
        height={50}
        fontSize={16}
        classType='Inverted'
        className='sendButton'
        onClick={navigateToSendNotification}
        />
       </div>
    </div>
  )
}

const mapStateToProps = state => ({
    token: state.users?.tokens?.id_token
  })

export default connect (mapStateToProps, null)(SendNotification)