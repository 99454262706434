import React, { useState, useEffect } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { Container, Image, Menu, Responsive, Icon, Dropdown, Modal, Grid, Header  } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import './styles.scss'

import logo from '../../../assets/media/images/BioLife_logo.svg'
import mobileLogo from '../../../assets/media/images/ic_launcher.png'
import iconUser from '../../../assets/media/icons/icon-tkd-profile.png'
import mailIcon from '../../../assets/media/images/mail-frame.svg'
import importer from '@takedapdt/biolife-core/src/importer'
import { CenterOpsUserRole } from '../../Constant/centerOps_enums';
import {
  messages,
} from '../../Helpers/Factory'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const SuperDropdownMenu = importer('Components/Menus/SuperDropdownMenu')
const SecondaryMenu = importer('Components/Menus/SecondaryMenu')
const IconButton = importer('Components/Buttons/IconButton')
const AlertBanner = importer('Components/AlertBanner')
const StartupActions = importer('Stores/Startup/Actions')
const changeLanguageHelper = importer('Helpers/changeLanguage')
const NavBar = ({
  handleToggleSidebar,
  loggedInUser,
  initializeApp,
  parsedMenuItems
}) => {
  const { t, i18n } = useTranslation('FactoryFunctionTexts', 'NavBar');

  const [pageTitle, setPageTitle] = useState('')
  const [pageName, setPageName] = useState('')
  const [pageMetaData, setPageMetaData] = useState('')
  const currentPagePath = window.location.pathname
  const [openAppModal, setOpenAppModal] = useState(true)
  const history = useHistory()
  const aboutBiolifeData = parsedMenuItems?.aboutBiolife
  const aboutPlasmaData= parsedMenuItems?.aboutPlasma
  const becomeADonorData=parsedMenuItems?.becomeDonor
  const currentDonorData= parsedMenuItems?.currentDonor
  const legal= parsedMenuItems?.legal
  const [centerOpsData, setCenterOpsData] = useState("");
  
  useEffect(()=>{
    if(loggedInUser?.systemRole===CenterOpsUserRole.MANAGER)
    {
      setCenterOpsData(parsedMenuItems?.centerOps);
    }else{
      setCenterOpsData(parsedMenuItems?.centerOps.filter((item)=>item.id!==76));
    }
  },[parsedMenuItems]);


  const dismissOpenAppModal = () => {
    setOpenAppModal(false)
    localStorage.setItem('openAppModal', false);
  }
  useEffect(() => {
    if (currentPagePath.startsWith('/about-biolife')) {
      setPageMetaData(aboutBiolifeData && aboutBiolifeData.find(obj => obj.to === currentPagePath)?.metaData)
      setPageTitle(aboutBiolifeData && aboutBiolifeData.find(obj => obj.to === currentPagePath)?.title)
      setPageName(aboutBiolifeData && aboutBiolifeData.find(obj => obj.to === currentPagePath)?.name)
    } else if (currentPagePath.startsWith('/center-ops')) {
      setPageTitle(centerOpsData && centerOpsData.find(obj => obj.to === currentPagePath)?.title)
      setPageName(centerOpsData && centerOpsData.find(obj => obj.to === currentPagePath)?.name)
      setPageMetaData(centerOpsData && centerOpsData.find(obj => obj.to === currentPagePath)?.metaData)
    } else if (currentPagePath.startsWith('/about-plasma')) {
      setPageMetaData(aboutPlasmaData && aboutPlasmaData.find(obj => obj.to === currentPagePath)?.metaData)
      setPageTitle(aboutPlasmaData && aboutPlasmaData.find(obj => obj.to === currentPagePath)?.title)
      setPageName(aboutPlasmaData && aboutPlasmaData.find(obj => obj.to === currentPagePath)?.name)
    } else if (currentPagePath.startsWith('/become-a-donor')) {
      setPageMetaData(becomeADonorData && becomeADonorData.find(obj => obj.to === currentPagePath)?.metaData)
      setPageTitle(becomeADonorData && becomeADonorData.find(obj => obj.to === currentPagePath)?.title)
      setPageName(becomeADonorData && becomeADonorData.find(obj => obj.to === currentPagePath)?.name)
    } else if (currentPagePath.startsWith('/current-donor')) {
      setPageMetaData(currentDonorData && currentDonorData.find(obj => obj.to === currentPagePath)?.metaData)
      setPageTitle(currentDonorData && currentDonorData.find(obj => obj.to === currentPagePath)?.title)
      setPageName(currentDonorData && currentDonorData.find(obj => obj.to === currentPagePath)?.name)
    } else if (currentPagePath.startsWith('/legal')) {
      setPageMetaData(legal && legal.find(obj => obj.to === currentPagePath)?.metaData)
      setPageTitle(legal && legal.find(obj => obj.to === currentPagePath)?.title)
      setPageName(legal && legal.find(obj => obj.to === currentPagePath)?.name)
    }
    if (localStorage.getItem('openAppModal') === 'false') setOpenAppModal(false)
    else setOpenAppModal(true)
    // dataLayer.push({'event': 'optimize.activate'});
  
  }, [currentPagePath])

   const isMobile = () =>{
    if((window.navigator.userAgent.toLowerCase().indexOf("android") > -1 || window.navigator.userAgent.toLowerCase().indexOf("iphone") > -1) && (document.referrer.includes('https://info.biolifeplasma.com/') && !document.referrer.includes('wifi')) && currentPagePath.startsWith('/login')){
    return true
    }
    else return false
  }
   
  const getGclickId=()=>{
    var b = document.cookie.match("(^|;)\\s*" + "_gcl_aw" + "\\s*=\\s*([^;]+)");
    return b ? b.pop().split(".")[2] : null;
  } 
  
  const changeLanguage = (lang) => {
    changeLanguageHelper(lang)
     initializeApp()
  }
  
  const getHref = () => {
    switch(i18n.language){
      case 'es-MX':
        return 'https://info.biolifeplasma.com/carreras'
      case 'en-US':
        return 'https://jobs.biolifeplasma.com/'
    }
  }
  return (
    <div>
      <Helmet htmlAttributes={{ lang: i18n.language }}>
        {pageTitle && <title>{pageTitle}</title>}
        {pageMetaData && <meta name="description" content={pageMetaData} />}
        {window.navigator.userAgent.toLowerCase().indexOf('android') !== -1 &&
          <meta name="apple-itunes-app" content="app-id=1356589861"></meta>
        }
        {window.navigator.userAgent.toLowerCase().indexOf('iphone') !== -1 &&
          <meta name="google-play-app" content="app-id=com.shire.biolife"></meta>
        }
      </Helmet>
      <AlertBanner message={messages[2]} />
      <Responsive as={Menu} borderless className='NavBarMenu'>
        <Container fluid>
          <Menu.Item className='NavBarMenuItem'>
            <div className='LogoContainer'>
              <NavLink to={loggedInUser ? '/center-ops/donor-search': '/'}>
                <Image size='small' alt="Biolife Logo" src={logo} width="100" height="47" />

              </NavLink>
            </div>
          </Menu.Item>{' '}
          <Responsive as={Menu.Menu} position='right' minWidth={Responsive.onlyComputer.minWidth}>
            <Menu.Item className={`NavBarMenuItem ${i18n.language}`}>
              <Dropdown
                placeholder='Select language'
                icon={<Icon size='small' name='chevron down' className='DropdownIcon' />}
                defaultValue={i18n.language}
                value={i18n.language}
                // selection
                compact
                options={[
                  { key: 'English', text: t('NavBar:english'), value: 'en-US' },
                  { key: 'Spanish', text: t('NavBar:spanish'), value: 'es-MX' }
                ]}
                onChange={(e, data) => {
                  changeLanguage(data.value)
                }}
              />
            </Menu.Item>
            {loggedInUser && loggedInUser.role === 'Admin' && (
              <SuperDropdownMenu
                parent='centerOpsMenu'
                dropSpread='vertical'
                trigger={
                  <Menu.Item className='BorderLeft NavBarMenuItem' as={NavLink} to='/center-ops/donor-search'>
                    <Image size='mini' src={iconUser} className='DonorIcon' alt="Donor icon" /> {t('NavBar:welcome')} {loggedInUser.name}
                  </Menu.Item>
                }
                content={<SecondaryMenu menuSpread='vertical' items={centerOpsData} />}
              />
            )}
          </Responsive>
          <Responsive as={Menu.Item} position='right' className='mobileWebHamBurger' maxWidth={Responsive.onlyTablet.maxWidth}>
            <IconButton name='bars' handleOnClick={handleToggleSidebar} />
          </Responsive>
        </Container>
        <Modal open={openAppModal && isMobile()} className='OpenAppModal'>
          <Modal.Content>
          <div className='OpenAppImage'>
             <Image src={mobileLogo} alt='Mobile Logo' />
             </div>
              <div className='OpenAppMsg'>
                <b>{t('NavBar:openBioLifeUsingApp')} </b>
              </div>
              <a href={"https://biolifeplasma.page.link/?link=https://www.biolifeplasma.com/?gclickid%3D"+getGclickId()+"&apn=com.shire.biolife&isi=1356589861&ibi=com.shire.biolife"} className='app-open' >
            {/* appChosen class has been added to track analytics click event ,do not remove it or reuse it anywhere else*/}
            <button className='appChosen' onClick={dismissOpenAppModal}>{t('NavBar:ok')}
            </button></a>
            
            <div className="close-button" onClick={dismissOpenAppModal}>
              <span >{t('NavBar:continueWithWebVersion')}</span>
            </div>
          </Modal.Content>
        </Modal>
      </Responsive>
    </div>
  )
}

const mapStateToProps = (state) => ({
  parsedMenuItems: state.publicContent?.parsedMenuItems
})

const mapDispatchToProps = dispatch => ({
  initializeApp: () => dispatch(StartupActions.initializeApp()),
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
