import React,{ useState } from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Container, Header, Grid } from 'semantic-ui-react'
import './styles.scss';

const AccessManagementSearch = importer('Components/CenterOps/AccessManagement/AccessManagementSearch');
const AccessManagementUserDetails = importer('Components/CenterOps/AccessManagement/AccessManagementUserDetails');

const AccessManagementContainer = () => {
  const [userId,setUserId]= useState(null);
    const getUserId = (loginId)=> {
       setUserId(loginId);
    }
    return (
        <Container fluid className='AccessManagementContainer'>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column mobile={16} tablet={5} computer={4}>
            <Grid.Row>
              <AccessManagementSearch onUserSelected={getUserId}/>
            </Grid.Row>
            </Grid.Column>
            <Grid.Column mobile={16} tablet={11} computer={12}>
              <Grid.Row>
              <AccessManagementUserDetails loginId={userId}/>
              </Grid.Row>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
  
  export default AccessManagementContainer