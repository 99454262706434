import React, { useState, useEffect } from 'react'
import { Form, Message, Loader, Dimmer, Visibility, Icon, Checkbox } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';
import './styles.scss';

import importer from '@takedapdt/biolife-core/src/importer'
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectSearch = importer('Components/Inputs/SelectSearch')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import { mapOptionsByState, sortCentersAlphabetically } from '../../../../Helpers/Utils'
import { connect } from 'react-redux'
const centerActions = importer('Stores/Centers/Actions')

const AddUserCenterAccessForm = ({
    getAllCenters,
    center,
    addUserCenterAccess,
    addUserCenterAccessLoading,
    addUserCenterAccessFailure,
    addUserCenterAccessSuccess,
    selectedUser,
    closeAddCenterModal,
    toggleSuccessModal }) => {
    const { t } = useTranslation('AddUserCenterAccessForm');
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState('');
    const [submited, setSubmited] = useState(false);
    const [centerDropDown, setCenterDropDown] = useState([{text:'',value:''}])
    const [isDefaultCenter, setIsDefaultCenter] = useState(false);
    const [centerList, setCenterList] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [requiredDonationCenter,setRequiredDonationCenter] = useState(null);
    const [isIntialLoad,setIsIntialLoad] = useState(0);

      useEffect(() => {
        if (addUserCenterAccessSuccess !== null && submited) {
          setLoading(false);
          setErrorMessages("");
          toggleSuccessModal(true,"AddUserCenterAccessModal");
          closeAddCenterModal();
        }
      }, [addUserCenterAccessSuccess])

      useEffect(() => {
        if (addUserCenterAccessFailure !== null && submited) {
          setSubmited(false)
          setLoading(false)
          setErrorMessages(addUserCenterAccessFailure ? addUserCenterAccessFailure.message : t('somethingWentWrong'))
        }
      }, [addUserCenterAccessFailure])

      const onSubmit = values => {
          setLoading(true);
          setSubmited(true);
          console.log("submitted");
          if(!selectedCenter){
            setRequiredDonationCenter(true);
          }else{
          const request = {
            donationCenter: selectedCenter,
            isDefault: isDefaultCenter,
            loginId: selectedUser.loginId
          }
          addUserCenterAccess(request);
        }
      };

      const onCancel = () => {
        closeAddCenterModal();
      }
    // This function will called only once
    useEffect(() => {
        getAllCenters();
        setErrorMessages("");
    }, [])

    const transformCenterCollection = () =>
    center &&
    center.map((obj) => ({
      key: obj.sfid,
      text: obj.name,
      value: obj.sfid,
      zipcode: obj.zipcode,
      name: obj.name,
    }))

    useEffect(() => {
        setCenterDropDown(transformCenterCollection())
      },[center]);

    //update User Default Center
    const updateDefaultCenter = () => {
      setIsDefaultCenter(!isDefaultCenter)
    };

    useEffect(() => {
      center && preparedOptions()
      .then(res => setCenterList(res))
    },[center]);
    
    const preparedOptions = async () =>{
      const res = await mapOptionsByState(center)
      const data = sortCentersAlphabetically(res)
      return data
    }
  
  const handleSelection = async ({ value }) => {
    setSelectedCenter(value);
  };

    return (
        <Visibility>
        <FinalForm
      onSubmit={onSubmit}
      initialValues={{role : selectedUser.role}}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className='AddUserCenterAccessForm'>
            <Form.Group>
            <Field name='defaultCenter'>
            {({ input, meta }) => (
                  <SelectSearch
                  options={centerList}
                  centers={center}
                  placeholder={t('placeholderdefaultCenter')}
                  handleOnChange={handleSelection}
                  allowZipcodeSearch={true}
                  topLabel={t('defaultCenter')}
                  required={true}
                  className={selectedCenter? "centerSelected":"SelectSearch"}
                  errorMessage={t('mandatoryField')}
                  hasError={requiredDonationCenter}
                />
                )}
            </Field>
            <Field name='role'>
                {({ input, meta }) => (
                <InputField
                    meta={meta}
                    {...input}
                    required
                    placeholder={t('placeholderdefaultRole')}
                    fieldLabel={t('role')}
                    className='CreateInput'
                    disabled={true}
                    value={selectedUser.role}
                    icon={
                        <Icon className='Required' size='tiny' name='asterisk' />
                      }
                />
                )}
            </Field>
            </Form.Group>
            <Form.Group>
            <Checkbox checked={isDefaultCenter} onClick={updateDefaultCenter} label={t('check-box-label')}/>
            </Form.Group>
            {errorMessages && (
          <Message warning className='errorMessage'>
            <p>{Array.isArray(errorMessages) ? errorMessages.join(', ') : errorMessages}</p>
          </Message>
        )}
            <Form.Group className='submitBtnGroup'>
            
            <PrimaryButton
                text={t('cancelButton')}
                classType='Inverted'
                className='cancel'
                onClick={onCancel}
                />
            <PrimaryButton
                text={t('saveButton')}
                classType='Primary'
                className='save'   
                />
            </Form.Group>
            </Form>
      )}
    />
    {(loading && addUserCenterAccessLoading) && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
    </Visibility>
    )
}

const mapStateToProps = (state) => ({
    addUserCenterAccessLoading: state.centers.addUserCenterAccessLoading,
    addUserCenterAccessSuccess: state.centers.addUserCenterAccessSuccess,
    addUserCenterAccessFailure: state.centers.addUserCenterAccessFailure,
    center: state.centers.getDonorCentersAllSuccess?.data,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    addUserCenterAccess: (applicationUser) => dispatch(centerActions.addUserCenterAccess(applicationUser)),
    getAllCenters: () => dispatch(centerActions.getDonorCentersAll("", "", "", false)),
  })

export default connect(
    mapStateToProps, mapDispatchToProps
) (AddUserCenterAccessForm)