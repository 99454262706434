import React, { useEffect, useState } from 'react'
import { Image, Dimmer, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash'
import './styles.scss'
import IconLock from '../../../../assets/media/icons/icon-lock.png'
import importer from '@takedapdt/biolife-core/src/importer'

const UpdateAccountForm = importer('Components/Forms/UpdateAccountForm')
const centersActions = importer('Stores/Centers/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const publicContentActions = importer('Stores/PublicContent/Actions')

const DonorInformation = ({
  center,
  centers,
  getAllCenters,
  getDonorCentersAll,
  getDonors,
  donor,
  user,
  updateDonor,
  getCountries,
  countries,
  states,
  getAllStates,
  genders,
  getGenders,
  ethnicityOptions,
  getEthnicityOptions,
  getCentersLoading,
  resetNearbyCenters,
  updateDonorLoading,
  updateDonorSuccess,
  updateDonorFailure,
  getCountriesLoading,
  getAllStatesLoading
}) => {
  const { t } = useTranslation('DonorInformation');
  const [loading, setloading] = useState(false)
  const [message, setMessage] = useState('yourDonorInformation')
  const loadData = async () => {
    getCountries()
    getAllStates()
    getGenders()
    if (!isEmpty(donor)) {
      if(donor.pdn){ setMessage('profileIsLocked')}
      if (donor.donationCenter) {
        await getAllCenters(donor.donationCenter)
      }
    }
  }
  useEffect(() => {
    loadData()
  }, [loading])
  if (loading) {
    return (
      <Dimmer active inverted>
        <Loader>{t('loading')}</Loader>
      </Dimmer>
    )
  }
  if (getCountriesLoading || getAllStatesLoading) {
    return (
      <Dimmer active inverted>
        <Loader>{t('loading')}</Loader>
      </Dimmer>
    )
  }
  return (
    <div className='DonorInformation'>
      <UpdateAccountForm
        getGenders={getGenders}
        getEthnicityOptions={getEthnicityOptions}
        ethnicityOptions={ethnicityOptions}
        updateDonor={updateDonor}
        donor={donor}
        updateDonorLoading={updateDonorLoading}
        updateDonorSuccess={updateDonorSuccess}
        updateDonorFailure={updateDonorFailure}
        centers={centers?.data}
        setloading={setloading}
        countries={countries}
        states={states}
        genders={genders}
        getDonorCentersAll={getDonorCentersAll}
        getCentersLoading={getCentersLoading}
        resetNearbyCenters={resetNearbyCenters}
      />
      <div className='Disclaimer'>
        <Image src={IconLock} alt='Lock' inline />
  <span>{t(message)}</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.users.loginHerokuSuccess,
  centers: state.centers?.donorCenters,
  center: state.centers?.getAllCentersSuccess,
  getCentersLoading: state.centers?.getDonorCentersAllLoading,
  donor: state.donors.donors?.data,
  countries: state.publicContent?.Countries,
  states: state.publicContent?.States,
  genders: state.publicContent?.Genders,
  updateDonorLoading: state.donors?.updateDonorLoading,
  updateDonorSuccess: state.donors?.updateDonorSuccess,
  updateDonorFailure: state.donors?.updateDonorFailure?.response,
  ethnicityOptions: state.publicContent?.Ethnicity,
  getCountriesLoading: state.publicContent?.getCountriesLoading,
  getAllStatesLoading: state.publicContent?.getAllStates
})

const mapDispatchToProps = (dispatch) => ({
  getDonorCentersAll: (searchStr) => dispatch(centersActions.getDonorCentersAll(searchStr)),
  getAllCenters: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  getDonors: (id) => dispatch(donorsActions.getDonors(id)),
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
  getCountries: () => dispatch(publicContentActions.getCountries()),
  getAllStates: () => dispatch(publicContentActions.getAllStates()),
  getGenders: () => dispatch(publicContentActions.getGenders()),
  getEthnicityOptions: () => dispatch(publicContentActions.getEthnicityOptions()),
  resetNearbyCenters: (centers) => dispatch(centersActions.getDonorCenterInfoSuccess(centers))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonorInformation)
