import React, { useState } from 'react'
import { Form, Header } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import moment from 'moment'
// import WeekdayPicker from 'react-weekday-picker'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const MaskedField = importer('Components/Forms/FormFields/MaskedField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const validate = importer('Components/Forms/LeadGenReportForm/validate')

const MassUpdateForm = ({
  onClear,
  onSearch,
  pageName
}) => {
  const [renderForm, setRenderForm] = useState(true)
  const { t } = useTranslation('MassUpdateForm');

  const resetForm = () => {
    setRenderForm(false)
    setTimeout(() => { setRenderForm(true) }, 300)
  }
  const initialValues = {
    start_date: null,
    end_date: null
  }

  const onSubmit = values => {
    onSearch({
      ...values,
      start_date: moment(values.start_date).startOf('day').format('YYYY-MM-DDT00:00:00'),
      end_date: moment(values.end_date).endOf('day').format('YYYY-MM-DDT00:00:00')
    })
  }

  return (
    <div className="AppointmentMassUpdate">
      <Header as='h4' className='FormHeader'>
        {/* Mass Update */}
      </Header>
      {renderForm && (
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <Form onSubmit={handleSubmit} className='MassUpdateForm'>
              <Form.Group>
                <Field name='start_date'>
                  {({ input, meta }) => (
                    <MaskedField
                      required
                      meta={meta}
                      {...input}
                      height={50}
                      width={222}
                      topLabel={t('startDate')}
                      placeholder='mm/dd/yyyy'
                      mask='99/99/9999'
                      maskPlaceholder='mm/dd/yyyy'
                      className='SearchInput'
                    />
                  )}
                </Field>
                <Field name='end_date'>
                  {({ input, meta }) => (
                    <MaskedField
                      required
                      meta={meta}
                      {...input}
                      height={50}
                      width={222}
                      topLabel={t('endDate')}
                      placeholder='mm/dd/yyyy'
                      mask='99/99/9999'
                      maskPlaceholder='mm/dd/yyyy'
                      className='SearchInput'
                    />
                  )}
                </Field>

                <PrimaryButton
                  text={t('copy')}
                  width={222}
                  height={50}
                  classType='Primary'
                  fontSize={16}
                  className='AppMassUpdate'
                />

              </Form.Group>
            </Form>
          )}
        />
      )}
    </div>
  )
}

export default MassUpdateForm
