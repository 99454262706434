import React, {useEffect} from 'react';
import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from 'react-redux'
import { Container } from 'semantic-ui-react'
import './styles.scss'
const LoyaltyDashboard=importer('Components/Dashboard/LoyaltyDashboard')
const MyLoyaltyEnrolment = importer('Components/Dashboard/MyLoyaltyEnrolment')
const { navigate, ROUTES } = importer('NavigationService', null)

const MyLoyalty = ({ donor }) => {

  useEffect(() => {
    if((donor?.isLoyaltyMember === "No" || donor?.isLoyaltyMember === null) && !donor?.defaultCenter.isLoyaltyProgramEnabled){
      navigate(ROUTES.LoyaltyNotEligible)
    }
  }, [donor])

  useEffect(() => {
    window.addEventListener('beforeunload', () => {
      sessionStorage.removeItem('justLoyaltyEnrolled')
   })
    return () => {
      sessionStorage.removeItem('justLoyaltyEnrolled')
    }
  }, [])

  const loadLoyaltyDashboard = () => {
    if((donor?.isLoyaltyMember === "No" || donor?.isLoyaltyMember === null) && donor?.defaultCenter.isLoyaltyProgramEnabled){
      return <MyLoyaltyEnrolment />
    }
    else if(sessionStorage.getItem('justLoyaltyEnrolled') && donor?.isLoyaltyMember === "Yes" && donor?.defaultCenter.isLoyaltyProgramEnabled){
      return <MyLoyaltyEnrolment />
    }
    else if(donor?.isLoyaltyMember === "Yes" && !sessionStorage.getItem('justLoyaltyEnrolled')){
      return <LoyaltyDashboard/>
    }
  }

  return (
    <Container className='MyLoyaltyContainer'>
      {loadLoyaltyDashboard()}
    </Container>
  )
}


const mapStateToProps = ({ donors }) => ({
  donor: donors.donors?.data
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLoyalty)

