import React, { useEffect, useState } from 'react'
import { Form, Icon, Message,Image } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { debounce, isEmpty} from 'lodash'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import validate from './validate'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import GrayInfoIcon from '../../../../assets/media/images/GrayInfo-Icon.png'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const MaskedField = importer('Components/Forms/FormFields/MaskedField')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const UpdateAccountForm = (props) => {
  const { t, i18n } = useTranslation('UpdateAccountForm');
  const { width, donor, updateDonor, centers, setloading, countries, states, genders, getDonorCentersAll, getCentersLoading, resetNearbyCenters,updateDonorLoading ,updateDonorSuccess, updateDonorFailure, getGenders, ethnicityOptions, getEthnicityOptions} = props
  const [statesManager, setStatesManager] = useState({})
  const [countriesOptions, setCountriesOptions] = useState([])
  const [statesOptions, setStatesOptions] = useState([])
  const [donorCountry, setDonorCountry] = useState('')
  const [donorZipcode, setDonorZipcode] = useState(donor?.zipCode)
  const [zipCodeForDisplayMessage, setZipCodeForDisplayMessage] = useState(null)
  const [oldZipCode, setOldZipCode] = useState('')
  const [isSubmited, setIsSubmited] = useState(false)
  const [ethnicityDefaultValue, setEthnicityDefaultValue] = useState(null)
  const [NHOPICustomStyle, setNHOPICustomStyle] = useState('')
  const readOnly = donor?.pdn

  const onSubmit = async (values) => {
    setIsSubmited(true);
    if (!values.addressLine1) {
      values.addressLine1 = '';
    }
    // Implementation here
    const numericPhoneNumber = values.phoneNumber?.replace(/[^0-9]/g, '')
    setloading(true)
    if (zipCodeForDisplayMessage && (isEmpty(centers) || !isEmpty(centers) && !centers.some((center) => center.sfid === donor.donationCenter))){
      setZipCodeForDisplayMessage(null)
      values.donationCenter = null
      resetNearbyCenters({data:{nearbyCenters:null}})

    }
      updateDonor(donor.id, {
      donorDetails: {
        ...values,
        phoneNumber: numericPhoneNumber,
        birthDate: moment(values.birthDate).format('YYYY-MM-DD')
      }
    })
    setloading(false)
  }

  useEffect(() => {
    getGenders()
    getEthnicityOptions()
  },[i18n.language])

  useEffect(() => {
    if(donor?.ethnicity && ethnicityOptions != null){
      const defaultEthnicityValue = ethnicityOptions.find(item => item.en_label === donor?.ethnicity)
      i18n.language === 'es-MX' ? setEthnicityDefaultValue(defaultEthnicityValue.es_label) : setEthnicityDefaultValue(defaultEthnicityValue.en_label) 
    }
  }, [ethnicityOptions, i18n.language])

  const transformGenderCollection = () =>
    genders &&
    genders.map((gender) => ({
      key: gender.key,
      text: gender.label,
      value: gender.value
    }))

  const transformEthnicityOptionsCollection = () => 
    ethnicityOptions &&
    ethnicityOptions.map(ethnicityOption => ({
      key: ethnicityOption.key,
      text: i18n.language === 'es-MX' ? ethnicityOption.es_label : ethnicityOption.en_label,
      value: ethnicityOption.value
    }))

  const getStatesByCountries = (country,form) => {
    if (country) {
      const dCountry = countries && countries.find((c) => c.description === country)
      if (dCountry) {
        setDonorCountry(donorCountry)
        if (!isEmpty(states)) {
          const filteredStates = states
            .filter((state) => state.countrycode === dCountry.code)
            .map((s) => ({
              key: s.code,
              value: s.description,
              text: s.description
            }))
          setStatesOptions(filteredStates)
          if(form){
            if(country && country!=donor?.country){
              setStatesManager({
                ...statesManager,
                firstName:form.getFieldState("firstName").value,
                lastName: form.getFieldState("lastName").value,
                zipCode: form.getFieldState("zipCode").value,
                birthDate: form.getFieldState("birthDate").value,
                phoneNumber: form.getFieldState("phoneNumber").value,
                phoneType: form.getFieldState("phoneType").value,
                addressLine1: form.getFieldState("addressLine1").value,
                city: form.getFieldState("city").value.trim(),
                country: country,
                gender: form.getFieldState("gender").value,
                state:""
                })
            }
            else{
              setStatesManager({
                ...statesManager,
                country:country,
                state:donor.state
              })
            }
          }
         }
      }
    }
  }

  const debouncedZipcodeChange = debounce(value => {
    if (oldZipCode !== value && value.length === 5){
      getDonorCentersAll(value)
      setZipCodeForDisplayMessage(value)
    }
    else{
      setZipCodeForDisplayMessage(null)
    }
  }, 500)

  useEffect(() => {
    if (donor?.country) {
      getStatesByCountries(donor.country)
    }
    if (!isEmpty(countries)) {
      const availableContries = countries.map((c) => ({
        key: c.code,
        value: c.description,
        text: c.description
      }))
      setCountriesOptions(availableContries)
    }
  }, [])
 
  useEffect(() => {
    if (!isEmpty(donor)) {
      const complexStateInitial = {
        firstName: donor.firstName,
        lastName: donor.lastName,
        email: donor.email,
        zipCode: donor.zipCode,
        birthDate: moment(donor?.birthDate).format('MM/DD/YYYY'),
        language: donor.language,
        phoneNumber: donor.phoneNumber,
        phoneType: donor.phoneType,
        addressLine1: donor.addressLine1,
        addressLine2: donor.addressLine2,
        city: donor.city?.trim(),
        country: donor.country,
        state: donor.state,
        donationCenter: donor.donationCenter,
        gender: donor.gender,
        ethnicityCode: donor.ethnicity,
        pdn: donor.pdn
      }
      setStatesManager(complexStateInitial)
      setOldZipCode(donor.zipCode)
    }
  }, [donor])
  return (
    <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      initialValues={statesManager}
      render={({ handleSubmit,form}) => (
        <Form onSubmit={handleSubmit} style={{ width }} className='UpdateAccountForm'>
           <p className='FormNote'>
                {t('requiredField')}
              </p>
           <Form.Group style={{ marginBottom: 0 }} widths='equal'>
            <Field name='firstName'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                  fieldLabel={t('firstName')}
                  disabled={readOnly}
                />
              )}
            </Field>
            <Field name='lastName'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                  fieldLabel={t('lastName')}
                  disabled={readOnly}
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group style={{ marginBottom: 0 }} widths='equal'>
          <Field name='birthDate'>
            {({ input, meta }) => (
              <MaskedField
                meta={meta}
                {...input}
                height={50}
                fieldLabel={t('dob')}
                mask='99/99/9999'
                disabled={readOnly}
              />
            )}
          </Field>

          <Field name='gender'>
            {({ input, meta }) => ( 
              <SelectField
                meta={meta}
                input={input}
                options={transformGenderCollection()}
                key={donor?.gender}
                defaultValue={donor?.gender}
                fieldLabel={t('gender')}
                placeholder={t('select')}
                className="genderCustomClass"
              />
            )}
          </Field>
          </Form.Group>
          {donor?.pdn ?
          <div className="NonEditableTextContainer"><Image className='GrayInfoIcon'  src={GrayInfoIcon} width="19" height="19"/><span className="NonEditableText" >{t('FieldBeUpdatedMsg')}</span></div>:
          null}
          <Field name='email'>
            {({ input, meta }) => (
              <InputField meta={meta} {...input} height={50} topLabel={t('email')} placeholder={t('email')} disabled />
            )}
          </Field>
          <div className='Ethnicitycustomselectfield'>{t('ethnicity')}</div>
          <Field name='ethnicityCode'>
  {({ input, meta }) => (
    <SelectField
      meta={meta}
      input={input}
      className={`AccountFormLargeSelect largerSelect`}
                placeholder={ethnicityDefaultValue || t('select')}
      options={transformEthnicityOptionsCollection()}
      handleOnChange={val=>{
        input.onChange(val)
      }}
    />
  )}
</Field>
{donor?.pdn ?
          <div className="enthnicityNonEditableTextContainer"><Image className='GrayInfoIcon'  src={GrayInfoIcon} width="19" height="19"/><span className="NonEditableText" >{t('FieldBeUpdatedMsg')}</span></div>:
          null}
          <Form.Group style={{ marginBottom: 0 }} widths='equal'>
            <Field name='phoneNumber'>
              {({ input, meta }) => (
                <MaskedField
                  meta={meta}
                  {...input}
                  height={50}
                  fieldLabel={t('phoneNumber')}
                  mask='(999) 999-9999'
                  disabled={readOnly}
                />
              )}
            </Field>
            <Field name='phoneType'>
              {({ input, meta }) => (
                <SelectField
                  meta={meta}
                  input={input}
                  fieldLabel={t('type')}
                  options={[
                    { key: 'Mobile', value: 'Mobile', text: t('mobile') },
                    { key: 'Work', value: 'Work', text: t('work') },
                    { key: 'Home', value: 'Home', text: t('home') }
                  ]}
                  key={donor?.phoneType}
                  defaultValue={donor?.phoneType}
                  disabled={readOnly}
                />
              )}
            </Field>
          </Form.Group>


          <Field name='addressLine1'>
            {({ input, meta }) => (
              <InputField
                meta={meta}
                {...input}
                height={50}
                fieldLabel={t('address')}
                disabled={readOnly}
              />
            )}
          </Field>

          <Form.Group widths='equal'>
            <Field name='city'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                fieldLabel={t('city')}
                  disabled={readOnly}
                />
              )}
            </Field>
            <Field name='state'>
              {({ input, meta }) => (
                <SelectField
                  meta={meta}
                  input={input}
                  fieldLabel={t('state')}
                  options={statesOptions}
                  key={donor?.state}
                  defaultValue={donor?.state}
                   disabled={readOnly}
                />
              )}
            </Field>
          </Form.Group>
          <Form.Group widths='equal'>
            <Field name='country'>
              {({ input, meta }) => (
                <SelectField
                  meta={meta}
                  input={input}
                  fieldLabel={t('country')}
                  options={countriesOptions}
                  key={donor?.country}
                  defaultValue={donor?.country}
                  handleOnChange={(value) => {
                    getStatesByCountries(value,form)
                    input.onChange(value)
                  }}
                  disabled={readOnly}
                />
              )}
            </Field>
            <Field name='zipCode'>
              {({ input, meta }) => (
                <Form.Field required>
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    fieldLabel={t('zipCode*')}
                    disabled={readOnly}
                    onChange={(e) => {debouncedZipcodeChange(e.target.value), input.onChange(e.target.value)}}
                  />
                </Form.Field>
              )}
            </Field>
          </Form.Group>
          {getCentersLoading || updateDonorLoading && (
            <LoadingState/>
          )}
          {!getCentersLoading && zipCodeForDisplayMessage && !isEmpty(centers) && !centers.some((center) => center.sfid === donor.donationCenter) && (
            <div className='AlertBox'>
              <p>
               {t('updateZip')} <span>{zipCodeForDisplayMessage}</span> {t('willTakeYouOut')} <b>{t('reschedule')}</b> {t('andOr')} <b>{t('reApplyAnyActivePromo')}</b>. 
               {t('pleaseSelectANewCenter')}
              </p>
            </div>
          )}
          {!getCentersLoading && zipCodeForDisplayMessage && isEmpty(centers) && (
            <div className='AlertBox'>
              <p>
                {t('updateZip')} <span>{zipCodeForDisplayMessage}</span> {t('weAreGrowing')}
              </p>
            </div>
          )}
         
          <Form.Group>
            <div className='MessageButtonContainer'>
              {isSubmited && updateDonorFailure && Array.isArray(updateDonorFailure.data.message) &&
                <div className='FailureStateContainer'>
                  <Message warning visible>
                  {updateDonorFailure.data.message.map(message => <p>{message}</p>)}
                  </Message>
                </div>
              }
              {isSubmited && updateDonorFailure && !Array.isArray(updateDonorFailure.data.message) &&
               <div className='FailureStateContainer'>
                 <FailureState message={updateDonorFailure.data.message} />
               </div>
              }
              { isSubmited && updateDonorSuccess &&
               <p className="SuccessContainer">
                 {updateDonorSuccess.data.message}
               </p>
              }
              <PrimaryButton text={t('saveChanges')} width={180} height={50} classType='Primary' disabled={updateDonorLoading} />
            </div>
          </Form.Group>
        </Form>
        
      )}
    />
  )
}

export default UpdateAccountForm
