import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Container } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'
const centerActions = importer('Stores/Centers/Actions')
const AppContainer = importer('Containers/App')
const OpsCalendar = importer('Components/CenterOps/OpsCalendar')
const DaySchedule = importer('Components/CenterOps/DaySchedule')
const Footer = importer('Components/Footer')
const appointmentsActions = importer('Stores/Appointments/Actions')
const centersActions = importer('Stores/Centers/Actions')
const Appointments = importer('Sagas/Appointments')

const CenterOpsCalendar = props => {
  const {
    loggedInUser,
    calendarData,
    getCenterCalendar,
    calendarLoading,
    calendarError,
    cancelAppointmentHandler,
    getSlotsForAppointmentType,
    getSlotsForAppointmentTypeSuccess,
    getSlotsForAppointmentTypeFailure,
    getSlotsForAppointmentTypeLoading,
    cancelAppointmentSuccess,
    cancelAppointmentLoading,
    cancelAppointmentFailure,
    rescheduleAppointmentHandler,
    rescheduleAppointmentFailure,
    rescheduleAppointmentSuccess,
    rescheduleAppointmentLoading,
    scheduleQuickAppointment,
    scheduleQuickAppointmentLoading,
    scheduleQuickAppointmentSuccess,
    scheduleQuickAppointmentFailure
  } = props

  const [appointmentId, setAppointmentId] = useState(null)
  const [calendarDate, setCalendarDate] = useState(moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'))
  const [donationCenter, setDonationCenter] = useState(null)
  const [opsCalendarSelectedDate, setOpsCalendarSelectedDate] = useState(null)

  useEffect(() => {
    if (calendarDate && donationCenter) {
      getCenterCalendar(calendarDate, donationCenter)
    }
  }, [calendarDate, donationCenter])

  useEffect(() => {
    if (loggedInUser && loggedInUser.defaultCenter) {
      setDonationCenter(loggedInUser.defaultCenter.sfid)
    }
  }, [loggedInUser])

  const getAppSlotsHandler = (data) => {
    getSlotsForAppointmentType(
      data.appointmentDate || calendarDate,
      null,
      null,
      data.numberOfChildren || 0,
      donationCenter,
      data.appointmentType,
      data.appointmentId,
      data.donorSfid
    )
  }

  const scheduleQuickAppointmentHandler = (appointmentData) => {
    scheduleQuickAppointment(appointmentData)
  }

  return (
    <AppContainer compact>
      <div className='MainContainer' style={{ paddingBottom: '400px' }}>
        <Container fluid>
          <OpsCalendar onDateChange={(date) => setCalendarDate(date)} setOpsCalendarSelectedDate={setOpsCalendarSelectedDate}/>
          <DaySchedule
            donationCenter={donationCenter}
            calendarSlots = {calendarData && calendarData.data.slots}
            centerInfo = {calendarData && calendarData.data.center}
            loading={calendarLoading}
            serviceError={calendarError}
            getSlotsForAppointmentType = {getSlotsForAppointmentType}
            getSlotsSuccessInfo= {getSlotsForAppointmentTypeSuccess}
            getSlotsFailureInfo = {getSlotsForAppointmentTypeFailure}
            getSlotsLoading={getSlotsForAppointmentTypeLoading}
            cancelAppointmentHandler = {cancelAppointmentHandler}
            appointmentId = {appointmentId}
            cancelAppointmentSuccessResponse = {cancelAppointmentSuccess}
            getAppSlotsHandler = {getAppSlotsHandler}
            rescheduleAppointmentHandler = {rescheduleAppointmentHandler}
            reloadCalendar={() => { getCenterCalendar(calendarDate, donationCenter) }}
            cancelAppointmentLoading={cancelAppointmentLoading}
            cancelApptErrorInfo={cancelAppointmentFailure}
            rescheduleAppointmentFailInfo={rescheduleAppointmentFailure}
            rescheduleAppointmentSuccessInfo={rescheduleAppointmentSuccess}
            rescheduleAppointmentLoading={rescheduleAppointmentLoading}
            opsCalendarSelectedDate={opsCalendarSelectedDate}
            scheduleQuickAppointmentHandler={scheduleQuickAppointmentHandler}
            isScheduleQuickApptLoading={scheduleQuickAppointmentLoading}
            scheduleQuickApptSuccessInfo={scheduleQuickAppointmentSuccess}
            scheduleQuickApptErrorInfo={scheduleQuickAppointmentFailure}
          />
        </Container>
      </div>
      <Footer />
    </AppContainer>
  )
}
const mapStateToProps = state => {
  return {
    loggedInUser: state.users.user,
    donor: state.donors.donors?.data,
    calendarData: state.centers.getCenterCalendarSuccess,
    calendarError: state.centers.getCenterCalendarFailure,
    calendarLoading: state.centers.getCenterCalendarLoading,
    getSlotsForAppointmentTypeFailure: state.centers.getSlotsForAppointmentTypeFailure,
    getSlotsForAppointmentTypeSuccess: state.centers.getSlotsForAppointmentTypeSuccess,
    getSlotsForAppointmentTypeLoading: state.centers.getSlotsForAppointmentTypeLoading,
    cancelAppointmentFailure: state.appointments.cancelAppointmentFailure,
    cancelAppointmentSuccess: state.appointments.cancelAppointmentSuccess?.data,
    cancelAppointmentLoading: state.appointments.cancelAppointmentLoading,
    rescheduleAppointmentFailure: state.appointments.rescheduleAppointmentFailure,
    rescheduleAppointmentSuccess: state.appointments.rescheduleAppointmentSuccess?.data,
    rescheduleAppointmentLoading: state.appointments.rescheduleAppointmentLoading,
    scheduleQuickAppointmentLoading: state.appointments.scheduleQuickAppointmentLoading,
    scheduleQuickAppointmentSuccess: state.appointments.scheduleQuickAppointmentSuccess?.data,
    scheduleQuickAppointmentFailure: state.appointments.scheduleQuickAppointmentFailure

  }
}

const mapDispatchToProps = dispatch => ({
  getCenterCalendar: (date, centerSfid) => dispatch(centerActions.getCenterCalendar(date, centerSfid)),
  cancelAppointmentHandler: (id, cancelledFrom) => dispatch(appointmentsActions.cancelAppointment(id, cancelledFrom)),
  rescheduleAppointmentHandler: (appointment) => dispatch(appointmentsActions.rescheduleAppointment(appointment.id, appointment)),
  scheduleQuickAppointment: (appointment) => dispatch(appointmentsActions.scheduleQuickAppointment(appointment)),
  getSlotsForAppointmentType: (
    forDate,
    fromDateTime,
    toDateTime,
    numberOfChildren,
    centerSfid,
    appointmentType,
    rescheduleId,
    donorSfid
  ) =>
    dispatch(
      centersActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        rescheduleId,
        donorSfid
      )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(CenterOpsCalendar)
