import React from 'react'

import { Card, Image, Container } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const LazyImage = importer('Components/LazyLoading')
const HorizontalNewsBlock = props => {
  const { content, image, imageMobile } = props
  return (
    <div className='HorizontalNews'>
      <Card className='NewsCard'>
        {image.web_image_redirect_url__c ?  
          <a href={image.web_image_redirect_url__c}>
            <LazyImage src={image.image_url_desktop__c} alt={image.image_alt_text__c} wrapped ui={false} />
          </a> :
          <LazyImage src={image.image_url_desktop__c} alt={image.image_alt_text__c} wrapped ui={false} />
        }
        <Card.Content className='CardContent'>
          <Container>
            <div
              className='ui grid'
              dangerouslySetInnerHTML={{ __html: content?.content__c }}
            />
          </Container>
        </Card.Content>
      </Card>
    </div>
  )
}

export default HorizontalNewsBlock
