import React from 'react'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'
import { Header } from 'semantic-ui-react'
import './styles.scss'
const DonorData = importer('Components/CenterOps/DonorData')
const ScheduleAppointmentAction = importer('Components/CenterOps/ScheduleAppointmentAction')
const LastPhysicals = importer('Components/CenterOps/LastPhysicals')
const UpcomingAppointments = importer('Components/CenterOps/UpcomingAppointments')
const LastDonations = importer('Components/CenterOps/LastDonations')

const CenterOpsDonorInformation = () => {
  const { t} = useTranslation('CenterOpsDonorInformation');
  return (
    <div className='CenterOpsDonorInformation'>
      <Header as='h4' className='SectionHeader'>
        {t('searchResults')}
      </Header>
      <DonorData />
      <ScheduleAppointmentAction />
      <LastPhysicals />
      <UpcomingAppointments />
      <LastDonations />
    </div>
  )
}

export default CenterOpsDonorInformation
