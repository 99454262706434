import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import RouteWrapper from '../Helpers/RouteWrapper'

const RoutePrivate = ({
  component: Component,
  isAuthenticated,
  to,
  ...rest
}) => (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <RouteWrapper component={Component} {...props} />
        ) : (
            <Redirect
              to={{
                pathname: to,
                state: { redirect: props.location.pathname, isAuthenticated }
              }}
            />
          )}
    />
  )

RoutePrivate.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
  isAuthenticated: PropTypes.bool,
  location: PropTypes.object,
  to: PropTypes.string
}

RoutePrivate.defaultProps = {
  to: '/login'
}

export default RoutePrivate
