import React, { useEffect, useState } from 'react'
import { Form, Modal, Grid, Header, Image } from 'semantic-ui-react'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'

const validate = importer('Components/CenterOps/AppointmentSlotMassUpdateModal/validate')
const MaskedField = importer('Components/Forms/FormFields/MaskedField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const AppointmentSlotMassUpdateModal = ({
  open,
  loading,
  success,
  failure,
  toggleModal,
  onApplyMassUpdate,
  handleSaveModalClose,
  tab
}) => {
  const [saveClicked, setSaveClicked] = useState(false)
  const [successModalOpen, setSuccessModalOpen] = useState(false)
  const { t} = useTranslation('days','appointmentSlotMassUpdateModal');


  const initialValues = {
    start_date: null,
    end_date: null,
    weekday: [false, false, false, false, false, false, false]
  }

  const onSubmit = values => {
    setSaveClicked(true)
    onApplyMassUpdate({
      startDate: moment(values.start_date).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      endDate: moment(values.end_date).endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      weekDays: values.weekday
    })
  }
  const onCloseHandler = () => {
    toggleModal()
    setSuccessModalOpen(false)
    saveClicked && success && handleSaveModalClose()
  }

  useEffect(() => {
    saveClicked && success && setSuccessModalOpen(true)
  }, [success])

  useEffect(() => {
    if (open) { setSaveClicked(false) }
  }, [open])


  return (
    <Modal open={open} className='massUpdateModal'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as='h2' className='ModalHeader'>
            {t('appointmentSlotMassUpdateModal:appointmentSlotMassU')}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={toggleModal} />
          </Grid.Column>
        </Grid>
      </Modal.Header>

      <Modal.Content>
        <div className="AppointmentMassUpdate">
            <FinalForm
              onSubmit={onSubmit}
              initialValues={initialValues}
              initialValuesEqual={() => true}
              validate={validate}
              render={({ handleSubmit }) => (
                <Form onSubmit={handleSubmit} className='MassUpdateForm'>
                  <Grid columns='three'>
                    <Grid.Row centered>
                      <Grid.Column>
                        <Field name='start_date'>
                          {({ input, meta }) => (
                            <MaskedField
                              required
                              meta={meta}
                              {...input}
                              height={50}
                              width={222}
                              topLabel={t('appointmentSlotMassUpdateModal:startDate')}
                              placeholder='mm/dd/yyyy'
                              mask='99/99/9999'
                              maskPlaceholder='mm/dd/yyyy'
                              className='SearchInput'
                            />
                          )}
                        </Field>
                      </Grid.Column>
                      <Grid.Column>
                        <Field name='end_date'>
                          {({ input, meta }) => (
                            <MaskedField
                              required
                              meta={meta}
                              {...input}
                              height={50}
                              width={222}
                              topLabel={t('appointmentSlotMassUpdateModal:endDate')}
                              placeholder='mm/dd/yyyy'
                              mask='99/99/9999'
                              maskPlaceholder='mm/dd/yyyy'
                              className='SearchInput'
                            />
                          )}
                        </Field>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                  <Grid columns='twelve' divided>
                    <Grid.Row centered>
                      <Grid.Column className='dayColumn'>
                        <div><Field name='weekday[0]' type='checkbox' component='input' /></div>
                        <label>{t('sun')}</label>
                      </Grid.Column>
                      <Grid.Column className='dayColumn'>
                        <div><Field name='weekday[1]' type='checkbox' component='input' /></div>
                        <label>{t('mon')}</label>
                      </Grid.Column>
                      <Grid.Column className='dayColumn'>
                        <div><Field name='weekday[2]' type='checkbox' component='input' /></div>
                        <label>{t('tues')}</label>
                      </Grid.Column>
                      <Grid.Column className='dayColumn'>
                        <div><Field name='weekday[3]' type='checkbox' component='input' /></div>
                        <label>{t('wen')}</label>
                      </Grid.Column>
                      <Grid.Column className='dayColumn'>
                        <div><Field name='weekday[4]' type='checkbox' component='input' /></div>
                        <label>{t('thu')}</label>
                      </Grid.Column>
                      <Grid.Column className='dayColumn'>
                        <div><Field name='weekday[5]' type='checkbox' component='input' /></div>
                        <label>{t('fri')}</label>
                      </Grid.Column>
                      <Grid.Column className='dayColumn'>
                        <div><Field name='weekday[6]' type='checkbox' component='input' /></div>
                        <label>{t('sat')}</label>
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>

                  <div className='ModalActions'>
                    <Grid columns={1}>
                      <Grid.Row>
                        <Grid.Column textAlign='center'>
                          <PrimaryButton
                            text={t('appointmentSlotMassUpdateModal:close')}
                            width={220}
                            height={50}
                            fontSize={16}
                            classType='Inverted'
                            className='AppMassUpdate'
                            onClick={toggleModal}
                          />
                          <PrimaryButton
                            text={t('appointmentSlotMassUpdateModal:applyMassUpdate')}
                            width={220}
                            height={50}
                            classType='Primary'
                            fontSize={16}
                            className='AppMassUpdate'
                            disabled={saveClicked && loading}
                          />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    {saveClicked && failure && <FailureState message = {failure?.response?.data.message}/>}
                    {saveClicked &&loading  && <LoadingState/>}
                  </div>
                  { saveClicked && success && 
                  <Modal open={successModalOpen} size='small'>
                    <Modal.Header className='HeaderContainer'>
                      <Grid columns={2}>
                        <Grid.Column width={14}>
                          <Header as='h1' className='ModalHeader'>
                            {saveClicked && success && t('appointmentSlotMassUpdateModal:savedSuccessfully!')}
                          </Header>
                        </Grid.Column>
                        <Grid.Column width={2} textAlign='right'>
                          <Image
                            src={CloseIcon}
                            alt='Close'
                            onClick={() => {
                              onCloseHandler()
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </Modal.Header>
                    <Modal.Content>
                      <p>
                        {saveClicked && success && t('appointmentSlotMassUpdateModal:massupdatehasbeenapp')}
                      </p>
                    </Modal.Content>
                  </Modal>
                }
                </Form>
                
              )}
            />
        </div>
      </Modal.Content>

    </Modal>
  )
}

export default AppointmentSlotMassUpdateModal
