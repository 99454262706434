import React from "react";
import { Image, Grid, GridRow, Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import "./styles.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import AustenImage from "../../../../../../assets/media/images/Austen-Image-1@2x.png";
import CelinaImage from "../../../../../../assets/media/images/Celina-Image-1@2x.png";
import JulieImage from "../../../../../../assets/media/images/Julie-Image-1@2x.png";
const PatientTestimonials = () => {
  const { t } = useTranslation("LoyaltyTestimonials");
  const slider = React.useRef(null);
  const testimonials = [
    {
      id: 1,
      img: AustenImage,
      testimonial: t("Testimonial1"),
      name: "Austen",
      description: t("patientDescription"),
    },
    {
      id: 2,
      img: JulieImage,
      testimonial: t("Testimonial2"),
      name: "Julie",
      description: t("patientDescription"),
    },
    {
      id: 3,
      img: CelinaImage,
      testimonial: t("Testimonial3"),
      name: "Celina",
      description: t("patientDescription"),
    },
  ];
  const sliderSettings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
  };
  return (
    <div className="SliderContainer" style={{ backgroundColor: "beige" }}>
      <Slider ref={slider} {...sliderSettings} className="TestimonialCard">
        {testimonials.map((item) => (
          <Grid className="Testimonial" key={item.id} column={1}>
            <GridRow className="TestimonialHeading">
              {t("patientImpact")}
            </GridRow>
            <GridRow className="PatientImage">
              <Image
                src={item.img}
                alt={"patient-image"}
                circular
                size="tiny"
              />
            </GridRow>
            <GridRow className="PatientTestimonial">{item.testimonial}</GridRow>
            <GridRow className="PatientName">{item.name}</GridRow>
            <GridRow className="PatientDescription">{item.description}</GridRow>
          </Grid>
        ))}
      </Slider>
      <div className="ArrowContainer">
        <button
          className="SlickArrow LeftArrow"
          onClick={() => slider?.current?.slickPrev()}
        >
          <Icon name="chevron left big" />
        </button>
        <button
          className="SlickArrow RightArrow"
          onClick={() => slider?.current?.slickNext()}
        >
          <Icon name="chevron right big" />
        </button>
      </div>
    </div>
  );
};
export default PatientTestimonials;
