import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { bool } from 'prop-types';
import { List } from 'semantic-ui-react';
import './styles.scss';

const PasswordValidation = ({
	isValid,
	passwordInput,
	formType = 'ResetPassword'
}) => {
	const { t,i18n } = useTranslation('PasswordValidation');
	const [isPasswordOk, setIsPasswordOk] = useState(['empty', 'case', 'length'])

	useEffect(() => {
		let isOk = []
		const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		if(!passwordInput) {
			isOk.push('empty')
		}
		if (!(passwordInput.match(/[A-Z]/) && passwordInput.match(/[a-z]/) && passwordInput.match(/[0-9]/) && specialChars.test(passwordInput))) {
			isOk.push('case')
		}
		if (passwordInput.length < 8) {
			isOk.push('length')
		}
		setIsPasswordOk(isOk)
	}, [passwordInput])

	return (
		<div
			className={`password-validation ${formType}`}
		>
			<p className='password-validation__title'>{t('passwordRequirements')}</p>
			<List className='password-validation__list'>
				<List.Item>
					<List.Content>
						<List.Icon
							name={
								isPasswordOk.includes('length')
									? 'remove circle'
									: 'check circle'
							}
							className={
								isPasswordOk.includes('length')
									? 'RequirementError'
									: 'Requirement'
							}
						/>
						{t('minimumLengthEight')}
					</List.Content>
				</List.Item>
				<List.Item>
					<List.Content>
						<List.Icon
							name={
								isPasswordOk.includes('case')
									? 'remove circle'
									: 'check circle'
							}
							className={
								isPasswordOk.includes('case')
									? 'RequirementError'
									: 'Requirement'
							}
						/>
						{t('passwordRequirement')}
                	</List.Content>
				</List.Item>
			</List></div>
	)
}
PasswordValidation.defaultProps = {
	isValid: false,
}

PasswordValidation.propType = {
	isValid: bool
}

export default PasswordValidation;