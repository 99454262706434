import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = (values) => {
  const errors = {}
  // Email validation
  if (values.emailId) {
    if (!isValidEmail(values.emailId)) {
      errors.emailId = 'enterAValidEmail'
    }
  } else {
    errors.emailId = 'emailAddressIsRequired'
  }
  // Center validation
  if (!values.center) {
    errors.center = 'centerIsRequired'
  }
  if (!values.state) {
    errors.state = 'stateIsRequired'
  }
  if (!values.status) {
    errors.status = 'pleaseSelectAStatus'
  }
  if (!values.subject) {
    errors.subject = 'subjectIsRequired'
  }
  if(values.subject && values.subject==="Other Questions"){
    if (!values.customSubjectLine) {
      errors.customSubjectLine = 'typeSubject'
   }
  }
   
  if (!values.message) {
    errors.message = 'messageIsRequired'
  }
  return errors
}

export default validate
