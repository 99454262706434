import importer from '@takedapdt/biolife-core/src/importer'

import { isEmpty } from 'lodash'
const isValidEmail = importer('Helpers/isValidEmail')

const validate = (values) => {
  const errors = {}
  if (!isEmpty(values.firstName) && values.firstName.length < 2) {
    errors.firstName = 'firstNameShouldHaveAtLeast2Char'
  }

  if (!isEmpty(values.lastName) && values.lastName.length < 2) {
    errors.lastName = 'lastNameShouldHaveAtLeast2Char'
  }

  if (!isEmpty(values.email) && !isValidEmail(values.email)) {
    errors.email = 'enterAValidEmail'
  }

  if (!isEmpty(values.pdn) && values.pdn.length !== 9) {
    errors.pdn = 'pdnShouldBe9Characters'
  }

  return errors
}

export default validate
