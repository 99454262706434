import React, { useState, useEffect } from 'react'
import { Table, Header, Icon, Grid, Radio } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const HugeSelect = importer('Components/Inputs/HugeSelect')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const SearchResultsCalendar = ({
  playroom,
  searchDonors,
  toggleScheduleAppointment,
  scheduleAppointmentHandler,
  isScheduleAppointmentLoading,
  totalRecords,
  setIsNewDonorSearch,
  needPlayRoom
}) => {
  const [selectedDonorId, setSelectedDonorId] = useState(null)
  const [isBookPlayroom, setIsBookPlayroom] = useState(false)
  const [numberOfChildren, setNumberOfChildren] = useState(0)
  const { t } = useTranslation('SearchResultsCalendar');

  const scheduleNowHandler = () => {
    scheduleAppointmentHandler(selectedDonorId, numberOfChildren)
  }
  const cancelHandler = () => {
    setIsNewDonorSearch(true)
    toggleScheduleAppointment()
  }

  useEffect(() => {
    if (!isBookPlayroom) setNumberOfChildren(0)
  }, [isBookPlayroom])

  return (
    <div className='SearchResultsCalendar'>
      {searchDonors && searchDonors.length > 0 && <div className='TableContainer'>
        <Header as='h4' className='ResultsHeader'>
          {t('searchResults')}
        </Header>
        <Table basic='very' selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>{t('firstName')}</Table.HeaderCell>
              <Table.HeaderCell>{t('lastName')}</Table.HeaderCell>
              <Table.HeaderCell>{t('pdn')}</Table.HeaderCell>
              <Table.HeaderCell>{t('phoneNumber')}</Table.HeaderCell>
              <Table.HeaderCell>{t('center')}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {searchDonors && searchDonors.map((item, index) => (
              <Table.Row key={index} onClick={ () => item.sfid && setSelectedDonorId(item.sfid) }>
                <Table.Cell><Icon name='check' style={{ visibility: selectedDonorId && selectedDonorId === item.sfid ? 'visible' : 'hidden' }}/></Table.Cell>
                <Table.Cell className='FirstNameCell'>{item.firstName}</Table.Cell>
                <Table.Cell>{item.lastName}</Table.Cell>
                <Table.Cell>{item.pdn}</Table.Cell>
                <Table.Cell>{item.phoneNumber}</Table.Cell>
                <Table.Cell>{item.donationCenterName}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>}
      {needPlayRoom && <div className='PlayRoomContainer'>
        <Grid columns={3} verticalAlign='middle'>
          <Grid.Column verticalAlign='middle'>
            <Header
              as='h4'
              className='ResultsHeader'
              style={{ marginBottom: 0 }}
            >
            {t('needaPlayroom')}
            </Header>
          </Grid.Column>
          <Grid.Column verticalAlign='middle' textAlign='center'>
            <Radio
              label={t('yes')}
              name='radioGroup'
              value='Yes'
              checked={isBookPlayroom}
              onChange={() => setIsBookPlayroom(true)}
            />
            <Radio
              label={t('no')}
              name='radioGroup'
              value='No'
              checked={!isBookPlayroom}
              onChange={() => setIsBookPlayroom(false)}
            />
          </Grid.Column>
          <Grid.Column verticalAlign='middle'>
            {isBookPlayroom && (
              <HugeSelect
                options={playroom}
                name='playroom'
                className='HugeSelect '
                handleOnChange={(val) => setNumberOfChildren(val)}
              />
            )}
          </Grid.Column>
        </Grid>
      </div>}
      <div className='infoMessage'>
        {isScheduleAppointmentLoading && (
          <LoadingState />
        )}
      </div>
      <div className='ResultsActions'>
        <Grid columns={2}>
          <Grid.Column textAlign='left'>
            <PrimaryButton
              text={t('scheduleNow')}
              width={180}
              height={50}
              fontSize={16}
              onClick={scheduleNowHandler}
              disabled={isScheduleAppointmentLoading || !selectedDonorId }
            />
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <PrimaryButton
              text={t('cancel')}
              width={145}
              height={50}
              fontSize={16}
              classType='Inverted'
              onClick={cancelHandler}
            />
          </Grid.Column>
        </Grid>
      </div>
    </div>
  )
}

export default SearchResultsCalendar
