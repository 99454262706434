import React from 'react'
import { useHistory, NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { Grid, Image, Responsive, Header } from 'semantic-ui-react'
import './styles.scss'
import HeroMainImg from './images/HeroMainImg.jpg'
import HeroMainImgMobile from './images/img-hero-mobile.jpg'
import importer from '@takedapdt/biolife-core/src/importer'
const HeroSearchForm = importer('Components/Forms/HeroSearchForm')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const Hero = (props) => {
  const history = useHistory()
  const { t } = useTranslation( 'NavBar');
  const { web, mobile, content, altText, imageRedirectUrl } = props
  const headerText = 'Find your nearest'
  const headerBoldText = 'Biolife plasma donation center'

  const loadImage = (deviceType) => {
    if(deviceType === 'web'){
      if(web){
        if(imageRedirectUrl){
          return (
          <a href={imageRedirectUrl}>
            {/* please do not remove HeroImageTrack class it is for GA tracking */}
            <Image src={web} alt={altText} width="766" height="564" className="HeroImageTrack"/>
          </a>
          )
        }
        else{
          return <Image src={web} alt={altText} width="766" height="564" />
        }
      }
      else{
        return  <Image src={HeroMainImg} alt="Hero Image" width="766" height="564" />
      }
    }
    else if(deviceType === 'mobile'){
      if(mobile){
        if(imageRedirectUrl){
          return (
          <a href={imageRedirectUrl}>
            <Image src={mobile} alt={altText} width="320" height="248" />
          </a>
          )
        }
        else{
          return <Image src={mobile} alt={altText} width="320" height="248" />
        }
      }
      else{
        return  <Image src={HeroMainImgMobile} alt="Hero Image" width="320" height="248" />
      }
    }
  }

  return (
    <div className='Hero'>
      <Grid>
        <Grid.Row columns={2} className="HeroGridColumn">
          <Grid.Column computer={8} mobile={16} className='HeroImgContainer'>
            <Responsive minWidth={415}>
              {loadImage('web')}
            </Responsive>
            <Responsive maxWidth={415}>
              {loadImage('mobile')}
            </Responsive>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} verticalAlign="middle" className='HeroContentContainer'>
            <div className='ContentContainer'>
              <div
                className='HeroTextContainer'
                dangerouslySetInnerHTML={{ __html: content?.content__c }}
              />
             </div>
            <div className='signUpContainer'>
            <PrimaryButton text={t("LearnMore")} type='Primary' className="signUpBtn" classType="Inverted"
                  as={NavLink} to='/about-plasma/what-is-plasma'
            />
            <PrimaryButton text={t('NavBar:signup')} type='Primary' className="signUpBtn" 
                  as={NavLink} to='/registration'
            />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default Hero
