import React from 'react'
import { Modal, Image, Grid } from 'semantic-ui-react'
import CloseIcon from '../../../assets/media/icons/icon-close-web.png'
import "./styles.scss"

const LoyaltyModal = ({openModal, header, description, children, onClose,headerStyle = null, descriptionStyle = null}) => {
    return (
        <Modal
            open={openModal}
            className="LoyaltyModalContainer"
        >
        <Modal.Header>
            <Grid columns={2}>
                <Grid.Column width={14} className="ModalTitle">
                {headerStyle !== null ? (
              <span style={headerStyle}>{header}</span>
            ) : (
              header
            )}
                </Grid.Column>
                <Grid.Column width={2} textAlign='right'>
                    <Image src={CloseIcon} alt='Close' className='closeIcon' onClick={() => onClose()} />
                </Grid.Column>
            </Grid>
        </Modal.Header>
        <Modal.Content scrolling={true}>
            {description && <p className='ModalContent'> {description && (
          descriptionStyle !== null ? (
            <span style={descriptionStyle}>{description}</span>
          ) : (
            description
          )
        )}</p>}
            <Modal.Description className="ModalDescription">
                {children}
            </Modal.Description>
        </Modal.Content>
        </Modal>
    )
}

export default LoyaltyModal