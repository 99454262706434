import React, { useState ,useEffect} from "react";
import { useTranslation } from "react-i18next";
import {Header,Modal,Icon,Checkbox,Grid,GridRow, GridColumn} from "semantic-ui-react";
import { connect } from "react-redux";
import importer from "@takedapdt/biolife-core/src/importer";
import "./styles.scss";
const donorsActions = importer("Stores/Donors/Actions");
const PrimaryButton = importer("Components/Buttons/PrimaryButton");
const { isMobileWeb } = importer("Helpers/Utils", null);
const OptOutOfLoyalty = ({ updateDonor, donor,updateDonorLoading,updateDonorFailure,updateDonorSuccess }) => {
  const { t } = useTranslation("OptOutOfLoyalty");
  const [open, setOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const[ showErrorMessage,setShowErrorMessage]=useState(false);
  const handleClose = () => {
    setOpen(false);
    setIsChecked(false);
    setShowErrorMessage(false);
  };
  useEffect(() => {
    setShowErrorMessage(false);
    if(updateDonorSuccess!=null && donor.isLoyaltyMember==='No'){
    setOpen(false);
    setIsChecked(false);
    setShowErrorMessage(false);
    }
    if( isChecked &&
       updateDonorFailure!=null){
        setShowErrorMessage(true);
      }
  }, [updateDonorSuccess,updateDonorFailure])

  const handleConfirm = () => {
    updateDonor(donor.id, {
      donorDetails: {
        isLoyaltyMember: "No",
      },
      loyaltyApplicationUsed: isMobileWeb() ? "MOBILE_WEB" : "WEB",
    });
  };
  return (
    <div className="OptOutContainer">
      <Header as="h4" className="OptOutOfLoyaltyHeader">
        {t("OptOutOfLoyaltyText")}
      </Header>
      <Modal
        size="small"
        open={open}
        trigger={<PrimaryButton text={t("Opt-Out")} width={180} height={52}  classType="Inverted"/>}
        onClose={handleClose}
        onOpen={() => setOpen(true)}
      >
        <Header className="HeaderContainer">
          <span>
            <Icon
              className="CloseIcon"
              color="grey"
              name="delete"
              onClick={handleClose}
            />
          </span>
          <span className="OptOutHeading">{t("OptOutOfLoyaltyText")} </span>
          
        </Header>
        <Modal.Content className="ModalContent">
          <p className="ModalText">{t("ModalText")}</p>
          <Grid className="OptOutGrid">
            <GridRow className="CheckboxContainer" columns={2}>
              <GridColumn width={1} className="CheckboxStyle">
                <Checkbox onClick={() => setIsChecked(!isChecked)} />
              </GridColumn>
              <GridColumn width={15} className="CheckboxText">
                <span>{t("CheckBoxText1")}</span>
                <br />
                <span>{t("CheckBoxText2")}</span>
              </GridColumn>
            </GridRow>
            <GridRow columns={2} className="ButtonsContainer">
              <PrimaryButton
                text={t("cancel")}
                className="PrimaryBtn"
                type="submit"
                width={280}
                height={50}
                fontSize={16}
                classType="Inverted"
                onClick={handleClose}
              />
              <PrimaryButton
                text={t("confirm")}
                className="PrimaryBtn"
                type="button"
                width={280}
                height={50}
                fontSize={16}
                onClick={handleConfirm}
                disabled={!isChecked}   
                 loading={updateDonorLoading}
              />
            </GridRow>
            {showErrorMessage ?
            <GridRow className="OptOutErrorMessage">{t('OptOutErrorMessage')}</GridRow> :" "}
          </Grid>
        </Modal.Content>
      </Modal>
    </div>
  );
};
const mapStateToProps = ({ donors }) => ({
  donor: donors.donors?.data,
  updateDonorSuccess:donors?.updateDonorSuccess,
  updateDonorLoading: donors?.updateDonorLoading,
  updateDonorFailure:donors?.updateDonorFailure
});

const mapDispatchToProps = (dispatch) => ({
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OptOutOfLoyalty);
