import React from 'react'
import ReactDOM from 'react-dom'

import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react'

import 'semantic-ui-css/semantic.min.css'
import i18n from './i18n';
import { Loader } from 'semantic-ui-react'
import TagManager from 'react-gtm-module'

import importer from '@takedapdt/biolife-core/src/importer'

import '../src/i18n';
const createStore = importer('Stores/custom')
const Config = importer('Config')

const { store, persistor } = createStore()

const App = importer('Routes')

// const tagManagerArgs = {
//   gtmId: GTM_WEB_ID,
//   auth: GTM_WEB_AUTH,
//   preview: GTM_WEB_PREVIEW,
//   dataLayer: {}
// }

// TagManager.initialize(tagManagerArgs)

export const app = {
  run () {
    this.render(App)
    window.addEventListener("load", (event) => {
      // dataLayer.push({
      //   event: 'languageSetting',
      //   languageChosen: (i18n.language === "en-US" ? 'English' : 'Spanish')
      // });
    });
    /* istanbul ignore else */ 
    if (process.env.NODE_ENV === 'production') {
      this.initOfflinePlugin()
    }
  },

  initOfflinePlugin () {
    const OfflinePlugin = require('offline-plugin/runtime')

    /* istanbul ignore next */
    OfflinePlugin.install({
      onUpdateReady: () => {
        OfflinePlugin.applyUpdate()
      },
      onUpdated: () => {
        window.location.reload(true)
        // store.dispatch(showAlert(<Reload />, { id: 'sw-update', icon: 'bolt', timeout: 0 }))
      }
    })
  },

  render (Component) {
    const root = document.getElementById('react')

    /* istanbul ignore next */
    if (root) {
      ReactDOM.render(
        <Provider store={store}>
          <PersistGate loading={<Loader size='large' />} persistor={persistor}>
            <Component />
          </PersistGate>
        </Provider>,
        root
      )
    }
  }
}

app.run()
