import React from 'react'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const DonorSearchForm = importer('Components/Forms/DonorSearchForm')

const CenterOpsDonorSearch = () => {
  return (
    <div className='CenterOpsDonorSearch'>
      <DonorSearchForm />
    </div>
  )
}

export default CenterOpsDonorSearch
