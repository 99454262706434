
const validate = (values) => {
  const errors = {}
  if (!values.username) {
    errors.username = 'usernameIsRequired'
  }
  return errors
}

export default validate
