

export const legacyUrlRedirect = (sectionName, pageName) => {
    //removing .html from the url
    if (pageName.includes('.html')) {
        let pageNameWithoutHtml = pageName.split('.html')[0]
        return `${sectionName}/${pageNameWithoutHtml}`
    }
    // getting the new url info 
    let urlToredirect = urlMaping(sectionName, pageName)
    return urlToredirect

}


const urlMaping = (sectionName, pageName) => {
    switch (sectionName) {
        case 'about-biolife':
            switch (pageName) {
                case 'contact':
                    return 'about-biolife/contact-us'
                case 'our-centers':
                    return 'about-biolife/donation-centers'
                case 'kids-playroom':
                    return 'about-biolife/supervised-playrooms'
                case 'upcoming-events':
                    return 'locations'  
                case 'supervised-playrooms':
                    return 'locations'         
                default: return null
            }
        case 'about-plasma':
            switch (pageName) {
                case 'links-resources':
                    return 'about-plasma/links-and-resources'
                case 'what-is-plasma=':
                    return 'about-plasma/what-is-plasma'
                default: return null
            }
        case 'current-donor':
            switch (pageName) {
                case 'health-nutrition':
                    return 'current-donor/health-&-nutrition'
                default: return null
            }
        default: return null
    }
}