import React, { useState } from 'react'

import { Form, Icon, Header, Modal, Loader,Image } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { connect } from 'react-redux'

import './styles.scss'
import validate from './validate'
import { useTranslation } from 'react-i18next';

import importer from '@takedapdt/biolife-core/src/importer'
import GrayInfoIcon from '../../../../assets/media/images/GrayInfo-Icon.png'

const UserActions = importer('Stores/Users/Actions')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const { isMobileWeb } = importer("Helpers/Utils", null);

const UpdateEmailForm = ({
  donor, 
  updateAccountSetting, 
  updateAccountSettingSuccess, 
  updateAccountSettingFailure, 
  updateAccountSettingLoading 
}) => {
  const { t } = useTranslation('UpdateEmailForm');
  const [isSubmited, setIsSubmited] = useState(false)
  const [showFailState, setShowFailState] = useState(null)
  const [openModal, setOpenModal] = useState(false)
  const [changeEmailData, setChangeEmailData] = useState({})
  
  const toggleModal = () => setOpenModal(!openModal)
  const onSubmit = (values) => {
    setChangeEmailData({
      ...values
    })
    setIsSubmited(true)
    toggleModal()
    setShowFailState(true)
  }

  return (
    <div className='UpdateEmailForm AccountSettings'>
      <Header as='h4' className='SectionHeader'>
        {t('changeEmail')}
      </Header>
      <Form.Group>
              <div className='MessageButtonContainer'>
                <p className='FormNote'>
                  <sup>
                    <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                  </sup>
                  {t('requiredField')}
                </p>
              </div>
            </Form.Group>
      <FinalForm
        onSubmit={onSubmit}
        validate={validate}
        initialValues={{ currentEmail: donor && donor.loginId }}
        render={({
          handleSubmit, form
        }) => (
          <Form onSubmit={handleSubmit}>
            <Field name='currentEmail'>
              {({ input, meta }) => (
                <InputField
                  className="NonEditableInputField"
                  meta={meta}
                  {...input}
                  height={50}
                  topLabel={t('currentEmail')}
                  disabled={true}
                />
              )}
            </Field>
            <div className="NonEditableTextContainer"><Image className='GrayInfoIcon'  src={GrayInfoIcon} width="19" height="19"/><span className="NonEditableText" >{t('FieldCannotBeUpdatedMsg')}</span></div>
            <Field name='newEmail'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                  fieldLabel={t('newEmail*')}
                />
              )}
            </Field>

            <Field name='confirmEmail'>
              {({ input, meta }) => (
                <InputField
                  meta={meta}
                  {...input}
                  height={50}
                  fieldLabel={t('confirmEmail*')}
                />
              )}
            </Field>
            {updateAccountSettingFailure && showFailState &&
              <div className = 'FailureStateContainer'>
                <FailureState message = {updateAccountSettingFailure} />
              </div>
              }
              {isMobileWeb() ? (
                <p><Form.Group className='ButtonContainer'>
                <PrimaryButton className="cancelButton"
                  text={t('cancel')}
                  type='button'
                  width={70}
                  height={50}
                  fontSize={14}
                  classType='Inverted'
                  onClick={() => {
                    setShowFailState(false)
                    form.reset()
                    form.resetFieldState('newEmail')
                    form.resetFieldState('confirmEmail')
                  }}
                />
                 <PrimaryButton className
                  type='submit'
                  text={t('changeEmail')}
                  width={'60%'}
                  height={50}
                  fontSize={14}
                  classType='Inverted'
                  loading={updateAccountSettingLoading}
                />
              </Form.Group></p>
              
  ):(
<p><Form.Group className='ButtonContainer'>
              <PrimaryButton
                text={t('cancel')}
                type='button'
                width={200}
                height={50}
                fontSize={16}
                classType='Inverted'
                onClick={() => {
                  setShowFailState(false)
                  form.reset()
                  form.resetFieldState('newEmail')
                  form.resetFieldState('confirmEmail')
                }}
              />
               <PrimaryButton
                type='submit'
                text={t('changeEmail')}
                width={200}
                height={50}
                fontSize={16}
                classType='Primary'
                loading={updateAccountSettingLoading}
              />
            </Form.Group></p>
  )}
            
            <Modal open={openModal} className='UpdateModal'>
              <Modal.Content className='usernameUpdateModal'>
                <p className='usernameUpdateMessage'>
                  {isSubmited && t('youWilBeLoggedOut')}
                </p>
                <PrimaryButton
                  text={t('ok')}
                  width={150}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  className='confirmbtn'
                  onClick={() => {
                    toggleModal()
                    updateAccountSetting(changeEmailData)
                  }}
                />
                <PrimaryButton
                  text={t('cancel')}
                  type='button'
                  width={150}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  onClick={() => {
                    setShowFailState(false)
                    toggleModal()
                    form.reset()
                    form.resetFieldState('newEmail')
                    form.resetFieldState('confirmEmail')
                  }}
                />
              </Modal.Content>
            </Modal>
          </Form>
        )}
      />
      
    </div>
  )
}


const mapStateToProps = state => ({
  user: state.users.user,
  donor: state.donors.donors?.data,
  updateAccountSettingLoading: state.users?.updateAccountSettingLoading,
  updateAccountSettingSuccess: state.users?.updateAccountSettingSuccess,
  updateAccountSettingFailure: state.users?.updateAccountSettingFailure?.response?.data.message,
})

const mapDispatchToProps = dispatch => ({
  updateAccountSetting: (currentEmail, newEmail, confirmEmail) => dispatch(UserActions.updateAccountSetting(currentEmail, newEmail, confirmEmail))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateEmailForm)
