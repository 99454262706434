import React from 'react'
import { Grid, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss'

const DonorData = () => {
  const { t} = useTranslation('DonorData');
  return (
    <div className='DonorData'>
      <p className='SubTitle'>{t('donor')}</p>
      <Header as='h4' className='DonorName'>
        Jenny Smith
      </Header>

      <Grid columns={2}>
        <Grid.Row className='DataRow'>
          <Grid.Column width={6}>{t('pdn')}</Grid.Column>
          <Grid.Column width={10}>JS1091982</Grid.Column>
        </Grid.Row>
        <Grid.Row className='DataRow'>
          <Grid.Column width={6}>{t('dateofBirth')}</Grid.Column>
          <Grid.Column width={10}>01/09/1982</Grid.Column>
        </Grid.Row>
        <Grid.Row className='DataRow'>
          <Grid.Column width={6}>{t('gender')}</Grid.Column>
          <Grid.Column width={10}>Female</Grid.Column>
        </Grid.Row>
        <Grid.Row className='DataRow'>
          <Grid.Column width={6}>{t('email')}</Grid.Column>
          <Grid.Column width={10}>jenny.s@gmail.com</Grid.Column>
        </Grid.Row>
        <Grid.Row className='DataRow'>
          <Grid.Column width={6}>{t('phone')}</Grid.Column>
          <Grid.Column width={10}>(555) 555-5555</Grid.Column>
        </Grid.Row>
        <Grid.Row className='DataRow'>
          <Grid.Column width={6}>{t('phoneType')}</Grid.Column>
          <Grid.Column width={10}>555 West Anystreet</Grid.Column>
        </Grid.Row>
        <Grid.Row className='DataRow'>
          <Grid.Column width={6}>{t('address')}</Grid.Column>
          <Grid.Column width={10}>Orlando, FL 32789</Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

export default DonorData
