import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image, Message } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'

const QuickPhysicalForm = importer('Components/Forms/QuickPhysicalForm')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const QuickPhysicalModal = ({
  open,
  toogleQuickPhysicalModal,
  toggleScheduleAppointment,
  time,
  maxAllowed,
  scheduleQuickAppointmentHandler,
  appointment,
  donationCenter,
  isScheduleQuickApptLoading,
  scheduleQuickApptSuccessInfo,
  scheduleQuickApptErrorInfo,
  reloadCalendar
}) => {
  const { t } = useTranslation('QuickPhysicalModal');
  const [quickAppointmentLoading, setQuickAppointmentLoading] = useState(null)
  const [quickAppointmentSuccess, setQuickAppointmentSuccess] = useState(false)
  const [quickAppointmentError, setQuickAppointmentError] = useState(false)
  const [confirmClicked, setConfirmClicked] = useState(false)
  
  const  handleScheduleQuickAppointment= values => {
    setConfirmClicked(true)
    scheduleQuickAppointmentHandler({startDateTime: appointment.startDateTime, centerSfid: donationCenter, ...values })
  }
  const onCloseHandler= ()=> {
    toogleQuickPhysicalModal()
    setQuickAppointmentLoading(null)
    setQuickAppointmentSuccess(null)
    setQuickAppointmentError(null)
    reloadCalendar()
  }

  useEffect(() => {
    if (!confirmClicked) { return }
    setQuickAppointmentLoading(isScheduleQuickApptLoading)
    setQuickAppointmentSuccess(scheduleQuickApptSuccessInfo?.createAppointmentResponseDto)
    setQuickAppointmentError(scheduleQuickApptErrorInfo)
  }, [scheduleQuickApptSuccessInfo, scheduleQuickApptErrorInfo, isScheduleQuickApptLoading])

  useEffect( () => {
    if (open) { setConfirmClicked(false) }
  }, [open])

  return (
    <Modal
      open={open}
      onClose={()=> {toogleQuickPhysicalModal}}
      className='QuickPhysicalModal'
      size='large'
    >
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
           { quickAppointmentSuccess ? (
             <Header as='h3' className='ModalHeader'>
             {t('scheduleQuickPhysica')}
           </Header>
            ) : (
              <Header as='h2' className='ModalHeader'>
             {t('scheduleQuickPhysica')}
            </Header>
            )}
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image
              src={CloseIcon}
              alt='Close'
              onClick={onCloseHandler}
            />
          </Grid.Column>
        </Grid>
      </Modal.Header>
        <Modal.Content>
        { quickAppointmentSuccess &&
             <Header as='h2' className='ModalHeaderSuccess'>
             {t('yourQuickPhysicalConfirmed')}
           </Header>
        }
          {!quickAppointmentSuccess &&
            <div className='MyAppointment'>
              <div className='SelectedTimeHeader'>
                <Header as='h1' className='HeaderText'>
                  {appointment.time} {t('donation')} <span>({appointment.scheduled} {t('scheduled')} {appointment.maxAllowed} {t('allowed')}</span>
                </Header>
              </div>
              <QuickPhysicalForm
                toogleQuickPhysicalModal={toogleQuickPhysicalModal}
                handleScheduleQuickAppointment={handleScheduleQuickAppointment}
                quickAppointmentLoading={quickAppointmentLoading}
              />
            </div>
          }
          {quickAppointmentSuccess && (
            <div className='SelectedDate' >
              <Header as='h3' className='HeaderText'>{t('appointmentDate')} &amp; {t('time')}</Header>
              <p className='RegularText'>
                {moment(quickAppointmentSuccess.startDateTime).format('YYYY-MM-DD')} {t('at')} {(quickAppointmentSuccess.startDateTime).slice(11,16)} {(quickAppointmentSuccess.startDateTime).slice(11,13) < 12 ? 'AM' : 'PM'}
              </p>
              <Header as='h3' className='HeaderText'>
                {t('appointmentType')}
              </Header>
              {quickAppointmentSuccess.appointmentType && (
                <p className='RegularText'>
                  {`${quickAppointmentSuccess.appointmentType ==='PHYSICAL' ? t('physical') :t('donationCaps')}`}
                </p>
              )} 
            </div>
          )}
        </Modal.Content>
        <Modal.Content>
        {quickAppointmentLoading && ( <LoadingState/> )}
      </Modal.Content>
    </Modal>
  )
}

export default QuickPhysicalModal
