import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import moment from 'moment'
import {splitCenterName} from "../../../Helpers/Utils"
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import TagManager from 'react-gtm-module'
const {isMobileWeb} = importer('Helpers/Utils', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')

const ConfirmCancellationModal = ({
  open,
  toggleModal,
  appointment,
  center,
  cancelAppointment,
  cancelAppointmentSuccess,
  cancelAppointmentLoading,
  cancelAppointmentFailure,
  loggedInUser
}) => {
  const [confirmClicked, setConfirmClicked] = useState(false)
  const { t } = useTranslation('ConfirmCancellationModal');
  const cancelledFrom = loggedInUser?.role === 'Donor' ? isMobileWeb() ? 'MOBILE_WEB' : 'WEB' : 'CENTER_OPS'
  useEffect(() => {
    if (open) { setConfirmClicked(false) }
  }, [open])

  useEffect(() => {
    if (!confirmClicked) { return }
    if (cancelAppointmentSuccess) {
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: 'appointment',
      //     appointmentStage: 'canceled',
      //     appointmentId: cancelAppointmentSuccess.id
      //   }
      // })
    }
  }, [cancelAppointmentSuccess])

  return (
    <Modal open={open} onClose={toggleModal} className='ConfirmCancellationModal'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            {confirmClicked && cancelAppointmentSuccess ? (
              <Header as='h2' className='ModalHeader'>
              {t('yourAppointmentisCanceled')}
            </Header>
            ) : (
              <Header as='h2' className='ModalHeader'>
                {t('cancelAppointment')}
              </Header>
            )}
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={toggleModal} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
          {confirmClicked && cancelAppointmentSuccess && (
            <Header as='h1' className='CancelledHeader'>
               {t('yourAppointmentisCanceled')}
            </Header>
          )}
          <Header as='h3' className='HeaderText'>
            {t('appointmentDate')} &amp; {t('time')}
          </Header>
          <p className='RegularText'>
            {`${moment.parseZone(appointment.startDateTime).format('ddd')}, ${moment.parseZone(appointment.startDateTime).format('ll')} 
              at ${appointment.startTime && appointment.startTime.toLowerCase()}`}
          </p>
          <Header as='h3' className='HeaderText'>
            {t('appointmentType')}
          </Header>
          <p className='RegularText'>{appointment.appointmentType === 'PHYSICAL' ? t('physicalWithDonation') : t('donation') }</p>
          {appointment && appointment.numberOfChildren > 0 &&
            <React.Fragment>
              <Header as='h3' className='HeaderText'>
                {t('playroomReserved')}
              </Header>
              <p className='RegularText'>{t('children')} {appointment.numberOfChildren}</p>
            </React.Fragment>
          }
          <React.Fragment>
            <Header as='h3' className='HeaderText'>
              {loggedInUser?.role === 'Donor' ? splitCenterName(appointment.centerName) :appointment.centerName} {t('center')}
            </Header>
          </React.Fragment>
          <p className='RegularText' style={{ paddingBottom: confirmClicked && cancelAppointmentSuccess ? '60px' : '' }}>
            {center && (
              <p className='RegularText'>
                {center.addressLine1}
                <br /> 
                {loggedInUser?.role === 'Admin' && `${center.city}, ${center.stateCode} ${center.zipcode}`}
                {loggedInUser?.role === 'Donor' && `${splitCenterName(center.city)}, ${center.stateCode} ${center.zipcode}`}
              </p>
            )}
          </p>
          {(!confirmClicked || !cancelAppointmentSuccess) && (
            <div className='ModalActions'>
              {cancelAppointmentLoading && <LoadingState /> }
              {cancelAppointmentFailure && <FailureState message = {cancelAppointmentFailure?.response?.data?.message}/>}
              <Grid.Row className='ConfirmContainer'>
                <PrimaryButton
                  text={t('confirmCancellation')}
                  width={280}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  onClick={() => {
                    setConfirmClicked(true)
                    cancelAppointment(appointment.appointmentId, cancelledFrom)
                  }}
                  disabled={confirmClicked && cancelAppointmentLoading}
                />
              </Grid.Row>
              <Grid.Row>
                <PrimaryButton
                  text={t('keepThisAppointment')}
                  width={280}
                  height={50}
                  fontSize={16}
                  classType='Inverted'
                  onClick={toggleModal}
                />
              </Grid.Row>
            </div>
          )}
        </div>
      </Modal.Content>
    </Modal>
  )
}

export default ConfirmCancellationModal
