import React, { Component } from 'react'
import { Button, Modal, Grid, Image } from 'semantic-ui-react'
import CloseIcon from '../../../assets/media/icons/icon-close-web.png'
import './alertModal.scss'
var timeout = null
export default class index extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
    this.handleClose = this.handleClose.bind(this)
    this.onOpen = this.onOpen.bind(this)
  }

  UNSAFE_componentWillReceiveProps (props) {
    const { open } = props
    const { open: _open } = this.state
    if (open && !_open) { this.setState({ open }, () => this.onOpen()) }
  }

  handleOnClose () {
    this.setState({ open: false }, () => {
      if (timeout) { clearTimeout(timeout) }
    })
  }

  onOpen () {
    const { timedAction } = this.props
    if (timedAction) {
      const { callback, timer } = timedAction
      // console.log('On Open Set Timer: ', {
      //   callback,
      //   timer
      // })
      timeout = setTimeout(() => this.setState({ open: false }, () => callback()), timer)
    }
  }

  handleClose (callback) {
    this.setState({ open: false }, () => {
      if (timeout) { clearTimeout(timeout) }
      if (callback) { callback() }
    })
  }

  render () {
    const { open } = this.state
    const { title, message, actions, titleColour, dontClose } = this.props
    return (
      <Modal
        className='alert-modal'
        size='small'
        closeOnEscape={false}
        closeOnDimmerClick={false}
        open={open}
      >
        {title &&
          <Modal.Header>
            <Grid columns={2}>
              <Grid.Column width={14} style={{ color: titleColour ? titleColour : '#484848' }}>
                {title}
              </Grid.Column>
              {!dontClose &&
                <Grid.Column width={2} textAlign='right'>
                  <Image src={CloseIcon} alt='Close' onClick={() => this.handleOnClose()} />
                </Grid.Column>
              }
            </Grid>
          </Modal.Header>
        }
        <Modal.Content>
          <p>{message}</p>
          <br />
          <Grid stackable className='actions'>
            {actions && actions.length && actions.map((item, index) => (
              <Grid.Column className={actions.length >1 ? 'actionButtons' : ''} key={index}>
                <Button primary={item.primary} className={!item.primary? 'outlineButton' : ''} onClick={() => this.handleClose(item.callback)} negative>
                  {item.text}
                </Button>
              </Grid.Column>
            ))}
          </Grid>
        </Modal.Content>
      </Modal>
    )
  }
}
