import React, { useState } from 'react'
import { Icon, Dropdown } from 'semantic-ui-react'
import './styles.scss'
import { useTranslation } from 'react-i18next';
const PrimarySelect = (props) => {
  const {
    options,
    placeholder,
    className,
    required,
    error,
    handleOnChange,
    name,
    value,
    key,
    defaultValue,
    disabled,
    groupSelect,
    icon
  } = props
  const { t, i18n } = useTranslation('ContactForm','ValidationErrorMessages');

  const selectClasses = error ? `${className} Error` : className
  const [selectedValue, setSelectedValue] = useState({})
  const [updated, setUpdated] = useState(false)
  const handleSelection = (option) => {
    setSelectedValue(option.key)
    setUpdated(true)
    handleOnChange(option.value)
  }
  return (
    <div className='PrimarySelect'>
      {required && (icon || className === 'PrimarySelect') && <Icon className='Required' size='tiny' name='asterisk' />}
      {groupSelect ? <Dropdown
        className={`Base ${selectClasses}`}
        icon='chevron down'
        fluid
        selection
        trigger={(!updated && placeholder) ? placeholder : (t(`${selectedValue}`))}
      >
        <Dropdown.Menu>
          <Dropdown.Divider />
          {options.map(item => (
            <>
              {item.header && <Dropdown.Header style={{textTransform: i18n.language === 'es-MX' && 'none'}} content={item.category} />}
              {item.header ? item.subcategory.map(option =>
                <Dropdown.Item className={option.key === selectedValue ? 'active selected' : ''} key={option.value} {...option} defaultValue={defaultValue}
                  value={value || undefined} onClick={() => handleSelection(option)} />
              ) : <Dropdown.Item className={item.key === selectedValue ? 'active selected' : ''} key={item.value} {...item} defaultValue={defaultValue}
                value={value || undefined} onClick={() => handleSelection(item)} />
              }
              <Dropdown.Divider />
            </>
          ))}
        </Dropdown.Menu>
      </Dropdown> : <Dropdown
        fluid
        selection
        className={`Base ${selectClasses}`}
        placeholder={placeholder}
        options={options}
        icon='chevron down'
        key={key || undefined}
        onChange={(e, { value }) => handleOnChange(value)}
        name={name}
        defaultValue={defaultValue}
        value={value || undefined}
        disabled={disabled}
      />}
    </div>
  )
}

PrimarySelect.defaultProps = {
  className: 'PrimarySelect'
}
export default PrimarySelect
