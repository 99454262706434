import React, { useState } from 'react'
import { Grid} from 'semantic-ui-react'

import importer from '@takedapdt/biolife-core/src/importer'
const DashboardDebitCard = importer('Components/Dashboard/DashboardDebitCard')
const DashboardGiftCard = importer('Components/Dashboard/DashboardGiftCard')
import './styles.scss'

const PaymentTabs = importer('Components/Dashboard/PaymentTabs')

const PaymentCards = ({
  unavailableBalance
}) => {
  const [tab, setTab] = useState('Debitcard')
  return (
    <div>
    <Grid className='PaymentCards'>
      <Grid.Row className='PaymentCardsContent'>
        <div className='PaymentCardsColumn'>
          <PaymentTabs
            tab={tab}
            setTab={setTab}
           />
          {(tab === 'Debitcard') && (
            <div>
              <DashboardDebitCard unavailableBalance={unavailableBalance}/>
           </div>)}
          {(tab === 'PaymentHistory') && (
            <div>
              <DashboardGiftCard />
            </div>
          )}
        </div>
        </Grid.Row>
    </Grid>
   
    </div>
  )
}

export default PaymentCards
