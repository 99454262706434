import React, {useRef} from 'react'
import { Icon, Button } from 'semantic-ui-react'
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';

//custom styling for the filter dropdown.
const customStyles = {
    option: (defaultStyles, state) => ({
        ...defaultStyles,
        display: 'flex',
        alignItems: 'center',
        border: `0.5px solid #DDDDDD`,
        minHeight: '48px',
        backgroundColor: "transparent",
        ':active': {
            ...defaultStyles[':active'],
            backgroundColor: 'white',
          },
    }),
    dropdownIndicator: (defaultStyles, state) => ({
        ...defaultStyles,
        transform: state.selectProps.menuIsOpen && 'rotate(180deg)',
        color: 'gray'
    }),
    placeholder: (defaultStyles) => {
        return {
            ...defaultStyles,
            color: '#ABABAB',
            paddingLeft: 7,
        }
    },
    control: (defaultStyles) => {
        return {
            ...defaultStyles,
            height: '3.5rem'
        }
    },
    indicatorSeparator: (defaultStyles) => {
        return {
        ...defaultStyles,
        display: 'none',
        };
    },
    multiValueRemove: (defaultStyles) => {
        return {
            ...defaultStyles,
            display: 'none',
        }
    },
    multiValueLabel: (defaultStyles) => ({
        ...defaultStyles,
        backgroundColor: '#2181BC',
        color: 'white',
        
    }),
    valueContainer: (defaultStyles) => ({
        ...defaultStyles,
        overflow: "hidden",
        flexWrap: 'nowrap',
        maxWidth: '80%'
    }),
    menuList: (defaultStyles) => ({
        ...defaultStyles,
        "::-webkit-scrollbar": {
            width: "4px",
            height: "0px",
        },
        "::-webkit-scrollbar-track": {
        background: "#f1f1f1"
        },
        "::-webkit-scrollbar-thumb": {
        background: "#ababab"
        },
        "::-webkit-scrollbar-thumb:hover": {
        background: "#555"
        },
        paddingTop: 0,
        paddingBottom: 0,
    }),
};

const CustomValueContainer = ({ children, ...props }) => {
    let [values, input] = children;
    if (Array.isArray(values)) {
        const { length } = values;
        switch (length) {
        case 1:
            values = [values[0]];
            break;
        case 2:
            values = [values[0], values[1]];
            break;
        default:
            values = [values[0], values[1], values[2]];
            break;
        }
    }
    return (
        <components.ValueContainer {...props}>
            {values}
            {input}
        </components.ValueContainer>
    );
};

const CustomMenu = (props) => {
    const { t } = useTranslation('BlogsContainer');

    const handleClearFilterOnTouch = (event) => { 
        event.stopPropagation();
        event.preventDefault();
        props.selectProps.menuProps?.handleClearFilter();
    }

    const handleClearFilterOnClick = () => {
        props.selectProps.menuProps?.handleClearFilter();
    }

    return(
        <components.Menu {...props}>
            {props.children}
            <Button className='ClearFilterBtn' onClick={handleClearFilterOnClick} onTouchEnd={handleClearFilterOnTouch} icon labelPosition='left'>
                <Icon name='close' />
                {t('clearFilterLabel')}
            </Button>
        </components.Menu>)
};

const CustomOption = (props) => {
    const handleClick = (event) => {
        event.preventDefault(); // Prevent the default behavior of the checkbox's onChange event
        props.selectOption(props); // Manually select the option
    };
    return(
        <components.Option {...props} >
            <label onClick={handleClick} className='OptionContainer'>
                {props.label}
                <input 
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                />
                <span className='checkmark'></span>
            </label>
        </components.Option>)
};



const BlogFilter = (props) => {

    const { t } = useTranslation('BlogsContainer');

    const allOption = {
        value: "<SELECT_ALL>",
        label: `${t('filterPlaceholder')}`
    };

    // isOptionSelected sees previous props.value after onChange
    const {handleClearFilter} = props;
    const valueRef = useRef(props.value);
    valueRef.current = props.value;

    const isSelectAllSelected = () =>
        valueRef.current.length === props.options.length;

    const isOptionSelected = (option) =>
        valueRef.current.some(({ value }) => value === option.value) ||
        isSelectAllSelected();

    const getOptions = () => [allOption, ...props.options];

    const getValue = () => (isSelectAllSelected() ? [allOption] : props.value);

    const onChange = (newValue, actionMeta) => {
        const { action, option, removedValue } = actionMeta;
        if (action === "select-option" && option.value === allOption.value) {
            props.onChange(props.options, actionMeta);
        }else if ((action === "deselect-option" && option.value === allOption.value) || (action === "remove-value" && removedValue.value === allOption.value)) {
            props.onChange([], actionMeta);
        }else if (actionMeta.action === "deselect-option" && isSelectAllSelected()) {
            props.onChange(props.options.filter(({ value }) => value !== option.value),actionMeta);
        }else {
            props.onChange(newValue || [], actionMeta);
        }
    };
    
    return(
        <Select
            styles={customStyles}
            isSearchable={false}
            isClearable={false}
            isOptionSelected={isOptionSelected}
            options={getOptions()}
            value={getValue()}
            onChange={onChange}
            hideSelectedOptions={false}
            closeMenuOnSelect={false}
            menuProps={{ handleClearFilter }}
            isMulti
            placeholder={t('filterPlaceholder')}
            components={{ValueContainer: CustomValueContainer, Option: CustomOption, Menu: CustomMenu}}
        />
    )
}

export default BlogFilter;