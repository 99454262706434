export const donorSearchResultsData = [
  {
    firstName: 'Jenny',
    lastName: 'Smith',
    pdn: 'JS1091982',
    email: 'jenny.s@gmail.com',
    phoneNumber: '555-555-5555'
  },
  {
    firstName: 'Jenny',
    lastName: 'Smith',
    pdn: 'JS1091982',
    email: 'jenny.s@gmail.com',
    phoneNumber: '555-555-5555'
  },
  {
    firstName: 'Jenny',
    lastName: 'Smith',
    pdn: 'JS1091982',
    email: 'jenny.s@gmail.com',
    phoneNumber: '555-555-5555'
  },
  {
    firstName: 'Jenny',
    lastName: 'Smith',
    pdn: 'JS1091982',
    email: 'jenny.s@gmail.com',
    phoneNumber: '555-555-5555'
  },
  {
    firstName: 'Jenny',
    lastName: 'Smith',
    pdn: 'JS1091982',
    email: 'jenny.s@gmail.com',
    phoneNumber: '555-555-5555'
  },
  {
    firstName: 'Jenny',
    lastName: 'Smith',
    pdn: 'JS1091982',
    email: 'jenny.s@gmail.com',
    phoneNumber: '555-555-5555'
  }
]
