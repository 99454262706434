import React from 'react'
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react'
import './styles.scss'

const NoCentersInArea = () => {
  const { t } = useTranslation('NoCentersInArea');
  return (
    <div className='NoCentersInArea'>
      <div className='CenterSelectionContainer'>
        <Header as='h2' className='MainHeader'>
           {t('noCentersInYourArea')}
        </Header>
      </div>
    </div>
  )
}

export default NoCentersInArea
