import React,{ useState, useEffect } from "react";
import importer from "@takedapdt/biolife-core/src/importer";
import { useTranslation } from "react-i18next";
import { useLocation } from 'react-router-dom';
import {connect} from 'react-redux'
import "./styles.scss";
const LoyaltyDashboardTabs = importer("Components/Dashboard/LoyaltyDashboardTabs");
const RedeemRewards = importer("Components/Dashboard/RedeemRewards");
const LoyaltyDashboardCard = importer("Components/Dashboard/LoyaltyDashboardCard");
const LoyaltyDonorActions = importer('Stores/Loyalty/Actions')

const LoyaltyDashboard = ({donor, getLoyaltyActivity, setLoyaltyDashboardTab}) => {
  const LoyaltyDashboardTab= window.sessionStorage.getItem("LOYALTY_DASHBOARD_ACTIVE_TAB") ? window.sessionStorage.getItem("LOYALTY_DASHBOARD_ACTIVE_TAB"): setLoyaltyDashboardTab ? setLoyaltyDashboardTab : "MY_REWARDS"
  const [tab, setTab] = useState(LoyaltyDashboardTab);
  const location = useLocation();
  const navigateToRedeemPage = location?.state?.openRedeem
  const [onSubmit, setOnSubmit] = useState(false);
  const [preSelectedTab, setPreSelectedTab] = useState()
  const { t } = useTranslation("LoyaltyDashboard");
  const submitHandler = (value) => {
    setOnSubmit(value);
  };
  const navigateToTab =(value)=>{
    setOnSubmit(false)
    setPreSelectedTab(value)
  }

 //to Display the redeem now page when user using deeplinking '/redeem-rewards'
 // to achive this we need to toggle the onSubmit value to true. below useEfect does that
  useEffect(() => {
    if(navigateToRedeemPage === 'REDEEM_REWARDS'){
      setOnSubmit(true)
    }
  }, [navigateToRedeemPage])

  useEffect(() => {
    if(donor?.pdn && donor?.isLoyaltyMember === "Yes" && !window.sessionStorage.getItem("LOYALTY_ACTIVITY_CALLED")){
      getLoyaltyActivity(donor.pdn)
      window.sessionStorage.setItem("LOYALTY_ACTIVITY_CALLED", true)
    }

    return () => {
      window.sessionStorage.removeItem("LOYALTY_ACTIVITY_CALLED")
      window.sessionStorage.removeItem("LOYALTY_DASHBOARD_ACTIVE_TAB")
    }
  }, [donor])

  return (
    <div>
      {!onSubmit ? (
        <div>
          <LoyaltyDashboardCard
            onSubmit={onSubmit}
            showButton={true}
            submitHandler={submitHandler}
            text={t("Rewards Points")}
            setTab={setTab}
          />
          <LoyaltyDashboardTabs navigateToRedeemRewards={submitHandler}  setLoyaltyDashboardTab={setLoyaltyDashboardTab || preSelectedTab} tab={tab} setTab={setTab} LoyaltyDashboardTab={LoyaltyDashboardTab}/>
        </div>
      ) : (
        <RedeemRewards
        navigateToTab={navigateToTab} 
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  donor: state.donors.donors?.data,
})

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyActivity: (pdn) => dispatch(LoyaltyDonorActions.getLoyaltyActivity(pdn))
})


export default connect(mapStateToProps, mapDispatchToProps)(LoyaltyDashboard);
