import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { Grid, Container } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import { legacyUrlRedirect } from './helper'
const AppContainer = importer('Containers/App')
const PageTopBanner = importer('Components/PageTopBanner')
const VerticalMenu = importer('Components/Menus/VerticalMenu')
const PageContent = importer('Components/PageContent')
const Footer = importer('Components/Footer')
const NotFound = importer('Routes/NotFound')
const { webArticles } = importer("Helpers/WebArticles");
const Config = importer('Config')
/**
 * Web Article Container
 * ---
 * @param props.articles Article List
 * @param props.pages Menu List
 * @param props.title Page title
 */
class index extends Component {
  constructor(props){
    super(props)
    this.script = document.createElement('script')
    this.FAQsSchema = {"@context": "https://schema.org", "@type": "FAQPage", "mainEntity": []}
  }
  selectedPage () {
    const { articles, pages } = this.props
    const pageName = this.findPageName()
    const sectionName=this.findSectionName()
    const page = articles?.find((item) => item.webName === pageName?.toLowerCase())
    const pageObject = pages?.find((item) => item.page.toLowerCase() === pageName?.toLowerCase());
    const whatsNewPageObject = pages?.find((item) => item.page.toLowerCase() === 'whats-new');
    const title = pageObject ? pageObject?.title : whatsNewPageObject?.title;
    const whatsNewPageContent = articles?.find((item) => item.content_id__c === webArticles.ABOUT_BIOLIFE_WHATS_NEW)

    //passing the selectedPage data for whats-new page
    if(!page) {
      if(pageName === 'blog') {
        return [{...whatsNewPageContent}, title]
      }else {
        //check for legacy urls
        let urlToRedirect = legacyUrlRedirect(sectionName,pageName)
        if(urlToRedirect !== null) {
          window.location.replace(`${window.location.origin}/${urlToRedirect}`)
        }else {
          //redirect to page not found
          return ['page not found', 'title not found']
        }
      }
    }
    if (page) {
      if(pageName === 'whats-new') {
        history.replaceState(null, '', `${window.location.pathname}/blog`)
        return [{...whatsNewPageContent}, title]
      }
      return [page, title]
    } else {
      // redirect to page not found 
      return ['page not found', 'title not found']   
    }
  }

  findPageName () {
    const pathName = window.location.pathname
    if(pathName.split("/").reverse()[1] === 'blog'){
      return 'blog'
    }
    return pathName && pathName.length && pathName.substring(pathName.lastIndexOf('/') + 1, pathName.length)
  }
  findSectionName () {
    const pathName = window.location.pathname
    return pathName && pathName.length && pathName.substring(pathName.indexOf('/') + 1, pathName.lastIndexOf('/'))
  }

  generateFAQsSchema(data){
    this.script.type = "application/ld+json"
    data.forEach(item => {
     const eachItem =  {
        "@type": "Question", 
        "name": item.accordion_heading__c, 
        "acceptedAnswer": 
        {
          "@type": "Answer", 
          "text": item.accordion_content__c?.replace(/<[^>]*>/g, '')
        }
      }
      this.FAQsSchema["mainEntity"].push(eachItem)
    })
    this.script.text = JSON.stringify(this.FAQsSchema)
    document.head.appendChild(this.script)
    this.FAQsSchema = {"@context": "https://schema.org", "@type": "FAQPage", "mainEntity": []}
  }
  
  componentWillUnmount() {
    if (document.head.contains(this.script)) {
      document.head.removeChild(this.script);
    }
  }

  render () {
    const { title, pages, videos = false ,t} = this.props
    const [selectedPage, pageTitle] = this.selectedPage()
    if(selectedPage.unique_identifier__c === 20 || selectedPage.unique_identifier__c === 21){ 
      this.generateFAQsSchema(selectedPage.accordians) 
    }
    return (
      <>
      { selectedPage !== 'page not found' ?
      <AppContainer compact title={title}>
      <Helmet>
      <title>{ pageTitle && t(pageTitle)}</title>
       {window.location.pathname.toLowerCase().includes('gift-card') && <meta name="robots" content="noindex"/>}
        <link rel="canonical" href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>
        <PageTopBanner selectedPage={selectedPage} parentMenu={title} />
        <div className='MainContainer'>
          <Container fluid>
            <Grid>
              <Grid.Row columns={2}>
                <Grid.Column computer={4} only='computer'>
                  <VerticalMenu items={pages} compact />
                </Grid.Column>
                <Grid.Column computer={12} mobile={16}>
                  <PageContent selectedPage={selectedPage} videos={videos} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </div>
        <Footer />
      </AppContainer>
      :<NotFound/>
  }
      </>
    )
  }
}

export default (withTranslation('FactoryFunctionTexts')(index)) 

