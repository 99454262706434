import React, { useState, useEffect } from 'react';
import { Tab, Menu, Image } from 'semantic-ui-react';
import './styles.scss';
import BlogContainer from './BlogContainer';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import NoBlogsIcon from '../../../assets/media/icons/NoBlogsIcon.svg';

const PageContentTabs = ({ selectedPage, blogs, blogsCategoryList }) => {
	//activeTab 0 is equivalent to first tab(News) being set as activeTab
	const [activeTab, setActiveTab] = useState(0);
	const { t } = useTranslation('PageContentTabs');
	const prefixPath = window.location.pathname.split('/');

	const panes = [
		{
			menuItem: (
				<Menu.Item
					key="tab1"
					as={NavLink}
					to={`/about-biolife/whats-new/blog`}
					className={activeTab === 0 ? 'active' : ''}
				>
					{t('Blog')}
				</Menu.Item>
			),
			render: () => (
				<Tab.Pane attached={false}>
					{blogs?.length > 0 ? (
						<BlogContainer
							blogs={blogs}
							blogsCategoryList={blogsCategoryList}
							articleId={prefixPath[prefixPath.length - 1]}
						/>
					) : (
						<div style={{ textAlign: 'center' }}>
							<Image style={{ display: 'inline' }} src={NoBlogsIcon} />
							<h3>{t('checkBackSoon')}</h3>
						</div>
					)}
				</Tab.Pane>
			)
		}
	];

	return (
		<Tab
			className="TabMenuContainer"
			panes={panes}
			activeIndex={activeTab}
			onTabChange={(e, { activeIndex }) => setActiveTab(activeIndex)}
			menu={{ secondary: true, pointing: true }}
		/>
	);
};

export default PageContentTabs;
