import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Grid, Image, Header, Table, Dimmer, Loader, Visibility } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'
import { isEmpty } from 'lodash'
import bioLifeCenterImg from '../../../../assets/media/images/biolife-center-image.jpg'
import { getOperationHours, filterNonNomogramCenters, formatPhoneNumber, mapOptionsByState ,splitCenterName} from '../../../Helpers/Utils'

const centersActions = importer('Stores/Centers/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const SelectSearch = importer('Components/Inputs/SelectSearch')
const NearbyCentersSection =importer('Components/Dashboard/NearbyCentersSection')

const DonationCenter = ({
  center,
  centers,
  nearbyCenters,
  centerStatus,
  getDonorNearbyCenters,
  getCenterDetails,
  getDonorCentersAll,
  donor,
  updateDonor,
  loggedInUser
}) => {
  const { t,i18n } = useTranslation('DonationCenter');
  const [width, setWidth] = useState('')
  const [centerOptions, setCenterOptions] = useState([])

  const imgColumnStyle = width < 480 ? { paddingLeft: '0px' } : ''

  const handleUpdate = (e, { calculations }) => setWidth(calculations.width)

  const preparedOptions = async () => {
    const filteredNomogram = filterNonNomogramCenters(centers, donor)
    const res = await mapOptionsByState(filteredNomogram,false,loggedInUser)
    return res
  }

  useEffect(() => {
    preparedOptions()
    .then(res => setCenterOptions(res))
  }, [centers])

  const handleSelection = async ({ value }) => {
    await updateDonor(donor.id, {
      donorDetails: {
        donationCenter: value
      }
    }, true)
  }

  useEffect(() => {
    if (donor?.sfid && isEmpty(nearbyCenters)) {
      getDonorNearbyCenters(donor.sfid)
    }
    if (donor?.donationCenter && isEmpty(center)) {
      getCenterDetails(donor.donationCenter)
    }
    if (donor?.zipCode && isEmpty(centers)) {
      getDonorCentersAll(donor.zipCode)
    }
  }, [])

  if(centerStatus && centerStatus !== 'Selected') {
    return <NearbyCentersSection centerStatus={centerStatus} nearbyCenters={nearbyCenters} />
  }

  if (isEmpty(donor) || isEmpty(center)) {
    return (
      <Dimmer active inverted>
        <Loader>{t('loading')}</Loader>
      </Dimmer>
    )
  }

  return (
    <Visibility onUpdate={handleUpdate}>
      <Grid className='DonationCenter'>
        <Grid.Row columns={3} style={{ backgroundColor: '#D8E9E8' }}>
          <Grid.Column computer={5} mobile={16}>
            <p className='DonationCenterLabel'>{t('yourDonationCenter')}</p>
            <p className='DonationCenterName'>{loggedInUser?.role === 'Admin' ? center.data.name : splitCenterName(center?.data?.name)}</p>
            <p className='DonationCenterInfo'>
              {center.data.addressLine1}
              <br />
              {`${loggedInUser?.role === 'Admin' ? center.data.city : splitCenterName(center?.data?.city)}, ${center.data.stateCode} ${center.data.zipcode}, ${center.data.stateCode} ${center.data.zipcode}`}
              <br />
              <u style={{ textDecorationSkipInk: 'none' }}>{formatPhoneNumber(center.data.telephone)}</u>
              <br />
              {center.data.isSpanishSupported && <p style={{paddingTop: '10px',fontStyle: 'italic'}}>Se Habla Español</p>}
            </p>
          </Grid.Column>
          {!donor.pdn? 
          <Grid.Column
            computer={4}
            mobile={16}
            verticalAlign='bottom'
            style={{ paddingBottom: 33 }}
            textAlign={width < 480 ? 'left' : 'right'}>
            <SelectSearch
              options={centerOptions}
              centers={centers}
              handleOnChange={handleSelection}
              className={`CenterDropdown ${i18n.language}`}
              placeholder={t('changeCenter')}
            />
          </Grid.Column>
          :
          <Grid.Column
            computer={4}
            mobile={16}
            verticalAlign='bottom'
            style={{ paddingBottom: 33 }}
            textAlign={width < 480 ? 'left' : 'right'}>
            <p className='CallToChangeCenter'>{t('pleaseCallUsToChangeYourCenter')}</p>
          </Grid.Column>
          } 
          <Grid.Column style={{ paddingRight: '0px', marginBottom: 0, ...imgColumnStyle }} computer={7} mobile={16}>
            <Image src={center.data.mediumImageUrl || bioLifeCenterImg} alt={center.data.imageAltText} fluid style={{ minHeight: '100%' }} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingTop: '50px', paddingRight: 20, paddingLeft: 20 }}>
          <Grid.Column>
            <Table basic='very'>
              <Table.Row>
                <Table.Cell />
                <Table.Cell className='TableHeader'>{t('officeHours')}</Table.Cell>
              </Table.Row>
              {center.data.hoursOfOperation.map((officeHours, index) => (
                <Table.Row key={index}>
                  <Table.Cell className='OfficeHoursDay'>{officeHours.weekDayName}</Table.Cell>
                  <Table.Cell className='OfficeHoursTime'>{getOperationHours(officeHours)}</Table.Cell>
                </Table.Row>
              ))}
            </Table>
            <div className="DifferHours">{t('HrsMayDiffOnMajorHolidays')}</div>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ paddingRight: 20, paddingLeft: 20 }}>
          <Grid.Column>
            <Header as='h4'>{t('amenities')}</Header>
            <div className='OfficeAmenities' dangerouslySetInnerHTML={{ __html: center.data.amenities }} />

            <div className='Disclaimer'>
              <span>&#42;</span>{t('pleasenote')}
              <br />{t('notFeatureAllOfThe')}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Visibility>
  )
}

const mapStateToProps = (state) => ({
  centers: state.centers?.donorCenters?.data,
  loggedInUser: state.users?.user,
  center: state.centers?.getAllCentersSuccess,
  donor: state.donors?.donors?.data,
  nearbyCenters: state.centers?.nearbyCenters,
  centerStatus: state.centers?.centerStatus
})

const mapDispatchToProps = (dispatch) => ({
  getDonorCentersAll: (searchStr) => dispatch(centersActions.getDonorCentersAll(searchStr)),
  getCenterDetails: (sfid) => dispatch(centersActions.getAllCenters(sfid)),
  getDonorProfile: (id) => dispatch(donorsActions.getDonors(id)),
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
  getDonorNearbyCenters: (donorSfid) => dispatch(centersActions.getDonorCenterInfo(donorSfid))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonationCenter)
