import React from 'react'
import { Helmet } from 'react-helmet'
import importer from '@takedapdt/biolife-core/src/importer'
import { Container, Header, Image, List } from 'semantic-ui-react'
import HeroImage from '../../assets/media/images/hero-image-headquarters.jpg'
import { useTranslation } from 'react-i18next'
const AppContainer = importer('Containers/App')
const CheckEmailMessage = importer('Components/CheckEmailMessage')
const Footer = importer('Components/Footer')

const NotFound = props => {
  const {t} = useTranslation('NotFound')
    const NotFoundHeader = {
        color: '#1f237a',
        fontSize: '68px',
        fontWeight: 'bold',
        letterSpacing: '-0.32px',
        lineHeight: '74px',
        paddingTop: '97px',
        paddingBottom: '65px',
        textAlign: 'center',
    }
    const NotFoundText = {
        color: '#1f237a',
        fontSize: '25px',
        fontWeight: 'bold',
        lineHeight: '40px',
        textAlign: 'center',
        paddingBottom: '100px'
    }
    const notFoundHeroImage = {
      width:'100%'
   }
  return (
    <AppContainer compact title={t('notFound')}>
      <div className='HeroImageContainer'>
          <Image src={HeroImage} alt='Hero Image' style={notFoundHeroImage}/>
        </div>
        <Container>
          <Header as='h1' style={NotFoundHeader}>
            {t('pageNotFound')}
          </Header>
          <div style={NotFoundText}>
            {t('notFoundErrorMessage')}
          </div>
        </Container>
      <Footer />
    </AppContainer>
  )
}

export default NotFound