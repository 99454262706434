import React, { useState } from 'react'
import { func, object, bool } from 'prop-types'
import { Form, Icon, Header } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';

import importer from '@takedapdt/biolife-core/src/importer'

import './styles.scss'
import { connect } from 'react-redux';

const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const ChangePasswordConfirm = importer('Components/ChangePasswordConfirm')
const PasswordValidation = importer('Components/PasswordValidation')
// const validate = importer('Helpers/Validations')

const ChangePasswordForm = ({
  handleSubmit,
  serviceError,
  showLoader,
  donor
}) => {
  const [isNonStandardPasswordFlow, setIsNonStandardPasswordFlow] = useState(false)
  const [passwordReveal, setPasswordReveal] = useState(false)
  const [passwordConfirmReveal, setPasswordConfirmReveal] = useState(false)
  const [success, setSuccess] = useState(false)
  const [passwordInput, setPasswordInput] = useState('')
  const [saveClicked, setSaveClicked] = useState(false)
  const { t } = useTranslation('ChangePasswordForm');

  const handlePasswordChange = ({ target: { value } }) => {
    setPasswordInput(value)
  }

  const togglePasswordReveal = () => setPasswordReveal(!passwordReveal)
  const togglePasswordConfirmReveal = () => setPasswordConfirmReveal(!passwordConfirmReveal)

  const onSubmit = values => {
    setSaveClicked(true)
    handleSubmit(values)
  }

  if (success) {
    return <ChangePasswordConfirm />
  }
  return (
    <div className="change-passowrd-form page-container">
      <div className='change-passowrd-form__content page-container__content'>
        <Header as='h1' className='MainHeader'>
          {isNonStandardPasswordFlow ? t('passwordUpdateRequired') : t('changePassword')}
        </Header>
        <div className="page-container_pageinfo">
          <p className='mb-40'>
            {t('weKeepInfoSafe')}
          </p>
        </div>
        <FinalForm
          onSubmit={onSubmit}
          validate={(values) => {
            const errors = {}
          
            if(!values.currentpassword) {
              errors.currentpassword = 'currentPasswordCannotBeEmpty'
            }
            if (values.password) {
              if (values.password.length < 8) {
                errors.password = 'passwordShouldHaveAtLeast8Char'
              }
              if (!values.password.match(/[A-Z]/)) {
                errors.password = 'uppercaseRequired'
              }
              if (!values.password.match(/[0-9]/)) {
                errors.password = 'numbersRequired'
              }
              if (!values.password.match(/[a-z]/)) {
                errors.password = 'lowercaseRequired'
              }
              if (values.password.toLowerCase().includes(donor.firstName?.toLowerCase())) {
                errors.password = 'shouldNotContainFirstname';
              }
              if (values.password.toLowerCase().includes(donor.lastName?.toLowerCase())) {
                errors.password = 'shouldNotContainLastName';
              }
              if (values.password.toLowerCase().includes(donor.loginId?.toLowerCase())) {
                errors.password = 'shouldNotContainUserName';
              }
              const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
              if (!specialChars.test(values.password)) {
                errors.password = 'symbolRequired';
              }
            } else {
              errors.password = 'passwordCannotBeEmpty'
            }
            if (values.passwordConfirm) {
              if (values.passwordConfirm !== values.password) {
                errors.passwordConfirm = 'passwordsDoNotMatch'
              }
            } else {
              errors.passwordConfirm = 'confirmPasswordCannotBeEmpty'
            }
          
            return errors
          }
          }
          render={({
            handleSubmit
          }) => (
            <Form onSubmit={handleSubmit}>
              <Field name='currentpassword'>
                {({ input, meta }) => {
                  return (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={46}
                      placeholder={t('currentPassword')}
                      type={passwordReveal ? 'text' : 'password'}
                      icon={
                        <Icon
                          name={passwordReveal ? 'eye slash' : 'eye'}
                          link
                          onClick={togglePasswordReveal}
                        />
                      }
                      topLabel={t('currentPassword')}
                    />
                  )
                }}
              </Field>
              <Field name='password'>
                {({ input, meta }) => {
                  return (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={46}
                      placeholder={t('newPassword')}
                      type={passwordReveal ? 'text' : 'password'}
                      customOnChange={handlePasswordChange}
                      icon={
                        <Icon
                          name={passwordReveal ? 'eye slash' : 'eye'}
                          link
                          onClick={togglePasswordReveal}
                        />
                      }
                      topLabel={t('newPassword')}
                    />
                  )
                }}
              </Field>
              <Field name='passwordConfirm'>
                {({ input, meta }) => {
                  return (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={46}
                      placeholder={t('confirmPassword')}
                      type={passwordConfirmReveal ? 'text' : 'password'}
                      customOnChange={handlePasswordChange}
                      icon={
                        <Icon
                          name={passwordConfirmReveal ? 'eye slash' : 'eye'}
                          link
                          onClick={togglePasswordConfirmReveal}
                        />
                      }
                      topLabel={t('confirmPassword')}
                    />
                  )
                }}
              </Field>
              <Form.Group>
                <div className='MessageButtonContainer'>
                  <p className='FormNote'>
                    <sup>
                      <Icon
                        className='RequiredInfo'
                        size='tiny'
                        name='asterisk'
                      />
                    </sup>
                    {t('indicatesRequiredField')}
                  </p>
                  {serviceError &&
                    serviceError.response &&
                    serviceError.response.data && (
                    <FailureState visible warning message={serviceError.response.data.message} />
                  )}
                </div>
              </Form.Group>

              {saveClicked && showLoader && (
                <LoadingState />
              )}

              <PasswordValidation passwordInput={passwordInput} />
              <Form.Group className='ButtonContainer'>
                <PrimaryButton
                  text={t('Submit')}
                  width={340}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  type='submit'
                />
              </Form.Group>
            </Form>
          )}
        />
      </div>
    </div>
  )
}

ChangePasswordForm.defaultProps = {
  serviceError: null,
  showLoader: false
}

ChangePasswordForm.propTypes = {
  handleSubmit: func.isRequired,
  serviceError: object,
  showLoader: bool
}

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data
})

export default connect(mapStateToProps)(ChangePasswordForm)

