import React from 'react'
import { NavLink } from 'react-router-dom'
import ClockIcon from '../../../../../assets/media/icons/icon-clock.svg'
import moment from 'moment'
import { Grid, Card, Image, Label } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss'

const BlogCard = ({ blog }) => {
    
  const { t } = useTranslation('BlogCard')
  return (
    <Grid.Column className="BlogCardContainer" key={blog.slug__c}>
        <Card className='BlogCard'>
            <NavLink to={`/about-biolife/whats-new/blog/${blog.slug__c}`} onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>
                <Image alt={blog?.cover_image_alt_text__c} src={blog?.cover_image_cover_video_english__c} />
            </NavLink>
            <Card.Meta>
                <p>{moment(blog?.publish_date__c).format('MMMM D')}, &nbsp; {moment(blog?.publish_date__c).format('YYYY')} &nbsp; | &nbsp; <Image src={ClockIcon} /> {blog?.expectedReadingTime} {t('min')}</p>
            </Card.Meta>
            <Card.Meta>
                {blog?.content_category__c?.map((category, i) => <Label key={i}>{category.toUpperCase()}</Label>)}
            </Card.Meta>
            <Card.Content>
                <Card.Header style={{textDecoration: 'none'}} as={NavLink} to={`/about-biolife/whats-new/blog/${blog.slug__c}`} onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>{blog?.title__c?.length > 50 ? `${blog?.title__c.substring(0,47)}...` : blog?.title__c}</Card.Header>
                <Card.Description dangerouslySetInnerHTML={{ __html: `${blog?.content_internal__c.substring(0,150)}...` }} />
                {blog?.link_html__c ? <span dangerouslySetInnerHTML={{ __html: blog?.link_html__c}}></span> : null}
            </Card.Content>
            <NavLink to={`/about-biolife/whats-new/blog/${blog.slug__c}`} className='Link' onClick={() => window.scrollTo({top: 0, behavior: "smooth"})}>{t('readFullArticle')}</NavLink>
        </Card>
    </Grid.Column>
  )
}

export default BlogCard