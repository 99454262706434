import React from "react";
import { useTranslation } from "react-i18next";
import { Form, Image } from "semantic-ui-react";
import "./styles.scss";

import importer from "@takedapdt/biolife-core/src/importer";
import infoIcon from "../../../../../assets/media/images/info-icon.png";
const PrimaryInput = importer("Components/Inputs/PrimaryInput");

const InputField = (props) => {
  const {
    meta,
    input,
    required,
    height,
    placeholder,
    className,
    width,
    icon,
    type,
    onChange,
    customOnChange,
    topLabel,
    value,
    disabled,
    onBlur,
    name,
    fieldLabel,
    autoComplete,
    LabelclassName,
  } = props;
  const messsage = meta.touched && meta.error ? meta.error : topLabel;
  const onBlurHandler = (value) => {
    onBlur(value);
  };
  const { t, i18n } = useTranslation("ValidationErrorMessages");
  return (
    <Form.Field required={required} className="FormField">
      {fieldLabel && (
        <small className={LabelclassName ? LabelclassName : "TopLabel"}>
          {fieldLabel}
        </small>
      )}
      {messsage && (
        <small
          className={
            meta.touched && meta.error
              ? `TopLabelError ${i18n.language}`
              : "TopLabel"
          }
        >
          {fieldLabel ? (
            <div className="ErrorMsgContainer">
              <Image
                className="InfoIcon"
                src={infoIcon}
                width="18"
                height="18"
              />
              <span className="ErrorMsg">{t(messsage)}</span>
            </div>
          ) : (
            <span>{t(messsage)}</span>
          )}
        </small>
      )}
      <PrimaryInput
        height={height}
        placeholder={placeholder}
        className={className}
        width={width}
        {...input}
        value={value}
        error={!!(meta.touched && meta.error)}
        required={required}
        icon={icon}
        type={type}
        disabled={disabled}
        onChange={(e) => {
          onChange(e);
          customOnChange && customOnChange(e);
        }}
        onBlur={() => {
          onBlurHandler(value);
        }}
        name={name}
        autoComplete={autoComplete}
      />
    </Form.Field>
  );
};

export default InputField;
