import React from 'react'

import './styles.scss'

const CallToAction = ({ mainContent }) => (
  <div className='CallToAction'>
    <div
      className='CallToActionContent'
      dangerouslySetInnerHTML={{ __html: mainContent?.content__c }}
    />
  </div>
)

export default CallToAction
