import React, { useEffect } from 'react'
import { Header, Container, Grid ,Image} from 'semantic-ui-react'
import './styles.scss'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from "react-redux";
import { useHistory } from 'react-router-dom';

const { ArticleByIdentifier } = importer("Stores/PublicContent/Select", null);
const { webArticles } = importer("Helpers/WebArticles");
const  ProductionText= importer('Components/ProductionText')
const ContactForm = importer('Components/Forms/ContactForm')
const YourStoryForm = importer('Components/Forms/YourStoryForm')
const DonorInformation = importer('Components/Dashboard/DonorInformation')
const DashboardPreferences = importer('Components/Dashboard/DashboardPreferences')
const AccountSetting = importer('Components/Dashboard/AccountSetting')
const DonorDashboard = importer('Components/Dashboard/DonorDashboard')
const DonationCenter = importer('Components/Dashboard/DonationCenter')
const MyAppointments = importer('Components/Dashboard/MyAppointments')
// const DashboardDebitCard = importer('Components/Dashboard/DashboardDebitCard')
// const DashboardGiftCard = importer('Components/Dashboard/DashboardGiftCard')
const PaymentCards = importer('Components/Dashboard/PaymentCards')
const MyRewards = importer('Components/Dashboard/MyLoyalty')
const ScheduleAppointment = importer('Components/Dashboard/ScheduleAppointment')
const PageContentAccordion = importer('Components/PageContentAccordion')
const PatientTestimonals = importer('Components/PatientTestimonals')
const PageContentVideo = importer('Components/PageContentVideo')
const PageContentTabs = importer('Components/PageContentTabs')
const BiolifeMobileApp = importer('Components/BiolifeMobileApp')
const PrimaryButton = importer("Components/Buttons/PrimaryButton");

const PageContent = ({
  selectedPage,
  unavailableBalance,
  videos,
  publicContent,
  images,
  blogs,
  blogsCategoryList
}) => {
  const { t, i18n } = useTranslation('PageContent');
  const queryParam="x-vercel-protection-bypass=wvly71Le744tKvhhbF8mQ1hGZyFODEVb&x-vercel-set-bypass-cookie=samesitenone"
  const url=`https://experience.biolifeplasma.com?${queryParam}`
  const productionText = ArticleByIdentifier( publicContent,webArticles?.IMMERSIVE_DONOR_VIRTUAL_TOUR )
  const becomeDonorBottomText = ArticleByIdentifier( publicContent,webArticles?.BECOME_DONOR_BOTTOM_SECTION )
  const VirtualTourImage = images?.find( (i) => i?.name__c === "Virtually Tour a Center Image" );
  const isContactUsPage = selectedPage?.content_id__c === webArticles.ABOUT_BIOLIFE_CONTACT_US;
  const {isMobileWeb} = importer('Helpers/Utils', null)
  const history = useHistory();

  const bottomProductionText = ArticleByIdentifier(
    publicContent,
    webArticles.PRODUCTION_TEXT_CONTACT_US
  )
  const contactUsFeedbackText = ArticleByIdentifier(
    publicContent,
    webArticles.FEEDBACK_TEXT_CONTACT_US
  )
  const contactUsFAQS = ArticleByIdentifier(
    publicContent,
    webArticles.CONTACT_US_FAQS
  )

  useEffect(() => {
    const element = document.querySelector('#visitOurFAQ');
    const linkEventListener = element ? element.addEventListener('click', handleLinkClick) : null;

    return () => {
      if (linkEventListener) {
        linkEventListener.removeEventListener('click', handleLinkClick);
      }
    };
  }, [selectedPage]);

  // Added scroll function for showing FAQ portion of the contact us page
  const navigateToNextStep = () => {
    history.push({ pathname: "/about-biolife/contact-us" });
  }

  const handleLinkClick = (event) => {
    if (selectedPage?.content_id__c === webArticles.ABOUT_BIOLIFE_CONTACT_US) {
      event.preventDefault();
      navigateToNextStep();
      const className = 'contactUsFAQS'; 
      const element = document.querySelector(`.${className}`);
      if (element) {
        element.scrollIntoView({behaviour: 'smooth', block: 'start'})
        isMobileWeb() ? window.scrollBy(0,0) : window.scrollBy(0,-80)
      }
    }
  };

  if (selectedPage && selectedPage.page) {
    switch (selectedPage.page) {
      case 'donor-dashboard':
        return <DonorDashboard />

      case 'donor-information':
        return <DonorInformation />

      case 'account-setting':
        return <AccountSetting />

      case 'donation-center':
        return <DonationCenter />

      case 'preferences':
        return <DashboardPreferences />

      case 'my-appointments':
        return <MyAppointments />

      // case 'bioLife-debit-card':
     // return <DashboardDebitCard unavailableBalance={unavailableBalance} />

      case 'bioLife-payment-cards':
        return <PaymentCards unavailableBalance={unavailableBalance}/>

      case 'schedule-appointment':
        return <ScheduleAppointment />

      case 'my-rewards':
        return <MyRewards />

      default:
        return (
          <Header as='h1' className='PageMainHeader'>
            {t('comingSoon')}
          </Header>
        )
    }
  }
 
  const renderAccordians = () => {
    if (selectedPage?.accordians?.length > 0) {
      if (selectedPage?.content_id__c === webArticles.CURRENT_DONOR_TESTIMONIALS) {
        return (
          <PatientTestimonals
            data={selectedPage.accordians.map(tab => ({
              menuItem: tab.accordion_heading__c,
              pane: tab.accordion_content__c
            }))}
          />
        )
      }

      return (
        <PageContentAccordion
          data={selectedPage.accordians.map(acc => ({
            title: acc.accordion_heading__c,
            content: acc.accordion_content__c
          }))}
        />
      )
    }

    return null
  }

  const renderExtraContents = () => {
    if (
      selectedPage?.content_id__c === webArticles.ABOUT_BIOLIFE_CONTACT_US ||
      selectedPage?.content_id__c === webArticles.CURRENT_DONOR_YOUR_STORY
    ) {
      return (
        <Container fluid>
          <Grid>
            <Grid.Row>
              <Grid.Column computer={10} mobile={16}>
                {selectedPage?.content_id__c ===
                  webArticles.ABOUT_BIOLIFE_CONTACT_US && <ContactForm />}
                {selectedPage?.content_id__c ===
                  webArticles.CURRENT_DONOR_YOUR_STORY && <YourStoryForm />}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      )
    }
    else if(selectedPage?.content_id__c === webArticles.BIOLIFE_MOBILE_APP){
      return <BiolifeMobileApp />
    }

    return null
  }

  if (selectedPage?.content__c || selectedPage?.accordians?.length > 0) {
    return (
      <div className={`PageContent ${isContactUsPage ? 'contactUsContainer' : ''}`}>
        {selectedPage?.content_id__c === webArticles.ABOUT_BIOLIFE_WHATS_NEW 
          ? <PageContentTabs selectedPage={selectedPage} blogs={blogs} blogsCategoryList={blogsCategoryList} /> 
            : <div className={ isContactUsPage ? 'contactUsPage' : ''} dangerouslySetInnerHTML={{ __html: selectedPage.content__c }} />}
        
        {selectedPage.content_id__c === webArticles.ABOUT_PLASMA_VIDEO_GALLERY &&
          videos && <PageContentVideo videos={videos} />}

        {renderAccordians()}

        {renderExtraContents()}

        {isContactUsPage && 
          <>
            <div className="contactUsBottomText" 
              dangerouslySetInnerHTML={{ __html: contactUsFeedbackText?.content__c }} />
            <div className="contactUsFAQS" 
              dangerouslySetInnerHTML={{ __html: contactUsFAQS?.title__c }} />
            <div className='contactUsFAQSAccordion'>
              <PageContentAccordion 
              data={
                contactUsFAQS &&
                contactUsFAQS?.accordians?.map((contactUsFAQS) => ({
                  title: contactUsFAQS.accordion_heading__c,
                  content: contactUsFAQS.accordion_content__c,
                }))
              }
            /> 
            </div>
            <div className="contactUsBottomText"
              dangerouslySetInnerHTML={{ __html: bottomProductionText?.content__c }} />
          </>
        }

        {selectedPage.content_id__c === webArticles.STEP_BY_STEP_GUIDE &&
          <div className="ImmersiveDonorSection" >
            <h3>{t('VirtualTourHeading')}</h3>
            {/* The class "ClickableImageContainer" is being used as a trigger condition for "Click - Step By Step immersive Donor Experience" trigger function in GTM */}
            <a href={url} target="_blank" id="virtual-experience" className='ClickableImageContainer'>
              <Image 
                src={VirtualTourImage?.image_url_desktop__c}
                alt={VirtualTourImage?.image_alt_text__c}
                className='ClickableImage'
              /> 
            </a>
            { productionText &&
              <ProductionText
                      prodContent={productionText}
                    />
            }
          </div>
        }
        
        {selectedPage.content_id__c === webArticles?.DONOR_ELIGIBILITY &&
          <div className='becomeDonorBottomSection'>
            <div dangerouslySetInnerHTML={{ __html: becomeDonorBottomText?.content__c }} />
            <div className="becomeDonorBottomButton">
              <PrimaryButton
                text={t("scheduleAnAppointment")}
                height={50}
                width={300}
                fontSize={16}
                onClick={() => {
                  const lang = i18n.language === 'es-MX' ? 'es-MX' : 'en-US';
                  const url = `https://www.biolifeplasma.com/registration?lng=${lang}`;
                  window.open(url, '_blank');
                }}
                classType="Primary"
              />
            </div>
          </div>
        }
      </div>
    )
  }
  return null
}
const mapStateToProps = ({ publicContent }) => ({
  publicContent: publicContent.WebArticles?.completeArticles,
  images: publicContent.AppImages,
  blogs: publicContent.getBlogsSuccess,
  blogsCategoryList: publicContent.blogsCategoryList
});

export default connect(mapStateToProps,null)(PageContent);