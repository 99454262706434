import React, { useEffect, useState } from "react";
import importer from "@takedapdt/biolife-core/src/importer";
import { Container, Header, Grid, Table, Checkbox, Dimmer, Loader, Visibility } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
const centerActions = importer("Stores/Centers/Actions");
import "./styles.scss";

const PrimaryButton = importer("Components/Buttons/PrimaryButton");
const IconButton = importer("Components/Buttons/IconButton");
const InputField = importer("Components/Inputs/PrimaryInput");
import exclamationGreyCircle from "../../../../../assets/media/icons/exclamationGreyCircle.svg";
const EditAccessManagementUserDetailsModal = importer('Components/CenterOps/AccessManagement/EditAccessManagementUserDetailsModal');
const AccessManagementSuccessModal = importer('Components/CenterOps/AccessManagement/AccessManagementSuccessModal');
const RemoveUserCenterAccessModal = importer('Components/CenterOps/AccessManagement/RemoveUserCenterAccessModal');
const UpdateUserDefaultCenterModal = importer('Components/CenterOps/AccessManagement/UpdateUserDefaultCenterModal');
const RemoveUserPortalAccessModal = importer('Components/CenterOps/AccessManagement/RemoveUserPortalAccessModal');
const AddUserCenterAccessModal = importer('Components/CenterOps/AccessManagement/AddUserCenterAccessModal');

const AccessManagementUserDetails = (props) => {
  const loginId = props.loginId;
  useEffect(() => {
    if(loginId){
      props.getApplicationUserDetails(loginId);
    }
  }, [loginId]);
  const { t } = useTranslation("AccessManagementUserDetails");
  const [openEditAccessManagementUserDetailsModal, setEditAccessManagementUserDetailsModal] = useState(false);
  const toggleEditAccessManagementUserDetailsModal = () => setEditAccessManagementUserDetailsModal(!openEditAccessManagementUserDetailsModal);

  const [openAccessManagementSuccessModal, setAccessManagementSuccessModal] = useState(false);
  const toggleAccessManagementSuccessModal = () => {console.log("Success Modal");setAccessManagementSuccessModal(!openAccessManagementSuccessModal)};
  const [successModalHeader, setSuccessModalHeader] = useState("");
  const [successModalContent, setSuccessModalContent] = useState("");

  const [openRemoveUserCenterAccessModal, setRemoveUserCenterAccessModal] = useState(false);
  const toggleRemoveUserCenterAccessModal = () => setRemoveUserCenterAccessModal(!openRemoveUserCenterAccessModal);
  const [selectedCenter, setSelectedCenter] = useState({});
  const [defaultCenter, setDefaultCenter] = useState("");

  const [openUpdateUserDefaultCenterModal, setUpdateUserDefaultCenterModal] = useState(false);
  const toggleUpdateUserDefaultCenterModal = () => setUpdateUserDefaultCenterModal(!openUpdateUserDefaultCenterModal);
  
  const [openRemoveUserPortalAccessModal, setRemoveUserPortalAccessModal] = useState(false);
  const toggleRemoveUserPortalAccessModal = () => setRemoveUserPortalAccessModal(!openRemoveUserPortalAccessModal);
  
  const [openAddUserCenterAccessModal, setAddUserCenterAccessModal] = useState(false);
  const toggleAddUserCenterAccessModal = () => setAddUserCenterAccessModal(!openAddUserCenterAccessModal);
  

  const  [selectedUser, setSelectedUser] = useState({});
  const noUserFoundMessage = (
    <div className="userDefatulDetails">
      <img className="center" src={exclamationGreyCircle} />
      <div className="details">No application user to show !</div>
    </div>
  );
  
  const userDetails = props.getApplicationUserDetailsSuccess?.data;
  props.getApplicationUserDetailsSuccess?.data?.centers?.forEach((center) => {
    if (center.isDefault && !defaultCenter) {
      setDefaultCenter(center.name);
    }
  });

  const onEditUserClick= () => {
    setSelectedUser({
      name: userDetails.name,
      loginId: userDetails.loginId,
      role: userDetails.role,
      defaultCenter: defaultCenter,
      userId: userDetails.id,
      sfid: userDetails.sfid
    });
    setEditAccessManagementUserDetailsModal(true);
  };

  const onDeleteUserClick = () => {
    setSelectedUser({
      name: userDetails.name,
      loginId: userDetails.loginId,
      role: userDetails.role,
      defaultCenter: defaultCenter,
      userId: userDetails.id,
      sfid: userDetails.sfid
    });
    setRemoveUserPortalAccessModal(true);
    
  };

  const toggleSuccessModal= (isOpen, modal) => {
    if(modal==='EditUserDetails'){
      setSuccessModalHeader(t('editSuccessHeader'));
      setSuccessModalContent(t('editSuccessContent').replace("%u%",userDetails.name));
    }
    else if(modal==="RemoveUserCenterAccessModal"){
      setSuccessModalHeader(t('removeUserCenterSuccessHeader'));
      setSuccessModalContent(t('removeUserCenterSuccessContent').replace("%c%",selectedCenter.name)+" "+userDetails.name+".");
    }
    else if(modal==="UpdateUserDefaultCenterModal"){
      setSuccessModalHeader(t('updateUserDefaultCenterSuccessHeader'));
      setSuccessModalContent(t('updateUserDefaultCenterSuccessContent').replace("%c%",selectedCenter.name).replace("%u%",userDetails.name));
    }
    else if(modal==="RemoveUserPortalAccessModal"){
      setSuccessModalHeader(t('removeUserPortalAccessSuccessHeader'));
      setSuccessModalContent(t('removeUserPortalAccessSuccessContent').replace("%u%",userDetails.name));
    }
    else if(modal==="AddUserCenterAccessModal"){
      setSuccessModalHeader(t('addUserCenterAccessModalSuccessHeader').replace("%u%",userDetails.name));
      setSuccessModalContent(t('addUserCenterAccessModalSuccessContent').replace("%u%",userDetails.name));
    }
    setAccessManagementSuccessModal(isOpen);
    
  };
  useEffect(()=>{
    if(!openAccessManagementSuccessModal){
      if(loginId){
        props.getApplicationUserDetails(loginId);
      }
    }
  },[openAccessManagementSuccessModal]);

// remove user center access
  function removeCenterAccess(center) {
    setRemoveUserCenterAccessModal(true);
    
    setSelectedCenter(center);
  };

  //update User Default Center
  function updateDefaultCenter(center) {
    if(!center.isDefault){
      setSelectedUser({
        name: userDetails.name,
        loginId: userDetails.loginId,
        role: userDetails.role,
        defaultCenter: defaultCenter,
        userId: userDetails.id,
        sfid: userDetails.sfid
      });
      setSelectedCenter(center);
      setUpdateUserDefaultCenterModal(true);
    }
  };

  //Add new center access
  const addUserCenterAccess = () => {
    setSelectedUser({
      name: userDetails.name,
      loginId: userDetails.loginId,
      role: userDetails.role,
      defaultCenter: defaultCenter,
      userId: userDetails.id,
      sfid: userDetails.sfid
    });
    setAddUserCenterAccessModal(true);
  };

  return  userDetails ? (
    <Container fluid className="AccessManagementUserDetails">
      <Grid className="Margin__bottom--lg">
        <Grid.Row>
          <Grid.Column width="8">
            <Header size="tiny" className="HeaderPrimary">
              APPLICATION USER
            </Header>
            <Header size="large" className="HeaderPrimary">
              <span>{userDetails.name}</span>
            </Header>
          </Grid.Column>
          <Grid.Column width="8">
            <div>
              <PrimaryButton
                text="Edit"
                classType='Inverted'
                className="Float--right Button__background--color"
                onClick={onEditUserClick}
              ></PrimaryButton>
              <PrimaryButton
                text="Delete"
                className="Float--right deleteaccess"
                onClick={onDeleteUserClick}
              ></PrimaryButton>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* User Details */}
      <Grid className="Margin__bottom--lg">
        <Grid.Row className="Margin__bottom--sm">
          <Grid.Column width="8">
            <Header size="medium" className="HeaderPrimary">
              User Details
            </Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <span>Name</span>
            <InputField
              disabled={true}
              fluid={true}
              value={userDetails.name}
              className="Input__background--color Input__position"
            ></InputField>
          </Grid.Column>
          <Grid.Column width={8}>
            <span>Role</span>
            <InputField
              disabled={true}
              fluid={true}
              value={userDetails.role}
              className="Input__background--color Input__position"
            ></InputField>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8}>
            <span>Default Center</span>
            <InputField
              disabled={true}
              fluid={true}
              value={defaultCenter}
              className="Input__background--color Input__position"
            ></InputField>
          </Grid.Column>
          <Grid.Column width={8}>
            <span>Employee Email ID</span>
            <InputField
              disabled={true}
              fluid={true}
              value={userDetails.loginId}
              className="Input__background--color Input__position"
            ></InputField>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {/* Center Access */}
      <Grid>
        <Grid.Row className="Margin__bottom--sm">
          <Grid.Column width="8">
            <Header size="medium" className="HeaderPrimary">
              Center Access
            </Header>
          </Grid.Column>
          <Grid.Column width="8">
            <PrimaryButton
              text="Add"
              classType='Inverted'
              className="Float--right Button__background--color"
              onClick={addUserCenterAccess}
            ></PrimaryButton>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column size="16" className="tableWrap">
            <Table basic="very" size="small">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Center Name</Table.HeaderCell>
                  <Table.HeaderCell>Role</Table.HeaderCell>
                  <Table.HeaderCell>Default Center</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                {userDetails?.centers.map((center) => {
                  return (
                    <Table.Row key={center.id}>
                      <Table.Cell>{center.name}</Table.Cell>
                      <Table.Cell>{userDetails.role}</Table.Cell>
                      <Table.Cell>
                        <Checkbox   checked={center.isDefault} onClick={() => updateDefaultCenter(center)}/>
                      </Table.Cell>
                      <Table.Cell>
                        {!center.isDefault && <IconButton name="trash alternate outline" handleOnClick={() => removeCenterAccess(center)} />}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      <EditAccessManagementUserDetailsModal open={openEditAccessManagementUserDetailsModal} selectedUser={selectedUser} toggleSuccessModal={toggleSuccessModal} toggleModal={toggleEditAccessManagementUserDetailsModal}/>
      <RemoveUserCenterAccessModal open={openRemoveUserCenterAccessModal} selectedCenter={selectedCenter} toggleSuccessModal={toggleSuccessModal} toggleModal={toggleRemoveUserCenterAccessModal}/>
      <UpdateUserDefaultCenterModal open={openUpdateUserDefaultCenterModal} selectedCenter={selectedCenter} selectedUser={selectedUser} toggleSuccessModal={toggleSuccessModal} toggleModal={toggleUpdateUserDefaultCenterModal}/>
      <RemoveUserPortalAccessModal open={openRemoveUserPortalAccessModal} selectedUser={selectedUser} toggleSuccessModal={toggleSuccessModal} toggleModal={toggleRemoveUserPortalAccessModal}/>
      <AddUserCenterAccessModal open={openAddUserCenterAccessModal} selectedUser={selectedUser} toggleSuccessModal={toggleSuccessModal} toggleModal={toggleAddUserCenterAccessModal}/>
      <AccessManagementSuccessModal
        open={openAccessManagementSuccessModal}
        toggleModal={toggleAccessManagementSuccessModal}
        isToggleRequired={false}
        header={successModalHeader}
        content={successModalContent}
        />
        {(props.getApplicationUserDetailsLoading) && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
    </Container>
  ) : (
    <Visibility>
      {noUserFoundMessage}
      {(props.getApplicationUserDetailsLoading) && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
    </Visibility>
    
  );
};

const mapStateToProps = (state) => ({
  getApplicationUserDetailsLoading:
    state.centers.getApplicationUserDetailsLoading,
  getApplicationUserDetailsSuccess:
    state.centers.getApplicationUserDetailsSuccess,
  getApplicationUserDetailsFailure:
    state.centers.getApplicationUserDetailsFailure,
});

const mapDispatchToProps = (dispatch) => ({
  getApplicationUserDetails: (loginId) =>
    dispatch(centerActions.getApplicationUserDetails(loginId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccessManagementUserDetails);