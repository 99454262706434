import React, { useState, useEffect } from 'react'
import { Grid, Container, Button, Header } from 'semantic-ui-react'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
const {isMobileWeb} = importer('Helpers/Utils', null)

const HorizontalCalendarDayOfWeek = importer('Components/Dashboard/HorizontalCalendarDayOfWeek')
const CenterSelection = importer('Components/Dashboard/CenterSelection')

const getYear = (monday, sunday) =>
  moment(monday).year() === moment(sunday).year()
    ? moment(monday).format('YYYY')
    : `${moment(monday).format('YYYY')} / ${moment(sunday).format('YYYY')}`

const getMonth = (monday, sunday) =>
  moment(monday).month() === moment(sunday).month()
    ? moment(monday).format('MMMM')
    : `${moment(monday).format('MMMM')} / ${moment(sunday).format('MMMM')}`


const generateWeek = (date) => {
  const daysAfterMonday = moment(date).weekday() - 1;
  const monday = moment(date).add(isMobileWeb() ? 0 : -daysAfterMonday, 'days');
  const weekDays = [];

  for (let dayCounter = 0; dayCounter < (isMobileWeb() ? 4 : 7); dayCounter++) {
    weekDays.push(moment(monday).add(dayCounter, 'days'));
  }

  const sunday = weekDays[isMobileWeb() ? 3 : 6];
  const month = getMonth(monday, sunday);
  const year = getYear(monday, sunday);

  const week = { year, month, weekDays };
  return week;
};

const formatMonthYear = (month, year) => {
  if (year.includes('/') && month.includes('/')) {
    const years = year.split(' / ')
    const months = month.split(' / ')
    return `${months[0]} ${years[0]} / ${months[1]} ${years[1]}`
  }
  return `${month} ${year}`
}

const HorizontalCalendar = (props) => {
  const {i18n} = useTranslation('common')
  const { onDateChange, appointment, handleAppointment, edit, centers, donor, setAppointment, center, centerSlotsFailure } = props
  const [selectedDate, setSelectedDate] = useState(appointment.startDateTime || moment().format('YYYY-MM-DDT00:00:00'))
  const [currentDate, setCurrentDate] = useState(selectedDate)
  const [currentWeek, setCurrentWeek] = useState(generateWeek(selectedDate))
  const [centerList, setCenterList] = useState(centers)
  const { t } = useTranslation('HorizontalCalendar');

  useEffect(() => {
    setCurrentDate(moment(selectedDate.split('T')[0]).format())
  }, [])

  useEffect(() => {
    if (appointment.startDateTime) {
      setSelectedDate(moment(appointment.startDateTime.split('T')[0]).format())
    }
    setCurrentWeek(generateWeek(currentDate))
    setAppointment && setAppointment(prevState => ({
      ...prevState,
      reScheduledStartDate: moment(currentDate).format('MMMM D, YYYY')
    }))
  }, [currentDate,i18n.language])

  useEffect(() => {
    if (onDateChange) {
      onDateChange(moment(selectedDate))
    }
  }, [selectedDate])

  useEffect(() => {
    setCenterList(centers)
  }, [centers])

  const clickSlotDateHandler = (wd) => {
    handleAppointment({
      ...appointment,
      startDateTime: moment(wd).startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
      date: moment(wd).format('MMMM DD, YYYY'),
      weekDay: moment(wd).format('ddd'),
      startTime: null
    })
  }

  return (
    <div className='HorizontalCalendar'>
      {/*/ *** PD-6836 Move error messages close to calendar/  *** */}
      <h2 className="SelectYourDate">{t('SelectYourDate')}</h2>
      <Header as='h2' className='MonthHeader'>
        {formatMonthYear(currentWeek.month, currentWeek.year)}
      </Header>
      <div className='CalendarSlider'>
        <Grid columns={3}>
          <Grid.Column computer={2} className="CalendarSliderChevron">
            <Button circular icon='chevron left' onClick={() => setCurrentDate(moment(currentDate).add(isMobileWeb() ? -4 : -7, 'days'))} />
          </Grid.Column>
          <Grid.Column computer={12}>
            <Container>
              <Grid columns='equal'>
                {currentWeek.weekDays.map((wd) => (
                  <Grid.Column key={wd.date()} className='CalendarDays'>
                    <HorizontalCalendarDayOfWeek
                      centerSlotsFailure={centerSlotsFailure}
                      dayOfWeek={moment(wd).format('dddd')}
                      dayOfMonth={moment(wd).format('DD')}
                      isSeledted={moment(wd).format('YYYY-MM-DD') === moment(selectedDate).format('YYYY-MM-DD')}
                      onClick={() => {
                        setSelectedDate(wd)
                        clickSlotDateHandler(wd)
                      }}
                    />
                  </Grid.Column>
                ))}
              </Grid>
            </Container>
          </Grid.Column>
          <Grid.Column computer={2} className="CalendarSliderChevron">
            <Button circular icon='chevron right' onClick={() => setCurrentDate(moment(currentDate).add(isMobileWeb() ? 4 : 7, 'days'))} />
          </Grid.Column>
        </Grid>
      </div>
      <CenterSelection
        appointment={appointment}
        handleAppointment={handleAppointment}
        edit={edit}
        centers={centerList}
        donor={donor}
        center={center}
        setAppointment={setAppointment}
      />
    </div>
  )
}
export default HorizontalCalendar
