import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, Loader } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { useTranslation } from 'react-i18next';
const AppContainer = importer('Containers/App')
const ReportsSearchResults = importer('Components/CenterOps/ReportsSearchResults')
const ReportSearchForm = importer('Components/Forms/ReportSearchForm')
const Footer = importer('Components/Footer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')
const centersActions = importer('Stores/Centers/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const PER_PAGE = 10


const CenterOpsReports = ({
  donorLoading,
  operations,
  operationsLoading,
  user,
  getDonors,
  getAppointmentReport,
  downloadReport,
  operationsFailure
}) => {
  const { t } = useTranslation('CenterOpsReports');
  const [searched, setSearched] = useState(false)
  const [params, setParams] = useState({})
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(0)

  const options = [
    { key: 'All', value: 'ALL', text: t('all') },
    { key: 'Physicals', value: 'PHYSICAL', text: t('PhysicalWithDonation') },
    { key: 'Donation', value: 'DONATION', text: t('donation') }
  ]
  useEffect(() => {
    getDonors(user.id)
  }, [])

  useEffect(() => {
    if (operations?.totalRecords >= 0) {
      const total = Math.ceil(operations.totalRecords / PER_PAGE)
      setTotalPages(total)
    }
  }, [operations])

  const handleSearch = ({ start_date, end_date, appointment_type, page = 1 }) => {
    const { defaultCenter } = user
    const payload = {
      start_date,
      end_date,
      center_sfid: defaultCenter.sfid,
      appointment_type,
      limit: PER_PAGE,
      page
    }
    getAppointmentReport(
      start_date,
      end_date,
      defaultCenter.sfid,
      appointment_type === 'ALL' ? undefined : appointment_type,
      PER_PAGE,
      page
    )
    setPage(page)
    setSearched(true)
    setParams(payload)
  }

  const handleExport = (fileType) => {
    params.appointment_type === 'ALL' ? downloadReport(
      params.start_date,
      params.end_date,
      params.center_sfid,
      undefined,
      fileType
    )
    :
    downloadReport(
      params.start_date,
      params.end_date,
      params.center_sfid,
      params.appointment_type,
      fileType
    )

  }

  const handleChangePage = (_, { activePage }) => {
    handleSearch({
      start_date: params.start_date,
      end_date: params.end_date,
      appointment_type: params.appointment_type,
      page: activePage
    })
  }

  const handleClear = () => {
    setSearched(false)
    setPage(1)
    setParams({})
  }

  const loading = donorLoading || operationsLoading
  return (
    <AppContainer compact>
      <div className='MainContainer CenterOpsContainer'>
        <Container fluid>
          <ReportSearchForm
            onSearch={handleSearch}
            onClear={handleClear}
            pageName={t('reports')}
            options={options}
          />
          {operationsFailure && <FailureState message = {operationsFailure && operationsFailure?.response?.data?.message}/>}
          {loading ? (
            <Loader active />
          ) : (
            <ReportsSearchResults
              page={page}
              totalPages={totalPages}
              searched={searched}
              operations={operations}
              onExport={handleExport}
              onChangePage={handleChangePage}
            />
          )}
        </Container>
      </div>
      <Footer />
    </AppContainer>
  )
}

const mapStateToProps = ({ users, donors, centers }) => ({
  user: users.loginHerokuSuccess,
  operations: centers.getAppointmentReportSuccess?.data,
  operationsLoading: centers.getAppointmentReportLoading,
  operationsFailure: centers.getAppointmentReportFailure,
  donor: donors.donors?.data,
  donorLoading: donors?.getDonorsLoading
})

const mapDispatchToProps = dispatch => ({
  getDonors: id => dispatch(donorsActions.getDonors(id)),
  getAppointmentReport: (
    fromDateTime,
    toDateTime,
    centerSfid,
    appointmentType,
    limit,
    page
  ) =>
    dispatch(
      centersActions.getAppointmentReport(
        'appointmentsReport',
        fromDateTime,
        toDateTime,
        centerSfid,
        appointmentType,
        limit,
        page
      )
    ),
  downloadReport: (
    fromDateTime,
    toDateTime,
    centerSfid,
    appointmentType,
    fileType,
    limit,
    page
  ) =>
    dispatch(
      centersActions.downloadReport(
        'appointmentsReport',
        fromDateTime,
        toDateTime,
        centerSfid,
        appointmentType,
        fileType,
        limit,
        page
      )
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(CenterOpsReports)
