import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import './styles.scss'
import { Sidebar, Menu, Icon, Dropdown } from 'semantic-ui-react'

const publicContentActions = importer('Stores/PublicContent/Actions')
import importer from '@takedapdt/biolife-core/src/importer'
// import { openCookieSettingsModal } from '../../Helpers/Utils'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const MobileLanguageSelector = importer('Components/MobileLanguageSelector')
const SideMenuHeader = importer('Components/SideMenuHeader')
const StartupActions = importer('Stores/Startup/Actions')
import { CenterOpsUserRole } from '../../Constant/centerOps_enums';

const SideBarMenu = ({
  sidebarOpened,
  handleToggleSidebar,
  loggedInUser,
  signOut,
  initializeApp,
  parsedMenuItems,
  donor
}) => { 
  const { t, i18n } = useTranslation('FactoryFunctionTexts','SideBarMenu');
  const [openMenu, setOpenMenu] = useState('')
  const [isOpen, setIsOpen] = useState('')
  const [isSubMenuClicked , setIsSubMenuClicked]= useState(false)
  const [dashboardMenuItems, setDashboardMenuItems] = useState(parsedMenuItems?.dashBoard)
  const [centerOpsData, setCenterOpsData] = useState("");
  const history = useHistory()

  const aboutBiolifeData= parsedMenuItems?.aboutBiolife
  const aboutPlasmaData= parsedMenuItems?.aboutPlasma
  const becomeADonorData= parsedMenuItems?.becomeDonor
  const currentDonorData = parsedMenuItems?.currentDonor

  useEffect(()=>{
    if(loggedInUser?.systemRole===CenterOpsUserRole.MANAGER)
    {
      setCenterOpsData(parsedMenuItems?.centerOps);
    }else{
      setCenterOpsData(parsedMenuItems?.centerOps.filter((item)=>item.id!=='75'));
    }
  },[]);

  const handleOpenMenu = (menuName) => {
    if (isOpen !== menuName) {
      setOpenMenu(menuName)
      setIsOpen(menuName)
    } else {
      setOpenMenu('')
      setIsOpen('')
    }
  }
  const changeLanguage = (lang) => {
    changeLanguageHelper(lang)
    initializeApp()
  }
  const handleToggleLocal= (value)=>{
    if(value === 'subMenu'){
      setIsSubMenuClicked(true)
      handleToggleSidebar()
    } else if (!isSubMenuClicked){
      handleToggleSidebar()
      setIsSubMenuClicked(false)
    } else{
      setIsSubMenuClicked(false)
    }
  }

  const subMenuHelper = (items) =>
		items?.map((item, index) => {
			if (item.external || item.to.includes('http')) {
				return (
					<Menu.Item
						as="a"
						name={item.name}
						content={item.name}
						to={item.to}
						onClick={() => handleToggleLocal('subMenu')}
						key={item.id}
						href={item.to}
						index={index}
						target="_blank"
					/>
				);
			}
			return (
				<Menu.Item
					as={NavLink}
					name={item.name}
					content={item.name}
					key={index}
					to={item.to}
					onClick={() => handleToggleLocal('subMenu')}
				/>
			);
		});

    useEffect(() => {
      let menuItems = parsedMenuItems?.dashBoard
      if((donor?.isLoyaltyMember === "No" || donor?.isLoyaltyMember === null) && !donor?.defaultCenter?.isLoyaltyProgramEnabled){
        menuItems = parsedMenuItems?.dashBoard?.filter(item => item.page !== "my-rewards") 
      }
      if (loggedInUser?.role === 'Donor' && (donor?.defaultCenter === null || !donor?.defaultCenter?.isGiftcardEnabled)) {
        menuItems = menuItems.filter(item => item.page !== "gift-card-portal")
      }
      setDashboardMenuItems(menuItems)
    }, [parsedMenuItems])
    
  return (
    <Sidebar
      as={Menu}
      className='SideBar'
      animation='overlay'
      onHide={handleToggleLocal}
      vertical
      visible={sidebarOpened}
      width='wide'>
      <SideMenuHeader handleToggleSidebar={()=>handleToggleLocal('subMenu')}/> 
      <MobileLanguageSelector/>

      {loggedInUser && loggedInUser.loginId ? (
        <>
          <Menu.Item
            className='MenuHeader'
            as={NavLink} to="#"
            onClick={() => {
              handleOpenMenu('Welcome')
            }}>
            {t('SideBarMenu:welcome')}
            {openMenu === 'Welcome' ? <Icon name='chevron up' /> : <Icon name='chevron down' />}
          </Menu.Item>
          {openMenu === 'Welcome' && (
            <Menu.Menu className='SidebarSubMenu'>
              {loggedInUser && loggedInUser.role === 'Admin'? subMenuHelper(centerOpsData) : subMenuHelper(dashboardMenuItems)}
              {loggedInUser && loggedInUser.role === 'Donor' &&
              <Menu.Item name={t('SideBarMenu:logOut')} onClick={() => signOut()} className='VerticalMenuItem' content={t('SideBarMenu:logOut')} /> }
            </Menu.Menu>
          )}
        </>
      ) : (
        <>
          <Menu.Item className='MenuHeader' as={NavLink} to='/login'>
            {t('SideBarMenu:logIn')}
          </Menu.Item>
        </>
      )}
    </Sidebar>
  )
}

const mapStateToProps = (state) => ({
  parsedMenuItems: state.publicContent?.parsedMenuItems,
  donor: state.donors.donors?.data
})

const mapDispatchToProps = dispatch => ({
  initializeApp: () => dispatch(StartupActions.initializeApp()),

})

export default connect(mapStateToProps, mapDispatchToProps)(SideBarMenu)
