import React from 'react'
import iGrid from '../../../assets/media/images/icon-grid.png'
import './styles.scss'
import { Grid, Image, Responsive } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const SignUpFields = importer('Components/Forms/SignUpFields')
const LazyImage = importer('Components/LazyLoading')
const MarketingBlockTop = ({ mainContent, mainImage, imageAltText, imageRedirectUrl }) => {
  return (
    <Grid.Row columns={2}>
      <Grid.Column className='MarketingTextColumn hideInMobileTab' mobile={16} tablet={16} computer={9}>
        <div className='MarketingTextBlock' dangerouslySetInnerHTML={{ __html: mainContent?.content__c }}/>
        <SignUpFields />
      </Grid.Column>
      <Grid.Column
        computer={7}
        mobile={16}
        tablet={16}
        className='MarketingTextColumn'
      >
        <div className='ImageContainer'>
          {imageRedirectUrl ? 
            <a href={imageRedirectUrl}>
              <LazyImage src={mainImage} alt={imageAltText} className='bannerImg'/>
            </a> : 
            <LazyImage src={mainImage} alt={imageAltText} className='bannerImg'/>
          }
          <div className='IconGridContainerTop'>
            <LazyImage src={iGrid} />
          </div>
          <div className='IconGridContainerBottomRight'>
            <LazyImage src={iGrid} />
          </div>
        </div>
      </Grid.Column>
      <Grid.Column className='MarketingTextColumn HideInDesktop' mobile={16} tablet={16} computer={8}>
        <div className='MarketingTextBlock' dangerouslySetInnerHTML={{ __html: mainContent?.content__c }}/>
        <SignUpFields />
      </Grid.Column>
    </Grid.Row>
  )
}

export default MarketingBlockTop
