import React, { useEffect, useState} from 'react'
import { connect } from "react-redux";
import { Container, Header, Image, Dimmer, Loader, Modal, Grid } from 'semantic-ui-react'
import HeroImage from '../../../assets/media/images/hero-image-headquarters.jpg'
import { NavLink, useHistory } from 'react-router-dom'
import TagManager from 'react-gtm-module'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import AppAdvertisement from '../AppAdverstiment'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const AppAdverstiment = importer('Components/AppAdverstiment')
const { ArticleByIdentifier } = importer("Stores/PublicContent/Select", null);
const { webArticles } = importer("Helpers/WebArticles");

const HeroEmailConfirmation = ({
  error,
  success,
  loading,
  webContents
}) => {
  const { t, i18n } = useTranslation('UserVerification');

  const history = useHistory()
  const [ registrationId, setRegistrationId] = useState(null)
  const [ pid, setPid] = useState(null)
  const [ showModal, setShowModal] = useState(false)
  useEffect(() => {
    if(success && !loading) {
      // TagManager.dataLayer({
      //   dataLayer: {
      //     event: 'profileRegistration',
      //     profileRegistrationStage: 'account created'
      //   }
      // })
    }
  }, [success, loading])

  useEffect(() => {
    if(error?.code && error.code == 'TokenExpired') {
      setRegistrationId(error.sfid)
      setPid(error.pid)
    }
  }, [error])

  const navigateToNextStep = ()=>{
    if(registrationId){
      history.push({
        pathname: "/create-account",
        registrationId: registrationId,
        pid:pid
      });
    }else if(error?.code && error.code == 'TokenExpired'){
      setShowModal(true)
    }
    else {
      history.push({pathname:"/login"})
    }
  }

  const emailVerificationContent = ArticleByIdentifier(
    webContents,
    webArticles.POST_EMAIL_VERIFICATION_CONTENT
  );

  return (
    <div className='UserVerification'>
      <div className='HeroContent'>
        {success && !loading && (
          <div className='HeroImageContainer'>
            <Image src={HeroImage} alt='Hero Image' />
          </div>
        )}
        <Container>
          <div className='UserVerificationTextContainer'>
            {loading && (
              <Dimmer active>
                <Loader />
              </Dimmer>
            )}
            {success && !loading && (
              <div className='SuccessContainer'>
                <Header as='h1' className='UserVerificationHeader'>
                  {t('registrationCompleted')}
                </Header>
                <p className='UserVerificationRegularText'>
                 {t('youCanLogin')}
                </p>
              </div>
            )}
            {error && !loading && (
              <div className='ErrorContainer'>
                <Header as='h1' className='UserVerificationHeader'>
                  {t('linkExpired')}
                </Header>
                <p className='UserVerificationRegularText'>
                  {t('completeRegistration')}
                </p>
              </div>
            )}
            <PrimaryButton
              text={error ? t('continue') : t('scheduleYourFirstAppointment')}
              height={50}
              fontSize={16}
              lineHeight='25px'
              classType='Primary'
              onClick={navigateToNextStep}
              
            />
             <div
              dangerouslySetInnerHTML={{ __html: emailVerificationContent?.content__c  }}
            />
            {success && !loading && (
                <div className='AppAdvertisement'>
                  <AppAdvertisement/>
                </div>
            )}
          </div>
          <Modal open={showModal} style={{textAlign: 'center', width:'500px'}}>
              <Modal.Content >
                <p style={{fontSize:16}}>
                  {t('invaildDetails')}
                </p>
                <PrimaryButton
                  text={t('ok')}
                  width={150}
                  height={50}
                  fontSize={16}
                  classType='Primary'
                  onClick={() => {
                    setShowModal(false)
                  }}
                />
              </Modal.Content>
            </Modal>
        </Container>
      </div>
    </div>
  )
}

const mapStateToProps = ({ publicContent }) => ({
  webContents: publicContent.WebArticles?.completeArticles,
});

export default connect(mapStateToProps, {})(HeroEmailConfirmation);
