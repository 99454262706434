import React from 'react'
import iGrid from '../../../assets/media/images/icon-grid.png'
import './styles.scss'
import { Grid, Image } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
const LazyImage = importer('Components/LazyLoading')
const MarketingBlockBottom = ({ mainContent, mainImage, imageAltText, imageRedirectUrl }) => {
  return (
  <Grid.Row columns={2}>
    <Grid.Column computer={7} tablet={16} mobile={16} className='HealthTextColumn'>
      <div className='ImageContainer'>
        {imageRedirectUrl ? 
          <a href={imageRedirectUrl}>
            <LazyImage src={mainImage} alt={imageAltText} className='bannerImg'/>
          </a> :
          <LazyImage src={mainImage} alt={imageAltText} className='bannerImg'/>
        }
        <div className='IconGridContainerTopRight'>
          <LazyImage src={iGrid} />
        </div>
        <div className='IconGridContainerBottomTop'>
          <LazyImage src={iGrid} />
        </div>
      </div>
    </Grid.Column>
    <Grid.Column computer={9} tablet={16} mobile={16} className='HealthTextColumn'>
      <div className='HealthTextBlock' dangerouslySetInnerHTML={{ __html: mainContent?.content__c }} />
    </Grid.Column>
  </Grid.Row>
  )
}

export default MarketingBlockBottom
