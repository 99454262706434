import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { Dimmer, Loader } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
/* ACTIONS */
const UserActions = importer('Stores/Users/Actions')
/* CONTAINERS */
const AppContainer = importer('Containers/App')

const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { pageMetaArticles } = importer('Helpers/menuBuilder')
export class LoginAuth extends Component {
  componentDidMount () {
    var urlString = window.location.href
    var url = new URL(urlString)
    var code = url.searchParams.get('code')
    if (code) {
      var x = document.cookie
      // console.log('Cookies')
      // console.log(x)
      this.props.tokenExchange(code)
    }
  }

  render () {
    const { t, webArticles } = this.props;

    const signInAuthMeta =  ArticleByIdentifier(
      webArticles,
      pageMetaArticles.SIGN_IN_AUTH_META
    )
    return (
      <AppContainer style={{ height: '100%' }}>
        {!isEmpty(signInAuthMeta) &&
          <Helmet>
          <title>{`${signInAuthMeta?.meta_title__c}`} </title>
          <meta name="description" content= {`${signInAuthMeta?.meta_description__c}`} />
        </Helmet>}
        <Dimmer active style={{ height: '100%' }}>
          <Loader indeterminate>{t('talkingToServers')}</Loader>
        </Dimmer>
      </AppContainer>
    )
  }
}

const mapStateToProps = (state) => ({
  webArticles: state.publicContent.WebArticles?.completeArticles
})

const mapDispatchToProps = dispatch => ({
  tokenExchange: (code) => dispatch(UserActions.loginTokenExchange(code))
})

export default connect(
  mapStateToProps,
   mapDispatchToProps)
   (withTranslation('SignInAuth')
   (LoginAuth))
