import React from "react";
import { Container, Image } from "semantic-ui-react";
import HeroImage from "../../../assets/media/images/hero-image-headquarters.jpg";

import "./styles.scss";
import importer from "@takedapdt/biolife-core/src/importer";
const RegistrationWizardSteps = importer("Components/RegistrationWizardSteps");

const HeroEmailConfirmation = ({ content, registrationStep3Heading }) => {

  return (
    <div className="HeroEmailConfirmation">
      <div>
        <div
          dangerouslySetInnerHTML={{
            __html: registrationStep3Heading?.content__c,
          }}
        ></div>
      </div>
      <Container>
        <RegistrationWizardSteps step={3} />
      </Container>
      <div className="HeroContent">
        <div dangerouslySetInnerHTML={{ __html: content?.content__c }} />
      </div>
    </div>
  );
};

export default HeroEmailConfirmation;
