import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'

const HeroSignUp = importer('Components/HeroSignUp')
const Testimonials = importer('Components/Testimonials')

const SignUp = () => {
  return (
    <div className='RegistrationWizard'>
      <HeroSignUp />
      <Testimonials />
    </div>
  )
}

export default SignUp
