import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import { setErrorMessageTitle, splitCenterName } from '../../../Helpers/Utils'
import moment from 'moment'
import { connect } from 'react-redux'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const AppointmentTypeAndPlayroom = importer('Components/Dashboard/AppointmentTypeAndPlayroom')
const AppointmentHours = importer('Components/Dashboard/AppointmentHours')
const appointmentsActions = importer('Stores/Appointments/Actions')
const HorizontalCalendar = importer('Components/Dashboard/HorizontalCalendar')
const {isMobileWeb} = importer('Helpers/Utils', null)
// Actions

const centersActions = importer('Stores/Centers/Actions')

const ModifyAppointmentModal = ({
  open,
  toggleModal,
  appointment,
  handleAppointment,
  getSlotsForAppointmentType,
  centerSlots,
  centerSlotsFailure,
  centerSlotsLoading,
  centers,
  toggleConfirmModal,
  center,
  donor,
  loggedInUser,
  searchedCenters,
  getZipcodeSearchCenters,
  inquiry
}) => {
  const [modified, setModified] = useState(false)
  const [isNewSlotSelected, setIsNewSlotSelected] = useState(false)
  const { t } = useTranslation('ModifyAppointmentModal');
  const shouldShowFailure = centerSlotsFailure?.code === 'DayIsOutsideSchedulingWindowRule' || centerSlotsFailure?.code === 'DayIsInPastPotentialAppointmentDateRule';
  useEffect(() => {
    if (donor && !appointment.startTime && appointment.startDateTime && appointment.appointmentType) {
      setIsNewSlotSelected(false)
      getSlotsForAppointmentType(
        appointment.startDateTime,
        null,
        null,
        appointment.numberOfChildren || 0,
        appointment.centerSfid,
        appointment.appointmentType,
        appointment.appointmentId,
        donor.sfid
      )

    }
  }, [appointment])

  useEffect(() => {
    if (open) { setModified(true) }
    getZipcodeSearchCenters(donor?.zipCode, true, 'disDate', donor?.donationCenter, true)      // true corresponds to addNewDonorBonusInformation
  }, [open])
  useEffect(() => {
    if (appointment?.appointmentType !== 'DONATION')
      getZipcodeSearchCenters(donor?.zipCode, true, 'disdate', donor?.donationCenter, true)    // true corresponds to addNewDonorBonusInformation
  }, [appointment.appointmentType])

  const selectNewSlotHandler = (app) => {
    setIsNewSlotSelected(true)
    handleAppointment(app)
  }

  const setErrorMessage = (centerSlotsFailure) => {
    if (centerSlotsFailure.code === 'DonorInquiryRequestTimeout') {
      return (
        <div className='AppointmentHours'>
          <p className='NoResults' style={{ fontSize: '18px' }}>{setErrorMessageTitle(centerSlotsFailure.message).title}</p>
          <p className='NoResults' style={{ fontWeight: 'normal' }}>{setErrorMessageTitle(centerSlotsFailure.message).message}</p>
        </div>)
    } else {
      return (
        <div className='AppointmentHours'>
          <p className='NoResults'>{centerSlotsFailure.message}</p>
        </div>
      )
    }
  }

  const scrolltoCalendar = () => {
    if(isMobileWeb()){
      const HorizontalCalendarElement = document.querySelector(".HorizontalCalendar")
       if(HorizontalCalendarElement){
         HorizontalCalendarElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }

  return (
    <Modal open={open} onClose={toggleModal} className='ModifyAppointmentModal' size='large'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as='h2' className='ModalHeader'>
              {t('modifyAppointment')}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={toggleModal} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
          <AppointmentTypeAndPlayroom
            donor={donor}
            appointment={appointment}
            handleAppointment={handleAppointment}
            edit
            centerSlotsFailure={centerSlotsFailure}
            inquiry={inquiry}
            scrolltoCalendar={scrolltoCalendar}
          />
          <HorizontalCalendar
            appointment={appointment}
            handleAppointment={handleAppointment}
            edit={loggedInUser?.role === 'Admin' ? true : false}
            centers={appointment.appointmentType === 'DONATION' ? centers : searchedCenters}
            donor={donor}
            center={center}
            centerSlotsFailure={centerSlotsFailure}
          />
          {!centerSlotsFailure && (
                            <h2 className='SelectYourAppointment'>{t("SelectYourAppointment")}</h2>
                          )}
          {modified && centerSlotsFailure && shouldShowFailure ? (
                <div className='ModifycenterSlotsFailureContainer'>
                  <div className='centerSlotsFailure'>
                    <p className='centerSlotsFailureMesssage'>{centerSlotsFailure?.message}</p>
                  </div>
                </div>
              ) : (
          <div className='HoursContainer'>
            <Grid centered columns={2}>
              <Grid.Column centered width={10} className='ModifyContainer'>
              {modified && centerSlotsFailure && shouldShowFailure ? (
                <div className='reschedulecenterSlotsFailureContainer'>
                  <div className='centerSlotsFailure'>
                    <p className='centerSlotsFailureMesssage'>{centerSlotsFailure?.message}</p>
                  </div>
                </div>
              ) : (
                <><div>
                        </div><AppointmentHours
                            appointment={appointment}
                            centerSlotsLoading={centerSlotsLoading}
                            handleAppointment={selectNewSlotHandler}
                            toggleModal={toggleModal}
                            availableSlots={centerSlots || []} /></>
              )}
              </Grid.Column>
              {!centerSlotsFailure && (
              <Grid.Column width={6} textAlign='right' className='ModifyContainer'>
                <div className='ModalActions'>
                  <Header as='h3' className='HeaderText'>
                   {t('selectedAppointmentTime')}
                  </Header>
                  <p className='RegularText'>
                    {moment.parseZone(appointment.startDateTime).format('ddd')}, {moment.parseZone(appointment.startDateTime).format('ll')} at{' '}
                    {appointment.startTime && appointment.startTime.toLowerCase()}
                  </p>

                  {center && <Header as='h3' className='HeaderText'>
                    {loggedInUser?.role === 'Admin' ? center.name : splitCenterName(center.name)} {t('center')}
                  </Header>}
                  {center && (
                    <p className='RegularText'>
                      {center.addressLine1}
                      <br />
                      {loggedInUser?.role === 'Admin' ? `${center.city}, ${center.stateCode} ${center.zipcode}`: `${splitCenterName(center.city)}, ${center.stateCode} ${center.zipcode}`}
                    </p>
                  )}

                  <Grid.Row className='ConfirmContainer'>
                    <PrimaryButton
                      text={t('submitAppointmentChange')}
                      disabled={!isNewSlotSelected}
                      width={270}
                      height={50}
                      fontSize={16}
                      classType='Primary'
                      onClick={() => {
                        toggleModal()
                        toggleConfirmModal && toggleConfirmModal()
                      }}
                    />
                  </Grid.Row>
                  <Grid.Row>
                    <PrimaryButton
                      text={t('cancel')}
                      width={150}
                      height={50}
                      fontSize={16}
                      classType='Inverted'
                      onClick={() => {
                        setModified(false)
                        toggleModal()
                      }}
                    />
                  </Grid.Row>
                </div>
              </Grid.Column>
              )}
            </Grid>

          </div>

              )}


        </div>
      </Modal.Content>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors.donors?.data,
  centerSlots: state.centers.getSlotsForAppointmentTypeSuccess?.data,
  centerSlotsFailure: state.centers.getSlotsForAppointmentTypeFailure,
  centerSlotsLoading: state.centers.getSlotsForAppointmentTypeLoading,
  centers: state.centers.donorCenters?.data,
  loggedInUser: state.users?.user,
  searchedCenters: state.centers.getZipcodeSearchCentersSuccess?.data,
  inquiry: state.donors.inquiryDonorSuccess?.data,
})

const mapDispatchToProps = (dispatch) => ({
  getSlotsForAppointmentType: (
    forDate,
    fromDateTime,
    toDateTime,
    numberOfChildren,
    centerSfid,
    appointmentType,
    rescheduleId,
    donorSfid
  ) =>
    dispatch(
      centersActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        rescheduleId,
        donorSfid
      )
    ),
  getZipcodeSearchCenters: (searchStr, isDIS8Center, searchType, defaultCenter, addNewDonorBonusInformation) => dispatch(centersActions.getZipcodeSearchCenters(searchStr, isDIS8Center, searchType, defaultCenter, addNewDonorBonusInformation))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModifyAppointmentModal)
