import React from 'react'
import {Image, Grid} from 'semantic-ui-react'
import { connect } from 'react-redux'
import './styles.scss'
import { useTranslation } from 'react-i18next';
import webPromotionHeader from './Images/Web/promotion-header.png'
import webStep0 from './Images/Web/step0.png'
import webStep1 from './Images/Web/step1.png'
import webStep2 from './Images/Web/step2.png'
import webStep3 from './Images/Web/step3.png'
import webStep4 from './Images/Web/step4.png'

import mobilePromotionHeader from './Images/Mobile/promotion-header-mobile.png'
import mobileStep0 from './Images/Mobile/step0-mobile.png'
import mobileStep1 from './Images/Mobile/step1-mobile.png'
import mobileStep2 from './Images/Mobile/step2-mobile.png'
import mobileStep3 from './Images/Mobile/step3-mobile.png'
import mobileStep4 from './Images/Mobile/step4-mobile.png'

import googlePlay from './Images/badge-gplay.png'
import appStore from './Images/badge-ios.png'
import importer from '@takedapdt/biolife-core/src/importer'
const {isMobileWeb} = importer('Helpers/Utils', null)
const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { webArticles } = importer('Helpers/WebArticles')

export const BiolifeMobileApp = ({publicContent, images}) => {
  const { t } = useTranslation('BiolifeMobileApp');

  const getDeviceTypeLink = () => {
    if(isMobileWeb()){
      if(window.navigator.userAgent.toLowerCase().indexOf("iphone") !== -1){
        return 'https://apps.apple.com/us/app/biolife-plasma-services/id1356589861'
      }
      else if(window.navigator.userAgent.toLowerCase().indexOf("android") !== -1){
        return 'https://play.google.com/store/apps/details?id=com.shire.biolife&hl=en_US'
      }
    }
    else{
      return 'https://apps.apple.com/us/app/biolife-plasma-services/id1356589861'
    }
  }

  const findImage = (name) => {
    const item = images.find(i => i.name__c === name)
    return isMobileWeb() ? item.image_url_mobile__c : item.image_url_desktop__c
  }

  const BIOLIFE_MOBILE_APP_VERBIAGE_1 = ArticleByIdentifier(
    publicContent,
    webArticles.BIOLIFE_MOBILE_APP_VERBIAGE_1
  )
  const BIOLIFE_MOBILE_APP_VERBIAGE_2 = ArticleByIdentifier(
    publicContent,
    webArticles.BIOLIFE_MOBILE_APP_VERBIAGE_2
  )
  const BIOLIFE_MOBILE_APP_VERBIAGE_3 = ArticleByIdentifier(
    publicContent,
    webArticles.BIOLIFE_MOBILE_APP_VERBIAGE_3
  )
  const BIOLIFE_MOBILE_APP_VERBIAGE_4 = ArticleByIdentifier(
    publicContent,
    webArticles.BIOLIFE_MOBILE_APP_VERBIAGE_4
  )
  const BIOLIFE_MOBILE_APP_VERBIAGE_5 = ArticleByIdentifier(
    publicContent,
    webArticles.BIOLIFE_MOBILE_APP_VERBIAGE_5
  )
    
  const stepsData = [
    {id:1, backdropImageSrc: isMobileWeb() ? mobileStep0 : webStep0, text: BIOLIFE_MOBILE_APP_VERBIAGE_1.content__c, imageSrc: findImage('Biolife Mobile App - Step 0'), imgStyles: isMobileWeb() ? {height: 225, transform: 'translate(0px, 25px)'} : {height: 300, transform: 'translate(40px, 80px)'}, textStyles: isMobileWeb() ? {transform: 'translate(50px, 20px)'} : {transform: 'translate(15px, 100px)'}},
    {id:2, backdropImageSrc: isMobileWeb() ? mobileStep1 : webStep1, text: BIOLIFE_MOBILE_APP_VERBIAGE_2.content__c, imageSrc: findImage('Biolife Mobile App - Step 1'), imgStyles: isMobileWeb() ? {height: 375, transform: 'translate(25px, 60px)'} : {height: 550, transform: 'translate(-25px, 0px)'}, textStyles: isMobileWeb() ? {width: '75%', transform: 'translate(15px, 40px)'} : {transform: 'translate(0px, 80px)'}},
    {id:3, backdropImageSrc: isMobileWeb() ? mobileStep2 : webStep2, text: BIOLIFE_MOBILE_APP_VERBIAGE_3.content__c, imageSrc: findImage('Biolife Mobile App - Step 2'), imgStyles: isMobileWeb() ? {height: 375, transform: 'translate(-30px, 45px)'} : {height: 600, transform: 'translate(45px, 0px)'}, textStyles: isMobileWeb() ? {width: '65%', transform: 'translate(130px, -15px)'} :{transform: 'translate(70px, 165px)'}},
    {id:4, backdropImageSrc: isMobileWeb() ? mobileStep3 : webStep3, text: BIOLIFE_MOBILE_APP_VERBIAGE_4.content__c, imageSrc: findImage('Biolife Mobile App - Step 3'), imgStyles: isMobileWeb() ? {height: 375, transform: 'translate(0px, 30px)'} : {height: 600}, textStyles: isMobileWeb() ? {width: '80%', transform: 'translate(15px, -5px)'} : {transform: 'translate(0px, 80px)'}},
    {id:5, backdropImageSrc: isMobileWeb() ? mobileStep4 : webStep4, text: BIOLIFE_MOBILE_APP_VERBIAGE_5.content__c, imageSrc: findImage('Biolife Mobile App - Step 4'), imgStyles: isMobileWeb() ? {height: 375, transform: 'translate(-40px, 35px)'} : {height: 550, transform: 'translate(35px, 0px)'}, textStyles: isMobileWeb() ? {width: '75%', transform : 'translate(100px, 15px)'} : {transform: 'translate(60px, 95px)'}}
  ]

  const VerbiageImageComponent = ({backdropImageSrc, text, imageSrc, imgStyles, textStyles, rightText, index}) => {
    return (
      <div className='ImageContainer' style={{backgroundImage: `url(${backdropImageSrc})`, backgroundRepeat: 'no-repeat', backgroundSize: isMobileWeb() ? 'contain' : 'cover', height: isMobileWeb() ? index === 0 ? 525 : 600 : index === 0 ? 450 : 600, margin: isMobileWeb() ? index === 0 ? '25px auto -50px auto' : 0 : '15px auto' }}>
        <Grid columns={2} stackable>
          {!isMobileWeb() && (
            rightText ? 
            <>
              <Grid.Column>
                <Image src={imageSrc} style={imgStyles} />
              </Grid.Column>
              <Grid.Column>
                <div className='TextContainer' dangerouslySetInnerHTML={{ __html: text }} style={textStyles} />
              </Grid.Column>
            </> :
            <>
              <Grid.Column>
                <div className='TextContainer' dangerouslySetInnerHTML={{ __html: text }} style={textStyles} />
              </Grid.Column>
              <Grid.Column>
                <Image src={imageSrc} style={imgStyles} />
              </Grid.Column>
            </>
          )}
          {isMobileWeb() && 
          <>
            <Grid.Column>
              <Image src={imageSrc} style={imgStyles} />
            </Grid.Column>
            <Grid.Column>
              <div className='TextContainer' dangerouslySetInnerHTML={{ __html: text }} style={textStyles} />
            </Grid.Column>
          </>}
        </Grid>
      </div>
    )
  }



  return (
    <div className='BiolifeMobileAppContainer'>
      <Image 
        src={isMobileWeb() ? mobilePromotionHeader : webPromotionHeader} 
        as='a' 
        href={getDeviceTypeLink()}
        className='PromotionHeaderImage'
      />
      {stepsData.map((item, index) => (
        <VerbiageImageComponent 
          backdropImageSrc={item.backdropImageSrc} 
          text={item.text} 
          imageSrc={item.imageSrc} 
          imgStyles={item.imgStyles} 
          textStyles={item.textStyles} 
          rightText={item.id % 2 != 0} 
          index={index} />
      ))}
      <Grid className='DownloadAppContainer'>
        <Grid.Row>
          <h4 className='DownloadAppText'>{t('downloadAppToday')}</h4>
        </Grid.Row>
        <Grid.Row className='DownloadAppImage'>
          <Grid.Column width={8}>
            <Image 
              src={appStore}
              as='a'
              href='https://apps.apple.com/us/app/biolife-plasma-services/id1356589861'
              target='_blank'
            />
          </Grid.Column>
          <Grid.Column width={8}>
            <Image 
              src={googlePlay} 
              as='a'
              href='https://play.google.com/store/apps/details?id=com.shire.biolife&hl=en_US'
              target='_blank'
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  )
}

const mapStateToProps = state => ({
  publicContent: state.publicContent.WebArticles?.completeArticles,
  images: state.publicContent.AppImages
})

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BiolifeMobileApp)
