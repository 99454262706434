export const donationCenterOfficePlayroomHours = [
  {
    day: 'Sunday',
    office: 'Closed - Closed',
    playroom: 'Closed - Closed'
  },
  {
    day: 'Monday',
    office: 'Closed - Closed',
    playroom: 'Closed - Closed'
  },
  {
    day: 'Tuesday',
    office: '7:00 AM - 7:00 PM',
    playroom: '7:00 AM - 7:00 PM'
  },
  {
    day: 'Wednesday',
    office: '7:00 AM - 7:00 PM',
    playroom: '7:00 AM - 7:00 PM'
  },
  {
    day: 'Thursday',
    office: '7:00 AM - 7:00 PM',
    playroom: '7:00 AM - 7:00 PM'
  },
  {
    day: 'Friday',
    office: '7:00 AM - 7:00 PM',
    playroom: '7:00 AM - 7:00 PM'
  },
  {
    day: 'Saturday',
    office: '7:00 AM - 7:00 PM',
    playroom: '7:00 AM - 7:00 PM'
  }
]
