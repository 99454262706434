import React from 'react'
import { useTranslation } from 'react-i18next';
import { Header, Container, Grid, Image } from 'semantic-ui-react'
import { formatPhoneNumber } from '../../Helpers/Utils'
import facilityBigImage from '../../../assets/media/images/facilityBigImage.jpg'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const Breadcrumbs = importer('Components/Breadcrumbs')

const HeroFacility = (props) => {
  const { t } = useTranslation('HeroFacility');
  const { selectedFacility, parentMenu, selectedState } = props
  return (
    <div className='HeroFacility'>
      <Container fluid>
        <div className='HeroMainTextContainer'>
          <div className='BreadcrumbsContainer'>
            <Breadcrumbs
              page={selectedFacility.name}
              parentMenu={parentMenu}
              parentSubMenu={selectedState}
              color='blue'
            />
          </div>
          <Grid columns={2}>
            <Grid.Column computer={8} mobile={16}>
              <Header as='h4' className='SupHeader'>
                {t('donationCenter')}
              </Header>

              <Header as='h1' className='MainHeader'>
                {selectedFacility.name}
              </Header>
              <div className='Info'>
                <p className='HeroRegularText Address'>
                  {selectedFacility.addressLine1}
                </p>
                <p className='HeroRegularText Address'>
                  {selectedFacility.city}, {selectedFacility.stateCode} {selectedFacility.zipcode}
                </p>
                <p className='HeroRegularText Phone'>
                  <a href={`tel:${selectedFacility.telephone}`}>{formatPhoneNumber(selectedFacility.telephone)}</a>
                </p>
                {selectedFacility.isSpanishSupported && <p className='HeroRegularText isSpanish'>Se Habla Español</p>}
              </div>
            </Grid.Column>
            <Grid.Column computer={8} mobile={16}>
              <div className='FacilityDescription'>
                <div className='HeroRegularText'
                  dangerouslySetInnerHTML={{ __html: selectedFacility.centerDescription }}
                />
              </div>
            </Grid.Column>
          </Grid>
        </div>
      </Container>
      <div className='FacilityImageContainer'>
        <Image src={selectedFacility.webImageUrl || facilityBigImage} alt={selectedFacility.imageAltText} />
      </div>
    </div>
  )
}

export default HeroFacility
