import React from 'react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const ScheduleAppointmentAction = () => {
  const { t } = useTranslation('ScheduleAppointmentAction');
  return (
    <div className='ScheduleAppointmentAction'>
      <PrimaryButton
        text={t('scheduleAppointment')}
        width={408}
        classType='Primary'
        fontSize='16px'
        height={50}
        as={NavLink}
        to='/appointment-scheduling'
      />
    </div>
  )
}

export default ScheduleAppointmentAction
