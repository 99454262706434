export const changeCenterOptions = [
  {
    key: 'florida',
    text: 'FLORIDA',
    value: 'FLORIDA'
  },
  {
    key: 'orlando',
    text: 'Orlando Center',
    value: 'Orlando Center'
  }
]
