import React from 'react'
import { Container } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const RegistrationWizardSteps = importer('Components/RegistrationWizardSteps')

const HeroAccountCreate = (props) => {
  const { t } = useTranslation('HeroAccountCreate');
  return (
    <div className='HeroAccountCreate'>
      <Container>
      <div className='HeroTextContainer' dangerouslySetInnerHTML={{__html: props.createProfilePageHeading?.content__c}} />
        <RegistrationWizardSteps step={2} />
          <div className='HeroTextContainer HeroTextContent' >
            <div
              dangerouslySetInnerHTML={{
                __html: props.eligibilityRequirement.content__c
              }}
            />
          </div>
      </Container>
    </div>
  )
}

export default HeroAccountCreate
