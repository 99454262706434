import React, { useEffect, useState } from 'react'
import { Table } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

const CenterOpsModifyAppointmentModal = importer('Components/CenterOps/CenterOpsModifyAppointmentModal')
const CancelAppointmentModal = importer('Components/CenterOps/CancelAppointmentModal')
const ConfirmRescheduleModal = importer('Components/CenterOps/ConfirmRescheduleModal')
const CellDonorData = importer('Components/CenterOps/CellDonorData')
const CellPhysicalsData = importer('Components/CenterOps/CellPhysicalsData')
const CellPlayroomData = importer('Components/CenterOps/CellPlayroomData')
const QuickPhysicalModal = importer('Components/CenterOps/QuickPhysicalModal')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')

const DaySchedule = (props) => {
  const { t} = useTranslation('DaySchedule'); 

  const calendarSlots = props.calendarSlots
  const centerInfo = props.centerInfo
  const loading = props.loading
  const serviceError = props.serviceError
  const cancelAppointmentHandler = props.cancelAppointmentHandler
  const getSlotsForAppointmentType = props.getSlotsForAppointmentType
  const getSlotsSuccessInfo = props.getSlotsSuccessInfo
  const getSlotsFailureInfo = props.getSlotsFailureInfo
  const getSlotsLoading = props.getSlotsLoading
  const appointmentId = props.appointmentId
  const cancelAppointmentSuccessResponse = props.cancelAppointmentSuccessResponse
  const appointmentDate = props.appointmentDate
  const getAppSlotsHandler = props.getAppSlotsHandler
  const rescheduleAppointmentHandler = props.rescheduleAppointmentHandler
  const reloadCalendar = props.reloadCalendar
  const cancelAppointmentLoading = props.cancelAppointmentLoading
  const cancelApptErrorInfo = props.cancelApptErrorInfo
  const donationCenter = props.donationCenter
  const rescheduleAppointmentFailInfo = props.rescheduleAppointmentFailInfo
  const rescheduleAppointmentSuccessInfo = props.rescheduleAppointmentSuccessInfo
  const rescheduleAppointmentLoading = props.rescheduleAppointmentLoading
  const scheduleQuickAppointmentHandler = props.scheduleQuickAppointmentHandler
  const isScheduleQuickApptLoading = props.isScheduleQuickApptLoading
  const scheduleQuickApptSuccessInfo = props.scheduleQuickApptSuccessInfo
  const scheduleQuickApptErrorInfo = props.scheduleQuickApptErrorInfo

  const [appointment, setAppointment] = useState({
    name: '',
    donationType: '',
    date: '',
    weekDay: '',
    time: '',
    id: '',
    sfid: '',
    numberOfChildren: 0,
    city: centerInfo && centerInfo.city,
    addressLineOne: centerInfo && centerInfo.addressLine1,
    stateCode: centerInfo && centerInfo.stateCode,
    zipcode: centerInfo && centerInfo.zipcode
  })
  const [modifyAppointmentData, setModifyAppointmentData] = useState(null)

  const [openModal, setOpenModal] = useState(false)
  const [openCancelAppointmentModal, setOpenCanceAppointmentModal] = useState(false)
  const [openConfirmRescheduleModal, setOpenConfirmRescheduleModal] = useState(false)
  const [rescheduleAppointmentData, setRescheduleAppointmentData] = useState(null)

  const [openQuickPhysical, setOpenQuickPhysical] = useState(false)

  const toogleQuickPhysicalModal = () => {
    setOpenQuickPhysical(!openQuickPhysical)
  }

  const toggleModal = () => setOpenModal(!openModal)
  const toggleCancelAppointmentModal = () => setOpenCanceAppointmentModal(!openCancelAppointmentModal)
  const toggleConfirmRescheduleModal = () => setOpenConfirmRescheduleModal(!openConfirmRescheduleModal)

  const handleAppointment = app => {
    setAppointment({ ...app })
  }

// updates the 'appointment' state with center details when 'centerInfo' value changes
  useEffect(()=>{
    if(centerInfo){
      setAppointment({...appointment, city: centerInfo.city, addressLineOne: centerInfo.addressLine1, stateCode: centerInfo.stateCode, zipcode: centerInfo.zipcode})
      }
  },[centerInfo])

  // Display API error messages for the GET request
  if(serviceError){
    return (
    <div className='AppointmentHours'>
     <p className='NoResults'>{serviceError.message}</p>
    </div>
    )
  }

  return (
    <div className='DaySchedule'>
      {loading && <LoadingState />}
      <Table celled>
        <Table.Header>
          <Table.Row className='TableHeader'>
            <Table.HeaderCell>{t('time')}</Table.HeaderCell>
            <Table.HeaderCell>{t('donations')}</Table.HeaderCell>
            <Table.HeaderCell>{t('physicals')}</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {calendarSlots && calendarSlots.map(item => (
            <Table.Row
              key={item.id}
              className={ moment(item.appointmentSlotDateTime) < moment(centerInfo.currentDateTime) ? 'past-slot' : null }
              style={{
                display: (
                  moment(item.appointmentSlotDateTime) < moment(centerInfo.currentDateTime).subtract(30, 'minutes') &&
                  moment(item.appointmentSlotDateTime).format('YYYY-MM-DD') === moment(centerInfo.currentDateTime).format('YYYY-MM-DD')
                ) ? 'none' : null
              }}
            >
              <Table.Cell className='TimeCellFuture'>
                {item.appointmentSlotTime}
              </Table.Cell>
              <Table.Cell>
                {item.donations !== false && (
                  <CellDonorData
                    needPlayRoom={item.isPlayroomAvailable}
                    toogleQuickPhysicalModal={toogleQuickPhysicalModal}
                    appointment={appointment}
                    onClickModifyHandler={appointment => setModifyAppointmentData(appointment)}
                    onClickScheduleHandler={appointment => setAppointment(appointment)}
                    toggleModal={toggleModal}
                    donors={item.donations.donors}
                    time={item.appointmentSlotTime}
                    appointmentTime = {item.appointmentSlotDateTime}
                    donationScheduledAppointments = {item.donations.donationScheduledAppointments}
                    donationAdjustmentAppointments = {item.donations.donationAdjustmentAppointments}
                    getSlotsForAppointmentType = {getSlotsForAppointmentType}
                    getAppSlotsHandler = {getAppSlotsHandler}
                    reloadCalendar={reloadCalendar}
                    donationCenter={donationCenter}
                    getSlotsSuccessInfo= {getSlotsSuccessInfo}
                    getSlotsFailureInfo = {getSlotsFailureInfo}
                    getSlotsLoading={getSlotsLoading}
                    centerInfo={centerInfo}
                  />
                )}
              </Table.Cell>
              <Table.Cell
                className={ 
                  (item.physicals.physicalAdjustmentAppointments === 0 && item.physicals.physicalScheduledAppointments === 0)
                  ? 'no-border'
                  : undefined 
                }
              >
                {item.physicals && (item.physicals.physicalAdjustmentAppointments > 0 || item.physicals.physicalScheduledAppointments > 0) && (
                  <CellPhysicalsData
                    needPlayRoom={item.isPlayroomAvailable}
                    toogleQuickPhysicalModal={toogleQuickPhysicalModal}
                    appointment={appointment}
                    onClickModifyHandler={appointment => setModifyAppointmentData(appointment)}
                    onClickScheduleHandler={appointment => setAppointment(appointment)}
                    toggleModal={toggleModal}
                    physicals={item.physicals.donors}
                    time={item.appointmentSlotTime}
                    appointmentTime = {item.appointmentSlotDateTime}
                    physicalAdjustmentAppointments = {item.physicals.physicalAdjustmentAppointments}
                    physicalScheduledAppointments = {item.physicals.physicalScheduledAppointments}
                    getSlotsForAppointmentType = {getSlotsForAppointmentType}
                    getAppSlotsHandler = {getAppSlotsHandler}
                    reloadCalendar={reloadCalendar}
                    donationCenter={donationCenter}
                    getSlotsSuccessInfo= {getSlotsSuccessInfo}
                    getSlotsFailureInfo = {getSlotsFailureInfo}
                    getSlotsLoading={getSlotsLoading}
                    centerInfo={centerInfo}
                  />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {modifyAppointmentData && modifyAppointmentData.id && (
        <CenterOpsModifyAppointmentModal
          open={openModal}
          toggleModal={toggleModal}
          initialSnapshot={modifyAppointmentData}
          handleAppointment={handleAppointment}
          toggleCancelAppointmentModal={toggleCancelAppointmentModal}
          toggleConfirmRescheduleModal={toggleConfirmRescheduleModal}
          cancelAppointmentHandler = {cancelAppointmentHandler}
          appointmentId = {appointmentId}
          appointmentDate = {appointmentDate}
          getAppSlotsHandler = {getAppSlotsHandler}
          onClickRescheduleAppointment = {appointment => setRescheduleAppointmentData(appointment)}
          reloadCalendar={reloadCalendar}
          getSlotsSuccessInfo= {getSlotsSuccessInfo}
          getSlotsFailureInfo = {getSlotsFailureInfo}
          getSlotsLoading={getSlotsLoading}
        />
      )}
      {modifyAppointmentData && modifyAppointmentData.id && (
        <CancelAppointmentModal
          appointmentData={modifyAppointmentData}
          toggleCancelAppointmentModal={toggleCancelAppointmentModal}
          open={openCancelAppointmentModal}
          cancelAppointmentHandler = {cancelAppointmentHandler}
          cancelApptSuccessInfo = {cancelAppointmentSuccessResponse}
          isCancelApptLoading={cancelAppointmentLoading}
          cancelApptErrorInfo={cancelApptErrorInfo}
          reloadCalendar={reloadCalendar}
        />
      )}
      {modifyAppointmentData && modifyAppointmentData.id && (
        <ConfirmRescheduleModal
          open={openConfirmRescheduleModal}
          toggleModal={toggleConfirmRescheduleModal}
          appointmentData={rescheduleAppointmentData}
          onSubmitRescheduleClick={rescheduleAppointmentHandler}
          isScheduleApptLoading={rescheduleAppointmentLoading}
          scheduleApptErrorInfo={rescheduleAppointmentFailInfo}
          scheduleApptSuccessInfo={rescheduleAppointmentSuccessInfo}
          reloadCalendar={reloadCalendar}
        />
      )}
      <QuickPhysicalModal
        open={openQuickPhysical}
        toogleQuickPhysicalModal={toogleQuickPhysicalModal}
        scheduleQuickAppointmentHandler={scheduleQuickAppointmentHandler}
        appointment={appointment}
        donationCenter={donationCenter}
        isScheduleQuickApptLoading={isScheduleQuickApptLoading}
        scheduleQuickApptSuccessInfo={scheduleQuickApptSuccessInfo}
        scheduleQuickApptErrorInfo={scheduleQuickApptErrorInfo}
        reloadCalendar={reloadCalendar}
      />
    </div>
  )
}

export default DaySchedule
