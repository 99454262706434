import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Grid, Popup, Form, Icon, Radio, Checkbox, Modal, Header, Image } from 'semantic-ui-react'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import { Form as FinalForm, Field } from 'react-final-form'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next';

import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const changeLanguageHelper = importer('Helpers/changeLanguage')
const StartupActions = importer('Stores/Startup/Actions')
const {isMobileWeb} = importer('Helpers/Utils', null)
const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { webArticles } = importer('Helpers/WebArticles')

const UpdatePreferencesForm = ({
  donor,
  preferences,
  updateDonorsPreferences,
  loading,
  smsStatementContent,
  marketingStatementContent,
  updateDonorsPreferencesSuccess,
  getDonors,
  getDonorsSuccess,
  initializeApp,
  publicContent
}) => {
  const { t, i18n } = useTranslation('UpdatePreferencesForm');

  const [loaded, setLoaded] = useState(false)
  const [statesManager, setStatesManager] = useState({})
  const [marketingStatement, setMarketingStatement] = useState(false)
  const [smsStatement, setSmsStatement] = useState(false)
  const [showMarketingModal, setShowMarketingModal] = useState(false)
  const [showSmsModal, setShowSmsModal] = useState(false)
  const [showSmsMkModal, setShowSmsMkModal] = useState(false)
  const [isEnglish, setIsEnglish] = useState(preferences?.language === 'en-US' ? true : false)
  const [isSpanish, setIsSpanish] = useState(preferences?.language === 'es-MX' ? true : false)
  const [language, setLanguage] = useState(preferences?.language)
  const [saveBtnClicked, setSaveBtnClicked] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const optOutVerbiage = ArticleByIdentifier(
    publicContent,
    webArticles.OPT_OUT_VERBIAGE
  )

  const onChangeUseState = (e, { name, _, checked }) => {
    switch (name) {
      case 'isSmsReminderOptIn':
        smsStatement ? update(name, checked) : setShowSmsModal(true)
        return
      case 'isSmsOptIn':
        smsStatement ? update(name, checked) : setShowSmsMkModal(true)
        return
      case 'isEmailOptIn':
        marketingStatement ? update(name, checked) : setShowMarketingModal(true)
        return
      default:
        update(name, checked)
    }
  }

  const update = (name, checked) => {
    setStatesManager(prevState => ({ ...prevState, [name]: checked }))
  }

  const onSubmit = () => {
    setSaveBtnClicked(true)
    updateDonorsPreferences(donor.id, {
      isSmsOptIn: statesManager.isSmsOptIn,
      isEmailReminderOptIn: statesManager.isEmailReminderOptIn,
      isSmsReminderOptIn: statesManager.isSmsReminderOptIn,
      isEmailOptIn: statesManager.isEmailOptIn,
      language: language
    })
    setModalOpen(true)
  }

  useEffect(() => {
    if(updateDonorsPreferencesSuccess) {
      getDonors(donor.id)
    }
  }, [updateDonorsPreferencesSuccess])


  useEffect(() => {
    if (donor?.language === 'en-US') {
      setIsSpanish(false)
      setIsEnglish(true)
    } else {
      setIsSpanish(true)
      setIsEnglish(false)
    }
  }, [donor?.language])

  useEffect(() => {
    if(getDonorsSuccess && saveBtnClicked) {
      changeLanguageHelper(donor?.language)
      initializeApp()
    }
  }, [getDonorsSuccess])


  useEffect(() => {
    if (!isEmpty(preferences)) {
      const complexStateInitial = {
        isSmsOptIn: preferences.isSmsOptIn,
        isEmailReminderOptIn: preferences.isEmailReminderOptIn,
        isSmsReminderOptIn: preferences.isSmsReminderOptIn,
        isEmailOptIn: preferences.isEmailOptIn,
      }
      setStatesManager(complexStateInitial)
    }
  }, [preferences])

  useEffect(() => {
    setSmsStatement(isSmsOptinSelected())
    setMarketingStatement(isMarketingOptinSelected())
  }, [statesManager])

  const handleSmsStatement = field => {
    if (smsStatement) {
      setStatesManager({
        ...statesManager,
        isSmsReminderOptIn: false,
        isSmsOptIn: false
      })
    } else {
      setStatesManager({
        ...statesManager,
        [field]: true
      })
    }
  }

  const handleMarketingStatement = () => {

    if (statesManager.isSmsOptIn) {
      setStatesManager({
        ...statesManager,
        isSmsOptIn: !marketingStatement,
        isEmailOptIn: !marketingStatement
      })
    } else {
      setStatesManager({
        ...statesManager,
        isSmsOptIn: false,
        isEmailOptIn: !marketingStatement
      })
    }
  }

  const isMarketingOptinSelected = () =>
    statesManager.isSmsOptIn || statesManager.isEmailOptIn

  const isSmsOptinSelected = () =>
    statesManager.isSmsOptIn || statesManager.isSmsReminderOptIn

  return (
    <>
    <FinalForm
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <Form onSubmit={handleSubmit} className='UpdatePreferencesForm'>
          <Grid style={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column width={12} className='PreferenceText'>
                {t('appoinmentRemainders')} & {t('donationTips')}
              </Grid.Column>
              <Grid.Column width={4}>
                <Popup
                  content={t('recieveNotifications')}
                  on='click'
                  position={isMobileWeb() ? 'top right' : 'top left'}
                  trigger={<Icon className='FloatRight' name='info' circular />}
                  className='NotificationPopup'
                />
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column width={10}>
                {t('sms')}
                <Popup
                  on='click'
                  onClose={() => setShowSmsModal(false)}
                  onOpen={() => setShowSmsModal(true)}
                  open={showSmsModal}
                  pinned
                  position={isMobileWeb() ? 'top center' : 'top left'}
                  trigger={
                    <Icon name='info' circular style={{ marginLeft: '15px' }} />
                  }
                  className='NotificationPopup'
                >
                  <Popup.Content>
                    <div>
                      <Grid columns={2}>
                        <Grid.Column width={2}>
                          <Checkbox
                            label=''
                            checked={smsStatement}
                            onChange={() =>
                              handleSmsStatement('isSmsReminderOptIn')
                            }
                          />
                        </Grid.Column>
                        <Grid.Column width={14}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: smsStatementContent.content__c
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </div>
                  </Popup.Content>
                </Popup>
              </Grid.Column>
              <Grid.Column width={6}>
                <Field
                  name='isSmsReminderOptIn'
                  type='radio'
                  value='isSmsReminderOptIn'
                >
                  {({ input }) => (
                    <Radio
                      toggle
                      className='FloatRight'
                      value='isSmsReminderOptIn'
                      name='isSmsReminderOptIn'
                      checked={statesManager.isSmsReminderOptIn}
                      onChange={onChangeUseState}
                    />
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column>{t('email')}</Grid.Column>
              <Grid.Column>
                <Field
                  name='isEmailReminderOptIn'
                  type='radio'
                  value='isEmailReminderOptIn'
                >
                  {({ input }) => (
                    <Radio
                      toggle
                      className='FloatRight'
                      name='isEmailReminderOptIn'
                      value='isEmailReminderOptIn'
                      checked={statesManager.isEmailReminderOptIn}
                      onChange={onChangeUseState}
                    />
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid style={{ paddingBottom: '20px', paddingTop: '20px' }}>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column width={12} className='PreferenceText'>
                {t('marketingNotifications')}
              </Grid.Column>
              <Grid.Column width={4}>
                <Popup
                  on='click'
                  onClose={() => setShowMarketingModal(false)}
                  onOpen={() => setShowMarketingModal(true)}
                  open={showMarketingModal}
                  position={isMobileWeb() ? 'top right' : 'top left'}
                  trigger={<Icon name='info' circular className='FloatRight' />}
                  className='NotificationPopup NotificationPopupCheckbox'
                >
                  <Popup.Content>
                    <div>
                      <Grid columns={2}>
                        <Grid.Column width={2}>
                          <Checkbox
                            label=''
                            checked={marketingStatement}
                            onChange={handleMarketingStatement}
                          />
                        </Grid.Column>
                        <Grid.Column width={14}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: marketingStatementContent.content__c
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </div>
                  </Popup.Content>
                </Popup>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column width={10}>
                {t('sms')}
                <Popup
                  on='click'
                  onClose={() => setShowSmsMkModal(false)}
                  onOpen={() => setShowSmsMkModal(true)}
                  open={showSmsMkModal}
                  pinned
                  position={isMobileWeb() ? 'top center' : 'top left'}
                  trigger={
                    <Icon name='info' circular style={{ marginLeft: '15px' }} />
                  }
                  className='NotificationPopup'
                >
                  <Popup.Content>
                    <div>
                      <Grid columns={2}>
                        <Grid.Column width={2}>
                          <Checkbox
                            label=''
                            checked={smsStatement}
                            onChange={() => handleSmsStatement('isSmsOptIn')}
                          />
                        </Grid.Column>
                        <Grid.Column width={14}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: smsStatementContent.content__c
                            }}
                          />
                        </Grid.Column>
                      </Grid>
                    </div>
                  </Popup.Content>
                </Popup>
              </Grid.Column>
              <Grid.Column width={6}>
                <Field name='isSmsOptIn' type='radio' value='isSmsOptIn'>
                  {({ input }) => (
                    <Radio
                      toggle
                      className='FloatRight'
                      value='isSmsOptIn'
                      name='isSmsOptIn'
                      checked={statesManager.isSmsOptIn}
                      onChange={onChangeUseState}
                    />
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column>{t('email')}</Grid.Column>
              <Grid.Column>
                <Field name='isEmailOptIn' type='radio' value='isEmailOptIn'>
                  {({ input }) => (
                    <Radio
                      toggle
                      className='FloatRight'
                      value='isEmailOptIn'
                      name='isEmailOptIn'
                      checked={statesManager.isEmailOptIn}
                      onChange={onChangeUseState}
                    />
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Grid style={{ paddingBottom: '20px', paddingTop: '20px' }}>

            <Grid.Row columns={1} className='RowSpacing'>
              <Grid.Column className='PreferenceText'>
                {t('preferedLanguage')}
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column>{t('english')}</Grid.Column>
              <Grid.Column>
                <Field name='language' type='radio' value='en-US'>
                  {({ input }) => (
                    <Radio
                      toggle
                      className='FloatRight'
                      value='en-US'
                      name='enUS'
                      checked={isEnglish}
                      onChange={(e) => {
                        setIsEnglish(input.value)
                        setIsSpanish(false)
                        setLanguage(input.value)
                      }}
                    />
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column>{t('spanish')}</Grid.Column>
              <Grid.Column>
                <Field name='language' type='radio' value='es-MX'>
                  {({ input }) => (
                    <Radio
                      toggle
                      className='FloatRight'
                      value='es-MX'
                      name='esMX'
                      checked={isSpanish}
                      onChange={(e) => {
                        setIsSpanish(input.value)
                        setIsEnglish(false)
                        setLanguage(input.value)
                      }}
                    />
                  )}
                </Field>
              </Grid.Column>
            </Grid.Row>

          </Grid>
          <Grid style={{ paddingTop: '20px' }}>
            <Grid.Row columns={2} className='RowSpacing'>
              <Grid.Column>
                <PrimaryButton
                  text={t('save')}
                  width='100%'
                  type='submit'
                  loading={loading}
                />
              </Grid.Column>
              <Grid.Column />
            </Grid.Row>
          </Grid>
        </Form>
      )}
      />
      <Modal open={modalOpen} size='small'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
                {t('updatePreferenceMessageHeader')}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image
                src={CloseIcon}
                alt='Close'
                onClick={() => setModalOpen(false)}
              />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <div
            dangerouslySetInnerHTML={{
              __html: optOutVerbiage.content__c
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  )
}

const mapStateToProps = ({publicContent}) => ({
  publicContent: publicContent.WebArticles?.completeArticles
})

const mapDispatchToProps = dispatch => ({
  initializeApp: () => dispatch(StartupActions.initializeApp()),

})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePreferencesForm)
