import React from 'react'
import { Icon } from 'semantic-ui-react'
import InputMask from 'react-input-mask'
import './styles.scss'

const PrimaryMaskedInput = (props) => {
  const {
    width,
    height,
    action,
    actionPosition,
    as,
    className,
    disabled,
    error,
    fluid,
    focus,
    icon,
    iconPosition,
    inverted,
    label,
    labelPosition,
    onChange,
    size,
    transparent,
    type,
    placeholder,
    required,
    value,
    mask,
    onBlur,
    maskPlaceholder,
    hasErrorsClass
  } = props

  const allClassNames = `${className} ${hasErrorsClass}`
  return (
    <div className={allClassNames}>
      {required && className === 'PrimaryInput' && <Icon className='Required' size='tiny' name='asterisk' />}

      <InputMask
        action={action}
        actionPosition={actionPosition}
        as={as}
        disabled={disabled}
        error={error}
        fluid={fluid}
        focus={focus}
        icon={icon}
        iconPosition={iconPosition}
        inverted={inverted}
        label={label}
        labelPosition={labelPosition}
        onChange={onChange}
        size={size}
        transparent={transparent}
        type={type}
        placeholder={placeholder}
        style={{ width: width, height: height }}
        value={value}
        mask={mask}
        onBlur={onBlur}
        maskPlaceholder={maskPlaceholder}
      />
    </div>
  )
}

PrimaryMaskedInput.defaultProps = {
  className: 'PrimaryInput'
}
export default PrimaryMaskedInput
