import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import { useLocation } from 'react-router-dom'
/* COMPONENTS */
const UpdatePasswordForm = importer('Components/Forms/UpdatePasswordForm')
const UpdateEmailForm = importer('Components/Forms/UpdateEmailForm')
const OptOutOfLoyalty=importer('Components/Dashboard/OptOutOfLoyalty')
/* ACTIONS */
const AuthCustomActions = importer('Stores/AuthCustom/Actions')
const UserActions = importer('Stores/Users/Actions')

const {isMobileWeb} = importer('Helpers/Utils', null)
const AccountSetting = (props) => {
  const location = useLocation()
  const fromLoyaltyDashboard = location?.state?.fromLoyaltyDashboard
    useEffect(() => {
      if(isMobileWeb() && fromLoyaltyDashboard){
      window.scrollTo({
        top: 1000,
        behavior: "smooth",
      });
    }
    else if(fromLoyaltyDashboard){
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    }, [])
  
  const { changePassword, changePasswordSuccess, changePasswordFailure, donor, signOut } = props
  const handleSubmit = ({ currentPassword, passwordConfirm }) => {
    changePassword(currentPassword, passwordConfirm)
  }

  return (
    <div className='AccountSetting'>
      <UpdateEmailForm/>
      <UpdatePasswordForm
        setSubmitSuccess={handleSubmit}
        changePasswordFailure={changePasswordFailure}
        changePasswordSuccess={changePasswordSuccess}
      />
      {donor?.isLoyaltyMember==="Yes" && <OptOutOfLoyalty/>}
     
    </div>
  )
}

const mapStateToProps = (state) => ({
  changePasswordSuccess: state.authCustom.changePasswordSuccess,
  changePasswordFailure: state.authCustom.changePasswordFailure,
  donor: state.donors.donors?.data
})

const mapDispatchToProps = (dispatch) => ({
  changePassword: (oldPassword, newPassword) => dispatch(AuthCustomActions.changePassword(oldPassword, newPassword)),
  signOut: () => dispatch(UserActions.logout())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSetting)
