import React, { useState, useEffect } from 'react'
import { Form, Segment, Radio, Grid, List, Icon, Message, Loader, Dimmer, Visibility,Image} from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { chunkArray, getAge, formatPhoneNumber,filterNonNomogramCentersPublicPage } from '../../../Helpers/Utils'
import { NavLink, useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import validate from './validate'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import './styles.scss'
import LocationIcon from '../../../../assets/media/svg/icon-location.svg'

import importer from '@takedapdt/biolife-core/src/importer'
import GrayInfoIcon from '../../../../assets/media/images/GrayInfo-Icon.png'
const Config = importer('Config')
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const MaskedField = importer('Components/Forms/FormFields/MaskedField')
const PasswordValidation = importer('Components/PasswordValidation')
const {isMobileWeb} = importer('Helpers/Utils', null)

const AccountCreateForm = ({
  countries,
  states,
  centers,
  genders,
  getGenders,
  phoneTypes,
  ethnicityOptions,
  getPhoneTypes,
  getEthnicityOptions,
  getDonorCentersAll,
  getDonorCentersAllLoading,
  register,
  registerSuccess,
  registerFailure,
  donor,
  smsStatement,
  privacyStatement,
  touStatement,
  donorDetailsSaved
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null)
  const [passwordReveal, setPasswordReveal] = useState(false)
  const [statesManager, setStatesManager] = useState({})
  const [selectedCountry, setSelectedCountry] = useState('US')
  const [selectedPhoneType, setSelectedPhoneType] = useState('Mobile')
  const [countriesOptions, setCountriesOptions] = useState([])
  const [statesOptions, setStatesOptions] = useState([])
  const [errorMessages, setErrorMessages] = useState('')
  const [submited, setSubmited] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAgeError, setShowAgeError] = useState(false)
  const [passwordInput, setPasswordInput] = useState('')
  const [genderDropDown, setGenderDropDown] = useState([{text:'',value:''}])
  const [phoneTypeDropdown, setPhoneTypeDropdown] = useState([{text:'',value:''}])
  const [ethnicityDropdown, setEthnicityDropdown] = useState([{text:'',value:''}])
  const [NHOPICustomStyle, setNHOPICustomStyle] = useState('')
  const [loyaltyTnCsShow, setLoyaltyTnCsShow] = useState(false)
  const [zipcodeError, setZipcodeError] = useState(false)
  const [filterCenters, setfilterCenters] = useState()
  


  const createdFrom = isMobileWeb() ? 'MOBILE_WEB' : 'WEB'

  const { t, i18n } = useTranslation('AccountCreateForm');

  const languageOptions = [
    { key: 'English', text: t('english'), value: 'en-US' },
    { key: 'Spanish', text: t('spanish'), value: 'es-MX'}
  ]

  useEffect(() => {
    if(centers){
      setfilterCenters(filterNonNomogramCentersPublicPage(centers))
    }
  },[centers])

  const history = useHistory()
  const handleLocationChange = (value) => {
    setSelectedLocation(value.centerNum)
    if(value.isLoyaltyProgramEnabled){
      setLoyaltyTnCsShow(true)
    }
    else{
      setLoyaltyTnCsShow(false)
    }
  }
  const togglePasswordReveal = () => setPasswordReveal(!passwordReveal)

  const handlePasswordChange = ({ target: { value } }) => {
    setPasswordInput(value)
  }
 
 const updateCenters=(inputVal)=>{
      if(inputVal && inputVal.length === 5){
        setZipcodeError(false)
        setSelectedLocation(null)
        getDonorCentersAll(inputVal, '', 'pubDate', true, true)           // second to last true corresponds to addNewDonorBonusInformation
        setLoyaltyTnCsShow(false)
      }
  }
  const handleDateChange = ({ target: { value } }) => {
    if (!moment(value, 'MM/DD/YYYY').isValid()) {
      setShowAgeError(true)
    } else {
      if (value.slice(6) > moment().subtract(18, 'years').format('yyyy')) {
        setShowAgeError(true)
      } else {
        setShowAgeError(false)
      }
    }
  }

  const onSubmit = async (values) => {
    setLoading(true)
    const donorsAge = getAge(values.birthDate)
    const donorDetails = {
      donorDetails: {
        donorId: `${statesManager.donorId}`,
        firstName: values.firstName,
        lastName: values.lastName,
        birthDate: moment(values.birthDate).format('YYYY-MM-DD'),
        language: values.language,
        gender: values.gender,
        phoneNumber: values.phoneNumber.replace(/\D/g, ''),
        email: statesManager.loginId,
        ethnicityCode: values.ethnicityCode,
        phoneType: values.phoneType || selectedPhoneType,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city.trim(),
        state: values.state,
        country: values.country || selectedCountry,
        zipCode: values.zipCode,
        donationCenter: selectedLocation ? selectedLocation.toString() : selectedLocation,
        isMobileTermsAccepted: values.isMobileTermsAccepted,
        isSmsOptIn: values.isSmsOptIn,
        isEmailOptIn: donorDetailsSaved.isEmailOptIn,
        isSmsReminderOptIn: values.isSmsOptIn,
        isEmailReminderOptIn: donorDetailsSaved.isEmailOptIn,
        isLoyaltyMember: loyaltyTnCsShow ? values.isLoyaltyMember ? "Yes" : null : null            // BE teams explicitly wants Yes or No with respect to boolean true or false
      },
      loginId: statesManager.loginId,
      password: values.password,
      autoVerify: false,
      salesforceId: !isEmpty(donor) && donor.sfid !== null ? donor.sfid : '',
      personContactId: !isEmpty(donor) && donor.personContactId !== null ? donor.personContactId : '',
      url: `${Config.WEB_APP_URL}/verify-email`,
      loyaltyApplicationUsed: isMobileWeb() ? 'MOBILE_WEB' : 'WEB',
      createdFrom
    }

    if (donorsAge >= 18 && donorsAge <= 150) {
      await register(donorDetails)
      setSubmited(true)
    } else {
      setShowAgeError(true)
      setLoading(false)
    }
  }
  const getStatesByCountries = (country) => {
    if (country) {
      const dCountry = countries.find((c) => c.code === country)
      if (dCountry) {
        if (!isEmpty(states)) {
          const filteredStates = states
            .filter((state) => state.countrycode === dCountry.code)
            .map((s) => ({
              key: s.code,
              value: s.code,
              text: s.description
            }))
          setStatesOptions(filteredStates)
        }
      }
    }
  }
  useEffect(() => {
    getPhoneTypes()
    getGenders()
    getEthnicityOptions()
  },[i18n.language])

  useEffect(() => {
    setGenderDropDown(transformGenderCollection())
  },[genders])

  useEffect(() => {
    setPhoneTypeDropdown(transformPhoneTypeCollection())
  },[phoneTypes])

  useEffect(() => {
    setEthnicityDropdown(transformEthnicityOptionsCollection())
  },[ethnicityOptions])

  const transformGenderCollection = () =>
    genders &&
    genders.map((gender) => ({
      key: gender.key,
      text: gender.label,
      value: gender.value
    }))

    const transformPhoneTypeCollection = () =>
    phoneTypes &&
    phoneTypes.map((phoneTypes) => ({
      key: phoneTypes.key,
      text: phoneTypes.label,
      value: phoneTypes.value
    }))

    const transformEthnicityOptionsCollection = () => 
    ethnicityOptions &&
    ethnicityOptions.map((ethnicityOption) => ({
      key: ethnicityOption.key,
      text: i18n.language === 'es-MX' ? ethnicityOption.es_label : ethnicityOption.en_label,
      value: ethnicityOption.value
    }))

  useEffect(() => {
    const storageData = JSON.parse(window.sessionStorage.getItem('lead'))

    if (!isEmpty(storageData)) {
      getDonorCentersAll(storageData.zipCode, '', 'pubDate', true, true)      // second to last true corresponds to addNewDonorBonusInformation
      const complexStateInitial = {
        donorId: storageData.id,
        firstName: storageData.firstName,
        lastName: storageData.lastName,
        loginId: storageData.email,
        zipCode: storageData.zipCode
     }
      setStatesManager(complexStateInitial)
    }
  }, [donor, donorDetailsSaved])

  useEffect(() => {
    if (!isEmpty(countries) && !isEmpty(states)) {
      const availableContries = countries.map((c) => ({
        key: c.code,
        value: c.code,
        text: c.description
      }))
     setCountriesOptions(availableContries)
     getStatesByCountries(selectedCountry)
    }
  }, [countries,states])

  // useEffect(() => {
  //   const storageData = JSON.parse(window.sessionStorage.getItem('lead'))
  //   if (storageData) {
  //     getDonorCentersAll(storageData.zipCode, '', 'pubDate' )
  //   }
  // },[])
  
  useEffect(() => {
    if (registerSuccess !== null && submited) {
      setSubmited(false)
      setLoading(false)
      history.push('/email-confirmation')
    }
  }, [registerSuccess])

  useEffect(() => {
    if (registerFailure !== null && submited) {
      setSubmited(false)
      setLoading(false)
      setErrorMessages(registerFailure ? registerFailure.message : t('somethingWentWrong'))
    }
  }, [registerFailure])

  return (
    <Visibility>
      <div className='AccountCreateForm'>
        <FinalForm
          onSubmit={onSubmit}
          validate={validate}
          initialValues={statesManager}
          render={({ handleSubmit, values }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group>
                <Field name='zipCode'>
                  {({ input, meta }) => (
                    <Form.Field required>
                      <InputField
                        required
                        meta={meta}
                        {...input}
                        height={49}
                        className='AccountFormTextInput StandardFieldsWidths'
                        fieldLabel={t('zipCode')}
                        customOnChange={(e) => e.target.value.length !== 5 ? setZipcodeError(true) : null}
                        onBlur={() => {
                          input.onBlur()
                          updateCenters(input.value)
                        }}
                      />
                    </Form.Field>
                  )}
                </Field>
              </Form.Group>
              <p className='CentersNearYou'>{t('chooseaBioLifeCenterNearYou')}</p>
              {isEmpty(filterCenters) && !zipcodeError && !getDonorCentersAllLoading && (
                <p className='CentersNearYou'>
                  {t('noBioLifeCentreFoundMsg')}
                </p>
              )}
              {zipcodeError && (
                <p className='CentersNearYou'>{t('pleaseEnterValidZipcode')}</p>
              )}
              {!isEmpty(filterCenters) && !zipcodeError && !getDonorCentersAllLoading && (
                <Field name='donationCenter'>
                  {({ input, meta }) => (
                    <div>
                      {meta.touched && meta.error && !selectedLocation && (
                        <small className='LocationError'>{meta.error}</small>
                      )}
                      {chunkArray(filterCenters, 2).map((row, index) => (
                        <Form.Group key={index}>
                          {row.map((center) => (
                            <Form.Field key={center.id}>
                              <Segment
                                onClick={() => {
                                  handleLocationChange(center)
                                  input.onChange(center.centerNum)
                                }}
                                compact
                                className={
                                  selectedLocation === center.centerNum ? 'RadioLocationChecked' : 'RadioLocation'
                                }
                              >
                                <Grid columns={2}>
                                  <Grid.Column width={2}>
                                    <Radio
                                      name='donationCenter'
                                      checked={selectedLocation === center.centerNum}
                                    />
                                  </Grid.Column>
                                  <Grid.Column width={14}>
                                    <h6 className='LocationName'>{center.name}</h6>
                                    {center.isSpanishSupported && <h6 className='spanishCenter'>{t('seHablaEspanol')}</h6>}
                                    <p className='locationAddress'>
                                      {center.addressLine1}
                                      <br />
                                      {`${center.city}, ${center.stateCode} ${center.zipcode}`}
                                      <br />
                                      <a href={`tel:${center.telephone}`}>{formatPhoneNumber(center.telephone)}</a>
                                    </p>
                                    {center.newDonorBonusInformation && Object.keys(center.newDonorBonusInformation).length > 0 &&
                                    <div className='NewDonorBonusInformationBlock'>
                                      <p>{t('newBiolifePlasmaDonors')} <b>${center?.newDonorBonusInformation?.ndbAmount}</b> {t('newBiolifePlasmaDonors2')}</p>
                                      <span><Icon name='clock outline' className='ClockOutline' inline /> <b>{t('expiryDate')}:</b> {moment(center?.newDonorBonusInformation?.offerExpDate).format("MM/DD/YYYY")}</span>
                                    </div>}
                                    {center.distanceFromGivenZipcode && center.distanceFromGivenZipcode >= 0 ? 
                                      <div className='distanceInfo'>
                                        <div className='distanceIcon'><Image src={LocationIcon} /></div>
                                        <div><b>{t('distance')}</b> {(center.distanceFromGivenZipcode/1609.34).toFixed(2)}{t('miles')}</div>
                                      </div> : null}
                                  </Grid.Column>
                                </Grid>
                              </Segment>
                            </Form.Field>
                          ))}
                        </Form.Group>
                      ))}
                    </div>
                  )}
                </Field>
              )}
              {loyaltyTnCsShow && 
                <Form.Group>
                  <div className='CheckBoxContainer LoyaltyCheckboxContainer'>
                    <Grid columns={2}>
                      <Grid.Column computer={1} mobile={2}>
                        <Field name='isLoyaltyMember' type='checkbox' component='input' />
                      </Grid.Column>
                      <Grid.Column computer={15} mobile={14}>
                        <div>
                          <p>{t('loyaltyTnCsCheckbox')}&nbsp;
                          <a href={i18n.language === 'es-MX' ? 'https://info.biolifeplasma.com/myloyalty-terms-and-conditions-es' : 'https://info.biolifeplasma.com/myloyalty-terms-and-conditions'} target="_blank">{t('loyaltyBiolife')}</a>&nbsp;
                          {t('click')}&nbsp;
                          <a href={i18n.language === 'es-MX' ? 'https://info.biolifeplasma.com/myloyalty-FAQ-es' : 'https://info.biolifeplasma.com/myloyalty-FAQ'} target="_blank">{t('here')}</a>&nbsp;
                          {t('learnMoreAboutProgram')}</p>
                        </div>
                      </Grid.Column>
                    </Grid>
                  </div>
                </Form.Group>
              }
              <Form.Group>
                <Field name='firstName'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={49}
                      className='AccountFormTextInput StandardFieldsWidths'
                      fieldLabel={t('firstName')}
                    />
                  )}
                </Field>
                <Field name='lastName'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={49}
                      className='AccountFormTextInput StandardFieldsWidths'
                      fieldLabel={t('lastName')}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Field name='birthDate'>
                  {({ input, meta }) => (
                    <MaskedField
                      required
                      meta={meta}
                      {...input}
                      height={49}
                      className={showAgeError ? 'AccountFormTextInput DateOfBirthInput StandardFieldsWidths' : 'AccountFormTextInput StandardFieldsWidths'}
                      fieldLabel={t('dateofBirth')}
                      mask='99/99/9999'
                      customOnChange={handleDateChange}
                    />
                  )}
                </Field>
                 <Field name='gender'>
                  {({ input, meta }) => (
                    <SelectField
                      meta={meta}
                      input={input}
                      required
                      placeholder={t('select')}
                      className='AccountFormLargeSelect'
                      options={genderDropDown}
                      fieldLabel={t('gender')}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group >
                <Field name='language'>
                  {({ input, meta }) => (
                    <SelectField
                      meta={meta}
                      input={input}
                      required
                      placeholder={t('select')}
                      className='AccountFormLargeSelect'
                      options={languageOptions}
                      fieldLabel={t('preferredLanguage')}
                    />
                  )}
                </Field>                
                <Field name='ethnicityCode'>
                  {({ input, meta }) => (
                    <SelectField
                      meta={meta}
                      input={input}
                      required
                      className={`AccountFormLargeSelect ${NHOPICustomStyle}`}
                      placeholder={t('select')}
                      options={ethnicityDropdown}
                      fieldLabel={t('ethnicity')}
                      handleOnChange={val=>{
                        val==='NHOPI' ? setNHOPICustomStyle('NHOPICustomStyle') : setNHOPICustomStyle('')
                        input.onChange(val)
                      }}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group >
                <Field name='phoneNumber'>
                  {({ input, meta }) => (
                    <MaskedField
                      required
                      meta={meta}
                      {...input}
                      height={49}
                      className='AccountFormTextInput StandardFieldsWidths'
                      fieldLabel={t('phoneNumber')}
                      mask='(999) 999-9999'
                    />
                  )}
                </Field>
                <Field name='phoneType'>
                  {({ input, meta }) => (
                    <SelectField
                      meta={meta}
                      input={input}
                      required
                      className='AccountFormLargeSelect'
                      options={phoneTypeDropdown}
                      handleOnChange={(value) => {
                        setSelectedPhoneType(value)
                        input.onChange(value)
                      }}
                      defaultValue='Mobile'
                      fieldLabel={t('type')}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <div className='CheckBoxContainer'>
                  <Grid columns={2}>
                    <Grid.Column computer={1} mobile={2}>
                      <Field name='isSmsOptIn' type='checkbox' component='input' />
                    </Grid.Column>
                    <Grid.Column computer={15} mobile={14}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: smsStatement.content__c
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </div>
              </Form.Group>
              <Form.Group>
                <Field name='addressLine1'>
                  {({ input, meta }) => (
                    <InputField
                      meta={meta}
                      {...input}
                      height={49}
                      className='AccountFormTextInput LargeFieldsWidths'
                      fieldLabel={t('address')}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Field name='city'>
                  {({ input, meta }) => (
                    <InputField
                      required
                      meta={meta}
                      {...input}
                      height={49}
                      className='AccountFormTextInput StandardFieldsWidths'
                      fieldLabel={t('city')}
                    />
                  )}
                </Field>
                <Field name='state'>
                  {({ input, meta }) => (
                    <SelectField
                      meta={meta}
                      input={input}
                      required
                      placeholder={t('select')}
                      className='AccountFormLargeSelect'
                      options={statesOptions}
                      fieldLabel={t('state')}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <Field name='country'>
                  {({ input, meta }) => (
                    <SelectField
                      meta={meta}
                      input={input}
                      required
                      className='AccountFormLargeSelect'
                      options={countriesOptions}
                      handleOnChange={(value) => {
                        setSelectedCountry(value)
                        getStatesByCountries(value)
                        input.onChange(value)
                      }}
                      defaultValue={selectedCountry}
                      fieldLabel={t('country')}
                    />
                  )}
                </Field>
              </Form.Group>
              <div className='LoginCredentials'>
                <Form.Group>
                  <div className='UsernameContainer'>
                  <Field name='loginId'>
                    {({ input, meta }) => (
                      <InputField
                        required
                        meta={meta}
                        {...input}
                        height={49}
                        className='AccountFormTextInput StandardFieldsWidths'
                        fieldLabel={t('username')}
                        disabled
                      />
                    )}
                     </Field> 
                    <div className="NonEditableTextContainer"><Image className='GrayInfoIcon'  src={GrayInfoIcon} width="19" height="19"/><span className="NonEditableText" >{t('FieldCannotBeUpdatedMsg')}</span></div>
                  </div> 
                  </Form.Group> 
                  <Form.Group> 
                  <Field name='password'>
                    {({ input, meta }) => {
                      return (
                        <InputField
                          required
                          meta={meta}
                          {...input}
                          height={49}
                          className='AccountFormTextInput StandardFieldsWidths'
                          customOnChange={handlePasswordChange}
                          type={passwordReveal ? 'text' : 'password'}
                          icon={
                            <Icon name={passwordReveal ? 'eye slash' : 'eye'} link onClick={togglePasswordReveal} />
                          }
                          fieldLabel={t('password')}
                          autoComplete="new-password"
                        />
                      )
                    }}
                  </Field>
                </Form.Group>
              </div>
              <PasswordValidation
                passwordInput={passwordInput}
                formType='AccountSetup'
              />
              <Form.Group>
                <div className='CheckBoxContainer'>
                  <Grid columns={2}>
                    <Grid.Column computer={1} mobile={2}>
                      <Field name='isPrivacyNoticeAccepted' type='checkbox' component='input' />
                    </Grid.Column>
                    <Grid.Column computer={15} mobile={14}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: privacyStatement.content__c
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </div>
              </Form.Group>
              <Form.Group>
                <div className='CheckBoxContainer'>
                  <Grid columns={2}>
                    <Grid.Column computer={1} mobile={2}>
                      <Field name='isMobileTermsAccepted' type='checkbox' component='input' />
                    </Grid.Column>
                    <Grid.Column computer={15} mobile={14}>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: touStatement.content__c
                        }}
                      />
                    </Grid.Column>
                  </Grid>
                </div>
              </Form.Group>
              <Form.Group className='submitBtnGroup'>
                <div className='SignUpButtonContainer'>
                  <PrimaryButton text={t('Next')} width={173} height={52} fontSize={16} disabled={!values.isMobileTermsAccepted || !values.isPrivacyNoticeAccepted} />
                </div>
              </Form.Group>
            </Form>
          )}
        />
        {errorMessages && (
          <Message warning>
            <p>{Array.isArray(errorMessages) ? errorMessages.join(', ') : errorMessages}</p>
          </Message>
        )}
        {showAgeError && (
          <Message warning>
            <p>
              {t('ageRestrictionMsg')}
            </p>
          </Message>
        )}
        {loading && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
      </div>
    </Visibility>
  )
}

export default AccountCreateForm
