import React from 'react'

import './styles.scss'
import { Grid, Container } from 'semantic-ui-react'

import importer from '@takedapdt/biolife-core/src/importer'

const MarketingBlockTop = importer('Components/MarketingBlockTop')
const MarketingBlockBottom = importer('Components/MarketingBlockBottom')

const MarketingBlocks = ({ topContent, topImage, bottomContent, bottomImage }) => (
  <div className='MarketingBlocks'>
    <div className="MarketingBlockTop">
    <Container >
      <Grid>
        <MarketingBlockTop mainContent={topContent} imageAltText={topImage.image_alt_text__c} mainImage={topImage.image_url_desktop__c} imageRedirectUrl={topImage.web_image_redirect_url__c} />
      </Grid>
    </Container>
    </div>
    <div>
    <h6></h6>
    </div>
    <div  className="MarketingBlockBottom">
    <Container>
      <Grid>
      <MarketingBlockBottom mainContent={bottomContent} imageAltText={bottomImage.image_alt_text__c}  mainImage={bottomImage.image_url_desktop__c} imageRedirectUrl={bottomImage.web_image_redirect_url__c} />
      </Grid>
    </Container>
    </div> 
  </div>
)

export default MarketingBlocks
