import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { Message, Grid, Container, Image, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom'
import iconAlert from '../../../assets/media/images/icon-alert.png'
import iconAnnounce from '../../../assets/media/images/icon-announce.png'
import iconClose from '../../../assets/media/images/icon-close.png'
import { isEmpty, lowerCase } from 'lodash'
import './styles.scss'

const AlertBanner = props => {
  const {
    messages,
    donor,
    loggedInUser,
    facility
  } = props
  const [visible, setVisible] = useState(true)
  const [alerts, setAlerts] = useState([]);
  const [announcements, setAnnouncements] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [showSmartBanner, setShowSmartBanner] = useState(true)
  const { t} = useTranslation('alertBanner');

  const location = useLocation().pathname.split('/')
  const handleDismiss = () => {
    setVisible(false);
    localStorage.setItem('showAlert', false);
  }

  useEffect(() => {
    setMessages()
  }, [messages, donor])

  useEffect(() => {
    if (localStorage.getItem('showAlert') === 'false') setVisible(false)
    else setVisible(true)
    if (localStorage.getItem('showSmartBanner') === 'false') setShowSmartBanner(false)
    else setShowSmartBanner(true)
  }, [])

  const iconSelector = (message) => {
    if (message) {
      switch (message.message_type__c) {
        case 'Alerts': {
          return iconAlert
        }

        case 'Announcements': {
          return iconAnnounce
        }

        case 'Promotions': {
          return iconAnnounce
        }

        default:
          return iconAnnounce
      }
    }
  }

  const styleHelper = (message) => {
    if (message) {
      switch (message.message_type__c) {
        case 'Alerts':
          return 'Message AlertMessage'

        case 'Announcements':
          return 'Message AnnounceMessage'

        case 'Promotions':
          return 'Message PromoMessage'

        default:
          return 'Message AlertMessage'
      }
    }
  }

  const filterByType = (list, type) => list.filter(item => item.message_type__c === type && item.is_active__c)

  const getMessagesByCenter = (centerID) => {
    const _messages = messages.find(item => item.centerID === centerID);
    let _alerts = [];
    let _announcements = [];
    let _promotions = [];
    if (_messages && _messages.list.length) {
      /* FILTER FOR ONLY PROMOTIONS | ALERTS | ACCOUNCEMENTS */
      const { list } = _messages;
      _alerts = filterByType(list, 'Alerts');
      _announcements = filterByType(list, 'Announcements');
      _promotions = filterByType(list, 'Promotions');
    } else {
      //When the center has no message content
      const _message = messages.find(item => item.centerID === null);
      if(!isEmpty(_message)) {
        const { list } = _message;
        _alerts = filterByType(list, 'Alerts');
        _announcements = filterByType(list, 'Announcements');
        _promotions = filterByType(list, 'Promotions');
      }
    }
    return { _alerts, _promotions, _announcements };
  }

  const updateMessageState = ({ _alerts, _promotions, _announcements }) => {
    setAlerts(_alerts);
    setAnnouncements(_announcements);
    setPromotions(_promotions);
  }

  const setMessages = () => {
    //let centerID = donor ? donor.donationCenter : null;
    let defaultCenter=loggedInUser?.defaultCenter
    let centerID = defaultCenter?defaultCenter.sfid:null;

    if (messages && messages.length) {
      if (centerID) {
        if(lowerCase(location[2]) !== lowerCase(facility?.data?.state.split(' ').join('-'))) {
          updateMessageState(getMessagesByCenter(centerID));
        }
        // when navigate to homepage while logged in for the first time facility is undefined
        else if(facility?.data?.state === undefined) {
          updateMessageState(getMessagesByCenter(centerID));
        }
        else {
          updateMessageState(getMessagesByCenter(facility?.data?.sfid));
        }
      }

      if (!centerID) {
        if(lowerCase(location[2]) !== lowerCase(facility?.data?.state.split(' ').join('-'))) {
          updateMessageState(getMessagesByCenter(centerID));
        }
        else {
          updateMessageState(getMessagesByCenter(facility?.data?.sfid));
        }
      }
    }
  }

  function getAltText(message){
    switch (message.message_type__c) {
      case 'Alerts':
        return 'Icon of an exclamation point.'

      case 'Announcements':
        return 'Icon of a megaphone producing sound.'

      case 'Promotions':
        return 'Icon of a megaphone producing sound.'

      default:
        return 'Alert Message'
    }
  }

  const handleBannerAction = (message, e) => {
    if (e.target.classList.contains('CloseContainer')) {
      handleDismiss()
    }
    else if(message.redirect_url__c){
      window.open(message.redirect_url__c, "_blank")
    }
  }

  const renderMessage = (message) => (
    <Message className={styleHelper(message)} onClick={(e) => handleBannerAction(message, e)} style={{cursor: message.redirect_url__c && 'pointer'}}>
      <Container fluid>
        <Grid verticalAlign='middle'>
          <Grid.Row columns={2}>
            <Grid.Column computer={2} mobile={3} textAlign='center'>
              <div className='MessageIconContainer'>
                <Image className='MessageIcon' alt={getAltText(message)} src={iconSelector(message)} height="60" width="60"/>
              </div>
            </Grid.Column>
            <Grid.Column
              computer={14}
              mobile={13}
              className='MainContentLeftBorder'
            >
              <Container>
                <Grid columns={2} verticalAlign='middle'>
                  <Grid.Column width={14} className='alertContent'>
                    <Header className='MessageHeader' as='h2'>
                      {message && message.title__c}
                    </Header>
                    <p className='MessageText'>
                      {message && message.description__c}
                    </p>
                    {message && message.redirect_url__c && (
                      <span className='MessageLink'>{t('clickHere')}</span>
                    )}
                  </Grid.Column>
                  <Grid.Column width={2} className='CloseContainer'>
                    {/*don't remove or change the class*/}
                    <div className='CloseContainer'>            
                    {/*don't remove or change the class*/}          
                      <Image                                              
                        className='CloseContainer'                        
                        src={iconClose}
                        alt="close"
                        height="20" width="20"
                      />                                                  
                    </div>
                  </Grid.Column>
                </Grid>
              </Container>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Message>
  )

  const isMobile = () =>{
    if(window.navigator.userAgent.toLowerCase().indexOf("android") > -1 || window.navigator.userAgent.toLowerCase().indexOf("iphone") > -1){
      return true
    }
    else return false
  }

  const closeSmartBanner = () => {
    setShowSmartBanner(false)
    localStorage.setItem('showSmartBanner', false);
  }
  return (
      <div>
        {isMobile() && showSmartBanner &&
          <div class="smartbanner" id="smartabanner">
            <div class="smartbanner-container">
              <div class="smartbanner-info">
                <a href='javascript:;' onClick={closeSmartBanner} id="smb-close" class="smartbanner-close">&times;</a>
                <span class="smartbanner-icon"></span>
                <div class="smartbanner-title">
                  <p>{t('bioLifePlasmaSe')}</p>
                  <div>{t('takedaPharma')}</div>
                  {window.navigator.userAgent.toLowerCase().indexOf("android") !== -1 ?
                    <span>{t('get')} &ndash; {t('getOnthePlaySto')}</span> : <span>{t('get')}  &ndash; {t('getOntheAppStor')}</span>
                  }
                </div>
              </div>
              {window.navigator.userAgent.toLowerCase().indexOf("android") !== -1 &&
                <a href="https://play.google.com/store/apps/details?id=com.shire.biolife" target="_blank" class="smartbanner-button">
                  <span class="smartbanner-button-text">{t('view')}</span>
                </a>
              }
              {window.navigator.userAgent.toLowerCase().indexOf("iphone") !== -1 &&
                // <a href="http://itunes.apple.com/lb/app/truecaller-caller-id-number/id448142450?mt=8" target="_blank" class="smartbanner-button">
                <a href="https://apps.apple.com/us/app/biolife-plasma-services/id1356589861" target="_blank" class="smartbanner-button">
                  <span class="smartbanner-button-text">{t('view')}</span>
                </a>
              }
            </div>
          </div>
        }
        {visible && (
        alerts.length > 0 ? alerts.filter((message, index) => index === 0).map(message => (
          <Fragment key={message.id}>
            {renderMessage(message)}
          </Fragment>
        )) :
          announcements.length > 0 ? announcements.filter((message, index) => index === 0).map(message => (
            <Fragment key={message.id}>
              {renderMessage(message)}
            </Fragment>
          )) :
            promotions.length > 0 ? promotions.filter((message, index) => index === 0).map(message => (
              <Fragment key={message.id}>
                {renderMessage(message)}
              </Fragment>
            )) : null
        )
            }
      </div>
  )
}

const mapStateToProps = state => ({
  messages: state.publicContent.messagesFiltered,
  donor: state.donors.donors?.data,
  loggedInUser: state.users.user,
  facility: state.centers.getCenterInfoSuccess
})

export default connect(mapStateToProps, null)(AlertBanner)
