import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss';

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const CreateAccessManagementHeader = () => {
    const { t } = useTranslation('CreateAccessManagement');
    const pageName=t('pageHeader');
    return (
          <div className='CreateAccessManagementHeader'>
              <div className='createHeader '>
                    <Header as='h4' className='FormHeaderText'>
                      {pageName}
                    </Header>
              </div>
          </div>
    )
  }
  
  export default CreateAccessManagementHeader