import React, { useState, useEffect, Fragment } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Header, Container } from 'semantic-ui-react'
import './styles.scss'

const DashboardMessagesSection = ({ messages, user }) => {
  const [messageList, setMessageList] = useState([]);
  const { t } = useTranslation('DashboardMessagesSection');

  useEffect(() => {
    setMessages()
  }, [messages, user])

  const setMessages = () => {
    let centerID = user?.defaultCenter?.sfid;
    if (messages && messages.length && centerID) {
      const _messages = messages.find(item => item.centerID === centerID);

      if (_messages && _messages.list.length) {
        const { list } = _messages;
        const _messageList = list.filter(item => item.message_type__c === 'Donor' && item.is_active__c).sort((m1, m2) => m2.id - m1.id);
        setMessageList(_messageList);
      }
    }
  }

  return (
    <Container fluid className='MessagesSection'>
      <Header as='h4' className='MessagesHeader'>
       {t('messages')}
      </Header>
      {messageList.map((message, index) => (
        <p key={index} className='MessageText'>
          {message.description__c}
        </p>
      ))}
    </Container>
  )
}

const mapStateToProps = state => ({
  messages: state.publicContent.messagesFiltered,
  user: state.users.loginHerokuSuccess,
})

export default connect(mapStateToProps, null)(DashboardMessagesSection)
