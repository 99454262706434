import React, {useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Container, Header, Icon, Image } from 'semantic-ui-react'
import { getOperationHours } from '../../Helpers/Utils'
import GoogleMapReact from 'google-map-react'
import { formatPhoneNumber } from '../../Helpers/Utils'
import './styles.scss'

const MapMarker = ({ text }) => (
  <div style={{ textAlign: 'center' }}>
    <Icon color='red' name='map marker alternate' size='large' />
  </div>
)

const FacilityDetails = (props) => {
  const { selectedFacility } = props
  const localBusinessSchema = {"@context": "https://schema.org", "@type": "MedicalBusiness", "openingHoursSpecification": []}
  const { t } = useTranslation('FacilityDetails');

  const buildOpeningHoursSchema = (facilityHours) => {
    const groupedHours = {};
    // Group objects by openingTime and closingTime
    facilityHours?.forEach(item => {
      const key = `${item.openingTime}-${item.closingTime}`;
      if (!groupedHours[key]) {
        groupedHours[key] = {
          opens: item.openingTime ? item.openingTime.slice(0, item.openingTime.lastIndexOf(":")) : null,
          closes: item.closingTime ? item.closingTime.slice(0, item.closingTime.lastIndexOf(":")) : null,
          dayOfWeek: [item.weekDayName],
        };
      } else {
        groupedHours[key]?.dayOfWeek?.push(item.weekDayName);
      }
    });

    // Convert groupedHours object to the desired output format
    const outputArray = Object.values(groupedHours).map(group => {
    const dayOfWeek = group.dayOfWeek?.length > 1 ? group.dayOfWeek : group.dayOfWeek[0];

      return {
        "@type": "OpeningHoursSpecification",
        dayOfWeek: dayOfWeek,
        opens: group.opens,
        closes: group.closes,
      };
    });

    return outputArray;
  }

  useEffect(() => {
    localBusinessSchema["name"] = selectedFacility.name
    localBusinessSchema["image"] = selectedFacility.webImageUrl
    localBusinessSchema["url"] = window.location.href
    localBusinessSchema["telephone"] = selectedFacility.telephone
    localBusinessSchema["address"] = {"@type": "PostalAddress", "streetAddress": selectedFacility.addressLine1, "addressLocality": selectedFacility.city, "addressRegion": selectedFacility.stateCode, "postalCode": selectedFacility.zipcode, "addressCountry": "US"}
    localBusinessSchema["geo"] = {"@type": "GeoCoordinates", "latitude": selectedFacility.latitude, "longitude": selectedFacility.longitude}
    localBusinessSchema["openingHoursSpecification"] = buildOpeningHoursSchema(selectedFacility.hoursOfOperation)

    const script = document.createElement('script')
    script.type = "application/ld+json"
    script.text = JSON.stringify(localBusinessSchema)
    document.head.appendChild(script)

    return () => document.head.removeChild(script)
  }, [])

  if (!selectedFacility.hoursOfOperation) return <div />
  return (
    <div className='FacilityDetails'>
      <Container>
        <Grid columns={2}>
          <Grid.Column computer={8} mobile={16} tablet={8}>
            <div className='HoursContainer'>
              <Header as='h2' className='SecondaryHeader'>
               {t('officeHours')}
              </Header>
              <Grid columns={2}>
                {selectedFacility.hoursOfOperation.map((ofh) => (
                  <Grid.Row key={ofh.weekDayName}>
                    <Grid.Column width={6}>
                      <p className='WeekDay'>{ofh.weekDayName}</p>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <p className='Hours'>{getOperationHours(ofh)}</p>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
              <p className="DifferHours">{t('HrsMayDiffOnMajorHolidays')}</p>
            </div>
            {false && (
            <div className='PlayroomContainer'>
              <Header as='h2' className='SecondaryHeader'>
                {t('playroomHours')}
              </Header>
              <Grid columns={2}>
                {selectedFacility.hoursOfOperation.map((ofh) => (
                  <Grid.Row key={ofh.weekDayName}>
                    <Grid.Column width={6}>
                      <p className='WeekDay'>{ofh.weekDayName}</p>
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <p className='Hours'>{getOperationHours(ofh)}</p>
                    </Grid.Column>
                  </Grid.Row>
                ))}
              </Grid>
            </div>
            )}
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={8}>
            <div className='MapContainer'>
              <Header as='h2' className='SecondaryHeader'>
                {t('location')}
              </Header>
              <div className='Map' style={{ height: '300px', width: '100%' }}>
                {selectedFacility && (
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyCqhmAA-4JUW-sJGZoB4ufuNIraE_JEDfM' }}
                    center={{
                      lat: selectedFacility.latitude,
                      lng: selectedFacility.longitude
                    }}
                    defaultZoom={14}
                  >
                    <MapMarker
                      key={selectedFacility.id}
                      lat={selectedFacility.latitude}
                      lng={selectedFacility.longitude}
                      text='BioLife Plasma Services'
                    />
                  </GoogleMapReact>
                )}
              </div>
              <div className='ContactInfo'>
                <p className='Address'>
                  {selectedFacility.addressLine1}
                </p>
                <p className='Address'>
                  {selectedFacility.city}, {selectedFacility.stateCode} {selectedFacility.zipcode}
                </p>
                <a href={`tel:${selectedFacility.telephone}`} className='Phone'>
                  {formatPhoneNumber(selectedFacility.telephone)}
                </a>
              </div>
            </div>
            </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={8}>
            <div className='OurCenterImage'>
              <Header as="h1" className='ImageTitle'> { selectedFacility?.centerUniqueImageTitle } </Header>
              <div className='UniqueImageContainer'>
                <Image src={selectedFacility?.centerUniqueImageUrl} alt="img" />
              </div>
              <p className='ImageDescription'>{ selectedFacility?.centerUniqueImageDescription }</p>
            </div>
          </Grid.Column>
          <Grid.Column computer={8} mobile={16} tablet={8}>
            <div className='AmenitiesContainer'>
              <Header as='h2' className='SecondaryHeader'>
              {t('amenities')}
              </Header>
              <div>
                <div dangerouslySetInnerHTML={{ __html: selectedFacility.amenities }} />
                <p className='Note'>
                 {t('bioLifePlasmaWarningOnFeatures')}
                </p>
              </div>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    </div>
  )
}

export default FacilityDetails
