import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import RouteWrapper from '../Helpers/RouteWrapper'

const RoutePublic = ({
  component: Component,
  isAuthenticated,
  to,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => isAuthenticated ? <Redirect to={to} /> : <RouteWrapper component={Component} {...props}/>}
  />
)

export default RoutePublic
