import React, { useState, useEffect } from 'react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const ScheduleAppointmentModal = importer('Components/CenterOps/ScheduleAppointmentModal')
const ModifyPerson = importer('Components/CenterOps/ModifyPerson')
const ScheduleDayActions = importer('Components/CenterOps/ScheduleDayActions')

const CellPhysicalsData = ({
  physicals,
  toggleModal,
  onClickModifyHandler,
  onClickScheduleHandler,
  appointment,
  time,
  toogleQuickPhysicalModal,
  physicalAdjustmentAppointments,
  physicalScheduledAppointments,
  appointmentTime,
  getSlotsForAppointmentType,
  getAppSlotsHandler,
  reloadCalendar,
  donationCenter,
  isAddButtonDisabled,
  centerInfo,
  needPlayRoom
}) => {
  const [appointmentType, setAppointmentType] = useState('PHYSICAL')
  const [openScheduleAppointment, setOpenScheduleAppointment] = useState(false)
  const toggleScheduleAppointment = () => setOpenScheduleAppointment(!openScheduleAppointment)

  const [searchResults, setSearchResults] = useState([])
  const [tempSearchResults, setTempSearchResults] = useState([])
  const [isNewDonorSearch, setIsNewDonorSearch] = useState(false)
 
  useEffect(() => {
    if (isNewDonorSearch) {
      setTempSearchResults([])
    } else {
      setTempSearchResults(searchResults)
    }
  }, [isNewDonorSearch, searchResults])

  const handleClearSearchResults = (searchData) => {
    setSearchResults(searchData)
  }

  return (
    <div className='CellData'>
      {physicals && physicals.map((physical, index) => (
          <ModifyPerson
            person={physical.donorName}
            speOnly={physical.speOnly}
            isQuickPhysical={physical.isQuickPhysical}
            donorSfid={physical.donorSfid}
            appointmentId = {physical.appointmentId}
            appointmentType = {appointmentType}
            numberOfChildren={physical.numOfKids}
            language={physical.language}
            key={index}
            toggleModal={toggleModal}
            onClickModify={onClickModifyHandler}
            getSlotsForAppointmentType = {getSlotsForAppointmentType}
            getAppSlotsHandler = {getAppSlotsHandler}
            time={time}
            appointment = {appointment}
            appointmentTime = {appointmentTime}
            donationCenter = {donationCenter}
            isAddButtonDisabled={isAddButtonDisabled}
          />
        ))}
      <ScheduleDayActions
        scheduled={physicalScheduledAppointments}
        maxAllowed={physicalAdjustmentAppointments}
        toggleScheduleAppointment={toggleScheduleAppointment}
        setAppointment={onClickScheduleHandler}
        appointment={appointment}
        time={time}
        isAddButtonDisabled={isAddButtonDisabled}
        appointmentDateTime = {appointmentTime}
        setIsNewDonorSearch={setIsNewDonorSearch}
        isNewDonorSearch={isNewDonorSearch}
      />
      <ScheduleAppointmentModal
        needPlayRoom={needPlayRoom}
        open={openScheduleAppointment}
        toggleScheduleAppointment={toggleScheduleAppointment}
        appointmentDateTime = {appointmentTime}
        appointmentTime={appointment.time}
        maxAllowed={physicalAdjustmentAppointments}
        totalScheduled={physicalScheduledAppointments}
        appointment = {appointment}
        toogleQuickPhysicalModal={toogleQuickPhysicalModal}
        donationType = {appointmentType}
        reloadCalendar={reloadCalendar}
        donationCenter={donationCenter}
        centerInfo={centerInfo}
        handleClearSearchResults={handleClearSearchResults}
        tempSearchResults={tempSearchResults}
        setIsNewDonorSearch={setIsNewDonorSearch}
        isNewDonorSearch={isNewDonorSearch}
      />
    </div>
  )
}

export default CellPhysicalsData
