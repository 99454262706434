export const ROUTES = {
  /* AUTH */
  AuthConfirmSignUp: 'AuthConfirmSignUp',
  AuthForgotPassword: '/donor-forgot-password',
  AuthResetPassword: '/reset-password',
  AuthSignIn: '/login',
  AuthSignUp: '/registration',
  AuthAuthorize: '/authorize',
  /* DASHBOARD */
  Dashboard: '/dashboard',
  DonorDashboard: '/dashboard/donor-dashboard',
  CenterDashboard: '/center-ops/donor-search',
  CreateProfile: 'CreateProfile',
  EmailConfirmation: '/email-confirmation',
  NoCenters: '/',
  Welcome: '/',
  SignIn: '/login',
  LoyaltyNotEligible: '/not-eligible-loyalty',
  LoyaltyDashboard: '/dashboard/my-rewards'
}

export const navigate = (routeName, params) => {
  window.location.href = routeName
}

export const navigateCustom = (route) => {
  window.location.href = route
}

export const genAuthUrl = (redirect) => {
  const local = window.location.href
  const arr = local.split('/')
  const returnUrl = arr[0] + '//' + arr[2] + redirect
  // console.log(returnUrl)
  return encodeURIComponent(returnUrl)
}
