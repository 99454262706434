import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import './styles.scss'

const PaymentTabs = ({
  tab,
  setTab,

}) => {
  const { t } = useTranslation('PaymentTabs');
  return (
    <ul className='PaymentTabs'>
      <div style={{ display: 'flex' }}>
        <li
          className={tab === 'Debitcard' ? 'tabs active' : 'tabs'}
          onClick={() => {
            setTab('Debitcard')
          }}
        >
          {t('Debitcard')}
        </li>
        <li
          className={tab === 'PaymentHistory' ? 'tabs active' : 'tabs'}
          onClick={() => {
            setTab('PaymentHistory')
          }}
        >
          {t('PaymentHistory')}
        </li>

      </div>

    </ul>
  )
}

export default PaymentTabs
