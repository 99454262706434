import React from 'react'
import { Header, Grid, Table, Pagination, Dropdown } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import moment from 'moment'
import { formatPhoneNumber } from '../../../Helpers/Utils'
import './styles.scss'

const ReportsSearchResults = ({
  page,
  totalPages,
  searched,
  operations,
  onExport,
  onChangePage
}) => {
  const { t } = useTranslation('ReportsSearchResults');
  if (!searched) {
    return null
  }

  const exportOptions = [
    { key: '1', value: 'pdf', text: t('pdf') },
    { key: '2', value: 'excel', text: t('excel') }
  ]

  if (operations) {
    const {
      centerCurrentTime,
      centerAddress,
      reportFromDate,
      reportToDate,
      appointments
    } = operations
    return (
      <div className='ReportsSearchResults'>
        <Grid columns={2}>
          <Grid.Row>
            <Grid.Column verticalAlign='middle'>
              <Header as='h4' className='Header'>
                {t('reportResults')}
              </Header>
            </Grid.Column>
            <Grid.Column textAlign='right'>
              <Dropdown
                className='Export'
                item
                text={t('export')}
                icon='chevron down'
                name={t('potentialDonorReport')}
                disabled={totalPages === 0}
              >
                <Dropdown.Menu>
                  {exportOptions.map(item =>
                    <Dropdown.Item key={item.key} onClick={(e, { value }) => onExport(value)} value={item.value} text={item.text} />
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column verticalAlign='bottom'>
              <p className='RegularText'>
                <b>{t('reportDates')}</b>{' '}
                {moment.utc(reportFromDate).format('MMMM D, YYYY')} to{' '}
                {moment.utc(reportToDate).format('MMMM D, YYYY')}
              </p>
              <p className='RegularText'>
                <b>{t('center')}</b> {centerAddress}
              </p>
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='bottom'>
              <p className='RegularText'>
                <b>{t('reportGenerated')}</b>{' '}
                {moment(centerCurrentTime).format('MMMM D, YYYY [at] hh:mmA')}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div className='Results'>
          <div className='ResultsTableContainer'>
            {appointments?.length === 0 ? (
              <div className='NoResult'>
                {t('noresultsfound')}
              </div>
            ) :
              <Table basic='very' selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>{t('date')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('time')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('donorName')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('phonenumber')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('languageSupport')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('type')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('numberofkids')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('pdn')}</Table.HeaderCell>
                    <Table.HeaderCell>{t('giftCardOptIn')}</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {appointments.map((app, i) => (
                    <Table.Row key={`search-donor-${i}`}>
                      <Table.Cell>
                        {moment.parseZone(app.startDateTime).format('MM/DD/YYYY')}
                      </Table.Cell>
                      <Table.Cell>
                        {app.startTime}
                      </Table.Cell>
                      <Table.Cell>{app.donorName}</Table.Cell>
                      <Table.Cell>
                        {formatPhoneNumber(app.donorPhoneNumber)}
                      </Table.Cell>
                      <Table.Cell>{app.language === 'es-MX' ? 'Español' : ''}</Table.Cell>
                      <Table.Cell>{app.appointmentType === 'PHYSICAL' ? t('physicalwithDonation') : t('donation')}</Table.Cell>
                      <Table.Cell>{app.numberOfChildren}</Table.Cell>
                      <Table.Cell>{app.donorPdn}</Table.Cell>
                      <Table.Cell>{app.giftCardOptIn === 'Yes' ? t('yes') : app.giftCardOptIn === 'No' ? t('no') : null}</Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            }
          </div>
          {totalPages > 0 && (
            <div className='PaginationContainer'>
              <span className='PageText'>{t('page')}</span>
              <Pagination
                secondary
                boundaryRange={3}
                activePage={page}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={totalPages}
                onPageChange={onChangePage}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  return null
}

export default ReportsSearchResults
