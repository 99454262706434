import React from 'react'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'

import { Menu } from 'semantic-ui-react'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
const { ScrollToTop } = importer('Helpers/Utils', null)

const SecondaryMenu = ({
  items,
  menuSpread = 'horizontal',
  additionalClass=''
}) => {
  const { i18n } = useTranslation('FactoryFunctionTexts');
  return (
    <Menu pointing secondary className={`SecondaryMenu ${menuSpread}`} borderless compact>
      {items?.map((item, index) => {
        if (item.external || item.to.includes('http')) {
          return (
            <Menu.Item
              className='SecondaryMenuItem'
              name={item.name}
              key={item.id}
              as='a'
              href={item.to}
              index={index}
              content={item.name}
              target='_blank'
            />
          )
        }
        return (
          <Menu.Item
            className={`SecondaryMenuItem ${additionalClass} ${i18n.language}`}
            name={item.name}
            key={item.id}
            as={NavLink}
            to={item.to}
            index={index}
            content={item.name}
            onClick={()=> ScrollToTop()}
          />
        )
      })}
    </Menu>
  )
}

export default SecondaryMenu
