import React from 'react'
import { Header, Container, Grid } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import moment from 'moment'
import './styles.scss'
import { useTranslation } from 'react-i18next';
import { isNull } from 'lodash'

const LocationsList = (props) => {
  const { centers, locationState } = props
  const { t } = useTranslation('LocationsList');
  
  const getRedirect = (state, centerUrlName) => {
    if(state.includes(" ")){
      return locationState ? `${state.split(" ").join('-').toLowerCase()}/${centerUrlName}` :centerUrlName ? `/locations/${state.split(" ").join('-').toLowerCase()}/${centerUrlName}` : `/locations/${state.split(" ").join('-').toLowerCase()}`
    }
    return locationState ? `${state.toLowerCase()}/${centerUrlName}` : centerUrlName ? `/locations/${state.toLowerCase()}/${centerUrlName}` : `/locations/${state.toLowerCase()}`
  }

  const checkCenterWithPubDate=(facilities)=>{
    for (let i = 0; i < facilities.length; i++) {
      if(moment(facilities[i].publication_date__c) <= moment())
      return true;
    }
     return false;
  }
  return (
    <Container className='LocationsList'>
      <Grid.Row>
        <Header as='h2' className='MainHeader'>
         {t('locations')}
        </Header>
      </Grid.Row>
      <Grid.Row>
        <Grid columns='3' className='LocationsListContent'>
          {centers &&
            centers.length &&
            centers.map((item, index) => (item.facilities && item.facilities.length>0 && checkCenterWithPubDate(item.facilities) &&
              <Grid.Column key={index} computer={4} tablet={8} mobile={8}>
               <Header as='h2'>
                 <NavLink className='SecondaryHeader' to={getRedirect(item.state)} onClick={() => scrollTo({top: 0, behavior: "auto"})}>
                 {item.state}
                 </NavLink>
                </Header>
                <ul className='CitiesContent'>
                  {item.facilities &&
                    item.facilities.length &&
                    item.facilities.sort((a, b) => a.name < b.name ? -1 : 1).map((f_item, f_index) => {
                      return moment(f_item.publication_date__c) <= moment() && isNull(f_item.is_center_hidden_from_donors__c) && (
                        <li key={f_index}>
                          <NavLink className='Facility' to={getRedirect(item.state, f_item.center_url_name__c)}>
                            {f_item.name}
                          </NavLink>
                        </li>
                      )
                    })
                  }
                </ul>

          </Grid.Column>
            ))}
        </Grid>
      </Grid.Row>
    </Container>
  )
}
export default LocationsList
