import React, { useState, useEffect } from 'react'
import { Dropdown, Input, Loader, Visibility } from 'semantic-ui-react'
import './styles.scss'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
const centersActions = importer('Stores/Centers/Actions')
import { mapOptionsByState,splitCenterName } from "../../../Helpers/Utils"
import { useTranslation } from 'react-i18next';
import exclamationcircle from '../../../../assets/media/icons/exclamationRedCircle.svg'
import infoIcon from '../../../../assets/media/images/info-icon.png'

const SelectSearch = (props) => {
  const { t, i18n } = useTranslation('SelectSearch');
  const { donor, loggedInUser, options, centers, centerOpsSearch, centerID, className, handleOnChange, placeholder, defaultValue, alwaysShowPlaceholder,placeholderVal, allowZipcodeSearch, searchedCenters, searchCentersLoading, getZipcodeSearchCenters,topLabel, required, hasError,errorMessage } = props
  const [centerList, setCenterList] = useState(options)
  const [selectedValue, setSelectedValue] = useState({})
  const [updated, setUpdated] = useState(false)
  const [userInput, setUserInput] = useState('')

  const preparedOptions = async (searchedCenters) => {
    const res = await mapOptionsByState(searchedCenters,false,loggedInUser)
    return res
  }

  useEffect(() => {
    if (donor?.pdn !== null) {
      preparedOptions(searchedCenters)
      .then(res => res && setCenterList(res))
    }
  },[searchedCenters])

  useEffect(() => {
    const currentName = selectedValue?.name
    setSelectedValue({
      ...selectedValue,
      name: defaultValue ? defaultValue : currentName,
    })
  }, [defaultValue])


  const _handleOnChange = (e, {value}) => {
    setUserInput(value)
    if(allowZipcodeSearch && !isNaN(parseInt(value,10)) && value.length === 5){
      getZipcodeSearchCenters(value, true, 'disDate')
    }
    else if (centerOpsSearch){
      handleCenterOpsFilter(value)
    }
    else{
      handleFilter(value)
    }
  }

  const handleFilter = async (value) => {
    let finalFilteredCenters = [];
    if(value.length > 1){
      const filteredStates = options.filter(({state,stateCode}) => {
        return (state.substring(0,value.length).toLowerCase()===value.toLowerCase()) 
        || (stateCode?.toLowerCase()===value.toLowerCase())
      })
      finalFilteredCenters.push(...filteredStates)
      const filteredCenters = centers.filter(item => 
        item.name.substring(0,value.length).toLowerCase()===value.toLowerCase())
      await mapOptionsByState(filteredCenters,false,loggedInUser).then(res => finalFilteredCenters.push(...res))
      setCenterList(finalFilteredCenters)
      }
      else{
        setCenterList(options)
      }
    }

    const handleCenterOpsFilter = async (value) => {
      let finalFilteredCenters = [];
      if(value.length > 1){
        const filteredStates = options.filter(({state,stateCode}) => {
          return (state.substring(0,value.length).toLowerCase()===value.toLowerCase())
          || (stateCode?.toLowerCase()===value.toLowerCase())
        })
        finalFilteredCenters.push(...filteredStates)
        const filteredCenters = centers.filter(({details}) => 
          details.name.substring(0,value.length).toLowerCase()===value.toLowerCase())
        await mapOptionsByState(filteredCenters, true,loggedInUser).then(res => finalFilteredCenters.push(...res))
        setCenterList(finalFilteredCenters)
        }
        else{
          setCenterList(options)
        }
    }

  const findSelectedObj = (arr, value) => arr.find((op) => op.sfid === value)
  const handleSelection = (option) => {
    setSelectedValue(option)
    setUpdated(true)
    handleOnChange(option)
  }
  useEffect(() =>{
    setCenterList(options)
    if (centerID) {
     const defaultOption = findSelectedObj(centers, centerID)
      if (defaultOption) {
        handleSelection({
          key: defaultOption?.sfid,
          zipCode: defaultOption?.zipcode,
          value: defaultOption?.sfid,
          name:  loggedInUser.role === 'Admin' ? defaultOption?.name : splitCenterName(defaultOption?.name),
          text: `${loggedInUser.role === 'Admin' ? defaultOption.name : splitCenterName(defaultOption.name)} Center`
       })
      }
    }
  }, [options])
  useEffect(() => {
    if (centerID) {
      const defaultOption = findSelectedObj(centers, centerID)
      if (defaultOption) {
        handleSelection({
          key: defaultOption?.sfid,
          zipCode: defaultOption?.zipcode,
          value: defaultOption?.sfid,
          name: loggedInUser.role === 'Admin' ? defaultOption?.name : splitCenterName(defaultOption?.name),
          text: `${loggedInUser.role === 'Admin' ? defaultOption.name : splitCenterName(defaultOption.name)} Center`
       })
      }
      else {
        setSelectedValue({
          key: centers[0]?.sfid,
          zipCode: centers[0]?.zipcode,
          value: centers[0]?.sfid,
          name: loggedInUser.role === 'Admin' ? centers[0]?.name : splitCenterName(centers[0]?.name),
          text: `${loggedInUser.role === 'Admin' ? centers[0].name :splitCenterName(centers[0]?.name) } Center`
       })
      }
    }
  }, [])
  
  const handleOnInputClick = (e) => {
    e.stopPropagation();
  }
  const handleOnInputKeyDown = (e) => {
    if(e.keyCode === 32){    //checking only for space
      e.stopPropagation();
    }
  }

  const _onBlur = () => {
    setUserInput('')
    setTimeout(() => {
      setCenterList(options)
    }, 1000)
  }

  return (
    <Visibility className={`SelectSearch ${hasError?"error":""}`}>
    {topLabel && <small className="TopLabel searchDropDown">{topLabel}</small>}  
    {required && <i aria-hidden="true" className="asterisk tiny icon Required"></i>}
      <Dropdown
        floating
        closeOnEscape
        fluid
        icon='chevron down'
        className={`Base ${className}`}
        trigger={(!updated && placeholder) || (alwaysShowPlaceholder && placeholder) ? placeholder : `${loggedInUser.role === 'Admin' ? selectedValue.name : splitCenterName(selectedValue.name)} Center`}
      >
        <Dropdown.Menu>
          <Input id="searchInput" placeholder={placeholderVal?placeholderVal: t('enterStateOrCenterName')} className='Filter' 
          value={userInput}
          onBlur={() => _onBlur()} 
          onClick={(e) => handleOnInputClick(e)}
          onKeyDown={(e) => handleOnInputKeyDown(e)} 
          onChange={_handleOnChange} />
          <Dropdown.Divider />
          {searchCentersLoading? 
            <div style={{padding: '20px'}}>
              <Loader active size='small' inline='centered' />
            </div> : 
            <Dropdown.Menu scrolling>
              {centerList?.map(({state,facilities}) => (
                <>
                  <Dropdown.Header content={state} />
                  {facilities?.map(option => 
                    <Dropdown.Item key={option.value} {...option} onClick={() => handleSelection(option)} />
                  )}
                  <Dropdown.Divider />
                </>
              ))}
            </Dropdown.Menu>
          }
        </Dropdown.Menu>
      </Dropdown>
    {hasError && <div className='selectSearchErrorMessage'><img className='ErrorIcon' src={infoIcon}/>&nbsp;<small src={exclamationcircle} className='TopLabelError'>{errorMessage}</small></div>}
    </Visibility>
  )
}

SelectSearch.defaultProps = {
  className: 'SelectSearch'
}

const mapStateToProps = (state) => ({
  searchedCenters: state.centers.getZipcodeSearchCentersSuccess?.data,
  searchCentersLoading: state.centers.getZipcodeSearchCentersLoading,
  donor: state.donors?.donors?.data,
  loggedInUser: state.users?.user
})

const mapDispatchToProps = (dispatch) => ({
  getZipcodeSearchCenters: (searchStr, isDIS8Center, searchType) => dispatch(centersActions.getZipcodeSearchCenters(searchStr, isDIS8Center, searchType)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectSearch)