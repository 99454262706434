import React, { useState, useEffect } from 'react'
import { Grid, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import EsIcon from '../../../../assets/media/icons/ES-icon.svg'
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'
import moment from 'moment'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const ModifyPerson = ({
  person,
  isQuickPhysical,
  donorSfid,
  appointmentId,
  appointmentType,
  appointment,
  numberOfChildren,
  time,
  appointmentTime,
  donationCenter,
  toggleModal,
  onClickModify,
  getAppSlotsHandler,
  isAddButtonDisabled,
  speOnly,
  language
}) => {
  const { t } = useTranslation('ModifyPerson');
  const [isModifySubmitted, setIsModifySubmitted] = useState(false)

  const handleOnClick = () => { 
    setIsModifySubmitted(true)
    getAppSlotsHandler({appointmentType, appointmentId, numberOfChildren, donorSfid })
    onClickModify({
      ...appointment,
      name: person,
      donationType: appointmentType,
      date: moment.parseZone(appointmentTime).format('MMMM D, YYYY'), 
      weekDay: moment.parseZone(appointmentTime).format('ddd'),
      numberOfChildren: numberOfChildren,
      time: time,
      id: appointmentId,
      donorSfid: donorSfid,
      appointmentSlotDateTime: appointmentTime,
      donationCenter: donationCenter
    })
    toggleModal()
  }

  const getModifyButtonClass = () => {
    if(isAddButtonDisabled){
      return 'HideModifyButton'
    }
    return 'ModifyButton'
  }
  const getDonorTextClass = () => {
    return 'Donor'
  }

  return (
    <div className='ModifyPerson'>
      <Grid columns={3}>
        <Grid.Column width={12}>
          <p className={getDonorTextClass()}>
            {person} {speOnly && <span className="speOnly">{t('speOnly')}</span>}
            {isQuickPhysical && <span className="QuickPE"> {t('quickPE')}</span>}
            {language === 'es-MX'? <Image className='spanish-logo' src={EsIcon} height="26" width="26"/>:''}
          </p>
        </Grid.Column>
        <Grid.Column textAlign='right' width={2}>
          <PrimaryButton
            text={t('modify')}
            className={getModifyButtonClass()}
            height={30}
            width={70}
            fontSize={14}
            onClick={handleOnClick}
          />
        </Grid.Column>
      </Grid>
    </div>
  )
}

export default ModifyPerson
