import React, { useEffect } from 'react'
import { connect } from 'react-redux'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
/* ACTIONS */
const UserActions = importer('Stores/Users/Actions')
/* CONTAINERS */
const AppContainer = importer('Containers/App')
import { useTranslation } from 'react-i18next'

const LogOut = ({
  signOut
}) => {
  useEffect(() => {
    window.sessionStorage.removeItem('logoutTimeOut')
    signOut()
  }, [])
  const {t} = useTranslation('LogOut')

  return (
    <AppContainer compact title={t('Logout')}>
      {t('pleaseWait')}
    </AppContainer>
  )
}

const mapStateToProps = state => ({
  logoutSuccess: state.users.logoutSucess
})

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(UserActions.logout())
})

export default connect(mapStateToProps, mapDispatchToProps)(LogOut)
