import React from 'react'
import { Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const NoResultsCalendar = ({
  toggleScheduleAppointment,
  toogleQuickPhysicalModal,
  quickPhysicalHandler,
  setQuickPhysicalError
}) => {
  const { t } = useTranslation('noResultsCalendar');
  return (
    <div className='NoResultsCalendar'>
      <div className='MessageContainer'>
        <div className='RedArea'>
          <p className='ErrorMessage'>{t('noResultsFound')}</p>
        </div>
      </div>
      <div className='ResultsActions'>
        <Grid columns={2}>
          <Grid.Column textAlign='left'>
            <PrimaryButton
              text={t('scheduleQuickPhysica')}
              width={282}
              height={50}
              fontSize={16}
              onClick={() => {
                quickPhysicalHandler()
              }}
            />
          </Grid.Column>
          <Grid.Column textAlign='right'>
            <PrimaryButton
              text={t('cancel')}
              width={145}
              height={50}
              fontSize={16}
              classType='Inverted'
              onClick={ () => {
                setQuickPhysicalError(false)
                toggleScheduleAppointment()
              }
              }
            />
          </Grid.Column>
        </Grid>
      </div>
    </div>
  )
}

export default NoResultsCalendar
