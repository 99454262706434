import React from 'react'

import { Popup } from 'semantic-ui-react'

import './styles.scss'

const SuperDropdownMenu = ({
  trigger,
  content,
  parent,
  dropSpread = 'horizontal'
}) => {
  return (
    <Popup
      className='SuperDropdownMenu'
      trigger={trigger}
      content={content}
      on={['hover', 'click']}
      position='bottom right'
      basic
      hoverable
      style={dropSpread === 'horizontal' && {
        minWidth: parent === 'becomeAdonor' ? '95%' : '90%',
        left: 40,
        paddingRight: parent === 'becomeAdonor' ? 0 : '8%',
        paddingLeft: parent === 'becomeAdonor' ? '1%' : '8%'
      }}
    />
  )
}

export default SuperDropdownMenu
