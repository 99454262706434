import React, { useEffect, useState } from "react";
import { Modal, Grid, Header, Image, Loader, Visibility } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import GiftCardImage from "../../../../assets/media/images/biolife-gift-card.png";
import DebitCardImage from "../../../../assets/media/images/biolife-debit-card.png";
import CloseIcon from "../../../../assets/media/icons/icon-close-web.png";

import "./styles.scss";
import importer from "@takedapdt/biolife-core/src/importer";

const PrimaryButton = importer("Components/Buttons/PrimaryButton");
const CheckboxCard = importer("Components/Dashboard/ChangePaymentMethodModal/CheckboxCard");

// Actions
const appointmentsActions = importer("Stores/Appointments/Actions");

const ChangePaymentMethodModal = ({
  open,
  toggleModal,
  appointment,
  updateGiftCardPreference,
  updateGiftCardPreferenceLoading,
  updateGiftCardPreferenceSuccess,
  updateGiftCardPreferenceFailure,
  setOpenModal,
}) => {
  const { t, i18n } = useTranslation("ChangePaymentMethod");

  const selectedPaymentOption = appointment?.giftCardOptIn;
  const [isGiftcardOptionSelected, setIsGiftcardOptionSelected] = useState();
  const [width, setWidth] = useState('')
  const [saveClicked, setSaveClicked] = useState(false)

  const buttonWidth = width < 480 ? 140 : 194
  const handleUpdate = (e, { calculations }) => setWidth(calculations.width)

  /** 
   * This method calls the updateGiftCardPreference api 
   * on saving the changed payment method
   */
  const handleGiftcardOptionChange = () => {
    const giftCardPreference = {
      appointmentId: appointment?.appointmentId,
      giftCardOptIn: isGiftcardOptionSelected,
    };
    updateGiftCardPreference(giftCardPreference);
  };

  const closeModal = () => {
    toggleModal();
    setIsGiftcardOptionSelected(selectedPaymentOption);
    setSaveClicked(false);
  }

  useEffect(() => {
    setIsGiftcardOptionSelected(selectedPaymentOption);
  }, [selectedPaymentOption]);

  /**
   * This hook used for closing modal after successful api calling
   * and getting data in success handler from api
   */
  useEffect(() => {
    if (!updateGiftCardPreferenceLoading && updateGiftCardPreferenceSuccess !== null && !updateGiftCardPreferenceFailure) {
      setOpenModal(false);
      setSaveClicked(false);
    }
  }, [updateGiftCardPreferenceLoading]);

  return (
  <Visibility onUpdate={handleUpdate}>
    <Modal
      open={open}
      onClose={() => {
        closeModal();
      }}
      className="ChangePaymentMethodModal"
    >
      <Modal.Header className="HeaderContainer">
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as="h2" className="ModalHeader">
              {t("Change Payment Method")}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign="right">
            <Image src={CloseIcon} alt="Close" onClick={() => {closeModal();}} disabled={updateGiftCardPreferenceLoading}/>
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content className="ModalContent">
        <Grid.Row>
          <div className="DescriptionTopContainer">
            <p className="RegularText">
              {t("You can change Payment Method")}
            </p>
          </div>
        </Grid.Row>


        <div className="checkbox-group">
          <Grid columns={2}>
            <CheckboxCard
              isGiftcardOptionSelected={isGiftcardOptionSelected}
              handleGiftcardOptionChange={setIsGiftcardOptionSelected}
              value='No'
              checkboxIcon={DebitCardImage}
              altText="DebitCard"
              label={t("BioLife Debit Card")}
            />
            <CheckboxCard
              isGiftcardOptionSelected={isGiftcardOptionSelected}
              handleGiftcardOptionChange={setIsGiftcardOptionSelected}
              value='Yes'
              checkboxIcon={GiftCardImage}
              altText="GiftCard"
              label={[<>{t("BioLife Smart Card")}<br/>{t("topbrands")}</>]}
            />

          </Grid>
        </div>

        <Grid.Row>
          <div className="DescriptionBottomContainer">
            <p className="RegularText">
              {t("see")}
              <a href={(appointment?.giftCardProgram === "profit share") ? '/legal/gift-card-pilot-profit-share-terms-and-conditions' :
                (appointment?.giftCardProgram === "equal value") ? '/legal/gift-card-pilot-equal-value-terms-and-conditions' : '/legal'}
                target='_blank' style={{ textDecoration: "underline", color: "#ac0079" }}>
                {t("termsandcondition")}
              </a>
               {t("forbiolifesmartcard")}
               <a href={(i18n.language === 'en-US') ? 'https://info.biolifeplasma.com/SmartCard' :
                 'https://info.biolifeplasma.com/ESSmartCard'}
                 target='_blank' style={{ textDecoration: "underline", color: "#ac0079" }}>
                {t("learnmore")}
              </a>
            </p>
          </div>
        </Grid.Row>

          {updateGiftCardPreferenceFailure && saveClicked &&
            <Grid.Row className="ErrorText">
              <span>{updateGiftCardPreferenceFailure?.response?.data?.message}</span>
            </Grid.Row>
          }

        <Modal.Actions>
          <Grid.Row className="ActionsContainer">
            <span className="ButtonContainer">
              <PrimaryButton
                text={t("Cancel")}
                width={buttonWidth}
                height={50}
                fontSize={16}
                classType="Inverted"
                onClick={() => {
                 closeModal();
                }}
                disabled={updateGiftCardPreferenceLoading}
              />
            </span>
            <span className="ButtonContainer">
              <PrimaryButton
                text={updateGiftCardPreferenceLoading ? <span><Loader active inline inverted size='mini' /> {t("Loading")}</span> : t("Save")}
                width={buttonWidth}
                height={50}
                fontSize={16}
                classType="Primary"
                onClick={() => {
                  setSaveClicked(true);
                  handleGiftcardOptionChange();
                }}
              />
            </span>
          </Grid.Row>
        </Modal.Actions>
      </Modal.Content>
    </Modal>        
  </Visibility>
  );
};

const mapStateToProps = (state, appointments) => ({
  app: state.appointments.appointment?.data,
  donor: state.donors.donors?.data,
  updateGiftCardPreferenceFailure:
    state.appointments.updateGiftCardPreferenceFailure,
  updateGiftCardPreferenceLoading:
    state.appointments.updateGiftCardPreferenceLoading,
  updateGiftCardPreferenceSuccess:
    state.appointments.updateGiftCardPreferenceSuccess?.data,
  getUpcomingAppointmentsLoading:
    state.appointments.getUpcomingAppointmentsLoading
});

const mapDispatchToProps = (dispatch) => ({
  updateGiftCardPreference: (giftCardPreference) =>
    dispatch(appointmentsActions.updateGiftCardPreference(giftCardPreference)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePaymentMethodModal);
