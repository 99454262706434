import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import './styles.scss'

const IconButton = (props) => {
  const { name, handleOnClick } = props

  return (
    <Button icon onClick={handleOnClick} className='IconButtonMenu'>
      <Icon size='large' name={name} />
    </Button>
  )
}

export default IconButton
