import React, { useEffect} from "react";
import importer from "@takedapdt/biolife-core/src/importer";
import { connect } from "react-redux";
import { Grid } from "semantic-ui-react";
import { Image } from "semantic-ui-react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const { ArticleByIdentifier } = importer("Stores/PublicContent/Select", null);
const { webArticles } = importer("Helpers/WebArticles");
const { isMobileWeb } = importer("Helpers/Utils", null);
const publicContentActions = importer("Stores/PublicContent/Actions");
const PageContentAccordion = importer("Components/PageContentAccordion");

const WaystoEarnCard = ({ publicContent, loyaltyMilestones, navigateToRedeemRewards, donor, getLoyaltyMilestones }) => {
  const { t,i18n } = useTranslation("WaystoEarnCard");
  useEffect(() => {
    document.addEventListener("click", onDocumentClick);
    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, []);

  useEffect(() => {
    if (donor) {
      getLoyaltyMilestones(donor?.defaultCenter?.sfid);
    }
  }, [donor, i18n.language]);

   const onDocumentClick=(e)=>{
    const el =document.querySelector('div[class*="content active"] a[href*="redeem-rewards"] strong u')
    if (e.target===el) {
      e.preventDefault()
      navigateToRedeemRewards(true)
      window.scrollTo({top: 0, behavior: "smooth"})
    }
   }

  const loyaltyfqasContent = ArticleByIdentifier(
    publicContent,
    webArticles.LOYALTY_FAQS
  );

  return (
    <div>
      <div className="WaystoEarnCardContainer">
      <p className="desc">{t("SubText")}</p>

        {isMobileWeb() ? (
          <div className="mobileEarnContainer">           
            {loyaltyMilestones?.length > 0 ? (
              loyaltyMilestones.filter((milestone) => milestone.bll_active__c)
              .sort((a, b) => a.bll_top_earn_activity_order__c - b.bll_top_earn_activity_order__c)
              .map((milestone) => (
                <div className="Loyalty">
                  <div className="row1">
                  <div style={{backgroundColor: 'white', width: 80, height: 80, borderRadius: '50%', display: 'flex', justifyContent: 'center', padding: 10, aspectRatio: '1/1'}}>
                    <Image
                      className="EarnImage"
                      src={milestone.bll_milestone_icon_url__c}
                    />
                    </div>
                    <p className="RedeemTextPoints">
                    {`${milestone.bll_points__c} ${t("points")}`}
                    </p>
                  </div>
                  <div className="row2">
                    <p className="milestoneTitle">
                      {milestone.bll_milestone_title__c}
                    </p>
                  </div>
                  <div className="row3">
                    <p className="milestoneDesc">
                      {milestone.bll_milestone_sub_title__c}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div></div>
            )}

          </div>
        ) : (
          <div>
            <Grid>
          <Grid.Column mobile={16} tablet={8} computer={9}>
            <h2 className="titleForWaysTab">{t("WaystoEarnlabel")}</h2>
          </Grid.Column>
          <Grid.Column mobile={16} tablet={8} computer={7}>
            <h2 className="points">{t("points")}</h2>
          </Grid.Column>
        </Grid>
        {loyaltyMilestones?.length > 0 ? (
              loyaltyMilestones.filter((milestone) => milestone.bll_active__c)
              .sort((a, b) => a.bll_top_earn_activity_order__c - b.bll_top_earn_activity_order__c)
              .map((milestone) => (
                <div className="loyaltyHead" key={milestone.id}>
                  <Grid columns={4}>
                  <Grid.Column width={2} style={{margin: 'auto'}}>
                      <div style={{backgroundColor: 'white', width: 80, height: 80, borderRadius: '50%', display: 'flex', justifyContent: 'center', padding: 10}}>
                        <Image  className="EarnImage"
                        src={milestone.bll_milestone_icon_url__c} />
                           </div>
                      </Grid.Column>
                    <Grid.Column width={5}>
                      <p className="webMilestoneTitle">
                        {milestone.bll_milestone_title__c}
                      </p>
                    </Grid.Column>
                    <Grid.Column width={7}>
                      <p className="webmilestoneDesc">
                        {milestone.bll_milestone_sub_title__c}
                      </p>
                    </Grid.Column>
                    <Grid.Column width={2}>
                      <p className="webRedeemTextPoints">
                        {milestone.bll_points__c}
                      </p>
                    </Grid.Column>
                  </Grid>
                </div>
              ))
              ) : (
                <div></div>
              )}
          </div>
        )}
        <div>
        <h1 className="rewardsfaqs">{t('RewardFAQs')}</h1>
        <p>{loyaltyfqasContent?.accordians?.accordion_heading__c}</p>
      </div>

      <PageContentAccordion 
         data={
          loyaltyfqasContent &&
          loyaltyfqasContent?.accordians?.map((loyaltyfqasContent) => ({
            title: loyaltyfqasContent.accordion_heading__c,
            content: loyaltyfqasContent.accordion_content__c,
          }))
        }
      />
      {i18n.language === 'es-MX' ?  
      <a href="https://info.biolifeplasma.com/myloyalty-terms-and-conditions-es" target="_blank" className="rewardsLink" >
              {t("rewardsLink")}</a>
      :
      <a href="https://info.biolifeplasma.com/myloyalty-terms-and-conditions" target="_blank" className="rewardsLink" >
              {t("rewardsLink")}</a>
}
      </div>
    </div>
  );
};
const mapStateToProps = (state, publicContent) => ({
  loyaltyMilestones: state.publicContent.getLoyaltyMilestonesSuccess,
  publicContent: state.publicContent.WebArticles?.completeArticles,
  donor: state.donors.donors?.data,
});

const mapDispatchToProps = (dispatch) => ({
  getLoyaltyMilestones: (sfid) =>
    dispatch(publicContentActions.getLoyaltyMilestones(sfid)),
});
export default connect(mapStateToProps, mapDispatchToProps)(WaystoEarnCard);
