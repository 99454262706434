import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image, Visibility, Dimmer, Message,Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../../assets/media/icons/icon-close-web.png'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer';
const centerActions = importer("Stores/Centers/Actions");
import { connect } from "react-redux";

const PrimaryButton = importer('Components/Buttons/PrimaryButton');

const RemoveUserPortalAccessModal = (
    { 
        selectedUser,
        toggleSuccessModal,
        open,
        toggleModal,
        removeUserPortalAccessFailure,
        removeUserPortalAccessSuccess,
        removeUserPortalAccessLoading,
        removeUserPortalAccess
    }) => {  
  const { t } = useTranslation('RemoveUserPortalAccessModal'); 
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  const [submited, setSubmited] = useState(false);

    useEffect(() => {
      if (removeUserPortalAccessSuccess !== null && submited) {
        setLoading(false);
        setErrorMessages("");
        toggleSuccessModal(true,"RemoveUserPortalAccessModal");
        toggleModal();
      }
    }, [removeUserPortalAccessSuccess])

    useEffect(() => {
      if (removeUserPortalAccessFailure !== null && submited) {
        setSubmited(false)
        setLoading(false)
        setErrorMessages(removeUserPortalAccessFailure ? removeUserPortalAccess.message : t('somethingWentWrong'))
      }
    }, [removeUserPortalAccessFailure]);

    useEffect(() => {
      setErrorMessages("");
    },[]);

    const onConfirmation = () => {
        setLoading(true);
        setSubmited(true)
        removeUserPortalAccess(selectedUser);
    };

    return (
    <Visibility> 
      <Modal open={open} onClose={null} className='RemoveUserPortalAccessModal'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
               {t('header').replace("%u%", selectedUser.name)}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image src={CloseIcon} alt='Close' onClick={toggleModal} className='CloseModal' />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
            <Grid.Row>
                <div className='ModalContent'>
                    <p className='RegularText'>{t('content').replace("%u%", selectedUser.name)}</p>
                </div>
            </Grid.Row>
            <Grid.Row>
                <PrimaryButton
                    text={t('cancelButton')}
                    fontSize={16}
                    classType='Inverted'
                    onClick={toggleModal}
                    className='close'
                />
                <PrimaryButton
                    text={t('saveButton')}
                    classType='Primary'
                    className='save'
                    onClick={onConfirmation}
                />
            </Grid.Row>
            {errorMessages && (
          <Message warning className='errorMessage'>
            <p>{Array.isArray(errorMessages) ? errorMessages.join(', ') : errorMessages}</p>
          </Message>
        )}
        </Modal.Content>
      </Modal>
      {(loading && removeUserPortalAccessLoading) && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
    </Visibility>
    )
}

const mapStateToProps = (state) => ({
  removeUserPortalAccessLoading: state.centers.removeUserPortalAccessLoading,
  removeUserPortalAccessSuccess: state.centers.removeUserPortalAccessSuccess,
  removeUserPortalAccessFailure: state.centers.removeUserPortalAccessFailure,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    removeUserPortalAccess: (userCenter) => dispatch(centerActions.removeUserPortalAccess(userCenter)),
  })

export default connect(
    mapStateToProps, mapDispatchToProps
) (RemoveUserPortalAccessModal)
