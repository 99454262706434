import React, { useState, useEffect } from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Container, Tab, Input, Dimmer,Visibility, Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss';

import Add_User from '../../../../../assets/media/icons/add_user.svg';
const centerActions = importer('Stores/Centers/Actions');
import { connect } from 'react-redux';
import exclamationcircle from '../../../../../assets/media/icons/exclamationRedCircle.svg'

const AccessManagementSearch= ({
  user,
  onUserSelected,
  searchApplicationUser,
  searchApplicationUserLoading,
  searchApplicationUserFailure,
  searchApplicationUserSuccess}) => {
    const { t } = useTranslation('AccessManagementSearch');
    const [inputValue, setInputValue]=useState();
    const [inputError, setInputError]=useState();
    const [searchedString, setSearchedString]=useState(null);
    const [selectedUser, setSelectedUser]=useState(null);
    const [loading, setLoading] = useState(false);
    const userDefault= <div className='defaultUser'><img className='center' src={Add_User}/><div className='searchContent'>Create application user</div></div>;
    const [userList, setUserList] =useState(userDefault);
    const [userListData, setUserListData]=useState([]);
    const [page, setPage] = useState(1);
    const contextRef = React.createRef();
    const [errorMessages, setErrorMessages] = useState('');
    const [isSameTabeSelected, setIsSameTabeSelected]=useState(true);
    const [tabeSelected, setTabeSelected]=useState('All user');
    const [totalNoOfRecords, setTotalNoOfRecords]=useState(null);
    const pageSize=15;

    function selectUserDetails (userid){
      setSelectedUser(userid);
    };
    var getInitials = function (string) {
      var names = string?.split(' '),
          initials = names[0]?.substring(0, 1)?.toUpperCase();
      
      if (names?.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
      }
      return initials;
  };

    const loadData= ()=>{
      let userDatalist=[];
      if(userListData){
        const data=userListData;
        userDatalist = userListData.map((user) =>
        <div className={user.emp_email_id__c==selectedUser?"userListsSelected":"userLists"} key={user.emp_id} onClick={() => selectUserDetails(user.emp_email_id__c)}> 
          <div className="userShortName">
            <span className='userShortNameSpan'>
              {getInitials(user.emp_name)}
            </span>
          </div>
        <div className='userlistContent'>
          <div className='userName'>
            {user.emp_name}
          </div>
          <div className='userEmail'>
            {user.emp_email_id__c}
          </div>
        </div>
        </div>
        );
      }
      const visibility= <Visibility
                        continuous={false}
                        once={false}
                        onBottomVisible={() => setPage(page + 1)}
                        onBottomPassed={() => setPage(page + 1)}
                        context={contextRef}
                        > {userDatalist}</Visibility>
      setUserList(visibility);
    }
    const userTabSelected= (e) => {
      const sameCenterUser=(e.target.text == "My Center User");
      setIsSameTabeSelected(tabeSelected===e.target.text?true:false);
      setPage(tabeSelected===e.target.text ? page : 1);
      if(sameCenterUser && !user?.defaultCenter?.sfid){
        setUserList(userDefault);
      }
      else{
        const req={
          centerSfid : sameCenterUser?(user?.defaultCenter?.sfid):null,
          pageSize: pageSize,
          currentPage: 1
        }
        searchApplicationUser(req);
      }
      setTabeSelected(e.target.text);
    };

    const searchApplicationUserList= (searchString)=>{
      const req={
        searchString : searchString,
        pageSize: pageSize,
        currentPage: 1,
        centerSfid : (tabeSelected==="My Center User")?(user?.defaultCenter?.sfid):null,
      }
      searchApplicationUser(req);
    }
    const searchClick = (e)=>{
      const srearchString=inputValue?inputValue:null;
        setSearchedString(srearchString);
        searchApplicationUserList(srearchString);
    };

    useEffect(() =>{
      if(userListData && page>1 && totalNoOfRecords>userListData.length){
        const req={
          searchString : searchedString,
          pageSize: pageSize,
          currentPage: page,
          centerSfid : (tabeSelected==="My Center User")?(user?.defaultCenter?.sfid):null,
        }
        searchApplicationUser(req);
      }
    },[page])

    useEffect(() =>{
      if(userListData && page>1 && totalNoOfRecords>userListData.length){
        setSearchedString(isSameTabeSelected? searchedString:null);
      }
    },[isSameTabeSelected])

    const handleInputChange = (e) => {
      setInputValue(e.target.value)
    };
    useEffect(() => {
      loadData();
      if(selectedUser){
        onUserSelected(selectedUser);
      }
    }, [selectedUser]);

    useEffect(() => {
      setLoading(true)
      searchApplicationUserList(null);
    }, []);

    useEffect(() => {
      if (searchApplicationUserSuccess !== null) {
        setTotalNoOfRecords(searchApplicationUserSuccess?.totalRecords);
        setLoading(false);
        if(searchApplicationUserSuccess?.totalRecords<=0){
          setUserList(userDefault);
          if(searchedString){
            setInputError(true);
          }else{
            setInputError(false);
          }
        }else{
          let prevData=[...userListData];
          if(page>1){
            prevData.push(...searchApplicationUserSuccess?.applicationUser)
          }
          else{prevData=searchApplicationUserSuccess?.applicationUser}
          setUserListData(prevData);
          setInputError(false);
        }
      }
    }, [searchApplicationUserSuccess])

    useEffect(() =>{
      loadData(userListData);
    },[userListData])
  
    useEffect(() => {
      if (searchApplicationUserFailure !== null) {
        setLoading(false);
        setErrorMessages(searchApplicationUserFailure ? searchApplicationUserFailure.message : t('somethingWentWrong'))
      }
    }, [searchApplicationUserFailure])

    const panes = [
        {
          menuItem: 'All user',
          render: () => <Tab.Pane attached={true}>{userList}</Tab.Pane>,
        },
        {
          menuItem: 'My Center User',
          render: () => <Tab.Pane attached={true}>{userList}</Tab.Pane>,
        },
      ];

    return (
        <Container fluid className='AccessManagementSearch'>
          <Visibility>
            <Input action={{ icon: 'search', onClick: searchClick}} placeholder='Search by Name or Email id' onChange={handleInputChange}/>
            {inputError && <div className='searchError'><img className='ErrorIcon' src={exclamationcircle}/>&nbsp;<small src={exclamationcircle} className='searchErrorText'>{t("searchInput")}</small></div>}
          </Visibility>
          <Tab menu={{ text: true }} panes={panes} onTabChange={userTabSelected}/>
          {( searchApplicationUserLoading) && (
            <Dimmer active inverted>
              <Loader>{t('loading')}</Loader>
            </Dimmer>
          )}
        </Container>
    )
  }
  
  const mapStateToProps = (state) => ({
    user: state.users.user,
    searchApplicationUserLoading: state.centers.searchApplicationUserLoading,
    searchApplicationUserSuccess: state.centers.searchApplicationUserSuccess,
    searchApplicationUserFailure: state.centers.searchApplicationUserFailure,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    searchApplicationUser: (searchUserRequest) => dispatch(centerActions.searchApplicationUser(searchUserRequest)),
  })

export default connect(
    mapStateToProps, mapDispatchToProps
) ( AccessManagementSearch );