import React, {useState, useEffect} from 'react';
import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from 'react-redux'
import { Grid, Container, Form, Image } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { useTranslation } from 'react-i18next';
import './styles.scss'
const donorsActions = importer('Stores/Donors/Actions')
const {isMobileWeb} = importer('Helpers/Utils', null)
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoyaltyDashboard=importer('Components/Dashboard/LoyaltyDashboard');
const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { webArticles } = importer('Helpers/WebArticles')

const MyLoyaltyEnrolment = ({ updateDonor, donor, updateDonorSuccess,updateDonorLoading, publicContent, images, updateDonorFailure }) => {
  const [enrolmentSuccess, setEnrolmentSuccess] = useState(false)
  const [enrolmentError, setEnrolmentError] = useState(false)
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [waysToEarnClicked, setWaysToEarnClicked] = useState(false);
  const { t, i18n } = useTranslation('MyLoyaltyEnrolment');
  const enrolmentContent = ArticleByIdentifier(
    publicContent,
    webArticles.LOYALTY_ENROLMENT
  )

  const enrolmentSuccessContent = ArticleByIdentifier(
    publicContent,
    webArticles.LOYALTY_ENROLMENT_SUCCESS
  )

  const enrolmentHeroImage = images.find(i => i.name__c === 'Loyalty Enrolment Hero Image')

  useEffect(() => {
    if(updateDonorSuccess != null && isSubmitClicked){
      sessionStorage.setItem('justLoyaltyEnrolled', true)
      setEnrolmentSuccess(true)
      if (isMobileWeb()) {
        window.scrollTo({
          top: 500, 
          behavior: "smooth"
        });
      } else {
        window.scrollTo({
          top: 0, 
          behavior: "smooth"
        });
      }
    }
  }, [updateDonorSuccess])


  const handleSubmit = (values) => {
    setIsSubmitClicked(true)
    if(values.isLoyaltyMember) {
      setEnrolmentError(false)
      updateDonor(donor.id, {
        donorDetails: {
          isLoyaltyMember: 'Yes'
        },
        loyaltyApplicationUsed: isMobileWeb() ? 'MOBILE_WEB' : 'WEB'
      })
    } else {
      setEnrolmentError(true);
    }
  }

  return (
    <div>
    { !waysToEarnClicked ?
       <Container className='MyLoyaltyEnrolmentContainer'>
      <Grid stackable>
        <Grid.Row columns={2}>
          <Grid.Column>
          {!enrolmentSuccess && <h1 className='EnrolHeading'>{enrolmentContent?.title__c}</h1>}
          {enrolmentSuccess && <h1 className='EnrolHeading EnrolSuccessHeading'>{enrolmentSuccessContent?.title__c}</h1>}
            {isMobileWeb() && 
              <Image 
                src={enrolmentHeroImage?.image_url_mobile__c} 
                alt={enrolmentHeroImage?.image_alt_text__c}
                width="350"
                height="350"
              />
            }
           {!enrolmentSuccess && <div dangerouslySetInnerHTML={{ __html: enrolmentContent?.content__c }} />}
            {enrolmentSuccess ? 
              <div className='EnrolSuccessMessageContainer'>
                 <div dangerouslySetInnerHTML={{__html: enrolmentSuccessContent?.content__c}} />
                <PrimaryButton
                  className='EnrollNowButton'
                  width={150}
                  text={t('waysToEarn')}
                  onClick={()=>{  
                    
                    setWaysToEarnClicked(true);
                  }}
                />
              </div>
             :
              <div className='CheckboxContainer'>
                <FinalForm
                  onSubmit={handleSubmit}
                  render={({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Grid columns={2}>
                        <Grid.Column computer={1} mobile={2}>
                          <Field name='isLoyaltyMember' type='checkbox' component='input' />
                        </Grid.Column>
                        <Grid.Column computer={15} mobile={14}>
                          <p className='CheckboxLabel'>{t('loyaltyTnCs')} <a href={i18n.language === 'es-MX' ? 'https://info.biolifeplasma.com/myloyalty-terms-and-conditions-es' : 'https://info.biolifeplasma.com/myloyalty-terms-and-conditions'} style={{color: '#007065', textDecoration: 'underline'}}>{t('loyaltyBiolife')}</a> {t('click')} <a href={i18n.language === 'es-MX' ? 'https://info.biolifeplasma.com/myloyalty-FAQ-es' : 'https://info.biolifeplasma.com/myloyalty-FAQ'} style={{color: '#007065', textDecoration: 'underline'}}>{t('here')}</a> {t('learnMoreAboutProgram')}</p>
                        </Grid.Column>
                        <Grid.Row>
                          <PrimaryButton
                            className='EnrollNowButton'
                            width={150}
                            text={t('enrollNow')}
                            onClick={handleSubmit}
                            loading={updateDonorLoading}
                          />
                        </Grid.Row>
                      </Grid>
                      {enrolmentError && <p className='enrollmentUncheckError'>{t('enrolmentUncheckError')}</p>}
                      {updateDonorFailure && isSubmitClicked && <p className='enrollmentUncheckError'>{updateDonorFailure.message[0]}</p>}
                    </Form>
                  )}
                />
              </div>
            }
          </Grid.Column>
          {!isMobileWeb() && 
            <Grid.Column>
              <Image className="EnrolmentHeroImage"
                src={enrolmentHeroImage?.image_url_desktop__c} 
                alt={enrolmentHeroImage?.image_alt_text__c}
              />
            </Grid.Column>
          }
        </Grid.Row>
      </Grid>
    </Container>   
    :
     <LoyaltyDashboard setLoyaltyDashboardTab="WAYS_TO_EARN"/>
    }
    </div>
  )
}


const mapStateToProps = ({ donors, publicContent }) => ({
  donor: donors.donors?.data,
  updateDonorSuccess: donors?.updateDonorSuccess,
  updateDonorFailure: donors?.updateDonorFailure?.response?.data,
  updateDonorLoading: donors?.updateDonorLoading,
  publicContent: publicContent.WebArticles?.completeArticles,
  images: publicContent.AppImages,
})

const mapDispatchToProps = (dispatch) => ({
  updateDonor: (id, donor) => dispatch(donorsActions.updateDonor(id, donor)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyLoyaltyEnrolment)

