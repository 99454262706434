import React from 'react'

import { Grid, Image } from 'semantic-ui-react'
import googlePlay from './images/badge-gplay.png'
import playStore from './images/badge-ios.png'
import takeda from './images/takeda-logo.png'
import BioLifeLogo from './images/BioLife-Black-White-logo.jpg'
import { useTranslation } from 'react-i18next';

import './styles.scss'

const SubFooter = (props) => {
  const { isVertical } = props
  const { t } = useTranslation('SubFooter');

  const current_year= new Date().getFullYear();
  return (
    <Grid.Row columns={3} className='SubFooter'>
      {!isVertical && (
        <Grid.Column computer={10} tablet={6}>
          <p className='CopyRight'>
          {t('biolifeAndThe')} <Image className='biolife-logo' src={BioLifeLogo} alt="BioLife Logo" as='a' href='#' target='_blank' height="28" width="150" /> {t('areRegisteredTrademarks')}<Image className='bandLogo' src={takeda} alt="Takeda logo" as='a' href='#' target='_blank' height="28" width="150" /> {t('tradeMarkLastPart',{current_year})} {t('allRightsReserved')}
          </p>
        </Grid.Column>
      )}
      <Grid.Column computer={4} mobile={16} tablet={6} textAlign='center'>
        <Image
          className='GplayImgContainer'
          src={googlePlay}
          alt="Download BioLife App from play store"
          as='a'
          href='https://play.google.com/store/apps/details?id=com.shire.biolife&hl=en_US'
          target='_blank'
          height="29"
          width="150"
        />

        <Image
          className='AppStoreImgContainer'
          src={playStore}
          alt="Download BioLife App from App store"
          as='a'
          href='https://apps.apple.com/us/app/biolife-plasma-services/id1356589861'
          target='_blank'
          height="29"
          width="150"
        />
      </Grid.Column>
      <Grid.Column computer={2} mobile={16} tablet={4} textAlign='center'>
        <Image className='TakedaImgContainer' src={takeda} alt="Takeda logo" as='a' href='#' target='_blank' height="28" width="150" />
      </Grid.Column>
      {isVertical && (
        <Grid.Column mobile={15} tablet={16}>
          <p className='CopyRight'>
          {t('biolifeAndThe')} <Image className='biolife-logo' src={BioLifeLogo} alt="BioLife Logo" as='a' href='#' target='_blank' height="28" width="150" /> {t('areRegisteredTrademarks')}<Image className='bandLogo' src={takeda} alt="Takeda logo" as='a' href='#' target='_blank' height="28" width="150" /> {t('tradeMarkLastPart',{current_year})} {t('allRightsReserved')}
          </p>
        </Grid.Column>
      )}
    </Grid.Row>
  )
}

export default SubFooter
