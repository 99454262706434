import React,{useEffect} from 'react'
import { connect } from 'react-redux'
import importer from '@takedapdt/biolife-core/src/importer'
import { Helmet } from 'react-helmet'
import { isEmpty } from 'lodash'

const DesktopContainer = importer('Containers/Desktop')
const MobileContainer = importer('Containers/Mobile')
const { ScrollToTop } = importer('Helpers/Utils', null)

const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { pageMetaArticles } = importer('Helpers/menuBuilder')

const AppContainer = ({ children,webArticles, ...props }) =>{ 

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    window.addEventListener("beforeunload", ScrollToTop());
    return () => {
      window.removeEventListener("beforeunload", ScrollToTop());
    };
  }, []);

  const homePageOGMeta =  ArticleByIdentifier(
    webArticles,
    pageMetaArticles.HOME_PAGE_OG_META
  )


  return (
  <div style={{ width: '100%' }}>
    {!isEmpty(homePageOGMeta) && <Helmet>
    <meta property="og:description"
    content={`${homePageOGMeta?.meta_description__c}`}/>
    </Helmet>}
    <DesktopContainer {...props}>{children}</DesktopContainer>

    <MobileContainer {...props}>{children}</MobileContainer>
  </div>
)}

const mapStateToProps = (state) => ({
  webArticles: state.publicContent.WebArticles?.completeArticles
})

export default connect(mapStateToProps,null) (AppContainer)