import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import { Grid, Container, Icon } from 'semantic-ui-react'

import { isEmpty } from 'lodash'
import './styles.scss'
import { filterNonNomogramCentersPublicPage } from '../../Helpers/Utils'
import importer from '@takedapdt/biolife-core/src/importer'

const FacilityCard = importer('Components/FacilityCard')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const MapMarker = ({ text }) => (
  <div style={{ textAlign: 'center' }}>
    <Icon color='red' name='map marker alternate' size='large' />
  </div>
)

const Map = ({ width, height, defaultProps, mapKey, sites }) => {
  const onMapLoaded = ({ map, maps }) => {
    const bounds = new maps.LatLngBounds()
    if (sites.length === 1) {
      map.setZoom(14)
      return false
    }
    sites.forEach(site => {
      bounds.extend(new maps.LatLng(site.latitude, site.longitude))
    })
    map.fitBounds(bounds)
  }

  return (
    <div className='Map' style={{ height: height, width: width }}>
      <GoogleMapReact
        key={mapKey}
        bootstrapURLKeys={{ key: 'AIzaSyCqhmAA-4JUW-sJGZoB4ufuNIraE_JEDfM' }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={onMapLoaded}
      >
        {sites &&
          sites.map(center => (
            <MapMarker
              key={center.id}
              lat={center.latitude}
              lng={center.longitude}
            />
          ))
        }
      </GoogleMapReact>
    </div>
  )
}

const LocationStateSearch = ({
  centers,
  getLocationsLoading
}) => {
  const [defaultProps, setDefaultProps] = useState({})
  const [filterCenters, setfilterCenters] = useState()


useEffect(() => {
  if(centers){
    setfilterCenters(filterNonNomogramCentersPublicPage(centers))
  }
},[centers])

  useEffect(() => {
    if (filterCenters && filterCenters.length > 0) {
      const mapData = {
        center: {
          lat: filterCenters[0].latitude,
          lng: filterCenters[0].longitude
        },
        zoom: 13
      }
      setDefaultProps(mapData)
    }
  }, [filterCenters])
  return (
    <div className='LocationStateSearch'>
      {getLocationsLoading && (
        <LoadingState />
      )}
      {!getLocationsLoading && (
        <Container>
          <div className='ResultsContainer'>
            <Grid columns={2}>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {!isEmpty(filterCenters) &&
                  filterCenters?.map((facility, index) => (
                    <FacilityCard key={index} facility={facility} selectedState={facility.state} />
                  ))
                }
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={8}>
                {!isEmpty(defaultProps) && filterCenters && filterCenters.length > 0 && (
                  <Map
                    width='100%'
                    height='80vh'
                    sites={filterCenters}
                    mapKey={filterCenters[0].id}
                    defaultProps={defaultProps}
                  />
                )}
              </Grid.Column>
            </Grid>
          </div>
        </Container>
      )}
    </div>
  )
}

export default LocationStateSearch
