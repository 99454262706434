import PropTypes from 'prop-types'
import React from 'react'
import { useState } from 'react'
import { Visibility, Image, Loader } from 'semantic-ui-react'

const LazyImage = (props) => {
    const [show,setShow]=useState(false)
    const propTypes = {
        src: PropTypes.string.isRequired,
        size: PropTypes.string,
    }

    const defaultProps = {
        size: `medium`,
    }

    const showImage = () => {
       setShow(true)
    }
          return (
                !show ?  <Visibility as="span" onTopVisible={showImage}>
                    <Loader active inline="centered"  />
                </Visibility> :<Image {...props} />
            
            )
        
        
    
}
export default LazyImage