import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Header, Grid, Pagination } from 'semantic-ui-react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const Container = ({ children }) => {
  const { t } = useTranslation('SearchResults');
  return (
    <Grid className='SearchDonorsResult'>
      <Grid.Row>
        <Header as='h4'>{t('searchResults')}</Header>
      </Grid.Row>
      {children}
    </Grid>
  )
}

const SearchResults = props => {
  const totalPages = props.totalPages
  const donorsList = props?.donors?.donors?.length > 0 ? props.donors.donors : []
  const onPageChange = props.onPageChange
  const page = props.page
  const { t } = useTranslation('SearchResults');
  if (!props.loading && donorsList && donorsList.length === 0) {
    return (
      <Container>
        <p className='NoResults'>{t('noResultsfound')}</p>
      </Container>
    )
  }

  return (
    <Container>
      <Grid.Row>
        <Grid className='ResultsTableContainer'>
          <Grid.Row columns={5} className='ResultsTableHeader'>
            <Grid.Column style={{ paddingLeft: 0 }}>{t('firstName')}</Grid.Column>
            <Grid.Column>{t('lastName')}</Grid.Column>
            <Grid.Column>{t('pdn')}</Grid.Column>
            <Grid.Column>{t('phoneNumber')}</Grid.Column>
            <Grid.Column textAlign='right'>{t('center')}</Grid.Column>
          </Grid.Row>
          {props.loading && (
            <LoadingState />
          )}
          {!props.loading && donorsList.map((donor, index) => (
            <Grid.Row
              key={index}
              columns={5}
              className='ResultsTableEntry'
              style={{
                borderBottom:
                donorsList.length - 1 !== index ? '2px solid #DDDDDD' : ''
              }}
            >
              <Grid.Column className='DonorFirstName' style={{ paddingLeft: 0 }}>
                <div onClick={() => props.onSelectDonor(donor)}>
                  {donor.firstName}
                </div>
              </Grid.Column>
              <Grid.Column>{donor.lastName}</Grid.Column>
              <Grid.Column>{donor.pdn}</Grid.Column>
              <Grid.Column>{donor.phoneNumber}</Grid.Column>
              <Grid.Column className='DonorName' textAlign='right'>{donor.donationCenterName}</Grid.Column>
            </Grid.Row>
          ))}
          {!props.loading && donorsList && totalPages && totalPages > 0 && (
            <div className='PaginationContainer'>
              <span className='PageText'>{t('page')}</span>
              <Pagination
                secondary
                boundaryRange={3}
                activePage={page}
                ellipsisItem={null}
                firstItem={null}
                lastItem={null}
                siblingRange={1}
                totalPages={totalPages}
                onPageChange={onPageChange}
              />
            </div>
          )}
        </Grid>
      </Grid.Row>
    </Container>
  )
}

export default SearchResults
