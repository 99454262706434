import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
const LazyImage = importer('Components/LazyLoading')
import {
  Card,
  Image,
  Container,
  Responsive
} from 'semantic-ui-react'
import './styles.scss'

const VerticalNewsBlock = props => {
  const { content, image } = props
  return (
    <div className='VerticalNewsBlock'>
      <Card className='VerticalBlockNewsCard'>
        <Responsive minWidth={415}>
          {image.web_image_redirect_url__c ?
            <a href={image.web_image_redirect_url__c}>
              <LazyImage
                className='VerticalBlockImage'
                src={image.image_url_desktop__c}
                alt={image.image_alt_text__c}
                wrapped
                ui={false}
              /> 
            </a> :
            <LazyImage
              className='VerticalBlockImage'
              src={image.image_url_desktop__c}
              alt={image.image_alt_text__c}
              wrapped
              ui={false}
            />
          }
        </Responsive>
        <Responsive maxWidth={415}>
          <LazyImage
            className='VerticalBlockImage'
            src={image.image_url_mobile__c}
            alt={image.image_alt_text__c}
            wrapped
            ui={false}
          />
        </Responsive>

        <Card.Content className='VerticalBlockCardContent'>
          <Container>
            <div
              className='ui grid'
              dangerouslySetInnerHTML={{ __html: content?.content__c }}
            />
          </Container>
        </Card.Content>
      </Card>
    </div>
  )
}

export default VerticalNewsBlock
