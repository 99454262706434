import React, { useState } from 'react'
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../../assets/media/icons/icon-close-web.png'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const EditAccessManagementForm = importer('Components/Forms/AccessManagementForms/EditAccessManagementForm');

const EditAccessManagementUserDetailsModal = ({ selectedUser, toggleSuccessModal, open, toggleModal }) => {  
  const { t } = useTranslation('EditAccessManagementUserDetails'); 
    return (
      <Modal open={open} onClose={null} className='EditAccessManagementUserDetails'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
               {t('header')+" "+selectedUser.name}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image src={CloseIcon} alt='Close' onClick={toggleModal} className='CloseModal' />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <EditAccessManagementForm selectedUser={selectedUser} closeEditModal={toggleModal} toggleSuccessModal={toggleSuccessModal}/>
        </Modal.Content>
      </Modal>
    )
}

export default EditAccessManagementUserDetailsModal
