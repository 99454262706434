import React, { useState, useEffect } from 'react'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const ScheduleAppointmentModal = importer('Components/CenterOps/ScheduleAppointmentModal')
const ModifyPerson = importer('Components/CenterOps/ModifyPerson')
const ScheduleDayActions = importer('Components/CenterOps/ScheduleDayActions')

const CellDonorData = ({
  donors,
  toggleModal,
  onClickModifyHandler,
  onClickScheduleHandler,
  appointment,
  time,
  toogleQuickPhysicalModal,
  donationScheduledAppointments,
  donationAdjustmentAppointments,
  appointmentTime,
  getSlotsForAppointmentType,
  getAppSlotsHandler,
  reloadCalendar,
  donationCenter,
  isAddButtonDisabled,
  centerInfo,
  needPlayRoom
}) => {
  const [appointmentType, setAppointmentType] = useState('DONATION')
  const [openScheduleAppointment, setOpenScheduleAppointment] = useState(false)
  const toggleScheduleAppointment = () => setOpenScheduleAppointment(!openScheduleAppointment)

  const [searchResults, setSearchResults] = useState([])
  const [tempSearchResults, setTempSearchResults] = useState([])
  const [isNewDonorSearch, setIsNewDonorSearch] = useState(false)

  useEffect(() => {
    if (isNewDonorSearch) {
      setTempSearchResults([])
    } else {
      setTempSearchResults(searchResults)
    }
  }, [isNewDonorSearch, searchResults])

  const handleClearSearchResults = (searchData) => {
    setSearchResults(searchData)
  }

  return (
    <div className='CellData'>
      {donors && donors.map((donor, index) => (
        <ModifyPerson
          person={donor.donorName}
          speOnly={donor.speOnly}
          donorSfid={donor.donorSfid}
          appointmentId={donor.appointmentId}
          appointmentType = {appointmentType}
          numberOfChildren={donor.numOfKids}
          language={donor.language}
          key={index}
          toggleModal={toggleModal}
          onClickModify={onClickModifyHandler}
          getSlotsForAppointmentType = {getSlotsForAppointmentType}
          getAppSlotsHandler = {getAppSlotsHandler}
          time={time}
          appointment = {appointment}
          appointmentTime = {appointmentTime}
          donationCenter = {donationCenter}
          isAddButtonDisabled={isAddButtonDisabled}
        />
      ))}
      <ScheduleDayActions
        scheduled={donationScheduledAppointments}
        maxAllowed={donationAdjustmentAppointments}
        toggleScheduleAppointment={toggleScheduleAppointment}
        setAppointment={onClickScheduleHandler}
        appointment={appointment}
        time={time}
        isAddButtonDisabled={isAddButtonDisabled}
        appointmentDateTime = {appointmentTime}
        setIsNewDonorSearch={setIsNewDonorSearch}
        isNewDonorSearch={isNewDonorSearch}
      />
      <ScheduleAppointmentModal
        needPlayRoom={needPlayRoom}
        open={openScheduleAppointment}
        toggleScheduleAppointment={toggleScheduleAppointment}
        appointmentDateTime ={appointmentTime}
        appointmentTime={appointment.time}
        maxAllowed={donationAdjustmentAppointments}
        totalScheduled={donationScheduledAppointments}
        appointment = {appointment}
        toogleQuickPhysicalModal={toogleQuickPhysicalModal}
        donationType = {appointmentType}
        reloadCalendar={reloadCalendar}
        donationCenter={donationCenter}
        centerInfo={centerInfo}
        handleClearSearchResults={handleClearSearchResults}
        tempSearchResults={tempSearchResults}
        setIsNewDonorSearch={setIsNewDonorSearch}
        isNewDonorSearch={isNewDonorSearch}
      />
    </div>
  )
}

export default CellDonorData
