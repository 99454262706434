import { isEmpty } from 'lodash'

const validate = (values) => {
  const errors = {}

  if (isEmpty(values.firstname)) {
    errors.firstname = 'firstNameIsRequired'
  } else if (values.firstname.length < 2) {
    errors.firstname = 'Too Short'
  }

  if (isEmpty(values.lastname)) {
    errors.lastname = 'lastNameIsRequired'
  }

  if (isEmpty(values.email)) {
    errors.email = 'emailIsRequired'
  } else if (values.email.match(/(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i) == null) {
    errors.email = 'enterAValidEmail'
  }

  if (isEmpty(values.center)) {
    errors.center = 'centerIsRequired'
  }
  if (isEmpty(values.state)) {
    errors.state = 'stateIsRequired'
  }
  if (isEmpty(values.status)) {
    errors.status = 'pleaseSelectAStatus'
  }

  if (isEmpty(values.message)) {
    errors.message = 'messageIsRequired'
  }

  if (values.password) {
    if (values.password.length < 8) {
      errors.password = 'passwordShouldHaveAtLeast8Char'
    }
    if (!values.password.match(/[A-Z]/)) {
      errors.password = 'uppercaseRequired'
    }
    if (!values.password.match(/[0-9]/)) {
      errors.password = 'numbersRequired'
    }
    if (!values.password.match(/[a-z]/)) {
      errors.password = 'lowercaseRequired'
    }
    
  } else {
    errors.password = 'passwordIsRequired'
  }

  return errors
}

export default validate
