import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react'

import './styles.scss'

const CheckEmailMessage = ({ location }) => {
  const { t } = useTranslation('CheckEmailMessage');
  const [messageType, setMessageType] = useState(null)

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      setMessageType(searchParams.get('type'))
    }
  }, [location])

  return (
    <div className="page-container">
      <div className='CheckEmailMessage  page-container__content'>
        <Header as='h1' className='MainHeader'>
          { messageType === 'AccountUpdate' && t('accountUpdate')}
          { messageType === 'ForgotPassword' && t('forgotPassword')}
          { !(messageType === 'AccountUpdate' || messageType === 'ForgotPassword') && t('checkMailbox')}
        </Header>
        { messageType === 'AccountUpdate' && (
          <div>
            <p className='Message'>
             {t('passwordRestProcessFlowLinkToCompleteProcess')}
            </p>
          </div>
        )}
        { messageType !== 'AccountUpdate' && (
          <p className='Message'>
          {t('passwordRestProcessFlowLinkToCompleteProcess')}
          </p>
        )}
      </div>
    </div>
  )
}

export default CheckEmailMessage
