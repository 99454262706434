import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Responsive, Sidebar } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'

const getWidth = importer('Helpers/getWidth')
const NavBar = importer('Components/NavBar')
const SideBarMenu = importer('Components/SideBarMenu')
/* ACTIONS */
const UserActions = importer('Stores/Users/Actions')

const MobileContainer = ({
  children,
  loggedInUser,
  signOut
}) => {
  const [sidebarOpened, setSidebarOpened] = useState(false)

 
  const handleToggleSidebar = () => {
    setSidebarOpened(!sidebarOpened)
  }
  

  return (
    <Responsive as={Sidebar.Pushable} getWidth={getWidth} maxWidth={Responsive.onlyTablet.maxWidth}>
      <SideBarMenu
        sidebarOpened={sidebarOpened}
        handleToggleSidebar={handleToggleSidebar}
        loggedInUser={loggedInUser}
        signOut={signOut}
      />

      <Sidebar.Pusher dimmed={sidebarOpened}>
        <NavBar handleToggleSidebar={handleToggleSidebar} loggedInUser={loggedInUser} />
        {children}
      </Sidebar.Pusher>
    </Responsive>
  )
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users.user
})

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(UserActions.logout())
})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileContainer)