import React from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import './styles.scss'
import { Icon } from 'semantic-ui-react'
import { splitCenterName } from "../../Helpers/Utils"




const SideMenuHeader = ({ user,center,donor,handleToggleSidebar }) => {
    const history = useHistory()
    const navigate = (path) => {
        if (path === 'donorInfo') {
            history.push('/dashboard/donor-information')
        } else if (path === 'donationCenter') {
            history.push('/dashboard/donation-center')
        }
        handleToggleSidebar()
    }

    return (
        user && (<div className='sideMenu-Container'>
            <Icon name='user' circular color='#1f237a' size='large' className='user-icon' onClick={() => navigate('donorInfo')} />
            <div className='details-container'>
                {donor?.firstName && <div className='user-name' onClick={() => navigate('donorInfo')}> {donor?.firstName} {donor?.lastName}</div>}
                {donor?.defaultCenter?.name && <div className='center-name' onClick={() => navigate('donationCenter')}>{splitCenterName(donor?.defaultCenter?.name)}</div>}
            </div>
        </div>)
    )
}

const mapStateToProps = (state) => ({
    user: state.users?.user,
    donor: state.donors.donors?.data
})



export default connect(mapStateToProps)(SideMenuHeader)