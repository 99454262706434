import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react'
import moment from 'moment'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const SubmitAppointment = ({ appointment, toggleModal }) => {
  const [appointmentTime, setAppointmentTime] = useState('')
  const [dataCompleted, setDataCompleted] = useState(false)
  const { t } = useTranslation('SubmitAppointment');
  useEffect(() => {
    const selectedTime =
      appointment.startDateTime && appointment.startTime
        ? `${moment(appointment.startDateTime).format('ll')} at ${appointment.startTime &&
            appointment.startTime.toLowerCase()}`
        : ''

    setAppointmentTime(selectedTime)
    setDataCompleted(appointment.startDateTime && appointment.startTime && appointment.appointmentType)
  }, [appointment])
  return (
    <div className='SubmitAppointment'>
      {dataCompleted && (
        <Header as='h4' className='SelectedAppointmentHeader'>
          {t('SelectedAppointmentTime')}
        </Header>
      )}
      {dataCompleted && (
        <p className='SelectedDate'>
          {moment.parseZone(appointment.startDateTime).format('ddd')}, {moment.parseZone(appointment.startDateTime).format('ll')} {t('at')}{' '}
          {appointment.startTime && appointment.startTime.toLowerCase()}
        </p>
      )}
      <div className='ButtonContainer'>
        <PrimaryButton
        className="SubmitAppointmentScheduleButton"
          text={t('submit')}
          width={262}
          height={50}
          fontSize={16}
          onClick={toggleModal}
          disabled={!dataCompleted}
        />
      </div>
    </div>
  )
}

export default SubmitAppointment
