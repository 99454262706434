import React, { useState, useEffect } from 'react'

import { Form, Icon, List, Header, Message } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { isEmpty, isArray } from 'lodash'

import './styles.scss'
import { useTranslation } from 'react-i18next';

import importer from '@takedapdt/biolife-core/src/importer'
import { connect } from 'react-redux'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const PasswordValidation = importer('Components/PasswordValidation')

const UpdatePasswordForm = ({ setSubmitSuccess, changePasswordFailure, changePasswordSuccess, donor }) => {
  const [passwordReveal, setPasswordReveal] = useState(false)
  const [errorMessages, setErrorMessages] = useState([])
  const [submited, setSubmited] = useState(false)
  const [currentPasswordReveal, setCurrentPasswordReveal] = useState(false)
  const [passwordConfirmReveal, setPasswordConfirmReveal] = useState(false)
  const [passwordInput, setPasswordInput] = useState('')
  const { t,i18n } = useTranslation('UpdatePasswordForm','apiErrorMessages');

  const handlePasswordChange = ({ target: { value } }) => {
    setPasswordInput(value)
  }

  const togglePasswordReveal = () => setPasswordReveal(!passwordReveal)
  const toggleCurrentPasswordReveal = () => setCurrentPasswordReveal(!currentPasswordReveal)
  const togglePasswordConfirmReveal = () => setPasswordConfirmReveal(!passwordConfirmReveal)

  const onSubmit = (values) => {
    setSubmited(true)
    setSubmitSuccess(values)
  }

  const formErrors = (errors, submitFailed) => {
    return (
      submitFailed && (
        <div className='ErrorsContainer'>
          {Object.values(errors).map((e, index) => (
            <p key={index} className='ErrorText'>
              {e}
            </p>
          ))}
        </div>
      )
    )
  }

  useEffect(() => {
    if (changePasswordFailure !== null && submited) {
      if (isArray(changePasswordFailure.errorCauses)) {
        setErrorMessages(changePasswordFailure.errorCauses)
      } else {
        setErrorMessages([changePasswordFailure.errorSummary])
      }
      setSubmited(false)
    }
  }, [changePasswordFailure])

  if (changePasswordSuccess && submited) {
    return (
      <Header as='h1' className='BigSuccessMessage'>
        {t('updatedSuccess')}
      </Header>
    )
  }

  return (
    <div className='UpdatePasswordForm'>
      <Header as='h4' className='SectionHeader'>
        {t('changePassword')}
      </Header>
      <Form.Group>
              <div className='MessageButtonContainer'>
                <p className='FormNote'>
                  <sup>
                    <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                  </sup>
                  {t('requiredField')}
                </p>
              </div>
            </Form.Group>
      <FinalForm
        onSubmit={onSubmit}
        validate={(values) => {
          const errors = {}
        
          if (values.password) {
            if (values.password.length < 8) {
              errors.password = 'passwordShouldHaveAtLeast8Char'
            }
            if (!values.password.match(/[A-Z]/)) {
              errors.password = 'uppercaseRequired'
            }
            if (!values.password.match(/[0-9]/)) {
              errors.password = 'numbersRequired'
            }
            if (!values.password.match(/[a-z]/)) {
              errors.password = 'lowercaseRequired'
            }
            if (values.password.toLowerCase().includes(donor.firstName?.toLowerCase())) {
              errors.password = 'shouldNotContainFirstname';
            }
            if (values.password.toLowerCase().includes(donor.lastName?.toLowerCase())) {
              errors.password = 'shouldNotContainLastName';
            }
            if (values.password.toLowerCase().includes(donor.loginId?.toLowerCase())) {
              errors.password = 'shouldNotContainUserName';
            }
            const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
            if (!specialChars.test(values.password)) {
              errors.password = 'symbolRequired';
            }
          } else {
            errors.password = 'passwordCannotBeEmpty'
          }
          if (values.passwordConfirm) {
            if (values.passwordConfirm !== values.password) {
              errors.passwordConfirm = 'passwordsDoNotMatch'
            }
          } else {
            errors.passwordConfirm = 'confirmPasswordCannotBeEmpty'
          }
        
          return errors
        }}
        render={({ handleSubmit, errors, submitFailed, invalid, pristine }) => (
          <Form onSubmit={handleSubmit}>
            <Field name='currentPassword'>
              {({ input, meta }) => {
                return (
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    type={currentPasswordReveal ? 'text' : 'password'}
                    // customOnChange={handlePasswordChange}
                    icon={
                      <Icon
                        name={currentPasswordReveal ? 'eye slash' : 'eye'}
                        link
                        onClick={toggleCurrentPasswordReveal}
                      />
                    }
                    fieldLabel={t('currentPassword')}
                  />
                )
              }}
            </Field>
            <Field name='password'>
              {({ input, meta }) => {
                return (
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    type={passwordReveal ? 'text' : 'password'}
                    customOnChange={handlePasswordChange}
                    icon={<Icon name={passwordReveal ? 'eye slash' : 'eye'} link onClick={togglePasswordReveal} />}
                    fieldLabel={t('newPassword*')}
                  />
                )
              }}
            </Field>
            <Field name='passwordConfirm'>
              {({ input, meta }) => {
                return (
                  <InputField
                    meta={meta}
                    {...input}
                    height={50}
                    type={passwordConfirmReveal ? 'text' : 'password'}
                    // customOnChange={handlePasswordChange}
                    icon={
                      <Icon
                        name={passwordConfirmReveal ? 'eye slash' : 'eye'}
                        link
                        onClick={togglePasswordConfirmReveal}
                      />
                    }
                    fieldLabel={t('confirmPassword*')}
                  />
                )
              }}
            </Field>

            {formErrors(errors, submitFailed)}
            {!isEmpty(errorMessages) && (
              <Message negative>
                  {errorMessages.map((message, index) => (
                    <p key={index}> {i18n.exists(`apiErrorMessages:${message.errorSummary}`)?
                     t(`apiErrorMessages:${message.errorSummary}`)
                    : message.errorSummary} </p>
                  ))}
                </Message>
              )
            }
            <PasswordValidation passwordInput={passwordInput} />

            <Form.Group className='ButtonContainer'>
              <PrimaryButton
                text={t('submit')}
                loading={submited}
                width={200}
                height={52}
                fontSize={16}
                classType='Primary'
                type='submit'
                disabled={invalid || pristine || submited}
              />
            </Form.Group>
          </Form>
        )}
      />
    </div>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data
})

export default connect(mapStateToProps)(UpdatePasswordForm)
