import React from 'react'
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react'

import './styles.scss'

const errorInfo = {
  title: 'debitCardTitleTranslationKey',
  subTitle: 'debitCardSubTitleTranslationKey',
  phone: '888-472-0099',
  link: 'https://login.northlane.com/biolife',
  customerServiceLable: 'northLaneCustomerService'

}
const DashboardUnavailableDebitCard = () => {
  const { t } = useTranslation('DashboardUnavailableDebitCard');
  return(
  <div className='UnavailableContainer'>
    <Header as='h2' className='UnavailableHeader'>
      { t(errorInfo.title) }
    </Header>
    <div className='UnavailableContent'>
      <p className='UnavailableContentText'>
        {t(errorInfo.subTitle)}
      </p>
      <p className='UnavailableContentLabel'> {t(errorInfo.customerServiceLable)} </p>
      <a className='UnavailableContentLink' href='tel:888-472-0099'>{errorInfo.phone}</a>
      <div className='UnavailableContentSpace' />
      <p className='UnavailableContentLabel'> {t(errorInfo.customerServiceLable)} </p>
      <a className='UnavailableContentLink' href={errorInfo.link} target='blank'>{errorInfo.link}</a>
    </div>
  </div>
  )
}

export default DashboardUnavailableDebitCard
