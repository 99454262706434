import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import importer from 'common/importer'
import * as firebase from 'firebase/app'
import 'firebase/analytics'
const Config = importer('Config')

// import { dedupExchange, fetchExchange } from 'urql'
// import { cacheExchange } from '@urql/exchange-graphcache'
import {ApolloClient, HttpLink, InMemoryCache } from 'apollo-boost'
const persistConfig = {
  key: 'root',
  storage: storage,
  blacklist: [
    'appointments',
    'authCustom',
    'centers',
    'users',
    'donors',
    'wirecard',
    'payments'
  ] // Blacklist state that we do not need/want to persist
}

const composerEnhanced = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const GRAPHQL_HASURA_URL = Config.GRAPHQL_HASURA_URL

export default (rootReducer, rootSaga) => {
  const middleware = []
  const enhancers = []

  const client = new ApolloClient({
    link: new HttpLink({uri:GRAPHQL_HASURA_URL}),
    cache:new InMemoryCache()
  });
  
  // Connect the Sagas to the Redux Store
  const sagaMiddleware = createSagaMiddleware({
    context: {
      firebase,
      client
    }
  })

  middleware.push(sagaMiddleware)

  // @todo
  // if (process.env.NODE_ENV === 'development') {
  //   const { createLogger } = require('redux-logger')
  //   const invariant = require('redux-immutable-state-invariant').default

  //   middleware.push(invariant())
  //   middleware.push(createLogger({ collapsed: true }))
  // }

  enhancers.push(composerEnhanced(applyMiddleware(...middleware)))

  // Redux persist
  const persistedReducer = persistReducer(persistConfig, rootReducer)

  const store = createStore(persistedReducer, compose(...enhancers))
  const persistor = persistStore(store)

  // Kick off the root saga
  sagaMiddleware.run(rootSaga)

  return { store, persistor }
}
