import moment from 'moment'
import { isEmpty } from 'lodash'

const validate = values => {
  const errors = {}

  if (!Boolean(values.date_range[0]) || !Boolean(values.date_range[1])) {
    errors.date_range = 'startDateCannotBeEmpty'
  }

  if (!isEmpty(values.date_range[0])) {
    if (!moment(values.date_range[0], 'MM/DD/YYYY', true).isValid()) {
      errors.date_range = 'startDateShouldBeMmDdYy'
    }
  }

  if (!isEmpty(values.date_range[1])) {
    if (!moment(values.date_range[1], 'MM/DD/YYYY', true).isValid()) {
      errors.date_range = 'endDateShouldBeMmDdYyyy'
    }
  }

  return errors
}

export default validate
