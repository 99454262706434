import React from 'react'
import { Container, Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import { useTranslation } from 'react-i18next';
const AppContainer = importer('Containers/App')
const CenterOpsDonorCenter = importer('Components/CenterOps/CenterOpsDonorCenter')
const CenterOpsDonorInformation = importer('Components/CenterOps/CenterOpsDonorInformation')
const CenterOpsDonorSearch = importer('Components/CenterOps/CenterOpsDonorSearch')
const Footer = importer('Components/Footer')

const CenterOpsDonorView = props => {
  const { t } = useTranslation('CenterOpsDonorView');
  return (
    <AppContainer compact>
      <div
        className='MainContainer'
        style={{
          paddingBottom: '400px',
          paddingTop: 65
        }}
      >
        <Container fluid style={{ paddingLeft: '6%', paddingRight: '6%' }}>
          <Grid columns={3}>
            <Grid.Column width={4}>
              <CenterOpsDonorSearch />
            </Grid.Column>
            <Grid.Column width={6}>
              <CenterOpsDonorInformation />
            </Grid.Column>
            <Grid.Column width={6}>
              <CenterOpsDonorCenter />
            </Grid.Column>
          </Grid>
        </Container>
      </div>
      <Footer />
    </AppContainer>
  )
}

export default CenterOpsDonorView
