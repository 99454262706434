import { put } from 'redux-saga/effects'
import importer from '@takedapdt/biolife-core/src/importer'
const PublicContentActions = importer('Stores/PublicContent/Actions')
/**
 * PUBLIC_STARTUP_SUCCESS
 */
function * initializeAppSuccess () {
  // console.log('initializeAppSuccess')
  /**
   * GET APP IMAGES
   */
  yield put(PublicContentActions.getAppImages())
  yield put(PublicContentActions.getWebArticles())
  yield put(PublicContentActions.getWebVideos())
  yield put(PublicContentActions.getMessages())
  yield put(PublicContentActions.getCenters())
  yield put(PublicContentActions.getLoyaltyAchievementBadges())
  yield put(PublicContentActions.getBlogs())
}

export default initializeAppSuccess
