export const upcomingAppointments = [
  {
    id: 1,
    date: '5/06/2020',
    time: '7:15 AM'
  },
  {
    id: 2,
    date: '6/06/2020',
    time: '8:15 AM'
  },
  {
    id: 3,
    date: '7/06/2020',
    time: '9:15 AM'
  }
]
