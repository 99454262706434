import React, { useEffect, useState } from 'react'
import { Form, Header } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import moment from 'moment'
import { useTranslation } from 'react-i18next';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const validate = importer('Components/Forms/LeadGenReportForm/validate')


const LeadGenReportForm = ({
  onClear,
  onSearch,
  pageName
}) => {
  const [renderForm, setRenderForm] = useState(true)
  const { t, i18n } = useTranslation('LeadGenReportForm','ValidationErrorMessages');
  const options = [
    { key: 'DonorName', value: 'DonorName', text: t('donorName') },
    { key: 'ProfileCreated', value: 'ProfileCreated', text: t('profileCreated') },
    { key: 'AppointmentDate', value: 'AppointmentDate', text: t('appointmentDate') }
  ]
  const [dateRange, setDateRange] = useState([null,null])

  const resetForm = () => {
    setDateRange([null,null])
    onClear()
    setRenderForm(false)
    setTimeout(() => { setRenderForm(true) }, 300)
  }
  const initialValues = {
    date_range: dateRange,
    sort_type: 'DonorName'
  }

  const onSubmit = values => {
    onSearch({
      ...values,
      start_date: moment(values.date_range[0]).startOf('day').format('YYYY-MM-DDT00:00:00'),
      end_date: moment(values.date_range[1]).endOf('day').format('YYYY-MM-DDT23:59:59')
    })
  }

  return (
    <div className="AppointmentReportFilter">
      <Header as='h4' className='FormHeader'>
        {t('donorReport')}
      </Header>
      {renderForm && (
        <FinalForm
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine }) => (
            <Form onSubmit={handleSubmit} className='ReportSearchForm'>
              <Form.Group>
                <Field name='date_range'>
                  {({ input, meta }) => (
                    <Form.Field required className='FormField'>
                      <small className={meta.touched && meta.error ? 'TopLabelError' : 'TopLabel'}>{meta.touched && meta.error ? t(`ValidationErrorMessages:${meta.error}`) : t('LeadGenReportForm:selectDateRange')}</small>
                      <DateRangePicker
                        onChange={(date)=> {input.onChange(date), setDateRange(date)}}
                        clearIcon={null}
                        value={dateRange}
                        locale={i18n.language}
                        rangeDivider="--"
                        format="MM/dd/yyyy"
                        calendarClassName="SelectorContainer"
                        yearPlaceholder={t('yearPlaceholder')}
                        monthPlaceholder={t('monthPlaceholder')}
                        dayPlaceholder={t('dayPlaceholder')}
                        minDetail="decade"
                        todayButtonPlaceholder={t('today')}                 //don't change the prop name!
                        onTodayButtonClick={()=> {input.onChange([new Date(), new Date()]), setDateRange([new Date(), new Date()])}}          //don't change the prop name!
                      />
                    </Form.Field>
                  )}
                </Field>
                <Field name='sort_type'>
                  {({ input, meta }) => (
                    <SelectField
                      meta={meta}
                      input={input}
                      required
                      placeholder={t('select')}
                      topLabel={t('sortBy')}
                      defaultValue='DonorName'
                      handleOnChange={val => input.onChange(val)}
                      options={options}
                      className= 'SortByTypeSelect'
                    />
                  )}
                </Field>
                <PrimaryButton
                  text={t('search')}
                  width={222}
                  height={50}
                  classType='LightBlueWithPadding'
                  fontSize={16}
                  className='SearchReport'
                />
                <PrimaryButton
                  text={t('clear')}
                  width={222}
                  height={50}
                  classType='Primary'
                  fontSize={16}
                  disabled={submitting}
                  onClick={resetForm}
                  className='ClearForm'
                />
              </Form.Group>
            </Form>
          )}
        />
      )}
    </div>
  )
}

export default LeadGenReportForm
