import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image, Visibility, Dimmer,Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../../assets/media/icons/icon-close-web.png'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer';

const AddUserCenterAccessForm = importer('Components/Forms/AccessManagementForms/AddUserCenterAccessForm');


const AddUserCenterAccessModal = (
    { 
      selectedUser,
      toggleSuccessModal,
      open,
      toggleModal
    }) => {  
  const { t } = useTranslation('AddUserCenterAccessModal'); 

    return (
    <Visibility> 
      <Modal open={open} onClose={null} className='AddUserCenterAccessModal'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
               {t('header').replace("%u%", selectedUser.name)}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image src={CloseIcon} alt='Close' onClick={toggleModal} className='CloseModal' />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
            <AddUserCenterAccessForm selectedUser={selectedUser} toggleSuccessModal={toggleSuccessModal} closeAddCenterModal={toggleModal}/>
        </Modal.Content>
      </Modal>
    </Visibility>
    )
}


export default AddUserCenterAccessModal;
