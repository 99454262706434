import React from "react"
import { Menu } from "semantic-ui-react"
import { NavLink } from "react-router-dom"

import "./styles.scss"

import importer from "@takedapdt/biolife-core/src/importer"
const { ScrollToTop } = importer("Helpers/Utils", null)

const VerticalMenu = ({ items, compact }) => {

  return (
    <Menu text vertical compact={compact}>
      {items?.map((item) => {
        if (!item.hidden) {
          return (
            <Menu.Item
              key={item.id}
              name={item.name}
              as={NavLink}
              to={item.to}
              className="VerticalMenuItem"
              content={item.name}
              onClick={() => ScrollToTop()}
            />
          )
        }
      })}
    </Menu>
  )
}

export default VerticalMenu