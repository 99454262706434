import React, { Component } from 'react'
import { Container, Embed, Header } from 'semantic-ui-react'
import './styles.scss'

const videoData = []

class EmbeddedVideos extends Component {
  render () {
    for (let i = 0; i < videoData.length; i++) {
      videoData[i].url = videoData[i].url.replace('watch?v=', 'embed/')
    }

    return (
      <div className='EmbeddedVideos'>
        <Container>
          {videoData.map((video, index) => (
            <div key={index} className='Video'>
              <Header as='h4'>{video.title}</Header>
              <Embed
                autoplay={false}
                color='white'
                hd={false}
                iframe={{
                  allowFullScreen: true,
                  style: {
                    padding: 10
                  }
                }}
                url={video.url}
              />
            </div>
          ))}
        </Container>
      </div>
    )
  }
}

export default EmbeddedVideos
