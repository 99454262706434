import React from 'react'
import { connect } from 'react-redux'
import { Container, Grid, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const RegistrationWizardSteps = importer('Components/RegistrationWizardSteps')
const SignUpForm = importer('Components/Forms/SignUpForm')
/* ACTIONS */
const userActions = importer('Stores/Users/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const centersActions = importer('Stores/Centers/Actions')

const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { webArticles } = importer('Helpers/WebArticles')

const HeroSignUp = ({
  registerDonor,
  registerDonorSuccess,
  registerDonorFailure,
  registerDonorLoading,
  getDonorCentersAll,
  centers,
  webContents,
  saveSignUp
}) => {
  const { t } = useTranslation('HeroSignUp');

  const disclaimerText = ArticleByIdentifier(
    webContents,
    webArticles.WEB_DISCLAIMER_TEXT
  )

  const agreeText = ArticleByIdentifier(
    webContents,
    webArticles.MOBILE_MARKETING_STATEMENT
  )

  const registrationHeroText = ArticleByIdentifier(
    webContents,
    webArticles.REGISTRATION_HERO_TEXT
  )
  const signUpPageHeading=ArticleByIdentifier(
    webContents,
    webArticles.SIGNUP_PAGE_HEADING
  )
  
  return (
    <div className='HeroSignUp'>
      <Container>
      <div className='SignUpTextContainerHeading'  dangerouslySetInnerHTML={{__html: signUpPageHeading?.content__c}} />
        <RegistrationWizardSteps step={1} />
        <div className='HeroContent'>
          <Grid columns={2}>
            <Grid.Column computer={8} mobile={16} tablet={16}>
              <div className='SignUpTextContainer' dangerouslySetInnerHTML={{__html: registrationHeroText?.content__c}} />
            </Grid.Column>
            <Grid.Column computer={8} mobile={16} tablet={16}>
              <SignUpForm
                saveSignUp={saveSignUp}
                registerDonor={registerDonor}
                registerDonorSuccess={registerDonorSuccess}
                registerDonorFailure={registerDonorFailure}
                registerDonorLoading={registerDonorLoading}
                getDonorCentersAll={getDonorCentersAll}
                centers={centers}
                disclaimerText={disclaimerText}
                agreeText={agreeText}
              />
            </Grid.Column>
          </Grid>
        </div>
      </Container>
    </div>
  )
}

const mapStateToProps = state => ({
  registerDonorSuccess: state.donors.registerDonorSuccess,
  registerDonorFailure: state.donors.registerDonorFailure,
  registerDonorLoading: state.donors.registerDonorLoading,
  centers: state.centers.donorCenters?.data,
  webContents: state.publicContent.WebArticles?.completeArticles
})

const mapDispatchToProps = dispatch => ({
  saveSignUp: user => dispatch(userActions.saveSignUp(user)),
  registerDonor: donor => dispatch(donorsActions.registerDonor(donor)),
  getDonorCentersAll: (searchStr, isDIS8Center, searchType, addNewDonorBonusInformation) =>
    dispatch(centersActions.getDonorCentersAll(searchStr, isDIS8Center, searchType, addNewDonorBonusInformation))
})

export default connect(mapStateToProps, mapDispatchToProps)(HeroSignUp)
