import React from 'react'
import { Header, Grid, Image } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'
import bioLifeCenterImg from '../../../../assets/media/images/biolife-center-image.jpg'
import './styles.scss'
import { donationCenterOfficeHours, donationCenterPlayroom } from './helperFactory'

const SelectSearch = importer('Components/Inputs/SelectSearch')

const CenterOpsDonorCenter = () => {
  const { t} = useTranslation('daysFull','centerOpsDonorCenter');
  const options = [
    { key: 'co', value: 'co', text: 'Cordova Center' },
    { key: 'fl', value: 'fl', text: 'Florida' },
    { key: 'tx', value: 'tx', text: 'Texas' }
  ]

  const handleSelection = value => {}

  return (
    <div className='CenterOpsCenter'>
      <Grid textAlign='right' columns={1}>
        <Grid.Column>
          <NavLink to='#' className='GoBack'>
          {t('centerOpsDonorCenter:backtoSearchResults')}
          </NavLink>
        </Grid.Column>
      </Grid>

      <div className='CenterOpsDonorCenter'>
        <div className='CenterInfoContainer'>
          <p className='DonationCenterLabel'>{t('centerOpsDonorCenter:DONATIONCENTER')}</p>
          <p className='DonationCenterName'>Cordova</p>
          <p className='DonationCenterInfo'>
            8015 Bellvue Parkway Cordova, TN 38016
            <br />
            <a href='tel:9012480251'>(901) 248-0251</a>
          </p>
          <SelectSearch
            options={options}
            handleOnChange={handleSelection}
            className='CenterDropdown'
            placeholder='Change Center'
          />
        </div>
        <div className='ImgContainer'>
          <Image src={bioLifeCenterImg} alt="Biolife Center Image" fluid />
        </div>
        <div className='OfficeHoursContainer'>
          <Header as='h4' className='SectionHeader'>
          {t('centerOpsDonorCenter:officeHours')}
          </Header>
          <Grid columns={2}>
            {donationCenterOfficeHours.map((officeHours, index) => (
              <Grid.Row key={index} className='HoursRow'>
                <Grid.Column width={6} style={{ color: '#1F237A' }}>
                  {t(officeHours.day)}
                </Grid.Column>
                <Grid.Column width={10} style={{ color: '#484848' }}>
                  {officeHours.time}
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </div>
        <div className='OfficeHoursContainer'>
          <Header as='h4' className='SectionHeader'>
          {t('centerOpsDonorCenter:playroomHours')}
          </Header>
          <Grid columns={2}>
            {donationCenterPlayroom.map((officeHours, index) => (
              <Grid.Row key={index} className='HoursRow'>
                <Grid.Column width={6} style={{ color: '#1F237A' }}>
                  {t(officeHours.day)}
                </Grid.Column>
                <Grid.Column width={10} style={{ color: '#484848' }}>
                  {officeHours.time}
                </Grid.Column>
              </Grid.Row>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  )
}

export default CenterOpsDonorCenter
