import React from 'react'
import { useTranslation } from 'react-i18next';

import { Header } from 'semantic-ui-react'
import './styles.scss'

const SetupTitleHeader = props => {
  const { t } = useTranslation('SetupTitleHeader');
  return (
    <Header as='h3' className='SetupTitleHeader'>
      {t('appointmentSetup')}
    </Header>
  )
}

export default SetupTitleHeader
