import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Grid, Radio, Segment, Container } from 'semantic-ui-react'
import { setErrorMessageTitle } from '../../../Helpers/Utils'

import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const AppointmentHours = ({
  appointment,
  handleAppointment,
  availableSlots = [],
  centerSlotsFailure,
  centerSlotsLoading = false
}) => {
  const { t } = useTranslation('AppointmentHours');
  const [selectedHour, setSelectedHours] = useState(null)
  const [changes, setChanges] = useState(false)
  const handleHoursChange = (e, { value }) => {
    setSelectedHours(value.appointmentSlotTime)
    handleAppointment({
      ...appointment,
      startTime: value.appointmentSlotTime,
      startDateTime: value.appointmentSlotDateTime
    })
  }

  useEffect(() => {
    if (appointment.startTime) {
      setSelectedHours(appointment.startTime)
    } else {
      setSelectedHours(null)
    }
    if (appointment.appointmentType) {
      setChanges(true)
    }
  }, [appointment])

  if (centerSlotsLoading) {
    return (
      <div className='AppointmentHours'>
        <LoadingState />
      </div>
    )
  }
  if (changes && centerSlotsFailure) {
    if(centerSlotsFailure.code === 'DonorInquiryRequestTimeout'){
      return (
      <div className='AppointmentHours'>
      <p className='NoResults' style={{fontSize:'18px'}}>{setErrorMessageTitle(centerSlotsFailure.message).title}</p>
      <p className='NoResults' style={{fontWeight: 'normal'}}>{setErrorMessageTitle(centerSlotsFailure.message).message}</p>
    </div>)
    }else {
      return (
        <div className='AppointmentHours'>
          <p className='NoResults'>{centerSlotsFailure.message}</p>
        </div>
      )
    }
    
  }
  if (appointment && appointment.appointmentType === '') {
    return (
      <div className='AppointmentHours'>
        <p className='NoResults'>{t('pleaseSelectAnAppointmentType')}</p>
      </div>
    )
  }

  return (
    <div className='AppointmentHours'>
      <Container fluid>
        <Grid columns={6} className="AppointmentSlotGrid">
          {availableSlots && availableSlots.length > 0 &&
            availableSlots.map((h, index) => (
              <Grid.Column key={index} mobile={8} computer={3} tablet={4}>
                <Segment
                  compact
                  className={selectedHour === h.appointmentSlotTime ? 'HourButtonSelected' : 'HourButton'}>
                  <Radio
                    label={h.appointmentSlotTime.toLowerCase()}
                    value={h}
                    name='hours'
                    onChange={handleHoursChange}
                    checked={selectedHour === h.appointmentSlotTime}
                  />
                </Segment>
              </Grid.Column>
            ))}
        </Grid>
      </Container>
    </div>
  )
}

export default AppointmentHours
