import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = values => {
  //const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  const errors = {}
  const { email } = values
  if (!email) {
    errors.email = 'emailIsRequired'
  } else if (!isValidEmail(values.email)) {
    errors.email = 'pleaseEnterAValidEmailId'
  }
  return errors
}

export default validate
