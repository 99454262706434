import React, { useState, useEffect } from "react";
import { Radio, Grid, Form, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import moment from "moment";
import {
  filterNonNomogramCenters,
  splitCenterName,
} from "../../../Helpers/Utils";
import { useTranslation } from "react-i18next";
import "./style.scss";
import importer from "@takedapdt/biolife-core/src/importer";
const centersActions = importer("Stores/Centers/Actions");

const AlternativeCenters = (props) => {
  const {
    loggedInUser,
    donor,
    appointment,
    alternateCenters,
    handleAppointment,
    getSlotsForAppointmentType,
    getAlternateDonorCenters,
    center
  } = props;
  const [selectedLocationCenterNum, setSelectedLocationCenterNum] = useState(null);
  const [nomoFilteredAlternateCenters, setNomoFilteredAlternateCenters] = useState();
  const [showButton, setShowButton] = useState(4);
  const { t } = useTranslation("alternateCenters");
  const todayDate = moment().format("YYYY-MM-DDT00:00:00");
  const handleLocationChange = (value) => {
    setSelectedLocationCenterNum(value?.centerNum);
    getSlotsForAppointmentType(
      todayDate,
      null,
      null,
      0,
      value?.sfid,
      appointment?.appointmentType,
      null,
      donor?.sfid
    );
    handleAppointment({
      ...appointment,
      center: value?.name,
      centerCity: value?.city,
      centerName: value?.name,
      centerSfid: value?.sfid,
    });
  };

  useEffect(() => {
    if (alternateCenters) {
      setNomoFilteredAlternateCenters(
        filterNonNomogramCenters(alternateCenters, donor, loggedInUser)
      );
    }
  }, [alternateCenters]);

  const buttonClicked = () => {
    setShowButton(nomoFilteredAlternateCenters?.length);
  };

  const viewLessButton = () => {
    setShowButton(4);
  };

  useEffect(() => {
    getAlternateDonorCenters(donor?.sfid, appointment?.startDateTime);
  }, []);

  return (
    (nomoFilteredAlternateCenters?.length ? <div className="AlternateCentersContainer">
      <div className="DonationCenterClosedMessage">
        {t("donationCenterClosedMessage")}
      </div>
      <div className="SelectNearByCenterMessage">
        {t("selectNearByCentersMessage")}
      </div>
      <div className="ui four column relaxed grid AlternateCentersFormGrid">
        {nomoFilteredAlternateCenters
          ?.slice(0, showButton)
          ?.map((center, index) => (
            <Form.Group key={index}>
              <Grid
                onClick={() => {
                  handleLocationChange(center);
                }}
                className={
                  selectedLocationCenterNum === center?.centerNum
                    ? "RadioLocationChecked"
                    : "RadioLocation"
                }
              >
                <Grid.Column width={2}>
                  <Radio
                    name="donationCenter"
                    checked={selectedLocationCenterNum === center?.centerNum}
                  />
                </Grid.Column>
                <Grid.Column width={14}>
                  <h6 className="LocationName">{center?.name}</h6>
                  {center?.isSpanishSupported && (
                    <h6 className="spanishCenter">{t("seHablaEspanol")}</h6>
                  )}
                  <p className="locationAddress">
                    {center?.addressLine1}
                    {`${splitCenterName(center?.city)}, ${center?.stateCode} ${
                      center?.zipcode
                    }`}
                  </p>
                </Grid.Column>
              </Grid>
            </Form.Group>
          ))}
      </div>
      <div className="ChangeCenterDisclaimerMessage">
        {nomoFilteredAlternateCenters?.length > 4 &&
          showButton !== nomoFilteredAlternateCenters?.length && (
            <div className="ViewMoreButton">
              <Button onClick={() => buttonClicked()}>
                {t("viewMoreButton")}
              </Button>
            </div>
          )}
        {showButton >= 5 &&
          showButton === nomoFilteredAlternateCenters?.length && (
            <div className="ViewLessButton">
              <Button onClick={() => viewLessButton()}>
                {t("viewLessButton")}
              </Button>
            </div>
          )}
        {t("alternateCentersDisclaimerMessage")}
      </div>
    </div> : null)
  );
};

const mapStateToProps = (state) => ({
  alternateCenters: state.centers?.getAlternateDonorCentersInfoSuccess?.data?.nearbyCenters
});

const mapDispatchToProps = (dispatch) => ({
  getSlotsForAppointmentType: (
    forDate,
    fromDateTime,
    toDateTime,
    numberOfChildren,
    centerSfid,
    appointmentType,
    appointmentId,
    donorSfid
  ) =>
    dispatch(
      centersActions.getSlotsForAppointmentType(
        forDate,
        fromDateTime,
        toDateTime,
        numberOfChildren,
        centerSfid,
        appointmentType,
        appointmentId,
        donorSfid
      )
    ),
  getAlternateDonorCenters: (sfid, todayDate) => dispatch(centersActions?.getAlternateDonorCentersInfo(sfid, todayDate))
  
});

export default connect(mapStateToProps, mapDispatchToProps)(AlternativeCenters);
