import React, { useState, useEffect } from 'react'
import {
  Container,
  Accordion as AccordionSemantic,
  Icon
} from 'semantic-ui-react'

const PageContentAccordion = ({ data }) => {

  const [activeIndex, setActiveIndex] = useState([])

  const handleClick = (e, itemProps) => {
    const { index } = itemProps;
    let newState;
    if (activeIndex.indexOf(index) > -1) {
      newState = activeIndex.filter((i) => i !== index);
    } else {
      newState = [...activeIndex, index]
    }
    setActiveIndex(newState);
  }

  useEffect(() => {
    setActiveIndex([])
  }, [data])

  return (
    <div className='Accordion'>
      <Container>
        <AccordionSemantic fluid>
          {data?.map((item, index) => {
            return (
              <div key={`accordion_item_${index}`}>
                <AccordionSemantic.Title
                  active={activeIndex.includes(index)}
                  index={index}
                  onClick={handleClick}
                >
                  <Icon name={activeIndex.includes(index) ? 'chevron up' : 'chevron down'} />
                  <h2>{item.title}</h2>
                </AccordionSemantic.Title>
                <AccordionSemantic.Content active={activeIndex.includes(index)}>
                  <div
                    className='AccordionContent'
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                </AccordionSemantic.Content>
              </div>
            )
          })}
        </AccordionSemantic>
      </Container>
    </div>
  )
}

export default PageContentAccordion
