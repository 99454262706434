import React, { useState } from 'react'

import { Form, Icon, Header } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { NavLink } from 'react-router-dom'

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const InputField = importer('Components/Forms/FormFields/InputField')
const validate = importer('Helpers/Validations')

const LogInForm = () => {
  const [passwordReveal, setPasswordReveal] = useState(false)

  const togglePasswordReveal = () => setPasswordReveal(!passwordReveal)

  const onSubmit = () => {
    // Implementation here
  }
  return (
    <div className='LogInForm'>
      <Header as='h1' className='MainHeader'>
        Log In
      </Header>
      <FinalForm
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, pristine }) => (
          <Form onSubmit={handleSubmit}>
            <Field name='username'>
              {({ input, meta }) => (
                <InputField
                  required
                  meta={meta}
                  {...input}
                  height={46}
                  placeholder='Username *'
                  topLabel='Username'
                />
              )}
            </Field>
            <Field name='password'>
              {({ input, meta }) => {
                return (
                  <InputField
                    required
                    meta={meta}
                    {...input}
                    height={46}
                    placeholder='Password'
                    type={passwordReveal ? 'text' : 'password'}
                    icon={
                      <Icon
                        name={passwordReveal ? 'eye slash' : 'eye'}
                        link
                        onClick={togglePasswordReveal}
                      />
                    }
                    topLabel='Password *'
                  />
                )
              }}
            </Field>

            <Form.Group>
              <div className='MessageButtonContainer'>
                <p className='FormNote'>
                  <sup>
                    <Icon
                      className='RequiredInfo'
                      size='tiny'
                      name='asterisk'
                    />
                  </sup>
                  Indicates required field
                </p>
              </div>
            </Form.Group>
            <Form.Group className='ButtonContainer'>
              <PrimaryButton
                text='Sign in'
                width={340}
                height={50}
                fontSize={16}
                classType='Primary'
                as={NavLink}
                to='/dashboard/donor-dashboard'
              />
            </Form.Group>
            <Form.Group className='ButtonContainer'>
              <PrimaryButton
                text='Forgot Password?'
                width={340}
                height={50}
                fontSize={16}
                classType='Inverted'
                as={NavLink}
                to='/donor-forgot-password'
              />
            </Form.Group>
            <Form.Group className='ButtonContainer'>
              <PrimaryButton
                text='Register'
                width={340}
                height={50}
                fontSize={16}
                classType='Inverted'
                as={NavLink}
                to='/registration'
              />
            </Form.Group>
          </Form>
        )}
      />
    </div>
  )
}

export default LogInForm
