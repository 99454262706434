import React from "react";
import { Modal, Grid, Header, Image } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import NotificationIcon from "../../../../assets/media/icons/icon-notification.png";
import CloseIcon from "../../../../assets/media/icons/icon-close-web.png";

import "./styles.scss";
import importer from "@takedapdt/biolife-core/src/importer";

const PrimaryButton = importer("Components/Buttons/PrimaryButton");

const BiolifePaymentCardsModal = ({ openBiolifePaymentCardsModal, toggleBiolifePaymentCardsModal, headerText, bodyTextLine1, link, bodyTextLine2 }) => {
  const { t } = useTranslation("BiolifePaymentCardsModal");
  return (
    <Modal
      open={openBiolifePaymentCardsModal}
      onClose={toggleBiolifePaymentCardsModal}
      className="BiolifePaymentCardsModal"
    >
      <Modal.Header className="HeaderContainer">
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as="h2" className="ModalHeader">
            {headerText}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} style={{ textAlign: "right" }}>
            <Image src={CloseIcon} alt="Close" onClick={toggleBiolifePaymentCardsModal} />
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className="ModalContent">
          <Grid columns={2} className="NotificationContent">
            <Grid.Column computer={2} tablet={2} mobile={4}>
              <Image src={NotificationIcon} alt="Notification" style={{ marginTop: 0 }} />
            </Grid.Column>
            <Grid.Column
              computer={14}
              tablet={14}
              mobile={12}
              className="NotificationText"
            >
              <Header className="HeaderText">
                {t("Notification")}
              </Header>

              <p className="RegularText">
              {bodyTextLine1}
              </p>
                {bodyTextLine2 && <p className="RegularText"> {bodyTextLine2}</p>}
            </Grid.Column>
          </Grid>
        </div>
        <div className="ModalActions">
          <Grid columns={2}>
            <Grid.Column className="ConfirmContainer">
              <a href={link} target="_blank">
                <PrimaryButton
                  text={t("Continue")}
                  width={window.innerWidth > 480 ? 280 : 315}
                  height={50}
                  fontSize={16}
                  classType="Primary"
                  onClick={toggleBiolifePaymentCardsModal}
                />
              </a>
            </Grid.Column>
            <Grid.Column>
              <PrimaryButton
                text={t("Cancel")}
                width={window.innerWidth > 480? 280 : 315}
                height={50}
                fontSize={16}
                classType="Inverted"
                onClick={toggleBiolifePaymentCardsModal}
              />
            </Grid.Column>
          </Grid>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default BiolifePaymentCardsModal;
