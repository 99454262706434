import React, { useEffect, useLayoutEffect, useState } from 'react'
import { connect } from 'react-redux'
import importer from "@takedapdt/biolife-core/src/importer";
import { Card, Image, Grid } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import "./styles.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import badgeCheck from "./badgeCheck.png"

const RecentRewardActivity = importer('Components/Dashboard/LoyaltyDashboardTabs/MyRewards/RecentRewardActivity');
const TopEarningActivity  = importer('Components/Dashboard/LoyaltyDashboardTabs/MyRewards/TopEarningActivity');
const PatientTestimonials=importer('Components/Dashboard/LoyaltyDashboardTabs/MyRewards/PatientTestimonials');
const LoyaltyAchievementsModal = importer('Components/LoyaltyModal')
const MyRewards = ({loyaltyAchievementBadges, loyaltyUserData, setTab}) => {

  const [loyaltyAchievementsBadges, setLoyaltyAchievementsBadges] = useState([])
  const [openAchievementsModal, setOpenAchievementsModal] = useState(false)
  const [renderAchievmentsCarousel, setRenderAchievmentsCarousel] = useState(true)
  const { t, i18n } = useTranslation('MyRewards');
  const AchievementBadge = ({title, subtitle, iconUrl}) => {
    return(
        <Grid columns={2} stackable className='badgeDetailsContainer' verticalAlign="middle">
            <Grid.Row>
                <Grid.Column width={4}>
                    <Image src={iconUrl} />
                </Grid.Column>
                <Grid.Column width={12}>
                    <Grid.Row className="badgeTitle"><h3>{title}</h3></Grid.Row>
                    <Grid.Row>{subtitle}</Grid.Row>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}

  const sliderSettings = {
    arrows: true,
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };
  useLayoutEffect(() => {
    const userBadgesArray = loyaltyUserData?.loyaltyBadges?.split(',')?.reverse();
    const transformedBadgesArray = [];
    const sortingBadgesArray = [];
    userBadgesArray?.map(userBadge => {
      const badge = loyaltyAchievementBadges?.find(item => item.bll_achievement_badge_code__c === userBadge)
      if(badge){
        transformedBadgesArray.push({...badge, achieved: true})
      }
    })
    loyaltyAchievementBadges?.map(item => {
      if(item.bll_active__c && !userBadgesArray?.includes(item.bll_achievement_badge_code__c)){
        sortingBadgesArray.push({...item, achieved: false})
      }
    })
    setLoyaltyAchievementsBadges([...transformedBadgesArray, ...sortingBadgesArray])
  },[loyaltyAchievementBadges, loyaltyUserData])

  useEffect(() => {
    setRenderAchievmentsCarousel(false)
    setTimeout(() => setRenderAchievmentsCarousel(true), 300)
  }, [i18n.language])

  return (
    <><div className='MyRewardsContainer'>
      <Card fluid className='MyAchievementsCard'>
        <Card.Header>
          <h2>{t('myAchievements')}</h2>
          <span className='achievementsCtaWeb' onClick={() => setOpenAchievementsModal(true)}>{t('whatAreAchievements')}</span>
        </Card.Header>
        <Card.Meta>
          {t('subtext')}
        </Card.Meta>
        <Card.Description>
          <div className="SliderContainer">
            {renderAchievmentsCarousel &&
              <Slider {...sliderSettings}>
                {loyaltyAchievementsBadges?.map(item => (
                  <div className='badgeContainer' key={item.id}>
                    {item.achieved && <Image className='badgeCheck' src={badgeCheck} size="mini" />}
                    <Image className='badgeImage' src={item.bll_badge_icon_url__c} circular size="tiny" style={{ filter: item.achieved ? '' : 'grayscale(1)', opacity: item.achieved ? 1 : 0.5 }} />
                    <p className='badgeName' style={{ opacity: item.achieved ? 1 : 0.5 }}>{item.bll_achievement_badge_title__c}</p>
                  </div>
                ))}
              </Slider>}
          </div>
          <span className='achievementsCtaMobile' onClick={() => setOpenAchievementsModal(true)}>{t('whatAreAchievements')}</span>
        </Card.Description>
      </Card>
      <LoyaltyAchievementsModal
        openModal={openAchievementsModal}
        header={t('achievmentsModalHeader')}
        description={t('achievementsModalDescription')}
        onClose={() => setOpenAchievementsModal(false)}
      >
        <Grid columns={2} stackable>
          <Grid.Row>
            {loyaltyAchievementBadges?.filter(item => item.bll_active__c === true)?.map(item => (
              <Grid.Column key={item.id}>
                <AchievementBadge title={item.bll_achievement_badge_title__c} subtitle={item.bll_achievement_badge_sub_title__c} iconUrl={item.bll_badge_icon_url__c} />
              </Grid.Column>
            ))}
          </Grid.Row>
        </Grid>
      </LoyaltyAchievementsModal>
    </div>
    <div className='sections'>
      <div className='card1'>
        <RecentRewardActivity setTab={setTab} />
      </div>
      <div className="card2">
        <TopEarningActivity setTab={setTab} />
      </div>
    </div>
    <div className="TestimonialsSection">
        <PatientTestimonials/>
        <Link to={{pathname:"/dashboard/account-setting" , state:{fromLoyaltyDashboard:true}}}  preventScrollReset={true} className="OptOutLink" > {t("OptOutLoyalty")}</Link>
      </div>
      </>
  )
}
const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  loyaltyAchievementBadges: state.publicContent?.getLoyaltyAchievementBadgesSuccess,
  loyaltyUserData: state.loyalty.getLoyaltyDetails?.data,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps,mapDispatchToProps)(MyRewards);