import React from 'react'
import { useTranslation } from 'react-i18next';
import { Header } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const ChangePasswordConfirm = () => {
  const { t } = useTranslation('ChangePasswordConfirm');
  return (
    <div className='ChangePasswordConfirm'>
      <Header as='h1' className='MainHeader'>
        {t('changePassword')}
      </Header>
      <p className='Message'>{t('yourpasswordhasbeenupdated')}</p>
      <div className='ActionButtonContainer'>
        <PrimaryButton
          text={t('signin')}
          as={NavLink}
          to='/login'
          height={50}
          width='80%'
          className='SignInButton'
          fontSize={16}
        />
      </div>
    </div>
  )
}

export default ChangePasswordConfirm
