import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import { Container, Header } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next';
const AccessManagementHeader = importer('Components/CenterOps/AccessManagement/AccessManagementHeader');
const AccessManagementContainer = importer('Components/CenterOps/AccessManagement/AccessManagementContainer');
const AppContainer = importer('Containers/App')
const Footer = importer('Components/Footer')

const CenterOpsAccessManagementView = () => {
  const { t } = useTranslation('centerOpsAccessManagement');
  const pageName=t('pageHeader');
  return (
    <AppContainer compact title={t('acessManagementTitle')}>
      <Helmet>
        <title>{t('accessManagementTitle')}</title>
        <meta name="description" content= {t('accessManagementMeta')}/>
      </Helmet>
      <div className='MainContainer CenterOpsContainer'>
        <Container fluid>
            <AccessManagementHeader/>
            <AccessManagementContainer/>
        </Container>
      </div>
      <Footer />
    </AppContainer>
  )
}

export default CenterOpsAccessManagementView