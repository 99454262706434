import React from 'react'
import { Container, Grid } from 'semantic-ui-react'

import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'
const getYoutubeId = importer('Helpers/YoutubeId')

const renderVideo = video => {
  const id = getYoutubeId(video.video_url__c)
  if (id) {
    const [name, duration] = String(video.name).split('?t=')
    return (
      <div className='VideoContainer'>
        <iframe
          className='VideoIframe'
          width='100%'
          src={`https://www.youtube-nocookie.com/embed/${id}`}
          frameBorder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowFullScreen
        ></iframe>
        <p className='VideoName'>
          {name} <span className='VideoDuration'>{duration}</span>
        </p>
      </div>
    )
  }

  return null
}

const PageContentVideo = ({ videos }) => (
  <div className='PageVideoContent'>
    <Container fluid>
      <Grid columns={2}>
        {videos.map(video => (
          <Grid.Column
            key={`video_${video.id}`}
            mobile={16}
            tablet={7}
            computer={7}
          >
            {renderVideo(video)}
          </Grid.Column>
        ))}
      </Grid>
    </Container>
  </div>
)

export default PageContentVideo
