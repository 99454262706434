import React, {useState, useEffect} from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { isEmpty } from 'lodash'
import importer from '@takedapdt/biolife-core/src/importer'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
const AppContainer = importer('Containers/App')
const VerifyAccountForm = importer('Components/Forms/VerifyAccountForm')
const Footer = importer('Components/Footer')
const UserActions = importer('Stores/Users/Actions')

const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { pageMetaArticles } = importer('Helpers/menuBuilder')

const ResetPassword = ({
  verifyToken,
  verifyTokenLoading,
  verifyTokenSuccess,
  verifyTokenError,
  resetPassword,
  resetPasswordSuccess,
  resetPasswordLoading,
  resetPasswordError,
  webArticles
}) => {
  const location = useLocation()
  const history = useHistory()
  const {t} = useTranslation('ResetPassword')
  const handleSubmit = (values) => {
    resetPassword(verifyTokenSuccess, values.password)
  }
  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      const token = searchParams.get('token');
      if(token){
        verifyToken(token)
      }
    }
  }, [location])

  useEffect(() => {
    if (resetPasswordSuccess != null) {
      history.push('/account-setup-success')
    }
  }, [resetPasswordSuccess])

  const resetPageMeta =  ArticleByIdentifier(
    webArticles,
    pageMetaArticles.RESET_PASSWORD_META
  )

  return (
    <AppContainer compact title={`${resetPageMeta?.meta_title__c}`}>
      {!isEmpty(resetPageMeta) &&
      <Helmet>
        <title>{`${resetPageMeta?.meta_title__c}`}</title>
        <meta name="description" content= {`${resetPageMeta?.meta_description__c}`} />
      </Helmet>}
      <VerifyAccountForm
        verifyTokenLoading={verifyTokenLoading}
        verifyTokenError={verifyTokenError}
        verifyTokenSuccess={verifyTokenSuccess}
        resetPasswordSuccess={resetPasswordSuccess}
        resetPasswordLoading={resetPasswordLoading}
        resetPasswordError={resetPasswordError}
        handleSubmit={handleSubmit}
      />
      <Footer />
    </AppContainer>
  )
}

const mapStateToProps = ({ users, publicContent }) => ({
  verifyTokenLoading: users.resetPassword2Loading,
  verifyTokenSuccess: users.resetPassword2Success,
  verifyTokenError: users.resetPassword2Failure?.response?.data,
  resetPasswordLoading: users.resetPassword3Loading,
  resetPasswordSuccess: users.resetPassword3Success,
  resetPasswordError: users.resetPassword3Failure?.response?.data,
  webArticles: publicContent.WebArticles?.completeArticles
})

// Broken down existing consecuent calls of /verify-token and /reset-password to individual sagas, as the flow has changed. First need to verify the token on page load, depending on response show the page.
const mapDispatchToProps = dispatch => ({
  verifyToken: (token) => dispatch(UserActions.resetPassword2(token)),
  resetPassword: (token, password) => dispatch(UserActions.resetPassword3(token.stateToken, password))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)
