import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'
import { Accordion as AccordionSemantic, Icon } from 'semantic-ui-react'

const MergeDonorAccountForm = importer('Components/Forms/MergeDonorAccountForm')
const ResendVerification = importer('Components/Forms/ResendVerification')
const DonorAccountDetail = ({ donor }) => {
  const { t } = useTranslation('DonorAccountDetail');
  const [activeIndex, setActiveIndex] = useState()

  const data = [
    { title: "changeEmail", content: (<MergeDonorAccountForm donor={donor} />) },
    { title: "reSendVerification", content: (<ResendVerification />) },
  ]
  const accordion = [
    { title: "changeEmail", content: (<MergeDonorAccountForm donor={donor} />) },
  ]

  const [accordionData, setAccordionData] = useState(accordion)


  useEffect(() => {
    if (donor?.profileCompletionStage !== 'ProspectiveDonor') {
      setAccordionData(data)
    }
    else {
      setAccordionData(accordion)
    }
  }, [donor])


  const handleClick = (e, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index
    setActiveIndex(newIndex)
  }

  return (
    <div className="DonorDetailAccordion">
      <AccordionSemantic fluid >
        {accordionData.map((item, index) => (
          <div key={`accordion_tab_${index}`}>
            <AccordionSemantic.Title active={activeIndex === index} index={index} onClick={handleClick}>
              <Icon name={activeIndex === index ? 'chevron up' : 'chevron down'} />
              <h1>{t(item.title)}</h1>
            </AccordionSemantic.Title>
            <AccordionSemantic.Content active={activeIndex === index}>
              {item.content}
            </AccordionSemantic.Content>
          </div>

        ))}
      </AccordionSemantic></div>
  )
}

export default DonorAccountDetail
