import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const AppointmentTabs = ({
  onSaveSlotConfig,
  onTabChange,
  tab,
  setTab,
  toggleMassUpdateModal,
  isCheckboxSelected,
  isInputBlank
}) => {
  const { t,i18n} = useTranslation('AppoinmentTabs');

  useEffect(() => {
    window.sessionStorage.setItem("slotType",tab)
  },[tab])

  return (
    <ul className='AppointmentTabs'>
      <div style={{ display: 'flex' }}>
        <li
          className={tab === 'DONATION' ? 'tabs active' : 'tabs'}
          onClick={() => {
            setTab('DONATION')
            onTabChange('DONATION')
          }}
        >
         {t('donationAppointments')}
        </li>
        <li
          className={tab === 'PHYSICAL' ? 'tabs active' : 'tabs'}
          onClick={() => {
            setTab('PHYSICAL')
            onTabChange('PHYSICAL')
          }}
        >
         {t('physicalAppointments')}
        </li>
      </div>
      <div style={{display: 'flex', justifyContent: 'flex-end', position: 'relative', bottom: '10px' }}>
        <div style={{paddingRight:15, alignSelf: 'flex-end'}}>
      <PrimaryButton
        text={t('save')}
        width={150}
        height={50}
        classType='Primary'
        fontSize={16}
        className='save'
        onClick={onSaveSlotConfig}
        disabled={!isCheckboxSelected}
      />
      </div>
      <div style={{paddingRight:15, alignSelf: 'flex-end'}}>
      <PrimaryButton
        text={t('massUpdate')}
        width={150}
        height={50}
        classType='Primary'
        fontSize={16}
        className={`save ${i18n.language}`}
        onClick={toggleMassUpdateModal}
      />
      </div>
      </div>
    </ul>
  )
}

export default AppointmentTabs
