import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next';

import { Button, Heading } from 'styled-minimal'

export const ReloadWrapper = styled.div`
  button {
    pointer-events: all;
  }
`

const Reload = () => {
  const { t } = useTranslation('Reload');

  return (
  <ReloadWrapper>
    <Heading as='h6' mb={3}>
      {t('newVersion')}
    </Heading>
    <Button
      variant='dark'
      outline
      size='sm'
      onClick={() => window.location.reload()}
    >
      {t('reload')}
    </Button>
  </ReloadWrapper>
  )
  
}

export default Reload
