import React from 'react'
import { connect } from 'react-redux'
import { Header, Container, Grid, Image, List } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import { getOperationHours, formatPhoneNumber, splitCenterName } from '../../../Helpers/Utils'
import bioLifeCenterImg from '../../../../assets/media/images/biolife-center-image.jpg'
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'
/* */
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FaiureState = importer('Components/Dashboard/StateErrorContainer')
const NoDataState = importer('Components/Dashboard/StateNoDataContainer')
const DashboardCenterInformation = ({ donor, loggedInUser, center, error, loading, inquiry, donorLoading }) => {
  const { t } = useTranslation('donorDashboardPage');
  return (
    <div className='DonationCenterInformation'>
      <Header as='h3' className='CenterInformationHeader'>
      {t('centerInfo')}
      </Header>
      {loading && <LoadingState />}
      {error && <FaiureState />}
      {!center && <NoDataState />}
      {!loading && !error && center && (
        <Container fluid className='DashboardCenterContainer'>
          <Grid.Row className='CenterInfoContainer' style={{ backgroundColor: '#EBF7FA', overflow: 'auto' }}>
            <p className='DonationCenterLabel'>{t('yourDonationCenter')}</p>
            <p className='DonationCenterName'>{loggedInUser?.role === 'Admin' ? center.name : splitCenterName(center?.name)}</p>
            <p className='DonationCenterInfo'>
              {center.addressLine1}
              <br />
              {`${loggedInUser?.role === 'Admin' ? center.city : splitCenterName(center?.city)}, ${center.stateCode} ${center.zipcode}`}
              <br />
              <a href={`tel:${center.telephone}`}>{formatPhoneNumber(center.telephone)}</a>
              <br />
              {center.isSpanishSupported && <p style={{paddingTop: '10px',fontStyle: 'italic'}}>Se Habla Español</p>}
            </p>
            <NavLink to='/locations' className='ExploreOtherLocations'>
              {t('exploreOtherLocations')}
            </NavLink> 
          </Grid.Row>
          <Grid.Row className='CenterImageContainer'>
            <Image src={center.mediumImageUrl || bioLifeCenterImg} alt={center.imageAltText} fluid />
          </Grid.Row>

          <div className='CenterMoreDetailsContainer'>
            <Grid.Row>
              <Header as='h4' className='OfficeHoursHeader'>
              {t('officeHours')}
              </Header>
              <List className='OfficeHoursContainer'>
                {center.hoursOfOperation.map((officeHours, index) => (
                  <List.Item key={index}>
                    <Grid columns={2}>
                      <Grid.Column style={{ color: '#1F237A' }} width={5}>
                        {officeHours.weekDayName}
                      </Grid.Column>
                      <Grid.Column className='WorkingHoursColumn' style={{ color: '#484848' }} width={11}>
                        {getOperationHours(officeHours)}
                      </Grid.Column>
                    </Grid>
                  </List.Item>
                ))}
              </List>
              <p className="DifferHours">{t('HrsMayDiffOnMajorHolidays')}</p>
            </Grid.Row>
            <Grid.Row className='ScheduleOnMobileContainer'>
              <PrimaryButton
                text={t('scheduleAppointment')}
                width='100%'
                classType='PrimaryWithPadding'
                fontSize='16px'
                as={NavLink}
                to='/appointment-scheduling'
                disabled={donorLoading || (donor?.pdn && !inquiry?.data)}
              />
            </Grid.Row>
          </div>
        </Container>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  donor: state.donors?.donors?.data,
  loggedInUser: state.users?.user,
})

export default connect(mapStateToProps,null)(DashboardCenterInformation)
