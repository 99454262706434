import moment from 'moment'
import { isEmpty } from 'lodash'

const validate = values => {
  const errors = {}
  if (isEmpty(values.start_date)) {
    errors.start_date = 'startDateCannotBeEmpty'
  } else {
    if (!moment(values.start_date, 'MM/DD/YYYY', true).isValid()) {
      errors.start_date = 'startDateShouldBeMmDdYy'
    }
    if (moment().diff(values.start_date, 'd', true) > 1) {
      errors.start_date = 'startDateCannotBeInThePast'
    }
  }
  if (isEmpty(values.end_date)) {
    errors.end_date = 'endDateCannotBeEmpty'
  } else {
    if (!moment(values.end_date, 'MM/DD/YYYY', true).isValid()) {
      errors.end_date = 'endDateShouldBeMmDdYyyy'
    }
    if (moment(values.start_date).diff(values.end_date, 'd' ) >= 1) {
      errors.end_date = 'endDateCannotBeBeforeStartDate'
    }
  }
  return errors
}


export default validate
