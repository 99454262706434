import React, { useState } from 'react'
import { Container, Grid, Tab, Accordion, Icon } from 'semantic-ui-react'
import './styles.scss'

const PatientTestimonals = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const handleClick = (_, titleProps) => {
    const { index } = titleProps
    const newIndex = activeIndex === index ? -1 : index

    setActiveIndex(newIndex)
  }

  const panes = data.map(({ menuItem, pane }) => ({
    menuItem,
    render: () => (
      <Tab.Pane>
        <div dangerouslySetInnerHTML={{ __html: pane }} />
      </Tab.Pane>
    )
  }))

  return (
    <Container>
      <Grid>
        <Grid.Column className='TestimonalsTabDesktop' only='computer'>
          <Tab
            className='TestimonalsTab'
            panes={panes}
            menu={{ secondary: true, pointing: true }}
          />
        </Grid.Column>
        <Grid.Column className='TestimonalsTabMobile' only='tablet'>
          <Tab
            className='TestimonalsTab'
            panes={panes}
            menu={{
              secondary: true,
              fluid: true,
              vertical: true,
              tabular: true
            }}
          />
        </Grid.Column>
        <Grid.Column className='TestimonalsTabMobile' only='mobile'>
          <Accordion fluid>
            {data.map((item, index) => {
              const isActive = activeIndex === index
              return (
                <div key={`accordion_item_${index}`}>
                  <Accordion.Title
                    active={isActive}
                    index={index}
                    onClick={handleClick}
                  >
                    <Icon name={isActive ? 'chevron up' : 'chevron down'} />
                    <h2>{item.menuItem}</h2>
                  </Accordion.Title>
                  <Accordion.Content active={isActive}>
                    <div
                      className='AccordionContent'
                      dangerouslySetInnerHTML={{ __html: item.pane }}
                    />
                  </Accordion.Content>
                </div>
              )
            })}
          </Accordion>
        </Grid.Column>
      </Grid>
    </Container>
  )
}

export default PatientTestimonals
