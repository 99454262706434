import React from 'react'
import { Grid, Container, Button, Label } from 'semantic-ui-react'

import './styles.scss'

const HorizontalCalendarDayOfWeek = ({
  dayOfWeek,
  dayOfMonth,
  isSeledted,
  onClick,
  centerSlotsFailure
}) => (
  <Container>
    <Grid justify='center'>
      <Grid.Column>
        <Button
          onClick={onClick}
          style={{
            border: 0,
            background: 'transparent',
            padding: 0,
            outline: 'none',
            cursor: 'pointer'
          }}
        >
          <p className='DayLabel'>{dayOfWeek}</p>

          {!centerSlotsFailure && <Label
            circular
            size='massive'
            className={isSeledted ? 'DayNumberLabelSelected' : 'DayNumberLabel'}
          >
            {dayOfMonth}
          </Label>}

          {centerSlotsFailure && <Label
            circular
            size='massive'
            className={isSeledted ? 'DayNumberLabelSelected DayNumberLabelSelectedGrey' : 'DayNumberLabel'}
          >
            {dayOfMonth}
          </Label>}

        </Button>
      </Grid.Column>
    </Grid>
  </Container>
)

export default HorizontalCalendarDayOfWeek
