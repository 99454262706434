import React, { useState, useEffect } from 'react'
import { Grid, Radio, Segment, Container } from 'semantic-ui-react'
import { setErrorMessageTitle } from '../../../Helpers/Utils'

import './styles.scss'
const AppointmentHours = ({ appointment, setIsHoursSelected, handleAppointment, availableSlots = [], centerSlotsFailure, getSlotsSuccessInfo,getSlotsFailureInfo, setAppointment }) => {
  const [selectedHour, setSelectedHours] = useState(null)
  const [changes, setChanges] = useState(false)
  const handleHoursChange = (e, { value }) => {
    setSelectedHours(value.appointmentSlotTime)
    setIsHoursSelected(true)
    setAppointment(prevState => ({
      ...prevState,
      rescheduledTime: value.appointmentSlotTime,
      rescheduledStartDateTime: value.appointmentSlotDateTime
    }))
  }

  useEffect(() => {
    if (appointment.rescheduledTime) {
      setSelectedHours(appointment.rescheduledTime)
    } else {
      setSelectedHours(null)
    }
    if (appointment.appointmentType) {
      setChanges(true)
    }
  }, [appointment])

  if (changes && centerSlotsFailure) {
    if(centerSlotsFailure.code === 'DonorInquiryRequestTimeout'){
      return (
      <div className='AppointmentHours'>
      <p className='NoResults' style={{fontSize:'18px'}}>{setErrorMessageTitle(centerSlotsFailure.message).title}</p>
      <p className='NoResults' style={{fontWeight: 'normal'}}>{setErrorMessageTitle(centerSlotsFailure.message).message}</p>
    </div>)
    }else {
      return (
        <div className='AppointmentHours'>
          <p className='NoResults'>{centerSlotsFailure.message}</p>
        </div>
      )
    }
  }
  availableSlots = getSlotsSuccessInfo
  return (
    <div className='AppointmentHours'>
      <Container fluid>
        <Grid columns={6}>
          {!!availableSlots && availableSlots.data.length > 0 &&
            availableSlots.data.map((h, index) => (
              <Grid.Column key={index}>
                <Segment
                  compact
                  className={selectedHour && selectedHour === h.appointmentSlotTime ? 'HourButtonSelected' : 'HourButton'}>
                  <Radio
                    label={h.appointmentSlotTime.toLowerCase()}
                    value={h}
                    name='hours'
                    onChange={handleHoursChange}
                    checked={selectedHour && selectedHour === h.appointmentSlotTime}
                  />
                </Segment>
              </Grid.Column>
            ))}
        </Grid>
      </Container>
    </div>
  )
}

export default AppointmentHours
