import React from "react"
import { Menu, Icon } from "semantic-ui-react"
import "./styles.scss"
import MenuItem from "./MenuItem"
import { useTranslation } from "react-i18next";

const MembersDashboardMenu = ({ menu, selectedPage, pageContent, signOut }) => {
  const { t } = useTranslation("FactoryFunctionTexts", "MembersDashboardMenu");
  return (
    <div className="ui vertical overlay menu MemberDashboardMobileMenu">
      {menu.map(item => {
        const isOpen = selectedPage && selectedPage.page === item.page

        if (item.hidden) {
          return null
        }

        return (
          <MenuItem
            key={`MenuItem_${item.id}`}
            title={item.name}
            isOpen={isOpen}
            navigateTo={item.to}
            pageContent={pageContent}
          />
        )
      })}

      <Menu.Item onClick={signOut} className="MenuHeader">
        {t("MembersDashboardMenu:logout")}
        <Icon name="chevron right" />
      </Menu.Item>
    </div>
  )
}

export default MembersDashboardMenu