import React, { useState, useEffect } from 'react'
import { Form, Message, Loader, Dimmer, Visibility, Icon } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import validates from './validate'
import { useTranslation } from 'react-i18next';
import './styles.scss';
import { useHistory } from 'react-router-dom'

import importer from '@takedapdt/biolife-core/src/importer'
const InputField = importer('Components/Forms/FormFields/InputField')
const SelectField = importer('Components/Forms/FormFields/SelectField')
const SelectSearch = importer('Components/Inputs/SelectSearch')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
import { mapOptionsByState, sortCentersAlphabetically } from '../../../../Helpers/Utils'
import { connect } from 'react-redux'
const centerActions = importer('Stores/Centers/Actions')
const AccessManagementSuccessModal = importer('Components/CenterOps/AccessManagement/AccessManagementSuccessModal')
import { CenterOpsUserRole } from '../../../../Constant/centerOps_enums';


const CreateAccessManagementForm = ({
    createApplicationUser,
    getAllCenters,
    center,
    createAccountUserLoading,
    createAccountUserFailure,
    createAccountUserSuccess }) => {
    const { t } = useTranslation('CreateAccessManagementForm');
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [errorMessages, setErrorMessages] = useState('');
    const [submited, setSubmited] = useState(false);
    const [centerList, setCenterList] = useState([]);
    const [selectedCenter, setSelectedCenter] = useState(null);
    const [requiredDonationCenter,setRequiredDonationCenter] = useState(null);
    const [isIntialLoad,setIsIntialLoad] = useState(0);
    const [openAccessManagementSuccessModal, setAccessManagementSuccessModal] = useState(false);
    const toggleAccessManagementSuccessModal = () => setAccessManagementSuccessModal(!openAccessManagementSuccessModal)
    const roleOptions = [
        { key: CenterOpsUserRole.STAFF, text: CenterOpsUserRole.STAFF, value: CenterOpsUserRole.STAFF, options: CenterOpsUserRole.STAFF },
        { key: CenterOpsUserRole.MANAGER, text: CenterOpsUserRole.MANAGER, value: CenterOpsUserRole.MANAGER, options: CenterOpsUserRole.MANAGER},
      ];

      useEffect(() => {
        if (createAccountUserSuccess !== null && submited) {
          setLoading(false)
          setAccessManagementSuccessModal(true)
        }
      }, [createAccountUserSuccess])

      useEffect(() => {
        if (createAccountUserFailure !== null && submited) {
          setSubmited(false)
          setLoading(false)
          setErrorMessages(createAccountUserFailure ? createAccountUserFailure.message : t('somethingWentWrong'))
        }
      }, [createAccountUserFailure])

    const onSubmit = values => {
        setLoading(true);
        setSubmited(true);
        if(!selectedCenter){
          setRequiredDonationCenter(true);
        }else{
          values.defaultCenter=selectedCenter;
          createApplicationUser(values);
        }
    };

    const onCancel = () => {
        history.push('/center-ops/access-management')
    }
    
    // This function will called only once
    useEffect(() => {
        getAllCenters();
    }, [])

    useEffect(() => {
        center && preparedOptions()
        .then(res => setCenterList(res))
      },[center]);
      
      const preparedOptions = async () =>{
        const res = await mapOptionsByState(center)
        const data = sortCentersAlphabetically(res)
        return data
      }

    useEffect(() => {
      if (submited && !openAccessManagementSuccessModal) {
        history.push('/center-ops/access-management');
      }
    },[openAccessManagementSuccessModal])
    
    const handleSelection = async ({ value }) => {
      setSelectedCenter(value);
    };

    const validate = (values)=>{
      const errorMessage=validates(values);
      return errorMessage;
    };

    return (
        <Visibility>
        <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, pristine}) => (
        <Form onSubmit={handleSubmit} className='CreateAccessManagementForm'>
            <Form.Group>
            <Field name='name'>
                {({ input, meta }) => (
                <InputField
                    meta={meta}
                    {...input}
                    required
                    placeholder={t('placeholderName')}
                    fieldLabel={t('name')}
                    LabelclassName='NameInput TopLabel'
                    className='CreateInput'
                    icon={
                        <Icon className='Required' size='tiny' name='asterisk' />
                      }
                />
                )}
            </Field>
            <Field name='role'>
                {({ input, meta }) => (
                <SelectField
                    meta={meta}
                    input={input}
                    required
                    placeholder={t('placeholderdefaultRole')}
                    fieldLabel={t('role')}
                    options={roleOptions}
                    className='CreateFormSelect'
                    icon={
                        <Icon className='Required' size='tiny' name='asterisk' />
                      }
                />
                )}
            </Field>
            </Form.Group>
            <Form.Group className="secondRow">
            <Field name='defaultCenter'>
            {({ input, meta }) => (
                  <SelectSearch
                  options={centerList}
                  centers={center}
                  placeholder={t('selectcenter')}
                  handleOnChange={handleSelection}
                  allowZipcodeSearch={true}
                  topLabel={t('defaultCenter')}
                  required={true}
                  className={selectedCenter? "centerSelected":"SelectSearch"}
                  errorMessage={t('mandatoryField')}
                  hasError={requiredDonationCenter}
                />
                )}
            </Field>
            <Field name='email'>
                {({ input, meta }) => (
                <InputField
                    meta={meta}
                    {...input}
                    required
                    placeholder={t('placeholderEmail')}
                    fieldLabel={t('email')}
                    className='CreateInput'
                    LabelclassName='EmailInput TopLabel'
                    errorBottom={true}
                    icon={
                        <Icon className='Required' size='tiny' name='asterisk' />
                      }
                />
                )}
            </Field>
            </Form.Group>
            {errorMessages && (
          <Message warning className='errorMessage'>
            <p>{Array.isArray(errorMessages) ? errorMessages.join(', ') : errorMessages}</p>
          </Message>
        )}
            <Form.Group className='submitBtnGroup'>
            <PrimaryButton
                text={t('cancelButton')}
                classType='Inverted'
                className='cancel'
                onClick={onCancel}
                />
               <PrimaryButton
                text={t('saveButton')}
                classType='Primary'
                className='save' 
            />
            </Form.Group>
            </Form>
      )}
    />
        <AccessManagementSuccessModal
        open={openAccessManagementSuccessModal}
        toggleModal={toggleAccessManagementSuccessModal}
        isToggleRequired={false}
        header={t('successModelHeader')}
        content={t('successModelContent')}
        />
    {(loading && createAccountUserLoading) && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
    </Visibility>
    )
}

const mapStateToProps = (state) => ({
    createAccountUserLoading: state.centers.createApplicationUserLoading,
    createAccountUserSuccess: state.centers.createApplicationUserSuccess,
    createAccountUserFailure: state.centers.createApplicationUserFailure,
    center: state.centers.getDonorCentersAllSuccess?.data,
    
  })
  
  const mapDispatchToProps = (dispatch) => ({
    createApplicationUser: (applicationUser) => dispatch(centerActions.createApplicationUser(applicationUser)),
    getAllCenters: () => dispatch(centerActions.getDonorCentersAll("", "", "", false)),
  })

export default connect(
    mapStateToProps, mapDispatchToProps
) (CreateAccessManagementForm)