import React, { useState, useEffect } from 'react'
import { Form, Icon, Header, Message, Label } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import { NavLink, Link, useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './styles.scss'
import validate from './validate'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
// import { openCookieSettingsModal } from '../../../Helpers/Utils'
/* COMPONENTS */
const InputField = importer('Components/Forms/FormFields/InputField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')

const initialData = {
  username: '',
  password: ''
}
const LogInForm = ({
  onSubmit,
  logoutTimeout,
  loginError,
  loading,
  migrateLoading,
  migrateError,
  resetMigrationError,
  resetLoginError
}) => {
  const [showInactiveMessage, setShowInactiveMessage] = useState(false)
  const [migrationInitiated, setMigrationInitiated] = useState(false)
  const { t } = useTranslation('LogInForm');
  const history = useHistory()
  const handleSubmit = ({ username }) => {
    resetMigrationError()
    onSubmit({ email: username})
  }


  useEffect(() => {
    setMigrationInitiated(false)
    setShowInactiveMessage(window.sessionStorage.getItem('logoutTimeOut'))
    window.addEventListener('beforeunload', function (e) {
      if(window.sessionStorage.getItem('logOutFlow')){
        window.sessionStorage.removeItem('logOutFlow')
      }else {
        window.sessionStorage.removeItem('logoutTimeOut')
      }
      
    });
    
    return () => {
      window.removeEventListener('beforeunload', function (e) {
        window.sessionStorage.removeItem('logoutTimeOut')
       });
    }
  }, [])

  useEffect(() => {
    if (migrateError && migrateError.response && migrateError.response.data) {
      setMigrationInitiated(true)
    }
  }, [migrateError])
  return (
    <div className="login-form page-container">
      <div className='login-form__content page-container__content'>
        <Header as='h1' className='MainHeader'>
          {t('logIn')}
        </Header>
        {showInactiveMessage && (
          <Form.Group className='timeout-container'>
            <Label color='red'>{t('inactivLogout')}</Label>
          </Form.Group>
        )}
        <FinalForm
          onSubmit={handleSubmit}
          validate={validate}
          initialValues={initialData}
          render={({ handleSubmit }) => (
            <Form onSubmit={handleSubmit}>
              <Form.Group className={` ${loginError ? 'error-visible' : ''}`}>
                <Field name='username'>
                  {({ input, meta }) => (
                    <InputField
                      meta={meta}
                      {...input}
                      className='login-form__textinput'
                      fieldLabel={t('usernameWithReq')}
                      value={null}
                      autoComplete='off'
                      customOnChange={() => {
                        if(migrationInitiated){
                          resetMigrationError()
                        }
                        if(loginError){
                          resetLoginError()
                        }
                        if (loginError) {
                          meta.active && meta.touched ? meta.invalid ? meta.dirty ? 'increased-margin' : '' : '' : '';
                        }
                        if(window.sessionStorage.getItem('login4Err'))
                        {
                          window.sessionStorage.removeItem('login4Err')
                        }
                        }}
                    />
                  )}
                </Field>
              </Form.Group>
              <Form.Group>
                <div className='MessageButtonContainer'>
                  <p className='FormNote'>
                    <sup>
                      <Icon className='RequiredInfo' size='tiny' name='asterisk' />
                    </sup>
                    {t('requiredField')}
                  </p>
                  {loginError && !(migrateError || migrateLoading) && (
                    <Message visible warning>
                      {loginError && loginError?.response?.data?.code === 'TryAfterSomeTime'
                        ? <p>{loginError?.response?.data?.message}</p>
                        : loginError = 'notEmployee'? 
                          <p>{t('accessDenied')}</p>
                          :<p>{t('unsuccessFulLogin')}</p>
                      }
                    </Message>
                  )}
                  {window.sessionStorage.getItem('login4Err') && ( 
                    <Message visible warning> 
                      <p>{t(window.sessionStorage.getItem('login4Err'))}</p>
                    </Message>
                  )}
                  {migrateLoading && (
                    <Message visible warning>
                      <p>{t('registering')}</p>
                    </Message>
                  )}
                  {migrationInitiated && migrateError && migrateError.response && migrateError.response.data && (
                    <Message visible warning>
                      <p>{migrateError.response.data.message}</p>
                    </Message>
                  )}
                  {loading && (
                    <LoadingState />
                  )}
                </div>
              </Form.Group>
              <Form.Group className='ButtonContainer'>
                <PrimaryButton text={t('logIn')} width={340} height={50} fontSize={16} classType='Primary' type='submit' />
              </Form.Group>
            </Form>
          )} />
      </div>
    </div>
  )
}

export default LogInForm
