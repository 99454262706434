import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { useHistory, useLocation } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
/* ACTIONS */
const UserActions = importer('Stores/Users/Actions')
/* CONTAINERS */
const AppContainer = importer('Containers/App')
/* COMPOENTS */
const LogInForm = importer('Components/Forms/LogInForm')
const Footer = importer('Components/Footer')
const Config = importer('Config')

const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { pageMetaArticles } = importer('Helpers/menuBuilder')

const { navigate, ROUTES } = importer('NavigationService', null)

const LogIn = ({
  loggedInUser,
  session,
  signIn,
  signInLoading,
  loginError,
  logoutTimeout,
  loginLoading,
  migrateDonor,
  migrateDonorLoading,
  migrateDonorSuccess,
  migrateDonorError,
  resetMigrationError,
  resetLoginError,
  webArticles
}) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loginClicked, setLoginClicked] = useState(false)
  const [origin, setOrigin] = useState(null)
  const history = useHistory()
  const encodedEmail = encodeURIComponent(Buffer.from(email).toString('base64'))
  const encodedPassword = encodeURIComponent(Buffer.from(password).toString('base64'))
  const location = useLocation()
  const {t} = useTranslation('LogIn')
  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search)
      setOrigin(searchParams.get('origin'))
      if(searchParams.get('redirectUri') === '/my-rewards'){
        sessionStorage.setItem("routeToLoyaltyDashboard", '{"tab": "MY_REWARDS"}')
      }
      else if(searchParams.get('redirectUri') === '/ways-to-earn'){
        sessionStorage.setItem("routeToLoyaltyDashboard", '{"tab": "WAYS_TO_EARN"}')
      }
      else if(searchParams.get('redirectUri') === '/redeem-rewards'){
        sessionStorage.setItem("routeToLoyaltyDashboard", '{"tab": "REDEEM_REWARDS"}')
      }
    }
  }, [location])

  useEffect(() => {
    if(session && loggedInUser){
      const route = session.role === 'Donor' ? ROUTES.DonorDashboard : session.role === 'SuperAdmin'? ROUTES.DonorDashboard : ROUTES.CenterDashboard
      navigate(route);
    }
    return function cleanup () {
      setLoginClicked(false)
      sessionStorage.removeItem("routeToLoyaltyDashboard")
    }
  }, [])
  useEffect(() => {
    if (loginError && loginError.response && loginError.response.data) {
      if (loginClicked && loginError.response.data.code === 'DonorNotMigrated') {
        migrateDonor(email, password)
      }
    }
  }, [loginError])

  useEffect(() => {
    if (loginClicked && migrateDonorError && migrateDonorError.response && migrateDonorError.response.data) {
      handleNavigation(migrateDonorError.response.data.code)
    }
  }, [migrateDonorError])

  useEffect(() => {
    if (loginClicked && migrateDonorSuccess && migrateDonorSuccess.data) {
      handleNavigation(migrateDonorSuccess.data.code)
    }
  }, [migrateDonorSuccess])

  const loginPageMeta =  ArticleByIdentifier(
    webArticles,
    pageMetaArticles.LOGIN_PAGE_META
  )


  const handleNavigation = (code) => {
    switch (code) {
      case 'MigrationSuccessful':
        history.push('/account-setup-success')
        break
      case 'NonOktaStandardPassword':
        history.push(`/change-password/${email}`)
        break
      case 'NonOktaStandardLoginId':
        history.push(`/login-with-email/${encodedEmail}/${encodedPassword}`)
        break
    }
  }

  const handleSubmit = ({ email, password }) => {
    if(origin){
      sessionStorage.setItem('authOrigin', origin);
    }else if(sessionStorage.getItem('authOrigin')){
      sessionStorage.removeItem('authOrigin')
    }
    email = email.replace(/\s+/g,'')
    setEmail(email)
    setLoginClicked(true)
    signIn(email)
    window.sessionStorage.removeItem('logoutTimeOut')
  }

  return (
    <AppContainer compact title='LogIn'>
      {!isEmpty(loginPageMeta) &&
      <Helmet>
        <title>{`${loginPageMeta?.meta_title__c}`}</title>
        <meta name="description" content={`${loginPageMeta?.meta_description__c}`} />
        <link rel="canonical" href={`${window.location.origin}${window.location.pathname.toLowerCase()}`} />
      </Helmet>}
      {signInLoading
        ? <Loader /> : <LogInForm
          logoutTimeout={logoutTimeout}
          loading={loginClicked && (migrateDonorLoading || loginLoading)}
          loginError={loginClicked && loginError}
          migrateLoading = {loginClicked && migrateDonorLoading}
          migrateError = {loginClicked && migrateDonorError}
          onSubmit={handleSubmit}
          resetMigrationError={resetMigrationError}
          resetLoginError={resetLoginError}
        />
      }
      <Footer />
    </AppContainer>
  )
}

const mapStateToProps = state => ({
  /* AWS */
  user: state.auth?.user,
  loggedInUser: state.users.user,
  session: state.users.session,
  signInLoading: state.auth?.signInLoading,
  signInErrorMessage: state.auth?.signInErrorMessage,
  /* */
  logoutTimeout: state.users.logoutTimeout,
  // Login donor
  loginLoading: state.users.loginLoading,
  loginError: state.users.loginFailure,
  loginSuccess: state.users.loginSuccess,
  // Migrate donor
  migrateDonor: state.users.verifyOktaAccount,
  migrateDonorLoading: state.users.verifyOktaAccountLoading,
  migrateDonorSuccess: state.users.verifyOktaAccountSuccess,
  migrateDonorError: state.users.verifyOktaAccountFailure,
  webArticles: state.publicContent.WebArticles?.completeArticles
})

const mapDispatchToProps = dispatch => ({
  signIn: (username) => dispatch(UserActions.login(username)),
  signOut: () => dispatch(UserActions.logout()),
  migrateDonor: (username, password) => dispatch(UserActions.verifyOktaAccount(username, password)),
  resetMigrationError: () => dispatch(UserActions.verifyOktaAccountFailure(null)),
  resetLoginError: () => dispatch(UserActions.loginFailure(null))
})

export default connect(mapStateToProps, mapDispatchToProps)(LogIn)
