import React from 'react';
import { Route, Redirect } from 'react-router-dom';

//RouteWrapper is a wrapper to modify all incoming url paths to remove ?lng query string parameter 
//change uppercase paths to lowercase
//and also remove comma followed by hyphen and characters from location name in url
const RouteWrapper = ({ component: Component, ...rest }) => {
    const pathname = rest.location.pathname.toLowerCase();
    const searchParams = new URLSearchParams(rest.location.search);
    const lng = searchParams.get('lng');
    if(lng) {
        searchParams.delete('lng');
    }
    const search = searchParams.toString();
    if (pathname !== rest.location.pathname || lng || (pathname.includes('locations') && pathname.includes(',-'))) { 
        // for the locations related pathname if pathname(end part of the URL) includes comma followed by hyphen and characters then this will remove it.
        const updatedPathname = pathname.includes('locations') && pathname.includes(',-') ? pathname.replace(/,-[^/]*$/, '') : pathname

        return <Redirect to={{ pathname: updatedPathname, search}} />;
    }
    return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default RouteWrapper;
