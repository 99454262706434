import React, { useEffect } from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
import TagManager from 'react-gtm-module'

import { Grid } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import './styles.scss'

const DashboardAppointmentsSection = importer('Components/Dashboard/DashboardAppointmentsSection')
const DashboardCenterInformation = importer('Components/Dashboard/DashboardCenterInformation')
const DashboardMessagesSection = importer('Components/Dashboard/DashboardMessagesSection')
const NearbyCentersSection = importer('Components/Dashboard/NearbyCentersSection')
const CentersActions = importer('Stores/Centers/Actions')
const { getDonorAgeGroup } = importer('Helpers/getDonorAgeGroup', null)

const DonorDashboard = ({
  /* DONOR */
  donor,
  center,
  nearbyCenters,
  centerStatus,
  getDonorNearbyCenters,
  centerLoading,
  donorLoading,
  centerError,
  /* APPOINTMENTS */
  appointments,
  appointmentsLoading,
  appointmentsError,
  /* INQUIRY */
  inquiry,
  inquiryLoading,
  inquiryError
}) => {

  useEffect(() => {
    if (!isEmpty(donor) && isEmpty(nearbyCenters)) {
      getDonorNearbyCenters(donor.sfid)
    }
  }, [donor])

  useEffect(() => {
    if (!isEmpty(center)) {
      // TagManager.dataLayer({
      //   dataLayer: {
      //     donorId: donor.id,
      //     donorPdn: donor.pdn,
      //     donorEmail:donor.email?encodeURIComponent(Buffer.from(donor.email).toString('base64')):donor.email,
      //     //donorEmail:donor.email,
      //     bdpDonorId: donor.bdpDonorId,
      //     sfid: donor.sfid,
      //     donorCountry: donor.country,
      //     donorState: donor.state,
      //     donorZip: donor.zipCode,
      //     donorAgeGroup: getDonorAgeGroup(donor.birthDate),
      //     donorEthnicity: donor.ethnicity,
      //     donorGender: donor.gender,
      //     donorLanguage: donor.language,
      //     donorWorkStatus: donor.workstatus,
      //     centerName: donor.defaultCenter?.name || null,
      //     centerClassification: donor.defaultCenter?.centerClassification || null,
      //     disCenterId: donor.defaultCenter?.centerNum || null,
      //     bdpCenterId: donor.defaultCenter?.bdpCenterId || null,
      //     centerDemographic: donor.defaultCenter?.demographic || null,
      //     donorPhysical: 'Dummy Val',
      //     donorType: 'Dummy Val',
      //     donorDeferred: 'Dummy Val'
      //   }
      // })
    }
  }, [center])

  if (centerStatus && centerStatus !== 'Selected') {
    return <NearbyCentersSection centerStatus={centerStatus} nearbyCenters={nearbyCenters} />
  }

  return (
    <Grid className='DonorDashboard'>
      <Grid.Row columns={2}>
        <Grid.Column computer={8} mobile={16} className='AppointmentContainer'>
          <DashboardAppointmentsSection
            loading={appointmentsLoading || inquiryLoading}
            error={appointmentsError || inquiryError}
            appointments={appointments || []}
            inquiry={inquiry}
            donorLoading={donorLoading}
          />
        </Grid.Column>
        <Grid.Column computer={8} mobile={16} className='CenterDataContainer'>
          <DashboardCenterInformation donorLoading={donorLoading} inquiry={inquiry} loading={centerLoading} error={centerError} center={center} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={1}>
        <Grid.Column>
          <DashboardMessagesSection />
        </Grid.Column>
      </Grid.Row>
    </Grid>
  )
}

const mapStateToProps = ({ donors, centers, appointments }) => ({
  donor: donors.donors?.data,
  center: centers.centers?.data,
  appointments: appointments.getUpcomingAppointmentsSuccess,
  inquiry: donors.inquiryDonorSuccess,
  nearbyCenters: centers.nearbyCenters,
  centerStatus: centers.centerStatus,
  /* LOADING STATE */
  donorLoading: donors.getDonorsLoading,
  centerLoading: centers.getAllCentersLoading,
  appointmentsLoading: appointments.getUpcomingAppointmentsLoading,
  inquiryLoading: donors.inquiryDonorLoading,
  /* ERROR STATE */
  donorError: donors.getDonorsFailure,
  centerError: centers.getAllCentersFailure,
  appointmentsError: appointments.getUpcomingAppointmentsFailure,
  inquiryError: donors.inquiryDonorFailure
})

const mapDispatchToProps = (dispatch) => ({
  getDonorNearbyCenters: (donorSfid) => dispatch(CentersActions.getDonorCenterInfo(donorSfid))
})

// export default DonorDashboard
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DonorDashboard)
