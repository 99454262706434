import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'

const CenterNoSelected = importer('Components/Dashboard/CenterNoSelected')
const NoCentersInArea = importer('Components/Dashboard/NoCentersInArea')

const NearbyCentersSection = ({ centerStatus, nearbyCenters }) => {

    if(centerStatus === 'Not-selected'){
        return (<CenterNoSelected centers={nearbyCenters} />);
    }else {
        return (<NoCentersInArea/>);
    }
}

export default NearbyCentersSection
