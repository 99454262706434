import React from 'react'
import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom'
import { Container, Grid, Segment, Image, Menu, Icon, Button } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'

import BioLifeLogo from './images/BioLife_logo.svg'

const getWidth = importer('Helpers/getWidth')
const SubFooter = importer('Components/SubFooter')
const { ScrollToTop } = importer('Helpers/Utils', null)
const { ArticleByIdentifier } = importer('Stores/PublicContent/Select', null)
const { pageMetaArticles } = importer('Helpers/menuBuilder')

const Footer = ({webArticles}) => {
  const viewport = getWidth()
  const breakpoint = 659
  const isVertical = viewport <= breakpoint
  const { t, i18n } = useTranslation('Footer');

  const contactUsText =  ArticleByIdentifier(
    webArticles,
    pageMetaArticles.CONTACT_US_TEXT
  )
  const nonDiscriminationText = ArticleByIdentifier(
    webArticles,
    pageMetaArticles.NON_DISCRIMINATION_STATEMENT
  )
  const privacyText = ArticleByIdentifier(
    webArticles,
    pageMetaArticles.PRIVACY_TXT
  )
  const TermsAndConditionsTxt = ArticleByIdentifier(
    webArticles,
    pageMetaArticles.TERMS_AND_CONDITIONS
  )

  const getHref = () => {
    switch(i18n.language){
      case 'es-MX':
        return 'https://info.biolifeplasma.com/carreras'
      case 'en-US':
        return 'https://jobs.biolifeplasma.com/'
    }
  }


  return (
    <Segment className='Footer'>
      <Container fluid>
        <Grid verticalAlign='middle' stackable>
          <Grid.Row columns={3} className='FooterMenuContent'>
            <Grid.Column computer={3} mobile={16} tablet={16}>
              <div className='FooterLogoContainer'>
                <Image src={BioLifeLogo} alt="BioLife Logo" size="medium" width="300" height="60" />
              </div>
            </Grid.Column>
            <Grid.Column computer={11} mobile={16} tablet={16} textAlign={isVertical ? 'left' : 'right'}> </Grid.Column>
            <Grid.Column
              computer={2}
              mobile={16}
              tablet={16}
              textAlign='center'
              className='SocialIcons'>
              <Button
                size={isVertical ? 'huge' : 'medium'}
                icon
                className='IconButton'
                href='https://www.facebook.com/BioLifePlasmaServices/'>
                <Icon name='facebook f' />
              </Button>
              <Button
                size={isVertical ? 'huge' : 'medium'}
                icon
                className='IconButton'
                href='https://www.instagram.com/biolifeplasmaservices/'>
                <Icon name='instagram' />
              </Button>
              <Button
                size={isVertical ? 'huge' : 'medium'}
                icon
                className='IconButton'
                href='https://www.pinterest.com/BioLifePlasmaServices/'>
                <Icon name='pinterest' />
              </Button>
              <Button
                size={isVertical ? 'huge' : 'medium'}
                icon
                className='IconButton'
                href='https://www.youtube.com/user/BioLifePlasmaService'>
                <Icon name='youtube' />
              </Button>
              <Button
                size={isVertical ? 'huge' : 'medium'}
                icon
                className='IconButton'
                href='https://www.linkedin.com/company/biolife-plasma-of-mqt/mycompany/'>
                <Icon name='linkedin' />
              </Button>
            </Grid.Column>
          </Grid.Row>
          <SubFooter isVertical={isVertical} />
        </Grid>
      </Container>
    </Segment>
  )
}

const mapStateToProps = (state) => ({
  webArticles: state.publicContent.WebArticles?.completeArticles
})

export default connect(mapStateToProps,null)(Footer)
