const validates = (values) => {
  const errors = {}
  // center Validation
  if(!values.defaultCenter) {
    errors.defaultCenter = 'mandatoryField'
  }
  return errors
}

export default validates
