import React, { useEffect, useState }  from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Container, Grid, Button, Icon } from 'semantic-ui-react'
import {
  getOperationHours,
  getPlaroomHours,
  formatPhoneNumber,
  mapOptionsByState,
  sortCentersAlphabetically
} from '../../../Helpers/Utils'
import bioLifeCenterImg from '../../../../assets/media/images/biolife-center-image.jpg'
import importer from '@takedapdt/biolife-core/src/importer'
import './styles.scss'

const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FaiureState = importer('Components/Dashboard/StateErrorContainer')
const NoDataState = importer('Components/Dashboard/StateNoDataContainer')
const SelectSearch = importer('Components/Inputs/SelectSearch')
const AlertModal = importer('Components/AlertModal')
const UserActions = importer('Stores/Users/Actions')

const DashboardCenterInformation = ({ center,loggedInUser, error, loading, isOpen, setIsOpen, signOut, changeDefaultCenter, user, changeDefaultCenterLoading }) => {
  const { t,i18n } = useTranslation('DonorSearchDonationCenter');
  const centers = user.accessCenters
  const findDefaultCenter = () => {
    return centers.find((item) => {
      return item.donationCenter === center?.sfid
    })
  }

 const [showLogoutModal, setShowLogoutModal] = useState(false)
 const [defaultCenter, setDefaultCenter] = useState(findDefaultCenter())
 const [newCenter, setNewCenter] = useState('')
 const [centerOptions, setCenterOptions] = useState([])
 const preparedOptions = async () => {
  const res = await mapOptionsByState(centers, true,loggedInUser)
  const data = await sortCentersAlphabetically(res)
  return data
}

  useEffect(() => {
    setDefaultCenter(findDefaultCenter())
    preparedOptions()
    .then(res => setCenterOptions(res))
   }, [center])

  const handleSelection = async ({ value }) => {
    if(value !== defaultCenter.donationCenter){
      setNewCenter(value)
      setShowLogoutModal(true)
    }
   
  }

  return (
    <div className='DonationCenterSearch'>
      {loading && <LoadingState />}
      {error && <FaiureState />}
      {!center && <NoDataState />}
      {!loading && !error && center && (
        <Container fluid>
          <Grid columns={2} padded>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={10}
              className='LeftContainer'
            >
              <div style={{flexDirection : 'row', display: 'flex', justifyContent: 'space-between'}}>
              <div style={{flexDirection : 'column'}}>
                  <div className='LeftContent'>
                    <p className='DonationCenterLabel'>{t('donationCenter')}</p>
                    <p className='DonationCenterName'>{center.name}</p>
                    <p className='DonationCenterInfo'>
                      {center.addressLine1}
                      <br />
                      {`${center.city}, ${center.stateCode} ${center.zipcode}`}
                      <br />
                      <a href={`tel:${center.telephone}`}>
                        {formatPhoneNumber(center.telephone)}
                      </a>
                    </p>
                  </div>
                </div>
                <div style={{ flexDirection: 'column', display: 'flex', paddingRight: 20, alignSelf: 'flex-end', paddingBottom: 20, justifyContent:'center' }}>
                  <Button
                    className={!isOpen ? 'Primary' : 'Teal'}
                    onClick={() => setIsOpen(!isOpen)}
                    icon
                    labelPosition='right'
                  >
                   {t('centerHours')}
                  <Icon name={!isOpen ? 'down arrow' : 'up arrow'} />
                  </Button>
                  {!(centers.length == 1 && centers[0].donationCenter === defaultCenter?.donationCenter) &&
                    <div style={{width: i18n.language === 'en-US' ? '170px' : '200px', paddingTop: '10px', alignSelf:'center'}}>
                    <SelectSearch
                      options={centerOptions}
                      centers={centers}
                      handleOnChange={handleSelection}
                      className='CenterDropdown'
                      placeholder={t('changeCenter')}
                      alwaysShowPlaceholder = {true}
                      centerOpsSearch={true}
                      allowZipcodeSearch={true}
                    />
                    </div>
                  }
                  
                </div>
              </div>
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={16}
              computer={6}
              className='DonationSearchImageContainer'
            >
              <div
                className='DonationSearchImage'
                style={{
                  backgroundImage: `url(${center.mediumImageUrl ||
                    bioLifeCenterImg})`
                }}
              />
            </Grid.Column>
          </Grid>

          {isOpen && (
            <div>
              <Grid columns={3} className='DonationHourTableHeader' padded>
                <Grid.Column />
                <Grid.Column>{t('officeHours')}</Grid.Column>
                <Grid.Column>{t('playroomHours')}</Grid.Column>
              </Grid>

              {center?.hoursOfOperation.map((info, index) => (
                <Grid
                  key={index}
                  columns={3}
                  className='DonationHourTableRow'
                  padded
                >
                  <Grid.Column className='DayText'>
                    <b>{info.weekDayName}</b>
                  </Grid.Column>
                  <Grid.Column>{getOperationHours(info)}</Grid.Column>
                  <Grid.Column>{getPlaroomHours(info)}</Grid.Column>
                </Grid>
              ))}
            </div>
          )}
        </Container>
      )}
      <AlertModal
        open={showLogoutModal}
        dontClose = {true}
        message={
          <div>
            {t('youWouldbeLoggedOutupon')}
          </div>
        }
        actions={[
          {
            text: t('cancel'),
            primary: false,
            callback: () => {
              setShowLogoutModal(false)
            }
          },
          {
            text: t('confirm'),
            primary: true,
            callback: () => {
              changeDefaultCenter(user.sfid, newCenter )
            }
          }
        ]}
      />
    </div>
  )
}

const mapStateToProps = state => ({
  user: state.users?.user,
  changeDefaultCenterLoading: state.users?.changeDefaultCenterLoading,
  loggedInUser: state.users?.user
})

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(UserActions.logout()),
  changeDefaultCenter: (userSfid, center) => dispatch(UserActions.changeDefaultCenter(userSfid, center))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardCenterInformation)
