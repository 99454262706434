import React, { useEffect, useState } from 'react'
import {connect} from 'react-redux'
import { Grid, Image } from 'semantic-ui-react'
import { useTranslation } from "react-i18next";
import './styles.scss'

const RewardsHistory = ({ loyaltyActivity }) => {
    const { t, i18n } = useTranslation("RewardsHistory");
    const [rewardsHistoryData, setRewardsHistoryData] = useState()

    useEffect(() => {
        const transformedRewardsHistoryData = new Map()
        loyaltyActivity?.transactions?.map((item) => {
            const date = String(new Date(item.activityDate).toLocaleString('en-us',{month:'long', year:'numeric'}))
            if(transformedRewardsHistoryData.has(date)){
                transformedRewardsHistoryData.get(date).push({...item, activityType: i18n.language === 'es-MX' ? item.activityTypeSpanish : item.activityType, expiryText: i18n.language === 'es-MX' ? item.expiryTextSpanish : item.expiryText, transType: i18n.language === 'es-MX' ? item.transTypeSpanish : item.transType, transDesc: i18n.language === 'es-MX' ? item.transDescSpanish : item.transDesc})
            }
            else{
                transformedRewardsHistoryData.set(date, [{...item, expiryText: i18n.language === 'es-MX' ? item.expiryTextSpanish : item.expiryText, transType: i18n.language === 'es-MX' ? item.transTypeSpanish : item.transType, transDesc: i18n.language === 'es-MX' ? item.transDescSpanish : item.transDesc}])
            }
        })
        setRewardsHistoryData(transformedRewardsHistoryData)
    }, [loyaltyActivity, i18n.language])

    return (
        <div className='RewardsHistoryContainer'>
        <p className='description'>{t('description')}</p>
            {rewardsHistoryData && rewardsHistoryData.size > 0 ? (
          <><div className='MonthlyDataContainer'>
                { Array.from(rewardsHistoryData)?.map(item => (
                    <>
                        <Grid columns={2} style={{padding: '0 10px'}}>
                            <Grid.Column computer={8} mobile={16}><h3  className='GridContainer'>{t(item[0].split(' ')[0]).toUpperCase()} {item[0].split(' ')[1]}</h3></Grid.Column>
                            <Grid.Column textAlign='right' only='computer' computer={8}><h3  className='GridContainer'>{t('points')}</h3></Grid.Column>
                        </Grid>
                        {item[1].map((reward) => (
                            <Grid columns={2} className='MonthTransationContainer'>
                                <Grid.Column computer={8} mobile={10}>
                                    <Grid columns={2} stackable>
                                        <Grid.Column width={4} style={{margin: 'auto'}}>
                                            <div className='RewardIconStyles'>
                                                <Image src={reward.iconUrl} className="rewardIconImage" />
                                            </div>
                                        </Grid.Column>
                                        <Grid.Column width={12} className='RewardTypeDetails'>
                                            <p><b>{reward.transType.toUpperCase()}</b></p>
                                            <h2 className='RedemptionType'>{reward.transDesc}</h2>
                                            <Grid stackable className='DateContainer'>
                                                <Grid.Column width={8}>
                                                    <p style={{fontSize: 11}} className='RewardActivityDate'>{i18n.language === 'es-MX' ? reward.activityTypeSpanish : reward.activityType} {reward.activityDate}</p>
                                                </Grid.Column>
                                                {reward.expiryDate && 
                                                    <Grid.Column width={8}>
                                                        <p style={{fontSize: 11}} className='RewardActivityDate'>{reward.expiryText} {reward.expiryDate}</p>
                                                    </Grid.Column>
                                                }
                                            </Grid>
                                        </Grid.Column>
                                    </Grid>
                                </Grid.Column>
                                <Grid.Column computer={8} mobile={6} tablet={6} textAlign='right' className='RewardPoints'>
                                    <h2>{reward.transOperation === 'Debit' ? '-' : '+'} {reward.points}</h2>
                                    <p>{t('points')}</p>
                                </Grid.Column>
                            </Grid>
                        ))}
                    </>
                ))}
            </div>
            <p style={{padding: "20px 0"}}>{t('bottomClause')} <a href={i18n.language === 'es-MX' ? "https://www.biolifeplasma.com/about-biolife/contact-us?lng=es-MX" : "https://www.biolifeplasma.com/about-biolife/contact-us?lng=en-US"} target="_blank" style={{color: "#ac0079", textDecoration: "underline"}}>www.biolifeplasma.com/about-biolife/contact-us</a></p>
        </>
      ) : (
        <div>
            <div className="norewardContainer">
           <Grid column>
                    <Grid.Column>
                    <Grid.Column >
                      <p className="noRewardTitle">
                      {t('yourFutureStartsHere')}
                      </p>
                    </Grid.Column>
                    <Grid.Column >
                      <p className="noRewardDesc">
                      {t('noRewardDesc')}
                      </p>
                    </Grid.Column>
                    </Grid.Column>
                  </Grid>
                  </div>
        </div>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
    loyaltyActivity: state.loyalty.getLoyaltyActivity,
});
  
const mapDispatchToProps = (dispatch) => ({});
  
export default connect(mapStateToProps, mapDispatchToProps)(RewardsHistory);