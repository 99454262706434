import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { isEmpty } from 'lodash'
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment'
import 'moment/locale/es-mx'

import enTranslations from "./en.json";
import esTranslations from "./es.json";
import i18next from 'i18next';

const languages = ["en-US", "es-MX"];

  const options = {
    // order and from where user language should be detected
    order: ['querystring','localStorage'],
    lookupQuerystring: 'lng',
    lookupLocalStorage: 'i18nextLng',
  }
  i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    react: { 
        useSuspense: false //   <---- this will do the magic
      },
    fallbackLng: 'en-US',
    debug: false,
    resources: { "en-US":enTranslations, "es-MX":esTranslations },
    whitelist: languages,
    supportedLngs: languages,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: options,
    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',
    load: 'currentOnly'
  }).then(
    ()=>{
      let langStoredOnLocalStorage=localStorage.getItem('i18nextLng')
      if(!isEmpty(langStoredOnLocalStorage)){
        i18next.changeLanguage(langStoredOnLocalStorage)
      } else{
        i18next.changeLanguage('en-US')
      }
    }
  )

  i18next.on('languageChanged', 
  function(lng) {
    const languageChosenMoment = lng=== "en-US" ? 'en' : 'es-MX' ? 'es-mx' : 'en';
    moment.locale(languageChosenMoment)
  })



export default i18n;