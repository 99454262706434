import React from 'react'

import {
  Container,
  Header
} from 'semantic-ui-react'

const Heading = ({ mobile = false, compact = false, title = 'Biolife Plasma' }) => (
  <Container text>
    <Header
      as='h1'
      content={title}
      inverted
      style={{
        fontSize: mobile ? '3em' : '4em',
        fontWeight: 'normal',
        marginTop: compact ? '0.5em' : 0
      }}
    />
  </Container>
)

export default Heading
