import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import * as moment from 'moment'
import { Container, Message, Modal, Header, Image, Grid } from 'semantic-ui-react'
import importer from '@takedapdt/biolife-core/src/importer'
import CloseIcon from '../../assets/media/icons/icon-close-web.png'
import { useTranslation } from 'react-i18next';
const AppContainer = importer('Containers/App')
const SetupTitleHeader = importer('Components/CenterOps/SetupTitleHeader')
const OpsCalendar = importer('Components/CenterOps/OpsCalendar')
const AppointmentTabs = importer('Components/CenterOps/AppointmentTabs')
const AppointmentSetupTable = importer('Components/CenterOps/AppointmentSetupTable')
const EffectiveSlotsForm = importer('Components/Forms/EffectiveSlotsForm')
const AppointmentSlotMassUpdateModal = importer('Components/CenterOps/AppointmentSlotMassUpdateModal')

const Footer = importer('Components/Footer')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const FailureState = importer('Components/Dashboard/StateErrorContainer')

const centerActions = importer('Stores/Centers/Actions')

const CenterOpsSetup = ({
  loggedInUser,
  slotConfigData,
  getCenterSlotConfig,
  saveCenterSlotConfig,
  getSlotConfigLoading,
  getSlotConfigError,
  saveSlotConfigLoading,
  saveSlotConfigError,
  saveSlotConfigSuccess,
  massUpdateSlotConfig
}) => {
  const { t } = useTranslation('CenterOpsSetup');
  const [selectedCenter, setSelectedCenter] = useState(null)
  const [slotType, setSlotType] = useState(window.sessionStorage.getItem("slotType") || 'DONATION')
  const [selectedDate, setSelectedDate] = useState(moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'))
  const [saveClicked, setSaveClicked] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [openMassUpdateModal, setOpenMassUpdateModal] = useState(false)
  const [isParentChecked, setIsParentChecked] = useState(false)
  const [tab, setTab] = useState(window.sessionStorage.getItem("slotType") || 'DONATION')
  const [isSelectClicked, setIsSelectClicked] = useState(false)
  const [isCheckboxSelected, setIsCheckboxSelected] = useState(false)
  const [isInputBlank, setIsInputBlank] = useState(false)

  useEffect(() => {
    return () => {
      window.sessionStorage.removeItem("slotType")
      window.sessionStorage.removeItem("lastChoosenDate")
    }
  },[])

  const initialState = {
    checkedFromDate: null,
    checkedUntilDate: null,
    isParentChecked: false,
    slotsList: []
  }
  const handleDateChange = (date) => {
    setDonationConfig({ ...initialState })
    setPhysicalConfig({ ...initialState })
    setSelectedDate(date)
  }
  const [physicalConfig, setPhysicalConfig] = useState({ ...initialState })
  const [donationConfig, setDonationConfig] = useState({ ...initialState })

  const handleTabChange = (tab) => {
    setSlotType(tab)
  }

  const initializeDonationConfig = (data) => {
    const config = data.filter(item => item.donationBaseAppointments > 0).map(item => ({
      id: item.id,
      appointmentSlotDateTime: item.appointmentSlotDateTime,
      appointmentSlotTime: item.appointmentSlotTime,
      allowedCount: item.donationAdjustmentAppointments,
      scheduledCount: item.donationScheduledAppointments,
      donationBaseAppointments: item.donationBaseAppointments,
      isChecked: false,
      blankError: false
    }))
    setDonationConfig({
      ...donationConfig,
      slotsList: config
    })
  }
  const initializePhysicalConfig = (data) => {
    const config = data.filter(item => item.physicalBaseAppointments > 0).map(item => ({
      id: item.id,
      appointmentSlotDateTime: item.appointmentSlotDateTime,
      appointmentSlotTime: item.appointmentSlotTime,
      allowedCount: item.physicalAdjustmentAppointments,
      scheduledCount: item.physicalScheduledAppointments,
      physicalBaseAppointments: item.physicalBaseAppointments,
      isChecked: false,
      blankError: false
    }))
    setPhysicalConfig({
      ...physicalConfig,
      slotsList: config
    })
  }

  const checkAnyCheckboxSelected = (slotType) => {
    if(slotType === 'DONATION'){
      const value = donationConfig.slotsList.find(item => item.isChecked)
      setIsCheckboxSelected(value?.isChecked)
    }
    else if(slotType === 'PHYSICAL'){
      const value = physicalConfig.slotsList.find(item => item.isChecked)
      setIsCheckboxSelected(value?.isChecked)
    }
  }
  
  const checkIfBlankSlotIsSelected = (slotType) => {
    if(slotType === 'DONATION'){
      const value = donationConfig.slotsList.find(item => item.blankError)
      value?.isChecked && value?.blankError ? setIsInputBlank(true) : setIsInputBlank(false)
    }
    else if(slotType === 'PHYSICAL'){
      const value = physicalConfig.slotsList.find(item => item.blankError)
      value?.isChecked && value?.blankError ? setIsInputBlank(true) : setIsInputBlank(false)
    }
  }
  // Step 0 - Set selected center based on the logged in staff ...
  useEffect(() => {
    if (loggedInUser && loggedInUser.defaultCenter) {
      setSelectedCenter(loggedInUser.defaultCenter.sfid)
    }
  }, [loggedInUser])

  // Step 1 - Get calendar for selected Date ...
  useEffect(() => {
    if (selectedDate && selectedCenter) {
      getCenterSlotConfig(selectedDate, selectedCenter, slotType)
    }
  }, [selectedDate, selectedCenter])

  // Step 2 - Initialize individual tab data when calendar is loaded ...
  useEffect(() => {
    if (slotConfigData && slotConfigData.data && slotConfigData.data.length > 0) {
      initializeDonationConfig(slotConfigData.data)
      initializePhysicalConfig(slotConfigData.data)
    }
  }, [slotConfigData])

  // Step 3 - Select start, end and apply filter to select slots that are to be modified
  const checkSlotsOnCalendar = (slotType, fromDate, toDate) => {
    if (slotType === 'DONATION') {
      const config = donationConfig.slotsList.map(item => ({
        ...item,
        isChecked: moment(item.appointmentSlotDateTime).isBetween(moment(fromDate), moment(toDate), 'minute', '[]')
      }))
      setIsCheckboxSelected(true)
      setDonationConfig({
        checkedFromDate: fromDate,
        checkedUntilDate: toDate,
        slotsList: config
      })
    } else if (slotType === 'PHYSICAL') {
      const config = physicalConfig.slotsList.map(item => ({
        ...item,
        isChecked: moment(item.appointmentSlotDateTime).isBetween(moment(fromDate), moment(toDate), 'minute', '[]')
      }))
      setIsCheckboxSelected(true)
      setPhysicalConfig({
        checkedFromDate: fromDate,
        checkedUntilDate: toDate,
        slotsList: config
      })
    }
  }

  // Step 4. When user changes any specific value in the input box ..
  const handleInputChange = (value, index, slotType) => {
    if (slotType === 'DONATION') {
      const config = [...donationConfig.slotsList]
      config[index].allowedCount = value
      config[index].isTouched = true
      if(config[index].allowedCount === ''){
        config[index].blankError = true
        config[index].isChecked = false
        checkAnyCheckboxSelected(slotType)
      }
      else{
        config[index].blankError = false
        config[index].isChecked = true
        setIsCheckboxSelected(true)
        checkIfBlankSlotIsSelected(slotType)
      }
      setDonationConfig({
        ...donationConfig,
        slotsList: config
      })
    } else if (slotType === 'PHYSICAL') {
      const config = [...physicalConfig.slotsList]
      config[index].allowedCount = value
      config[index].isTouched = true
      if(config[index].allowedCount === ''){
        config[index].blankError = true
        config[index].isChecked = false
        checkAnyCheckboxSelected(slotType)
      }
      else{
        config[index].blankError = false
        config[index].isChecked = true
        setIsCheckboxSelected(true)
        checkIfBlankSlotIsSelected(slotType)
      }
      setPhysicalConfig({
        ...physicalConfig,
        slotsList: config
      })
    }
  }

  // Step 5. When user selects a specific checkbox to apply override a selected slot
  const handleCheckboxChange = (isChecked, index, slotType) => {
    if (slotType === 'DONATION') {
      const config = [...donationConfig.slotsList]
      config[index].isChecked = isChecked
      checkAnyCheckboxSelected(slotType)   //requirement is to enable save button if any checkbox is checked
      if(config[index].blankError){        //to check if the selected slot has blank value
        checkIfBlankSlotIsSelected(slotType)
      }  
      setDonationConfig({
        ...donationConfig,
        slotsList: config
      })
    } else if (slotType === 'PHYSICAL') {
      const config = [...physicalConfig.slotsList]
      config[index].isChecked = isChecked
      checkAnyCheckboxSelected(slotType)
      if(config[index].blankError){        //to check if the selected slot has blank value
        checkIfBlankSlotIsSelected(slotType)
      } 
      setPhysicalConfig({
        ...physicalConfig,
        slotsList: config
      })
    }
  }

  // Step 6. Toggle parent checkbox to enable, disable all slot configurations
  const handleParentCheckboxChange = (isChecked, slotType) => {
    setIsCheckboxSelected(isChecked)
    if (slotType === 'DONATION') {
      const config = { ...donationConfig, isParentChecked: isChecked }
      config.slotsList = config.slotsList.map(item => ({ ...item, isChecked: isChecked }))
      const value = config.slotsList.find(item => item.blankError)
      if(value?.blankError && isChecked){
        setIsInputBlank(true)
      }
      else if (!isChecked){
        setIsInputBlank(false)
      }
      setDonationConfig(config)
    } else if (slotType === 'PHYSICAL') {
      const config = { ...physicalConfig, isParentChecked: isChecked }
      config.slotsList = config.slotsList.map(item => ({ ...item, isChecked: isChecked }))
      const value = config.slotsList.find(item => item.blankError)
      if(value?.blankError && isChecked){
        setIsInputBlank(true)
      }
      else if (!isChecked){
        setIsInputBlank(false)
      }
      setPhysicalConfig(config)
    }
  }

  // Save 7. Apply the revised effective slots value to all the checked items
  const handleApplyToChecked = (slotType, val) => {
    if (slotType === 'DONATION') {
      const config = {
        ...donationConfig,
        slotsList: donationConfig.slotsList.map(item => ({
          ...item,
          allowedCount: item.isChecked ? val : item.allowedCount,
          isTouched: true,
          blankError: item.isChecked && false 
        }))
      }
      checkIfBlankSlotIsSelected(slotType)
      setDonationConfig(config)
    } else if (slotType === 'PHYSICAL') {
      const config = {
        ...physicalConfig,
        slotsList: physicalConfig.slotsList.map(item => ({
          ...item,
          allowedCount: item.isChecked ? val : item.allowedCount,
          isTouched: true,
          blankError: item.isChecked && false 
        }))
      }
      checkIfBlankSlotIsSelected(slotType)
      setPhysicalConfig(config)
    }
  }

  const prepareSlotsArrayToSave = () => {
    const saveArray = []
    donationConfig.slotsList.forEach(item => {
      if (item.isChecked) {
        const i = saveArray.findIndex(inner => inner.id === item.id)
        if (i > -1) {
          saveArray[i] = { ...saveArray[i], donationAdjustmentAppointments: parseInt(item.allowedCount) }
        } else {
          saveArray.push({
            id: item.id,
            donationAdjustmentAppointments: parseInt(item.allowedCount)
          })
        }
      }
    })
    physicalConfig.slotsList.forEach(item => {
      if (item.isChecked) {
        const i = saveArray.findIndex(inner => inner.id === item.id)
        if (i > -1) {
          saveArray[i] = { ...saveArray[i], physicalAdjustmentAppointments: parseInt(item.allowedCount) }
        } else {
          saveArray.push({
            id: item.id,
            physicalAdjustmentAppointments: parseInt(item.allowedCount)
          })
        }
      }
    })
    return saveArray;
  }
  // Step 8: Save the selected records with values ..
  const handleSaveSlotConfig = () => {
    const saveArray = prepareSlotsArrayToSave()
    if(isInputBlank){
      setOpenModal(true)
    }
    else{
      setSaveClicked(true)
      saveCenterSlotConfig(selectedDate, selectedCenter, saveArray)
    }
  }

  // Step 9 - Show Success Error on save successful .. 
  useEffect(() => {
    if ((saveSlotConfigSuccess || saveSlotConfigError) && saveClicked) {
      setOpenModal(true)
    }
  }, [saveSlotConfigSuccess, saveSlotConfigError])

  // Step 10 - Closes the save modal 
  const handleSaveModalClose = () => {
    window.location.reload()
  }

  // Mass Update Modal 
  const toggleMassUpdateModal = () => setOpenMassUpdateModal(!openMassUpdateModal)

  // Step 8: Save the selected records with values ..
  const handleMassUpdateSlotConfig = values => {
    const saveArray = prepareSlotsArrayToSave()
    massUpdateSlotConfig(
      selectedDate,
      selectedCenter,
      saveArray,
      values.startDate,
      values.endDate,
      values.weekDays,
      tab
    )
  }

  return (
    <AppContainer compact >
      <div
        className='MainContainer'
        style={{
          paddingBottom: '400px'
        }}
      >
        <Container fluid>
          <OpsCalendar
            onDateChange={handleDateChange}
            setSlotType={setSlotType}
            onTabChange={handleTabChange}
            setTab={setTab}
          />
          <div style={{ paddingLeft: '6%', paddingRight: '6%' }}>
            <AppointmentTabs
              onSaveSlotConfig={handleSaveSlotConfig}
              onTabChange={handleTabChange}
              tab={tab}
              setTab={setTab}
              toggleMassUpdateModal={toggleMassUpdateModal}
              isCheckboxSelected={isCheckboxSelected}
              isInputBlank={isInputBlank}
            />
            {(getSlotConfigLoading || saveSlotConfigLoading) && (
              <LoadingState/>
            )}
            {!getSlotConfigLoading && getSlotConfigError &&
              getSlotConfigError.response && getSlotConfigError.response.data && (
              <FailureState
                message = {getSlotConfigError.response.data.message}
              />
            )}
            {slotType === 'DONATION' &&
              donationConfig.slotsList.length > 0 && (
              <div>
                <EffectiveSlotsForm
                  slotType='DONATION'
                  slotConfigData={donationConfig.slotsList}
                  defaultFromDate={donationConfig.checkedFromDate}
                  defaultToDate={donationConfig.checkedUntilDate}
                  onApplyDateFilter={checkSlotsOnCalendar}
                  onApplyToSelected={handleApplyToChecked}
                  isParentChecked={donationConfig.isParentChecked}
                  setIsSelectClicked={setIsSelectClicked}
                />
                <AppointmentSetupTable
                  slotType='DONATION'
                  slotConfigData={donationConfig.slotsList}
                  checkedFromDate={donationConfig.checkedFromDate}
                  checkedUntilDate={donationConfig.checkedUntilDate}
                  isParentChecked={donationConfig.isParentChecked}
                  handleInputChange={handleInputChange}
                  handleCheckboxChange={handleCheckboxChange}
                  handleParentCheckboxChange={handleParentCheckboxChange}
                />
              </div>
            )}
            {slotType === 'PHYSICAL' &&
              physicalConfig.slotsList.length > 0 && (
              <div>
                <EffectiveSlotsForm
                  slotType='PHYSICAL'
                  slotConfigData={physicalConfig.slotsList}
                  defaultFromDate={physicalConfig.checkedFromDate}
                  defaultToDate={physicalConfig.checkedUntilDate}
                  onApplyDateFilter={checkSlotsOnCalendar}
                  onApplyToSelected={handleApplyToChecked}
                  isParentChecked={physicalConfig.isParentChecked}
                  setIsSelectClicked={setIsSelectClicked}
                />
                <AppointmentSetupTable
                  slotType='PHYSICAL'
                  slotConfigData={physicalConfig.slotsList}
                  checkedFromDate={physicalConfig.checkedFromDate}
                  checkedUntilDate={physicalConfig.checkedUntilDate}
                  isParentChecked={physicalConfig.isParentChecked}
                  handleInputChange={handleInputChange}
                  handleCheckboxChange={handleCheckboxChange}
                  handleParentCheckboxChange={handleParentCheckboxChange}
                />
              </div>
            )}
            {donationConfig.slotsList.length == 0 &&
              !getSlotConfigError && !getSlotConfigLoading && (
              <Message warning visible>
                <p>{t('configSlot')}</p>
              </Message>
            )}
          </div>
        </Container>

        <AppointmentSlotMassUpdateModal
          open={openMassUpdateModal}
          toggleModal={toggleMassUpdateModal}
          onApplyMassUpdate={handleMassUpdateSlotConfig}
          loading={saveSlotConfigLoading}
          failure={saveSlotConfigError}
          success={saveSlotConfigSuccess}
          handleSaveModalClose={handleSaveModalClose}
          tab={tab}
        />
        
        <Modal open={openModal} size='small'>
          <Modal.Header className='HeaderContainer'>
            <Grid columns={2}>
              <Grid.Column width={14}>
                <Header as='h1' className='ModalHeader'>
                  {isInputBlank ? t('errorSavingChanges') : saveSlotConfigSuccess ? t('savedSuccessfully') : t('errorSavingChanges')}
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign='right'>
                <Image
                  src={CloseIcon}
                  alt='Close'
                  onClick={isInputBlank? () => setOpenModal(false) : 
                    () => {
                    setOpenModal(false)
                    handleSaveModalClose()
                  }}
                />
              </Grid.Column>
            </Grid>
          </Modal.Header>
          <Modal.Content>
            <p>
              {isInputBlank ? t('effectiveFieldMustHaveaValidNum') : saveSlotConfigSuccess ?
                t('appointmentSaved') :
                t('slotCanNotChange')
              }
            </p>
          </Modal.Content>
        </Modal>
      </div>
      <Footer />
    </AppContainer>
  )
}

const mapStateToProps = state => {
  return {
    loggedInUser: state.users.user,
    slotConfigData: state.centers.getCenterSlotConfigSuccess,
    getSlotConfigError: state.centers.getCenterSlotConfigFailure,
    getSlotConfigLoading: state.centers.getCenterSlotConfigLoading,
    saveSlotConfigLoading: state.centers.saveCenterSlotConfigLoading,
    saveSlotConfigSuccess: state.centers.saveCenterSlotConfigSuccess,
    saveSlotConfigError: state.centers.saveCenterSlotConfigFailure
  }
}

const mapDispatchToProps = dispatch => ({
  getCenterSlotConfig: (date, centerSfid, slotType) => dispatch(centerActions.getCenterSlotConfig(date, centerSfid, slotType)),
  saveCenterSlotConfig: (date, centerSfid, slotsArray) => dispatch(centerActions.saveCenterSlotConfig(false, date, centerSfid, slotsArray)),
  massUpdateSlotConfig: (date, centerSfid, slotsArray, start, end, weekdays, appointmentType) => dispatch(centerActions.saveCenterSlotConfig(true, date, centerSfid, slotsArray, start, end, weekdays, appointmentType))
})

export default connect(mapStateToProps, mapDispatchToProps)(CenterOpsSetup)
