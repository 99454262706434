import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import { Grid, Header ,Modal, Image } from 'semantic-ui-react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import importer from '@takedapdt/biolife-core/src/importer'

import './styles.scss'
const DonorAccountDetail = importer('Components/DonorSearch/DonorAccountDetail')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const DonorSearchCenterInformation = importer('Components/DonorSearch/DonorSearchCenterInformation')
const DashboardAppointmentsSection = importer('Components/Dashboard/DashboardAppointmentsSection')
const DashboardDonationsSection = importer('Components/Dashboard/DashboardDonationsSection')
const appointmentsActions = importer('Stores/Appointments/Actions')
const donorsActions = importer('Stores/Donors/Actions')
const centersActions = importer('Stores/Centers/Actions')
const DonorDetailTabs = importer('Components/DonorSearch/DonorDetailTabs')

const DonorsDetails = ({
  center, selectedDonor,
  handleBackToSearch,
  getUpcomingAppointments,
  getDonorInquiry,
  appointments,
  inquiry,
  appointmentsLoading,
  inquiryLoading,
  appointmentsError,
  inquiryError,
  getAllCenters,
  loggedInUser,
  updateDonorSuccess,
  donor,
  donorLoading
}) => {
  const { t,i18n } = useTranslation('DonorDetails');
  const [currentDonor, setCurrentDonor] = useState(selectedDonor)
  const [tab, setTab] = useState('PROFILE')
  const [openModal, setOpenModal] = useState(false)
  useEffect(() => {
    if(donor?.isAddressChanged && selectedDonor?.donationCenter === donor.defaultCenter?.sfid){
      setOpenModal(true)
    }
    if (selectedDonor?.sfid) {
      getUpcomingAppointments(selectedDonor.sfid)
    }
    if (selectedDonor?.pdn) {
      getDonorInquiry(selectedDonor.pdn)
    }
  }, [selectedDonor,donor])


  useEffect(() => {
    setCurrentDonor(donor)
  }, [donor, updateDonorSuccess])

  useEffect(() => {
    if (donor?.donationCenter) {
      getAllCenters(donor.donationCenter)
    }
  }, [i18n.language])     // this runs on mount as well

  selectedDonor.birthDate = moment.utc(selectedDonor.birthDate).format('YYYY-MM-DD')
  if (center) center.webImageUrl = null
  const profileStateMappingObject = {
    ProspectiveDonor: 'prospectiveDonor',
    NotVerified: 'pendingEmailverification',
    EmailSent: 'pendingEmailverification',
    AccountVerified: 'registrationComplete',
    AccountWiped: 'accountWiped'
  }

  const getAddress = () => {
    let address = ''
    if (donor?.addressLine1) {
      address = address + donor.addressLine1 + ' '
    }
    if (donor?.city) {
      address = address + donor.city + ',' + ' '
    }
    if(donor?.state) {
      address = address + donor.state + ' '
    }
    if(donor?.zipCode) {
      address = address + donor.zipCode + ' '
    }
    if(donor?.country) {
      address = address + donor.country
    }
    return address
  }
  const getDOB = () => {
    return selectedDonor.birthDate ? moment.utc(selectedDonor.birthDate).format('YYYY-MM-DD') : '';
  }
  const getStatus = () => {
    return selectedDonor.status ? 'migrated' : 'legacy'
  }
  const getLanguage = () => {
    if(isEmpty(selectedDonor?.language)){
      return  t('notAvailable')
    }
    return selectedDonor?.language === 'en-US' ? 'English' : 'es-MX' ? 'Español' : selectedDonor?.language
  }
  
  return (
    <div>
    <Grid className='DonorsDetails'>
      <Grid.Row className='HeaderBar'>
        <Header className='HeaderText' as='h4'>{t('searchResults')}</Header>
        <div className='BackToSearchButton' onClick={handleBackToSearch}>{t('backToSearchResults')}</div>
      </Grid.Row>
      <Grid.Row className='DonorsDetailsContent'>
        <div className='DonorDetailsColumn'>
          <DonorDetailTabs
            tab={tab}
            setTab={setTab}
           />
          {(tab === 'PROFILE') && (
            <div>
              {donorLoading && <LoadingState />}
              {!donorLoading &&
                <div className='DonorsDetailsPersonal DonorsDetailsSection'>
                <p className='DonationCenterTitle'>{t('donor')}</p>
                <p className='DonationCenterBigTitle'>{selectedDonor.firstName} {selectedDonor.lastName}</p>
                <table>
                  <tr>
                    <td>{t('pdn')}</td>
                    <td>{selectedDonor.pdn}</td>
                  </tr>
                  <tr>
                    <td>{t('dateofBirth')}</td>
                    <td>{getDOB()}</td>
                  </tr>
                  <tr>
                    <td>{t('preferredLanguage')}:</td>
                    <td>{getLanguage()}</td>
                  </tr>
                  <tr>
                    <td>{t('gender')}</td>
                    <td>{selectedDonor.gender}</td>
                  </tr>
                  <tr>
                    <td>{t('email')}</td>
                    <td>{donor.email}</td>
                  </tr>
                  <tr>
                    <td>{t('phone')}</td>
                    <td>{selectedDonor.phoneNumber}</td>
                  </tr>
                  <tr>
                    <td>{t('phoneType')}</td>
                    <td>{selectedDonor.phoneType}</td>
                  </tr>
                  <tr>
                    <td>{t('address')}:</td>
                    <td>{getAddress()}</td>
                  </tr>
                  <tr>
                    <td>{t('status')}</td>
                    <td>{t(getStatus())}</td>
                  </tr>
                  <tr>
                    <td>{t('ProfileState')}</td>
                    <td>{t(profileStateMappingObject[selectedDonor.profileState])}</td>
                  </tr>
                  <tr>
                    <td>{t('socialLogin')}</td>
                    <td>{selectedDonor.socailLogin ? t('yes') : t('no')}</td>
                  </tr>
                </table>
              </div>}
              <div className='DonorsDetailsSection'>
                <DashboardAppointmentsSection
                  enableSchedule={currentDonor && loggedInUser}
                  loading={appointmentsLoading}
                  error={appointmentsError}
                  appointments={appointments || []}
                  inquiry={selectedDonor.pdn ? inquiry : null}
                  hideHeader={true}
                  donorLoading={donorLoading}
                />
                <div className="last-two-donations">
                  <DashboardDonationsSection
                  loading={appointmentsLoading}
                  error={appointmentsError}
                  inquiry={selectedDonor.pdn ? inquiry : null}
                  center={center}
                  loggedInUser={loggedInUser}
                />
                </div>
              </div>
            </div>)}
          {(tab === 'ACCOUNT') && (
            <div className='DonorsDetailsPersonal DonorsDetailsSection'>
              <DonorAccountDetail donor={donor} />
            </div>
          )}
        </div>
        <div className='DonorCenterColumn'>
          <DonorSearchCenterInformation
            selectedDonor={selectedDonor}
            shouldCenterPopulate={selectedDonor?.donationCenter ? true : false}/>
        </div>
      </Grid.Row>
    </Grid>
    <Modal open={openModal} size='small'>
          <Modal.Header className='HeaderContainer'>
            <Grid columns={2}>
              <Grid.Column width={14}>
                <Header as='h2' className='ModalHeader'>
                {t('pleaseUpdateDonorsCenter')}
                </Header>
              </Grid.Column>
              <Grid.Column width={2} textAlign='right'>
                <Image
                  src={CloseIcon}
                  alt='Close'
                  onClick={() => setOpenModal(false) 
                  }
                />
              </Grid.Column>
            </Grid>
          </Modal.Header>
          
        </Modal>
    </div>
  )
}

const mapStateToProps = ({ appointments, donors, centers, users }) => ({
  loggedInUser: users.user,
  center: centers.getAllCentersSuccess?.data,
  centers: centers.donorCenters?.data,
  appointments: appointments.getUpcomingAppointmentsSuccess,
  inquiry: donors.inquiryDonorSuccess,
  appointmentsLoading: appointments.getUpcomingAppointmentsLoading,
  inquiryLoading: donors.inquiryDonorLoading,
  appointmentsError: appointments.getUpcomingAppointmentsFailure,
  inquiryError: donors.inquiryDonorFailure,
  updateDonorSuccess: donors.updateDonorSuccess,
  donor: donors.donors?.data
})

const mapDispatchToProps = dispatch => ({
  getUpcomingAppointments: (donorSfid) => dispatch(appointmentsActions.getUpcomingAppointments(donorSfid)),
  getDonorInquiry: (pdn) => dispatch(donorsActions.inquiryDonor(pdn)),
  getAllCenters: searchStr => dispatch(centersActions.getAllCenters(searchStr)),

})

export default connect(mapStateToProps, mapDispatchToProps)(DonorsDetails)
