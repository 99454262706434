import React from 'react'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'

import './styles.scss'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const ScheduleDayActions = ({
  scheduled,
  maxAllowed,
  toggleScheduleAppointment,
  setAppointment,
  appointment,
  time,
  isAddButtonDisabled,
  appointmentDateTime,
  setIsNewDonorSearch
}) => {
  const { t } = useTranslation('ScheduleDayActions');
  const buttonText = (scheduled != null) && (scheduled >= maxAllowed) ? t('overbook') : t('add')
  const getButtonClass = () => {
    if (scheduled != null && !isAddButtonDisabled) {
      if (scheduled > 0 && scheduled < maxAllowed) {
        return 'AddButtonYellow'
      }
      if (scheduled >= 0 && scheduled >= maxAllowed) {
        return 'AddButtonOverbook'
      }
    }
    else if(isAddButtonDisabled){
      return 'HideAddButton'
    }

    return 'AddButton'
  }
  const getAllowAppointmentsTextClass = () =>{
    if(isAddButtonDisabled){
      return 'hideAppointment'
    }
    return 'AllowedAppointments'
  }

  const handleOnClick = () => {
    setIsNewDonorSearch(true)
    setAppointment({ ...appointment, time, startDateTime: appointmentDateTime, scheduled, maxAllowed })
    toggleScheduleAppointment()
  }

  return (
    <div className='ScheduleDayActions'>
      <p className={getAllowAppointmentsTextClass()}>
        ({scheduled ? scheduled : 0} {t('scheduled')} {maxAllowed} {t('allowed')})
      </p>
      <PrimaryButton
        // style={{backgroundColor: isAddButtonDisabled? '#F4F4F4': ''}}
        text={buttonText}
        className={getButtonClass() }
        height={30}
        width={297}
        fontSize={14}
        onClick={handleOnClick}
      />
    </div>
  )
}

export default ScheduleDayActions
