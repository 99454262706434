import React from 'react'
import { Header } from 'semantic-ui-react'
import { NavLink} from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import './styles.scss'
/* DYNAMIC IMPORTS */
import importer from '@takedapdt/biolife-core/src/importer'
/* COMPONENTS */
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const AccountSetupConfirmation = () => {
  const { t } = useTranslation('AccountSetupConfirmation');
  return (
    <div className="login-form page-container">
      <div className='verify-account-form__content page-container__content'>
        <Header as='h1' className='MainHeader'>
          {t('accountUpdateSuccess')}
        </Header>
        <div className="page-container_pageinfo">
          <p>
            {t('yourAccountHasBeenUpdated')}
          </p>
          <p className="mb-40">
            {t('pleaseSigninToContinue')}
          </p>
          <PrimaryButton
            text={t('logIn')}
            width={340}
            height={50}
            fontSize={16}
            as={NavLink}
            to='/login'
          />
        </div>
      </div>
    </div>
  )
}

export default AccountSetupConfirmation
