import React from 'react'
import importer from '@takedapdt/biolife-core/src/importer'
const AppContainer = importer('Containers/App')
const DonorSearch = importer('Components/DonorSearch')
const Footer = importer('Components/Footer')

const CenterOps = () => {
  return (
    <AppContainer compact>
      <div className='MainContainer CenterOpsContainer'>
        <DonorSearch />
      </div>
      <Footer />
    </AppContainer>
  )
}

export default CenterOps
