import React from "react";

import { Grid, Image } from "semantic-ui-react";
import "./styles.scss";
import { useTranslation } from "react-i18next";
import MobileAppEnglish from "./images/mobile-app-screen-en.png";
import MobileAppSpanish from "./images/mobile-app-screen-es.png";
import googlePlay from "./images/badge-gplay.png";
import appStore from "./images/badge-ios.png";

const AppAdvertisement = () => {
  const { t, i18n } = useTranslation("UserVerification");
  return (
    <Grid className="AppAdvertisementContainer">
      <Grid.Column computer={9} mobile={16}>
        <Grid className="AppAdvertisementLeftSide">
          <Grid.Row className="AppAdvertisementVerbiage">
            <p>
              {t("stayUpdatedWith")} <b>{t("biolifeApp")}</b>
            </p>
          </Grid.Row>
          <Grid.Row className="AppAdvertisementDownloadToday">
            <p>{t("downloadToday")}</p>
          </Grid.Row>
          <Grid.Row className="AppAdvertisementDownloadImages">
            <Grid.Column width={8}>
              <Image
                src={appStore}
                as="a"
                href="https://apps.apple.com/us/app/biolife-plasma-services/id1356589861"
                target="_blank"
              />
            </Grid.Column>
            <Grid.Column width={8}>
              <Image
                src={googlePlay}
                as="a"
                href="https://play.google.com/store/apps/details?id=com.shire.biolife&hl=en_US"
                target="_blank"
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Grid.Column>
      <Grid.Column
        className="AppAdvertisementRightSide"
        computer={7}
        mobile={16}
      >
        <Image
          src={i18n.language === "es-MX" ? MobileAppSpanish : MobileAppEnglish}
          alt="Mobile app dashboard screen"
          height="300"
        />
      </Grid.Column>
    </Grid>
  );
};

export default AppAdvertisement;
 