import React from 'react'
import { Grid, Header } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import './styles.scss'
import { upcomingAppointments } from './HelperFactory'

import importer from '@takedapdt/biolife-core/src/importer'
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const UpcomingAppointments = () => {
  const { t } = useTranslation('UpcomingAppointments');
  return (
    <div className='UpcomingAppointments'>
      <Header as='h4' className='SectionHeader'>
        {t('upcomingAppointments')}
      </Header>
      <Grid columns={3}>
        {upcomingAppointments.map((appointment, index) => (
          <Grid.Row
            key={appointment.id}
            className='RowData'
            style={{
              borderBottom:
                upcomingAppointments.length - 1 !== index
                  ? '1px solid #dddddd'
                  : ''
            }}
          >
            <Grid.Column width={6}>
              {appointment.date}
              <p>{t('cordova')}</p>
            </Grid.Column>

            <Grid.Column width={5}>{appointment.time}</Grid.Column>
            <Grid.Column width={5} verticalAlign='middle'>
              <PrimaryButton
                text={t('modify')}
                width={102}
                classType='Secondary'
                fontSize='16px'
                // onClick={toggleModal}
              />
            </Grid.Column>
          </Grid.Row>
        ))}
      </Grid>
    </div>
  )
}

export default UpcomingAppointments
