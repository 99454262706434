import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Header, Container, Grid, Visibility } from 'semantic-ui-react'
import { useHistory } from 'react-router-dom'
import './styles.scss'

import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const HeroAppointmentScheduling = (props) => {
  const history = useHistory()
  const [width, setWidth] = useState('')

  const buttonWidth = width < 480 ? 130 : 180

  const handleUpdate = (e, { calculations }) => setWidth(calculations.width)
  const { t } = useTranslation('HeroAppointmentScheduling');
  return (
    <div className='HeroAppointmentScheduling'>
      <Visibility onUpdate={handleUpdate}>
        <Container fluid>
          <Grid columns={2}>
            <Grid.Column>
              <Header as='h1' className='MainHeader'>
                {t('scheduleAnAppointment')}
              </Header>
            </Grid.Column>
            <Grid.Column textAlign='right' verticalAlign='middle'>
              <PrimaryButton
                text={t('cancel')}
                width={buttonWidth}
                height={52}
                fontSize={16}
                classType='LightBlueWithPadding'
                type='submit'
                onClick={() => {
                  if(props.loggedInUser && props.loggedInUser.role === 'Admin'){
                    history.push('/center-ops/donor-search')
                  }else {
                    history.push('/dashboard/donor-dashboard')
                  }
                }}
              />
            </Grid.Column>
          </Grid>
        </Container>
      </Visibility>
    </div>
  )
}

export default HeroAppointmentScheduling
