import React from 'react'
import { Container, Header, Image } from 'semantic-ui-react'
import HeroImage from '../../../assets/media/images/hero-image-headquarters.jpg'
import { useTranslation } from 'react-i18next';

import './styles.scss'

const NoCenterSignUpMessage = () => {
  const { t } = useTranslation('NoCenterSignUpMessage');

  return (
    <div className='NoCenterSignUpMessage'>
      <div className='HeroContent'>
        <div className='HeroImageContainer'>
          <Image src={HeroImage} alt='Hero Image' />
        </div>
        <Container>
          <div className='NoCenterSignUpMessageTextContainer'>
            <Header as='h1' className='UserVerificationHeader'>
              {t('noCenterMsg')}
            </Header>
          </div>
        </Container>
      </div>
    </div>
  )
}

export default NoCenterSignUpMessage
