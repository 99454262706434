import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image, Visibility, Dimmer, Message,Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../../assets/media/icons/icon-close-web.png'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer';
const centerActions = importer("Stores/Centers/Actions");
import { connect } from "react-redux";

const PrimaryButton = importer('Components/Buttons/PrimaryButton');

const RemoveUserCenterAccessModal = (
    { 
        selectedCenter,
        toggleSuccessModal,
        open,
        toggleModal,
        removeUserCenterAccessFailure,
        removeUserCenterAccessSuccess,
        removeUserCenterAccessLoading,
        removeUserCenterAccess
    }) => {  
  const { t } = useTranslation('RemoveUserCenterAccessModal'); 
  const [loading, setLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState('');
  const [submited, setSubmited] = useState(false);

    useEffect(() => {
      if (removeUserCenterAccessSuccess !== null && submited) {
        setLoading(false);
        setErrorMessages("");
        toggleSuccessModal(true,"RemoveUserCenterAccessModal");
        toggleModal();
      }
    }, [removeUserCenterAccessSuccess])

    useEffect(() => {
      if (removeUserCenterAccessFailure !== null && submited) {
        setSubmited(false)
        setLoading(false)
        setErrorMessages(removeUserCenterAccessFailure ? removeUserCenterAccessFailure.message : t('somethingWentWrong'))
      }
    }, [removeUserCenterAccessFailure])
    
    useEffect(() => {
      setErrorMessages("");
    },[])

    const onConfirmation = () => {
        setLoading(true);
        setSubmited(true)
        removeUserCenterAccess(selectedCenter);
    };

    return (
    <Visibility> 
      <Modal open={open} onClose={null} className='RemoveUserCenterAccessModal'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
               {t('header')}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image src={CloseIcon} alt='Close' onClick={toggleModal} className='CloseModal' />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
            <Grid.Row>
                <div className='ModalContent'>
                    <p className='RegularText'>{t('content').replace("%c%", selectedCenter.name)}</p>
                </div>
            </Grid.Row>
            <Grid.Row className="removeRow">
                <PrimaryButton
                    text={t('close')}
                    fontSize={16}
                    classType='Inverted'
                    onClick={toggleModal}
                    className='close'
                />
                <PrimaryButton
                    text={t('saveButton')}
                    classType='Primary'
                    className='save'
                    onClick={onConfirmation}
                />
            </Grid.Row>
            {errorMessages && (
          <Message warning className='errorMessage'>
            <p>{Array.isArray(errorMessages) ? errorMessages.join(', ') : errorMessages}</p>
          </Message>
        )}
        </Modal.Content>
      </Modal>
      {(loading && removeUserCenterAccessLoading) && (
          <Dimmer active inverted>
            <Loader>{t('loading')}</Loader>
          </Dimmer>
        )}
    </Visibility>
    )
}

const mapStateToProps = (state) => ({
    removeUserCenterAccessLoading: state.centers.removeUserCenterAccessLoading,
    removeUserCenterAccessSuccess: state.centers.removeUserCenterAccessSuccess,
    removeUserCenterAccessFailure: state.centers.removeUserCenterAccessFailure,
  })
  
  const mapDispatchToProps = (dispatch) => ({
    removeUserCenterAccess: (userCenter) => dispatch(centerActions.removeUserCenterAccess(userCenter)),
  })

export default connect(
    mapStateToProps, mapDispatchToProps
) (RemoveUserCenterAccessModal)
