import React, { useState, useEffect } from 'react'
import { Modal, Grid, Header, Image, Checkbox, Loader } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux'
import GiftCardImage from '../../../../assets/media/images/biolife-gift-card.png'
import AddToCalendar from 'react-add-to-calendar'
import CloseIcon from '../../../../assets/media/icons/icon-close-web.png'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { isEmpty, isNull, isUndefined } from 'lodash'
import { splitCenterName } from "../../../Helpers/Utils"
import DebitCardImage from "../../../../assets/media/images/biolife-debit-card.png"
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')
const appointmentsActions = importer('Stores/Appointments/Actions')
const LoadingState = importer('Components/Dashboard/StateLoadingContainer')
const CheckboxCard = importer('Components/Dashboard/ChangePaymentMethodModal/CheckboxCard')

const AddToCalendarModal = ({ lastPhysicalDate, open, toggleModal, appointment, center, createNewAppointmentSuccess, update, createNewAppointmentFailure, loggedInUser, centers, isNewDonorBonusEligible, newDonorBonusEligibilityLoading, inquiry, donor, checkNewDonorBonusEligibility, searchedCenters, updateGiftCardPreference, updateGiftCardPreferenceSuccess, updateGiftCardPreferenceLoading, updateGiftCardPreferenceFailure }) => {

  const history = useHistory()
  const { t, i18n } = useTranslation('AddToCalendarModal');
  const [isGiftcardOptionSelected, setIsGiftcardOptionSelected] = useState('No');
  const [donorBonusInformation, setDonorBonusInformation] = useState({})

  const handleGiftcardOptionChange = (value) => {
    setIsGiftcardOptionSelected(value);
    const giftCardPreference = {
      appointmentId: createNewAppointmentSuccess.id,
      giftCardOptIn: value
    }
    updateGiftCardPreference(giftCardPreference)
  }

  const getCalendarData = () => {
    if (!isEmpty(createNewAppointmentSuccess)) {
      const event = {
        title: createNewAppointmentSuccess.appointmentType,
        description: `${createNewAppointmentSuccess.appointmentType} ${t('appointmentat')} ${createNewAppointmentSuccess.addressLine1
          } ${createNewAppointmentSuccess.addressLine2}`,
        location: `${createNewAppointmentSuccess.addressLine1} ${createNewAppointmentSuccess.addressLine2}`,
        startTime: createNewAppointmentSuccess.startDateTime,
        endTime: createNewAppointmentSuccess.endDateTime
    }
      return event
    }
    return null
  }
  
  useEffect(() => {
    if(open){
      if(donor.pdn){
        inquiry.pastDonations.length === 0 ? checkNewDonorBonusEligibility() : null 
      }
      else{
        checkNewDonorBonusEligibility()
      }
    }
  }, [open])

  useEffect(() => {
    if((center && centers) || (center && searchedCenters)){
      let centerNewDonorBonusInformation = (centers || searchedCenters).find(item => item.sfid === center.sfid)?.newDonorBonusInformation
      if(centerNewDonorBonusInformation){
        setDonorBonusInformation(centerNewDonorBonusInformation)
      }
    }
  }, [center, centers])

  if (createNewAppointmentFailure) {
    return (
      <Modal open={open} onClose={toggleModal} className='AddToCalendarModal'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
                {t('oopsSomethingwentwrong')}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image src={CloseIcon} alt='Close' onClick={toggleModal} />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <div className='MyAppointment'>
            <p className='RegularText'>{createNewAppointmentFailure && createNewAppointmentFailure.response.data.message}</p>
          </div>
        </Modal.Content>
      </Modal>
    )
  }

  return (
    <Modal open={open} onClose={() => {toggleModal(); setIsGiftcardOptionSelected('No');}} className='AddToCalendarModal'>
      <Modal.Header className='HeaderContainer'>
        <Grid columns={2}>
          <Grid.Column width={14}>
            <Header as='h2' className='ModalHeader'>
              {t('yourAppointmentisConfirme')}
            </Header>
          </Grid.Column>
          <Grid.Column width={2} textAlign='right'>
            <Image src={CloseIcon} alt='Close' onClick={() => {toggleModal(); setIsGiftcardOptionSelected('No');}} disabled={updateGiftCardPreferenceLoading}/>
          </Grid.Column>
        </Grid>
      </Modal.Header>
      <Modal.Content>
        <div className='MyAppointment'>
        {newDonorBonusEligibilityLoading ? <LoadingState /> :
          isNewDonorBonusEligible && Object.keys(donorBonusInformation)?.length > 0 ? 
            <div className='NewDonorBonusInformationBlock'>
              <p>{t('congratulations')}</p> 
              <br></br>
              <p>{t('youAreOnYourWay')} <b>${donorBonusInformation?.ndbAmount}</b> {t('beingANewDonor')}
              <br></br>
              {t('newDonorBonusCouponIs')} <b>{donorBonusInformation?.payCode}</b>.</p>
              <p>{t('youWillBePaidOut')} 
              <br></br>
              ${donorBonusInformation?.bonusStructure?.split('/').join(', $').substring(0, donorBonusInformation?.bonusStructure.split('/').join(', $').lastIndexOf(', $')) + ` ${t('and')}` + donorBonusInformation?.bonusStructure.split('/').join(', $').substring(donorBonusInformation?.bonusStructure.split('/').join(', $').lastIndexOf(', $')+1)}</p>
              <p>{t('takeScreenshot')}</p>
              <span>{t('termsAndConditionsApply')}</span>
            </div> 
          : null}
          <Header as='h3' className='HeaderText'>
            {center && loggedInUser?.role === 'Donor' ? splitCenterName(center?.name) : center?.name} {t('center')}
          </Header>
          <p className='RegularText'>
            {`${moment.parseZone(appointment.startDateTime).format('ddd')}, ${moment.parseZone(appointment.startDateTime).format('ll')}
              at ${appointment.startTime && appointment.startTime.toLowerCase()}`}
          </p>
          <div className='ModalActions'>
            <Grid.Row className='ConfirmContainer'>
              <AddToCalendar event={getCalendarData()} buttonLabel={t('addToCalendar')}  buttonClassClosed={updateGiftCardPreferenceLoading ? "react-add-to-calendar__button_disabled" : "react-add-to-calendar__button"}/>
            </Grid.Row>

            {loggedInUser?.role === 'Donor' && loggedInUser?.pdn !== null && center?.isGiftcardEnabled &&

              <div className="PaymentOptions">

                <Grid.Row className="PaymentOptionText">
                  <span className='RegularText'>
                    {t("confirmyourpaymentoption")}
                  </span>
                </Grid.Row>

                <div className="checkbox-group">
                  <Grid columns={2}>
                    <CheckboxCard
                      isGiftcardOptionSelected={isGiftcardOptionSelected}
                      handleGiftcardOptionChange={handleGiftcardOptionChange}
                      value='No'
                      checkboxIcon={DebitCardImage}
                      altText="DebitCard"
                      label={t("biolifedebitcard")}
                    />
                    <CheckboxCard
                      isGiftcardOptionSelected={isGiftcardOptionSelected}
                      handleGiftcardOptionChange={handleGiftcardOptionChange}
                      value='Yes'
                      checkboxIcon={GiftCardImage}
                      altText="GiftCard"
                      label={[<>{t("biolifesmartcard")}<br />{t("topbrands")}</>]}
                    />
                  </Grid>
                </div>

                {updateGiftCardPreferenceFailure &&
                  <Grid.Row className={`ErrorText ${i18n.language}`} >
                    <span>{updateGiftCardPreferenceFailure?.response?.data?.message}</span>
                  </Grid.Row>
                }

                <Grid.Row className="SmartCardDescription">
                  <span className="RegularText">
                    {t("biolifesmartcarddescription")}
                    <a href={i18n.language === 'es-MX' ? `https://info.biolifeplasma.com/ESSmartCard` : `https://info.biolifeplasma.com/SmartCard `} 
                       style={{ textDecoration: "underline", color: "#ac0079", fontWeight: 700 }} target='_blank'>
                        {t("learnmore")}
                    </a>
                    {t("orsee")}
                    <a href={(center?.giftCardProgram === "profit share") ? '/legal/gift-card-pilot-profit-share-terms-and-conditions' :
                      (center?.giftCardProgram === "equal value") ? '/legal/gift-card-pilot-equal-value-terms-and-conditions' : '/legal'} 
                      target='_blank' style={{ textDecoration: "underline", color: "#ac0079", fontWeight: 700 }}>
                        {t("termsandconditions")} 
                    </a>
                  </span>
                </Grid.Row>
              </div>
            }

            <Grid.Row>
            <div className='InboxNoticeContainer'>
             {(isNull(donor?.pdn) || (isEmpty(lastPhysicalDate) || isUndefined(lastPhysicalDate))) ? 
             ( <p className="RegularText">
             {t("prepareAppointment")}<br/>
             <a
               className="AppointmentAnchor"
               href={t("clickHereEndpont")}
               target="_blank"
             >
               {t("clickHere")}
             </a>{" "}
             {t("viewAppointment")}<br/>
             {t("deliveredMessage")}  
                 </p>):(<p className='RegularText'>
                 {t('remembertocheckyourinbox')}
                </p>)
              }
             </div>
            </Grid.Row>
            <Grid.Row>
              <PrimaryButton
                text={t('backtoDashboard')}
                width={270}
                height={50}
                fontSize={16}
                classType='Primary'
                onClick={() => {
                  toggleModal();
                  setIsGiftcardOptionSelected('No')
                  if (loggedInUser && loggedInUser.role === 'Admin') {
                    history.push('/center-ops/donor-search')
                  } else {
                    history.push('/dashboard/donor-dashboard')
                  }
                }}
                disabled={updateGiftCardPreferenceLoading}
              />
            </Grid.Row>
          </div>
        </div>
      </Modal.Content>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  loggedInUser: state.users?.user,
  isNewDonorBonusEligible: state.appointments?.newDonorBonusEligibilitySuccess?.data?.isEligible,
  newDonorBonusEligibilityLoading: state.appointments?.newDonorBonusEligibilityLoading,
  inquiry: state.donors.inquiryDonorSuccess?.data,
  donor: state.donors?.donors?.data,
  updateGiftCardPreferenceFailure: state.appointments.updateGiftCardPreferenceFailure,
  updateGiftCardPreferenceLoading: state.appointments.updateGiftCardPreferenceLoading,
  updateGiftCardPreferenceSuccess: state.appointments.updateGiftCardPreferenceSuccess?.data,
  searchedCenters: state.centers.getZipcodeSearchCentersSuccess?.data
})

const mapDispatchToProps = (dispatch) => ({
  checkNewDonorBonusEligibility: () => dispatch(appointmentsActions.newDonorBonusEligibility()),
  updateGiftCardPreference: (giftCardPreference) => dispatch(appointmentsActions.updateGiftCardPreference(giftCardPreference))

})


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddToCalendarModal)