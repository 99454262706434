import React from 'react'
import { Modal, Grid, Header, Image } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import CloseIcon from '../../../../../assets/media/icons/icon-close-web.png'
import './styles.scss'
import importer from '@takedapdt/biolife-core/src/importer'

const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const AccessManagementSuccessModal = ({ open, toggleModal, isToggleRequired, header,content }) => {
  const { t } = useTranslation('AccessManagementSuccessModal');

    return (
      <Modal open={open} onClose={isToggleRequired?toggleModal:null} className='AccessManagementSuccessModal'>
        <Modal.Header className='HeaderContainer'>
          <Grid columns={2}>
            <Grid.Column width={14}>
              <Header as='h2' className='ModalHeader'>
               {header}
              </Header>
            </Grid.Column>
            <Grid.Column width={2} textAlign='right'>
              <Image src={CloseIcon} alt='Close' onClick={toggleModal} className='CloseModal' />
            </Grid.Column>
          </Grid>
        </Modal.Header>
        <Modal.Content>
        <Grid.Row>
          <div className='ModalContent'>
            <p className='RegularText'>{content}</p>
          </div>
        </Grid.Row>
          <Grid.Row className="closerow">
              <PrimaryButton
                text={t('close')}
                fontSize={16}
                classType='Primary'
                onClick={toggleModal}
              />
          </Grid.Row>
        </Modal.Content>
      </Modal>
    )
}

export default AccessManagementSuccessModal
