import React from 'react'
import { Form, Header } from 'semantic-ui-react'
import { Form as FinalForm, Field } from 'react-final-form'
import './styles.scss'
import validate from './validations'
import { useTranslation } from 'react-i18next';
import importer from '@takedapdt/biolife-core/src/importer'

const InputField = importer('Components/Forms/FormFields/InputField')
const PrimaryButton = importer('Components/Buttons/PrimaryButton')

const DonorSearchForm = (props) => {
  const { t } = useTranslation('DonorSearchForm');

  const onSubmit = (values) => {
    props.submitDonorSearch(values)
  }

  return (

    <FinalForm
      onSubmit={onSubmit}
      validate={validate}
      render={({ handleSubmit, form, submitting, pristine, invalid, values }) => (
        <Form onSubmit={handleSubmit} className='DonorSearch'>
          <Header as='h4'>{t('donorSearch')}</Header>

          <Field name='firstName'>
            {({ input, meta }) => <InputField meta={meta} {...input} height={50} placeholder={t('firstName')} />}
          </Field>
          <Field name='lastName'>
            {({ input, meta }) => <InputField meta={meta} {...input} height={50} placeholder={t('lastName')} />}
          </Field>
          <Field name='pdn'>
            {({ input, meta }) => <InputField meta={meta} {...input} height={50} placeholder={t('pdn')} />}
          </Field>
          <Field name='email'>
            {({ input, meta }) => <InputField meta={meta} {...input} height={50} placeholder={t('email')} />}
          </Field>
          <Field name='phoneNumber'>
            {({ input, meta }) => <InputField meta={meta} {...input} height={50} placeholder={t('phoneNumber')} />}
          </Field>
          <div className='ActionsRow'>
            <PrimaryButton
              text={t('search')}
              type='submit'
              width='100%'
              classType='LightBlueWithPadding'
              disabled={invalid || Object.keys(values).length === 0}
              loading={props.loading}
            />
          </div>
          <div className='ActionsRow'>
            <PrimaryButton
              text={t('clear')}
              width='100%'
              classType='PrimaryWithPadding'
              className='ClearForm'
              disabled={submitting || pristine}
              onClick={() => {
                form.reset()
                props.clearDonorSearch()
              }}
            />
          </div>
        </Form>
      )}
    />
  )
}

export default DonorSearchForm
