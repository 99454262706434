import React, { useState, useEffect, useRef } from 'react'
import "./styles.scss"
import FrameIcon from "../../../../assets/media/icons/NoBlogsIcon.svg";
import { Search, Grid, Icon, Pagination } from 'semantic-ui-react'
import BlogCard from './BlogCard'
import BlogFilter from './BlogFilter'
import BlogView from './BlogView'
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';

const BlogContainer = ({ blogs, blogsCategoryList, articleId }) => {
    const [searchKeyword, setSearchKeyword] = useState('');
    const [searchClicked, setSearchClicked] = useState(false);
    const [searchedBlogs, setSearchedBlogs] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [activePage, setActivePage] = useState(1);
    const [noResultFound, setNoResultFound] = useState(false);
    const ref = useRef(null);
    const { t } = useTranslation('BlogsContainer', 'PageContentTabs')
    const BLOGS_PER_PAGE = 9;

       
    const [filteredBlog, setFilteredBlog] = useState([])
    
    useEffect(() => {
      blogs = sortByBasedOnNews();
        if(articleId != 'blog'){
            setFilteredBlog(blogs?.filter(item => item.slug__c === decodeURIComponent(articleId)))
        }
    }, [blogs, window.location.pathname])

    const formattedBlogsCategoryList = blogsCategoryList.map((category)=>{
        return {value: category.toLowerCase(), label: category}
    });

    useEffect(() => {
        blogs = sortByBasedOnNews();
        setSearchedBlogs(blogs);
    }, [blogs]);

    useEffect(() => {
        blogs = sortByBasedOnNews();
        if(!searchKeyword.length) {
            if(!selectedOptions.length) {
                setSearchedBlogs(blogs);
                return;
            }
            // Filter the searchResult state based on the selected categories
            const selectedFilters = selectedOptions.map((option)=>option.label.toLowerCase());
            const filteredBlogs = blogs?.filter((blog) => {
                const blogCategoriesArray = blog.content_category__c.map((category)=>category.toLowerCase());
                return blogCategoriesArray.some((item)=>selectedFilters.includes(item));
            });
            // Update the searchResult state with the filtered posts
            setSearchedBlogs(filteredBlogs);
            setNoResultFound(false)
            setActivePage(1);
        }
        if(searchKeyword.length && selectedOptions.length) {
            handleClearFilter();
        }
    }, [selectedOptions, searchKeyword])

    const handleClearFilter = () => {
        setSelectedOptions([]);
        setActivePage(1);
    };

    const handlePageScroll = () => {
        ref.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    };

    const handleOnFilterSelectionChange = (data, actionMeta) => {
        if(searchKeyword?.length) {
            setSearchKeyword('');
        }
        setSelectedOptions(data);
    }

    const handleSearch = (e, { value }) => {
        setSearchKeyword(value);
        if(value === '' && noResultFound) {
            setNoResultFound(false);
        }
        setSearchClicked(false);
    };

    const handleSearchOnClick = () => {
        setSearchClicked(true);
    };

    useEffect(()=>{
      blogs = sortByBasedOnNews();
        if(searchClicked && searchKeyword?.length) {
            const blogSearchResults = blogs?.filter((blog) => 
                                        (blog.title__c.toLowerCase().includes(searchKeyword.toLowerCase()) ||
                                            blog.content_category__c.join().toLowerCase().includes(searchKeyword.toLowerCase())));

            // Check if the search query has no results
            if(blogSearchResults.length === 0) {
                // Update the searchResult state with all the blogPosts
                setSearchedBlogs(blogs);
                setNoResultFound(true);
            } else {
                // Update the searchResult state with the filtered posts
                setSearchedBlogs(blogSearchResults);
                setNoResultFound(false);
            }
            setActivePage(1);
        }
    },[searchClicked,searchKeyword])

    const startIndex = (activePage - 1) * BLOGS_PER_PAGE;
    const endIndex = startIndex + BLOGS_PER_PAGE;
    const paginatedResults = searchedBlogs?.slice(startIndex, endIndex);

    const sortByBasedOnNews = () => sortBy(blogs, (blog) => blog.content_category__c.includes(t('News')));

    const changePage = (event, { activePage }) => {
        setActivePage(activePage);
        handlePageScroll();
    };

    const renderNoSearchResultsContent = () => {
        return (
            <div className='NoSearchResults'>
                <img src={FrameIcon} alt="frame-icon" />
                <p>{t('noBlogSearchResults')}</p>
            </div>)
    }

    const renderBlogPane = () => {
        return <Grid columns={3} stackable>
                {paginatedResults?.map((blog, index) => (
                    <BlogCard key={index} blog={blog} />))}
                </Grid>
    }

    return (
        <div ref={ref} className='BlogContainer'>
            {filteredBlog?.length > 0 ? 
                <BlogView blog={filteredBlog[0]} setFilteredBlog={setFilteredBlog} />  : 
                    <>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column className="SearchContainer">
                                    <p className='SearchLabel'>{t('searchLabel')}</p>
                                    <Search
                                        value={searchKeyword}
                                        onSearchChange={handleSearch}
                                        placeholder={t('searchPlaceholder')}
                                        showNoResults={false}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                handleSearchOnClick();
                                            }
                                        }}
                                        icon={<Icon name="search" link onClick={handleSearchOnClick} />}
                                    />
                                </Grid.Column>
                                <Grid.Column className="FilterContainer">
                                        <p className='SearchLabel'>{t('filterLabel')}</p>
                                        <BlogFilter
                                            options={formattedBlogsCategoryList}
                                            value={selectedOptions}
                                            onChange={handleOnFilterSelectionChange}
                                            handleClearFilter={handleClearFilter}
                                        />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        {searchKeyword?.length && noResultFound ? renderNoSearchResultsContent() : null}
                        <div className='Blogs'>
                            {renderBlogPane()}
                        </div>
                        {searchedBlogs.length > 9 ?
                            <div className='PaginationContainer'>
                                <span className='PaginationLabel'>{t('paginationLabel')}</span>
                                <Pagination
                                    activePage={activePage}
                                    totalPages={Math.ceil(searchedBlogs?.length / BLOGS_PER_PAGE)}
                                    onPageChange={changePage}
                                    ellipsisItem={null}
                                    firstItem={null}
                                    lastItem={null}
                                    prevItem={{ 
                                        content: <Icon size='large' className={activePage === 1 ? 'lookDisable' : 'lookActive'} name="chevron left" />,
                                    }}
                                    nextItem={{ 
                                        content: <Icon size='large' className={activePage === Math.ceil(searchedBlogs?.length / BLOGS_PER_PAGE) ? 'lookDisable' : 'lookActive'} name="chevron right" />,
                                    }}
                                    secondary
                                />
                            </div>
                        : null}
                    </>
            }
        </div>)
}


export default BlogContainer;