import importer from '@takedapdt/biolife-core/src/importer'
const isValidEmail = importer('Helpers/isValidEmail')
const validate = values => {
  const errors = {}

  if (values.newEmail) {
    if (!isValidEmail(values.newEmail)) {
      errors.newEmail = 'emailAddressMustBeInValid'
    }
    if (values.newEmail.indexOf('@takeda.com') > -1 || values.newEmail.indexOf('@shire.com') > -1) {
      errors.newEmail = 'pleaseEnterANonEmployeeEmail'
    }
    if (values.currentEmail == values.newEmail) {
      errors.newEmail = 'emailAlreadyExists'
    }
  } else {
    errors.newEmail = 'emailIsRequired'
  }

  if (values.confirmEmail) {
    if (values.confirmEmail !== values.newEmail) {
      errors.confirmEmail = 'emailDoNotMatch'
    }
  } else {
    errors.confirmEmail = 'confirmEmailCannotBeEmpty'
  }

  return errors
}

export default validate
