import React from 'react'
import { useTranslation } from 'react-i18next';
import './styles.scss'

const CellPlayroomData = ({
  childrens,
  playroomAdjustmentAppointments,
  playroomScheduledAppointments,
  isAddButtonDisabled
}) => {
  const { t } = useTranslation('CellPlayroomData');

  const getAllowedPlayroomClass = () => {
    if (isAddButtonDisabled) {
      return 'HidePlayroom'
    }
    return 'AllowedPlayroom'
  }

  return (
    <div className='CellPlayroomData'>
      {childrens &&
        childrens.map((children, index) => (
          <p className='PlayroomMember' key={index}>
            {children.name}
          </p>
        ))}
      <p className={getAllowedPlayroomClass()}>
        ({playroomScheduledAppointments || 0} {t('scheduled')} { playroomAdjustmentAppointments || 0} {t('allowed')})
      </p>
    </div>
  )
}

export default CellPlayroomData
